import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select } from '@/components/Inputs';
import { applyMask } from '@/masks';

const propTypes = {
  initialValue: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func
};

const defaultProps = {
  initialValue: [],
  onChange: () => {}
};

const CNPJ_TEXT_LENGTH = 14;
const MAX_USED_CNPJS = 100;
const MAX_AVAILABLE_CNPJS = MAX_USED_CNPJS + 1;
const CLIPBOARD_SEPARATORS_REGEX = /[\s,]+/u;
const NON_NUMBER_REGEX = /\D/gu;
const NON_CNPJ_CHARACTERS_REGEX = /[^\d./-]+/gu;

function CNPJInput({ initialValue, onChange, ...props }) {
  const memoizedInitialOptions = useMemo(() => valuesToOptions(initialValue), []);

  const [options, setOptions] = useState(memoizedInitialOptions);
  const [error, setError] = useState(null);

  const values = useMemo(() => options.map((option) => option.value), [options]);

  useEffect(() => {
    const invalidValue = values.find((value) => value.length < CNPJ_TEXT_LENGTH);

    if (invalidValue) {
      setError(`O CNPJ informado "${applyMask(invalidValue, 'cnpj')}" é inválido e será ignorado`);
    } else if (values.length > MAX_USED_CNPJS) {
      setError(`Somente os primeiros ${MAX_USED_CNPJS} CNPJs informados serão utilizados`);
    } else {
      setError(null);
    }

    const cleanedValues = values
      .filter((value) => value.length === CNPJ_TEXT_LENGTH)
      .slice(0, MAX_USED_CNPJS);

    onChange(cleanedValues);
  }, [values]);

  const addOptions = (addedOptions) => {
    const newOptions = [...options, ...addedOptions].slice(0, MAX_AVAILABLE_CNPJS);

    setOptions(newOptions);
  };

  const handleOnChange = (newValues) => {
    const newOptions = options.filter((option) => newValues?.includes(option.value));

    setOptions(newOptions);
  };

  const handleInputOnPaste = (event) => {
    event.preventDefault();

    if (values.length > MAX_USED_CNPJS) {
      return;
    }

    const data = event.clipboardData.getData('Text');

    const splittedData = data.split(CLIPBOARD_SEPARATORS_REGEX);
    const slicedData = splittedData.slice(0, MAX_AVAILABLE_CNPJS);
    const cleanedData = slicedData.map(
      (item) => item.replace(NON_NUMBER_REGEX, '').slice(0, CNPJ_TEXT_LENGTH)
    );
    const noRepeatedData = cleanedData.filter(clipboardValuesFilter(values));
    const newOptions = noRepeatedData.map(createOption);

    addOptions(newOptions);
  };

  const handleOnInputChange = (currentValue) => {
    if (values.length > MAX_USED_CNPJS) {
      return '';
    }

    const cleanedValue = currentValue.replace(NON_NUMBER_REGEX, '');

    if (cleanedValue.length > CNPJ_TEXT_LENGTH) {
      const newValue = cleanedValue.slice(0, CNPJ_TEXT_LENGTH);
      const rest = cleanedValue.slice(CNPJ_TEXT_LENGTH);

      if (!values.includes(newValue)) {
        const newOption = createOption(newValue);

        addOptions([newOption]);
      }

      return rest;
    }

    const preCleanedValue = currentValue.replace(NON_CNPJ_CHARACTERS_REGEX, '');

    return preCleanedValue;
  };

  const handleOnKeyDown = (event) => {
    if (event.key !== 'Enter') {
      return;
    }

    const cleanedValue = event.target.value.replace(NON_NUMBER_REGEX, '');

    if (cleanedValue && !values.includes(cleanedValue)) {
      const newOption = createOption(cleanedValue);

      addOptions([newOption]);
    }

    event.target.blur();
    event.target.focus();
  };

  return (
    <Select
      multiple
      className='text-small'
      controlClassName='min-height-select-area max-height-select-area align-items-start'
      placeholder='Após inserir pressione a tecla ENTER para confirmar'
      options={options}
      value={values}
      menuListClassName='d-none'
      multiValueClassName='leads-generation-cnpj-badge-width'
      inputProps={{ onPaste: handleInputOnPaste }}
      onChange={handleOnChange}
      onInputChange={handleOnInputChange}
      onKeyDown={handleOnKeyDown}
      error={error}
      {...props}
    />
  );
}

function clipboardValuesFilter(currentValues) {
  return (item, index, array) => (
    item && !currentValues.includes(item) && array.indexOf(item) === index
  );
}

function valuesToOptions(values) {
  return values.map(createOption);
}

function createOption(value) {
  return { label: applyMask(value, 'cnpj'), value };
}

CNPJInput.propTypes = propTypes;
CNPJInput.defaultProps = defaultProps;

export default CNPJInput;
