import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

const defaultProps = {
  className: ''
};

function Info({ className, children }) {
  return (
    <p className={classnames('mb-8', className)}>
      {children}
    </p>
  );
}

Info.propTypes = propTypes;
Info.defaultProps = defaultProps;
Info.displayName = 'ConfirmationModal.Info';

export default Info;
