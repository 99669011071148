import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Modal from '@/components/Modal';
import PropTypes from 'prop-types';

const propTypes = {
  images: PropTypes.array,
  imageIndex: PropTypes.number,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onSelect: PropTypes.func
};

const defaultProps = {
  images: [],
  imageIndex: 0,
  show: false,
  onHide: () => {},
  onSelect: () => {}
};

function ImageCarouselModal({ images, children, imageIndex, show, onHide, onSelect }) {
  if (!images?.length) {
    return null;
  }
  return (
    <>
      <Modal
        size='lg'
        bodyClassName='p-1'
        show={show}
        onHide={onHide}
        placement='top'
        rootCloseEvent='mousedown'>
        <Carousel
          variant='dark'
          activeIndex={imageIndex}
          onSelect={onSelect}
          slide={false}
          interval={null}
        >
          {
            images.map((image, index) => (
              <Carousel.Item key={index}>
                <img
                  className='d-block w-100 img-fluid border-0 p-0'
                  src={image.url}
                />
              </Carousel.Item>
            ))
          }
        </Carousel>
      </Modal>
      {children}
    </>
  );
}

ImageCarouselModal.propTypes = propTypes;
ImageCarouselModal.defaultProps = defaultProps;

export default ImageCarouselModal;
