import React, { createContext, useContext } from 'react';

const LeadsGenerationContext = createContext(undefined);

export function useLeadsGenerationContext() {
  const context = useContext(LeadsGenerationContext);

  if (context === undefined) {
    throw new Error('useLeadsGeneration must be used within LeadsGenerationProvider');
  }

  return context;
}

export function LeadsGenerationProvider({ children, value }) {
  return (
    <LeadsGenerationContext.Provider value={value}>
      {children}
    </LeadsGenerationContext.Provider>
  );
}
