import React from 'react';
import { useFormikContext } from 'formik';
import Stack from 'react-bootstrap/Stack';
import Alert from '@/components/Alert';
import CardFormField from '@/feature/automation/CardFormField';

function DealActionFields() {
  const { values } = useFormikContext();
  const { funnel_id: funnelId } = values.trigger_params ?? {};
  const { funnel_id: actionFunnelId } = values.action_params ?? {};
  const sameFunnel = funnelId === actionFunnelId && funnelId !== null && funnelId !== undefined;

  return (
    <>
      <Stack direction='horizontal' gap={4} className='align-items-start'>
        <CardFormField type='action_funnel' />
      </Stack>

      {sameFunnel && (
        <Alert variant='light' className='mt-4 mb-0 w-100 bg-light-yellow'>
          <span className='fw-bold'>O negócio será duplicado no funil de origem!</span>
          <div>Recomendamos duplicá-lo para outro funil para uma melhor organização.</div>
        </Alert>
      )}
    </>
  );
}

export default DealActionFields;
