import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useAlert } from 'react-alert';
import { useParams, useNavigate } from 'react-router-dom';
import Main from '@/components/Main';
import Breadcrumb from '@/components/Breadcrumb';
import { Select, StageSelect, TextInput } from '@/components/Inputs';
import Button from '@/components/Button';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import DependentFormField from '@/components/DependentFormField';
import APIErrorMessage from '@/components/APIErrorMessage';
import ConfirmationModal from '@/components/ConfirmationModal';
import Table from '@/components/Table';
import Truncate from '@/components/Truncate';
import Icon from '@/components/Icon';
import { settingsFunnelsPath } from '@/routes';
import { useDocumentTitle } from '@/hooks';
import NotFound from '@/pages/not-found';
import { isNotFoundError } from '@/errors';
import {
  useCreateFunnelDealsRelocation,
  useFunnel,
  useFunnels,
  useStages
} from '@/api';

const PAGE_TITLE = 'Excluir funil';

const schema = Yup.object().shape({
  destination_funnel_id: Yup.number().required(),
  mappings: Yup.array().of(Yup.object().shape({
    origin_stage_id: Yup.number().required(),
    destination_stage_id: Yup.number().required()
  }))
    .required()
});

function FunnelsDealsRelocationsIndex() {
  const navigate = useNavigate();
  const { funnelId } = useParams();
  const alert = useAlert();

  const [destinationFunnel, setDestinationFunnel] = useState(null);
  const [params, setParams] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(null);
  const relocateMutation = useCreateFunnelDealsRelocation({ funnelId });

  useDocumentTitle(PAGE_TITLE);

  const {
    data: { data: accountFunnels = [] } = {},
    isLoading: isLoadingAccountFunnels
  } = useFunnels({ config: { enabled: !relocateMutation.isLoading } });

  const {
    data: { data: destinationStages = [] } = {},
    isLoading: isLoadingDestinationStages
  } = useStages({
    params,
    config: {
      enabled: Boolean(destinationFunnel)
    }
  });

  const {
    data: { data: currentFunnel } = {},
    error: currentFunnelError,
    isLoading: isLoadingCurrentFunnel
  } = useFunnel({ funnelId });

  const stages = currentFunnel ? currentFunnel.stages : [];

  const funnelsOptions = accountFunnels
    .filter((item) => item.id !== parseInt(funnelId, 10))
    .map((item) => ({ label: item.name, value: item.id }));

  const defaultValues = useMemo(() => (
    getDefaultValues(
      destinationFunnel,
      stages,
      destinationStages
    )
  ), [destinationFunnel, stages, destinationStages]);

  const destinationMapping = useMemo(
    () => {
      if (!formData) {
        return [];
      }

      return formData.mappings.map(
        ({ origin_stage_id: originId, destination_stage_id: destinationId }) => ({
          origin: stages.find((stage) => stage.id === originId),
          destination: destinationStages.find((stage) => stage.id === destinationId)
        })
      );
    },
    [stages, formData, destinationStages]
  );

  if (currentFunnelError && isNotFoundError(currentFunnelError)) {
    return <NotFound />;
  }

  if (
    isLoadingAccountFunnels ||
    isLoadingDestinationStages ||
    isLoadingCurrentFunnel
  ) {
    return <div className='flex-grow-1 bg-light' />;
  }

  const handleModalHide = () => setShowModal(false);

  const onSubmit = (data) => {
    setShowModal(true);

    setFormData(data);
  };

  const handleDeleteConfirm = () => {
    relocateMutation.mutate(formData, {
      onSuccess: (_response) => {
        alert.show(
          'Funil excluído com sucesso.',
          { variant: 'success', timeout: 5000 }
        );

        navigate(settingsFunnelsPath());
      },
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='funnel' action='delete' />,
          { variant: 'danger' }
        );
      }
    });
  };

  return (
    <Main>
      <Breadcrumb>
        <Breadcrumb.Item href={settingsFunnelsPath()}>
          Personalizar funis
        </Breadcrumb.Item>

        <Breadcrumb.Item href='#' active>
          Excluir {currentFunnel.name}
        </Breadcrumb.Item>
      </Breadcrumb>

      <h2 className='mb-6'>
        Excluir {currentFunnel.name}
      </h2>

      <p className='mb-6'>
        Para executar esta ação, você precisa selecionar abaixo um destino
        para os negócios deste funil.
      </p>

      <ConfirmationModal
        show={showModal}
        disabled={relocateMutation.isLoading}
        onHide={handleModalHide}
        onConfirm={handleDeleteConfirm}
        confirmationText='Mover negócios e excluir funil'
      >
        <ConfirmationModal.Title>
          Confirmar exclusão do {currentFunnel.name}
        </ConfirmationModal.Title>

        <ConfirmationModal.Info>
          Verifique abaixo se está tudo certo antes de confirmar a exclusão.
          Esta ação não poderá ser revertida.
        </ConfirmationModal.Info>

        <ConfirmationModal.Content>
          <div className='mb-2 text-start text-dark-gray text-uppercase fw-bold'>
            Destino dos negócios do {currentFunnel.name}
          </div>

          <div className='position-relative overflow-y-auto max-height-5'>
            <Table className='alig-self-start text-start table-border-separate'>
              <thead className='sticky-top bg-white'>
                <tr>
                  <th className='max-width-4 px-0'>
                    <Truncate>{currentFunnel.name}</Truncate>
                  </th>
                  <th></th>
                  <th className='max-width-4 px-0'>
                    {destinationFunnel && <Truncate>{destinationFunnel.name}</Truncate>}
                  </th>
                </tr>
              </thead>

              <tbody>
                {destinationMapping.map(({ origin, destination }) => (
                  <tr key={origin.id}>
                    <td className='max-width-4 px-0'>
                      <Truncate>{origin.order}. {origin.name}</Truncate>
                    </td>
                    <td className='text-center'>
                      <Icon name='full-arrow-right' className='text-gray' />
                    </td>
                    <td className='max-width-4 px-0'>
                      <Truncate>{destination.order}. {destination.name}</Truncate>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </ConfirmationModal.Content>
      </ConfirmationModal>

      <Form
        name='funnel-deal-relocation'
        onSubmit={onSubmit}
        validationSchema={schema}
        defaultValues={defaultValues}
      >
        {
          ({ isValid }) => (
            <div>
              <FormField
                as={Select}
                className='mb-4 width-6'
                name='destination_funnel_id'
                label={<h5>Para onde os negócios devem ser movidos?</h5>}
                placeholder='Selecione um funil'
                options={funnelsOptions}
                onChange={(value, { option: { label } }) => {
                  setDestinationFunnel({ name: label, id: value });
                  setParams({ funnel_id_eq: value });
                }}
              />

              {destinationFunnel &&
                <div className='border-top pt-4'>
                  <h5 className='mb-2'>
                    Para quais etapas os negócios devem ser movidos?
                  </h5>

                  <p>
                    Escolha o destino dos negócios de cada etapa
                    do {currentFunnel.name} para as suas respectivas
                    no {destinationFunnel.name}.
                  </p>

                  <div>
                    {stages.map((stage, index) => (
                      <div
                        key={stage.id}
                        className='d-flex align-items-center mb-4'
                      >
                        <div className='width-5 fw-bold'>
                          {stage.order}. {stage.name}
                        </div>

                        <div className='width-3 text-center'>
                          <Icon
                            name='full-arrow-right'
                            className='text-gray'
                          />
                        </div>

                        <div className='width-5'>
                          <FormField
                            as={TextInput}
                            name={`mappings[${index}][origin_stage_id]`}
                            type='hidden'
                            className='mb-0'
                          />

                          <DependentFormField
                            as={StageSelect}
                            name={`mappings[${index}][destination_stage_id]`}
                            placeholder='Selecione uma etapa'
                            className='mb-0'
                            source='destination_funnel_id'
                            target='funnelId'
                            label={null}
                            autohide
                          />
                        </div>
                      </div>
                    ))}
                  </div>

                  <Button
                    className='py-3 px-5'
                    type='submit'
                    disabled={!isValid || relocateMutation.isLoading}
                  >
                    Prosseguir
                  </Button>

                  <Button
                    variant='link'
                    type='button'
                    href={settingsFunnelsPath()}
                  >
                    Cancelar
                  </Button>
                </div>
              }
            </div>
          )
        }
      </Form>
    </Main>
  );
}

function getDefaultValues(destinationFunnel, originStages, destinationStages) {
  return {
    destination_funnel_id: destinationFunnel ? destinationFunnel.id : '',
    mappings: originStages.map((stage, index) => {
      let destinationStageId;

      if (destinationStages.length === 0) {
        destinationStageId = '';
      } else if (index < destinationStages.length) {
        destinationStageId = destinationStages[index].id;
      } else {
        destinationStageId = destinationStages[destinationStages.length - 1].id;
      }

      return {
        origin_stage_id: stage.id,
        destination_stage_id: destinationStageId
      };
    })
  };
}

export default FunnelsDealsRelocationsIndex;
