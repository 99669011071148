import React, { useMemo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Tooltip from '@/components/Tooltip';
import Form from '@/components/Form';
import Popover from '@/components/Popover';
import FormField from '@/components/FormField';
import { Checkbox } from '@/components/Inputs';


const propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    isRequired: PropTypes.bool.isRequired,
    isUnique: PropTypes.bool.isRequired
  })).isRequired,
  duplicationCriterias: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSubmit: PropTypes.func
};

const defaultProps = {
  onSubmit: () => {}
};

function DuplicationCriteriasForm({ options, duplicationCriterias, onSubmit }) {
  const defaultValues = useMemo(() => options.reduce((result, option) => {
    result[option.value] =
      option.isRequired || option.isUnique || duplicationCriterias.includes(option.value);

    return result;
  }, {}), []);

  const handleSubmit = (data) => {
    const selectedCriterias = Object.keys(data).filter((optionName) => data[optionName]);

    onSubmit(selectedCriterias);
  };

  return (
    <Form
      initialSubmit
      className={classnames('d-flex')}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      name={'import-duplication-criterias-form'}
    >
      {({ submitForm, resetForm }) => (
        <Popover
          closeButton
          content={
            ({ onHide }) => (
              <div className='width-5 py-1'>
                <h4>Configurar duplicidade</h4>

                <div
                  className={classnames(
                    'duplication-criterias-popover-content-max-height',
                    'min-height-4',
                    'mt-4',
                    'overflow-y-auto',
                    'px-4',
                    'mx-n4'
                  )}
                >
                  {options.map((option) => renderCriteriaOption(option))}
                </div>

                <div className='mt-4 d-grid gap-2'>
                  <Button
                    onClick={() => {
                      submitForm();
                      onHide();
                    }}
                  >
                    Salvar alterações
                  </Button>

                  <Button
                    variant='outline-dark-gray'
                    onClick={() => {
                      resetForm({ values: defaultValues });
                      submitForm();
                      onHide();
                    }}
                  >
                    Restaurar padrão
                  </Button>
                </div>
              </div>
            )
          }
          trigger={['click']}
          placement='bottom-start'
          rootClose
          rootCloseEvent='mousedown'
          flip
        >
          <Button variant='light' className='border-0 text-primary py-0 ps-2'>
            <Icon name='config' size='lg' className='px-1' />

            Configurar duplicidade
          </Button>
        </Popover>
      )}
    </Form>
  );
}

function renderCriteriaOption(option) {
  let tooltipMessage = null;
  const symbol = option.isRequired ? '*' : '';
  const title = `${option.title}${symbol}`;
  const isDisabled = option.isRequired || option.isUnique;

  if (option.isUnique) {
    tooltipMessage = `
      Campos únicos detectam a duplicidade automaticamente
      e não podem ser desativados.
    `;
  }

  if (option.isRequired) {
    tooltipMessage = `
      Campos obrigatórios detectam a duplicidade automaticamente
      e não podem ser desativados.
    `;
  }

  return (
    <FormField
      as={Checkbox}
      key={option.value}
      name={option.value}
      disabled={isDisabled}
      label={
        <div className='d-flex align-items-stretch'>
          <Tooltip hide={!tooltipMessage} content={tooltipMessage}>
            <span>{title}</span>
          </Tooltip>
        </div>
      }
    />
  );
}

DuplicationCriteriasForm.propTypes = propTypes;
DuplicationCriteriasForm.defaultProps = defaultProps;

export default DuplicationCriteriasForm;
