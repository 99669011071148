import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Main from '@/components/Main';
import ExternalLink from '@/components/ExternalLink';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Card from '@/components/Card';
import * as Settings from '@/feature/settings';
import RechargeCard from '@/feature/voip/RechargeCard';
import CurrentBalance from '@/feature/voip/CurrentBalance';
import CallHistory from '@/feature/voip/CallHistory';
import { toNumber } from '@/number';
import { helpUrl, settingsModulesVoipPath } from '@/routes';
import { useVoipAccount, useVoipVerify } from '@/api';
import { useDocumentTitle } from '@/hooks';
import emptyPanel from 'images/empty-panel.png';
import PanelModal from '@/components/Voip/CustomNumber/PanelModal';

const PAGE_TITLE = 'Telefone Virtual';
const PAGE_SUBTITLE = 'Veja seu saldo, históricos e realize recarga no Telefone virtual';

function SettingsVoipPanel() {
  const [showPanelModal, setShowPanelModal] = useState(false);
  useDocumentTitle(PAGE_TITLE);
  const [rechargeValue, setRechargeValue] = useState(0);

  const {
    data: voipAccount,
    refetch: refetchVoipAccount,
    isLoading: isLoadingVoipAccount,
    isRefetching: isRefetchingVoipAccount
  } = useVoipAccount();
  const { data: voip, isLoading: isLoadingVoipVerify } = useVoipVerify();
  const pricePerMinute = voip?.pricePerMinute;
  const balance = toNumber(voipAccount?.balance);
  const approxMinutes = Math.ceil(balance / pricePerMinute);
  const hasEnabled = Boolean(voip?.enabled);
  const isLoading = isLoadingVoipAccount || isLoadingVoipVerify;

  const HELP_TEXT = `Tire suas dúvidas sobre o número que é exibido para
  seus clientes ou outras questões relacionadas ao telefone virtual.`;

  const SPAM_TEXT = `Isso acontece porque alguns celulares podem indentificá-lo como spam
  e compartilhar com outros aparelhos. Você pode contratar
  um número próprio para resolver esse problema.`;

  if (isLoading) {
    return null;
  }

  return (
    <Main>
      <Container>
        {hasEnabled
          ? (
            <>
              <h1 className='mb-3'>
                {PAGE_TITLE}
              </h1>
              <div>
                <Settings.PageSubtitle>
                  {PAGE_SUBTITLE}
                </Settings.PageSubtitle>
              </div>
              <Row>
                <Col md={4} sm={12}>
                  <CurrentBalance
                    balance={balance}
                    approxMinutes={approxMinutes}
                    pricePerMinute={pricePerMinute}
                    onReload={() => refetchVoipAccount()}
                    isLoading={isLoadingVoipAccount || isRefetchingVoipAccount}
                  />
                  <RechargeCard
                    value={rechargeValue}
                    isLoading={isLoadingVoipVerify}
                    pricePerMinute={pricePerMinute}
                    onChangeRechargeValue={(value) => setRechargeValue(toNumber(value))}
                  />
                </Col>
                {/* <Col md={8} sm={12}>
                  <RechargeHistory />
                </Col> */}
                <Col md={8} sm={12}>
                  <CallHistory />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Card className='p-4 border-lighter-gray min-height-100'>
                    <Icon
                      name='call-start'
                      size='lg'
                      className='bg-primary bg-opacity-10 rounded p-1 text-dark-gray'>
                    </Icon>
                    <span className='text-nowrap h5 mt-4 mb-2 text-dark-gray'>
                      Seu número está sendo apontado como spam?</span>
                    <span className='text-medium-gray'>{SPAM_TEXT}</span>
                    <div className='mt-4'>
                      <Button
                        onClick={() => setShowPanelModal(true)}
                        variant='primary'
                      >
                        <span>Contratar número próprio</span>
                      </Button>
                      <PanelModal
                        data={voipAccount}
                        show={showPanelModal}
                        onHide={() => setShowPanelModal(false)}
                      />
                      <Button
                        as={ExternalLink}
                        target='_blank'
                        href={helpUrl('replace-number')}
                        variant='light'
                        className='ms-4'
                      >
                        <Icon
                          name='info'
                          size='sm'
                          className='me-1 text-dark-gray'
                        />
                        <span className='text-dark-gray'>Saiba mais</span>
                      </Button>
                    </div>
                  </Card>
                </Col>
                <Col sm={6}>
                  <Card className='p-4 border-lighter-gray min-height-100'>
                    <Icon
                      name='help'
                      size='lg'
                      className='bg-primary bg-opacity-10 rounded p-1 text-dark-gray'>
                    </Icon>
                    <span className='text-nowrap h5 mt-4 mb-2 text-dark-gray'>
                      Central de Ajuda
                    </span>
                    <span className='text-medium-gray'>{HELP_TEXT}</span>
                    <div className='mt-4'>
                      <Button
                        as={ExternalLink}
                        target='_blank'
                        href={helpUrl('activate-voip')}
                        variant='light'
                      >
                        <Icon
                          name='external-link'
                          size='sm'
                          className='me-1 text-dark-gray'
                        />
                        <span className='text-dark-gray'>Acessar</span>
                      </Button>
                    </div>
                  </Card>
                </Col>
              </Row>
            </>
          )
          : (
            <div
              className='d-flex flex-column align-items-center justify-content-center'
              style={{ minHeight: '84vh' }}
            >
              <img src={emptyPanel} />
              <h3 className='mt-5 fw-bold text-center'>
                Ative o telefone virtual para visualizar<br /> as informações deste painel
              </h3>
              <Button
                as={ExternalLink}
                href={settingsModulesVoipPath()}
                className='mt-4'
              >
                Ir para tela de ativação
              </Button>
            </div>
          )
        }
      </Container>
    </Main >
  );
}

export default SettingsVoipPanel;
