import React, { useState } from 'react';
import classname from 'classnames';
import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';
import Row from 'react-bootstrap/Row';
import { useAlert } from 'react-alert';
import { FileInput } from '@uppy/react';
import { useUppy, createUppy } from '@/lib/uppy';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import Tooltip from '@/components/Tooltip';
import BannerOptions from '@/components/BannerOptions';

const BG_COLOR = '';
const TEXT_COLOR = '#000000';
const MEDIUM_OPACITY = 0.50;

BannerArea.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.object,
  children: PropTypes.any,
  hide: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  onChangeColors: PropTypes.func
};

BannerArea.defaultProps = {
  name: '',
  value: null,
  children: null,
  hide: false,
  className: '',
  onChange: () => {},
  bgColor: BG_COLOR,
  textColor: TEXT_COLOR,
  onChangeColors: () => {}
};

const ASPECT_WIDTH = 4;
const ASPECT_HEIGHT = 1;
const ASPECT_RATIO = ASPECT_WIDTH / ASPECT_HEIGHT;
const MAX_FILE_SIZE_MB = 50;

function BannerArea({
  name, value, hide, children, className, onChange, bgColor, textColor,
  onChangeColors, layoutMode
}) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [showEdit, setShowEdit] = useState(false);
  const [croppedArea, setCroppedArea] = useState(null);
  const [selectedImage, setSelectedImage] = useState();

  const alert = useAlert();
  const uppy = useUppy(() => {
    const newUppy = createUppy({
      allowedFileTypes: ['.jpg', '.jpeg', '.png'],
      maxFileSizeMega: MAX_FILE_SIZE_MB,
      maxNumberOfFiles: 3
    });

    newUppy
      .on('file-added', (fileAdded) => {
        newUppy.getFiles().forEach((file) => {
          if (fileAdded.id !== file.id) {
            newUppy.removeFile(file.id);
          }
        });
        if (fileAdded.data instanceof File) {
          fileAdded.preview = URL.createObjectURL(fileAdded.data);
        }
        setSelectedImage(fileAdded);
      })
      .on('file-removed', () => {
        setCroppedArea(null);
        onChange(null);
      })
      .on('restriction-failed', () => {
        alert.show(
          `Não foi possível adicionar imagem de fundo.
          Selecione um arquivo JPEG ou PNG de até ${MAX_FILE_SIZE_MB} MB.`,
          { variant: 'danger' }
        );
      })
      .on('upload-success', (uploadedFile) => {
        const file = newUppy.getFile(uploadedFile.id);
        const hasCropped = !(file.data instanceof File) && file.data instanceof Blob;
        if (hasCropped) {
          const body = structuredClone(file.response.body);
          body.preview = URL.createObjectURL(file.data);
          onChange(body);
        }
      })
      .on('upload-error', (file) => {
        alert.show(
          'Ocorreu um erro ao adicionar imagem.',
          { variant: 'danger' }
        );
        newUppy.removeFile(file.id);
      });

    return newUppy;
  });

  const fileUrl = value?.preview;
  const blobUrl = selectedImage?.preview;
  const imageUrl = blobUrl || fileUrl;
  const disableSave = !imageUrl;
  const disableCancel = !imageUrl;

  const onCropComplete = (_percentage, areaPixels) => {
    setCroppedArea(areaPixels);
  };

  const handleCancel = () => {
    setCroppedArea(null);
    setShowEdit(false);
  };

  const handleDelete = () => {
    uppy.reset();
    setSelectedImage(null);
    setCroppedArea(null);
    setShowEdit(false);
    onChange(null);
  };

  const handleCropDone = () => {
    uppy.reset();

    const imageCanvas = document.createElement('canvas');
    const context = imageCanvas.getContext('2d');

    imageCanvas.width = croppedArea.width;
    imageCanvas.height = croppedArea.height;

    const imageObject = new Image();

    imageObject.src = imageUrl;
    imageObject.crossOrigin = 'anonymous';
    imageObject.onload = () => {
      context.drawImage(
        imageObject,
        croppedArea.x,
        croppedArea.y,
        croppedArea.width,
        croppedArea.height,
        0,
        0,
        croppedArea.width,
        croppedArea.height
      );

      imageCanvas.toBlob(async (imageBlob) => {
        uppy.addFile({
          name: selectedImage?.name,
          type: selectedImage?.type,
          data: new Blob([imageBlob], { type: selectedImage?.type })
        });
        await uppy.upload();
        setShowEdit(false);
      });
    };

    onChangeColors({ bgColor: BG_COLOR });
  };

  if (showEdit) {
    return (
      <Row
        className={classname(className, 'top-0 start-0')}
        style={{ height: '320px' }}
      >
        <Cropper
          image={imageUrl}
          crop={crop}
          zoom={zoom}
          aspect={ASPECT_RATIO}
          objectFit='horizontal-cover'
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
          style={{
            containerStyle: {
              padding: 0,
              top: 93,
              left: 0,
              height: '320px',
              backgroundSize: 'cover'
            }
          }}
        />
        <div
          className={classname(
            'd-flex justify-content-end z-index-1060 position-absolute pe-8'
          )}
          style={{ top: 360, right: 0 }}
        >
          <Tooltip content='Carregar' placement='top'>
            <Button
              as='label'
              alt='Carregar'
              variant='light'
              className='me-1 opacity-100'
              onClick={() => uppy.reset()}
            >
              <FileInput
                uppy={uppy}
                inputName={name}
                pretty={false}
              />
              <Icon name='upload' />
            </Button>
          </Tooltip>
          <Tooltip content='Excluir' placement='top'>
            <Button
              alt='Excluir'
              variant='light'
              className='me-1 opacity-100'
              onClick={handleDelete}
            >
              <Icon name='delete' />
            </Button>
          </Tooltip>
          <Tooltip content='Cancelar' placement='top'>
            <Button
              alt='Cancelar'
              variant='light'
              className='me-1 opacity-100'
              disabled={disableCancel}
              onClick={handleCancel}
            >
              <Icon name='close' />
            </Button>
          </Tooltip>
          <Tooltip content='Salvar' placement='top'>
            <Button
              alt='Salvar'
              variant='primary'
              onClick={handleCropDone}
              disabled={disableSave}
              className='opacity-100'
            >
              <Icon name='check' />
            </Button>
          </Tooltip>
        </div>
      </Row>
    );
  }

  return (
    <div className='mx-0 px-0'>
      {/*
        CSS manualmente implementado para espelhar o comportamento necessário
        tanto para a modificação da proposta quanto no carregamento do PDF,
        vide comentário no ProposalDocument > Container.
      */}
      <div
        className='w-100 mx-0 p-0 position-absolute'
        style={{
          top: 65,
          left: 0,
          height: '320px',
          opacity: imageUrl ? 1 : MEDIUM_OPACITY,
          backgroundImage: imageUrl ? `url(${imageUrl})` : null,
          backgroundSize: 'cover'
        }}
      />
      <div className='position-relative'>
        <Row
          className={classname(
            'w-100 mx-0 p-0 border-top border-1 border-light-gray',
            { 'bg-light': !imageUrl && !bgColor }
          )}
          style={{
            height: layoutMode ? '320px' : '315px',
            backgroundColor: bgColor,
            color: textColor
          }}
        >
          {
            hide
              ? null
              : (
                <BannerOptions
                  onChangeColors={onChangeColors}
                  onOpenBanner={() => setShowEdit(true)}
                  bgColor={bgColor ?? BG_COLOR}
                  textColor={textColor ?? TEXT_COLOR}
                />
              )
          }
          {children}
        </Row>
      </div>
    </div>
  );
}

export default BannerArea;
