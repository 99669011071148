import React, { createContext, useContext } from 'react';

const CheckoutContext = createContext(undefined);

export function useCheckoutContext() {
  const context = useContext(CheckoutContext);

  if (context === undefined) {
    throw new Error('useCheckout must be used within CheckoutProvider');
  }

  return context;
}

export function CheckoutProvider({ children, value }) {
  return (
    <CheckoutContext.Provider value={value}>
      {children}
    </CheckoutContext.Provider>
  );
}
