import React from 'react';
import SortInput from '@/components/Inputs/SortInput';

const propTypes = {
};

const defaultProps = {
};

function DealSortInput(props) {
  return (
    <SortInput
      { ...props }
      options={[
        {
          label: 'Última Atualização',
          value: 'updated_at',
          direction: { type: 'date', defaultValue: 'desc' }
        },
        {
          label: 'Ordem Alfabética',
          value: 'title',
          direction: { type: 'text', defaultValue: 'asc' }
        },
        {
          label: 'Ranking',
          value: 'ranking',
          direction: { type: 'number', defaultValue: 'desc' }
        },
        {
          label: 'Valor',
          value: 'value',
          direction: { type: 'number', defaultValue: 'desc' }
        },
        {
          label: 'Data de Cadastro',
          value: 'created_at',
          direction: { type: 'date', defaultValue: 'desc' }
        },
        {
          label: 'Data de Início',
          value: 'started_at',
          direction: { type: 'date', defaultValue: 'desc' }
        },
        {
          label: 'Data de Conclusão',
          value: 'finished_at',
          direction: { type: 'futureDate', defaultValue: 'asc' }
        }
      ]}
    />
  );
}

DealSortInput.propTypes = propTypes;
DealSortInput.defaultProps = defaultProps;

export default DealSortInput;
