import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SearchBar from '@/components/SearchBar';
import Item from '@/components/CustomerSearch/Item';
import FooterActionButton from '@/components/CustomerSearch/FooterActionButton';
import FooterStatus from '@/components/CustomerSearch/FooterStatus';

function FooterActions({ isFetching, isEmpty, term, items, onButtonClick }) {
  if (isFetching) {
    return null;
  }

  return (
    <div
      className={classnames(
        'd-flex',
        'flex-column',
        'fw-bold',
        'align-items-center',
        { 'pt-5': !isEmpty }
      )}
    >
      <div className='text-darker-gray'>
        {`Criar "${term}" como novo(a):`}
      </div>

      <div className='d-flex align-items-center'>
        <FooterActionButton
          type='organization'
          items={items}
          term={term}
          onClick={onButtonClick}
          className='p-5'
        />

        <FooterActionButton
          type='person'
          items={items}
          term={term}
          onClick={onButtonClick}
          className='p-5'
        />
      </div>
    </div>
  );
}

function Footer({ isFetching, isEmpty, term, items, onButtonClick }) {
  return (
    <div>
      <FooterStatus isFetching={isFetching} isEmpty={isEmpty} />
      <FooterActions
        isFetching={isFetching}
        isEmpty={isEmpty}
        term={term}
        items={items}
        onButtonClick={onButtonClick}
      />
    </div>
  );
}

const propTypes = {
  onItemSelect: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  className: PropTypes.string
};

const defaultProps = {};

function CustomerSearch(props) {
  const { onItemSelect, onButtonClick, className } = props;

  const inputProps = {
    'placeholder': 'Digite o nome do cliente',
    'label': 'Empresa ou pessoa',
    'autoComplete': 'off',
    'maxLength': 100,
    'data-autofocus': 'true'
  };

  const popoverProps = {
    className: 'popover-padding-0 w-100 max-width-100',
    size: 'lg',
    placement: 'bottom-start'
  };

  const resultsFooter = (resultsFooterProps) => (
    <Footer { ...resultsFooterProps } onButtonClick={onButtonClick} />
  );

  return (
    <SearchBar
      name='customer-search'
      types={['organization', 'person']}
      perPage={5}
      resultsFooter={resultsFooter}
      ItemComponent={Item}
      inputProps={inputProps}
      popoverProps={popoverProps}
      onSelect={onItemSelect}
      className={className}
    />
  );
}

CustomerSearch.propTypes = propTypes;
CustomerSearch.defaultProps = defaultProps;

CustomerSearch.Item = Item;
CustomerSearch.Item.displayName = 'CustomerSearch.Item';

CustomerSearch.FooterActionButton = FooterActionButton;
CustomerSearch.FooterActionButton.displayName = 'CustomerSearch.FooterActionButton';

CustomerSearch.FooterStatus = FooterStatus;
CustomerSearch.FooterStatus.displayName = 'CustomerSearch.FooterStatus';

export default CustomerSearch;
