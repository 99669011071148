import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { useAlert } from 'react-alert';

import Modal from '@/components/Modal';
import Header from '@/components/EntityModal/Organization/Header/Header';
import Sidebar from '@/components/EntityModal/Organization/Sidebar/Sidebar';
import Activities from '@/components/EntityModal/Organization/Activities/Activities';
import { OrganizationModalProvider } from '@/contexts/organizationModal';
import {
  useOrganization,
  useUpdateOrganization,
  useUpdatePerson,
  useDeleteOrganization
} from '@/api';
import APIErrorMessage from '@/components/APIErrorMessage';

const propTypes = {
  id: PropTypes.oneOfType([PropTypes.string]).isRequired
};

function OrganizationModal({ id }) {
  const alert = useAlert();
  const navigate = useNavigate();
  const updateOrganizationMutation = useUpdateOrganization();
  const updatePersonMutation = useUpdatePerson();
  const deleteOrganizationMutation = useDeleteOrganization();
  const {
    data: organization,
    isLoading
  } = useOrganization({ organizationId: id, config: { staleTime: 1000 } });

  const onUpdate = (params, done) => {
    updateOrganizationMutation.mutate({ organizationId: id, params }, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='organization' action='update' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.({ error: err });
      },
      onSuccess: (data) => {
        alert.show(
          'Empresa atualizada com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        done?.(data);
      }
    });
  };

  const onUpdatePerson = (params, done, personData) => {
    updatePersonMutation.mutate({ personId: personData.id, params }, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='person' action='update' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.({ error: err });
      },
      onSuccess: (data) => {
        alert.show(
          'Pessoa atualizada com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        done?.(data);
      }
    });
  };

  const handleClose = () => {
    navigate(location?.pathname, { replace: true });
  };

  const onDelete = (_params, done) => {
    deleteOrganizationMutation.mutate({ organizationId: id }, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='organization' action='delete' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.(err);
      },
      onSuccess: () => {
        alert.show(
          'Empresa Excluida',
          { variant: 'success', timeout: 5000 }
        );
        done?.();
        handleClose();
      }
    });
  };

  if (!organization && isLoading) {
    return null;
  }

  return (
    <Modal
      id='organization-modal'
      size='xxxl'
      baseClassName='organization-modal'
      bodyClassName='bg-light p-0 rounded'
      show={Boolean(id)}
      onHide={handleClose}
    >
      <OrganizationModalProvider
        value={{ organization: organization.data, onUpdate, onUpdatePerson, onDelete }}
      >
        <Row noGutters className='mb-2'>
          <Col sm='12'>
            <Header />
          </Col>
          <Col sm='8'>
            <Activities />
          </Col>
          <Col sm='4'>
            <Sidebar />
          </Col>
        </Row>
      </OrganizationModalProvider>
    </Modal>
  );
}

OrganizationModal.prototype = propTypes;

export default OrganizationModal;
