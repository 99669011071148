import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import LoadSpinner from '@/components/LoadSpinner';
import { useDealsCustomFields } from '@/api';

const propTypes = {
  /** Callback chamado quando os campos customizados são buscados com sucesso. */
  onSuccess: PropTypes.func.isRequired,
  /** Função que avança para o próximo passo. */
  advanceStep: PropTypes.func
};

const defaultProps = {
  entity: {},
  advanceStep: () => {}
};

function CustomFieldsStep({ onSuccess, advanceStep }) {
  useDealsCustomFields({
    config: {
      // Necessário devido ao callback de onSuccess.
      staleTime: 0,
      onSuccess: ({ data }) => {
        onSuccess(data);
        advanceStep();
      }
    }
  });

  return (
    <LoadSpinner
      size='lg'
      className={classnames(
        'd-flex',
        'align-items-center',
        'justify-content-center',
        'text-dark-gray',
        'opacity-100',
        'height-4',
        'py-6'
      )}
    />
  );
}

CustomFieldsStep.propTypes = propTypes;
CustomFieldsStep.defaultProps = defaultProps;

export default CustomFieldsStep;
