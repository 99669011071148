import React from 'react';
import PropTypes from 'prop-types';
import logoSpeedio from 'images/leads_generation/logo-speedio.svg';
import MainTitle from '@/components/LeadsGeneration/MainTitle';

const propTypes = {
  children: PropTypes.node.isRequired
};

const defaultProps = {};

function GenericStepWrapper({ children }) {
  return (
    <div className='d-flex flex-column h-100'>
      <div className='d-flex align-items-center'>
        <MainTitle className='bg-light bg-opacity-25' />

        <div className='ms-auto'>
          <span className='fw-bold text-dark-gray text-small me-2'>Em parceria com</span>

          <img src={logoSpeedio} />
        </div>
      </div>

      {children}

      <div
        id='leads-generation-footer'
        className='w-100'
      />
    </div>
  );
}

GenericStepWrapper.propTypes = propTypes;
GenericStepWrapper.defaultProps = defaultProps;

export default GenericStepWrapper;
