import React from 'react';
import Button from '@/components/Button';
import ExternalLink from '@/components/ExternalLink';
import Icon from '@/components/Icon';

function MobileAppsAddonsCard() {
  return (
    <div className='d-flex justify-content-between align-items-center pt-5'>
      <div className='d-flex'>
        <div className='d-inline-block p-1 me-2'>
          <div
            className='p-1 rounded bg-primary bg-opacity-10 text-dark-gray lh-0 position-relative'
          >
            <Icon name='smartphone'/>
          </div>
        </div>
        <div>
          <h5>Aplicativo para Android e iOS</h5>
          <span className='text-small text-medium-gray'>
            Acesse informações dos seus clientes
            <br/>pelo celular, em qualquer lugar!
          </span>
        </div>
      </div>
      <div className='d-flex flex-column align-items-end'>
        <h5 className='me-1 mb-1'>Grátis para o seu plano</h5>
        <Button
          variant='outline-primary'
          size='sm'
          className='mb-1 width-4 align-items-center'
          as={ExternalLink}
          target={'_blank'}
          href='https://play.google.com/store/apps/details?id=br.com.agendor&hl=pt_BR&pli=1'
        >
          <Icon name='download' size='sm' className='me-1' />
          Android
        </Button>
        <Button
          variant='outline-primary'
          size='sm'
          className='mb-1 width-4 align-items-center'
          as={ExternalLink}
          target={'_blank'}
          href='https://apps.apple.com/br/app/agendor-crm-para-vendas/id804767405'
        >
          <Icon name='download' size='sm' className='me-1' />
          iOS
        </Button>
      </div>
    </div>
  );
}

export default MobileAppsAddonsCard;
