import React from 'react';

import banner from 'images/news/voip-banner.png';
import gif from 'images/news/voip-gpt-transliteration.gif';

import Icon from '@/components/Icon';
import Main from '@/components/Main';
import ExternalLink from '@/components/ExternalLink';
import { funnelsPath, helpUrl } from '@/routes';
import Button from '@/components/Button';

function Voip() {
  return (
    <Main overflow className='container-full-height'>
      <div className='news mx-auto rounded-2 border border-1 overflow-hidden pb-6'>
        <div className='mb-9'>
          <div className='h-100 d-flex'>
            <div className='bg-light p-7 w-50'>
              <div className='p-7'>
                <div className='w-25 height-2 mb-6'>
                  <Icon name='agendor-logo' className='text-primary' size='full' />
                </div>
                <h1 className='mb-5'>
                  Chegou seu Telefone Virtual Inteligente ✨
                </h1>
                <p className='text-dark-gray fs-4 mb-0'>
                  Transforme as ligações com seus clientes em insights valiosos.
                </p>
              </div>
            </div>
            <div className='w-50'>
              <img src={banner} className='img-fluid' />
            </div>
          </div>
        </div>

        <div className='px-8 pt-1'>
          <div className='mb-9'>
            <div className='d-flex gap-6'>
              <div className='w-50'>
                <img src={gif} className='rounded w-100' />
              </div>
              <div className='w-50'>
                <h2 className='mb-4'>
                  Economize tempo.
                  <br />
                  Melhore seu atendimento.
                  <br />
                  Faça mais vendas.
                </h2>
                <p className='text-dark-gray fs-4'>
                  Utilizamos ChatGPT e outras inteligências artificiais para automatizar o
                  processo de revisão de conversas, ganhando tempo para a equipe focar
                  no que importa: <strong>vender!</strong>
                </p>
              </div>
            </div>
          </div>

          <div className='pt-1 d-flex flex-column gap-6'>
            <div className='d-flex'>
              <Icon name='call' size='md' className='me-2' />
              <div>
                <h4 className='mb-2'>Faça ligações direto do Agendor, em poucos cliques.</h4>
                <p className='text-dark-gray mb-0'>
                  Esqueça a agenda de contatos e o telefone, agora é só fone de ouvido e internet.
                  Converse com seus clientes direto do seu computador, através do CRM.
                </p>
              </div>
            </div>

            <hr className='opacity-100' />

            <div className='d-flex'>
              <Icon name='ai' size='md' className='me-2' />
              <div>
                <h4 className='mb-2'>Receba um resumo inteligente das suas conversas.</h4>
                <p className='text-dark-gray mb-0'>
                  A ligação foi longa e cheia de informações? O Telefone Virtual
                  Inteligente resume tudo para você, destacando os pontos mais relevantes.
                </p>
              </div>
            </div>

            <hr className='opacity-100' />

            <div className='d-flex'>
              <Icon name='transcription' size='md' className='me-2 text-purple' />
              <div>
                <h4 className='mb-2'>Veja a transcrição completa das suas chamadas.</h4>
                <p className='text-dark-gray mb-0'>
                  Com o Telefone Virtual Inteligente, você não precisa mais fazer anotações.
                  Suas ligações são transcritas automaticamente enquanto
                  você se concentra na conversa.
                </p>
              </div>
            </div>

            <hr className='opacity-100' />

            <div className='d-flex'>
              <Icon name='task' size='md' className='me-2' />
              <div>
                <h4 className='mb-2'>
                  Tenha um relatório de ligações preenchido automaticamente.
                </h4>
                <p className='text-dark-gray mb-0'>
                  Uma atividade do tipo “Ligação” é salva automaticamente,
                  permitindo que os gestores obtenham relatórios de tarefas da equipe
                  sem trabalho manual.
                </p>
              </div>
            </div>

            <div className='d-flex'>
              <p className='text-dark-gray mb-0'>
                Dúvidas? Confira nosso artigo: <ExternalLink
                  target='_blank'
                  href={helpUrl('use-voip-gpt')}>
                  Como usar o Telefone Virtual Inteligente?
                </ExternalLink>
              </p>
            </div>

            <div className='d-flex justify-content-end'>
              <Button href={funnelsPath()}>Acesse sua conta</Button>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
}

export default Voip;
