import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Popover from '@/components/Popover';

const propTypes = {
  children: PropTypes.node.isRequired,
  term: PropTypes.string.isRequired,
  fetchMore: PropTypes.func.isRequired,
  footer: PropTypes.func.isRequired,
  ItemComponent: PropTypes.elementType.isRequired,
  formRef: PropTypes.any,
  popoverProps: PropTypes.object,
  hide: PropTypes.bool,
  isFetching: PropTypes.bool,
  canFetchMore: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired
  })),
  onSelect: PropTypes.func
};

const defaultProps = {
  popoverProps: {},
  formRef: null,
  hide: false,
  isFetching: false,
  canFetchMore: false,
  items: []
};

function Wrapper(props) {
  const {
    children, term, items, formRef,
    isFetching, canFetchMore, fetchMore,
    hide, footer, ItemComponent, popoverProps, onSelect
  } = props;

  const popoverRef = useRef(null);

  const footerProps = {
    isFetching,
    canFetchMore,
    fetchMore,
    term,
    items,
    isEmpty: !items.length
  };

  const popoverContent = ({ onHide: onHideOptions }) => (
    <div
      className='max-height-6 overflow-auto p-2'
    >
      {items.map((item) => (
        <ItemComponent
          key={`${item.type}-${item.id}`}
          item={item}
          term={term}
          onSelect={(data) => onSelect({ ...data, onHideOptions })}
          onHide={onHideOptions}
        />
      ))}

      {footer({ ...footerProps, onHideOptions })}
    </div>
  );

  return (
    <Popover
      ref={popoverRef}
      { ...popoverProps }
      content={popoverContent}
      hide={hide}
      container={formRef && formRef.current}
      rootClose
      trigger={['clickIn', 'focusIn']}
      rootCloseEvent='mousedown'
    >
      {children}
    </Popover>
  );
}

Wrapper.propTypes = propTypes;
Wrapper.defaultProps = defaultProps;

export default Wrapper;
