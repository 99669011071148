import { isBlank } from '@/utils';

export function countWhile(array, predicate) {
  let count = 0;

  for (const item of array) {
    const result = predicate(item);

    if (result) {
      count = count + 1;
    } else {
      break;
    }
  }

  return count;
}

export function compact(array) {
  return array.filter((item) => !isBlank(item));
}
