import React from 'react';
import classnames from 'classnames';
import Card from '@/components/Card';
import Skeleton from '@/components/Skeleton';

function CardSkeleton({ topBorder = false }) {
  return (
    <Card className={classnames(
      'px-6',
      'py-2',
      'border-1',
      'rounded-0',
      'border-start-0',
      'border-end-0',
      { 'border-top-0': !topBorder }
    )}>
      <Card.Body className='d-flex p-0 justify-content-between align-items-center'>
        <Card.FlexibleArea>
          <Card.Title>
            <div className='width-4'>
              <Skeleton />
            </div>
          </Card.Title>

          <Card.Content>
            <div className='width-3'>
              <Skeleton />
            </div>
          </Card.Content>
        </Card.FlexibleArea>
      </Card.Body>
    </Card>
  );
}

function FunnelBacklogSkeleton() {
  return (
    <div className='flex-grow-1'>
      <CardSkeleton />
      <CardSkeleton topBorder />
      <CardSkeleton topBorder />
    </div>
  );
}

export default FunnelBacklogSkeleton;
