import React from 'react';
import PropTypes from 'prop-types';
import Base from 'react-bootstrap/Popover';
import OverlayWrapper from '@/components/OverlayWrapper';
import OverlayClose from '@/components/OverlayClose';
import classnames from 'classnames';

const propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  content: PropTypes.func.isRequired,
  footer: PropTypes.any,
  children: PropTypes.node.isRequired,
  placement: PropTypes.oneOf([
    'top',
    'bottom',
    'left',
    'left-start',
    'left-end',
    'right',
    'right-start',
    'right-end',
    'top-start',
    'top-end',
    'bottom-start',
    'bottom-end'
  ]),
  rootClose: PropTypes.bool,
  closeButton: PropTypes.bool,
  contentClassName: PropTypes.string,
  showArrow: PropTypes.bool
};

const defaultProps = {
  className: null,
  title: null,
  footer: null,
  placement: 'right',
  trigger: ['click'],
  rootClose: true,
  closeButton: false,
  contentClassName: '',
  showArrow: false
};

const Popover = React.forwardRef(
  ({ className, contentClassName, children, title, content, footer, showArrow, ...props }, ref) => (
    <OverlayWrapper
      ref={ref}
      overlay={
        ({ onHide }) => (
          <Base
            className={classnames('shadow', 'overflow-visible', className)}
            style={{ '--bs-popover-arrow-height': showArrow ? '10px' : '0' }}
          >
            {props.closeButton && <OverlayClose onClose={onHide} />}

            {
              title &&
              <div className='popover-title px-5 py-4'>
                {title}
              </div>
            }

            <Base.Body className={contentClassName}>
              {content({ onHide })}
            </Base.Body>

            {
              footer &&
              <div className='popover-footer bg-light px-5 py-4'>
                {footer({ onHide })}
              </div>
            }
          </Base>
        )
      }
      { ...props }
    >
      {children}
    </OverlayWrapper>
  )
);

Popover.displayName = 'Popover';
Popover.propTypes = propTypes;
Popover.defaultProps = defaultProps;

export default Popover;
