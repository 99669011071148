import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import BacklogForm from '@/components/BacklogForm';
import Card from '@/components/Card';
import CardList from '@/components/CardList';
import DotList from '@/components/DotList';
import ExportButton from '@/components/ExportButton';
import SkeletonWrapper from '@/components/SkeletonWrapper';
import FunnelBacklogSkeleton from '@/components/FunnelBacklogSkeleton';
import { BACKLOG_ID } from '@/components/Funnel';
import { DealIcon } from '@/feature/automation';
import { selectElement } from '@/browser';
import { useDidUpdateEffect, useStoredState } from '@/hooks';
import { pluralize, isBlank } from '@/utils';
import Icon from '@/components/Icon';
import LoadingWrapper from '@/components/LoadingWrapper';
import EntityLink from '@/components/EntityLink';
import { useFunnelContext } from '@/contexts';
import { useInfiniteBacklogItems } from '@/api';

const propTypes = {
  funnelId: PropTypes.number.isRequired
};

function FunnelBacklog({ funnelId }) {
  const { getColumn, dispatch } = useFunnelContext();
  const [params, setParams] = useState({});
  const { items = [], totalCount } = getColumn(BACKLOG_ID);
  const { value: backlogId, store: setBacklogId } = useStoredState(
    `funnel_${funnelId}_backlog_id`,
    'organization'
  );
  const [backlogType, backlogFunnelId] = backlogId.split('-');

  const {
    isLoading, isFetching, isIdle, fetchNextPage, hasNextPage, isFetchingNextPage
  } = useInfiniteBacklogItems({
    funnelId,
    backlogId,
    params,
    config: {
      onSuccess: ({ pages }) => {
        const [{ meta: { count: itemsTotalCount } }] = pages;

        const column = {
          items: pages.flatMap((page) => page.data),
          totalCount: itemsTotalCount
        };
        dispatch({
          type: 'update_column',
          payload: { id: BACKLOG_ID, column }
        });
      }
    }
  });

  const [showSkeleton, setShowSkeleton] = useState(true);

  useDidUpdateEffect(() => {
    if (!isIdle && !isFetching) {
      setShowSkeleton(false);
    }
  }, [isFetching, isIdle]);

  const onChangeFilters = useCallback((filterParams) => {
    setParams({ funnelId, backlogId, ...filterParams });
  }, [funnelId, backlogId]);

  const exportButtonFilters = {
    funnel_id: [backlogFunnelId],
    deal_status_id: params.deal_status_id_eq,
    organization_id: params.organization_id_eq,
    person_id: params.person_id_eq,
    products_id: params.products_id_in,
    forgotten: params.forgotten,
    started_at_date: params.started_at_local_date_within,
    finished_at_date: params.finished_at_local_date_within,
    created_at_date: params.created_at_utc_date_within,
    users_id: params.user_id_in || params.owner_user_id_in,
    states: params.state_in,
    cities_id: params.city_id_in,
    area_codes: params.area_code_in,
    sectors_id: params.sector_id_in,
    origins_id: params.lead_origin_id_in,
    teams_id: params.team_id_in,
    imports_id: params.import_id_in,
    categories_id: params.category_id_in,
    deals_parent_funnel_id: params.exclude_duplicated ? funnelId : null,
    without_deals: params.without_deals,
    custom_fields: params.custom_fields
  };


  return (
    <div className={classnames(
      'backlog-column',
      'd-flex',
      'flex-column',
      'flexible-height',
      'position-relative',
      'bg-white',
      'border-start'
    )}>
      <div className='d-flex align-items-center px-4 pt-6 pb-5'>
        <h4>Lista de contatos e negócios</h4>
      </div>

      <BacklogForm
        backlogId={backlogId}
        funnelId={funnelId}
        onChangeBacklogId={setBacklogId}
        name={`backlog-form.${funnelId}`}
        onSubmit={onChangeFilters}
      />

      {
        !isBlank(totalCount) && !isLoading && (
          <div className='d-flex align-items-center justify-content-between mb-3 px-4'>
            <span className='me-2 text-dark-gray text-small'>
              Exibindo{' '}
              <span className='fw-bold'>
                {totalCount} {pluralize('resultado', totalCount)}
              </span>
            </span>

            <ExportButton
              variant='transparent-light'
              size='sm'
              tooltipPlacement='right'
              tooltipText='Exporte esta lista em formato de planilha de Excel (.xlsx)'
              exportEntity={backlogType}
              filters={exportButtonFilters}
              className='text-nowrap px-0'
            />
          </div>
        )
      }

      <SkeletonWrapper
        isLoading={showSkeleton}
        SkeletonComponent={FunnelBacklogSkeleton}
      >
        <LoadingWrapper isLoading={isLoading} className='flex-grow-1'>
          <Droppable
            droppableId='backlog'
            type='card'
            isDropDisabled
          >
            {(provided) => (
              <CardList
                items={items}
                renderItem={renderDraggable}
                innerRef={provided.innerRef}
                innerProps={provided.droppableProps}
                innerPlaceholder={provided.placeholder}
                fetchMore={fetchNextPage}
                canFetchMore={hasNextPage}
                isFetchingMore={Boolean(isFetchingNextPage)}
              />
            )}
          </Droppable>
        </LoadingWrapper>
      </SkeletonWrapper>
    </div>
  );
}

function renderDraggable(card, index) {
  return (
    <Draggable
      draggableId={card.id}
      index={index}
      key={card.id}
      isDragDisabled={card.isDragDisabled}
    >
      {(provided, snapshot) => (
        <div
          className={classnames(
            'mb-2',
            'draggable',
            'hover-parent',
            {
              'disabled opacity-50': card.isDragDisabled,
              'transition-delay-short':
                !snapshot.isDragging &&
                provided.draggableProps.style.transform
            }
          )}
          ref={provided.innerRef}
          { ...provided.draggableProps }
          { ...provided.dragHandleProps }
        >
          {renderCard(card)}
        </div>
      )}
    </Draggable>
  );
}

export function renderCard(card) {
  const [cardType, cardId] = card.id.split('-');
  const entity = { type: cardType, id: cardId };
  const selectable = cardType === 'organization' || cardType === 'person';

  return (
    <Card className={classnames(
      'px-3',
      'py-2',
      'mx-4',
      'hover-child-bg-lighter-blue',
      'border-md',
      'border-light',
      'rounded'
    )}>
      <Card.Body className='d-flex p-0 justify-content-between align-items-center'>
        <Card.Status className='ms-0 me-2'>
          <Icon name={cardType} size='lg' className='text-medium-gray' />
        </Card.Status>

        <Card.FlexibleArea className='flex-fill'>
          <Card.Title>
            <EntityLink entity={entity} origin='funnel_backlog'>
              <div className='d-flex'>
                <DealIcon deal={card} />

                {card?.title}
              </div>
            </EntityLink>
          </Card.Title>

          <Card.Content>
            <DotList
              items={Object.values(card.details).filter((detail) => detail)}
              selectable={selectable}
              onClick={selectElement}
              truncate
            />
          </Card.Content>
        </Card.FlexibleArea>
      </Card.Body>
    </Card>
  );
}

FunnelBacklog.propTypes = propTypes;

export default FunnelBacklog;
