import React from 'react';
import Voip from '@/pages/news/voip';
import Whatsapp from '@/pages/news/whatsapp';
import ABTest from '@/components/ABTest';

function News() {
  return (
    <ABTest name='news'>
      <ABTest.Variant name='A'>
        <Voip />
      </ABTest.Variant>
      <ABTest.Variant name='B'>
        <Whatsapp />
      </ABTest.Variant>
    </ABTest>
  );
}

export default News;
