import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Row, Col } from 'react-bootstrap';
import Icon from '@/components/Icon';
import Card from '@/components/Card';
import MainTitle from '@/components/LeadsGeneration/MainTitle';
import StepperHeaderMini from '@/components/StepperHeaderMini';

const propTypes = {
  children: PropTypes.node.isRequired,
  stepItems: PropTypes.array,
  currentStepIndex: PropTypes.number
};

const defaultProps = {
  stepItems: [],
  currentStepIndex: null
};

function SearchStepWrapper({ children, stepItems, currentStepIndex }) {
  return (
    <div className='d-flex flex-column h-100'>
      <div className='d-flex'>
        <MainTitle />

        <div className='ms-auto'>
          <StepperHeaderMini
            headerClassName='d-flex justify-content-center align-items-center fw-bold mt-4'
            headerLabels={stepItems}
            currentStep={currentStepIndex}
          />
        </div>
      </div>

      <hr className='mt-2 mb-4' />

      <Row className='px-3 flex-grow-1'>
        <Col className='px-0' sm='3'>
          {
            stepItems.map(({ icon, label }, index) => {
              const isActiveStep = currentStepIndex === index;

              return (
                <div key={index} className={
                  classnames(
                    'd-flex',
                    'py-4',
                    'px-3',
                    'mb-4',
                    'text-dark-gray',
                    { 'border-start border-primary': isActiveStep }
                  )
                }>
                  <Icon name={icon} className={
                    classnames('me-3', { 'text-primary': isActiveStep })
                  }
                  />

                  <h4 className={
                    classnames(
                      'fw-bold d-inline-block',
                      { 'text-primary': isActiveStep }
                    )
                  }>
                    {`${index + 1}. ${label}`}
                  </h4>
                </div>
              );
            })
          }
        </Col>

        <Col className='h-100 px-0' sm='9'>
          <Card className='p-4 border-light leads-generation-card-height'>
            <div className={
              classnames('h-100 w-100', { 'overflow-y-auto': currentStepIndex === 0 })
            }>
              {children}
            </div>
          </Card>
        </Col>
      </Row>
      <div
        id='leads-generation-footer'
        className='w-100'
      />
    </div>
  );
}

SearchStepWrapper.propTypes = propTypes;
SearchStepWrapper.defaultProps = defaultProps;

export default SearchStepWrapper;
