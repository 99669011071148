import React from 'react';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import Icon, { ICONS } from '@/components/Icon';

const propTypes = {
  /** Flag que indica se o collapse está aberto ou não. */
  open: PropTypes.bool.isRequired,
  /** Nome do ícone a ser usado quando o collapse está aberto. */
  openIcon: PropTypes.oneOf(ICONS),
  /** Texto do label a ser usado quando o collapse está aberto. */
  openLabel: PropTypes.string.isRequired,
  /** Nome do ícone a ser usado quando o collapse está fechado. */
  closedIcon: PropTypes.oneOf(ICONS),
  /** Texto do label a ser usado quando o collapse está aberto. */
  closedLabel: PropTypes.string.isRequired,
  /** Callback chamado ao clicar no botão. */
  onToggle: PropTypes.func.isRequired
};

const defaultProps = {
  openIcon: 'minus',
  closedIcon: 'plus'
};

function CollapseToggle(props) {
  const { open, openIcon, closedIcon, openLabel, closedLabel, onToggle } = props;

  let icon = closedIcon;
  let label = closedLabel;

  if (open) {
    icon = openIcon;
    label = openLabel;
  }

  return (
    <Button
      variant='link'
      className='p-0 fw-bold'
      onClick={onToggle}
    >
      {icon && <Icon name={icon} className='me-1' />}

      <span>{label}</span>
    </Button>
  );
}

CollapseToggle.propTypes = propTypes;
CollapseToggle.defaultProps = defaultProps;

export default CollapseToggle;
