import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { applyMask } from '@/masks';
import { useStripe } from '@stripe/react-stripe-js';

const propTypes = {
  bankSlipName: PropTypes.string.isRequired,
  bankSlipCpfCnpj: PropTypes.string,
  isStripeComponent: PropTypes.bool,
  setPaymentData: PropTypes.func
};

function BankSlip({ bankSlipName, bankSlipCpfCnpj, isStripeComponent, setPaymentData }) {
  const stripe = useStripe();

  useEffect(() => {
    if (isStripeComponent) {
      setPaymentData((prev) => ({ ...prev, stripe }));
    }
  }, [isStripeComponent]);


  return (
    <div className='text-small'>
      <div className='d-flex flex-column'>
        <span className='fw-bold'>Dados que vão aparecer no boleto</span>

        <span className='text-dark-gray'>{bankSlipName}</span>
        <span className='text-dark-gray'>{applyMask(bankSlipCpfCnpj, 'cpf_cnpj')}</span>
      </div>

      <hr className='w-100 my-3 text-dark-gray' />

      <span className='form-label mb-1'>Informações importantes:</span>

      <ul className='text-dark-gray'>
        <li>Seu pagamento será confirmado em até 2 dias uteis após o pagamento do Boleto.</li>

        <li>
          Caso o pagamento não seja realizado até a data de vencimento,
          é necessário emitir segunda via ou alterar a forma de pagamento.
        </li>

        <li>
          O boleto estará disponível na sessão de histórico de cobranças
          no menu de configurações do Agendor.
        </li>
      </ul>
    </div>
  );
}

BankSlip.propTypes = propTypes;

export default BankSlip;
