export function canExport({ user, data }) {
  let effect, reason, privilege;

  switch (data.entity) {
    case 'organization':
      privilege = user.privileges.organizations.export;
      break;

    case 'person':
      privilege = user.privileges.people.export;
      break;

    case 'deal':
      privilege = user.privileges.deals.export;
      break;

    case 'product':
      privilege = user.admin;
      break;

    default:
      privilege = false;
  }

  if (privilege) {
    effect = 'allow';
  } else {
    effect = 'disable';
    reason =
      'Você não possui privilégio para realizar exportação. ' +
      'Contate o administrador de sua conta.';
  }

  return { effect, reason };
}
