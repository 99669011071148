import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Avatar from '@/components/Avatar';
import Truncate from '@/components/Truncate';
import { legacyAccountUrl, legacyUserUrl } from '@/routes';
import { useAuth } from '@/lib/auth';
import ExternalLink from '@/components/ExternalLink';

function HeaderUser() {
  const { user } = useAuth();

  if (!user) {
    return null;
  }

  return (
    <div
      className='d-flex align-self-center align-items-center me-0 me-xl-6'
    >
      <ExternalLink href={legacyUserUrl(user.id)} className='me-2'>
        <Avatar
          name={user.name}
          url={user.avatarUrl}
          size='lg'
          tooltip={false}
        />
      </ExternalLink>

      <Navbar.Text className='d-none d-xl-flex flex-column max-width-4'>
        <ExternalLink href={legacyUserUrl(user.id)} className='text-white'>
          <Truncate disableTooltip>{user.name}</Truncate>
        </ExternalLink>

        <ExternalLink href={legacyAccountUrl()} className='text-gray'>
          <Truncate disableTooltip>{user.account.name}</Truncate>
        </ExternalLink>
      </Navbar.Text>
    </div>
  );
}

export default HeaderUser;
