import React from 'react';
import EntityTitle from '@/components/EntityModal/EntityTitle';
import LegacyButton from '@/components/EntityModal/LegacyButton';
import Menu from '@/components/EntityModal/Menu';
import { usePerson, useOnUpdate, useOnDelete } from '@/contexts/personModal';
import { canEditPerson } from '@/policies';
import { personEditPath } from '@/routes';
import SelectOwnerUser from '@/components/EntityModal/SelectOwnerUser';

function Header() {
  const person = usePerson();
  const onUpdate = useOnUpdate();
  const onDelete = useOnDelete();

  return (
    <div className='mb-4 bg-white rounded p-4 mb-4'>
      <div className='mb-4 d-flex justify-content-between'>
        <EntityTitle
          className='width-5'
          onUpdate={onUpdate}
          entity={{ title: person.name }}
        />
        <LegacyButton id={person.id} entityType='person' />
      </div>
      <div className='d-flex justify-content-between'>
        <SelectOwnerUser
          ownerUser={person.ownerUser}
          canEditPolicy={canEditPerson}
          onUpdate={onUpdate}
        />
        <Menu
          entity={person}
          entityType='person'
          loading={false}
          onDelete={onDelete}
          editPath={personEditPath(person.id)}
        />
      </div>
    </div>
  );
}

export default Header;
