import React from 'react';
import PropTypes from 'prop-types';
import Base from 'react-bootstrap/Tooltip';
import classnames from 'classnames';
import OverlayWrapper from '@/components/OverlayWrapper';

const propTypes = {
  className: PropTypes.string,
  content: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right', 'right-start', 'left-start']),
  trigger: PropTypes.arrayOf(PropTypes.oneOf(['hover', 'focus', 'click', 'none']))
};

const defaultProps = {
  className: null
};

function Tooltip({ className, content, children, ...props }) {
  return (
    <OverlayWrapper
      flip
      overlay={
        () => (
          <Base className={classnames('fw-bold', className)}>
            {content}
          </Base>
        )
      }
      { ...props }
    >
      {children}
    </OverlayWrapper>
  );
}

Tooltip.propTypes = propTypes;
Tooltip.defaultProps = defaultProps;

export default Tooltip;
