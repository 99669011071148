import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@/components/Tooltip';
import Icon from '@/components/Icon';

const propTypes = {
  deal: PropTypes.object.isRequired,
  size: PropTypes.oneOf(['sm', 'md'])
};

const defaultProps = {
  deal: {},
  className: 'text-primary me-1',
  size: 'sm'
};


function DealIcon({ deal, className, size }) {
  if (!deal.createdByAutomation) {
    return null;
  }

  return (
    <Tooltip content='Criado por automação'>
      <div className='d-inline-flex align-items-start'>
        <Icon
          name='lightning'
          className={className}
          size={size}
        />
      </div>
    </Tooltip>
  );
}

DealIcon.propTypes = propTypes;
DealIcon.defaultProps = defaultProps;

export default DealIcon;
