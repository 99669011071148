import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import EntityIcon from '@/components/EntityIcon';
import { getString } from '@/utils';

const propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape({
    question: PropTypes.string,
    customField: PropTypes.shape({
      name: PropTypes.string
    }),
    entity: PropTypes.oneOf(['organization', 'person', 'deal']),
    defaultField: PropTypes.string
  }),
  icon: PropTypes.bool,
  question: PropTypes.bool,
  light: PropTypes.bool
};

const defaultProps = {
  className: null,
  icon: false,
  question: false,
  light: false
};

function RequiredFieldLabel(props) {
  const { className, item, icon, question, light } = props;

  if (!item) {
    return '';
  }

  let text;
  if (question && item.question) {
    text = item.question;
  } else {
    text = item.customField?.name ??
      getString(['attributes', item.entity, item.defaultField], 'Desconhecido');
  }

  return (
    <span className={classnames(className)}>
      {icon && (
        <EntityIcon type={item.entity} className='me-1' light={light} />
      )}

      <span className='fs-5'>{text}</span>
    </span>
  );
}

RequiredFieldLabel.propTypes = propTypes;
RequiredFieldLabel.defaultProps = defaultProps;

export default RequiredFieldLabel;
