import React from 'react';
import PropTypes from 'prop-types';
import Link from '@/components/Link';
import Icon from '@/components/Icon';
import { dealPath, organizationPath, personPath } from '@/routes';

const propTypes = {
  activity: PropTypes.shape({
    deal: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string
    }),
    organization: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    }),
    person: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  }).isRequired,
  entity: PropTypes.shape({
    id: PropTypes.number
  }).isRequired
};

function OriginalHeader(props) {
  const {
    activity,
    entity: currentEntity
  } = props;


  const getActivityEntityType = () => {
    if (activity.deal) {
      return 'deal';
    }
    if (activity.organization) {
      return 'organization';
    }
    if (activity.person) {
      return 'person';
    }
  };

  const getActivityEntityData = () => {
    const entityType = getActivityEntityType();
    const entityData = activity[entityType] || {};

    const entity = {
      deal: {
        path: dealPath(entityData.id),
        icon: 'deal',
        name: entityData.title
      },

      organization: {
        path: organizationPath(entityData.id),
        icon: 'organization',
        name: entityData.name
      },

      person: {
        path: personPath(entityData.id),
        icon: 'person',
        name: entityData.name
      }
    };

    return { ...entity[entityType], id: entityData.id };
  };

  const originalEntity = getActivityEntityData();

  if (activity.deal && (originalEntity.id === currentEntity.id)) {
    return null;
  }

  return (
    <div className='px-4 py-1 border-bottom border-1 text-small'>
      <Link
        href={originalEntity.path}
        className='fw-bold link-dark-gray hover-text-primary'
      >
        <Icon name={originalEntity.icon} size='sm' className='me-2' />

        <span>{originalEntity.title || originalEntity.name}</span>
      </Link>
    </div>
  );
}

OriginalHeader.propTypes = propTypes;

export default OriginalHeader;
