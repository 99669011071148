import React, { useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Card from '@/components/Card';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import Icon from '@/components/Icon';
import { FunnelSelect, RadioButton } from '@/components/Inputs';
import Footer from '@/components/LeadsGeneration/Footer';
import Tooltip from '@/components/Tooltip';
import illustrationDataImage from 'images/leads_generation/illustration-data.png';
import { useLeadsGenerationContext } from '@/contexts';
import { useAuth } from '@/lib/auth';

function LeadsGenerationFunnel() {
  const { user: { account: { defaultFunnel } } } = useAuth();
  const {
    funnelData,
    setFunnelData,
    selectedLeads,
    nextStep,
    prevStep,
    callMixpanelEvent
  } = useLeadsGenerationContext();

  const funnelSelectionOptionRef = useRef();

  const handleFunnelSelectFocus = () => funnelSelectionOptionRef.current?.click();

  const [selectedFunnelName, setSelectedFunnelName] = useState();

  const handleSubmit = ({
    funnel_selection: funnelSelection,
    funnel_id: funnelId
  }) => {
    setFunnelData({
      funnel_selection: funnelSelection,
      funnel_id: funnelId,
      funnel_name: selectedFunnelName
    });
  };

  const onClickNext = () => {
    callMixpanelEvent('trackLeadsGenerationChooseFunnel');

    nextStep();
  };

  return (
    <>
      <div className='text-dark-gray text-center flex-grow-1'>
        <img src={illustrationDataImage}></img>

        <h1>
          Você escolheu gerar {selectedLeads.length} leads
        </h1>

        <div className='my-1'>
          Serão criadas {selectedLeads.length} empresas e {selectedLeads.length} negócios.
          Os contatos dos decisores serão <br />
          incluídos em notas nos negócios para não poluir sua base de dados.
        </div>

        <div className='text-small mb-5'>
          Caso alguma empresa já exista, ela será atualizada sem substituir nenhum dado já existente
        </div>

        <Row className='d-flex justify-content-center'>
          <Col sm={7}>
            <Card className='p-4 border-lighter-gray'>
              <div className='d-flex flex-column w-100'>
                <div className='d-flex align-items-center mb-4 pb-2 border-bottom border-1'>
                  <div className='bg-lighter-gray rounded me-2'>
                    <Icon name='task' className='m-1 text-medium-gray' />
                  </div>
                  <h4>Em qual funil deseja criar negócios?</h4>
                </div>
                <Form
                  initialValues={funnelData}
                  onSubmit={handleSubmit}
                >
                  {({ setFieldValue, debouncedSubmitForm }) => {
                    const handleFunnelSelectChange = (_, { option }) => {
                      setSelectedFunnelName(option?.label);
                      debouncedSubmitForm();
                    };

                    const handleOptionButtonChange = (value) => {
                      const newValue = value === 'funnel_id' ? defaultFunnel.id : null;

                      setSelectedFunnelName(defaultFunnel.name);
                      setFieldValue('funnel_id', newValue);
                      debouncedSubmitForm();
                    };

                    return (
                      <div className='d-flex justify-content-around'>
                        <div className='d-flex align-items-center px-4'>
                          <FormField
                            as={RadioButton}
                            name='funnel_selection'
                            id='funnel_selection_leads'
                            label={<span className='text-small'>Funil de Leads</span>}
                            radioValue='leads'
                            noFlex
                            checkClassName='mb-0'
                            onChange={handleOptionButtonChange}
                          />
                          <Tooltip
                            placement='bottom'
                            content='Os negócios serão criados no Funil de Leads.
                            Se ainda não existir este funil, nós criaremos.'
                          >
                            <div>
                              <Icon className='text-dark-gray ms-1' name='info' size='sm'/>
                            </div>
                          </Tooltip>
                        </div>

                        <div className='d-flex align-items-center width-5 px-4'>
                          <FormField
                            as={RadioButton}
                            name='funnel_selection'
                            id='funnel_selection_funnel_id'
                            label=''
                            radioValue='funnel_id'
                            checkClassName='mb-0'
                            noFlex
                            ref={funnelSelectionOptionRef}
                            onChange={handleOptionButtonChange}
                          />
                          <FormField
                            as={FunnelSelect}
                            className='w-100 text-small'
                            name='funnel_id'
                            source='funnel_selection'
                            target='funnelSelection'
                            label=''
                            placeholder='Selecione outro funil'
                            onFocus={handleFunnelSelectFocus}
                            onChange={handleFunnelSelectChange}
                          />
                        </div>
                      </div>
                    );
                  }}
                </Form>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <Footer
        buttonNextText='Avançar para pagamento'
        onClickPrev={prevStep}
        onClickNext={onClickNext}
      />
    </>
  );
}

export default LeadsGenerationFunnel;
