import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Popover from '@/components/Popover';
import MaskedInput from '@/components/Inputs/MaskedInput';
import { toDateString, fromDMA } from '@/date';
import { usePopover } from '@/contexts';
import DatePicker from '@/components/DatePicker';
import DatePresets from '@/components/DatePresets';

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  variant: PropTypes.oneOf(['light', 'white', 'transparent']),
  placement: PropTypes.oneOf([
    'top',
    'bottom',
    'left',
    'left-start',
    'left-end',
    'right',
    'right-start',
    'right-end',
    'bottom-start',
    'bottom-end'
  ])
};

const defaultProps = {};
function DatePickerInput(props) {
  const { name, value, onChange, actionButtons, initialShow, placement, ...inputProps } = props;

  const { container } = usePopover();

  const handleChange = (date) => {
    if (onChange) {
      onChange(date);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      document.body.click();
    }
  };

  const handleClickDate = (date, onHide) => {
    handleChange(toDateString(date));
    onHide();
  };

  const parsedValue = useMemo(() => {
    if (!value) {
      return;
    }

    const dateValue = fromDMA(value);

    return dateValue;
  }, [value]);


  return (
    <Popover
      container={container && container.current}
      className='max-width-7'
      flip
      initialShow={initialShow}
      placement={placement}
      content={({ onHide }) => (
        <>
          <DatePicker
            mode='uncontrolled'
            numberOfMonths={1}
            month={parsedValue}
            onDayClick={(date) => handleClickDate(date, onHide)}
            selected={parsedValue}
            className='rdp'
            fixedWeeks
          />
          <DatePresets handleChange={handleChange} onHide={onHide} />
        </>
      )}
    >
      <div>
        <MaskedInput
          name={name}
          maskType='date'
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          { ...inputProps }
        />
      </div>
    </Popover>
  );
}


DatePickerInput.propTypes = propTypes;
DatePickerInput.defaultProps = defaultProps;

export default DatePickerInput;
