import React from 'react';
import PropTypes from 'prop-types';
import Base from 'react-bootstrap/Collapse';

const propTypes = {
  children: PropTypes.node.isRequired,
  in: PropTypes.bool
};

const defaultProps = {
  in: false
};

const Collapse = React.forwardRef(
  ({ children, ...props }, ref) => (
    <Base { ...props } ref={ref}>
      {children}
    </Base>
  )
);

Collapse.propTypes = propTypes;
Collapse.defaultProps = defaultProps;
Collapse.displayName = 'Collapse';

export default Collapse;
