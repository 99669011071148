import { useQuery, useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { get, post, patch, _delete } from '@/lib/fetch';

export const activitiesKeys = {
  details: () => ['detail'],
  detail: (activityId) => [...activitiesKeys.details(), activityId],
  infiniteAll: ['infinite-activities'],
  infiniteLists: () => [...activitiesKeys.infiniteAll, 'list'],
  infiniteList: (params) => ([...activitiesKeys.infiniteLists(), params])
};

export async function getActivities(params = {}) {
  const { data } = await get('/activities', params);

  return data;
}

export async function getActivity(activityId) {
  const { data } = await get(`/activities/${activityId}`);

  return data;
}

export async function createActivity(params = {}) {
  const { data } = await post('/activities', params);

  return data;
}

export async function updateActivity({ activityId, params = {} }) {
  const { data } = await patch(`/activities/${activityId}`, params);

  return data;
}

export async function deleteActivity({ activityId }) {
  const { data } = await _delete(`/activities/${activityId}`);

  return data;
}

export function useCreateActivity({ config = {}, listParams = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = ({ data: newData }) => {
    const key = activitiesKeys.infiniteList(listParams);
    const currentData = queryClient.getQueryData(key);
    currentData?.pages[0]?.data.unshift(newData);
    queryClient.setQueryData(key, currentData);
  };

  return useMutation(
    createActivity,
    {
      onSuccess,
      ...config
    }
  );
}

export function useUpdateActivity({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await queryClient.invalidateQueries(activitiesKeys.infiniteLists);
  };

  return useMutation(
    updateActivity,
    {
      onSuccess,
      ...config
    }
  );
}

export function useDeleteActivity({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await queryClient.invalidateQueries(activitiesKeys.infiniteLists);
  };

  return useMutation(
    deleteActivity,
    {
      onSuccess,
      ...config
    }
  );
}

export function useInfiniteActivities({ params = {}, config = {} } = {}) {
  return useInfiniteQuery(
    activitiesKeys.infiniteList(params),
    ({ pageParam: page = 1 }) => getActivities({ ...params, page }),
    {
      ...config,
      getNextPageParam: (lastPage) => lastPage.meta.next
    }
  );
}

export function useActivity({ activityId, config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: activitiesKeys.detail(activityId),
    queryFn: () => getActivity(activityId)
  });
}
