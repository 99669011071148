import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Header from '@/components/Header';
import HeaderWarning from '@/components/HeaderWarning';
import { useAuth } from '@/lib/auth';

const propTypes = {
  children: PropTypes.node
};

const defaultProps = {};

function Layout({ children }) {
  const { user } = useAuth();

  return (
    <div className='d-flex flex-column container-full-height'>
      {user.account.newBilling && <HeaderWarning />}

      <Header />

      <Container
        fluid
        className='d-flex flex-column flex-grow-1 px-0 overflow-hidden'
      >
        {children}
      </Container>
    </div>
  );
}

Layout.propTypes = propTypes;
Layout.defaultProps = defaultProps;

export default Layout;
