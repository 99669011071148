import { useQuery } from 'react-query';
import { get } from '@/lib/fetch';
import { accessoryCallCache } from '@/utils';

export const citiesKeys = {
  all: ['cities'],
  lists: () => [...citiesKeys.all, 'list'],
  list: (params) => [...citiesKeys.lists(), params]
};

export async function getCities(params = {}) {
  const { data } = await get('/cities', params);

  return data;
}

export function useCities({ params = {}, config = {} }) {
  return useQuery({
    ...accessoryCallCache,
    ...config,
    queryKey: citiesKeys.list(params),
    queryFn: () => getCities(params)
  });
}
