import React, { useState } from 'react';
import classnames from 'classnames';
import { useAlert } from 'react-alert';
import { getValidationErrors } from '@/utils';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';

import Dropdown from '@/components/Dropdown';
import Button from '@/components/Button';
import ActivitiesTemplatesModal from
  '@/components/EntityModal/Deal/Activities/DealActivitiesTemplatesModal';
import { isRootCloseOnSubOverlay } from '@/components/OverlayWrapper';
import Icon from '@/components/Icon';
import { useActivityTemplates, useCreateActivityTemplate, useDeleteActivityTemplate } from '@/api';
import { useTextArea } from '@/components/Inputs/TextArea';
import Popover from '@/components/Popover';
import ActivityTemplatePreview from
  '@/components/EntityModal/Deal/Activities/ActivityTemplatePreview';
import APIErrorMessage from '@/components/APIErrorMessage';
import ConfirmationModal from '@/components/ConfirmationModal';
import strings from '@/strings';

function DealActivitiesTemplatesDropdown({ navType, ...props }) {
  const alert = useAlert();
  const { user } = useAuth();
  const tracker = useTracking();
  const activityType = { activityType: strings.models.activity.type[navType] };

  const textArea = useTextArea();
  const onSelect = (item) => {
    textArea.update((value) => `${value}\n\n${item.text}`.trim());

    tracker.trackActivityTemplateSelected({
      user,
      activityTemplateId: item.id,
      entity: activityType
    });
  };

  /**
   * Por padrão, o Dropdown é fechado ao clicar fora dele, porém, para este
   * caso, ele não deve fechar ao clicar no modal aberto por ele (que no HTML
   * está fora dele).
   * Por isso, foi adicionado o controle manual do estado do Dropdown, que
   * desconsidera os eventos disparados de dentro do modal (considerado como
   * "sub-overlay" como foi feito no OverlayWrapper).
   * Talvez esse comportamento possa ser movido para o Dropdown base.
   */
  const [showDropdown, setShowDropdown] = useState(false);
  const handleToggleDropdown = (newShow, meta) => {
    if (isRootCloseOnSubOverlay(meta.originalEvent)) {
      return;
    }

    setShowDropdown(newShow);

    if (newShow) {
      tracker.trackActivityTemplateButtonClicked({
        user,
        entity: activityType
      });
    }
  };
  const closeDropdown = () => {
    setShowDropdown(false);
  };

  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const handleOnCancel = () => closeModal();

  const createMutation = useCreateActivityTemplate();

  const onSubmit = (params, { setFieldError, setSubmitting }) => {
    createMutation.mutate(params, {
      onSuccess: ({ data }) => {
        closeModal();
        closeDropdown();

        alert.show(
          'Modelo de anotação cadastrado com sucesso!',
          { variant: 'success', timeout: 5000 }
        );

        tracker.trackActivityTemplateCreated({
          user,
          activityTemplateId: data.id,
          entity: activityType
        });

        onSelect(data);
      },
      onError: (err) => {
        const errors = getValidationErrors(err, 'activity_templates');

        Object.entries(errors).forEach(([key, message]) => {
          setFieldError(key, message);
        });

        setSubmitting(false);

        alert.show(
          <APIErrorMessage err={err} resource='activity_templates' action='create' />,
          { variant: 'danger', timeout: 5000 }
        );
      }
    });
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const openDeleteModal = (item) => {
    setSelectedItem(item);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setSelectedItem(null);
    setShowDeleteModal(false);
  };

  const deleteMutation = useDeleteActivityTemplate();
  const isDeleting = deleteMutation.isLoading;

  const handleDelete = () => {
    deleteMutation.mutate({ id: selectedItem.id }, {
      onSuccess: () => {
        alert.show(
          'Modelo excluído!',
          { variant: 'success', timeout: 5000 }
        );
        closeDeleteModal();

        tracker.trackActivityTemplateDeleted({
          user,
          activityTemplateId: selectedItem.id,
          entity: activityType
        });
      },
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='activity_templates' action='delete' />,
          { variant: 'danger', timeout: 5000 }
        );
        closeDeleteModal();
      }
    });
  };

  const { data } = useActivityTemplates();
  const isEmpty = data?.data.length === 0;

  return (
    <Dropdown
      show={showDropdown}
      onToggle={handleToggleDropdown}
      className='position-absolute bottom-0 end-0 mx-5 my-1'
      { ...props }
    >
      <Dropdown.Toggle
        align='end'
        variant='light'
        className='text-dark-gray p-1'
      > + Modelos
      </Dropdown.Toggle>

      <Dropdown.Menu
        renderOnMount
        className='width-5'
      >
        <Dropdown.ItemText>
          <h5>Modelos de anotação</h5>
        </Dropdown.ItemText>

        <div className='max-height-5 overflow-auto'>
          {
            isEmpty
              ? (
                <Dropdown.ItemText className='text-medium-gray py-0'>
                  Nenhum modelo criado.
                </Dropdown.ItemText>
              )
              : data?.data.map((item) => (
                <Popover
                  key={item.id}
                  trigger={['hover']}
                  content = {() => <ActivityTemplatePreview data={item} />}
                >
                  <Dropdown.Item
                    as='div'
                    key={item.id}
                    className={classnames(
                      'border-bottom',
                      'border-1',
                      'border-light',
                      'text-wrap',
                      'word-wrap',
                      'py-2',
                      'd-flex',
                      'justify-content-between',
                      'align-items-center',
                      'cursor-pointer',
                      'hover-parent'
                    )}
                    onClick={() => onSelect(item)}
                  >
                    <span className='flexible-width'>
                      {item.name}
                    </span>
                    <Button
                      variant='link'
                      className='p-0 opacity-0 hover-child-opacity-100 flex-shrink-0'
                      onClick={(event) => {
                        event.stopPropagation();
                        openDeleteModal(item);
                      }}
                    >
                      <Icon
                        name='delete'
                        className='text-danger'
                      />
                    </Button>
                  </Dropdown.Item>
                </Popover>
              ))
          }
        </div>

        <Dropdown.ItemText>
          <Button
            variant='outline-dark-gray'
            className='w-100'
            size='sm'
            onClick={handleOpenModal}
          >
            + Adicionar novo modelo
          </Button>
        </Dropdown.ItemText>
      </Dropdown.Menu>

      <ActivitiesTemplatesModal
        show={showModal}
        onCancel={handleOnCancel}
        onSubmit={onSubmit}
      />

      <ConfirmationModal
        confirmationText='Excluir'
        show={showDeleteModal}
        onConfirm={handleDelete}
        onHide={closeDeleteModal}
        className='sub-overlay'
        loading={isDeleting}
        confirmationLoadingText='Excluindo...'
        disabled={isDeleting}
      >
        <ConfirmationModal.Title>
          Deseja excluir esse modelo?
        </ConfirmationModal.Title>
        <ConfirmationModal.Info>
          Essa ação não poderá ser desfeita.
        </ConfirmationModal.Info>
      </ConfirmationModal>
    </Dropdown>
  );
}

export default DealActivitiesTemplatesDropdown;
