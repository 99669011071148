import React from 'react';
import classnames from 'classnames';
import Skeleton from '@/components/Skeleton';

function ColumnSkeleton() {
  return (
    <div className={classnames(
      'd-flex',
      'flex-column',
      'flexible-height',
      'position-relative',
      'width-5',
      'flex-shrink-0'
    )}>
      <div className={classnames(
        'border-top',
        'opacity-10',
        'hover-child-opacity-100',
        'border-primary'
      )} />

      <div className='flex-shrink-0 mt-3 mb-2 pe-2'>
        <div className='width-3'>
          <Skeleton />
        </div>

        <div className='width-4'>
          <Skeleton />
        </div>
      </div>

      <div className='d-flex w-100 flex-grow-1 pe-5'>
        <Skeleton
          containerClassName='d-flex flex-grow-1'
          className='flex-grow-1'
        />
      </div>
    </div>
  );
}

function FunnelBoardSkeleton() {
  return (
    <div className={classnames(
      'flex-grow-1',
      'flexible-height',
      'd-flex',
      'align-items-stretch',
      'ps-8'
    )}>
      <ColumnSkeleton />
      <ColumnSkeleton />
      <ColumnSkeleton />
      <ColumnSkeleton />
      <ColumnSkeleton />
      <ColumnSkeleton />
    </div>
  );
}

export default FunnelBoardSkeleton;
