import React, { useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as Yup from 'yup';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import LoadingButton from '@/components/LoadingButton';
import Popover from '@/components/Popover';
import HookForm from '@/components/HookForm';
import HookProductsFields, {
  defaultValues as productDefaultValues,
  getInitialValues as getProductsInitialValues,
  schema as productsSchema
} from '@/components/Inputs/HookProductsFields';

const propTypes = {
  products: PropTypes.array,
  dealId: PropTypes.number,
  isEdit: PropTypes.bool,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  editButtonClassName: PropTypes.string
};

const defaultProps = {
  products: [],
  dealId: null,
  isEdit: false,
  onSubmit: () => {},
  disabled: false,
  editButtonClassName: ''
};

const schema = Yup.object().shape({
  entity_products: productsSchema
});

const defaultValues = {
  entity_products: [productDefaultValues]
};

function ProductsFormPopover({
  products, dealId, isEdit, onSubmit, disabled, editButtonClassName
}) {
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const cancelButtonRef = useRef(null);

  /*
   * A biblioteca react-hook-form que é utilizada para gerenciar o estado do
   * formulário utiliza apenas defaultValues para definir os valores iniciais.
   * Por isso precisou-se de um tratamento para incluir tanto os valores
   * iniciais quanto os default.
   * ref: https://react-hook-form.com/docs/useform?#defaultValues
   */
  const initialValues = useMemo(() => {
    const productsInitialValues = getProductsInitialValues(products);
    return {
      entity_products: productsInitialValues,
      ...(isEdit ? productsInitialValues : defaultValues)
    };
  }, [products, dealId, isEdit]);

  const handleFormSubmit = (formData) => {
    setIsSubmitting(true);
    onSubmit(
      { ...formData, value: formData?.calculated_value },
      () => {
        cancelButtonRef?.current?.click?.();
        setShowModal(false);
        setIsSubmitting(false);
      }
    );
  };

  return (
    <Popover
      flip
      showArrow
      hide={!showModal}
      className='max-width-7'
      rootCloseEvent='mousedown'
      placement={isEdit ? 'left-start' : 'top-end'}
      content={({ onHide }) => (
        <div className='mt-2'>
          <h3>Adicionar produtos e serviços</h3>
          <HookForm
            name={`create-deal-${dealId}-products`}
            onSubmit={handleFormSubmit}
            defaultValues={{ ...initialValues }}
            validationSchema={schema}
          >
            {() => (
              <>
                <div className='d-flex my-2 max-height-6 overflow-y-scroll'>
                  <HookProductsFields />
                </div>
                <hr />
                <div className='d-flex justify-content-end mt-2'>
                  <Button
                    variant='link'
                    className='me-2 fw-bold'
                    ref={cancelButtonRef}
                    onClick={onHide}
                    disabled={isSubmitting}
                  >
                    Cancelar
                  </Button>
                  <LoadingButton
                    type='submit'
                    isLoading={isSubmitting}
                    variant='primary'
                  >
                    Salvar
                  </LoadingButton>
                </div>
              </>
            )}
          </HookForm>
        </div>
      )}
    >
      <div className={classnames({ 'mt-n2 ms-n2': isEdit })}>
        <Button
          variant={isEdit ? 'link' : 'light'}
          className={classnames(
            'py-1 fw-bold text-primary',
            isEdit ? editButtonClassName : ''
          )}
          size='md'
          disabled={disabled}
          onClick={() => setShowModal(true)}
        >
          <Icon name={isEdit ? 'edit-field' : 'plus'} size='md' className='me-1' />
          {isEdit ? '' : 'Adicionar produtos/serviços'}
        </Button>
      </div>
    </Popover>
  );
}

ProductsFormPopover.propTypes = propTypes;
ProductsFormPopover.defaultProps = defaultProps;

export default ProductsFormPopover;
