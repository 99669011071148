import React, { createContext, useContext } from 'react';
import { useStore } from 'zustand';

import { useOrganizationModalStore } from '@/store/organization-modal';

const OrganizationModalContext = createContext(undefined);

export function useOrganizationModalContext(selector) {
  const context = useContext(OrganizationModalContext);

  if (context === undefined) {
    throw new Error('useOrganizationModalContext must be used within OrganizationModalProvider');
  }

  return useStore(context, selector);
}

export const useOrganization = () => useOrganizationModalContext(
  (state) => state.organization
);

export const useOnUpdate = () => useOrganizationModalContext(
  (state) => state.onUpdate
);

export const useOnCreatePerson = () => useOrganizationModalContext(
  (state) => state.onCreatePerson
);

export const useOnUpdatePerson = () => useOrganizationModalContext(
  (state) => state.onUpdatePerson
);

export const useOnDelete = () => useOrganizationModalContext(
  (state) => state.onDelete
);

export const useActions = () => useOrganizationModalContext(
  (state) => state.actions
);

export function OrganizationModalProvider({ children, value }) {
  const [store] = useOrganizationModalStore(value);

  return (
    <OrganizationModalContext.Provider value={store}>
      {children}
    </OrganizationModalContext.Provider>
  );
}
