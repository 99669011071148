import React from 'react';
import ABTest from '../ABTest';
import ImportGuideA from './ABTest/ImportGuide';
import ImportGuideB from '../ImportGuide';
import MapDataStepB from './MapDataStep';

export function ImportGuide(props) {
  return (
    <ABTest name='import_guide'>
      <ABTest.Variant name='A'>
        <ImportGuideA {...props} />
      </ABTest.Variant>

      <ABTest.Variant name='B'>
        <ImportGuideB {...props} />
      </ABTest.Variant>
    </ABTest>
  );
}

export function MapDataStep(props) {
  return (
    <ABTest name='import_ignore'>
      <ABTest.Variant name='A'>
        <MapDataStepB
          {...props}
          getIgnoredColumnTarget={() => 'ignore'}
        />
      </ABTest.Variant>

      <ABTest.Variant name='B'>
        <MapDataStepB {...props} />
      </ABTest.Variant>
    </ABTest>
  );
}
