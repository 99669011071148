import React from 'react';
import PropTypes from 'prop-types';
import Base from 'react-bootstrap/Dropdown';
import Menu from '@/components/Dropdown/Menu';
import Item from '@/components/Dropdown/Item';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

const defaultProps = {
  className: ''
};

const Dropdown = React.forwardRef(
  ({ children, ...props }, ref) => (
    <Base { ...props } ref={ref}>
      {children}
    </Base>
  )
);

Dropdown.propTypes = propTypes;
Dropdown.defaultProps = defaultProps;
Dropdown.displayName = 'Dropdown';

Dropdown.Menu = Menu;
Dropdown.Item = Item;

Dropdown.Toggle = Base.Toggle;
Dropdown.Divider = Base.Divider;
Dropdown.ItemText = Base.ItemText;

export default Dropdown;
