import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, useStoredState } from '@/hooks';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Popover from '@/components/Popover';
import DetailsWhatsappContactList from '@/components/EntityModal/Deal/DetailsWhatsappContactList';
import ModalAgendorChat from '@/components/WhatsApp/ModalAgendorChat';

DetailsWhatsApp.propTypes = {
  deal: PropTypes.object,
  entity: PropTypes.object,
  entityType: PropTypes.string,
  onUpdateEntity: PropTypes.func
};

DetailsWhatsApp.defaultProps = {
  entityType: '',
  deal: null,
  entity: null,
  onUpdateEntity: () => { }
};

function DetailsWhatsApp({ deal, entity, entityType, onUpdateEntity }) {
  const { user } = useAuth();
  const tracker = useTracking();
  const isBelow1200 = useMediaQuery('(max-width: 1300px)');

  const {
    value: hasShownModalOnce, store: setHasShownModalOnce
  } = useStoredState('details-whatsapp', false);
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    tracker.trackActionButtonClicked({ user, action: 'Enviar whatsapp' });
    if (!hasShownModalOnce) {
      tracker.trackWhatsappModalViewed({ user });
      setShowModal(true);
      setHasShownModalOnce(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Popover
        showArrow
        placement='left-start'
        className='me-1 z-index-1060 max-width-6'
        content={() => (
          <DetailsWhatsappContactList
            deal={deal}
            entity={entity}
            entityType={entityType}
            onUpdateEntity={onUpdateEntity}
          />
        )}
      >
        <Button
          className={
            `mt-2 d-flex align-items-center text-nowrap w-100
            ${isBelow1200 ? 'ms-0' : ''}`
          }
          onClick={handleClick}
        >
          <Icon name='whatsapp' className='ms-1 me-2' size='fixed' />
          <span>Enviar WhatsApp</span>
        </Button>
      </Popover>
      {
        showModal
          ? (
            <ModalAgendorChat show={showModal} onClose={handleCloseModal} />
          )
          : null
      }
    </>
  );
}

export default DetailsWhatsApp;
