import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const propTypes = {
  className: PropTypes.string
};

function MainTitle({ className }) {
  return (
    <div className={classnames(
      'd-flex',
      'align-items-center',
      'rounded-5',
      'title-box-size',
      className
    )}>
      <div
        className='
          d-flex
          me-3
          justify-content-center
          align-items-center
          bg-light-blue
          bg-opacity-25
          height-2
          width-2
          rounded-circle
          title-circle-size
          ms-2'
      >
        🚀
      </div>
      <span className='text-primary fw-bold'>Geração de leads</span>
    </div>
  );
}

MainTitle.prototype = propTypes;

export default MainTitle;
