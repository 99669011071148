import React, { useMemo } from 'react';
import { CNPJInput } from '@/components/Inputs';
import Tooltip from '@/components/Tooltip';
import Footer from '@/components/LeadsGeneration/Footer';
import SearchSubSection from '@/components/LeadsGeneration/Segmentation/SearchSubSection';
import { useLeadsGenerationContext } from '@/contexts';

function FindSimilar() {
  const {
    similarCnpjsData,
    setSimilarCnpjsData,
    nextStep,
    prevStep,
    callMixpanelEvent,
    leadsCount,
    minLeads
  } = useLeadsGenerationContext();

  const onClickNext = () => {
    callMixpanelEvent('trackLeadsGenerationSegmented');

    nextStep();
  };

  const showCnpjAlert = useMemo(() => leadsCount && (leadsCount < minLeads), [leadsCount]);

  const nextButtonDisabledMessage = `É necessário um mínimo de ${minLeads}
  leads selecionados para dar continuidade para a próxima etapa`;

  return (
    <>
      <SearchSubSection
        icon='person'
        title='Informe CNPJs de clientes ideais. Informe-os separados por vírgula ou espaço.'
        description='
          Vamos usar CNPJs de empresas que você conhece
          como parâmetro para encontrar empresas semelhantes
        '
        subtitle='CNPJs'
      >
        <Tooltip
          hide={!showCnpjAlert}
          trigger={['none']}
          content={<>Para encontrar mais resultados, adicione mais CNPJs à pesquisa.<br />
          Dessa forma, mais CNAEs serão incluídos na sua segmentação.</>}
          placement='bottom'
          initialShow
          className='z-index-1060 tooltip-lg'
        >
          <div>
            <CNPJInput
              id='input_cnpj_lookalike'
              name='lead-gen-cnpjs'
              initialValue={similarCnpjsData}
              onChange={setSimilarCnpjsData}
            />
          </div>
        </Tooltip>
      </SearchSubSection>

      <Footer
        buttonNextText='Buscar leads'
        onClickPrev={prevStep}
        onClickNext={onClickNext}
        disabled={showCnpjAlert}
        nextButtonDisabledMessage={nextButtonDisabledMessage}
        isCounterVisible
      />
    </>
  );
}

export default FindSimilar;
