import React, { useEffect } from 'react';
import SearchSubSection from '@/components/LeadsGeneration/Segmentation/SearchSubSection';
import { IndependentCitySelect, RadioButton, StateSelect } from '@/components/Inputs';
import { useLeadsGenerationContext } from '@/contexts';
import Footer from '@/components/LeadsGeneration/Footer';
import Alert from '@/components/Alert';
import ExternalLink from '@/components/ExternalLink';

const BUSINESS_MODEL_OPTIONS = [
  {
    label: 'Empresas (B2B)',
    value: 'b2b'
  },
  {
    label: 'Pessoas físicas (B2C)',
    value: 'b2c',
    info: {
      title: 'Nossos dados de pessoa física são somente através de empresas',
      paragraph: `Devido às regras da LGPD, nossa base é somente de empresas.
        Através desses dados de pessoa jurídica, você pode chegar em contatos de pessoas físicas.`,
      link: {
        label: 'Saber mais',
        href: 'https://ajuda.agendor.com.br/pt-BR/articles/8403648-como-funciona-a-geracao-de-leads-pelo-agendor#h_d522b8b5d8'
      }
    }
  },
  {
    label: 'Empresas e Pessoas físicas (B2B e B2C)',
    value: 'b2b_and_b2c'
  }
];

const REGION_OPTIONS = [
  {
    value: 'state',
    label: 'Estados'
  },
  {
    value: 'city',
    label: 'Cidades'
  }
];

function PublicRegion() {
  const {
    publicRegionData,
    setPublicRegionData,
    nextStep,
    prevStep,
    callMixpanelEvent
  } = useLeadsGenerationContext();

  useEffect(() => {
    callMixpanelEvent('trackLeadsGenerationStarted');
  }, []);

  const isState = publicRegionData.search_by === 'state';
  const isCity = publicRegionData.search_by === 'city';

  const handlePublicChange = (value) => {
    const newPublicData = { ...publicRegionData, business_model: value };

    setPublicRegionData(newPublicData);
  };

  const handleSearchByChange = (value) => {
    const newSearchByData = { ...publicRegionData, search_by: value };

    setPublicRegionData(newSearchByData);
  };

  const handleStateChange = (values) => {
    const newStateData = {
      ...publicRegionData,
      addresses: { ...publicRegionData.addresses, states: values }
    };

    setPublicRegionData(newStateData);
  };

  const handleCityChange = (values, option) => {
    const options = option ? option.map((opt) => opt.label) : [];
    const newCityData = {
      ...publicRegionData,
      addresses: { ...publicRegionData.addresses, cities_id: values, cities: options }
    };

    setPublicRegionData(newCityData);
  };

  const onClickNext = () => {
    callMixpanelEvent('trackLeadsGenerationSegmented');
    nextStep();
  };

  return (
    <>
      <SearchSubSection title='Para quem você vende?' icon='task'>
        {
          BUSINESS_MODEL_OPTIONS.map(({ label, value, info }, index) => (
            <div key={index}>
              <RadioButton
                noFlex
                name='business_model'
                id={value}
                radioValue={value}
                value={publicRegionData.business_model}
                onChange={handlePublicChange}
                checkClassName='ms-0 mb-3 text-dark-gray fw-bold'
                label={label}
              />
              {
                publicRegionData.business_model === 'b2c' && (
                  info && (
                    <Alert noTextSmall variant='light'>
                      <h5 className='mb-2 text-dark-gray'>
                        {info.title}
                      </h5>
                      <div className='text-dark-gray text-base'>
                        {info.paragraph}
                      </div>
                      <ExternalLink className='text-base' target='_blank' href={info.link.href}>
                        {info.link.label}
                      </ExternalLink>
                    </Alert>
                  )
                )
              }
            </div>
          ))
        }
      </SearchSubSection>

      <hr className='my-6' />

      <SearchSubSection title='Em quais regiões você atua?' icon='task'>
        <div className='d-flex'>
          {
            REGION_OPTIONS.map(({ value, label }, index) => (
              <RadioButton
                className='me-4'
                noFlex
                key={index}
                name='search_by'
                id={value}
                radioValue={value}
                value={publicRegionData.search_by}
                onChange={handleSearchByChange}
                checkClassName='ms-0 mb-3 text-dark-gray fw-bold'
                label={label}
              />
            ))
          }
        </div>

        {
          isState && (
            <StateSelect
              name='states'
              value={publicRegionData.addresses.states}
              onChange={handleStateChange}
              multiple
              isSearchable
              fullName
              placeholder='Todo o Brasil'
              label=''
            />
          )
        }

        {
          isCity && (
            <IndependentCitySelect
              name='cities_id'
              value={publicRegionData.addresses.cities_id}
              onChange={handleCityChange}
            />
          )
        }
      </SearchSubSection>
      <Footer
        buttonNextText='Avançar'
        onClickPrev={prevStep}
        onClickNext={onClickNext}
        isCounterVisible
        isBackButtonVisible={false}
      />
    </>
  );
}

export default PublicRegion;
