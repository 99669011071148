import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import TruncateLinkfied from '@/components/TruncateLinkfied';

const propTypes = {
  email: PropTypes.object
};

const defaultProps = {
  email: {}
};

const TRUNCATED_BODY_SIZE = 255;

function EmailBodyContent({ email }) {
  const [showBodyHTML, setShowBodyHTML] = useState(false);

  const frameSrc = `/api/activities/${email.activityId}/email`;
  const onLoadFrame = ({ target: frame }) => {
    // Altera altura do frame conforme altura do seu conteúdo.
    frame.height = `${frame.contentDocument.documentElement.scrollHeight}px`;
  };

  return (
    <>
      {
        showBodyHTML
          ? (
            <div className='position-relative'>
              <div className='position-absolute'>
                Carregando e-mail completo...
              </div>

              <iframe
                src={frameSrc}
                sandbox='allow-popups allow-popups-to-escape-sandbox allow-same-origin'
                csp="script-src 'none'"
                className='email-frame position-relative w-100'
                height='0'
                onLoad={onLoadFrame}
              />
            </div>
          )
          : (
            <TruncateLinkfied
              fullWidth
              anchorClassName='d-block fw-bold'
              text={email.body}
              maxLength={TRUNCATED_BODY_SIZE}
            />
          )
      }

      <div className='mt-2 d-flex justify-content-center'>
        <Button
          variant='link'
          className='fw-bold'
          onClick={() => setShowBodyHTML((previous) => !previous)}
        >
          {`Ver e-mail ${showBodyHTML ? 'resumido' : 'completo'}`}
        </Button>
      </div>
    </>
  );
}

EmailBodyContent.propTypes = propTypes;
EmailBodyContent.defaultProps = defaultProps;

export default EmailBodyContent;
