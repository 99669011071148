import React from 'react';
import PropTypes from 'prop-types';

import Button from '@/components/Button';
import { DATE_DEFAULT_PRESETS, toDateString } from '@/date';

const propTypes = {
  onHide: PropTypes.func,
  handleChange: PropTypes.func
};

const defaultProps = {
  onHide: () => {},
  handleChange: () => {}
};

function DatePresets({ onHide, handleChange }) {
  return (
    <div>
      {
        DATE_DEFAULT_PRESETS.map(({ label, getValue }) => {
          const handleClick = () => {
            handleChange(toDateString(getValue()));
            onHide();
          };

          return <Button
            key={label}
            onClick={handleClick}
            size='sm' variant='outline-darker-gray'
            className='me-1'
          >
            { label }
          </Button>;
        })
      }
    </div>

  );
}

DatePresets.propTypes = propTypes;
DatePresets.defaultProps = defaultProps;

export default DatePresets;
