import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import ConfirmationModal from '@/components/ConfirmationModal';
import APIErrorMessage from '@/components/APIErrorMessage';
import Authorization from '@/components/Authorization';
import { canDeleteStage } from '@/policies';
import { settingsStagesDealsRelocationsPath } from '@/routes';
import { useDeleteStage } from '@/api';

const propTypes = {
  funnelId: PropTypes.number,
  stage: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    hasDeals: PropTypes.bool.isRequired
  }).isRequired,
  totalCount: PropTypes.number.isRequired
};

const defaultProps = {
};

function FunnelStagesDelete({ funnelId, stage, totalCount }) {
  const { id: stageId, name: stageName, hasDeals } = stage;

  const [isDeleting, setIsDeleting] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const alert = useAlert();

  const mutation = useDeleteStage({
    onDelete: () => {
      alert.show(
        'Etapa removida com sucesso.',
        { variant: 'success', timeout: 5000 }
      );
    }
  });

  const handleModalHide = () => setShowModal(false);

  const handleDeleteClick = (e) => {
    e.currentTarget.blur();

    setShowModal(true);
  };

  const handleDeleteConfirm = () => {
    setIsDeleting(true);
    setShowModal(false);

    mutation.mutate({ stageId }, {
      onError: (err) => {
        setIsDeleting(false);

        alert.show(
          <APIErrorMessage err={err} resource='stage' action='delete' />,
          { variant: 'danger' }
        );
      }
    });
  };

  const deleteButtonProps = {
    onClick: !hasDeals ? handleDeleteClick : null,
    href: hasDeals ? settingsStagesDealsRelocationsPath(funnelId, stageId) : null
  };

  return (
    <React.Fragment>
      <ConfirmationModal
        show={showModal}
        disabled={isDeleting}
        onHide={handleModalHide}
        onConfirm={handleDeleteConfirm}
        confirmationText='Excluir etapa'
      >
        <ConfirmationModal.Title>
          Confirmar exclusão da etapa {stageName}
        </ConfirmationModal.Title>

        <ConfirmationModal.Info>
          Esta ação não poderá ser revertida.
        </ConfirmationModal.Info>
      </ConfirmationModal>

      <Authorization policy={canDeleteStage} data={{ totalCount }}>
        <Button
          variant='transparent-light'
          className='py-2 px-4 border-0 stage-delete-button'
          disabled={isDeleting}
          { ...deleteButtonProps }
        >
          <Icon name='delete' className='me-2' />

          <span>Excluir etapa</span>
        </Button>
      </Authorization>
    </React.Fragment>
  );
}

FunnelStagesDelete.propTypes = propTypes;
FunnelStagesDelete.defaultProps = defaultProps;

export default FunnelStagesDelete;

