import React from 'react';
import PropTypes from 'prop-types';
import Badge from '@/components/Badge';
import { startOfToday, differenceInDays } from 'date-fns';
import { parseDate } from '@/date';
import { DEAL_STATUSES_KEY, pluralize } from '@/utils';

StatusBadge.defaultProps = {
  status: null,
  loading: true,
  finishedAt: null
};

StatusBadge.propTypes = {
  status: PropTypes.oneOf(Object.values(DEAL_STATUSES_KEY)),
  loading: PropTypes.bool,
  finishedAt: PropTypes.string
};

function getBadgeData(status, finishedAt) {
  if (status) {
    if (finishedAt) {
      const today = startOfToday();
      const finishedAtDate = parseDate(finishedAt);
      const diffInDays = differenceInDays(finishedAtDate, today);
      if (status === DEAL_STATUSES_KEY.ongoing) {
        if (diffInDays >= 1) {
          return {
            variant: 'light-green',
            label: `Conclusão em ${diffInDays} ${pluralize('dia', diffInDays)}`
          };
        }
        if (diffInDays <= -1) {
          return {
            variant: 'yellow',
            label: `Atrasado há ${Math.abs(diffInDays)} ${pluralize('dia', Math.abs(diffInDays))}`
          };
        }
        return { variant: 'light-green', label: 'Para hoje' };
      }
      if (status === DEAL_STATUSES_KEY.lost) {
        if (diffInDays >= 1) {
          return {
            variant: 'light-red',
            label: `Conclusão em ${diffInDays} ${pluralize('dia', diffInDays)}`
          };
        }
        if (diffInDays <= -1) {
          return {
            variant: 'light-red',
            label: `Perdido há ${Math.abs(diffInDays)} ${pluralize('dia', Math.abs(diffInDays))}`
          };
        }
        return { variant: 'light-red', label: 'Perdido hoje' };
      }
      if (status === DEAL_STATUSES_KEY.won) {
        if (diffInDays >= 1) {
          return {
            variant: 'light-green',
            label: `Conclusão em ${diffInDays} ${pluralize('dia', diffInDays)}`
          };
        }
        if (diffInDays <= -1) {
          return {
            variant: 'light-green',
            label: `Ganho há ${Math.abs(diffInDays)} ${pluralize('dia', Math.abs(diffInDays))}`
          };
        }
        return { variant: 'light-green', label: 'Ganho hoje' };
      }
    }
    return { variant: 'light', label: 'Período indefinido' };
  }
  return null;
}

function StatusBadge({ status, finishedAt, loading }) {
  const badge = getBadgeData(status, finishedAt);
  if (loading || !badge) {
    return null;
  }
  return (
    <h3>
      <Badge variant={badge.variant} className='ms-2'>
        {badge.label}
      </Badge>
    </h3>
  );
}

export default StatusBadge;
