import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  children: PropTypes.node.isRequired,
  innerRef: PropTypes.any,
  innerProps: PropTypes.object,
  innerPlaceholder: PropTypes.node
};

const defaultProps = {
  innerRef: null,
  innerProps: {},
  innerPlaceholder: null
};

function EmptyFunnelStage({ children, innerRef, innerProps, innerPlaceholder }) {
  return (
    <div className='pe-2 width-5 overflow-y-scroll d-flex flex-grow-1 position-relative'>
      <div className='flex-grow-1 d-flex' ref={innerRef} { ...innerProps }>
        <div
          className={classnames(
            'bg-primary',
            'bg-opacity-10',
            'rounded',
            'd-flex',
            'px-6',
            'fw-bold',
            'align-items-center',
            'text-center',
            'text-dark-gray',
            'flex-grow-1'
          )}
        >
          {children}
        </div>

        {innerPlaceholder}
      </div>
    </div>
  );
}

EmptyFunnelStage.propTypes = propTypes;
EmptyFunnelStage.defaultProps = defaultProps;

export default EmptyFunnelStage;
