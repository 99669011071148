import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import FormField from '@/components/FormField';
import { MaskedInput, WhatsAppInput } from '@/components/Inputs';
import Icon from '@/components/Icon';
import Truncate from '@/components/Truncate';
import Card from '@/components/Card';
import Form from '@/components/Form';
import Button from '@/components/Button';
import LoadingButton from '@/components/LoadingButton';

DetailsContactForm.propTypes = {
  show: PropTypes.bool,
  entity: PropTypes.object,
  entityType: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
};

DetailsContactForm.defaultProps = {
  show: false,
  entity: {},
  entityType: '',
  onCancel: () => { },
  onSubmit: () => { }
};

/* eslint-disable no-magic-numbers */
const schema = Yup.object().shape({
  contact: Yup.object().shape({
    whatsapp: Yup.string()
      .min(12, 'Número inválido.')
      .max(16, 'Número inválido.'),
    work_phone: Yup.string()
      .min(10, 'Número inválido.')
      .max(11, 'Número inválido.'),
    mobile_phone: Yup.string()
      .min(10, 'Número inválido.')
      .max(11, 'Número inválido.')
  })
});
/* eslint-enable no-magic-numbers */

function DetailsContactForm({ show, entity, entityType, onCancel, onSubmit }) {
  const initialValues = {
    contact: {
      whatsapp: entity?.contact?.whatsapp ?? '',
      work_phone: entity?.contact?.workPhone ?? '',
      mobile_phone: entity?.contact?.mobilePhone ?? ''
    }
  };

  const handleUpdateContact = (contact) => onSubmit(contact, onCancel, { ...entity, entityType });

  if (!show) {
    return null;
  }

  return (
    <Card className='p-3 border-0 bg-lighter-gray mb-2'>
      <div key={entity.id} className='d-flex'>
        <div className='d-flex bg-light-gray rounded p-2 align-self-baseline'>
          <Icon name={entityType} />
        </div>
        <div className='ms-2 flex-fill'>
          <Truncate as='div' className='width-5 text-darker-gray fw-bold mt-2 mb-1'>
            {entity.name}
          </Truncate>
          <Form
            name='voip-contact-form'
            initialValues={initialValues}
            onSubmit={handleUpdateContact}
            validationSchema={schema}
          >
            {({ isSubmitting, handleSubmit }) => (
              <>
                <div className='align-items-start ms-n9 mt-6'>
                  <FormField
                    as={WhatsAppInput}
                    name='contact.whatsapp'
                    label='Whatsapp'
                    autoComplete='off'
                    variant='light'
                  />
                  <FormField
                    as={MaskedInput}
                    name='contact.mobile_phone'
                    label='Celular'
                    maskType='phone'
                    placeholder='(00) 0000-0000'
                  />
                  <FormField
                    as={MaskedInput}
                    maskType='phone'
                    name='contact.work_phone'
                    label='Telefone'
                    autoComplete='off'
                    placeholder='(00) 0000-0000'
                    variant='light'
                  />
                  <div className='d-flex justify-content-end mt-2'>
                    <Button
                      className='fw-bold'
                      variant='link'
                      onClick={onCancel}
                      disabled={isSubmitting}
                    >
                    Cancelar
                    </Button>
                    <LoadingButton
                      variant='primary'
                      type='submit'
                      isLoading={isSubmitting}
                      loadingText='Salvando...'
                      onClick={handleSubmit}
                    >
                    Salvar
                    </LoadingButton>
                  </div>
                </div>
              </>
            )}
          </Form>
        </div>
      </div>
    </Card>
  );
}

export default DetailsContactForm;
