import React, { createContext, useContext } from 'react';

const UpdateSubscriptionContext = createContext(undefined);

export function useUpdateSubscriptionContext() {
  const context = useContext(UpdateSubscriptionContext);

  if (context === undefined) {
    throw new Error('useUpdateSubscription must be used within UpdateSubscriptionProvider');
  }

  return context;
}

export function UpdateSubscriptionProvider({ children, value }) {
  return (
    <UpdateSubscriptionContext.Provider value={value}>
      {children}
    </UpdateSubscriptionContext.Provider>
  );
}
