import { useMutation } from 'react-query';
import { post } from '@/lib/fetch';


export async function createACContact(params = {}) {
  const { data } = await post('/ac_contacts', params);

  return data;
}

export function useCreateACContact({ config = {} } = {}) {
  return useMutation(
    createACContact,
    { ...config }
  );
}

