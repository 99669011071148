import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { startOfDay, endOfDay, isBefore, isValid } from 'date-fns';
import Button from '@/components/Button';
import DatePicker from '@/components/DatePicker';
import RangeAdornment from '@/components/RangeAdornment';
import MaskedInput from '@/components/Inputs/MaskedInput';
import Popover from '@/components/Popover';
import TextInput from '@/components/Inputs/TextInput';
import { parseRangeISO, formatRangeISO } from '@/date-range';
import { toDateString, fromDMA, formatRange } from '@/date';
import { usePopover } from '@/contexts';

const propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  presets: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    days: PropTypes.number
  })),
  value: PropTypes.string
};

const defaultProps = {
  presets: []
};

function DateRangeInput({ name, label, onChange, value, presets }) {
  const initialState = useMemo(() => getState(value), [value]);

  const [selectedFrom, setSelectedFrom] = useState(initialState.to);
  const [selectedTo, setSelectedTo] = useState(initialState.from);

  const dateSelectedFrom = useMemo(() => fromDMA(selectedFrom), [selectedFrom]);
  const dateSelectedTo = useMemo(() => {
    const selectedToFromDMA = fromDMA(selectedTo);

    if (selectedToFromDMA) {
      return endOfDay(selectedToFromDMA);
    }
  }, [selectedTo]);
  const dateSelectedFromCalendar = dateSelectedFrom || dateSelectedTo;

  const onSelect = (range) => {
    const newSelectedFrom = range[0] ? toDateString(range[0]) : undefined;
    const newSelectedTo = range[1] ? toDateString(range[1]) : undefined;

    setSelectedFrom(newSelectedFrom);
    setSelectedTo(newSelectedTo);
  };

  const onDayClick = (date) => {
    const newSelected = updateRange([dateSelectedFrom, dateSelectedTo], date);
    onSelect(newSelected);
  };

  const onApply = (callback) => {
    const formatted = formatRangeISO([dateSelectedFrom, dateSelectedTo]);
    onChange(formatted);
    callback();
  };

  const onReset = () => {
    setSelectedTo(undefined);
    setSelectedFrom(undefined);
    onChange(null);
  };

  const isApplied = Boolean(initialState.from || initialState.to);
  const isSelectedFromValid = isValid(dateSelectedFrom);
  const isSelectedToValid = isValid(dateSelectedTo);
  const canApply = isSelectedFromValid || isSelectedToValid;
  const { container } = usePopover();

  // Update state when the value prop changes
  useEffect(() => {
    if (value) {
      const newState = getState(value);
      onSelect([newState.from, newState.to]);
    }
  }, [value]);

  const inputValue = useMemo(() => (
    formatRange(initialState.from, initialState.to)
  ), [initialState]);

  const minDateSelectedTo = useMemo(() => {
    if (dateSelectedTo) {
      return startOfDay(dateSelectedTo);
    }
  }, [dateSelectedTo]);

  return (
    <Popover
      container={container && container.current}
      className='max-width-7'
      content={({ onHide }) => (
        <div>
          <div className='d-flex pb-3 border-bottom'>
            <MaskedInput
              name={`${name}_from`}
              label='de'
              maskType='date'
              className='width-4 me-5 d-flex align-items-baseline'
              value={selectedFrom}
              onChange={setSelectedFrom}
              max={dateSelectedTo}
            />

            <MaskedInput
              min={minDateSelectedTo}
              name={`${name}_to`}
              label='até'
              maskType='date'
              className='width-4 d-flex align-items-baseline'
              value={selectedTo}
              onChange={setSelectedTo}
            />
          </div>

          <DatePicker
            mode='uncontrolled'
            numberOfMonths={2}
            onDayClick={onDayClick}
            selected={{ from: dateSelectedFromCalendar, to: dateSelectedTo }}
            modifiers={{ from: dateSelectedFromCalendar, to: dateSelectedTo }}
            className='rdp rdp-range'
            month={dateSelectedFromCalendar}
            fixedWeeks
          />

          <div className='d-flex pt-3 border-top'>
            {presets.map(({ label: presetLabel, getValue }, index) => (
              <Button
                key={index}
                variant='outline-darker-gray'
                size='sm'
                className='me-3'
                onClick={() => onSelect(getValue())}
              >
                {presetLabel}
              </Button>
            ))}

            <Button
              className='ms-auto'
              size='sm'
              disabled={!canApply}
              onClick={() => onApply(onHide)}
            >
              Aplicar
            </Button>
          </div>
        </div>
      )}
      placement='right-start'
      flip
    >
      <TextInput
        readOnly
        name={name}
        value={inputValue}
        label={label}
        placeholder='Qualquer período'
        innerClassName='cursor-default'
        rightAdornment={() => (
          <RangeAdornment isFilled={isApplied} onClear={onReset} />
        )}
      />
    </Popover>
  );
}

function getState(value) {
  const parsed = parseRangeISO(value) || [];

  return { from: parsed[0], to: parsed[1] };
}

function updateRange(previousRange, selectedDate) {
  const [previousFrom, previousTo] = previousRange;

  const isSelectingStart = !previousFrom || previousTo;
  if (isSelectingStart) {
    return [startOfDay(selectedDate)];
  }

  const selectedEndIsBeforeStart = isBefore(selectedDate, previousFrom);
  if (selectedEndIsBeforeStart) {
    return [startOfDay(selectedDate), endOfDay(previousFrom)];
  }

  return [previousFrom, endOfDay(selectedDate)];
}

DateRangeInput.propTypes = propTypes;

DateRangeInput.defaultProps = defaultProps;

export default DateRangeInput;
