import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';

import { RadioButton, Select } from '@/components/Inputs';
import FooterButtons from '@/feature/profile_form/FooterButtons';
import { useUpdateProfile } from '@/api';
import { useAuth } from '@/lib/auth';
import HookForm from '@/components/HookForm';
import HookFormField from '@/components/HookFormField';
import Badge from '@/components/Badge';
import HookFormFeedback from '@/components/HookFormFeedback';
import { useTracking } from '@/lib/tracking';

const propTypes = {
  advanceStep: PropTypes.func,
  returnStep: PropTypes.func
};

const defaultProps = {
  advanceStep: () => {},
  returnStep: () => {}
};

const schema = Yup.object().shape({
  user: Yup.object().shape({
    crm_experience: Yup.string()
      .required('Por favor, informe o seu nível de experiência com CRM.'),
    persona: Yup.string()
      .required('Por favor, informe o seu cargo.')
  })
});

const crmExperienceOptions = [
  { value: 'Já usei', label: 'Já usei vários CRMs e tenho conhecimento avançado' },
  { value: 'Já usei um ou outro', label: 'Já usei um ou outro CRM no meu dia a dia' },
  { value: 'Nunca usei', label: 'Nunca usei ou tenho pouca familiaridade com CRMs' }
];

const personaOptions = [
  { value: 'Empresário / CEO', label: 'Empresário / CEO' },
  { value: 'Diretor Comercial', label: 'Diretor Comercial' },
  { value: 'Gestor de Vendas', label: 'Gestor de Vendas' },
  { value: 'Gestor de Marketing', label: 'Gestor de Marketing' },
  { value: 'Supervisor(a) / Coordenador(a)', label: 'Supervisor(a) / Coordenador(a)' },
  { value: 'Profissional de TI', label: 'Profissional de TI' },
  { value: 'Vendedor Externo', label: 'Vendedor Externo' },
  { value: 'Vendedor Interno', label: 'Vendedor Interno' },
  { value: 'Representante Comercial', label: 'Representante Comercial' },
  { value: 'Consultor empresarial', label: 'Consultor empresarial' },
  { value: 'Secretária(o) / Assistente', label: 'Secretária(o) / Assistente' },
  { value: 'Outro', label: 'Outro' }
];

function CRMExperienceStep({
  returnStep,
  advanceStep
}) {
  const { user } = useAuth();
  const tracker = useTracking();

  useEffect(() => {
    tracker.trackProfileStepViewed({ user, step: 2 });
  }, []);

  const { mutate, isLoading } = useUpdateProfile();
  const handleNext = (values) => {
    mutate({ params: values }, {
      onSuccess: ({ data }) => {
        /**
         * Aqui é usada a resposta da requisição (`data`) ao invés do objeto de
         * usuário (`user`) porque no momento que essa função é chamada, o `user`
         * ainda não está atualizado com os dados que foram preenchidos no formulário.
         */
        tracker.trackProfileStepCompleted({ user: data });

        advanceStep();
      }
    });
  };

  const defaultValues = useMemo(() => ({
    user: {
      crm_experience: user.crmExperience,
      persona: user.persona
    }
  }), [user]);

  return (
    <HookForm
      onSubmit={handleNext}
      name='profile-form'
      validationSchema={schema}
      defaultValues={defaultValues}
      className='mt-4'
    >
      {
        () => (
          <Row>
            <Col lg={{ span: 6, offset: 3 }}>
              <Badge variant='light' className='text-small mb-3'>
                Passo 2 de 4
              </Badge>

              <h1 className='mb-3'>Qual sua experiência com CRM?</h1>
              <ListGroup className='rounded-3'>
                {crmExperienceOptions.map(({ label, value }, index) => (
                  <ListGroup.Item key={value} className='text-dark-gray px-6 py-4'>
                    <HookFormField
                      as={RadioButton}
                      name='user.crm_experience'
                      id={`profile-form-crm-experience-${index}`}
                      radioValue={value}
                      checkClassName='mb-0'
                      label={<span className='cursor-pointer stretched-link'>{label}</span>}
                      noFlex
                    />
                  </ListGroup.Item>
                ))}
              </ListGroup>
              <HookFormFeedback name='user.crm_experience' />

              <HookFormField
                as={Select}
                name='user.persona'
                placeholder='Selecione'
                options={personaOptions}
                label={<h1 className='mt-6 mb-3'>Qual seu cargo?</h1>}
              />
              <FooterButtons handlePrevious={returnStep} disabled={isLoading} />
            </Col>
          </Row>
        )
      }
    </HookForm>
  );
}

CRMExperienceStep.propTypes = propTypes;
CRMExperienceStep.defaultProps = defaultProps;

export default CRMExperienceStep;
