import React from 'react';
import { Select, TextInput } from '@/components/Inputs';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { InputPreview } from '@/components/CustomFields/InputPreview';

export function AddressFieldsPreview() {
  return (
    <fieldset>
      <Row className='gx-3'>
        <Col sm={3}>
          <InputPreview
            as={TextInput}
            label='CEP'
          />
        </Col>

        <Col sm={6}>
          <InputPreview
            as={TextInput}
            label='País'
          />
        </Col>

        <Col sm={3}>
          <InputPreview
            as={Select}
            label='Estado'
          />
        </Col>
      </Row>

      <Row className='gx-3'>
        <Col sm={6}>
          <InputPreview
            as={Select}
            label='Cidade'
          />
        </Col>

        <Col sm={6}>
          <InputPreview
            as={TextInput}
            label='Bairro'
          />
        </Col>
      </Row>

      <Row className='gx-3'>
        <Col sm={6}>
          <InputPreview
            as={TextInput}
            label='Rua'
          />
        </Col>

        <Col sm={2}>
          <InputPreview
            as={TextInput}
            label='Número'
          />
        </Col>

        <Col sm={4}>
          <InputPreview
            as={TextInput}
            label='Complemento'
          />
        </Col>
      </Row>
    </fieldset>
  );
}

