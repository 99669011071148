import React from 'react';
import Popover from '@/components/Popover';

function AudioPlayer({ src, children }) {
  return (
    <Popover
      placement='bottom-start'
      closeButton
      rootClose={false}
      contentClassName='p-0 bg-light rounded'
      className='rounded-pill'
      content={() => <audio controls autoPlay src={src} />}
    >
      {children}
    </Popover>
  );
}

export default AudioPlayer;
