import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

const defaultProps = {
  className: ''
};

function FlexibleArea({ children, className }) {
  return (
    <div className={classnames('flexible-width', className)}>
      { children }
    </div>
  );
}

FlexibleArea.propTypes = propTypes;
FlexibleArea.defaultProps = defaultProps;
FlexibleArea.displayName = 'Card.FlexibleArea';

export default FlexibleArea;
