import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import LoadSpinner from '@/components/LoadSpinner';

const propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string
};

const defaultProps = {
  show: false
};

function LoadingOverlay({ show, children, className }) {
  return (
    <div className='position-relative'>
      {children}
      <div
        className={classNames(
          'loading-overlay',
          'position-absolute',
          'top-0',
          'start-0',
          'bottom-0',
          'end-0',
          'd-flex',
          'align-items-center',
          'justify-content-center',
          'z-index-1090',
          { 'd-none': !show },
          className
        )}
      >
        <LoadSpinner size='lg' className='text-dark-gray opacity-100' />
      </div>
    </div>
  );
}

LoadingOverlay.propTypes = propTypes;
LoadingOverlay.defaultProps = defaultProps;

export default LoadingOverlay;
