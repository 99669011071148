import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  href: PropTypes.string.isRequired
};

const ExternalLink = React.forwardRef(
  (props, ref) => {
    const { children, ...rest } = props;

    return (
      <a { ...rest } ref={ref}>
        {children}
      </a>
    );
  }
);

ExternalLink.propTypes = propTypes;
ExternalLink.displayName = 'ExternalLink';

export default ExternalLink;
