import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { goBack } from '@/browser';
import { useDocumentTitle } from '@/hooks';
import Main from '@/components/Main';
import ImportStatus from '@/components/ImportStatus';
import ImportHistory from '@/components/ImportHistory';
import Icon from '@/components/Icon';

const PAGE_TITLES = {
  organizations: 'Importação de empresas',
  people: 'Importação de pessoas',
  products: 'Importação de produtos',
  activities: 'Importação de comentários',
  deals: 'Importação de negócios'
};

const propTypes = {
  entity: PropTypes.oneOf(['organizations', 'people', 'products', 'activities', 'deals'])
};

function ImportsShow({ entity }) {
  const title = PAGE_TITLES[entity];

  useDocumentTitle(title);

  const { importId } = useParams();

  return (
    <Main fluid flex className='flexible-height'>
      <Container
        className='d-flex flex-column flex-grow-1 flexible-height mt-4'
      >
        <Row className='align-items-center mb-5'>
          <div
            onClick={() => goBack()}
            className='cursor-pointer d-flex align-items-center'
          >
            <Icon
              name='arrow-left'
              size='lg'
              className='ms-2 text-primary fw-bold'
            />

            <h4 className='ms-2 fw-bold'>{title}</h4>
          </div>
        </Row>

        <Row>
          <Col
            className='d-flex flex-column flex-grow-1 flexible-height mb-4'
            sm={12}
            md={12}
            lg={9}
          >
            <ImportStatus
              key={importId}
              importId={importId}
              entity={entity}
            />
          </Col>

          {
            <Col sm={12} md={12} lg={3}>
              <h4 className='mb-4'>Histórico de importações</h4>

              <ImportHistory entity={entity} />
            </Col>
          }
        </Row>
      </Container>
    </Main>
  );
}

ImportsShow.propTypes = propTypes;

export default ImportsShow;
