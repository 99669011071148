import { useMutation } from 'react-query';
import { post, patch } from '@/lib/fetch';

export async function createSubscription(params = {}) {
  const { data } = await post('/billing/subscriptions', params);

  return data;
}

export async function createBankSlip(params = {}) {
  const { data } = await post('/billing/subscriptions/create_bank_slip', params);

  return data;
}

export async function updateSubscription(params = {}) {
  const { data } = await patch('/billing/subscriptions', params);

  return data;
}

export async function updateSubscriptionPaymentMethod(params = {}) {
  const { data } = await patch('/billing/subscriptions/update_payment_method', params);

  return data;
}

export async function upgradeToPerformance(params = {}) {
  const { data } = await patch('/billing/subscriptions/upgrade_to_performance', params);

  return data;
}

export function useCreateSubscription({ config = {} } = {}) {
  return useMutation(
    createSubscription,
    { ...config }
  );
}

export function useCreateBankSlip({ config = {} } = {}) {
  return useMutation(
    createBankSlip,
    { ...config }
  );
}

export function useUpdateSubscription({ config = {} } = {}) {
  return useMutation(
    updateSubscription,
    { ...config }
  );
}

export function useUpdateSubscriptionPaymentMethod({ config = {} } = {}) {
  return useMutation(
    updateSubscriptionPaymentMethod,
    { ...config }
  );
}

export function useUpgradeToPerformance({ config = {} } = {}) {
  return useMutation(
    upgradeToPerformance,
    { ...config }
  );
}
