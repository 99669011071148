import { useCallback, useState } from 'react';
import { useFormikContext } from 'formik';
import { usePostalCodeLookup } from '@/api';

/**
 * Hook que faz uso da API para buscar dados de um endereço baseado no CEP.
 *
 * **Nescessário usar como filho do componente <Form />.**
 *
 * @param {{ postalCodeFieldName: string, onSuccess: (data: Record<string, any>) => any }} options
 * @returns {{ onPostalCodeBlur: (e) => any, isFetching: boolean }}
 */
export function useAutoCompleteCep(options = {}) {
  const { onSuccess, postalCodeFieldName } = options;
  const { getFieldMeta, handleBlur } = useFormikContext();

  const [params, setParams] = useState({ postal_code: '' });
  const [isValidPostalCode, setIsValidPostalCode] = useState(false);

  const { isFetching } = usePostalCodeLookup({
    params,
    config: {
      enabled: isValidPostalCode,
      onSuccess: ({ data }) => {
        onSuccess?.(data);
      }
    }
  });

  const onPostalCodeBlur = useCallback((e) => {
    handleBlur(e);

    const { value, error } = getFieldMeta(postalCodeFieldName);

    if (value && !error) {
      setParams({ postal_code: value });
      setIsValidPostalCode(true);
    }
  }, [handleBlur, getFieldMeta, setParams, setIsValidPostalCode, postalCodeFieldName]);

  return { onPostalCodeBlur, isFetching };
}
