import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as Yup from 'yup';
import FormField from '@/components/FormField';
import Form from '@/components/Form';
import { TextInput } from '@/components/Inputs';
import Button from '@/components/Button';
import LoadingButton from '@/components/LoadingButton';
import { toDigitsString } from '@/number';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  nextOrder: PropTypes.number.isRequired,
  className: PropTypes.string
};

const defaultProps = {
  className: ''
};

/* eslint-disable no-magic-numbers */
const schema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(30, 'Nome do funil deve ter no máximo 30 caracteres.')
    .required('Nome do funil é obrigatório.'),
  initials: Yup.string()
    .trim()
    .length(3, 'Sigla do funil deve conter 3 caracteres.')
    .required('Sigla do funil é obrigatória.')
});
/* eslint-enable no-magic-numbers */

const defaultValues = { name: 'Funil de ', initials: '' };

function CreateFunnelForm({ className, nextOrder, onSubmit, onCancel }) {
  return (
    <Form
      className={classnames('d-flex', className)}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      name='create-funnel-form'
      validationSchema={schema}
    >
      {({ handleBlur, isSubmitting }) => (
        <div className={classnames(
          'd-flex',
          'align-items-start',
          'bg-light',
          'rounded'
        )}>
          <div className='width-3 px-3 py-2 ms-2 text-dark-gray'>
            <div className={classnames(
              'py-2',
              'align-self-center',
              'd-flex',
              'align-items-center',
              'justify-content-between'
            )}>
              <div className='ms-auto'>
                {toDigitsString(nextOrder, 2)}
              </div>
            </div>
          </div>
          <div className='width-5 px-3 py-2'>
            <FormField
              as={TextInput}
              name='name'
              className='mb-0'
              placeholder='Nome do funil'
              autoComplete='off'
              variant='white'
              onBlur={handleBlur}
              autoFocus
            />
          </div>
          <div className='width-4 px-3 py-2'>
            <FormField
              as={TextInput}
              name='initials'
              className='mb-0'
              placeholder='ex: VEN'
              autoComplete='off'
              variant='white'
              onBlur={handleBlur}
              normalize={(value) => (value || '').toUpperCase()}
            />
          </div>
          <div className='px-3 py-2'>
            <LoadingButton
              type='submit'
              className='px-4 border-0'
              isLoading={isSubmitting}
            />
            <Button
              variant='link'
              className='px-4 border-0'
              onClick={onCancel}
            >
              Cancelar
            </Button>
          </div>
        </div>
      )}
    </Form>
  );
}

CreateFunnelForm.propTypes = propTypes;
CreateFunnelForm.defaultProps = defaultProps;

export default CreateFunnelForm;
