import { createCable } from '@anycable/web';
import { getCookie } from '@/browser';

export function subscribeTo(channel, params) {
  const url = getCookie('action_cable_url');

  const cable = createCable(url);

  return cable.subscribeTo(channel, params);
}
