import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Button from '@/components/Button';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import Avatar from '@/components/Avatar';
import { Checkbox } from '@/components/Inputs';
import Truncate from '@/components/Truncate';
import APIErrorMessage from '@/components/APIErrorMessage';

import { useCreateVerifyList } from '@/api';

const propTypes = {
  showVerifyModal: PropTypes.func,
  hidePopover: PropTypes.func,
  usersForVerify: PropTypes.array
};

const defaultProps = {
  showVerifyModal: () => {},
  hidePopover: () => {},
  usersForVerify: []
};

function EmailVerifyForm({ showVerifyModal, hidePopover, usersForVerify }) {
  const { mutate: createVerifyList, isLoading: isSending } = useCreateVerifyList({
    config: {
      onSuccess: () => {
        showVerifyModal();
        hidePopover();
      },
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='email_sender' action='create' />,
          { variant: 'danger', timeout: 5000 }
        );
      }
    }
  });

  const handleSubmit = (obj) => {
    const users = Object.keys(obj).filter((key) => obj[key]);

    createVerifyList({ users });
  };

  return (
    <Form
      onSubmit={handleSubmit}
      name='email-verify-form'
    >
      {
        ({ setFieldValue, values }) => {
          const hasSelectedValues = _(values).values()
            .filter()
            .size();

          return (
            <>
              <div className='max-height-5 overflow-y-auto mb-3'>
                {
                  usersForVerify.map((user) => (
                    <div key={user.id}>
                      <div
                        onClick={() => setFieldValue(user.id, !values[user.id])}
                        className='d-flex justify-content-between
                          align-items-center cursor-pointer'
                      >
                        <div className='d-flex align-items-center h-2'>
                          <Avatar name={user.name} className='me-2' size='lg' url={user.avatar} />

                          <Truncate className='text-darker-gray width-5' as='span'>
                            {user.name}
                          </Truncate>
                        </div>
                        <div className='d-flex align-items-center'>
                          <FormField
                            as={Checkbox}
                            name={`${user.id}`}
                            label=''
                            className='m-auto'
                          />
                        </div>
                      </div>

                      <hr className='my-2' />
                    </div>
                  ))
                }
              </div>

              <Button className='w-100' type='submit' disabled={!hasSelectedValues || isSending}>
                Enviar e-mail
              </Button>
            </>
          );
        }
      }
    </Form>
  );
}

EmailVerifyForm.propTypes = propTypes;
EmailVerifyForm.defaultProps = defaultProps;

export default EmailVerifyForm;
