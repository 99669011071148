import React from 'react';
import Button from '@/components/Button';
import ExternalLink from '@/components/ExternalLink';
import Icon from '@/components/Icon';

function WhatsappAddonsCard() {
  return (
    <div className='d-flex justify-content-between align-items-center py-5'>
      <div className='d-flex'>
        <div className='d-inline-block p-1 me-2'>
          <div
            className='p-1 rounded bg-primary bg-opacity-10 text-dark-gray lh-0 position-relative'
          >
            <Icon name='whatsapp' />
          </div>
        </div>
        <div>
          <h5>Agendor Whatsapp</h5>
          <span className='text-small text-medium-gray'>
            Atenda seus clientes e venda
            <br/>mais sem sair do WhatsApp!
          </span>
        </div>
      </div>
      <div className='d-flex flex-column align-items-end'>
        <h5 className='me-1 mb-1'>Grátis para o seu plano</h5>
        <Button
          variant='outline-primary'
          size='sm'
          className='mb-1 width-4'
          as={ExternalLink}
          target={'_blank'}
          href='https://chrome.google.com/webstore/detail/crm-de-vendas-para-whatsa/gmpkcemlmfebbppdonfmfanggndocjec?hl=pt-BR'
        >
          <Icon name='download' size='sm' className='me-1' />
          Baixar
        </Button>
        <Button
          variant='outline-primary'
          size='sm'
          className='width-4'
          as={ExternalLink}
          target={'_blank'}
          href='https://ajuda.agendor.com.br/pt-BR/articles/6121363-como-funciona-a-extensao-whatsapp-web-do-agendor-no-google-chrome'
        >
          <Icon name='external-link' size='sm' className='me-1' />
          Saiba mais
        </Button>
      </div>
    </div>
  );
}

export default WhatsappAddonsCard;
