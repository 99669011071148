import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useAlert } from 'react-alert';
import { useAuth } from '@/lib/auth';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import CustomFieldFormModal from '@/components/CustomFieldFormModal';
import Authorization from '@/components/Authorization';
import { canEditCustomField } from '@/policies';
import { useTracking } from '@/lib/tracking';

const propTypes = {
  data: PropTypes.object.isRequired,
  mutation: PropTypes.object.isRequired,
  entity: PropTypes.string.isRequired
};

const defaultProps = {};

function EditCustomFields({ data, mutation, entity }) {
  const { user } = useAuth();
  const tracker = useTracking();
  const alert = useAlert();
  const [showModal, setShowModal] = useState(false);

  const isAdmin = user && user.admin;

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleFormSuccess = () => {
    alert.show(
      'Campo customizado editado!',
      { variant: 'success', timeout: 5000 }
    );
    tracker.trackCustomFieldEdited({ user, entity, type: data.type });
    handleCloseModal();
  };

  return (
    <>
      <Authorization
        policy={canEditCustomField}
        /*
         * Foi necessario incluir tooltipClassName='mx-4' para adicionar uma
         * espaço seguro no hover entre tooltip de edit e delete, caso contrario
         * o tooltip se esconde ao transitar de um botao para o outro.
         * No futuro, uma soluçao ideal seria usar prop offset do componente Tooltip
         */
        tooltipClassName='mx-4'
      >
        <Button
          id={`edit-field-${data.identifier}`}
          onClick={handleOpenModal}
          size='sm'
          variant='link'
          className='px-1 py-2 border-0 cursor-pointer'
          aria-label='Editar campo'
        >
          <Icon
            name='edit-field'
            className={classnames(
              'cursor-pointer',
              { 'text-dark-gray': isAdmin }
            )}
          />
        </Button>
      </Authorization>
      <CustomFieldFormModal
        isEdit
        title='Editar campo customizado'
        show={showModal}
        section={data?.section}
        initialData={data}
        onHide={handleCloseModal}
        mutation={mutation}
        onFormSuccess={handleFormSuccess}
      />
    </>
  );
}

EditCustomFields.propTypes = propTypes;
EditCustomFields.defaultProps = defaultProps;

export default EditCustomFields;
