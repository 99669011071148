import React from 'react';
import Main from '@/components/Main';
import FunnelsList from '@/pages/funnels/_list';
import { useDocumentTitle } from '@/hooks';
import { useFunnels } from '@/api';

const PAGE_TITLE = 'Personalizar funis';

function FunnelsIndex() {
  const { data: { data } = {}, isSuccess } = useFunnels();

  useDocumentTitle(PAGE_TITLE);

  if (!isSuccess) {
    return <div className='flex-grow-1 bg-light' />;
  }

  return (
    <Main>
      <h1 className='mb-6'>
        Personalizar funis
      </h1>

      <FunnelsList funnels={data} />
    </Main>
  );
}

export default FunnelsIndex;
