import React, { useMemo, useState, useEffect } from 'react';
import * as Yup from 'yup';
import Card from '@/components/Card';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import PropTypes from 'prop-types';
import { TextInput } from '@/components/Inputs';
import { toCurrencyString } from '@/number';
import SkeletonWrapper from '@/components/SkeletonWrapper';
import BillingFooter from '@/components/Billing/BillingFooter';
import { useCheckoutContext } from '@/contexts';
import { useDebounce } from '@/hooks';
import { redirect } from '@/browser';
import { legacyPlansUrl, legacyUsersUrl } from '@/routes';
import Button from '@/components/Button';
import CouponInput from '@/components/Billing/Steps/CardPlanDetails/CouponInput';
import PlanSelect from '@/components/Billing/Steps/CardPlanDetails/PlanSelect';
import Icon from '@/components/Icon';
import ExternalLink from '@/components/ExternalLink';
import { pluralize } from '@/utils';

const propTypes = {
  maxUsersCount: PropTypes.number,
  onChangeUsersCount: PropTypes.func,
  currentValuePerUser: PropTypes.number,
  currentActiveUsersCount: PropTypes.number,
  isTrial: PropTypes.bool,
  periodicity: PropTypes.string,
  planName: PropTypes.string
};

/* eslint-disable no-magic-numbers */
const schema = Yup.object().shape({
  users_count: Yup.number()
    .required('Número de usuários é obrigatório.')
    .positive('A quantidade mínima de usuários é 1.')
});
/* eslint-enable no-magic-numbers */

const defaultValues = {
  users_count: 0
};

const defaultDebounceTime = 600;

const periodicityText = {
  monthly: 'mensalmente',
  quarterly: 'trimestralmente',
  half_yearly: 'semestralmente',
  yearly: 'anualmente'
};

function CardPlanDetails({ maxUsersCount, currentValuePerUser,
  onChangeUsersCount, currentActiveUsersCount, periodicity, planName }) {
  const [showInput, setShowInput] = useState(false);
  const [error, setError] = useState('');
  const { nextStep } = useCheckoutContext();

  const debounceHandler = useDebounce((fn) => fn(), defaultDebounceTime);

  const initialValues = useMemo(() => ({ users_count: maxUsersCount }), [maxUsersCount]);

  useEffect(
    () => {
      const difference = currentActiveUsersCount - maxUsersCount;

      if (maxUsersCount > 0 && maxUsersCount < currentActiveUsersCount) {
        setError(
          `Sua conta tem ${currentActiveUsersCount} ` +
          `${pluralize('usuário', currentActiveUsersCount)}  ativos.\nDesative ${difference} ` +
          `${pluralize('usuário', difference)} para poder contratar ` +
          `o ${planName} ${maxUsersCount}.`
        );
      } else {
        setError('');
      }
    },
    [maxUsersCount]
  );

  return (
    <>
      <PlanSelect />

      <Card className='p-6 border-light'>
        <Form
          name='form'
          validationSchema={schema}
          defaultValues={defaultValues}
          initialValues={initialValues}
          onSubmit={nextStep}
        >
          {
            ({ handleSubmit }) => <>
              <div className='text-dark-gray fw-bold mb-4'>
                Quantas pessoas da sua empresa irão utilizar o Agendor? (incluindo você)
              </div>

              <div>
                <div className='fw-bold text-small'>Número de usuários</div>
                <FormField
                  as={TextInput}
                  name='users_count'
                  onChange={(value) => debounceHandler(() => onChangeUsersCount(Number(value)))}
                  className='max-width-5 mb-1'
                  type='number'
                  placeholder='4'
                  min='1'
                />
              </div>

              <SkeletonWrapper
                isLoading={!currentValuePerUser}
                SkeletonComponent={() => <div>Carregando</div>}
              >
                <div className='d-flex mb-1'>
                  <h1>{toCurrencyString(currentValuePerUser)}</h1>
                  <h1 className='mx-1'>/</h1>
                  <h4 className='fw-normal align-self-end mb-1'>por usuário</h4>
                </div>
              </SkeletonWrapper>

              <div className='fw-bold text-medium mt-2 mb-1'>
                Valor mensal, pago {periodicityText[periodicity]}
              </div>

              {error && (
                <div className='mt-4 yellow text-warning mb-1' style={{ whiteSpace: 'pre-line' }}>
                  {error}
                  <div className='mt-1'>
                    <Button as={ExternalLink}
                      className='me-3'
                      variant='outline-dark-gray'
                      href={legacyUsersUrl()}
                    >
                      <Icon name='edit' size='sm' className='me-1' />
                      Gerenciar usuários
                    </Button>
                  </div>
                </div>
              )}

              <BillingFooter
                buttonNextText='Avançar'
                onClickPrev={() => redirect(legacyPlansUrl())}
                onClickNext={handleSubmit}
                disabled={error}
              />
            </>
          }
        </Form>

        <Button
          className='mb-1 text-underline p-0 text-dark-gray text-small align-self-start'
          onClick={() => setShowInput(true)}
          variant='link'
        >
          Aplicar cupom
        </Button>

        {showInput && <CouponInput />}
      </Card>
    </>
  );
}

CardPlanDetails.propTypes = propTypes;

export default CardPlanDetails;
