import React from 'react';
import PropTypes from 'prop-types';
import Base from 'react-bootstrap/Table';

const propTypes = {
  children: PropTypes.node,
  hover: PropTypes.bool,
  className: PropTypes.string
};

const defaultProps = {
  hover: false,
  className: ''
};

const Table = React.forwardRef(
  ({ children, ...props }, ref) => (
    <Base { ...props } ref={ref}>
      {children}
    </Base>
  )
);

Table.propTypes = propTypes;
Table.defaultProps = defaultProps;
Table.displayName = 'Table';

export default Table;
