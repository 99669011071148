import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Form from '@/components/Form';
import { useStoredState } from '@/hooks';

const propTypes = {
  as: PropTypes.elementType,
  children: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  initialSubmit: PropTypes.bool,
  initialValues: PropTypes.any
};

const defaultProps = {
  as: Form,
  className: '',
  initialSubmit: true,
  initialValues: {}
};

function StoredForm(props) {
  const {
    as: Component, children, onSubmit, name, defaultValues,
    initialSubmit, initialValues, ...otherProps
  } = props;
  const { store, initialValue: storedInitialValues } = useStoredState(name);

  const memoKey = JSON.stringify(initialValues);

  const formInitialValues = useMemo(() => ({
    ...storedInitialValues,
    ...initialValues
  }), [memoKey]);

  const handleSubmit = useCallback((data) => {
    store(data);
    onSubmit(data);
  }, [store, onSubmit]);

  return (
    <Component
      name={name}
      defaultValues={defaultValues}
      initialValues={formInitialValues}
      initialSubmit={initialSubmit}
      onSubmit={handleSubmit}
      { ...otherProps }
    >
      {(formProps) => children({ ...formProps, store })}
    </Component>
  );
}

StoredForm.propTypes = propTypes;
StoredForm.defaultProps = defaultProps;

export default StoredForm;
