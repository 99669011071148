import React, { useState } from 'react';
import { useAlert } from 'react-alert';

import Alert from '@/components/Alert';
import Icon from '@/components/Icon';
import Tooltip from '@/components/Tooltip';
import ExternalLink from '@/components/ExternalLink';
import { EmailVerifyModal } from '@/feature/email';
import LoadingButton from '@/components/LoadingButton';
import APIErrorMessage from '@/components/APIErrorMessage';
import { useCreateEmailSender, useEmailSender } from '@/api';
import { helpUrl } from '@/routes';
import PropTypes from 'prop-types';

const propTypes = {
  userHasVerified: PropTypes.bool,
  isLoading: PropTypes.bool
};

const defaultProps = {
  userHasVerified: false,
  isLoading: false
};

function EmailVerifyAlert({ userHasVerified, isLoading }) {
  const [showModal, setShowModal] = useState(false);
  const onHideModal = () => setShowModal(false);

  useEmailSender({
    /**
     * Desabilita a query enquanto o modal estiver aberto, para que ela só seja
     * refeita quando o modal for fechado.
     */
    config: { enabled: !showModal }
  });

  const alert = useAlert();
  const { mutate: createEmailSender, isLoading: isCreating } = useCreateEmailSender({
    config: {
      onSuccess: () => {
        setShowModal(true);
      },
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='email_sender' action='create' />,
          { variant: 'danger', timeout: 5000 }
        );
      }
    }
  });

  if (userHasVerified || isLoading) {
    return null;
  }

  return (
    <Alert className='w-100 mt-3 my-0' variant='danger'>
      <div className='d-flex align-items-center'>
        <span className='fw-bold pt-1'>
          Seu endereço de e-mail precisa ser verificado
        </span>

        <ExternalLink
          className='text-dark-gray mx-2'
          target='_blank'
          href={helpUrl('email-sender')}
        >
          <Tooltip content='
            Para que seus clientes vejam o seu e-mail (ou dos usuários da sua conta)
            como remetente, precisamos garantir que o endereço é seu. Clique para saber mais.'
          >
            <div>
              <Icon name='info' />
            </div>
          </Tooltip>
        </ExternalLink>

        <LoadingButton
          size='sm'
          className='ms-auto text-small'
          variant='white'
          onClick={createEmailSender}
          isLoading={isCreating}
          loadingText='Verificando...'
        >
          Verificar e-mail
        </LoadingButton>
      </div>
      <EmailVerifyModal
        show={showModal}
        onHide={onHideModal}
      />
    </Alert>
  );
}

EmailVerifyAlert.prototypes = propTypes;
EmailVerifyAlert.defaultProps = defaultProps;

export default EmailVerifyAlert;
