import React from 'react';
import PropTypes from 'prop-types';
import Base from 'react-bootstrap/Card';

const propTypes = {
  children: PropTypes.node
};

function Content({ children }) {
  return (
    <Base.Text as='div' className='text-dark-gray text-small'>
      {children}
    </Base.Text>
  );
}

Content.propTypes = propTypes;
Content.displayName = 'Card.Content';

export default Content;
