import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTracking } from '@/lib/tracking';
import { useAuth } from '@/lib/auth';
import { useStoredState } from '@/hooks';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Card from '@/components/Card';
import {
  CardOverlay,
  CardRibbon,
  TemplateCardBox,
  TemplateIcon,
  TemplateTriggerBox
} from '@/feature/automation';

const propTypes = {
  identifier: PropTypes.string.isRequired,
  children: PropTypes.node
};

function SpoilerCard(props) {
  const { identifier, children } = props;

  const tracker = useTracking();
  const { user } = useAuth();
  const key = `automations.spoiler_card.${identifier}`;
  const { value: interested, store } = useStoredState(key, false);

  const onClick = () => {
    store(true);
    tracker.trackAutomationCardSpoilerClicked({
      user,
      automationName: identifier
    });
  };

  const buttonVariant = interested ? 'light-green' : 'lighter-gray';
  const buttonIcon = interested ? 'star' : 'star-outlined';

  return (
    <div
      className= {classnames(
        'hover-parent position-relative rounded overflow-hidden border-1 border bg-medium-gray',
        {
          'bg-flat-green border-flat-green': interested,
          'border-medium-gray': !interested
        }
      )}
    >
      <Card
        className='p-4 border-0 max-width-5 bg-medium-gray text-dark-gray cursor-pointer'
      >
        {children}
      </Card>

      <CardOverlay
        className='bg-opacity-70 justify-content-center'
      >
        <Button
          className='w-100 mb-6'
          variant={buttonVariant}
          disabled={interested}
          onClick={onClick}
        >
          <Icon
            size='sm'
            className='me-1'
            name={buttonIcon}
          />

          { interested ? 'Interesse confirmado!' : 'Tenho interesse' }
        </Button>

        <div className='text-white fw-bold'>
          Disponível em breve
        </div>
      </CardOverlay>

      {interested && <CardRibbon/>}
    </div>
  );
}

SpoilerCard.Icon = (props) => <TemplateIcon { ...props } variant='spoiler' />;
SpoilerCard.Icon.displayName = 'SpoilerCard.Icon';

SpoilerCard.TriggerBox = (props) => <TemplateTriggerBox { ...props } variant='spoiler' />;
SpoilerCard.TriggerBox.displayName = 'SpoilerCard.TriggerBox';

SpoilerCard.ActionBox = (props) => <TemplateCardBox { ...props } variant='spoiler' />;
SpoilerCard.ActionBox.displayName = 'SpoilerCard.ActionBox';

SpoilerCard.propTypes = propTypes;

export default SpoilerCard;
