import React, { useEffect, useRef } from 'react';
import {
  AreaCodeSelect,
  CitySelect,
  DateRangeInput,
  DealStatusSelect,
  ForgottenSelect,
  ImportSelect,
  LeadOriginSelect,
  OrganizationSelect,
  PersonSelect,
  ProductSelect,
  SectorSelect,
  StateSelect,
  TeamSelect,
  UserSelect
} from '@/components/Inputs';
import FormField from '@/components/FormField';
import DependentFormField from '@/components/DependentFormField';
import { DATE_RANGE_DEFAULT_PRESETS } from '@/date-range';
import { useDealsCustomFields, useCustomer } from '@/api';
import CustomFieldsFilters from '@/components/Filters/CustomFieldsFilters';
import LoadingWrapper from '@/components/LoadingWrapper';
import { useFormikContext } from 'formik';

function BacklogDealFilters() {
  const { values } = useFormikContext();
  const firstRenderRef = useRef(true);

  useEffect(() => {
    /*
     * Faz a alteração após o primeiro render, para as queries de `useCustomer`
     * serem chamadas apenas quando esse componente for montado.
     */
    firstRenderRef.current = false;
  }, []);

  const {
    data: {
      data = []
    } = {},
    isSuccess
  } = useDealsCustomFields();

  /**
   * Essas queries são para exibir o valor inicial da Empresa e Pessoa quando já preenchidas
   * anteriormente, essa lógica poderia no futuro ser interna desses componentes de Select.
   */
  const { data: person, isLoading: loadingPerson } = useCustomer({
    customerId: values.person_id_eq,
    params: {
      type: 'person'
    },
    config: {
      enabled: Boolean(values.person_id_eq) && firstRenderRef.current
    }
  });

  const { data: organization, isLoading: loadingOrganization } = useCustomer({
    customerId: values.organization_id_eq,
    params: {
      type: 'organization'
    },
    config: {
      enabled: Boolean(values.organization_id_eq) && firstRenderRef.current
    }
  });

  const loadingPersonOrOrganization = loadingPerson || loadingOrganization;

  return (
    <LoadingWrapper isLoading={!isSuccess || loadingPersonOrOrganization}>
      <FormField as={DealStatusSelect} name='deal_status_id_eq' />

      <FormField
        as={ProductSelect}
        name='products_id_in'
        multiple
        isSearchable
        allowNull
        queryArgs={{ params: { per_page: 600 } }}
      />

      <FormField
        as={UserSelect}
        name='owner_user_id_in'
        label='Responsável'
        multiple
        isSearchable
        allowNull
      />

      <FormField
        as={ForgottenSelect}
        name='forgotten'
      />

      <FormField
        as={StateSelect}
        name='state_in'
        multiple
        isSearchable
        allowNull
      />

      <DependentFormField
        as={CitySelect}
        name='city_id_in'
        source='state_in'
        target='state'
        multiple
        isSearchable
        allowNull
      />

      <FormField
        as={AreaCodeSelect}
        name='area_code_in'
        multiple
        isSearchable
        allowNull
      />

      <FormField
        as={SectorSelect}
        name='sector_id_in'
        multiple
        isSearchable
        allowNull
      />

      <FormField
        as={LeadOriginSelect}
        name='lead_origin_id_in'
        multiple
        isSearchable
        allowNull
      />

      <FormField
        as={OrganizationSelect}
        name='organization_id_eq'
        creatable={false}
        className='mb-4'
        defaultValue={organization?.data.name}
      />

      <FormField
        as={PersonSelect}
        name='person_id_eq'
        className='mb-4'
        defaultValue={person?.data.name}
      />

      <FormField
        as={TeamSelect}
        name='team_id_in'
        multiple
        isSearchable
      />

      <FormField
        as={ImportSelect}
        name='import_id_in'
        entity='deals'
        multiple
        allowNull
      />

      <FormField
        as={DateRangeInput}
        name='created_at_utc_date_within'
        label='Data de cadastro'
        presets={DATE_RANGE_DEFAULT_PRESETS}
      />

      <FormField
        as={DateRangeInput}
        name='started_at_local_date_within'
        label='Data de início'
        presets={DATE_RANGE_DEFAULT_PRESETS}
      />

      <FormField
        as={DateRangeInput}
        name='finished_at_local_date_within'
        label='Data de conclusão'
        presets={DATE_RANGE_DEFAULT_PRESETS}
      />

      <CustomFieldsFilters fields={data} />
    </LoadingWrapper>
  );
}

const defaultValues = {
  deal_status_id_eq: null,
  products_id_in: null,
  owner_user_id_in: null,
  forgotten: null,
  state_in: null,
  city_id_in: null,
  area_code_in: null,
  sector_id_in: null,
  lead_origin_id_in: null,
  organization_id_eq: null,
  person_id_eq: null,
  team_id_in: null,
  import_id_in: null,
  created_at_utc_date_within: null,
  started_at_local_date_within: null,
  finished_at_local_date_within: null,
  custom_fields: {}
};

export default BacklogDealFilters;
export { defaultValues };
