import React from 'react';
import PropTypes from 'prop-types';
import times from 'lodash/times';
import classnames from 'classnames';
import Skeleton from '@/components/Skeleton';

function TableIndexSkeleton({ index, iconless }) {
  return (
    <div className={classnames(
      'd-flex align-items-center',
      {
        'py-2': !iconless,
        'height-2': iconless
      }
    )}>
      <div className={classnames({ 'px-3': !iconless })}>
        <div className='me-2 width-2 text-end text-medium-gray'>
          {`${index}`.padStart(2, '0')}
        </div>
      </div>
      {
        !iconless && (
          <div className='px-4 py-2 rounded bg-primary bg-opacity-10 d-inline-block ms-2 me-4'>
            <Skeleton />
          </div>
        )
      }
      <div className='width-4'>
        <Skeleton />
      </div>
    </div>
  );
}

function TableCellSkeleton() {
  return (
    <div className='width-4'>
      <Skeleton />
    </div>
  );
}

const MAX_ROWS = 20;
const MAX_COLS = 10;

const propTypes = {
  maxRows: PropTypes.number,
  maxColumns: PropTypes.number,
  noIndex: PropTypes.bool
};

const defaultProps = {
  maxRows: MAX_ROWS,
  maxCols: MAX_COLS,
  noIndex: false
};

function EntitySkeleton({ maxRows, maxCols, iconless, noIndex }) {
  return times(maxRows, (rowIndex) => (
    <tr key={rowIndex}>
      {
        noIndex
          ? null
          : (
            <td className={classnames(
              'position-sticky',
              'bg-white',
              'start-0',
              'align-middle',
              'border-end',
              'border-1',
              {
                'p-0': !iconless
              }
            )}>
              <TableIndexSkeleton iconless={iconless} index={rowIndex + 1} />
            </td>
          )
      }
      {times(maxCols, (colIndex) => (
        <td key={colIndex} className='align-middle'>
          <TableCellSkeleton />
        </td>
      ))}
      {noIndex ? null : <td className='align-middle'></td>}
      {noIndex ? null : <td className='align-middle'></td>}
    </tr>
  ));
}


EntitySkeleton.propTypes = propTypes;
EntitySkeleton.defaultProps = defaultProps;

export default EntitySkeleton;
