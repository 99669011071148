import { useQuery, useQueryClient, useMutation } from 'react-query';
import { get, post } from '@/lib/fetch';

export const emailSendersKeys = {
  all: ['email-senders'],
  list: (params) => (['email-senders-list', params])
};

export const getEmailSender = async () => {
  const { data } = await get('/email_sender');
  return data;
};

export const createEmailSender = async () => {
  const { data } = await post('/email_sender');

  return data;
};

export async function createVerifyList(params = {}) {
  const { data } = await post('/email_sender/verify_list', params);

  return data;
}

export const getEmailSenderList = async (params = {}) => {
  const { data } = await get('/email_sender/list', params);

  return data;
};

export function useEmailSender({ config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: emailSendersKeys.all,
    queryFn: () => getEmailSender()
  });
}

export function useCreateEmailSender({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries(emailSendersKeys.all);

  return useMutation(
    createEmailSender,
    {
      onSuccess,
      ...config
    }
  );
}

export function useCreateVerifyList({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries(emailSendersKeys.all);

  return useMutation(
    createVerifyList,
    {
      onSuccess,
      ...config
    }
  );
}

export function useEmailSenderList({ config = {}, params = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: emailSendersKeys.list(params),
    queryFn: () => getEmailSenderList(params)
  });
}
