import React, { useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';

import { isNotFoundError } from '@/errors';
import { legacyPlansUrl } from '@/routes';
import { redirect } from '@/browser';
import { useCurrentAccount, useCurrentPlan, useSubscriptionPreview } from '@/api';
import { useDocumentTitle, useQueryParams, useStepper } from '@/hooks';
import { useOffcanvasPageContext, UpdateSubscriptionProvider } from '@/contexts';

import Button from '@/components/Button';
import ConfirmUpdatePlanStep from '@/components/Billing/ConfirmUpdatePlanStep';
import Footer from '@/components/Footer';
import Icon from '@/components/Icon';
import Main from '@/components/Main';
import PreviewProratedPlanStep from '@/components/Billing/PreviewProratedPlanStep';
import { parseDate, toTextualDateString } from '@/date';
import { useHeaderPerformanceWarningOct } from '@/components/HeaderPerformanceWarningOct';

const PAGE_TITLE = 'Alterar plano';

function UpdatePlan({ currentPlanData, accountData }) {
  useDocumentTitle(PAGE_TITLE);
  const canvasContext = useOffcanvasPageContext();

  const {
    new_plan_code: queryPlan,
    new_plan_max_users: queryMaxUsers
  } = useQueryParams();

  const planCode = queryPlan || currentPlanData.productCode;
  const maxUsers = Number(queryMaxUsers) || currentPlanData.maxUsersCount;

  const { nextStep, prevStep, step } = useStepper(1);
  const [selectedMaxUsers, setSelectedMaxUsers] = useState(maxUsers);
  const [selectedPlan, setSelectedPlan] = useState(planCode);
  const [updatedSubscriptionPreview, setUpdatedSubscriptionPreview] = useState();
  const [currentSubscritionPreview, setCurrentSubscriptionPreview] = useState();
  const shouldShowPerformanceNotice = useHeaderPerformanceWarningOct();
  const formattedExpirationDate = toTextualDateString(parseDate(currentPlanData.expirationDate));

  const [isWarningVisible, setIsWarningVisible] = useState(true);
  const handleWarningClose = () => {
    setIsWarningVisible(false);
  };

  const {
    isSuccess: loadedSubscriptionPreview
  } = useSubscriptionPreview({
    config: {
      cacheTime: 0,
      onSuccess: ({ data }) => setUpdatedSubscriptionPreview(data)
    },
    params: {
      period: currentPlanData.periodicity,
      product_code: selectedPlan,
      users_count: selectedMaxUsers,
      prorate: true,
      october_campaign: shouldShowPerformanceNotice
    }
  });

  const { isSuccess: loadedCurrentSubscriptionPreview } = useSubscriptionPreview({
    params: {
      period: currentPlanData.periodicity,
      product_code: currentPlanData.productCode,
      users_count: currentPlanData.maxUsersCount
    },
    config: {
      onSuccess: ({ data }) => setCurrentSubscriptionPreview(data)
    }
  });

  const closePlanConfig = canvasContext.hide;

  const value = {
    accountData,
    currentPlanData,
    selectedMaxUsers,
    setSelectedMaxUsers,
    selectedPlan,
    setSelectedPlan,
    loadedSubscriptionPreview,
    updatedSubscriptionPreview,
    loadedCurrentSubscriptionPreview,
    currentSubscritionPreview,
    closePlanConfig,
    nextStep,
    prevStep
  };

  const CurrentStep = useMemo(() => {
    const steps = [
      <PreviewProratedPlanStep key={1} />,
      <ConfirmUpdatePlanStep key={2} />
    ];

    return steps[step];
  }, [step]);

  return (
    <UpdateSubscriptionProvider value={value}>
      <Main fluid className='pt-6 px-5'>
        <Container className='d-flex h-100 flex-column'>
          <div className='d-flex align-items-start'>
            <div className='flex-fill'>
              <h2 className='mb-2'>{PAGE_TITLE}</h2>
            </div>

            <Button
              className={'rounded border-0 px-2 py-1'}
              variant='light-gray'
              type='button'
              aria-label='Fechar'
              onClick={closePlanConfig}
            >
              <Icon className='text-dark-gray' name='close' />
            </Button>
          </div>

          <hr className='mb-7' />

          {shouldShowPerformanceNotice && isWarningVisible && (
            <div className='p-4 rounded mb-7 bg-light-yellow'>
              <div className='col d-flex align-items-center justify-content-between'>
                <div className='text-medium'>
                  <p>
                    Você poderá usar as funcionalidades do Plano Performance de forma gratuita
                    até <b> 01 de outubro</b>.
                  </p>
                    O valor da pró-rata é referente ao período de <b>01 de outubro</b> até
                    a renovação da sua conta, no dia <b>{formattedExpirationDate}</b>
                </div>
                <div className='cursor-pointer ms-1 me-2'>
                  <Icon
                    name='close'
                    size='md'
                    onClick={handleWarningClose}
                  />
                </div>
              </div>
            </div>
          )}

          {CurrentStep}

        </Container>
      </Main>
      <Footer id='manage-plan-footer' className='mt-auto flex-shrink-0 pb-3 ps-6 pe-9 me-9' />
    </UpdateSubscriptionProvider>
  );
}

function UpdatePlanWrapper() {
  const {
    data: { data: currentPlanData } = {}
  } = useCurrentPlan(
    {
      config: {
        onError: (err) => {
          if (isNotFoundError(err)) {
            redirect(legacyPlansUrl());
          }
        }
      }
    }
  );

  const { data: { data: accountData } = {} } = useCurrentAccount();

  if (!currentPlanData || !accountData) {
    return null;
  }

  return (
    <UpdatePlan currentPlanData={currentPlanData} accountData={accountData} />
  );
}

export default UpdatePlanWrapper;
