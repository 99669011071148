import React, { useRef, useCallback } from 'react';
import Base from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import OverlayClose from '@/components/OverlayClose';

const propTypes = {
  bodyClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  backdrop: PropTypes.oneOf(['static', true, false]),
  disableClose: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'xxl', 'xxxl']),
  hideClose: PropTypes.bool,
  baseClassName: PropTypes.string
};

const defaultProps = {
  bodyClassName: null,
  backdrop: true,
  disableClose: false,
  size: 'md',
  hideClose: false,
  baseClassName: ''
};

function Modal(props) {
  const {
    id, bodyClassName, children, backdrop, baseClassName,
    onEntered, onHide, disableClose, hideClose, ...rest
  } = props;

  /**
   * O atributo `autoFocus` do input não funciona se o modal for animado.
   * ref: https://github.com/react-bootstrap/react-bootstrap/issues/5102
   *
   * Para corrigir isso, foi criado um `onEntered` padrão que foca o primeiro
   * filho que tiver o atributo `data-autofocus`.
   * Não foi utilizado o atributo `autoFocus` em si porque ele não é incluído
   * no DOM pelo React, e outras grafias dele (ex `autofocus`) geram warning.
   * ref: https://github.com/facebook/react/issues/11851
   */
  const bodyRef = useRef(null);
  const handleEntered = useCallback(() => {
    if (bodyRef.current) {
      const target = bodyRef.current.querySelector('[data-autofocus]');

      if (target) {
        target.focus();
      }

      /*
       * O bootstrap não permite injetar valores na Div pai do modal, foi necessário
       * injetar a prop id para o componente InfinitScroll consiga fazer o refetch dentro do modal
       */
      const mainModalEl = bodyRef.current?.parentElement?.parentElement?.parentElement;

      if (id && mainModalEl) {
        mainModalEl.id = id;
      }
    }

    // Forca um reload no userpilot ao abrir um modal de pagina que nao mudou url
    window?.userpilot?.reload?.();

    if (onEntered) {
      onEntered();
    }
  }, [onEntered]);

  return (
    <Base
      className={baseClassName}
      backdrop={backdrop}
      centered
      onEntered={handleEntered}
      /*
       * Essa propriedade foi adicionada pois na nova ficha ao selecionar o calendário para alterar
       * o status de um negócio, o select dos motivos de perda não estava ficando ativo/clicável,
       * por se tratar de um popover com select dentro do modal.
       * Ref.: https://github.com/react-bootstrap/react-bootstrap/issues/5846
       */
      enforceFocus={false}
      onHide={onHide}
      { ...rest }
    >
      <Base.Body
        className={bodyClassName}
        ref={bodyRef}
      >
        {!hideClose && <OverlayClose onClose={onHide} disabled={disableClose} />}

        {children}
      </Base.Body>
    </Base>
  );
}

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default Modal;
