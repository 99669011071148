import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import { useAlert } from 'react-alert';
import FunnelBacklog from '@/components/FunnelBacklog';
import FunnelBoard from '@/components/FunnelBoard';
import CreateDealModal from '@/components/CreateDealModal';
import Drawer from '@/components/Drawer';
import APIErrorMessage from '@/components/APIErrorMessage';
import RequiredFieldsModal, { useRequiredFieldsModal } from '@/components/RequiredFieldsModal';
import { useBoardDrag } from '@/hooks/use-board-drag';
import { FunnelProvider } from '@/contexts';
import { useStoredState } from '@/hooks';
import { useFunnelStageMove } from '@/api';
import { useTracking } from '@/lib/tracking';
import { useAuth } from '@/lib/auth';
import { dealPath } from '@/routes';

export const BACKLOG_ID = 'backlog';
const BACKLOG_DRAWER_KEY = 'funnel.backlog.drawer-open';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    stages: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })),
    color: PropTypes.string
  }).isRequired,
  meta: PropTypes.shape({
    funnels: PropTypes.arrayOf(PropTypes.object)
  })
};

function Funnel({ data }) {
  const moveMutation = useFunnelStageMove();
  const alert = useAlert();
  const { user } = useAuth();
  const tracker = useTracking();
  const [showCreateDealModal, setShowCreateDealModal] = useState(false);
  const [currentMove, setCurrentMove] = useState();
  const navigate = useNavigate();
  const hasAccess = user.account.legacyPro || user.account.performanceOrHigher;

  const {
    store: setBacklogDrawerState,
    value: isBacklogOpen
  } = useStoredState(BACKLOG_DRAWER_KEY, true);

  useEffect(() => {
    tracker.trackFunnelViewed({ user, isBacklogOpen });
  }, []);

  const {
    check: checkRequiredFields,
    ...requiredFieldsModal
  } = useRequiredFieldsModal();
  const onMove = useCallback(async (move, dispatch) => {
    const [type, id] = move.itemId.split('-');
    const targetStageId = move.destinationColumnId;

    const entity = {
      type,
      id,
      name: move.sourceData.title,
      person: move.sourceData.person,
      organization: move.sourceData.organization
    };

    setCurrentMove({ ...move, entity, stageId: targetStageId });

    if (move.fromBacklog) {
      setShowCreateDealModal(true);
      return;
    }

    if (data.hasRequiredFields && hasAccess) {
      const dealHasRequiredFields = await checkRequiredFields(entity, {
        funnel_id: data.id,
        stage_id: targetStageId
      });

      if (dealHasRequiredFields) {
        return;
      }
    }

    moveMutation.mutate({
      funnelId: move.destinationFunnel,
      stageId: move.destinationColumnId,
      params: { fromBacklog: move.fromBacklog, itemId: move.itemId }
    }, {
      onSuccess: ({ data: cardData }) => {
        dispatch({ type: 'move_success', payload: { move, data: cardData } });
      },
      onError: (err) => {
        dispatch({ type: 'move_failure', payload: { move } });
        alert.show(
          <APIErrorMessage err={err} resource={type} action='move' />,
          { variant: 'danger' }
        );
      }
    });
  }, [moveMutation.mutate, alert]);

  const [{ columns }, dispatch] = useBoardDrag(
    getInitialColumns(data),
    onMove
  );

  const onCancelMove = () => {
    if (currentMove === undefined) {
      return;
    }

    dispatch({ type: 'move_failure', payload: { move: currentMove } });

    setShowCreateDealModal(false);
    requiredFieldsModal.onHide();
  };

  const onFinishMove = (cardData) => {
    dispatch({
      type: 'move_success',
      payload: { move: currentMove, data: cardData }
    });
  };

  const onFinishCreateDeal = (cardData) => {
    onFinishMove(cardData);
    setShowCreateDealModal(false);

    const [, dealId] = cardData.id.split('-');
    navigate(dealPath(dealId));
  };

  const onFinishRequiredFields = (cardData) => {
    onFinishMove(cardData);
    requiredFieldsModal.onFinish();
  };

  const {
    entity: createDealEntity = null, stageId: createDealStageId = null
  } = currentMove ? currentMove : {};

  const onDragStart = () => {
    dispatch({ type: 'drag_start' });
  };

  const onDragEnd = (result) => {
    dispatch({ type: 'drag_end', payload: { result } });
  };

  const getColumn = (id) => columns[id] || {};

  const context = { columns, dispatch, getColumn };

  return (
    <DragDropContext onBeforeDragStart={onDragStart} onDragEnd={onDragEnd}>
      <FunnelProvider value={context}>
        <Drawer
          className='display-flex flexible-height flex-grow-1 shadow-right'
          open={isBacklogOpen}
          onToggle={(open) => setBacklogDrawerState(open)}
          label='coluna lateral'
        >
          <FunnelBacklog funnelId={data.id} />
        </Drawer>

        <div className='flexible-height flex-grow-1 d-flex flexible-width'>
          <FunnelBoard
            funnel={data}
            stages={data.stages}
            isBacklogOpen={isBacklogOpen}
          />
        </div>

        <CreateDealModal
          show={showCreateDealModal}
          onHide={onCancelMove}
          onFinish={onFinishCreateDeal}
          funnel={data}
          stageId={createDealStageId}
          entity={createDealEntity}
        />

        <RequiredFieldsModal
          { ...requiredFieldsModal }
          onHide={onCancelMove}
          onFinish={onFinishRequiredFields}
        />
      </FunnelProvider>
    </DragDropContext>
  );
}

function getInitialColumns(data) {
  return data.stages.reduce(
    (acc, stage) => {
      acc[`funnel-${stage.id}`] = {
        funnelId: data.id,
        isBacklog: false,
        id: stage.id,
        order: stage.order,
        hasRequiredFields: data.hasRequiredFields
      };

      return acc;
    },
    {
      [BACKLOG_ID]: {
        funnelId: data.id,
        isBacklog: true,
        id: BACKLOG_ID,
        order: 0,
        hasRequiredFields: false
      }
    }
  );
}

Funnel.propTypes = propTypes;

export default Funnel;
