import { init } from '@sentry/react';
import { RAILS_ENV, SENTRY_DSN } from '@/config';

init({
  environment: RAILS_ENV,
  dsn: SENTRY_DSN
});

export { ErrorBoundary, captureException } from '@sentry/react';

