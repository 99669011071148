import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Main from '@/components/Main';
import Tabs from '@/components/Tabs';
import NavLink from '@/components/NavLink';
import Icon from '@/components/Icon';
import Breadcrumb from '@/components/Breadcrumb';
import { settingsFunnelsPath } from '@/routes';
import FunnelStagesList from '@/pages/funnels/stages/_list';
import FunnelStatusList from '@/pages/funnels/stages/_status-list';
import NotFound from '@/pages/not-found';
import { useDocumentTitle } from '@/hooks';
import { isNotFoundError } from '@/errors';
import { useFunnel, useStages } from '@/api';

function FunnelStagesIndex() {
  const { funnelId } = useParams();

  const {
    data: { data: funnel } = {},
    isSuccess: isFunnelSuccess,
    error
  } = useFunnel({ funnelId });

  const {
    data: { data: stages } = {},
    isSuccess: isStagesSuccess
  } = useStages({ params: { funnel_id_eq: Number(funnelId) } });

  const documentTitle = funnel ? `Etapas do ${funnel.name}` : null;

  useDocumentTitle(documentTitle);

  const location = useLocation();
  const defaultActiveTab = location.state?.target?.type ?? 'stage';

  if (error && isNotFoundError(error)) {
    return <NotFound />;
  }

  if (!isFunnelSuccess || !isStagesSuccess) {
    return <div className='flex-grow-1 bg-light' />;
  }

  return (
    <Main>
      <Breadcrumb>
        <Breadcrumb.Item href={settingsFunnelsPath()}>
          Personalizar funis
        </Breadcrumb.Item>

        <Breadcrumb.Item href='.' active>
          Etapas do {funnel.name}
        </Breadcrumb.Item>
      </Breadcrumb>

      <h2 className='mb-6'>Etapas do {funnel.name}</h2>

      <Tabs.Container defaultActiveKey={defaultActiveTab}>
        <Tabs>
          <Tabs.Item>
            <NavLink eventKey='stage'>
              <Icon name='funnel' size='sm' className='me-2' />

              Etapas do funil
            </NavLink>
          </Tabs.Item>

          <Tabs.Item>
            <NavLink eventKey='status'>
              <Icon name='won-deal' size='sm' className='me-2' />

              Status do negócio neste funil
            </NavLink>
          </Tabs.Item>
        </Tabs>


        <Tabs.Content>
          <Tabs.Pane eventKey='stage'>
            <FunnelStagesList funnelId={funnel.id} stages={stages} />
          </Tabs.Pane>

          <Tabs.Pane eventKey='status'>
            <FunnelStatusList funnelId={funnel.id} />
          </Tabs.Pane>
        </Tabs.Content>
      </Tabs.Container>
    </Main>
  );
}

export default FunnelStagesIndex;
