import React from 'react';
import { useQueryClient } from 'react-query';

import DetailsContact from '@/components/EntityModal/DetailsContact';
import { usePerson, useOnUpdate } from '@/contexts/personModal';
import { personEditPath } from '@/routes';
import { peopleKeys, usePeopleCustomFields, useUpdatePerson } from '@/api';
import { canEditOrganization } from '@/policies';
import APIErrorMessage from '@/components/APIErrorMessage';

function Sidebar() {
  const person = usePerson();
  const onUpdate = useOnUpdate();
  const entityEditPath = personEditPath(person.id);
  const queryClient = useQueryClient();
  const updatePersonMutation = useUpdatePerson();
  const entityType = 'person';

  const {
    data: { data: personCustomFields } = {},
    isLoading
  } = usePeopleCustomFields(
    { config: { staleTime: 1000 } }
  );

  const onRefreshModal = () => {
    queryClient.invalidateQueries(
      peopleKeys.detail(person.id.toString())
    );
  };

  const onUpdatePerson = (params, done, personData) => {
    updatePersonMutation.mutate({ personId: personData.id, params }, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='person' action='update' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.(err);
      },
      onSuccess: () => {
        alert.show(
          'Pessoa atualizada com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        onRefreshModal();
        done?.();
      }
    });
  };

  const entityCustomFields = personCustomFields || [];

  return (
    <div className='me-3'>
      <DetailsContact
        onEditEntity={onUpdate}
        entity={person}
        entityType={entityType}
        entityTypeModal={entityType}
        entityEditPath={entityEditPath}
        entityCustomFields={entityCustomFields}
        canEditPolicy={canEditOrganization}
        loading={isLoading}
        onCreatePerson={onRefreshModal}
        onEditPerson={onUpdatePerson}
        showTitle={false}
      />
    </div>
  );
}

export default Sidebar;
