import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import mixpanel from 'mixpanel-browser';
import { useAuth } from '@/lib/auth';
import { MIXPANEL_TOKEN } from '@/config';

const propTypes = {
  children: PropTypes.node.isRequired,
  shouldInitialize: PropTypes.bool
};

const defaultProps = {
  shouldInitialize: true
};

const MixpanelContext = createContext();

/*
 * Expõe mixpanel globalmente para poder ser utilizado em integrações
 * via Google Tag Manager.
 */
window.mixpanel = mixpanel;

export function MixpanelProvider({ children, shouldInitialize }) {
  const { user } = useAuth();

  const value = useMemo(() => {
    if (shouldInitialize) {
      /*
       * Opção `api_host` é necessária para CORS
       * ref: https://help.mixpanel.com/hc/en-us/articles/115004511086-CORS-errors
       */
      mixpanel.init(MIXPANEL_TOKEN, { api_host: 'https://api.mixpanel.com' });

      if (user) {
        mixpanel.identify(user.id);
      }
    }

    return { mixpanel };
  }, [shouldInitialize]);

  return (
    <MixpanelContext.Provider value={value}>
      {children}
    </MixpanelContext.Provider>
  );
}

MixpanelProvider.propTypes = propTypes;
MixpanelProvider.defaultProps = defaultProps;

export function useMixpanel() {
  const context = useContext(MixpanelContext);

  if (context === undefined) {
    throw new Error('useMixpanel must be used within MixpanelProvider');
  }

  return context.mixpanel;
}
