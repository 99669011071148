import React from 'react';
import { useCheckoutContext } from '@/contexts';
import CardPlanDetails from '@/components/Billing/Steps/CardPlanDetails';

function ChoosePlan() {
  const {
    usersCount,
    setUsersCount,
    checkoutResumeData,
    activeUsersCount
  } = useCheckoutContext();

  return (
    <CardPlanDetails
      currentValuePerUser={checkoutResumeData?.monthlyInfo.valuePerUser}
      maxUsersCount={usersCount}
      onChangeUsersCount={setUsersCount}
      currentActiveUsersCount={activeUsersCount}
      periodicity={checkoutResumeData.periodicity}
      planName={checkoutResumeData.name}
    />
  );
}

export default ChoosePlan;
