import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node
};

function PageTitle({ children }) {
  return (
    <h1>
      {children}
    </h1>
  );
}

PageTitle.propTypes = propTypes;
export default PageTitle;
