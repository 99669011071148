import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Stack from 'react-bootstrap/Stack';
import Icon from '@/components/Icon';

const propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    'standard',
    'spoiler',
    'primary'
  ]).isRequired
};

const defaultProps = {
  className: 'border-1'
};

function Connector(props) {
  const { className, variant } = props;

  return (
    <Stack
      className={classnames(
        'align-items-center',
        className,
        {
          'text-flat-green': variant === 'standard',
          'text-light-gray': variant === 'spoiler',
          'text-primary': variant === 'primary'
        }
      )}
    >
      <div className='py-1 border-start border-current' />

      <div className='border border-current rounded-circle'>
        <div className='d-flex border border-1 border-transparent'>
          <Icon
            name='lightning'
            size='sm'
            variant={variant}
          />
        </div>
      </div>

      <div className='py-1 border-start border-current' />
    </Stack>
  );
}

Connector.propTypes = propTypes;
Connector.defaultProps = defaultProps;

export default Connector;
