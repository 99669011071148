import React from 'react';
import Base from 'react-loading-skeleton';
import classnames from 'classnames';
import 'react-loading-skeleton/dist/skeleton.css';

function Skeleton({ className, ...rest }) {
  return (
    <Base
      { ...rest }
      className={classnames('bg-primary', 'bg-opacity-10', className)}
      enableAnimation={false}
    />
  );
}

export default Skeleton;
