import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import Skeleton from '@/components/Skeleton';
import SkeletonWrapper from '@/components/SkeletonWrapper';
import Icon from '@/components/Icon';
import ExternalLink from '@/components/ExternalLink';
import { importsKeys, useImport } from '@/api';
import {
  organizationsPath, organizationsImportPath,
  peoplePath, peopleImportPath,
  settingsProductsPath, productsImportPath, legacyTasksUrl, activitiesImportPath,
  dealsPath, dealsImportPath
} from '@/routes';
import { getString, pluralize } from '@/utils';
import fullPageGif from 'images/full-page-loading.gif';
import successImage from 'images/success.svg';

const propTypes = {
  importId: PropTypes.oneOfType(
    [PropTypes.number, PropTypes.string]
  ).isRequired,
  entity: PropTypes.oneOf(['people', 'organizations', 'products', 'activities', 'deals']).isRequired
};

const REFETCH_INTERVAL = 5000;

const VARIANTS = {
  organizations: {
    entityListURL: organizationsPath,
    importURL: organizationsImportPath()
  },
  people: {
    entityListURL: peoplePath,
    importURL: peopleImportPath()
  },
  products: {
    entityListURL: settingsProductsPath,
    importURL: productsImportPath()
  },
  activities: {
    entityListURL: legacyTasksUrl,
    importURL: activitiesImportPath()

  },
  deals: {
    entityListURL: dealsPath,
    importURL: dealsImportPath()
  }
};

function ImportStatusSkeleton() {
  return (
    <div className='d-flex flex-column align-items-center'>
      <Skeleton
        width='64px'
        height='64px'
      />
      <Skeleton
        width='251px'
        height='28px'
        className='my-3'
      />
      <div className='d-flex flex-column align-items-center mb-3'>
        <Skeleton
          width='119px'
          height='11px'
        />
        <Skeleton
          width='266px'
          height='11px'
        />
        <Skeleton
          width='119px'
          height='11px'
        />
      </div>
    </div>
  );
}

function ImportStatus({ importId, entity }) {
  const { entityListURL, importURL } = VARIANTS[entity];

  const entityLabel = pluralize(
    getString(['attributes', 'imports', 'entity', entity])
  );

  const [isImporting, setIsImporting] = useState(true);

  const queryClient = useQueryClient();

  const { data: { data } = {} } = useImport({
    importId,
    config: {
      refetchInterval: REFETCH_INTERVAL,
      cacheTime: 0,
      enabled: isImporting,
      onSuccess: ({ data: importData }) => {
        if (importData.status === 'finished') {
          setIsImporting(false);

          queryClient.invalidateQueries(importsKeys.all);
        }
      }
    }
  });

  const notErrorsInfoEntries = Object.entries(
    data?.info ?? {}
  ).filter(([key]) => key !== 'errors');

  return (
    <div className='p-4 border rounded border-light py-9'>
      <SkeletonWrapper
        isLoading={!data?.fileName}
        SkeletonComponent={ImportStatusSkeleton}
      >
        {
          isImporting
            ? <div className='d-flex flex-column align-items-center'>
              <div className='width-5 text-center text-dark-gray'>
                <img className='width-3 height-3 mb-3' src={fullPageGif} />
                <h2 className='mb-3 text-nowrap'>Importação em andamento</h2>

                <p>
                  {data?.fileName}
                </p>
                <p>
                  Avisaremos por e-mail quando sua importação finalizar.
                </p>
              </div>
            </div>

            : <div className='text-center'>
              <img className='width-3 height-3 mb-3' src={successImage}/>

              <h2 className='mb-3 mx-1 text-dark-gray'>Importação concluída</h2>

              <div className='fw-bold text-dark-gray'>
                {
                  notErrorsInfoEntries.map(([key, value]) => (
                    <p className='mb-0' key={key}>
                      {getString(['attributes', 'imports', 'info', key])}: {value}
                    </p>
                  ))
                }
                {
                  data?.info?.errors > 0 && (
                    <>
                      <p className='text-danger mb-0'>
                        <Icon className='me-1 align-middle' name='alert' />
                        <span className='align-middle'>
                          Erros processados: {data?.info?.errors}
                        </span>
                      </p>
                      <p className='text-medium-gray mb-2'>
                        Enviaremos para o seu e-mail um relatório com os erros.
                      </p>

                      {data.errorsSheet && (
                        <ExternalLink
                          href={data.errorsSheet.url}
                          className='d-block'
                        >
                          Baixar planilha de erros
                        </ExternalLink>
                      )}
                    </>
                  )
                }
              </div>

              <div className='mt-4'>
                <Button
                  href={importURL}
                  variant='outline-dark-gray'
                  className='me-3'
                  size='sm'
                >
                  Nova importação
                </Button>

                <Button
                  as={ExternalLink}
                  href={entityListURL({ import: importId })}
                  size='sm'
                >
                  Ver {entityLabel}
                </Button>
              </div>
            </div>
        }
      </SkeletonWrapper>
    </div>
  );
}

ImportStatus.propTypes = propTypes;

export default ImportStatus;
