import React from 'react';
import { Keyword, SectionTitle, SpoilerCard, AutomationComponent } from '@/feature/automation';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Authorization from '@/components/Authorization';
import { canAccessLatestAutomationFeatures } from '@/policies/automations';

function AutomationsTemplates() {
  return (
    <div>
      <SectionTitle
        actionName = 'schedule_activity'
      />

      <Row className='g-4 row-cols-auto'>
        <Col>
          <AutomationComponent type='on_deal_stage_arrived_schedule_activity'/>
        </Col>

        <Col>
          <AutomationComponent type='on_deal_created_schedule_activity'/>
        </Col>

        <Col>
          <AutomationComponent type='on_person_created_schedule_activity'/>
        </Col>

        <Col>
          <AutomationComponent type='on_organization_created_schedule_activity'/>
        </Col>

        <Col>
          <SpoilerCard
            identifier='Quando um negócio estiver parado em uma etapa e → agendar uma atividade'
          >
            <SpoilerCard.TriggerBox>
              <SpoilerCard.Icon name='funnel' />

              <span>Quando um <Keyword>negócio</Keyword> estiver parado em uma etapa</span>
            </SpoilerCard.TriggerBox>

            <SpoilerCard.ActionBox>
              <SpoilerCard.Icon name='task' />

              <span>Agendar uma <Keyword>atividade</Keyword></span>
            </SpoilerCard.ActionBox>
          </SpoilerCard>
        </Col>

        <Col>
          <SpoilerCard
            identifier='Quando uma atividade for finalizada → agendar a próxima'
          >
            <SpoilerCard.TriggerBox>
              <SpoilerCard.Icon name='task' />

              <span>Quando uma <Keyword>atividade</Keyword> for finalizada</span>
            </SpoilerCard.TriggerBox>

            <SpoilerCard.ActionBox>
              <SpoilerCard.Icon name='task' />

              <span>Agendar a <Keyword>próxima</Keyword></span>
            </SpoilerCard.ActionBox>
          </SpoilerCard>
        </Col>
      </Row>

      <hr className='mt-2' />

      <SectionTitle
        actionName = 'duplicate_deal'
      />

      <Row className='g-5 row-cols-auto'>
        <Col>
          <AutomationComponent type='on_deal_won_duplicate_deal'/>
        </Col>

        <Col>
          <AutomationComponent type='on_deal_lost_duplicate_deal'/>
        </Col>

        <Col>
          <SpoilerCard
            identifier='Quando uma pessoa for adicionada → criar novo negócio'
          >
            <SpoilerCard.TriggerBox>
              <SpoilerCard.Icon name='person' />

              <span>Quando uma <Keyword>pessoa</Keyword> for adicionada</span>
            </SpoilerCard.TriggerBox>

            <SpoilerCard.ActionBox>
              <SpoilerCard.Icon name='deal' />

              <span>Criar novo <Keyword>negócio</Keyword></span>
            </SpoilerCard.ActionBox>
          </SpoilerCard>
        </Col>

        <Col>
          <SpoilerCard
            identifier='Quando uma empresa for adicionada → criar novo negócio'
          >
            <SpoilerCard.TriggerBox>
              <SpoilerCard.Icon name='organization' />

              <span>Quando uma <Keyword>empresa</Keyword> for adicionada</span>
            </SpoilerCard.TriggerBox>

            <SpoilerCard.ActionBox>
              <SpoilerCard.Icon name='deal' />

              <span>Criar novo <Keyword>negócio</Keyword></span>
            </SpoilerCard.ActionBox>
          </SpoilerCard>
        </Col>
      </Row>

      <hr className='mt-2' />

      <SectionTitle
        actionName = 'send_email'
      />

      <Row className='g-5 row-cols-auto'>
        <Col>
          <Authorization
            policy={canAccessLatestAutomationFeatures}
            fallback={
              <SpoilerCard
                identifier='Quando um negócio chegar a uma etapa → enviar um e-mail'
              >
                <SpoilerCard.TriggerBox>
                  <SpoilerCard.Icon name='funnel' />

                  <span>Quando um <Keyword>negócio</Keyword> chegar a uma etapa</span>
                </SpoilerCard.TriggerBox>

                <SpoilerCard.ActionBox>
                  <SpoilerCard.Icon name='email-sent' />

                  <span>Enviar um <Keyword>e-mail</Keyword></span>
                </SpoilerCard.ActionBox>
              </SpoilerCard>
            }
          >
            <AutomationComponent type='on_deal_stage_arrived_send_email'/>
          </Authorization>
        </Col>

        <Col>
          <Authorization
            policy={canAccessLatestAutomationFeatures}
            fallback={
              <SpoilerCard
                identifier='Quando negócio for ganho → enviar um e-mail'
              >
                <SpoilerCard.TriggerBox>
                  <SpoilerCard.Icon name='won-deal' />

                  <span>Quando um <Keyword>negócio</Keyword> for ganho</span>
                </SpoilerCard.TriggerBox>

                <SpoilerCard.ActionBox>
                  <SpoilerCard.Icon name='email-sent' />

                  <span>Enviar um <Keyword>e-mail</Keyword></span>
                </SpoilerCard.ActionBox>
              </SpoilerCard>
            }
          >
            <AutomationComponent type='on_deal_won_send_email'/>
          </Authorization>
        </Col>

        <Col>
          <Authorization
            policy={canAccessLatestAutomationFeatures}
            fallback={
              <SpoilerCard
                identifier='Quando negócio for perdido → enviar um e-mail'
              >
                <SpoilerCard.TriggerBox>
                  <SpoilerCard.Icon name='lost-deal' />

                  <span>Quando um <Keyword>negócio</Keyword> for perdido</span>
                </SpoilerCard.TriggerBox>

                <SpoilerCard.ActionBox>
                  <SpoilerCard.Icon name='email-sent' />

                  <span>Enviar um <Keyword>e-mail</Keyword></span>
                </SpoilerCard.ActionBox>
              </SpoilerCard>
            }
          >
            <AutomationComponent type='on_deal_lost_send_email'/>
          </Authorization>
        </Col>

        <Col>
          <SpoilerCard
            identifier='Quando um negócio for adicionado → enviar um e-mail'
          >
            <SpoilerCard.TriggerBox>
              <SpoilerCard.Icon name='deal' />

              <span>Quando um <Keyword>negócio</Keyword> for adicionado</span>
            </SpoilerCard.TriggerBox>

            <SpoilerCard.ActionBox>
              <SpoilerCard.Icon name='email-sent' />

              <span>Enviar um <Keyword>e-mail</Keyword></span>
            </SpoilerCard.ActionBox>
          </SpoilerCard>
        </Col>

        <Col>
          <SpoilerCard
            identifier='Quando uma pessoa for adicionada → enviar um e-mail'
          >
            <SpoilerCard.TriggerBox>
              <SpoilerCard.Icon name='person' />

              <span>Quando uma <Keyword>pessoa</Keyword> for adicionada</span>
            </SpoilerCard.TriggerBox>

            <SpoilerCard.ActionBox>
              <SpoilerCard.Icon name='email-sent' />

              <span>Enviar um <Keyword>e-mail</Keyword></span>
            </SpoilerCard.ActionBox>
          </SpoilerCard>
        </Col>

        <Col>
          <SpoilerCard
            identifier='Quando uma empresa for adicionada → enviar um e-mail'
          >
            <SpoilerCard.TriggerBox>
              <SpoilerCard.Icon name='organization' />

              <span>Quando uma <Keyword>empresa</Keyword> for adicionada</span>
            </SpoilerCard.TriggerBox>

            <SpoilerCard.ActionBox>
              <SpoilerCard.Icon name='email-sent' />

              <span>Enviar um <Keyword>e-mail</Keyword></span>
            </SpoilerCard.ActionBox>
          </SpoilerCard>
        </Col>
      </Row>
    </div>
  );
}

export default AutomationsTemplates;
