import React from 'react';
import Icon from '@/components/Icon';
import Tooltip from '@/components/Tooltip';
import { useAuth } from '@/lib/auth';

function VoipGptInfo() {
  const { user } = useAuth();
  const isPerformanceOrHigher = user?.account?.performanceOrHigher;
  const textByPlan = isPerformanceOrHigher ? 'Com ChatGPT' : 'Com ChatGPT  no Plano Performance';

  const tooltipText = (
    <>
      <div className='d-flex text-nowrap'>
        <Icon name='check' size='sm' className='me-2' />
        Salva o áudio automaticamente;
      </div>
      <div className='d-flex text-nowrap'>
        <Icon name='check' size='sm' className='me-2' />
        Transcreve as chamadas;
      </div>
      <div className='d-flex text-nowrap'>
        <Icon name='check' size='sm' className='me-2' />
        Resume os pontos principais;
      </div>
      <div className='d-flex text-nowrap'>
        <Icon name='check' size='sm' className='me-2' />
        Teste sem compromisso, sem cartão.
      </div>
    </>
  );

  return (
    <div>
      <Tooltip content={tooltipText} className='tooltip-lg'>
        <div className='text-nowrap me-2 w-100 text-purple fw-bold cursor-pointer'>
          <Icon name='ai' size='sm' className='me-1' />
          {textByPlan}
        </div>
      </Tooltip>
    </div>
  );
}

export default VoipGptInfo;
