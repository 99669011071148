import React from 'react';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import { checkoutPath } from '@/routes';
import { parseDate, toTextualDateString } from '@/date';
import { useAuth } from '@/lib/auth';
import { useNextVisibleTime } from '@/hooks';
import { useLocation } from 'react-router-dom';

const paymentWarningKey = 'headerPaymentWarning';

export function useHeaderPaymentWarning() {
  const noHeaderWarningPrefix = '/settings/account/current_plan';

  const location = useLocation();
  const { user } = useAuth();
  const { isWarningVisible, handleWarningClose } = useNextVisibleTime(paymentWarningKey);

  const isAdmin = user.admin;
  const { fiveDaysUntilBlock, expiredThreeDaysAgo } = user.account;

  const isPathExcluded = location.pathname.startsWith(noHeaderWarningPrefix);
  const shouldShowWarning = fiveDaysUntilBlock || (isAdmin && expiredThreeDaysAgo);

  if (shouldShowWarning && isWarningVisible && !isPathExcluded) {
    return { show: true, onClose: handleWarningClose };
  }

  return { show: false };
}

function HeaderPaymentWarning({ onClose }) {
  const { user } = useAuth();

  const isAdmin = user.admin;
  const { fiveDaysUntilBlock, blockAccessAt, paymentTypeCode } = user.account;

  const type = fiveDaysUntilBlock ? 'pre_block' : 'expired';

  const isBankSlip = paymentTypeCode === 'bank_slip';
  const blockingDateFormatted = toTextualDateString(parseDate(blockAccessAt));

  const cardData = {
    pre_block: {
      title: 'Evite o bloqueio da sua conta',
      buttonText: isBankSlip ? 'Gerar 2ª via boleto' : 'Alterar forma de pagamento',
      buttonVariant: isBankSlip ? 'outline-dark-gray' : 'primary',
      message: (
        <>
          {isAdmin
            ? 'Não identificamos o pagamento. Regularize a assinatura até o dia'
            : `Entre em contato com o administrador da conta para
              regularizar a assinatura até o dia`}{' '}
          <b>{blockingDateFormatted}</b>.
        </>
      ),
      icon: 'alert',
      iconColor: 'text-danger',
      background: 'bg-danger bg-opacity-10'
    },
    expired: {
      title: 'Pagamento não identificado',
      buttonText: isBankSlip ? 'Gerar 2ª via boleto' : 'Alterar forma de pagamento',
      buttonVariant: 'outline-dark-gray',
      message: isBankSlip
        ? `Caso já tenha pago o boleto e ele ainda esteja em
          compensação bancária, desconsidere essa mensagem.`
        : 'Regularize sua assinatura para continuar usando o Agendor.',
      icon: 'info',
      iconColor: '',
      background: 'bg-light-yellow'
    }
  };

  const { title, buttonText, buttonVariant, message, icon, iconColor, background } = cardData[type];

  return (
    <div className={`p-4 ${background}`}>
      <div className='p-0 d-flex align-items-center'>
        <div className='row w-100'>
          <div className={`ms-6 col-auto d-flex align-items-center ${iconColor}`}>
            <Icon name={icon} size='sm' />
          </div>
          <div className='col d-flex flex-column justify-content-center'>
            <h4 className='mb-1 text-medium fw-bold'>{title}</h4>
            <div className='text-dark-gray text-small'>{message}</div>
          </div>
          <div className='col-auto d-flex flex-column align-items-end'>
            {isAdmin && (
              <>
                {isBankSlip && (
                  <Button
                    href={checkoutPath()}
                    variant='outline-dark-gray'
                    className='w-100'
                    size='sm'
                  >
                    Pagar com Cartão de Crédito
                  </Button>
                )}
                <Button
                  href={checkoutPath()}
                  variant={buttonVariant}
                  className='mt-2 w-100'
                  size='sm'
                >
                  {buttonText}
                </Button>
              </>
            )}
          </div>
          <div className='col-auto d-flex flex-column justify-content-center cursor-pointer'>
            <Icon
              name='close'
              size='md'
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderPaymentWarning;
