export function importsPath(entity) {
  const base = '/imports';
  return entity ? `${base}/${entity}` : base;
}

export function peopleImportPath() {
  return importsPath('people');
}

export function organizationsImportPath() {
  return importsPath('organizations');
}

export function productsImportPath() {
  return importsPath('products');
}

export function activitiesImportPath() {
  return importsPath('activities');
}

export function emailTemplatesImportPath() {
  return importsPath('email_templates');
}

export function emailSignaturesImportPath() {
  return importsPath('email_signatures');
}

export function dealsImportPath() {
  return importsPath('deals');
}

export function importPath(entity, id) {
  return `${importsPath(entity)}/${id}`;
}

export function sampleImportUrl(entity) {
  const fileName = sampleImportFileName(entity);

  if (!fileName) {
    return null;
  }

  return `/files/${fileName}`;
}

export function sampleImportFileName(entity) {
  const sample = {
    organizations: 'exemplo-importacao-empresas.xlsx',
    people: 'exemplo-importacao-pessoas.xlsx',
    products: 'exemplo-importacao-produtos.xlsx',
    activities: 'exemplo-importacao-comentarios.xlsx',
    deals: 'exemplo-importacao-negocios.xlsx'
  };

  return sample[entity];
}
