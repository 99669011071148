import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Authorization from '@/components/Authorization';
import Button from '@/components/Button';
import CreateExportModal from '@/components/CreateExportModal';
import Icon from '@/components/Icon';
import Tooltip from '@/components/Tooltip';
import { canExport } from '@/policies';

const propTypes = {
  exportEntity: PropTypes.string.isRequired,
  tooltipPlacement: PropTypes.string,
  className: PropTypes.string,
  inactiveClassName: PropTypes.string,
  activeClassName: PropTypes.string,
  tooltipText: PropTypes.string,
  spanClassName: PropTypes.string,
  buttonText: PropTypes.string,
  modalSize: PropTypes.oneOf(['sm', 'md'])
};

const defaultProps = {
  tooltipPlacement: 'left',
  as: Button,
  buttonText: 'Exportar',
  modalSize: 'md'
};

function ExportButton(props) {
  const {
    as: Component, tooltipPlacement, className, exportEntity, spanClassName,
    buttonText, tooltipText, inactiveClassName, activeClassName, filters,
    alertMessage, modalSize, withoutOptions, ...rest
  } = props;

  const [showExportModal, setShowExportModal] = useState(false);

  const render = (disabled, child) => {
    if (disabled) {
      return child;
    }
    return (
      <Tooltip
        content={tooltipText}
        placement={tooltipPlacement}
      >
        {child}
      </Tooltip>
    );
  };

  return (
    <>
      <Authorization
        policy={canExport}
        tooltipContainerClassName='align-self-center'
        data={{ entity: exportEntity }}
      >
        {({ disabled }) => (
          <>
            {render(
              disabled,
              <Component
                { ...rest }
                className={
                  classnames(
                    disabled ? inactiveClassName : activeClassName,
                    className
                  )
                }
                disabled={disabled}
                onClick={() => setShowExportModal(true)}
              >
                <Icon className='me-2' name='download' />
                <span className={spanClassName}>{buttonText}</span>
              </Component>
            )}
          </>
        )}
      </Authorization>

      <CreateExportModal
        exportEntity={exportEntity}
        showExportModal={showExportModal}
        filters={filters}
        onHide={() => setShowExportModal(false)}
        alertMessage={alertMessage}
        initialSize={modalSize}
        withoutOptions={withoutOptions}
      />
    </>
  );
}

ExportButton.defaultProps = defaultProps;
ExportButton.propTypes = propTypes;

export default ExportButton;
