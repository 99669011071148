import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import classnames from 'classnames';

const propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  checkClassName: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.node,
  noFlex: PropTypes.bool
};

const defaultProps = {
  className: '',
  onChange: () => {},
  label: '',
  value: null,
  noFlex: false
};

const RadioButton = React.forwardRef(
  (props, ref) => {
    const {
      name, id, className, onChange, onClick, value, radioValue, noFlex, checkClassName, ...rest
    } = props;

    const checked = value === radioValue;
    const handleChange = (event) => onChange(event.target.value);

    return (
      <Form.Group
        className={className}
        controlId={id}
        onClick={onClick}
      >
        <Form.Check
          ref={ref}
          type='radio'
          checked={checked}
          onChange={handleChange}
          value={radioValue}
          name={name}
          className={classnames(
            { 'd-flex': !noFlex },
            'align-items-center',
            checkClassName
          )}
          {...rest}
        >
        </Form.Check>
      </Form.Group>
    );
  }
);

RadioButton.propTypes = propTypes;
RadioButton.defaultProps = defaultProps;
RadioButton.displayName = 'RadioButton';

export default RadioButton;
