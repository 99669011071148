/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import classnames from 'classnames';
import difference from 'lodash/difference';
import intersection from 'lodash/intersection';

import { Checkbox } from '@/components/Inputs';
import DragScrollable from '@/components/DragScrollable';
import EntitySkeleton from '@/components/List/EntitySkeleton';
import ExternalLink from '@/components/ExternalLink';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import Icon from '@/components/Icon';
import InfiniteScroll from '@/components/InfiniteScroll';
import Footer from '@/components/LeadsGeneration/Footer';
import Table from '@/components/Table';
import Tooltip from '@/components/Tooltip';
import Truncate from '@/components/Truncate';
import { useLeadsGenerationContext } from '@/contexts';
import EntityIcon from '@/components/EntityIcon';
import { pluralize } from '@/utils';

const defaultValues = {
  select_all: false,
  selected_leads: {}
};

const initialValues = {
  select_all: false,
  selected_leads: {}
};

const DecisionMakers = ({ departments, quantity }) => (
  <>
    <div>Possui {quantity} decisores</div>

    <ul>
      {
        departments?.filter(({ value }) => Boolean(value)).map(({ name, value }, index) => (
          <li className='text-start' key={index}>
            {name}: {value}
          </li>
        ))
      }
    </ul>
  </>
);

function LeadsGenerationList() {
  const {
    leads,
    hasNextPage,
    isLeadsFetched,
    fetchNextPage,
    isLeadsFetchingNextPage,
    selectedLeads,
    setSelectedLeads,
    itemsPerPage,
    nextStep,
    prevStep,
    callMixpanelEvent,
    minLeads
  } = useLeadsGenerationContext();

  useEffect(() => {
    callMixpanelEvent('trackLeadsGenerationListPresented');
  }, []);

  const formRef = useRef();

  const cnpjs = useMemo(() => leads.map(({ cnpj }) => cnpj), [leads.length]);
  const boughtCnpjs = useMemo(
    () => leads.filter(({ bought }) => bought).map(({ cnpj }) => cnpj),
    [leads.length]
  );

  const createResetedValues = useCallback((isSelected) => (
    Object.fromEntries(cnpjs.map((cnpj) => [cnpj, isSelected]))
  ), [leads.length]);

  const leadsListRender = useMemo(() => (
    isLeadsFetched && renderLeadsList(formRef, leads)
  ), [isLeadsFetched, leads.length]);

  const MAX_LEADS = 500;
  const TOOLTIP_TEXT_MIN = `É necessário um mínimo de 50 leads selecionados para
    dar continuidade para próxima etapa`;
  const TOOLTIP_TEXT_MAX = 'Por enquanto, só é possível gerar até 500 leads por vez';
  const selectedLeadsLength = selectedLeads.length;
  const isLeadsCountUpperToMax = selectedLeadsLength > MAX_LEADS;
  const isLeadsCountBelowToMin = selectedLeadsLength < minLeads;
  const disableNextStep = (isLeadsCountUpperToMax || isLeadsCountBelowToMin);
  const tooltipMessage = disableNextStep && (
    isLeadsCountUpperToMax ? TOOLTIP_TEXT_MAX : TOOLTIP_TEXT_MIN
  );

  return (
    <>
      <div className='d-flex flex-column flex-grow-1 flexible-height mt-9'>
        <Form
          className='d-flex flex-column flex-grow-1 flexible-height'
          ref={formRef}
          initialValues={initialValues}
          defaultValues={defaultValues}
          onSubmit={() => { }}
        >
          {({ setFieldValue, values }) => {
            const selectedCnpjs = useMemo(
              () => Object.keys(values.selected_leads).filter((key) => values.selected_leads[key]),
              [JSON.stringify(Object.values(values.selected_leads))]
            );

            const {
              isEveryCnpjSelected, isSomeCnpjSelected, isNoneCnpjSelected
            } = useMemo(() => ({
              isEveryCnpjSelected: selectedCnpjs.length === leads.length,
              isSomeCnpjSelected: selectedCnpjs.length > 0,
              isNoneCnpjSelected: selectedCnpjs.length === 0
            }), [leads.length, selectedCnpjs.length]);

            const boughtCnpjsSelected = useMemo(
              () => intersection(selectedCnpjs, boughtCnpjs),
              [selectedCnpjs, boughtCnpjs]
            );

            useEffect(() => {
              setFieldValue('select_all', isSomeCnpjSelected);
            }, [isSomeCnpjSelected]);

            useEffect(() => {
              if (leads.length) {
                const initialCnpjs = difference(cnpjs, boughtCnpjs);
                const selectedLeadsValue = Object.fromEntries(
                  initialCnpjs.map((cnpj, index) => [cnpj, index < itemsPerPage])
                );

                setFieldValue('selected_leads', selectedLeadsValue);
              }
            }, [leads.length > 0, itemsPerPage]);

            const handleSelectAllClick = useCallback(() => {
              if (isNoneCnpjSelected) {
                setFieldValue('selected_leads', createResetedValues(true));
              }

              if (isSomeCnpjSelected) {
                setFieldValue('selected_leads', createResetedValues(false));
              }
            }, [leads.length, isNoneCnpjSelected, isSomeCnpjSelected]);

            const uncheckBought = () => {
              boughtCnpjsSelected.forEach((cnpj) => {
                setFieldValue(`selected_leads.${cnpj}`, false);
              });
            };

            useEffect(() => {
              setSelectedLeads(selectedCnpjs);
            }, [selectedCnpjs.length]);

            return (
              <>
                <div className='d-flex align-items-center mb-2'>
                  <h1>
                    {`${selectedCnpjs.length} ${pluralize('lead', selectedCnpjs.length)}`}{' '}
                    <span className='text-dark-gray'>
                      {pluralize('selecionado', selectedCnpjs.length)}
                    </span>
                  </h1>

                  {Boolean(boughtCnpjsSelected.length) && (
                    <>
                      <h4 className='mx-2 text-dark-gray'>·</h4>
                      <h4
                        className='text-primary me-1 mb-0'
                      >
                        <span
                          className='cursor-pointer'
                          onClick={uncheckBought}
                        >
                          Desmarcar {
                            `${boughtCnpjsSelected.length}
                            ${pluralize('lead', boughtCnpjsSelected.length)}`
                          } {' '}
                        </span>
                        <span className='text-dark-gray fw-normal'>
                          já {pluralize('comprado', boughtCnpjsSelected.length)}
                        </span>
                      </h4>

                      <Tooltip
                        content={
                          `Sua lista possui ${boughtCnpjsSelected.length} empresas repetidas,
                            que você já comprou anteriormente.`
                        }
                      >
                        <span>
                          <Icon name='info' />
                        </span>
                      </Tooltip>
                    </>
                  )}
                </div>

                <InfiniteScroll
                  className='d-flex flex-column flex-grow-1 flexible-height'
                  length={leads?.length || 0}
                  loadItems={fetchNextPage}
                  hasMore={hasNextPage || false}
                  isLoading={isLeadsFetchingNextPage}
                >
                  <DragScrollable className='flex-grow-1'>
                    <Table className='table-border-separate'>
                      <thead className='position-sticky top-0 bg-white z-index-1'>
                        <tr>
                          {
                            isLeadsFetched && (
                              <th className='border-end border-1 d-flex justify-content-center'>
                                <FormField
                                  className={classnames(
                                    { 'minus-check': isSomeCnpjSelected && !isEveryCnpjSelected }
                                  )}
                                  onClick={handleSelectAllClick}
                                  as={Checkbox}
                                  name='select_all'
                                />
                              </th>
                            )
                          }

                          <th className={classnames(
                            'width-5',
                            'border-end',
                            'border-1',
                            'bg-white'
                          )}>
                            <span>
                              Empresa
                            </span>
                          </th>

                          <th className='border-end border-1 width-4'>Estado</th>

                          <th className='border-end border-1'>Cidade</th>

                          <th>Dados Disponíveis</th>
                        </tr>
                      </thead>

                      <tbody>
                        {
                          !isLeadsFetched && <EntitySkeleton maxCols={3} />
                        }

                        {leadsListRender}
                      </tbody>
                    </Table>
                  </DragScrollable>
                </InfiniteScroll>
              </>
            );
          }}
        </Form>
      </div>

      <Footer
        buttonNextText={`Gerar ${selectedLeadsLength} leads`}
        onClickNext={nextStep}
        onClickPrev={prevStep}
        disabled={disableNextStep}
        nextButtonDisabledMessage={tooltipMessage}
        isCounterVisible={true}
      />
    </>
  );
}

function renderLeadsList(formRef, leads = []) {
  return leads.map((item, index) => {
    const {
      name,
      state,
      cityName,
      website,
      phonesQuantity,
      emailsQuantity,
      decisionMakers,
      cnpj,
      bought
    } = item;

    const handleTableRowClick = () => {
      if (!formRef.current) {
        return;
      }

      const { getFieldMeta, setFieldValue } = formRef.current;

      const inputName = `selected_leads.${cnpj}`;

      const { value: currentValue } = getFieldMeta(inputName);

      setFieldValue(inputName, !currentValue);
    };

    return (
      <tr onClick={handleTableRowClick} className='border-end border-1' key={index}>
        <td className='align-middle border-end border-1'>
          <div className='d-flex justify-content-center'>
            <FormField
              as={Checkbox}
              name={`selected_leads.${cnpj}`}
            />
          </div>
        </td>

        <Tooltip
          hide={!bought}
          content={'Lead já comprado anteriormente'}
        >
          <td className='align-middle border-end border-1'>
            <div className='d-flex justify-content-center align-items-center'>
              <div className='position-relative'>
                <EntityIcon type='organization' />

                {bought && (
                  <div
                    className={classnames(
                      'd-flex',
                      'position-absolute',
                      'bottom-0',
                      'end-0',
                      'drop-shadow-white',
                      'rounded-circle',
                      'bg-flat-green'
                    )}
                  >
                    <Icon
                      name={'check'}
                      size='sm'
                    />
                  </div>
                )}
              </div>

              <div>
                <Truncate
                  disableTooltip={bought}
                  className={classnames('width-5 px-3 fw-bold', {
                    'opacity-50': bought
                  })}
                >
                  {name}
                </Truncate>
              </div>
            </div>
          </td>
        </Tooltip>

        <td className='align-middle border-end border-1'>
          <span className={classnames({ 'opacity-50': bought })}>
            {state}
          </span>
        </td>

        <td className='align-middle border-end border-1'>
          <span className={classnames({ 'opacity-50': bought })}>
            {cityName}
          </span>
        </td>

        <td className='align-middle'>
          <div className={classnames('d-flex', { 'opacity-50': bought })}>
            <Tooltip
              hide={Boolean(!phonesQuantity)}
              content={`${phonesQuantity} ${pluralize('telefone', phonesQuantity)}`}
            >
              <div>
                <Icon
                  name='call'
                  className={classnames(
                    'me-2 text-success',
                    { 'text-light-gray': !phonesQuantity }
                  )}
                />
              </div>
            </Tooltip>

            <Tooltip
              hide={Boolean(!emailsQuantity)}
              content={`${emailsQuantity} ${pluralize('e-mail', emailsQuantity)}`}
            >
              <div>
                <Icon
                  name='email'
                  className={classnames(
                    'me-2 text-success',
                    { 'text-light-gray': !emailsQuantity }
                  )}
                />
              </div>
            </Tooltip>

            <Tooltip
              hide={Boolean(!website)}
              content='Possui dados do website'
            >
              <div className='me-2'>
                <ExternalLink onClick={(e) => e.stopPropagation()} target='_blank' href={website}>
                  <Icon
                    name='globe'
                    className={classnames(
                      'text-success',
                      { 'text-light-gray': !website }
                    )}
                  />
                </ExternalLink>
              </div>
            </Tooltip>

            <Tooltip
              content={
                <DecisionMakers
                  departments={decisionMakers.departments}
                  quantity={decisionMakers.quantity}
                />
              }
            >
              <div>
                <Icon className='text-success' name='team' />
              </div>
            </Tooltip>
          </div>
        </td>
      </tr>
    );
  });
}

export default LeadsGenerationList;
