export function canDeleteStage({ user, data: { totalCount } }) {
  let effect, reason;

  if (!user.account.paid) {
    effect = 'paywall';
    reason =
      'Para personalizar seus funis e as etapas do seu processo comercial, ' +
      'é necessário fazer upgrade para o Plano Pro.';
  } else if (!user.admin) {
    effect = 'disable';
    reason = 'Apenas administradores podem editar etapas.';
  } else if (totalCount === 1) {
    effect = 'disable';
    reason = 'Não é possível excluir a única etapa do funil.';
  } else {
    effect = 'allow';
  }

  return { effect, reason };
}

export function canEditStage({ user, _data }) {
  let effect, reason;

  if (!user.account.paid) {
    effect = 'paywall';
    reason =
      'Para personalizar seus funis e as etapas do seu processo comercial, ' +
      'é necessário fazer upgrade para o Plano Pro.';
  } else if (!user.admin) {
    effect = 'disable';
    reason = 'Apenas administradores podem editar etapas.';
  } else {
    effect = 'allow';
  }

  return { effect, reason };
}
