import React, { useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Form from 'react-bootstrap/Form';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import LoadSpinner from '@/components/LoadSpinner';

const propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(['text', 'hidden', 'time', 'number']),
  placeholder: PropTypes.string,
  label: PropTypes.node,
  hint: PropTypes.string,
  variant: PropTypes.oneOf(['light', 'white', 'transparent']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  leftAdornment: PropTypes.func,
  rightAdornment: PropTypes.func,
  isClearable: PropTypes.bool,
  clearRef: PropTypes.any,
  error: PropTypes.string,
  loading: PropTypes.bool,
  loadingMessage: PropTypes.string,
  onClear: PropTypes.func,
  innerClassName: PropTypes.string,
  id: PropTypes.string
};

const defaultProps = {
  className: 'mb-4',
  type: 'text',
  placeholder: '',
  label: null,
  hint: null,
  variant: 'light',
  onChange: () => {},
  leftAdornment: null,
  rightAdornment: null,
  isClearable: false,
  clearRef: null,
  error: null,
  loading: false,
  loadingMessage: 'Carregando...',
  onClear: () => {}
};

const TextInput = React.forwardRef(
  (props, ref) => {
    const {
      name, type, label, className, hint, placeholder, onChange, onClear, variant,
      value, isClearable, error, loading, loadingMessage, helperText,
      leftAdornment, rightAdornment, innerClassName, clearRef, id, ...otherProps
    } = props;

    const inputClassName = classnames({
      'pe-7': isClearable || Boolean(rightAdornment),
      'ps-7': Boolean(leftAdornment),
      'bg-white': variant === 'white',
      'bg-transparent': variant === 'transparent'
    }, innerClassName);

    const showClearButton = isClearable && value && value !== '';

    const innerRef = useRef(null);

    /*
     * Repassa referência interna para o elemento pai
     * Ref: (Discussão nos comentários do artigo)
     * https://itnext.io/reusing-the-ref-from-forwardref-with-react-hooks-4ce9df693dd
     */
    useImperativeHandle(ref, () => innerRef.current);

    const handleChange = (event) => {
      onChange(event.target.value);
    };

    const handleClickClearButton = () => {
      onChange('', { debounce: { cancel: true } });
      onClear();
    };

    const handleAdornmentFocus = () => {
      innerRef.current.focus();
    };

    return (
      // O `name` não deveria estar sendo usado como ID, mas foi mantido por compatibilidade.
      <Form.Group className={className} controlId={id ?? name}>
        {(label || hint) &&
          <div className='d-flex align-items-base justify-content-between'>
            <Form.Label>{label}</Form.Label>

            <Form.Text className='text-medium-gray fst-italic text-small ms-2 mt-0'>
              {hint}
            </Form.Text>
          </div>
        }

        <div className={classnames(
          'd-flex',
          'min-width-0',
          {
            'position-relative': isClearable || leftAdornment || rightAdornment,
            'input-transparent-parent': variant === 'transparent'
          }
        )}>
          {leftAdornment && leftAdornment({ focus: handleAdornmentFocus })}

          <Form.Control
            ref={innerRef}
            name={name}
            type={type}
            value={value}
            placeholder={placeholder}
            onChange={handleChange}
            className={inputClassName}
            isInvalid={Boolean(error)}
            autoComplete='off'
            { ...otherProps }
          />

          {
            showClearButton &&
              (
                <Button
                  onClick={handleClickClearButton}
                  size='sm'
                  variant='link'
                  ref={clearRef}
                  className={classnames(
                    'position-absolute',
                    'top-50',
                    'end-0',
                    'translate-middle-y',
                    'rounded',
                    'p-2',
                    'border-0'
                  )}
                  aria-label='Limpar'
                >
                  <Icon name='close' />
                </Button>
              )
          }

          {!showClearButton && rightAdornment && rightAdornment({ focus: handleAdornmentFocus })}
        </div>

        {/* Feedback apenas é exibido quando está ao lado do elemento com `is-invalid`. */}
        <div className={classnames({ 'is-invalid': Boolean(error) })} />

        <Form.Control.Feedback type='invalid'>
          {error}
        </Form.Control.Feedback>

        {
          loading && (
            <div className='text-small text-dark-gray'>
              <LoadSpinner
                size='sm'
                className='me-1 d-inline-block text-dark-gray'
              />

              <span>{loadingMessage}</span>
            </div>
          )
        }

        {
          !error && !loading && helperText
        }
      </Form.Group>
    );
  }
);

TextInput.propTypes = propTypes;
TextInput.defaultProps = defaultProps;
TextInput.displayName = 'TextInput';

export default TextInput;
