import React from 'react';
import PropTypes from 'prop-types';
import Popover from '@/components/Popover';
import DotList from '@/components/DotList';
import DateAlert from '@/components/DateAlert';
import AvatarList from '@/components/AvatarList';
import Icon from '@/components/Icon';
import EntityLink from '@/components/EntityLink';
import { toDateTimeString } from '@/date';
import Task from '@/task';

const propTypes = {
  variant: PropTypes.oneOf(['nextTask', 'latestTask']).isRequired,
  className: PropTypes.string,
  task: PropTypes.instanceOf(Task)
};

const defaultProps = {
  className: null,
  task: null
};

const VARIANTS = {
  nextTask: {
    scheduled: {
      taskIconVariant: 'text-success',
      dateAlertVariant: 'success'
    },
    overdue: {
      taskIconVariant: 'text-warning',
      dateAlertVariant: 'warning'
    }
  },
  latestTask: {
    default: {
      taskIconVariant: 'text-dark-gray',
      dateAlertVariant: 'light'
    }
  }
};

function TaskPopover({ className, task, children, variant, entity, ...props }) {
  if (!task) {
    return children;
  }

  const taskVariant = VARIANTS[variant][task.status] ?? VARIANTS[variant].default;

  /*
   * Solução rápida para não exibirmos o popover em casos "inválidos"
   * ("próximas tarefas" sem status ou com done)
   */
  if (!taskVariant) {
    return children;
  }

  const { taskIconVariant, dateAlertVariant } = taskVariant;

  return (
    <Popover
      className={className}
      content={
        () => (
          <div className='d-flex'>
            <div className='p-2 me-2 d-inline-block'>
              <Icon
                name={task.type}
                className={taskIconVariant}
              />
            </div>

            <div className='flexible-width'>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='overflow-hidden me-2'>
                  <h4>{task.typeName}</h4>

                  <DotList truncate
                    className='text-muted text-small'
                    items={[
                      toDateTimeString(task.createdAt),
                      task.createdBy.name
                    ]}
                  />
                </div>

                {
                  task.dueAt &&
                    <DateAlert
                      date={task.dueAt}
                      allDay={task.allDay}
                      variant={dateAlertVariant}
                    />
                }
              </div>

              <div className='max-height-4 mt-4 overflow-auto'>
                {task.text}
              </div>

              {
                task.dueAt &&
                  <AvatarList
                    className='mt-4'
                    items={task.assignedUsers.map((user) => ({
                      name: user.name,
                      url: user.avatarUrl
                    }))}
                  />
              }
            </div>
          </div>
        )
      }
      footer={
        ({ onHide }) => (
          <div className='text-center fw-bold'>
            <EntityLink entity={entity} onClick={onHide}>
              Ver histórico completo
            </EntityLink>
          </div>
        )
      }
      trigger={['click']}
      placement='right-start'
      rootClose
      rootCloseEvent='mousedown'
      flip
      { ...props }
    >
      {children}
    </Popover>
  );
}

TaskPopover.propTypes = propTypes;
TaskPopover.defaultProps = defaultProps;

export default TaskPopover;
