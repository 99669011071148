import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Card from '@/components/Card';
import Icon from '@/components/Icon';

const propTypes = {
  actionComponent: PropTypes.node.isRequired,
  icon: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string
};

const defaultProps = {
  actionComponent: null,
  icon: '',
  title: '',
  subtitle: '',
  className: ''
};

function ActionCard({ icon, title, subtitle, className, actionComponent }) {
  return (
    <Card className={
      classnames(`d-flex justify-content-between justify-content-center
      flex-row card mb-3 border-lighter-gray`, className)
    }>
      <div className='d-flex align-items-center'>
        <div className={
          `bg-primary bg-opacity-10 rounded p-1
          m-5 text-dark-gray lh-0 position-relative`
        }>
          <Icon name={icon} />
        </div>
        <div>
          <div className='h5 mt-4 mb-2 text-dark-gray'>{title}</div>
          <div className='mb-5 text-medium-gray'>
            {subtitle}
          </div>
        </div>
      </div>
      {actionComponent}
    </Card>
  );
}

ActionCard.propTypes = propTypes;
ActionCard.defaultProps = defaultProps;

export default ActionCard;
