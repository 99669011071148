import { useMemo } from 'react';
import { useAuth } from '@/lib/auth';
import { useStoredState } from '@/hooks';

const LAST_VIEWED_FUNNEL_KEY_PREFFIX = 'last-viewed-funnel';

export function buildLastViewedFunnelKey(user) {
  return `${LAST_VIEWED_FUNNEL_KEY_PREFFIX}-${user.id}`;
}

export function useLastViewedFunnel() {
  const { user } = useAuth();

  const storageKey = useMemo(
    () => buildLastViewedFunnelKey(user),
    [user]
  );

  const { value: id, store } = useStoredState(storageKey, null);

  return { id, store };
}
