import React from 'react';
import PropTypes from 'prop-types';
import { DayPicker as Base } from 'react-day-picker';
import ptBR from 'date-fns/locale/pt-BR';

const propTypes = {
  onChange: PropTypes.func
};

const defaultProps = {
};

function DatePicker({ onChange, ...props }) {
  return (
    <Base
      locale={ptBR}
      onSelect={onChange}
      { ...props }
    />
  );
}

DatePicker.propTypes = propTypes;

DatePicker.defaultProps = defaultProps;

export default DatePicker;
