import React, { useMemo, useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import Offcanvas from '@/components/Offcanvas';
import { OffcanvasPageProvider } from '@/contexts';

function OffcanvasPage() {
  const navigate = useNavigate();

  const [show, setShow] = useState(true);
  const onHide = () => setShow(false);
  const onExited = () => navigate('./..');

  const value = useMemo(() => ({
    hide: () => setShow(false)
  }), []);

  return (
    <OffcanvasPageProvider value={value}>
      <Offcanvas
        show={show}
        onHide={onHide}
        onExited={onExited}
      >
        <Outlet />
      </Offcanvas>
    </OffcanvasPageProvider>
  );
}

export default OffcanvasPage;
