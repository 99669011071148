import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useAuth } from '@/lib/auth';
import { useCreateAudioSummary, useAudioSummary } from '@/api';
import { useTracking } from '@/lib/tracking';
import { helpUrl, plansPath, settingsManageUpdatePlanPath } from '@/routes';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Icon from '@/components/Icon';
import Popover from '@/components/Popover';
import Badge from '@/components/Badge';
import LoadSpinner from '@/components/LoadSpinner';
import Button from '@/components/Button';
import VoipGptFeedback from '@/components/VoipGptFeedback';
import AudioTranscriptionModal from '@/components/AudioTranscriptionModal';
import ExternalLink from '@/components/ExternalLink';

const propTypes = {
  activity: PropTypes.object
};

const DEFAULT_ACCORDION_KEY = 'summary';

function AccordionHeader({ eventKey, open, children, disabled }) {
  const decoratedOnClick = useAccordionButton(eventKey, () => { });
  const icon = open ? 'arrow-up' : 'arrow-down';

  return (
    <div
      className={classnames(
        'd-flex p-3 w-100 justify-content-between align-items-center',
        { 'cursor-pointer': !disabled }
      )}
      onClick={(e) => {
        if (!disabled) {
          decoratedOnClick(e);
        }
      }}
    >
      {children}
      <Icon name={icon} className='text-dark-gray' />
    </div>
  );
}

function breakContent(content = '') {
  if (content) {
    return content
      .split('\n')
      .filter((sentence) => sentence.trim() !== '')
      .map((sentence) => `<li>${sentence.trim()}</li>`)
      .join('');
  }
  return '';
}

function SmartSummary({ activity }) {
  const audioSummaryId = activity?.audioSummary?.id;

  const alert = useAlert();
  const { user } = useAuth();
  const tracker = useTracking();

  const [isOpenHeader, setIsOpenHeader] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const createAudioSummaryMutation = useCreateAudioSummary();
  const { data, isLoading: isLoadingAudioSummary } = useAudioSummary({
    audioSummaryId,
    config: { enabled: Boolean(audioSummaryId && isOpenHeader) }
  });

  const audioSummary = data?.data ?? {};
  const isFromVoip = activity?.isFromVoip;
  const audioTranscriptionId = audioSummary?.audioTranscriptionId;
  const liked = audioSummary?.liked;
  const likedInputs = liked === true || liked === false || liked === null;
  const content = breakContent(audioSummary?.content);
  const isLoading = isProcessing || isLoadingAudioSummary;
  const hasAccess = user?.account?.legacyPro || user?.account?.performanceOrHigher;

  const onCreateAudioSummary = (params) => {
    setIsProcessing(true);
    createAudioSummaryMutation.mutate(params, {
      onSuccess: () => {
        setIsProcessing(false);
        alert.show('Resumo com ChatGPT processado com sucesso!', {
          variant: 'success',
          timeout: 5000
        });
      },
      onError: () => {
        setIsProcessing(false);
        setIsOpenHeader(false);
        alert.show('Resumo com ChatGPT falhou, tente novamente em breve.', {
          variant: 'danger',
          timeout: 5000
        });
      }
    });
  };

  const handleAccordionToggle = (eventKey) => {
    const isExpanded = eventKey === DEFAULT_ACCORDION_KEY;
    setIsOpenHeader(isExpanded);
    if (isExpanded) {
      tracker.trackVoipGptSummaryViewed({ user });
    }
    if (isExpanded && !audioSummaryId && !isLoading) {
      onCreateAudioSummary({ activity_id: activity.id });
    }
  };

  if (!isFromVoip) {
    return null;
  }

  const currentEventKey = isOpenHeader ? DEFAULT_ACCORDION_KEY : '';
  const isNewPro = user?.account.plan.name === 'Pro';
  const updatePerformanceUrl = settingsManageUpdatePlanPath({ new_plan_code: 'performance_plan' });

  return (
    <>
      <Accordion
        onSelect={hasAccess ? handleAccordionToggle : () => setIsOpenHeader(!isOpenHeader)}
        className='mt-2'
      >
        <Accordion.Item
          eventKey={currentEventKey}
          className='border-purple'
        >
          <AccordionHeader
            open={isOpenHeader}
            className='d-flex p-3'
            eventKey={DEFAULT_ACCORDION_KEY}
            disabled={isLoading}
          >
            <div className='d-flex'>
              <Icon name='ai' className='text-purple' size='sm' />
              <div className='ms-2'>
                <span className='text-purple fw-bold'>Resumo com ChatGPT</span>
                <Popover
                  placement='top-start'
                  trigger={['hover']}
                  showArrow
                  rootCloseEvent='mousedown'
                  rootClose
                  content={() => (
                    <>
                      <div>
                        <Icon name='ai' size='sm' />
                      </div>
                      <h4 className='text-darker-gray my-2'>
                        O Telefone Virtual Inteligente está em desenvolvimento
                      </h4>
                      <span className='text-dark-gray'>
                        A sumarização e a transcrição podem ter falhas. Caso perceba alguma,
                        clique no ícone 👎. Se o resumo fizer sentido, clique no 👍. Dessa forma,
                        podemos aprimorar a funcionalidade.
                      </span>
                    </>
                  )}
                >
                  <Badge variant='light' className='ms-1 text-small cursor-pointer'>
                    <span className='h6'>Beta</span>
                  </Badge>
                </Popover>
              </div>
            </div>
          </AccordionHeader>
          {
            hasAccess
              ? (
                <Accordion.Body className='px-8 mx-1'>
                  <hr className='text-light-gray opacity-75 mt-n4 mb-4' />
                  {
                    isLoading && !likedInputs
                      ? (
                        <div className='d-flex justify-content-center text-medium-gray'>
                          <LoadSpinner size='sm' className='d-inline-block me-2' />
                          Carregando resumo...
                        </div>
                      )
                      : (
                        <div>
                          <div
                            dangerouslySetInnerHTML={{ __html: content }}
                            className='text-start text-dark'
                          />
                          <div
                            className='mt-3 d-flex justify-content-between align-items-center me-n4'
                          >
                            <Button
                              variant='light'
                              onClick={() => {
                                setShowModal(true);
                                tracker.trackVoipGptTranscriptionViewed({ user });
                              }}>
                              <Icon name='transcription' className='me-1 mb-1' />
                              <span className='text-dark'>Transcrição completa</span>
                            </Button>
                            <VoipGptFeedback data={audioSummary} entity='audioSummary' />
                          </div>
                        </div>
                      )}
                </Accordion.Body>
              )
              : (
                < Accordion.Body className='px-8 mx-1'>
                  <hr className='text-light-gray opacity-75 mt-n4 mb-4' />
                  <div>
                    <div className='bg-light-green text-center rounded p-1 width-3'>
                      <span className='text-success text-small fw-bold'>
                        Exemplo
                      </span>
                    </div>
                    <div className='mt-2'>
                      <div>
                        <li>
                          Carlos, representante da empresa XYZ, contatou Leo para
                          apresentar a nova linha de produtos de tecnologia.
                        </li>
                        <li>
                          Após uma breve introdução, Carlos destacou os benefícios
                          dos dispositivos de armazenamento em nuvem e laptops
                          com melhorias em desempenho e segurança.
                        </li>
                        <li>
                          Leo mostrou interesse e concordou em agendar uma demonstração
                          para a próxima
                        </li>
                      </div>
                      <div className='text-center'>
                        <div className='btn-gradient'>
                          <Icon
                            name='lock'
                            size='lg'
                            className='bg-primary bg-opacity-10 p-2 rounded-circle text-medium-gray'
                          />
                        </div>
                        <div className='mt-2'>
                          <span className='fw-bold text-darker-gray'>
                            A inteligência artificial do Agendor consegue resumir e
                            <br /> transcrever suas ligações.
                          </span>
                        </div>
                        <div className='mt-2'>
                          <span className='text-dark-gray'>
                            Disponível apenas no plano Performance.
                          </span>
                        </div>
                        <div className='mt-4'>
                          <Button
                            variant='light'
                            href={helpUrl('use-voip-gpt')}
                            as={ExternalLink}
                          >
                            <Icon
                              name='external-link'
                              className='me-2'
                            />
                            Saiba mais
                          </Button>
                          <Button
                            as={ExternalLink}
                            href={isNewPro ? updatePerformanceUrl : plansPath()}
                            target='_blank'
                            variant='primary'
                            className='ms-2'
                          >
                            <Icon name='rocket' className='me-2 text-white' />
                            Contratar plano
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              )
          }
        </Accordion.Item>
      </Accordion>
      <AudioTranscriptionModal
        show={showModal}
        onHide={() => setShowModal(false)}
        audioTranscriptionId={audioTranscriptionId}
      />
    </>
  );
}

SmartSummary.propTypes = propTypes;

export default SmartSummary;
