import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useStoredState } from '@/hooks';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  horizontalOnly: PropTypes.bool
};

const defaultProps = {
  className: '',
  children: null,
  horizontalOnly: false
};

const MOUSE_SCROLL_BUTTON = 4;
const MIN_MOUSE_SCROLL_DELTA = 20;

/**
 *
 * Container que permite scroll dentro dele arrastando com o mouse.
 *
 */
const DragScrollable = React.forwardRef((props, ref) => {
  const { className, children, horizontalOnly, ...propsRest } = props;

  const { value: hasDoneScrollWithDrag, store: setHasDoneScrollWithDrag } = useStoredState(
    'has_done_scroll_with_drag',
    false
  );

  const innerRef = useRef(null);
  const element = ref || innerRef;

  /*
   * As coordenadas do mouse são uma ref e não estado porque, como elas mudam
   * muito frequentemente, se fosse estado, isso geraria muitos re-renders.
   */
  const coordinates = useRef(null);
  const hasDrag = useRef(false);


  const onStart = (event) => {
    /*
     * A flag `defaultPrevented` é utilizada para detectar quando o evento está
     * relacionado a um drag num elemento filho.
     * ref: https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/how-we-use-dom-events.md
     */
    if (event.defaultPrevented || event.buttons !== MOUSE_SCROLL_BUTTON) {
      return;
    }

    const { clientX: x, clientY: y } = event;

    coordinates.current = { x, y };
    hasDrag.current = false;

    if (element.current) {
      // Classes são adicionadas diretamente via DOM também para evitar re-render.
      element.current.classList.add('cursor-grabbing', 'user-select-none');
    }

    event.preventDefault();
  };

  const onMove = (event) => {
    if (event.defaultPrevented || !coordinates.current || event.buttons !== MOUSE_SCROLL_BUTTON) {
      return;
    }

    const { clientX: x, clientY: y } = event;
    const { x: previousX, y: previousY } = coordinates.current;

    const deltaX = x - previousX;
    const deltaY = horizontalOnly ? 0 : y - previousY;

    if (element.current) {
      element.current.scrollBy(-deltaX, -deltaY);
    }

    coordinates.current = { x, y };

    // Utiliza um delta mínimo para ignorar casos onde o usuário apenas clica no botão de scroll.
    hasDrag.current = hasDrag.current || Math.abs(deltaX) > MIN_MOUSE_SCROLL_DELTA;
  };

  const onStop = (event) => {
    if (event.defaultPrevented || !coordinates.current) {
      return;
    }

    if (hasDrag.current && !hasDoneScrollWithDrag) {
      setHasDoneScrollWithDrag(true);
    }

    coordinates.current = null;
    hasDrag.current = false;

    if (element.current) {
      element.current.classList.remove('cursor-grabbing', 'user-select-none');
    }
  };

  return (
    <div
      ref={element}
      className={className}
      /*
       * Foram utilizados eventos de mouse e não eventos de drag devido a:
       * - melhor suporte de browsers (Firefox não inclui coordenadas no `drag`)
       * - no drag por padrão aparece uma imagem "ghost" que teria que ser retirada
       */
      onMouseDown={onStart}
      onMouseMove={onMove}
      onMouseUp={onStop}
      onMouseLeave={onStop}
      { ...propsRest}
    >
      {children}
    </div>
  );
});

DragScrollable.propTypes = propTypes;
DragScrollable.defaultProps = defaultProps;
DragScrollable.displayName = 'DragScrollable';

export default DragScrollable;
