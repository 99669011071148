import React from 'react';
import PropTypes from 'prop-types';
import { TriggerContent, ActionContent } from '@/feature/automation';

const propTypes = {
  className: PropTypes.string,
  tooltip: PropTypes.bool,
  data: PropTypes.shape({
    active: PropTypes.bool,
    triggerParams: PropTypes.object,
    actionParams: PropTypes.object
  })
};

const defaultProps = {
  tooltip: false,
  data: {
    active: true
  }
};

function SummaryContent(props) {
  const {
    className,
    data: { active, triggerParams, actionParams },
    tooltip,
    template
  } = props;

  return (
    <div className={className}>
      <TriggerContent
        active={active}
        params={triggerParams}
        template={template}
      />

      <span> → </span>

      <ActionContent
        active={active}
        params={actionParams}
        template={template}
        tooltip ={tooltip}
      />
    </div>
  );
}

SummaryContent.propTypes = propTypes;
SummaryContent.defaultProps = defaultProps;

export default SummaryContent;
