import React from 'react';
import PropTypes from 'prop-types';
import image from 'images/billing/pessoa-triste.svg';
import Button from '@/components/Button';

const propTypes = {
  closeModal: PropTypes.func
};

function LeadsGenerationError({ closeModal }) {
  return (
    <>
      <img width={148} src={image} className='mb-2' />

      <h2 className='mb-2'>Não conseguimos processar seu pagamento</h2>

      <p className='mb-6'>
        Por favor, verifique se os dados do cartão estão corretos e se ele ainda é válido.
        Caso contrário, tente usar outro cartão de crédito ou outra forma de pagamento disponível.
      </p>

      <Button onClick={closeModal} variant='light'>Fechar</Button>
    </>
  );
}

LeadsGenerationError.prototype = propTypes;

export default LeadsGenerationError;
