import { useQuery } from 'react-query';
import { get } from '@/lib/fetch';

export const leadOriginsKeys = {
  all: ['lead-origins'],
  lists: () => [...leadOriginsKeys.all, 'list'],
  list: (params) => [...leadOriginsKeys.lists(), params]
};

export async function getLeadOrigins(params = {}) {
  const { data } = await get('/lead_origins', params);

  return data;
}

export function useLeadOrigins({ params = {}, config = {} }) {
  return useQuery({
    ...config,
    queryKey: leadOriginsKeys.list(params),
    queryFn: () => getLeadOrigins()
  });
}
