import React from 'react';
import Icon from '@/components/Icon';

function CardRibbon() {
  return (
    <>
      <div className='card-ribbon top-0 end-0 position-absolute' />

      <Icon
        name='star'
        className='me-2 mt-2 top-0 end-0 position-absolute'
      />
    </>
  );
}

export default CardRibbon;
