import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import SearchBar from '@/components/SearchBar';
import CustomerSearch from '@/components/CustomerSearch';

const propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  className: PropTypes.string
};

const defaultProps = {
  className: ''
};

function PersonSelect(props) {
  const { onChange, defaultValue, className } = props;

  const [value, setValue] = useState(defaultValue);
  const [selected, setSelected] = useState(Boolean(defaultValue));

  const inputProps = useMemo(() => ({
    placeholder: 'Digite o nome da pessoa',
    label: 'Pessoa',
    autoComplete: 'off',
    maxLength: 60,
    isClearable: selected,
    disabled: selected,
    onClear: () => {
      setValue('');
      setSelected(false);
      onChange(undefined);
    }
  }), [selected, onChange]);

  const popoverProps = {
    className: 'popover-padding-0 w-100 max-width-100',
    size: 'lg',
    placement: 'bottom-start'
  };

  const onSelect = ({ id, name, onHideOptions }) => {
    setValue(name);
    setSelected(true);
    onChange(id);
    onHideOptions();
  };

  return (
    <SearchBar
      name='customer-search'
      types={['person']}
      perPage={5}
      resultsFooter={CustomerSearch.FooterStatus}
      ItemComponent={CustomerSearch.Item}
      inputProps={inputProps}
      popoverProps={popoverProps}
      onSelect={onSelect}
      className={className}
      searchValue={value}
    />
  );
}

PersonSelect.propTypes = propTypes;
PersonSelect.defaultProps = defaultProps;

export default PersonSelect;
