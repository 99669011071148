import React, { useState } from 'react';
import { useAlert } from 'react-alert';

import Form from '@/components/EntityModal/Activities/Form';
import History from '@/components/EntityModal/Activities/History';
import { useOrganization } from '@/contexts/organizationModal';
import APIErrorMessage from '@/components/APIErrorMessage';
import {
  useCreateActivity,
  useUpdateActivity,
  useDeleteActivity,
  useInfiniteActivities
} from '@/api';
import sortBy from 'lodash/sortBy';

const PER_PAGE = 30;

const ENTITY_NAME = 'organization';

export function createActivityListParams(entity, id) {
  return { per_page: PER_PAGE, entity_id: Number(id), entity };
}

function Activities() {
  const [, setIsModified] = useState(false);
  const organization = useOrganization();
  const id = organization.id;
  const alert = useAlert();
  const listParams = createActivityListParams('organization', id);
  const createActivityMutation = useCreateActivity({ listParams });
  const updateActivityMutation = useUpdateActivity();
  const deleteActivityMutation = useDeleteActivity();
  const isActivitiesEnabled = Boolean(organization?.id);
  const {
    data: { pages: activityPages } = {},
    isLoading,
    isFetching: isFetchingActivities,
    isFetchingNextPage: isFetchingNextPageActivities,
    fetchNextPage: fetchNextPageActivities,
    hasNextPage: hasNextPageActivities
  } = useInfiniteActivities({
    params: listParams,
    config: { enabled: isActivitiesEnabled, staleTime: 1000 }
  });
  const activities = sortBy(activityPages?.flatMap((page) => page.data), 'createdAt').reverse();

  const onCreateActivity = (params, done) => {
    createActivityMutation.mutate(params, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='activities' action='create' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.(err);
      },
      onSuccess: () => {
        alert.show(
          'Atividade cadastrada com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        done?.();
      }
    });
  };

  const onUpdateActivity = (activityId, params, done) => {
    updateActivityMutation.mutate({ activityId, params }, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='activities' action='update' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.(err);
      },
      onSuccess: () => {
        alert.show(
          'Atividade atualizada com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        setIsModified(true);
        done?.();
      }
    });
  };

  const onDeleteActivity = (activityId, done) => {
    deleteActivityMutation.mutate({ activityId }, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='activities' action='destroy' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.(err);
      },
      onSuccess: () => {
        alert.show(
          'Atividade excluída com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        setIsModified(true);
        done?.();
      }
    });
  };

  return (
    <div className='ms-3'>
      <Form entityName={ENTITY_NAME} entityId={id} onCreate={onCreateActivity} />
      <div className='text-end'>
        Mostrando atividades da empresa, pessoas e negócios relacionados
      </div>
      <History
        entity={organization}
        activities={activities}
        isFetchingActivities={isFetchingActivities}
        isFetchingNextPageActivities={isFetchingNextPageActivities}
        fetchNextPageActivities={fetchNextPageActivities}
        hasNextPageActivities={hasNextPageActivities}
        loading={isLoading}
        onUpdateActivity={onUpdateActivity}
        onDeleteActivity={onDeleteActivity}
        entityName={ENTITY_NAME}
      />
    </div>
  );
}

export default Activities;
