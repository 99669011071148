import React from 'react';
import ConfirmationModal from '@/components/ConfirmationModal';

function DeleteModal(props) {
  return (
    <ConfirmationModal
      confirmationText='Confirmar'
      confirmationLoadingText='Excluindo...'
      {...props}
    >
      <ConfirmationModal.Title>
        Deseja excluir esta automação?
      </ConfirmationModal.Title>

      <ConfirmationModal.Info>
        Esta ação não poderá ser desfeita.
      </ConfirmationModal.Info>
    </ConfirmationModal>
  );
}

export default DeleteModal;
