import React from 'react';
import PropTypes from 'prop-types';
import Select from '@/components/Inputs/Select';

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func
};

const defaultProps = {
  name: 'deal_status_id_eq',
  label: 'Status',
  placeholder: 'Qualquer status',
  multiple: false,
  isClearable: true,
  isSearchable: false,
  defaultValue: null,
  onChange: () => {}
};

const options = [
  { label: 'Em andamento', value: 1 },
  { label: 'Ganhos', value: 2 },
  { label: 'Perdidos', value: 3 }
];

function DealStatusSelect(props) {
  return <Select { ...props } options={options} />;
}

DealStatusSelect.propTypes = propTypes;
DealStatusSelect.defaultProps = defaultProps;

export default DealStatusSelect;
