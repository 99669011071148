import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import { QRCodeSVG } from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { useLeadsGenerationContext } from '@/contexts';
import PaymentWrapper from '@/components/LeadsGeneration/Payment/PaymentWrapper';
import PaymentMethodForm from '@/components/Billing/PaymentMethodForm';
import Button from '@/components/Button';
import Card from '@/components/Card';
import ExternalLink from '@/components/ExternalLink';
import Icon from '@/components/Icon';

import { useCreateConfirms, useTransaction } from '@/api';
import { applyMask } from '@/masks';
import {
  parseDate, toDateTimeString, toDayOfWeek, toTextualDateString
} from '@/date';

import checkImage from 'images/check.svg';

const REFETCH_INTERVAL = 5000;
const PAYMENT_DATA = {
  paymentMethod: { id: null }
};

const PAYMENT_TYPE_IDS = {
  credit_card: 13,
  bank_slip: 14,
  pix: 15
};

const propTypes = {
  show: PropTypes.bool
};

const defaultProps = {
  show: true
};

function PaymentData({ show, onClose }) {
  const {
    prevStep,
    goToStep,
    isEnabledEditingAccountData,
    callMixpanelEvent,
    setLeadsPaymentProcess,
    funnelData,
    decisionMakersDepartmentsData,
    decisionMakersLevelsData,
    selectedLeads,
    setImportId
  } = useLeadsGenerationContext();

  const [paymentType, setPaymentType] = useState('credit_card');
  const [paymentData, setPaymentData] = useState(PAYMENT_DATA);
  const [paymentCompletionData, setPaymentCompletionData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [transactionId, setTransactionId] = useState(null);

  const createConfirmsMutation = useCreateConfirms();
  const alert = useAlert();

  const isPix = paymentType === 'pix';
  const isBankSlip = paymentType === 'bank_slip';

  const isAdvanceStepEnable =
    isPix ||
    isBankSlip ||
    (paymentData.paymentMethod.id) ||
    (paymentData.stripe && paymentData?.event?.complete);

  const previousStep = 5;
  const onClickPrev = () => (isEnabledEditingAccountData ? prevStep() : goToStep(previousStep));

  const handleCreditCardPaymentError = () => {
    alert.show(
      <div>
        <h6>Infelizmente, não conseguimos processar seu pagamento</h6>
        <div>Por favor, tente novamente ou adicione um novo cartão.</div>
      </div>,
      { variant: 'danger' }
    );
  };

  const getConfirmParams = () => {
    const { funnel_id: funnelId } = funnelData;

    return ({
      cnpjs: selectedLeads,
      funnel_id: funnelId,
      decision_maker_departments: decisionMakersDepartmentsData,
      decision_maker_levels: decisionMakersLevelsData
    });
  };

  const getPixConfirmParams = () => ({
    ...getConfirmParams(),
    payment_type_id: PAYMENT_TYPE_IDS.pix
  });

  const getBankSlipConfirmParams = () => ({
    ...getConfirmParams(),
    payment_type_id: PAYMENT_TYPE_IDS.bank_slip
  });

  const getCreditCardConfirmParams = async () => {
    let paymentMethodId = paymentData.paymentMethod.id;

    if (paymentData.stripe) {
      const { error, paymentMethod } = await paymentData.stripe.createPaymentMethod(
        {
          elements: paymentData.elements,
          params: {
            billing_details: {
              address: {
                country: 'BR'
              }
            }
          }
        }
      );

      if (error) {
        console.error(error);
        setIsLoading(false);

        return handleCreditCardPaymentError();
      }

      paymentMethodId = paymentMethod.id;
    }

    return {
      ...getConfirmParams(),
      payment_method_id: paymentMethodId,
      payment_type_id: PAYMENT_TYPE_IDS.credit_card
    };
  };

  const confirmPayment = (params) => {
    callMixpanelEvent('trackLeadsGenerationPaymentStarted');

    createConfirmsMutation.mutate(params, {
      onSuccess: ({ data }) => {
        setTransactionId(data.id);
      },
      onError: () => {
        setIsLoading(false);
        setLeadsPaymentProcess('error');
      }
    });
  };

  const onConfirmPayment = async () => {
    setIsLoading(true);

    let confirmParams = null;

    if (isPix) {
      confirmParams = getPixConfirmParams();
    } else if (isBankSlip) {
      confirmParams = getBankSlipConfirmParams();
    } else {
      confirmParams = await getCreditCardConfirmParams();
    }

    confirmPayment(confirmParams);
  };

  useTransaction({
    billingTransactionId: transactionId,
    config: {
      refetchInterval: REFETCH_INTERVAL,
      cacheTime: 0,
      enabled: Boolean(isLoading && transactionId),
      onSuccess: ({ data }) => {
        switch (data.statusName) {
          case 'Aguardando Pagto':
            return;
          case 'Em análise':
            if (isPix) {
              const details = data.transactionDetails;

              setPaymentCompletionData({
                qrCode: details.qr_code,
                expiresAt: details.expires_at
              });
            } else if (isBankSlip) {
              const details = data.transactionDetails;

              setPaymentCompletionData({
                pdf: details.pdf,
                number: details.number,
                expiresAt: details.expires_at
              });
            }

            return;
          case 'Aprovado':
          case 'Completo':
            if (data.transactionDetails.importId) {
              setIsLoading(false);
              setLeadsPaymentProcess('importing');
              setImportId(data.transactionDetails.importId);
            }
            break;
          default:
            setIsLoading(false);
            setLeadsPaymentProcess('error');
        }
      }
    }
  });

  const renderPaymentSection = () => (
    <PaymentMethodForm
      paymentType={paymentType}
      setPaymentType={setPaymentType}
      setPaymentData={setPaymentData}
      isCreditCardSubscription={false}
    />
  );

  const renderCompletionSection = () => {
    const CompletionComponent = isPix ? PixCompletion : BankSlipCompletion;

    return (
      <CompletionComponent
        data={paymentCompletionData}
      />
    );
  };

  return (
    <PaymentWrapper
      show={show}
      isLoading={isLoading}
      disabled={!isAdvanceStepEnable}
      paymentCompletionData={paymentCompletionData}
      onClose={onClose}
      onClickNext={onConfirmPayment}
      onClickPrev={onClickPrev}
      buttonNextText='Finalizar'
    >
      {paymentCompletionData ? renderCompletionSection() : renderPaymentSection()}
    </PaymentWrapper>
  );
}

function PixCompletion({ data }) {
  return (
    <Card className='p-4 border-light'>
      <div className='d-flex flex-column align-items-center mb-2'>
        <h2 className='mb-4'>Código do Pix gerado</h2>

        <div className='mb-4 text-body'>
          <QRCodeSVG
            fgColor='currentColor'
            value={data.qrCode}
            size='172'
          />
        </div>

        <CopyToClipboard text={data.qrCode}>
          <Button
            variant='lighter-blue'
            className='mb-2 rounded-sm text-primary text-small'
          >
            <Icon name='copy' size='sm' className='me-2' />

            Copiar código Pix
          </Button>
        </CopyToClipboard>

        <span className='text-smaller text-dark-gray'>
          Vencimento: {toDateTimeString(parseDate(data.expiresAt))}
        </span>
      </div>

      <ol type='1' className='ps-4 text-smaller text-dark-gray'>
        <li>
          Acesse o app do seu banco ou internet banking e escolha &quot;Pagar com Pix&quot;.
        </li>

        <li>
          Copie e cole o código acima ou escaneie o QR code.
        </li>

        <li>
          Confira as informações.
        </li>

        <li>
          Finalize o pagamento.
        </li>
      </ol>
    </Card>
  );
}

function BankSlipCompletion({ data }) {
  const expirationDate = parseDate(data.expiresAt);

  return (
    <Card className='p-4 border-light align-items-center'>
      <img className='width-3 mb-2' src={checkImage} />

      <h4 className='mb-2'>Boleto foi gerado</h4>

      <div className='text-dark-gray text-center'>
        Quando o pagamento for processado, seu leads serão gerados.
      </div>

      <hr className='w-100 mt-5 mb-6 text-dark-gray' />

      <div className='d-flex flex-column align-items-center text-small fw-bold text-dark-gray mb-4'>
        <div className='mb-1'>Cód. do boleto</div>

        <h4 className='mb-1 text-body text-center'>{applyMask(data.number, 'bank_slip')}</h4>

        <div>Vencimento: {toDayOfWeek(expirationDate)}, {toTextualDateString(expirationDate)}</div>
      </div>

      <div className='d-flex align-items-center mb-4'>
        <CopyToClipboard text={data.number}>
          <Button
            variant='outline-dark-gray'
            className='me-2'
          >
            <Icon name='copy' size='sm' />

            <span className='ms-2'>Copiar cód. do boleto</span>
          </Button>
        </CopyToClipboard>

        <Button
          as={ExternalLink}
          target='_blank'
          href={data.pdf}
        >
          <Icon name='download' size='sm' />

          <span className='ms-2'>Baixar boleto</span>
        </Button>
      </div>
    </Card>
  );
}

PaymentData.propTypes = propTypes;
PaymentData.defaultProps = defaultProps;

export default PaymentData;
