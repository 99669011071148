export const HTTP_STATUSES = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  UNPROCESSABLE_ENTITY: 422,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504
};

export function isUnauthorizedRequestError(error) {
  return error.isFetchError && error.status === HTTP_STATUSES.UNAUTHORIZED;
}

export function isNotFoundError(error) {
  return error.isFetchError && error.status === HTTP_STATUSES.NOT_FOUND;
}

export function isUnprocessableEntityError(error) {
  return error.isFetchError && error.status === HTTP_STATUSES.UNPROCESSABLE_ENTITY;
}

export function isForbiddenError(error) {
  return error.isFetchError && error.status === HTTP_STATUSES.FORBIDDEN;
}
