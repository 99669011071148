const MAX_FUNNELS = 30;
const MAX_STAGES = 20;

export function canCreateFunnel({ user, data: { totalCount } }) {
  let effect, reason;

  if (!user.account.paid) {
    effect = 'paywall';
    reason =
      'Para personalizar seus funis e as etapas do seu processo comercial, ' +
      'é necessário fazer upgrade para o Plano Pro.';
  } else if (!user.admin) {
    effect = 'disable';
    reason = 'Apenas administradores podem criar funis.';
  } else if (totalCount >= MAX_FUNNELS) {
    effect = 'disable';
    reason = `Não é possível ter mais de ${MAX_FUNNELS} funis.`;
  } else {
    effect = 'allow';
  }

  return { effect, reason };
}

export function canEditFunnel({ user, _data }) {
  let effect, reason;

  if (!user.account.paid) {
    effect = 'paywall';
    reason =
      'Para personalizar seus funis e as etapas do seu processo comercial, ' +
      'é necessário fazer upgrade para o Plano Pro.';
  } else if (!user.admin) {
    effect = 'disable';
    reason = 'Apenas administradores podem editar funis.';
  } else {
    effect = 'allow';
  }

  return { effect, reason };
}

export function canCreateStage({ user, data: { totalCount } }) {
  let effect, reason;

  if (!user.account.paid) {
    effect = 'paywall';
    reason =
      'Para personalizar seus funis e as etapas do seu processo comercial, ' +
      'é necessário fazer upgrade para o Plano Pro.';
  } else if (!user.admin) {
    effect = 'disable';
    reason = 'Apenas administradores podem criar etapas.';
  } else if (totalCount >= MAX_STAGES) {
    effect = 'disable';
    reason = `Não é possível ter mais de ${MAX_STAGES} etapas num funil.`;
  } else {
    effect = 'allow';
  }

  return { effect, reason };
}

export function canDeleteFunnel({ user, data: { isDefault } }) {
  let effect, reason;

  if (!user.account.paid) {
    effect = 'paywall';
    reason =
      'Para personalizar seus funis e as etapas do seu processo comercial, ' +
      'é necessário fazer upgrade para o Plano Pro.';
  } else if (!user.admin) {
    effect = 'disable';
    reason = 'Apenas administradores podem excluir funis.';
  } else if (isDefault) {
    effect = 'disable';
    reason = 'Não é possível excluir o funil padrão.';
  } else {
    effect = 'allow';
  }

  return { effect, reason };
}
