import React from 'react';
import { createStore } from 'zustand';
import merge from 'lodash/merge';

export function usePersonModalStore({ onUpdate, person, onDelete }) {
  return React.useState(() => createStore((set) => ({
    onUpdate: (props, done) => {
      const finalCallback = ({ error, data }) => {
        done?.(error);

        if (data) {
          set({ person: merge(person, data) });
        }
      };
      return onUpdate(props, finalCallback);
    },
    onDelete,
    person
  })));
}
