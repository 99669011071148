import React from 'react';
import Icon from '@/components/Icon';
import Dropdown from '@/components/Dropdown';
import {
  legacyDealsUrl,
  legacyOrganizationsUrl,
  legacyPeopleUrl,
  legacyReportsUrl
} from '@/routes';
import ExternalLink from '@/components/ExternalLink';

function HeaderItemsDropdown({ dealsView }) {
  return (
    <Dropdown className='align-self-center d-block d-sm-none'>
      <Dropdown.Toggle
        id='menu-toggle'
        variant='darker-gray'
        className='align-self-center btn btn-transparent-dark btn-sm'
      >
        <Icon name='arrow-down' className='me-1' />
        <span>Mais</span>
      </Dropdown.Toggle>

      <Dropdown.Menu
        renderOnMount
        className='min-width-5 z-index-1090'
      >
        <Dropdown.Item
          href={legacyPeopleUrl()}
          className='text-dark-gray'
          as={ExternalLink}
        >
          <Icon name='person' className='me-2 text-primary' />
          <span className='fw-bold'>Pessoas</span>
        </Dropdown.Item>

        <Dropdown.Item
          href={legacyDealsUrl(dealsView)}
          className='text-dark-gray'
          as={ExternalLink}
        >
          <Icon name='deal' className='me-2 text-primary' />
          <span className='fw-bold'>Negócios</span>
        </Dropdown.Item>

        <Dropdown.Item
          href={legacyOrganizationsUrl()}
          className='text-dark-gray'
          as={ExternalLink}
        >
          <Icon name='organization' className='me-2 text-primary' />
          <span className='fw-bold'>Empresas</span>
        </Dropdown.Item>

        <Dropdown.Item
          href={legacyReportsUrl()}
          className='text-dark-gray'
          as={ExternalLink}
        >
          <Icon name='report' className='me-2 text-primary' />
          <span className='fw-bold'>Relatórios</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default HeaderItemsDropdown;
