/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';

import Main from '@/components/Main';
import AccountData from '@/components/Billing/Steps/AccountData';
import PaymentData from '@/components/Voip/PaymentData';
import StepperHeader from '@/components/StepperHeader';
import PaymentSummary from '@/components/Voip/PaymentSummary';
import LoadingOverlay from '@/components/LoadingOverlay';
import PaymentSuccessModal from '@/components/Billing/PaymentSuccessModal';
import PaymentFailureModal from '@/components/Billing/PaymentFailureModal';
import Footer from '@/components/Footer';
import { CheckoutProvider } from '@/contexts';
import { useCurrentAccount, useCheckoutPreview } from '@/api';
import { settingsVoipPanelPath } from '@/routes';

const HEADER_LABELS = [
  'Dados de faturamento',
  'Pagamento'
];

const PAYMENT_DATA = {
  type: 'credit_card',
  credit_card: {
    is_valid: false,
    end_digits: null,
    brand: null
  }
};

const PAGE_TITLE = 'Adicionar recarga';

const LAST_LABEL_INDEX = HEADER_LABELS.length - 1;

const VOIP_PRODUCT_ID = 5;

const MIN_FREE_CHARGE_VALUE = 100;

const CHARGE_FEE = 2;

const CONFIRMATION_TITLE = 'Sua recarga está liberada';

const CONFIRMATION_MESSAGE = 'Agora você já pode utilizar seus créditos';

function VoipCheckout() {
  const [step, setStep] = useState(0);
  const [checkoutResumeData, setCheckoutResumeData] = useState();
  const [paymentData, setPaymentData] = useState(PAYMENT_DATA);
  const [encryptedData, setEncryptedData] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState();

  const prevStep = () => setStep(Math.max(step - 1, 0));
  const nextStep = () => setStep(Math.min(step + 1, LAST_LABEL_INDEX));

  const location = useLocation();
  const navigate = useNavigate();
  const queryValue = new URLSearchParams(location?.search)?.get('value');
  const rechargeValue = Number(Number(queryValue?.replaceAll(/[\D]+/gu, '')).toFixed(0));
  if (!rechargeValue) {
    return <Navigate to={settingsVoipPanelPath()} replace={true} />;
  }
  const serviceCharge = rechargeValue < MIN_FREE_CHARGE_VALUE ? CHARGE_FEE : 0;
  const finalValue = Number(rechargeValue) + Number(serviceCharge);

  const { isLoading: isLoadingCheckoutResume } = useCheckoutPreview({
    config: {
      cacheTime: 0,
      onSuccess: ({ data }) => {
        setCheckoutResumeData({
          ...data,
          rechargeValue,
          serviceCharge,
          finalValue,
          maxUsersCount: finalValue,
          isPlanPayment: false,
          additionalData: {
            info: 'Recarga de créditos no Agendor Voip',
            rechargeValue,
            serviceCharge
          }
        });
      }
    },
    params: {
      period: 'none'
    }
  });

  const { data, isLoading: isLoadingCurrentAccount } = useCurrentAccount();

  const value = {
    accountData: data?.data,
    paymentData,
    setPaymentData,
    setPaymentStatus,
    encryptedData,
    setEncryptedData,
    checkoutResumeData,
    isLoadingCheckoutResume,
    step,
    prevStep,
    nextStep,
    finalValue,
    maxUsersCount: finalValue,
    isPlanPayment: false,
    productId: VOIP_PRODUCT_ID
  };

  useEffect(() => {
    setCheckoutResumeData({
      ...checkoutResumeData,
      rechargeValue,
      serviceCharge,
      finalValue,
      maxUsersCount: finalValue,
      isPlanPayment: false,
      additionalData: {
        info: 'Recarga de créditos no Agendor Voip',
        rechargeValue,
        serviceCharge
      }

    });
  }, [checkoutResumeData?.rechargeValue]);

  const CurrentStep = useMemo(() => {
    const steps = [
      <AccountData
        key={1}
        onCancel={() => navigate(settingsVoipPanelPath())}
      />,
      <PaymentData
        key={2}
        onClose={() => navigate(settingsVoipPanelPath())}
      />
    ];

    return steps[step];
  }, [step]);

  if (!checkoutResumeData || isLoadingCurrentAccount) {
    return null;
  }

  return (
    <CheckoutProvider value={value}>
      <Main fluid className='flex-grow-1 overflow-y-scroll py-8 ps-4 pe-8 container-full-height'>
        <Container className='pb-9 mb-9'>
          <div className='text-center'>
            <h1 className='m-2'>{PAGE_TITLE}</h1>
            <p className='my-2 font-weight-normal' style={{ fontSize: '20px' }}>
              Falta pouco, finalize para efetuar a recarga de créditos.
            </p>

            <StepperHeader
              headerClassName='d-flex justify-content-center align-items-center fw-bold mt-4'
              currentStep={step}
              headerLabels={HEADER_LABELS}
            />
          </div>

          <div className='py-3 mb-3'>
            <Row>
              <Col xl={{ span: 5, offset: 1 }} xxl={{ span: 4, offset: 2 }} className='pe-0'>
                {CurrentStep}
              </Col>

              <Col xl={5} xxl={4}>
                <LoadingOverlay show={isLoadingCheckoutResume} className='rounded'>
                  <PaymentSummary
                    serviceCharge={serviceCharge}
                    rechargeValue={rechargeValue}
                    finalValue={finalValue}
                  />
                </LoadingOverlay>
              </Col>
            </Row>
          </div>
        </Container>
      </Main>

      <PaymentSuccessModal
        show={paymentStatus === 'success'}
        title={CONFIRMATION_TITLE}
        message={CONFIRMATION_MESSAGE}
        redirectPath={settingsVoipPanelPath()}
      />

      <PaymentFailureModal
        show={paymentStatus === 'error'}
        onHide={() => setPaymentStatus()}
      />

      <Footer id='billing-footer' className='shadow-up bg-white' />
    </CheckoutProvider>
  );
}

export default VoipCheckout;
