import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  value: PropTypes.any,
  blankText: PropTypes.string
};

const defaultProps = {
  value: null,
  blankText: 'Indefinido'
};

function TableCell({ value, blankText }) {
  return (
    <span className={classnames({ 'text-medium-gray': !value })}>
      {value || blankText}
    </span>
  );
}

TableCell.propTypes = propTypes;
TableCell.defaultProps = defaultProps;

export default TableCell;
