import React from 'react';
import * as UnauthorizedError from '@/lib/auth/UnauthorizedError';
import Button from '@/components/Button';
import ExternalLink from '@/components/ExternalLink';
import { useAuthenticationStore } from '@/lib/auth/authenticationStore';
import { legacyLoginUrl } from '@/routes';

function Content() {
  const { unauthorizedError } = useAuthenticationStore();
  if (unauthorizedError.isConcurrentSession) {
    return <UnauthorizedError.ConcurrentSessionError />;
  }

  const loginButtonUrl = legacyLoginUrl();

  return (
    <UnauthorizedError.Container>
      <UnauthorizedError.Illustration />

      <UnauthorizedError.Title>
        Sua sessão expirou!
      </UnauthorizedError.Title>

      <UnauthorizedError.Body>
        Para sua segurança, a sessão foi encerrada.
      </UnauthorizedError.Body>

      <UnauthorizedError.Container direction='horizontal' className='mx-auto'>
        <Button as={ExternalLink} href={loginButtonUrl}>
          Entrar novamente
        </Button>
      </UnauthorizedError.Container>
    </UnauthorizedError.Container>
  );
}

export default Content;
