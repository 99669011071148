import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Icon from '@/components/Icon';
import createAdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import { ADYEN_CLIENT_KEY, ADYEN_CLIENT_ENV } from '@/config';

const propTypes = {
  setEncryptedData: PropTypes.func,
  setPaymentData: PropTypes.func
};

export function CreditCard({ setEncryptedData, setPaymentData }) {
  const cardContainerRef = useRef(null);

  useEffect(() => {
    const loadForm = async () => {
      const configuration = {
        environment: ADYEN_CLIENT_ENV,
        clientKey: ADYEN_CLIENT_KEY,
        locale: 'pt-BR',
        translations: {
          'pt-BR': {
            'creditCard.numberField.placeholder': '0000 0000 0000 0000',
            'creditCard.holderName': 'Nome do titular do cartão',
            'creditCard.holderName.placeholder': 'Digite o nome como escrito no cartão',
            'creditCard.expiryDateField.title': 'Validade Mês/Ano',
            'creditCard.expiryDateField.placeholder': '01/22',
            'creditCard.cvcField.title': 'CVV (Código de segurança)'
          }
        }
      };

      const checkout = await createAdyenCheckout(configuration);

      const objCard = {
        hasHolderName: true,
        holderNameRequired: true,
        styles: {
          base: {
            fontSize: '12px',
            fontFamily: 'Rubik, sans-serif',
            background: '#F0F0F7'
          },
          placeholder: {
            color: '#9393C2'
          }
        },
        brands: ['mc', 'visa', 'elo', 'amex', 'hipercard', 'diners'],
        showBrandsUnderCardNumber: false,
        onChange(data) {
          setPaymentData((prev) => ({
            ...prev,
            credit_card: { ...prev.credit_card, is_valid: data.isValid }
          }));

          setEncryptedData(data.data?.paymentMethod);
        },
        onFieldValid(data) {
          if (data.endDigits) {
            setPaymentData((prev) => ({
              ...prev,
              credit_card: { ...prev.credit_card, end_digits: data.endDigits }
            }));
          }
        },
        onBrand(data) {
          if (data.brand !== 'card') {
            setPaymentData((prev) => ({
              ...prev,
              credit_card: { ...prev.credit_card, brand: data.brand }
            }));
          }
        }
      };

      if (cardContainerRef.current) {
        checkout.create('card', objCard).mount(cardContainerRef.current);
      }
    };

    loadForm();
  }, []);

  return (
    <>
      <div ref={cardContainerRef} id='card-container' />

      <div className='text-dark-gray text-smaller mt-3'>
        <div className='mb-1 d-flex align-items-center'>
          <div className='me-2'>
            <Icon name='checkmark-security-lock' />
          </div>

          <span>Sua transação é 100% segura (Os dados do seu cartão estão protegidos).</span>
        </div>

        <div className='d-flex align-items-center'>
          <div className='me-2'>
            <Icon name='card-pay' />
          </div>

          <span>
            O cadastramento do cartão ficará salvo para transações futuras,
            recorrentes e automáticas.
          </span>
        </div>
      </div>
    </>
  );
}

CreditCard.propTypes = propTypes;
