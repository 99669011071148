import React from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';

import { useBillingProduct, useCreateTrial } from '@/api';
import { toCurrencyString } from '@/number';

import Button from '@/components/Button';
import FeatureList from '@/components/Billing/FeatureList';
import Icon from '@/components/Icon';
import Modal from '@/components/Modal';
import Tooltip from '@/components/Tooltip';

const propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  productCode: PropTypes.string,
  yearly: PropTypes.bool
};

const defaultProps = {
  yearly: false,
  productCode: 'performance_plan'
};

const proPlanInfo = [
  {
    image: 'https://agendor-files.s3.sa-east-1.amazonaws.com/product/plans-features/gerar-proposta.gif',
    text: 'Geração de propostas comerciais',
    description: ''
  },
  {
    image: 'https://agendor-files.s3.sa-east-1.amazonaws.com/product/plans-features/envio-de-emails.png',
    text: 'Envio de e-mails',
    description: ''
  },
  {
    image: 'https://agendor-files.s3.sa-east-1.amazonaws.com/product/plans-features/manage_contacts.png',
    text: 'Múltiplos funis de vendas',
    description: `
      Organize seus contatos e tarefas em único lugar. Acompanhe suas oportunidades de
      uma forma bem visual no funil de vendas.
    `
  },
  {
    image: 'https://agendor-files.s3.sa-east-1.amazonaws.com/product/plans-features/campos-customizados.gif',
    text: 'Campos customizados',
    description: ''
  },
  {
    image: 'https://agendor-files.s3.sa-east-1.amazonaws.com/product/plans-features/dashboard.png',
    text: 'Dashboard de vendas',
    description: `
      Visualize, em tempo real, suas principais métricas de vendas e o
      desempenho da equipe comercial.
    `
  }
];

const performancePlanInfo = [
  {
    image: 'https://agendor-files.s3.sa-east-1.amazonaws.com/product/plans-features/automations.gif',
    text: 'Automações de e-mail, tarefas e negócios',
    description: `
      Automatize tarefas repetitivas no Agendor e reduza o trabalho manual da sua equipe.
    `
  },
  {
    image: 'https://agendor-files.s3.sa-east-1.amazonaws.com/product/plans-features/required_fields.png',
    text: 'Qualificação obrigatória de negócios',
    description: `
      Defina quais campos devem ser preenchidos para avançar seus negócios no funil
      de vendas.
    `
  },
  {
    image: 'https://agendor-files.s3.sa-east-1.amazonaws.com/product/plans-features/telefone-virtual-inteligente.gif',
    text: 'Resumo de ligações com IA',
    description: `
      Automatize o preenchimento do CRM após uma ligação com uso de Inteligência Artificial
      que gera um resumo da conversa
    `
  }
];

const PlanInfo = ({ productCode }) => {
  let planInfoDetails = [...proPlanInfo];

  if (productCode === 'performance_plan') {
    planInfoDetails = [...proPlanInfo, ...performancePlanInfo];
  }

  return (
    planInfoDetails.map((item, index) => (
      <FeatureList.Item
        key={index}
        text={item.text}
        image={item.image}
        description={item.description}
        dashed
        size={'sm'}
        icon={{ name: 'check', className: 'text-dark-gray' }}
      />
    ))
  );
};
function PlanConversionModal({ show, onHide, yearly, productCode }) {
  const alert = useAlert();
  const activateTrial = useCreateTrial();

  const { data: { data } = {}, isSuccess } = useBillingProduct({ code: productCode });

  const planValue = toCurrencyString(yearly ? data?.yearlyValue : data?.value);
  const planDisplayName = data?.name;

  if (!isSuccess) {
    return null;
  }

  const handleTrialActivation = () => {
    activateTrial.mutate(
      { product_code: data?.code },
      {
        onError: (err) => {
          alert.show(
            `Error: ${err.message}`,
            { variant: 'danger', timeout: 5000 }
          );
        },
        onSuccess: () => {
          alert.show(
            'Trial ativado com sucesso',
            { variant: 'success', timeout: 5000 }
          );
          window.location.reload();
        }
      }
    );
  };

  return (
    <Modal show={show} onHide={onHide} bodyClassName='p-8'>
      <h3 className='text-dark-gray'>{planDisplayName}</h3>
      <p className='text-dark-gray'>
        Com todos os recursos necessários para começar a gerenciar suas vendas.
      </p>

      <div className='d-flex text-dark-gray'>
        <h4>{planValue}</h4><span>/mês</span>
      </div>

      <div className='d-flex text-dark-gray align-items-center text-small'>
        Por usuário, pagando mensalmente

        <Tooltip content='Assine o plano anual e ganhe 10% de desconto.'>
          <div className='ms-1'>
            <Icon name='info' size='sm' />
          </div>
        </Tooltip>
      </div>

      <Button className='my-4 w-100' onClick={handleTrialActivation}>
        Testar grátis por 7 dias
      </Button>

      <h3 className='text-dark-gray mb-3'>Tudo do Plano Gratuito, mais:</h3>

      <div className='d-flex flex-column align-items-start'>
        <PlanInfo productCode={productCode}/>
      </div>
    </Modal>
  );
}

PlanConversionModal.propTypes = propTypes;
PlanConversionModal.defaultProps = defaultProps;

export default PlanConversionModal;
