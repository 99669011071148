import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@/components/Modal';
import Stepper from '@/components/Stepper';
import TemplateStep from '@/components/CreateDealModal/TemplateStep';
import DealStep from '@/components/CreateDealModal/DealStep';
import CustomFieldsStep from '@/components/CreateDealModal/CustomFieldsStep';

const propTypes = {
  /** Flag que determina se o modal deve ser exibido ou não. */
  show: PropTypes.bool,
  /**
   * Callback chamado quando o modal quer ser escondido.
   * Controla a prop `show`.
   */
  onHide: PropTypes.func,
  /** Funil em que o negócio será criado. */
  funnel: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  /** ID da etapa em que o negócio será criado. */
  stageId: PropTypes.number,
  /**
   * Entidade 'origem' do negócio que será criado.
   * Caso não seja informada, o modal iniciará com uma busca pela entidade e
   * essa prop será não-controlada.
   */
  entity: PropTypes.shape({
    type: PropTypes.oneOf(['organization', 'person', 'deal']).isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired
  }),
  /** Callback chamado quando a criação do negócio é concluída. */
  onFinish: PropTypes.func,
  /** Callback chamado quando o modal termina de fechar. */
  onExited: PropTypes.func,
  /**
   * Componentes de passos que serão inseridos no início do fluxo de criação
   * de negócio.
   */
  children: PropTypes.node
};

const defaultProps = {
  show: false,
  onFinish: () => {}
};

function CreateDealModal(props) {
  const {
    show, onHide, funnel, stageId, entity, onFinish, onExited, children
  } = props;

  const [template, setTemplate] = useState(null);
  const [customFields, setCustomFields] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);

  const onSubmit = () => setSubmitting(true);
  const afterSubmit = () => setSubmitting(false);

  const handleFinish = (...args) => {
    afterSubmit();
    onFinish(...args);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size='xl'
      backdrop='static'
      bodyClassName='p-8'
      disableClose={isSubmitting}
      restoreFocus={false}
      onExited={onExited}
    >
      <Stepper>
        {children}

        <TemplateStep
          entity={entity}
          onSuccess={setTemplate}
        />

        <CustomFieldsStep onSuccess={setCustomFields} />

        <DealStep
          funnel={funnel}
          stageId={stageId}
          entity={entity}
          template={template}
          customFields={customFields}
          onCancel={onHide}
          onSuccess={handleFinish}
          onSubmit={onSubmit}
          onError={afterSubmit}
        />
      </Stepper>
    </Modal>
  );
}

CreateDealModal.propTypes = propTypes;
CreateDealModal.defaultProps = defaultProps;

export default CreateDealModal;
