import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '@/components/Icon';
import Truncate from '@/components/Truncate';
import { legacyOrganizationUrl, legacyPersonUrl } from '@/routes';
import EntityLink from '@/components/EntityLink';
import { useScreenSize } from '@/hooks';


EntityName.defaultProps = {
  deal: {},
  entity: {},
  className: '',
  isSuccess: false
};

EntityName.propTypes = {
  deal: PropTypes.object,
  entity: PropTypes.object,
  className: PropTypes.string,
  isSuccess: PropTypes.bool
};

function getEntityDataFrom(deal, entity, isSuccess) {
  if (deal?.organization && isSuccess) {
    return {
      icon: 'organization',
      name: entity?.name,
      legacyUrl: legacyOrganizationUrl(deal?.organization?.id)
    };
  }
  if (deal?.person && isSuccess) {
    return {
      icon: 'person',
      name: entity?.name,
      legacyUrl: legacyPersonUrl(deal?.person?.id)
    };
  }
  return { icon: 'link', name: 'Nenhum contato relacionado' };
}

function EntityName({ deal, entity, className, isSuccess }) {
  /*
   * Usamos entity.name ao invés de deal.organization|person.name para
   * evitar um request a mais em getDeals para atualizar apenas o nome da entidade
   */
  const data = getEntityDataFrom(deal, entity, isSuccess);
  const hasEntity = deal?.organization || deal?.person;
  const { isLG } = useScreenSize();
  return (
    <div className={classnames('d-inline-flex', className)}>
      <EntityLink entity={{ type: data.icon, id: entity.id }}>
        <Truncate
          className={classnames(
            { 'max-width-5': !isLG },
            { 'max-width-4': isLG },
            'text-medium fw-bold',
            hasEntity ? 'text-primary' : 'text-dark-gray'
          )}
        >
          <Icon name={data.icon} className='me-2' />
          {data.name}
        </Truncate>
      </EntityLink>
    </div>
  );
}

export default EntityName;
