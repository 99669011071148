import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DotList from '@/components/DotList';
import Icon from '@/components/Icon';
import FlexCollapse from '@/components/FlexCollapse';
import Button from '@/components/Button';
import EmptyFunnelStage from '@/components/EmptyFunnelStage';
import CardList from '@/components/CardList';
import { useFunnelContext } from '@/contexts';
import { toCurrencyString } from '@/number';
import { countWhile } from '@/array';
import { isPast, isToday, parseDate } from '@/date';
import { useInfiniteFunnelStageItems, defaultFunnelStageFrozenParams } from '@/api';
import FunnelStageCard from '@/components/FunnelStageCard';

const propTypes = {
  funnelId: PropTypes.number.isRequired,
  stageId: PropTypes.number.isRequired,
  show: PropTypes.bool,
  open: PropTypes.bool,
  onToggle: PropTypes.func
};

const defaultProps = {
  show: false,
  open: false,
  unfrozenCount: 0,
  onToggle: () => {}
};

function FunnelStageFrozenSection({ funnelId, stageId, show, open, onToggle }) {
  const {
    getColumn, dispatch, isFetchingFrozen: isFetching, params: boardParams, showFrozen
  } = useFunnelContext();

  const {
    initialFrozenItems: initialItems = [], frozenItems: items = [],
    frozenCount: totalCount = 0, frozenAmount: totalAmount = 0
  } = getColumn(`funnel-${stageId}`);
  const currencyTotalAmount = toCurrencyString(totalAmount);

  // Só considera itens iniciais porque acima de 9 será exibido "9+".
  const unfrozenCount = getUnfrozenCount(initialItems);
  const showUnfrozenCount = !open && unfrozenCount > 0;
  const unfrozenCountText = getUnfrozenCountText(unfrozenCount);

  const {
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = useInfiniteFunnelStageItems({
    funnelId,
    stageId,
    params: { ...boardParams, ...defaultFunnelStageFrozenParams },
    config: {
      // Isso é necessário para não fazer requisição, já que os dados foram pré-populados
      staleTime: 1000,
      enabled: Boolean(boardParams) && !isFetching && showFrozen,
      onSuccess: ({ pages }) => {
        const fullItems = pages.flatMap((page) => page.data);

        dispatch({
          type: 'set_items',
          payload: {
            id: `funnel-${stageId}`,
            items: fullItems,
            nested: 'frozen'
          }
        });
      }
    }
  });

  return (
    <div className={classnames(
      'frozen-section',
      {
        'slide-up': show,
        'slide-down': !show,
        'd-flex flex-column flexible-height flex-grow-1': open,
        'd-flex flex-column flexible-height flex-shrink-0': !open
      }
    )}>
      <div className='border-top border-1 border-darker-gray my-2 me-3' />

      <Button
        className={classnames(
          'mb-2',
          'me-3',
          'fw-normal',
          'border-0',
          'shadow-sm',
          {
            'text-white': open,
            'px-3 py-2': !showUnfrozenCount,
            'ps-0 py-0 pe-3': showUnfrozenCount
          }
        )}
        variant={open ? 'light-blue' : 'light-gray'}
        onClick={onToggle}
      >
        {
          showUnfrozenCount
            ? (
              <div className={classnames(
                'd-flex',
                'align-self-stretch',
                'bg-primary',
                'align-items-center',
                'p-2',
                'rounded-1'
              )}>
                <Icon className='text-white' name='unfrozen' />
                <span className='ps-1 text-white'>{unfrozenCountText}</span>
              </div>
            )
            : (
              <Icon
                className={classnames({ 'text-primary': !open })}
                name='frozen'
              />
            )
        }


        <DotList
          truncate
          className='ms-3'
          items={[totalCount, currencyTotalAmount]}
        />

        <Icon
          className={classnames(
            'ms-auto',
            { 'text-dark-gray': !open }
          )}
          name={open ? 'arrow-down' : 'arrow-up'}
        />
      </Button>

      <FlexCollapse open={open}>
        {items.length === 0 && !isFetching
          ? (
            <EmptyFunnelStage>
              Não há negócios congelados nessa etapa
            </EmptyFunnelStage>
          )
          : (
            <CardList
              items={items}
              renderItem={renderCard}
              fetchMore={fetchNextPage}
              canFetchMore={hasNextPage}
              isFetchingMore={Boolean(isFetchingNextPage)}
              className='pe-2'
            />
          )
        }
      </FlexCollapse>
    </div>
  );
}

function getUnfrozenCount(items) {
  return countWhile(
    items,
    (item) => {
      const { freezing: { unfreezeAt } = {} } = item.status;
      const unfreezeDate = parseDate(unfreezeAt);

      return isPast(unfreezeDate) || isToday(unfreezeDate);
    }
  );
}

function getUnfrozenCountText(count) {
  const threshold = 9;

  if (count > threshold) {
    return `${threshold}+`;
  }

  return `${count}`.padStart(2, '0');
}

function renderCard(card) {
  // Usamos o Fragment aqui pois estava dando warning de `unique key`
  return (
    <React.Fragment key={card.id} >
      <FunnelStageCard card={card} className='mb-1' />
    </React.Fragment>
  );
}

FunnelStageFrozenSection.propTypes = propTypes;
FunnelStageFrozenSection.defaultProps = defaultProps;

export default FunnelStageFrozenSection;
