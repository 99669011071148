import React from 'react';

import { useBillingProducts } from '@/api';
import { useDebounce } from '@/hooks';
import { toCurrencyString } from '@/number';

import FormField from '@/components/FormField';
import { FetchableSelect, IntegerInput } from '@/components/Inputs';
import Tooltip from '@/components/Tooltip';
import { useUpdateSubscriptionContext } from '@/contexts';

const defaultDebounceTime = 600;

function transformer(fetchedData) {
  const PLANS = fetchedData.data.filter((plan) => plan.paidPlan === true);

  return PLANS.map((plan) => ({
    label: plan.planName,
    value: plan.code,
    rightAdornment: (
      <Tooltip content={`${toCurrencyString(plan.value)}/mês por usuário`}>
        <div className='text-small fw-normal'>
          {`${toCurrencyString(plan.value)}/mês...`}
        </div>
      </Tooltip>
    )
  }));
}

function UpdatePlanFields() {
  const {
    setSelectedPlan,
    setSelectedMaxUsers
  } = useUpdateSubscriptionContext();

  const debounceHandler = useDebounce((fn) => fn(), defaultDebounceTime);
  const onChangeSelectedPlan = (value) => setSelectedPlan(value);
  const onChangeSelectedMaxUsers = (value) => debounceHandler(
    () => setSelectedMaxUsers(Number(value))
  );

  return (
    <>
      <span className='fw-bold'>NOVO PLANO</span>

      <FormField
        as={FetchableSelect}
        transformer={transformer}
        name='plan_select'
        onChange={onChangeSelectedPlan}
        query={useBillingProducts}
        queryArgs={{ params: { from_plan: true } }}
        optionsClassName='fw-bold'
        className='my-3'
      />
      <FormField
        as={IntegerInput}
        name='users_count'
        className='mb-1'
        onChange={onChangeSelectedMaxUsers}
        innerClassName='text-center'
        type='number'
        placeholder='1'
        min='1'
      />
    </>
  );
}

export default UpdatePlanFields;
