import React, { useMemo } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import CustomField from '@/components/CustomFields/CustomField';
import FormField from '@/components/FormField';
import {
  textCustomFieldValidations,
  decimalCustomFieldValidations,
  integerCustomFieldValidations
} from '@/components/CustomFields/Types';

const propTypes = {
  section: PropTypes.string.isRequired,
  colProps: PropTypes.object,
  perRow: PropTypes.bool,
  useRowContainer: PropTypes.bool,
  className: PropTypes.string
};

const defaultProps = {
  fields: [],
  className: '',
  useRowContainer: true,
  perRow: false
};

export function getInitialValues(customFields) {
  return Object.entries(customFields)
    .reduce((acc, [key, value]) => ({
      ...acc,
      [key]: Array.isArray(value) ? value : value?.toString() ?? ''
    }), {});
}

export const customFieldsSchema = Yup
  .object()
  .when('$customFields', ([customFields], fieldSchema) => {
    const validations = {
      text: textCustomFieldValidations,
      integer: integerCustomFieldValidations,
      decimal: decimalCustomFieldValidations
    };
    const fields = customFields.reduce((acc, field) => ({
      ...acc,
      [field.identifier]: validations[field.type]
    }), {});
    const schema = Yup.object().shape(fields);

    return fieldSchema.concat(schema);
  });

const COL_WIDTHS = { fullRow: 12, halfRow: 6 };

function FieldsContainer({ children, useRowContainer, className }) {
  if (!useRowContainer) {
    return (<>{children}</>);
  }
  return (
    <Row className={className}>
      {children}
    </Row>
  );
}

export function CustomFieldsSection({ section, fields, perRow, className, useRowContainer }) {
  const filteredFields = useMemo(
    () => fields.filter((field) => field.section === section),
    [section, fields]
  );

  const colWidth = COL_WIDTHS[perRow ? 'fullRow' : 'halfRow'];

  if (filteredFields.length === 0) {
    return null;
  }

  return (
    <FieldsContainer
      useRowContainer={useRowContainer}
      className={className}
    >
      {
        filteredFields.map((field) => (
          <Col sm={colWidth} key={field.identifier}>
            <FormField
              as={CustomField}
              field={field}
              name={`custom_fields.${field.identifier}`}
            />
          </Col>
        ))
      }
    </FieldsContainer>
  );
}

CustomFieldsSection.propTypes = propTypes;
CustomFieldsSection.defaultProps = defaultProps;
