import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import { settingsFunnelStagesRequiredFieldsPath } from '@/routes';
import { DEAL_STATUSES } from '@/utils';

const targets = {
  won: { type: 'status', id: DEAL_STATUSES.WON, name: 'Ganho' },
  lost: { type: 'status', id: DEAL_STATUSES.LOST, name: 'Perdido' }
};

const propTypes = {
  funnelId: PropTypes.number.isRequired
};


/**
 * Para seguir o mesmo padrão de tabela conforme a stages list foi necessário
 * utilizar divs em vez de Table para obter o mesmo comportamento.
 */
function FunnelStatusList({ funnelId }) {
  return (
    <div>
      <div className={classnames(
        'd-flex',
        'align-items-center',
        'border-1',
        'border-bottom',
        'border-darker-gray',
        'text-uppercase',
        'fw-bold'
      )}>
        <div className='width-3 p-3 flex-shrink-0'></div>
        <div className='width-5 p-3'>Nome</div>
        <div className='width-5 p-3'></div>
        <div className='width-5 p-3'></div>
      </div>

      <div className='text-nowrap'>
        <div className='d-flex align-items-center border-bottom border-1 item'>
          <div className='width-3 p-3'>
            <Icon name='won-deal' size='lg' className='text-flat-green'/>
          </div>
          <div className='width-5 p-3'>Status Ganho</div>
          <div className='width-5 p-3'>
            <Button
              variant='transparent-light'
              href={settingsFunnelStagesRequiredFieldsPath(funnelId)}
              state={{ target: targets.won }}
            >
              <Icon name='listing' className='me-1'/>
              <span>Editar campos do status</span>
            </Button>
          </div>
        </div>

        <div className='d-flex align-items-center border-bottom border-1 item'>
          <div className='width-3 p-3'>
            <Icon name='lost-deal' size='lg' className='text-danger'/>
          </div>
          <div className='width-5 p-3'>Status Perdido</div>
          <div className='width-5 p-3'>
            <Button
              variant='transparent-light'
              href={settingsFunnelStagesRequiredFieldsPath(funnelId)}
              state={{ target: targets.lost }}
            >
              <Icon name='listing' className='me-1'/>
              <span>Editar campos do status</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

FunnelStatusList.propTypes = propTypes;

export default FunnelStatusList;
