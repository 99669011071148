import React, { createContext, useContext } from 'react';

const FunnelContext = createContext({
  columns: {}
});

export function useFunnelContext() {
  const context = useContext(FunnelContext);

  if (context === undefined) {
    throw new Error('useFunnel must be used within FunnelProvider');
  }

  return context;
}

export function FunnelProvider({ children, value }) {
  return (
    <FunnelContext.Provider value={value}>
      {children}
    </FunnelContext.Provider>
  );
}
