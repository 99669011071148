import React from 'react';
import PropTypes from 'prop-types';
import Pills from '@/components/Pills';
import NavLink from '@/components/NavLink';
import Tooltip from '@/components/Tooltip';

const propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  title: PropTypes.node
};

const defaultProps = {
  href: ''
};

function Item({ children, href, title }) {
  return (
    <Tooltip placement='right' content={title}>
      <Pills.Item className='mb-2 text-small ms-1'>
        <NavLink
          href={href}
          className='p-1 border border-primary border-opacity-10'
        >
          <div className='d-flex align-items-center justify-content-center height-2 width-2'>
            {children}
          </div>
        </NavLink>
      </Pills.Item>
    </Tooltip>
  );
}

Item.propTypes = propTypes;
Item.defaultProps = defaultProps;

export default Item;
