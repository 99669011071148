import { useQuery, useMutation, useQueryClient } from 'react-query';
import { get, patch, post, _delete } from '@/lib/fetch';
import { accessoryCallCache } from '@/utils';

export const proposalModelKeys = {
  all: ['proposal-models'],
  lists: () => [...proposalModelKeys.all, 'list'],
  list: (params) => [...proposalModelKeys.lists(), params],
  details: () => [...proposalModelKeys.all, 'detail'],
  detail: (id) => [...proposalModelKeys.details(), id]
};

export async function getProposalModels(params = {}) {
  const { data } = await get('/proposal_models', params);

  return data;
}

export async function getProposalModel(id, params = {}) {
  const { data } = await get(`/proposal_models/${id}`, params);

  return data;
}

export async function createProposalModel(params = {}) {
  const { data } = await post('/proposal_models', params);

  return data;
}

export async function updateProposalModel({ id, params = {} }) {
  const { data } = await patch(`/proposal_models/${id}`, params);

  return data;
}

export async function deleteProposalModel({ id }) {
  const { data } = await _delete(`/proposal_models/${id}`);

  return data;
}

export async function duplicateProposalModel({ id }) {
  const { data } = await post(`/proposal_models/${id}/duplicate`);

  return data;
}

export function useProposalModels({ params = {}, config = {} } = {}) {
  return useQuery({
    ...accessoryCallCache,
    ...config,
    queryKey: proposalModelKeys.list(params),
    queryFn: () => getProposalModels(params)
  });
}

export function useProposalModel({ id, params = {}, config = {} } = {}) {
  return useQuery({
    ...accessoryCallCache,
    ...config,
    queryKey: proposalModelKeys.detail(id),
    queryFn: () => getProposalModel(id, params)
  });
}

export function useCreateProposalModel({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await queryClient.invalidateQueries(proposalModelKeys.all);
  };

  return useMutation(
    createProposalModel,
    { onSuccess, ...config }
  );
}

export function useUpdateProposalModel({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await queryClient.invalidateQueries(proposalModelKeys.all);
  };

  return useMutation(
    updateProposalModel,
    { onSuccess, ...config }
  );
}

export function useDeleteProposalModel({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await queryClient.invalidateQueries(proposalModelKeys.all);
  };

  return useMutation(
    deleteProposalModel,
    { onSuccess, ...config }
  );
}

export function useDuplicateProposalModel({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await queryClient.invalidateQueries(proposalModelKeys.all);
  };

  return useMutation(
    duplicateProposalModel,
    { onSuccess, ...config }
  );
}
