import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import DetailsCard from '@/components/EntityModal/DetailsCard';
import DetailsContactEntity from '@/components/EntityModal/DetailsContactEntity';
import Skeleton from '@/components/Skeleton';
import Authorization from '@/components/Authorization';

DetailsContact.defaultProps = {
  loading: false,
  entity: null,
  entityType: '',
  entityTypeModal: '',
  entityEditPath: '',
  entityCustomFields: [],
  onEditEntity: () => {},
  onCreatePerson: () => {},
  dropboxEmail: null,
  showTitle: true
};

DetailsContact.propTypes = {
  loading: PropTypes.bool,
  entity: PropTypes.object,
  entityType: PropTypes.string,
  entityTypeModal: PropTypes.string,
  entityEditPath: PropTypes.string,
  entityCustomFields: PropTypes.array,
  canEditPolicy: PropTypes.func,
  onEditEntity: PropTypes.func,
  onCreatePerson: PropTypes.func,
  dropboxEmail: PropTypes.string,
  showTitle: PropTypes.bool
};

function DetailsContact({
  entity,
  entityType,
  entityTypeModal,
  entityEditPath,
  entityCustomFields,
  canEditPolicy,
  loading,
  onEditEntity,
  onCreatePerson,
  dropboxEmail,
  onEditPerson,
  showTitle,
  deal,
  voip
}) {
  const LABELS = {
    deal: {
      emptyText: 'Nenhuma empresa ou pessoa ligada a este negócio.',
      textButton: 'Adicionar empresa/pessoa'
    },
    organization: {
      emptyText: 'Nenhuma pessoa ligada a esta empresa.',
      textButton: 'Adicionar pessoa'
    }
  };

  if (loading) {
    return (
      <Skeleton width='100%' height={200} className='mt-3' />
    );
  }

  if (!isEmpty(entity)) {
    return (
      <DetailsContactEntity
        loading={loading}
        onEditEntity={onEditEntity}
        onEditPerson={onEditPerson}
        entity={entity}
        entityType={entityType}
        entityTypeModal={entityTypeModal}
        entityCustomFields={entityCustomFields}
        onCreatePerson={onCreatePerson}
        canEditPolicy={canEditPolicy}
        dropboxEmail={dropboxEmail}
        showTitle={showTitle}
        deal={deal}
        voip={voip}
      />
    );
  }

  if (!['organization', 'deal'].includes(entityTypeModal)) {
    return null;
  }

  const { emptyText, textButton } = LABELS[entityTypeModal];

  return (
    <DetailsCard title='Dados do contato' className='mt-3'>
      <div className='text-dark-gray'>{emptyText}</div>
      <Authorization policy={canEditPolicy}>
        <Button href={entityEditPath} variant='lighter-gray' className='text-primary mt-3'>
          <Icon name='plus' size='sm' className='me-3'/>
          {textButton}
        </Button>
      </Authorization>
    </DetailsCard>
  );
}

export default DetailsContact;
