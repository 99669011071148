import { baseLegacyUrl } from '@/routes';
import { getUrlWithQueryString } from '@/utils';

export const DEALS_VIEW_PAGES = {
  list: '',
  funnel: 'funil.php',
  map: 'mapa.php'
};

export function legacyDealsUrl(subPath = '') {
  return `${baseLegacyUrl()}/negocios/${subPath}`;
}

export function legacyDealsMapUrl() {
  return `${baseLegacyUrl()}/negocios/mapa.php`;
}

export function legacyDealUrl(id, forceLegacy = false) {
  return `${baseLegacyUrl()}/negocios/historico.php?id=${id}${forceLegacy ? '&legado=1' : ''}`;
}

export function dealEditPath(id) {
  return `${dealsPath()}/${id}/edit`;
}

export function dealsPath(params) {
  return getUrlWithQueryString('/deals', params);
}

export function dealPath(id, base = '') {
  return `${base}?dealId=${id}`;
}

export function legacyDealsImportUrl() {
  return `${baseLegacyUrl()}/importar/?env=negocios`;
}
