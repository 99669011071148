import { useQuery, useMutation, useQueryClient } from 'react-query';
import { get, post, patch } from '@/lib/fetch';
import { activitiesKeys } from '@/api';

export const audioSummariesKeys = {
  details: () => ['audio-summary'],
  detail: (audioSummaryId) => [...audioSummariesKeys.details(), audioSummaryId]
};
export async function getAudioSummary(audioSummaryId) {
  const { data } = await get(`/audio_summaries/${audioSummaryId}`);

  return data;
}

export async function updateAudioSummary({ audioSummaryId, params = {} }) {
  const { data } = await patch(`/audio_summaries/${audioSummaryId}`, params);

  return data;
}

export async function createAudioSummary(params = {}) {
  const { data } = await post('/audio_summaries', params);

  return data;
}

export function useAudioSummary({ audioSummaryId, config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: audioSummariesKeys.detail(audioSummaryId),
    queryFn: () => getAudioSummary(audioSummaryId)
  });
}

export function useUpdateAudioSummary({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async ({ data }) => {
    await queryClient.invalidateQueries(audioSummariesKeys.detail(data?.id));
  };

  return useMutation(
    updateAudioSummary,
    {
      onSuccess,
      ...config
    }
  );
}
export function useCreateAudioSummary({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await queryClient.invalidateQueries(activitiesKeys.infiniteLists);
  };

  return useMutation(
    createAudioSummary,
    {
      onSuccess,
      ...config
    }
  );
}
