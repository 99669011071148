import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node
};

function PageSubtitle({ children }) {
  return (
    <p className='subtitle text-dark-gray max-width-7'>
      {children}
    </p>
  );
}

PageSubtitle.propTypes = propTypes;
export default PageSubtitle;
