import { useQuery } from 'react-query';
import { get } from '@/lib/fetch';

export const currentPlanKeys = {
  currentPlan: ['current-plan']
};

export async function getCurrentPlan(params = {}) {
  const { data } = await get('/current_plan', params);

  return data;
}

export function useCurrentPlan({ params = {}, config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: currentPlanKeys.currentPlan,
    queryFn: () => getCurrentPlan(params)
  });
}
