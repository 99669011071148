import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import Modal from '@/components/Modal';
import relax from 'images/billing/relax.svg';
import { funnelsPath } from '@/routes';

const propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  redirectPath: PropTypes.string
};

const defaultProps = {
  redirectPath: funnelsPath()
};

const initialTimeToRedirect = 10;
const second = 1000;

function PaymentSuccessModal({ show, title, message, redirectPath }) {
  const navigate = useNavigate();
  const [timeToRedirect, setTimeToRedirect] = useState(initialTimeToRedirect);

  const handleClick = () => navigate(redirectPath);

  useEffect(() => {
    let intervalId;
    if (show) {
      intervalId = setInterval(() => setTimeToRedirect((prev) => prev - 1), second);
    }

    return () => clearInterval(intervalId);
  }, [show]);

  useEffect(() => {
    if (timeToRedirect === 0) {
      handleClick();
    }
  }, [timeToRedirect]);

  return (
    <Modal
      size='md'
      show={show}
      bodyClassName='d-flex flex-column p-8 align-items-center text-center'
      backdrop='static'
      onHide={handleClick}
    >
      <img src={relax} className='mb-2 width-5 height-5' />
      <h2>{title}</h2>
      <span className='mt-2 mb-5 text-dark-gray'>{message}</span>

      <Button
        variant='lighter-blue'
        className='mb-2 rounded text-primary text-small'
        onClick={handleClick}
      >
        Comece a usar agora
      </Button>

      <span className='text-small text-dark-gray'>
        Direcionando em {timeToRedirect} segundos...
      </span>
    </Modal>
  );
}

PaymentSuccessModal.propTypes = propTypes;
PaymentSuccessModal.defaultProps = defaultProps;

export default PaymentSuccessModal;
