import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as Yup from 'yup';
import FormField from '@/components/FormField';
import Form from '@/components/Form';
import { TextInput } from '@/components/Inputs';
import Button from '@/components/Button';
import LoadingButton from '@/components/LoadingButton';
import Icon from '@/components/Icon';
import { toDigitsString } from '@/number';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  nextOrder: PropTypes.number.isRequired,
  className: PropTypes.string
};

const defaultProps = {
  className: ''
};

/* eslint-disable no-magic-numbers */
const schema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(45, 'Nome da etapa deve ter no máximo 45 caracteres.')
    .required('Nome da etapa é obrigatório.')
});
/* eslint-enable no-magic-numbers */

const defaultValues = { name: '' };

function CreateStageForm({ className, nextOrder, onSubmit, onCancel }) {
  return (
    <Form
      className={classnames('d-flex', className)}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      name='create-stage-form'
      validationSchema={schema}
    >
      {({ handleBlur, isSubmitting }) => (
        <div className={classnames(
          'd-flex',
          'align-items-start',
          'bg-light',
          'rounded'
        )}>
          <div className='width-3 px-3 py-2 ms-2 text-dark-gray'>
            <div className={classnames(
              'py-2',
              'align-self-center',
              'd-flex',
              'align-items-center',
              'justify-content-between'
            )}>
              <span className='invisible'>
                <Icon name='drag' withStroke />
              </span>

              <div className='ms-auto'>
                {toDigitsString(nextOrder, 2)}
              </div>
            </div>
          </div>
          <div className='width-6 px-3 py-2'>
            <FormField
              as={TextInput}
              name='name'
              className='mb-0'
              placeholder='Nome da etapa'
              autoComplete='off'
              variant='white'
              onBlur={handleBlur}
              autoFocus
            />
          </div>
          <div className='px-3 py-2'>
            <LoadingButton
              type='submit'
              className='px-4 border-0'
              isLoading={isSubmitting}
            />
            <Button
              variant='link'
              className='px-4 border-0'
              onClick={onCancel}
            >
              Cancelar
            </Button>
          </div>
        </div>
      )}
    </Form>
  );
}

CreateStageForm.propTypes = propTypes;
CreateStageForm.defaultProps = defaultProps;

export default CreateStageForm;
