import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { CurrencyInput } from '@/components/Inputs';
import Tooltip from '@/components/Tooltip';

function ValueInput({ calculatedValue, ...props }) {
  const [readOnly, setReadOnly] = useState(false);
  const { getFieldMeta } = useFormikContext();

  useEffect(() => {
    const { touched, value: products } = getFieldMeta('entity_products');

    const hasQuantity = products?.some((product) => Number(product.quantity) > 0);

    if (!hasQuantity) {
      setReadOnly(false);
    } else {
      setReadOnly(true);

      if (touched) {
        props.onChange?.(calculatedValue);
      }
    }
  }, [calculatedValue]);

  return (
    <Tooltip
      hide={!readOnly}
      trigger={['focus']}
      placement='bottom'
      content={
        calculatedValue !== props.value
          ? `Algum produto desse negócio foi excluído do Agendor.
            Esse campo reflete o valor dos produtos e serviços antes da exclusão.`
          : `Este campo reflete o valor dos produtos e serviços.
            O valor total precisa ser igual a zero para editá-lo.`
      }
    >
      <div>
        <CurrencyInput
          label='Valor total'
          autoComplete='off'
          placeholder='R$ 0,00'
          variant='light'
          readOnly={readOnly}
          { ...props }
        />
      </div>
    </Tooltip>
  );
}

export default ValueInput;
