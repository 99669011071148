import React from 'react';
import PropTypes from 'prop-types';
import FetchableSelect from '@/components/Inputs/FetchableSelect';
import Avatar from '@/components/Avatar';
import { usePrivacyCandidates } from '@/api';

const DEFAULT_TOOLTIP = 'Este usuário já possui acesso e por isso não pode ser selecionado';

const propTypes = {
  ownerUserId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func
};

const defaultProps = {
  ownerUserId: null,
  name: 'privacy.additional_users_ids',
  label: 'Acessos adicionais',
  placeholder: 'Selecionar...',
  defaultValue: null,
  onChange: () => {}
};

function transformer(fetchedData) {
  return (
    fetchedData.data.reduce((result, item) => {
      const { user, default: isDefault } = item;
      const groupIndex = isDefault ? 1 : 0;
      const option = {
        label: user.name,
        value: user.id,
        isDisabled: isDefault,
        tooltip: isDefault ? DEFAULT_TOOLTIP : null,
        leftAdornment: <Avatar name={user.name} url={user.avatarUrl} tooltip={false} />
      };

      result[groupIndex].options.push(option);

      return result;
    }, [{ label: 'Conceder Acesso', options: [] }, { label: 'Possuem Acesso', options: [] }])
  );
}

function PrivacyCandidateSelect({ ownerUserId, ...props }) {
  const params = ownerUserId ? { owner_user_id: ownerUserId } : {};

  return (
    <FetchableSelect
      { ...props }
      transformer={transformer}
      query={usePrivacyCandidates}
      queryArgs={{ params }}
      multiple
      autoAdjustValues
      isSearchable
    />
  );
}

PrivacyCandidateSelect.propTypes = propTypes;
PrivacyCandidateSelect.defaultProps = defaultProps;

export default PrivacyCandidateSelect;
