import React, { useEffect, useRef } from 'react';
import TextInput from '@/components/Inputs/TextInput';
import Inputmask from 'inputmask';

function PercentualInput(props) {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      const mask = new Inputmask({
        alias: 'decimal',
        digits: 2,
        rightAlign: false,
        groupSeparator: '.',
        autoGroup: true,
        max: 100,
        min: 0
      });
      mask.mask(ref.current);
    }
  }, []);

  return (
    <TextInput
      ref={ref}
      { ...props }
    />
  );
}

export default PercentualInput;
