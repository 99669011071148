import { useMutation } from 'react-query';
import { post } from '@/lib/fetch';

export async function createImportMappings(params = {}) {
  const { data } = await post('/imports/mappings', params);

  return data;
}

export function useCreateImportMappings({ config = {} } = {}) {
  return useMutation(
    createImportMappings,
    { ...config }
  );
}
