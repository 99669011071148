import React, { useMemo, useState } from 'react';
import { isWithinInterval } from 'date-fns';

import { parseDate, parseWithTimezoneOffset, toDateString } from '@/date';
import { useAuth } from '@/lib/auth';
import { useUpgradeToPerformance } from '@/api';
import { useQueryParams, useUpgradeStatus } from '@/hooks';

import Button from '@/components/Button';
import PerformanceUpgradeDeclinedModal from '@/components/Billing/PerformanceUpgradeDeclinedModal';
import PerformanceUpgradeAcceptedModal from '@/components/Billing/PerformanceUpgradeAcceptedModal';

import celebration from 'images/billing/celebration.svg';
import { useAlert } from 'react-alert';

const performanceWarningKey = 'header-performance-warning';
const EXPIRATION_START_DATE = new Date('2024-09-01T03:00:00.000Z');
const EXPIRATION_END_DATE = new Date('2024-10-01T02:59:59.000Z');

export function useHeaderPerformance() {
  const { user } = useAuth();
  const isAdmin = user.admin;
  const { legacyPro, performanceFeatures, expiresAt } = user.account;

  const isInExpirationPeriod = isWithinInterval(
    parseDate(expiresAt),
    { start: EXPIRATION_START_DATE, end: EXPIRATION_END_DATE }
  );

  const { upgrade_to_performance: queryUpgradeModal } = useQueryParams();

  const {
    upgradeStatus
  } = useUpgradeStatus(performanceWarningKey);

  const meetsConditions = legacyPro && isInExpirationPeriod &&
  ((performanceFeatures && !(upgradeStatus.declined && upgradeStatus.user === user.id)) ||
        queryUpgradeModal
  );

  return isAdmin && meetsConditions;
}

function HeaderPerformanceWarning() {
  const { user } = useAuth();
  const alert = useAlert();
  const { upgrade_to_performance: queryUpgradeModal } = useQueryParams();

  const {
    handleUpgradeStatusChange,
    upgradeStatus
  } = useUpgradeStatus(performanceWarningKey);

  const [showAcceptUpgradeModal, setShowAcceptUpgradeModal] = useState(queryUpgradeModal || false);
  const [showDeclineUpgradeModal, setShowDeclineUpgradeModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const upgradeSubscriptionMutation = useUpgradeToPerformance();

  const { legacyPro, performanceFeatures, expiresAt } = user.account;

  const isInExpirationPeriod = isWithinInterval(
    parseDate(expiresAt),
    { start: EXPIRATION_START_DATE, end: EXPIRATION_END_DATE }
  );

  const showUpgradeWarning = useMemo(
    () => legacyPro &&
      isInExpirationPeriod &&
      ((performanceFeatures && !(upgradeStatus.declined && upgradeStatus.user === user.id)) ||
        queryUpgradeModal
      ),
    [user, upgradeStatus]
  );

  const handleUpgradeSuccess = () => {
    alert.show('Plano alterado com sucesso!', { variant: 'success' });
    handleUpgradeStatusChange();
    setIsLoading(false);
  };

  const handleUpgradeError = () => {
    alert.show('Não foi possível alterar seu plano.', { variant: 'danger' });
    setIsLoading(false);
  };

  const handleConfirmUpgrade = () => {
    setIsLoading(true);
    upgradeSubscriptionMutation.mutate({}, {
      onSuccess: handleUpgradeSuccess,
      onError: handleUpgradeError
    });
  };

  if (!showUpgradeWarning) {
    return null;
  }

  const formattedExpirationDate = toDateString(parseWithTimezoneOffset(expiresAt));

  return (
    <div className='d-flex ps-0 pb-0 pe-8 bg-light'>
      <img className='h-100 max-width-5' src={celebration} />
      <div className='p-7'>
        <div className='d-flex'>
          <div className='px-4'>
            <h4 className='mb-1 text-medium fw-bold'>Oba, o plano Performance chegou!</h4>
            <div className='text-dark-gray'>
              <div className='mb-2'>
                Algumas funcionalidades que você usa hoje serão exclusivas do Plano
                Performance: <span className='fw-bold'>Automações + Modelos de Propostas
                Ilimitados + Telefone Virtual Inteligente com ChatGPT + Qualificação
                Obrigatória de Negócios.</span>
              </div>
              <div>
                Aproveite até a sua próxima renovação no dia <span className='fw-bold'>
                  {formattedExpirationDate}</span> para fazer o upgrade e manter os recursos
                que já são essenciais para seu sucesso!
              </div>
            </div>
          </div>
          <div className='d-flex
            flex-column
            align-items-end
            justify-content-center ms-4
            text-nowrap'
          >
            <Button
              className='w-100 text-small py-1'
              onClick={() => setShowAcceptUpgradeModal(true)}
              size='sm'
            >
              Quero manter as funcionalidades
            </Button>
            <Button
              onClick={() => setShowDeclineUpgradeModal(true)}
              variant='white'
              className='mt-2 w-100 text-darker-gray text-small py-1'
              size='sm'
            >
              Perder essa oportunidade
            </Button>
          </div>
        </div>
      </div>
      <PerformanceUpgradeAcceptedModal
        show={showAcceptUpgradeModal}
        onHide={() => setShowAcceptUpgradeModal(false)}
        onConfirm={handleConfirmUpgrade}
        isLoading={isLoading}
      />
      <PerformanceUpgradeDeclinedModal
        show={showDeclineUpgradeModal}
        onHide={() => setShowDeclineUpgradeModal(false)}
        expirationDate={formattedExpirationDate}
        onDecline={() => handleUpgradeStatusChange(true)}
        onConfirm={handleConfirmUpgrade}
        isLoading={isLoading}
      />
    </div>
  );
}

export default HeaderPerformanceWarning;
