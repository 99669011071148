import qs from 'qs';
import { getCookie } from '@/browser';

export function get(path, params = {}, options = {}) {
  const url = getUrl(path);

  const query = qs.stringify(params, {
    skipNulls: true,
    arrayFormat: 'brackets'
  });
  url.search = query;

  return execute({
    url,
    method: 'GET',
    /*
     * Isso faz com que o Chrome revalide o request ao usar o botão de voltar.
     * ref: https://developer.mozilla.org/en-US/docs/Web/API/Request/cache
     */
    cache: 'no-cache',
    ...options
  });
}

export function post(path, data, options = {}) {
  const url = getUrl(path);

  return execute({ url, data, method: 'POST', ...options });
}

export function patch(path, data, options = {}) {
  const url = getUrl(path);

  return execute({ url, data, method: 'PATCH', ...options });
}

export function _delete(path, data, options = {}) {
  const url = getUrl(path);

  return execute({ url, data, method: 'DELETE', ...options });
}

export function getUrl(path) {
  const baseUrl = process.env.BASE_URL || window.location;
  const prefix = '/api';

  return new URL(`${prefix}${path}`, baseUrl);
}

async function execute({ url, data = {}, method, ...options }) {
  const fetchOptions = {
    method,
    headers: getHeaders(),
    credentials: 'include',
    ...options
  };

  if (method !== 'GET') {
    fetchOptions.body = JSON.stringify(data);
  }

  const response = await fetch(url.toString(), fetchOptions);
  const result = await buildFetchResult(response);

  if (!result.ok) {
    const error = new Error(`HTTP status code: ${result.status}`);
    error.status = result.status;
    error.data = result.data;
    error.isFetchError = true;

    throw error;
  }

  return result;
}

function getHeaders() {
  return {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    ...getCsrfHeader()
  };
}

export function getCsrfHeader() {
  return { 'X-CSRF-Token': getCsrfToken() };
}

function getCsrfToken() {
  return getCookie('csrf_token');
}

async function buildFetchResult(result) {
  let body;

  try {
    body = await result.json();
  } catch (err) {
    console.error(err);
  }

  return { ok: result.ok, status: result.status, data: body };
}
