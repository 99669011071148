import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { Select } from '@/components/Inputs';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import { VOIP_DDD } from '@/utils';
import { useCreateCustomNumber } from '@/api';
import LoadingButton from '@/components/LoadingButton';
import Tooltip from '@/components/Tooltip';

const tooltipContent = 'Escolha um DDD antes de prosseguir';
function SelectDddStep({ onHide, zenviaId, onSuccess, onError }) {
  const mutation = useCreateCustomNumber();
  const [selected, setSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const formatOptions = (options) => options.map((item) => ({
    value: item.ddd,
    label: `${item.ddd} - ${item.full} (${item.acronym})`
  }));

  const formattedOptions = formatOptions(VOIP_DDD);

  const onSubmit = () => {
    setLoading(true);
    mutation.mutate(
      { zenvia_id: zenviaId, ddd: selected },
      {
        onSuccess: ({ data }) => {
          setLoading(false);
          onSuccess(data);
        },
        onError: () => {
          setLoading(false);
          onError();
        }
      }
    );
  };

  return (
    <div>
      <div className='h3 fw-bold mt-6'>Escolha um DDD para seu número</div>
      <div className='text-dark-gray mt-4'>
        Enviaremos esse dado para a Zenvia e eles entrarão em contato
        diretamente com você.
      </div>
      <div className='h6 fw-bold mt-4'>
        DDD
        <Select
          options={formattedOptions}
          placeholder='Selecione'
          className='mt-2'
          name='ddd'
          onChange={(value) => setSelected(value)}
        />
      </div>
      <Card className='p-4 bg-light border-0 mt-4'>
        <div>
          <Icon name='info' className='text-dark-gray' />
          <span className='text-dark-gray ms-2'>
            Após a solicitação, a Zenvia pode levar de 1 a 5 dias para deixar o
            número de telefone disponível.
          </span>
        </div>
      </Card>
      <div className='d-flex justify-content-end mt-4'>
        <Button
          variant='outline-dark-gray'
          className='me-2'
          onClick={onHide}
          disabled={loading}
        >
          Cancelar
        </Button>
        <Tooltip
          content={tooltipContent}
          placement='top'
          hide={Boolean(selected)}
        >
          <LoadingButton
            variant='primary'
            className='ms-2'
            disabled={!selected}
            onClick={onSubmit}
            isLoading={loading}
            loadingText='Enviando...'
          >
            Confirmar e enviar meus dados
          </LoadingButton>
        </Tooltip>
      </div>
    </div>
  );
}

export default SelectDddStep;
