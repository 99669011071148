import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node
};

function NavTitle({ children }) {
  return (
    <h5 className='text-dark-gray mt-1 text-small'>
      {children}
    </h5>
  );
}

NavTitle.propTypes = propTypes;

export default NavTitle;
