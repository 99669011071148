import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { settingsVoipPanelPath, helpUrl } from '@/routes';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Popover from '@/components/Popover';
import Tooltip from '@/components/Tooltip';
import ExternalLink from '@/components/ExternalLink';
import EmptyContact from '@/components/EntityModal/Deal/EmptyContact';
import DetailsVoipContactList from '@/components/EntityModal/Deal/DetailsVoipContactList';
import VoipEnabled from '@/components/EntityModal/Deal/VoipEnabled';

DetailsVoip.defaultProps = {
  deal: {},
  voip: {},
  entity: {},
  entityType: '',
  onRefreshModal: () => {}
};

DetailsVoip.propTypes = {
  deal: PropTypes.object,
  voip: PropTypes.object,
  entity: PropTypes.object,
  entityType: PropTypes.string,
  onRefreshModal: PropTypes.func
};

export const VoipButton = ({ disabled = false, showText, ...props }) => (
  <div id='deal-voip-button' {...props}>
    <Button
      id='voip-button'
      className='d-flex align-items-center text-nowrap w-100'
      disabled={disabled}
      aria-label='Fazer ligação'
    >
      <Icon name='call' className='me-2' size='fixed' />
      <span className='me-3'>Fazer ligação</span>
    </Button>
  </div>
);

function DetailsVoip({ deal, voip, entity, entityType, account, onRefreshModal, onUpdateEntity }) {
  const { user } = useAuth();
  const tracker = useTracking();
  const hasEntity = !isEmpty(entity);
  const isEnabled = Boolean(voip?.enabled);
  const accountBalance = Number(voip?.balance || 0);
  const pricePerMinute = Number(voip?.pricePerMinute);
  const hasNotBalance = accountBalance < pricePerMinute;
  const isAdmin = user?.admin;

  if (!isEnabled) {
    return (
      <VoipEnabled
        voip={voip}
        user={user}
        account={account}
        onCreate={onRefreshModal}
      />
    );
  }

  if (hasNotBalance && !isAdmin && isEnabled) {
    return (
      <div>
        <Tooltip
          placement='top'
          hide={!isEnabled}
          content='Seu saldo não é suficiente.
            Solicite ao administrador da conta para adicionar créditos.'
        >
          <div style={{ width: '100%' }}>
            <VoipButton disabled={true} />
          </div>
        </Tooltip>
      </div>
    );
  }

  return (
    <Popover
      placement={hasNotBalance ? 'bottom' : 'left-start'}
      onEnter={() => tracker.trackActionButtonClicked({ user, action: 'Fazer ligacao' })}
      showArrow
      className='me-1'
      content={() => {
        if (hasNotBalance && isAdmin && isEnabled) {
          return (
            <>
              <span className='text-dark fw-bold'>Saldo insuficiente</span>
              <div className='my-1 mb-3'>
                <span className='text-dark'>
                  Seu saldo não é suficiente.
                    Recarregue sua conta para continuar usando o telefone virtual.
                </span>
              </div>
              <Button variant='primary' href={settingsVoipPanelPath()}>
                Recarregar
              </Button>
              <Button
                variant='light'
                className='ms-2'
                as={ExternalLink}
                target='_blank'
                href={helpUrl('activate-voip')}
              >
                <Icon name='external-link' className='text-dark me-1'></Icon>
                  Saiba mais
              </Button>
            </>
          );
        }

        if (hasEntity && isEnabled && !hasNotBalance) {
          return (
            <DetailsVoipContactList
              entity={entity}
              entityType={entityType}
              deal={deal}
              onRefreshModal={onRefreshModal}
              onUpdateEntity={onUpdateEntity}
            />
          );
        }
        if (!hasEntity && isEnabled && !hasNotBalance) {
          return <EmptyContact deal={deal} />;
        }
      }}
    >
      <div style={{ width: '100%' }}>
        <div style={{ width: '100%' }}>
          <VoipButton />
        </div>
      </div>
    </Popover>
  );
}

export default DetailsVoip;
