import React from 'react';
import PropTypes from 'prop-types';

import { EditInPlace, TextInput } from '@/components/Inputs';
import Truncate from '@/components/Truncate';
import { DealIcon } from '@/feature/automation';

const propTypes = {
  entity: PropTypes.object,
  onUpdate: PropTypes.func,
  editName: PropTypes.string
};

const defaultProps = {
  entity: {},
  onUpdate: () => { },
  editName: 'name'
};

function EntityTitle({ entity, onUpdate, editName, className }) {
  return (
    <EditInPlace
      name={editName}
      as={TextInput}
      className={className}
      value={entity?.title}
      onConfirm={(params, done) => onUpdate(params, done)}
    >
      <DealIcon deal={entity} size='md' />

      <Truncate as='h2' className='text-darker-gray max-width-6'>
        {entity?.title || ''}
      </Truncate>
    </EditInPlace>
  );
}

EntityTitle.propTypes = propTypes;
EntityTitle.defaultProps = defaultProps;

export default EntityTitle;
