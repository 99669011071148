import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import classname from 'classnames';
import { formatRGBA } from '@/utils';
import Dropdown from '@/components/Dropdown';
import Icon from '@/components/Icon';
import Tooltip from '@/components/Tooltip';
import Button from '@/components/Button';

const BG_COLOR = '';
const TEXT_COLOR = '#000000';

BannerOptions.propTypes = {
  onOpenBanner: PropTypes.func,
  onChangeColors: PropTypes.func,
  bgColor: PropTypes.string,
  textColor: PropTypes.string
};

BannerOptions.defaultProps = {
  onOpenBanner: () => {},
  onChangeColors: () => {},
  bgColor: BG_COLOR,
  textColor: TEXT_COLOR
};

function BannerOptions({ onOpenBanner, onChangeColors, bgColor, textColor }) {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [isTextColor, setIsTextColor] = useState(false);
  const colorPickerRef = useRef(null);


  const handleColorPicker = (color) => {
    onChangeColors({
      bgColor: isTextColor ? bgColor : formatRGBA(color?.rgb),
      textColor: isTextColor ? color?.hex : textColor
    });
  };

  const handleExclude = () => {
    onChangeColors({
      bgColor: isTextColor ? bgColor : BG_COLOR,
      textColor: isTextColor ? TEXT_COLOR : textColor
    });
    setIsTextColor(false);
    setShowColorPicker(false);
  };

  const handleCancel = () => {
    onChangeColors({
      bgColor,
      textColor
    });
    setIsTextColor(false);
    setShowColorPicker(false);
  };

  /*
   * TODO: Testamos a implementação desse código via onBlur, porém acabaram
   * ocorrendo outros problemas de usabilidade nos onChangeColors. Por isso,
   * como a opção de js puro solucionou, optamos por manter esse código, mas
   * é importante que seja revisto/refatorado futuramente.
   */
  const handleClickOutside = (event) => {
    if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
      handleCancel();
    }

    if (showColorPicker) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  };

  if (showColorPicker) {
    return (
      <div className='position-absolute z-index-1060'>
        <div
          ref={colorPickerRef}
          onMouseDown={handleClickOutside}
          className='position-absolute'
          style={{ top: 200, right: 35 }}
        >
          <SketchPicker
            color={isTextColor ? textColor : bgColor}
            onChange={handleColorPicker}
          />

          <Tooltip content='Salvar' placement='top'>
            <Button
              variant='primary'
              className='mt-1 me-1'
              onClick={() => {
                setIsTextColor(false);
                setShowColorPicker(false);
              }}
            >
              <Icon name='check' />
            </Button>
          </Tooltip>
          <Tooltip content='Excluir' placement='top'>
            <Button
              variant='light'
              className='mt-1'
              onClick={handleExclude}
            >
              <Icon name='delete' />
            </Button>
          </Tooltip>
        </div>
      </div>
    );
  }

  return (
    <Dropdown
      className={classname(
        'd-flex justify-content-end z-index-1060 position-absolute pe-8'
      )}
      style={{ top: 270, right: 0 }}
      drop='up'
    >
      <Dropdown.Toggle
        variant='light'
        className='px-3 py-2 border-0'
      >
        <Icon name='edit-field' className='me-2' />
        <span className='text-darker-gray fw-bold'>
          Alterar estilo
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu renderOnMount>
        <Dropdown.Item
          as='button'
          onClick={onOpenBanner}
        >
          <Icon name='image' className='me-2' />
          Alterar imagem de fundo
        </Dropdown.Item>

        <Dropdown.Divider />

        <Dropdown.Item
          as='button'
          onClick={() => setShowColorPicker(true)}
        >
          <Icon name='edit-color' className='me-2' />
          Cor de filtro da imagem
        </Dropdown.Item>

        <Dropdown.Divider />

        <Dropdown.Item
          as='button'
          onClick={() => {
            setIsTextColor(true);
            setShowColorPicker(true);
          }}
        >
          <Icon name='font-family' className='me-2' />
          Alterar cor do texto
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default BannerOptions;
