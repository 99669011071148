import React, { useMemo, useState } from 'react';
import { useAlert } from 'react-alert';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import Popover from '@/components/Popover';
import Avatar from '@/components/Avatar';
import DotList from '@/components/DotList';
import LoadSpinner from '@/components/LoadSpinner';
import APIErrorMessage from '@/components/APIErrorMessage';
import ExternalLink from '@/components/ExternalLink';
import { legacyPersonUrl, personCreatePath } from '@/routes';
import { useInfinitePeople } from '@/api';

const propTypes = {
  organizationId: PropTypes.number.isRequired
};

const defaultProps = {};

function PeoplePopover({ organizationId }) {
  const [showModal, setShowModal] = useState(false);
  const alert = useAlert();

  const {
    isLoading, data: { pages = [] } = {}, fetchNextPage, hasNextPage
  } = useInfinitePeople({
    params: { organization_id_eq: organizationId, per_page: 5 },
    config: {
      enabled: showModal,
      cacheTime: 0,
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='person' action='list' />,
          { variant: 'danger' }
        );
      }
    }
  });

  const people = useMemo(
    () => pages.flatMap((page) => page.data),
    [pages]
  );

  return (
    <Popover
      flip
      hide={!showModal}
      className='max-width-6'
      placement='bottom-start'
      rootCloseEvent='mousedown'
      content={({ onHide }) => (
        <div>
          <h4 className='mb-3'>Pessoas da empresa</h4>

          <div className='width-5 max-height-5 overflow-y-auto'>
            {isLoading
              ? (
                <LoadSpinner
                  className='height-3 d-flex align-items-center justify-content-center text-primary'
                />
              )
              : renderPeopleList(people)
            }
          </div>

          <div className='d-grid gap-4 mt-2'>
            {renderFetchMoreButton(hasNextPage, fetchNextPage)}

            <Button
              variant='primary'
              target='_blank'
              href={personCreatePath(organizationId)}
              onClick={onHide}
            >
              <Icon name='plus' size='sm' className='me-1' />

              Adicionar pessoa
            </Button>
          </div>
        </div>
      )}
    >
      <Button
        variant='link'
        className='fw-bold p-0 border-0'
        onClick={() => setShowModal(true)}
      >
        <Icon name='person' size='sm' className='me-1' />

        Ver pessoas
      </Button>
    </Popover>
  );
}

function renderPeopleList(people) {
  if (!people.length) {
    return renderEmptyList();
  }

  return people.map(renderPersonItem);
}

function renderEmptyList() {
  return (
    <div className='text-center text-muted'>
      Esta empresa ainda não possui pessoas cadastradas.
    </div>
  );
}

function renderPersonItem(person, index) {
  return (
    <div
      key={person.id}
      className={classnames('d-flex', 'align-items-center', 'py-1', { 'border-top': index })}
    >
      <Avatar url={person.avatarUrl} name={person.name} />

      <div className='d-flex flex-column ms-2 flexible-width'>
        <ExternalLink
          className='text-body'
          target='_blank'
          href={legacyPersonUrl(person.id)}
        >
          {person.name}
        </ExternalLink>

        <DotList
          truncate
          className='text-muted'
          items={[
            person.jobTitle ?? <span className='text-medium-gray'>Indefinido</span>,
            person.availablePhone ?? <span className='text-medium-gray'>Indefinido</span>
          ]}
        />
      </div>
    </div>
  );
}

function renderFetchMoreButton(hasNextPage, fetchNextPage) {
  if (!hasNextPage) {
    return null;
  }

  return (
    <Button
      variant='link'
      className='fw-bold p-0'
      onClick={fetchNextPage}
    >
      <Icon name='arrow-down' size='sm' className='me-1' />

      Mais resultados
    </Button>
  );
}

PeoplePopover.propTypes = propTypes;
PeoplePopover.defaultProps = defaultProps;

export default PeoplePopover;
