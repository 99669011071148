import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Layout from '@/components/Layout';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import { emitter, useExperiment } from '@marvelapp/react-ab-test';

const propTypes = {};

const defaultProps = {};

const abTest = {
  name: 'Teste A/B Video Onboarding',
  variants: [
    'Grupo A - Teste com video',
    'Grupo B - Controle sem video'
  ]
};

emitter.defineVariants(abTest.name, abTest.variants);

function Page() {
  const { user } = useAuth();
  const tracker = useTracking();
  const { activeVariant } = useExperiment(abTest.name);

  useEffect(() => {
    if (user) {
      tracker.trackUserLoaded({
        user,
        abTest: { [abTest.name]: activeVariant }
      });
    } else {
      throw new Error('Internal page was rendered without user.');
    }
  }, [user]);

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

Page.propTypes = propTypes;
Page.defaultProps = defaultProps;

export default Page;
