import { useQuery } from 'react-query';
import { get } from '@/lib/fetch';

export async function getOrderSummary(params) {
  const { data } = await get('/billing/order_summaries', params);

  return data;
}

export function useOrderSummary({ params = {}, config = {} } = {}) {
  return useQuery({
    ...config,
    queryFn: () => getOrderSummary(params)
  });
}
