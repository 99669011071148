import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@/components/Avatar';
import { AutomationTag } from '@/feature/automation';

const propTypes = {
  createdBy: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    avatarUrl: PropTypes.string
  }),
  label: PropTypes.bool
};

const defaultProps = {
  createdBy: {
    name: '',
    type: '',
    avatarUrl: ''
  },
  label: false
};

function ActivityCreatedBy(props) {
  const {
    createdBy: { type, name, avatarUrl },
    label
  } = props;

  if (type === 'automation') {
    return (
      <div className='d-flex text-primary text-small fw-bold'>
        <AutomationTag size='md' />
      </div>
    );
  }

  return (
    <>
      <Avatar
        className='me-1'
        name={name}
        tooltip={name}
        url={avatarUrl}
      />

      {label && name}
    </>
  );
}

ActivityCreatedBy.propTypes = propTypes;
ActivityCreatedBy.defaultProps = defaultProps;

export default ActivityCreatedBy;
