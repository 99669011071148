import React from 'react';
import { Outlet } from 'react-router-dom';
import FunnelNav from '@/components/FunnelNav';

function FunnelsBase() {
  return (
    <div className='d-flex flex-grow-1 flexible-height flexible-width'>
      <FunnelNav />

      <Outlet />
    </div>
  );
}

export default FunnelsBase;
