import React, { useState } from 'react';
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import FormField from '@/components/FormField';

const propTypes = {
  /** Nome do campo. */
  name: PropTypes.string.isRequired,
  /** Query a ser usada para verificação. */
  query: PropTypes.func.isRequired,
  extraQueryArgs: PropTypes.object
};

const defaultProps = {
  extraQueryArgs: {}
};

const AsyncFormField = React.forwardRef((props, ref) => {
  const { name, query, onBlur, extraQueryArgs, ...componentProps } = props;

  const [{ value }, { error }] = useField(name);
  const { setFieldValue } = useFormikContext();

  const [queryEnabled, setQueryEnabled] = useState(false);
  const [params, setParams] = useState({});

  const { isFetching } = query({
    ...extraQueryArgs,
    params,
    config: {
      enabled: queryEnabled,
      /*
       * Quando a query retorna que encontrou um elemento, seu valor é armazenado nos valores do
       * form na chave _async para que possa ser usado para invalidar o campo.
       */
      onSuccess: () => {
        setFieldValue(`${name}_async`, value);
      }
    }
  });

  const handleBlur = (event) => {
    if (onBlur) {
      onBlur(event);
    }

    if (value && !error) {
      setParams({ [name]: value });
      setQueryEnabled(true);
    }
  };

  return (
    <FormField
      { ...componentProps }
      ref={ref}
      name={name}
      onBlur={handleBlur}
      readOnly={isFetching}
      loading={isFetching}
      loadingMessage='Verificando...'
    />
  );
});

AsyncFormField.propTypes = propTypes;
AsyncFormField.defaultProps = defaultProps;
AsyncFormField.displayName = 'AsyncFormField';

export default AsyncFormField;
