import React from 'react';
import error from 'images/billing/pessoa-triste.svg';

function ErrorStep() {
  return (
    <div className='text-center'>
      <div>
        <img src={error} className='mb-2 width-5 height-5' />
      </div>
      <div className='h3 fw-bold'>Ocorreu um erro ao enviar seus dados</div>
      <div className='text-dark-gray mt-4 h5'>
        Você pode entrar em contato com o nosso suporte
        para obter mais informações
      </div>
    </div>
  );
}

export default ErrorStep;
