import React from 'react';
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { STRIPE_API_KEY } from '@/config';

const stripePromise = loadStripe(
  STRIPE_API_KEY,
  { locale: 'pt-BR' }
);

const propTypes = {
  children: PropTypes.node.isRequired
};

/**
 * @param {Object} props
 */
function StripeElement({ children }) {
  const stripeOptions = {
    mode: 'setup',
    currency: 'brl',
    paymentMethodCreation: 'manual',
    paymentMethodTypes: ['card'],
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;1,400&display=swap'
      }
    ],
    appearance: {
      theme: 'stripe',
      variables: {
        colorPrimary: '#5C57FF',
        colorTextPlaceholder: '#9393C2',
        fontSizeBase: '12px',
        fontFamily: '"Rubik", sans-serif'
      },
      rules: {
        '.Input': {
          backgroundColor: '#F0F0F7'
        },
        '.Label': {
          fontWeight: 500,
          lineHeight: '20px'
        },
        '.Input::placeholder': {
          fontWeight: 400,
          color: '#9393C2'

        },
        '.Input:focus': {
          borderColor: '#5C57FF',
          boxShadow: '#5C57FF'
        }
      }
    }
  };

  return (
    <Elements stripe={stripePromise} options={stripeOptions} key='foo'>
      {children}
    </Elements>
  );
}

StripeElement.propTypes = propTypes;

export default StripeElement;
