export function canCreateEmailTemplate({ user, _data }) {
  let effect, reason;

  if (!user.account.paid) {
    effect = 'paywall';
    reason = 'Para criar modelos de e-mail, é necessário assinar um plano.';
  } else if (!user.admin) {
    effect = 'disable';
    reason = 'Apenas administradores podem criar modelos de e-mail.';
  } else {
    effect = 'allow';
  }

  return { effect, reason };
}

export function canEditEmailTemplate({ user, _data }) {
  let effect, reason;

  if (!user.account.paid) {
    effect = 'paywall';
    reason = 'Para editar modelos de e-mail, é necessário assinar um plano.';
  } else if (!user.admin) {
    effect = 'disable';
    reason = 'Apenas administradores podem editar modelos de e-mail.';
  } else {
    effect = 'allow';
  }

  return { effect, reason };
}

export function canDeleteEmailTemplate({ user, _data }) {
  let effect, reason;

  if (!user.account.paid) {
    effect = 'paywall';
    reason = 'Para excluir modelos de e-mail, é necessário assinar um plano.';
  } else if (!user.admin) {
    effect = 'disable';
    reason = 'Apenas administradores podem excluir modelos de e-mail.';
  } else {
    effect = 'allow';
  }

  return { effect, reason };
}
