import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Badge from '@/components/Badge';

const propTypes = {
  selector: PropTypes.string.isRequired
};

export const HEADWAY_ACCOUNT = 'xYrNl7';

function HeaderNews({ selector }) {
  const [hasUnseen, setHasUnseen] = useState(false);

  useEffect(() => {
    const config = {
      selector,
      account: HEADWAY_ACCOUNT,
      translations: {
        title: 'Últimas mudanças no produto',
        readMore: 'Leia mais',
        labels: {
          new: 'Novidade',
          improvement: 'Melhoria',
          fix: 'Correção'
        },
        footer: 'Ver todas 👉'
      },
      callbacks: {
        onWidgetReady: (widget) => {
          if (widget.getUnseenCount() > 0) {
            setHasUnseen(true);
          }
        },
        onShowWidget: () => {
          setHasUnseen(false);
        }
      }
    };

    /*
     * O script do Headway é incluído diretamente no HTML; se ele já tiver sido
     * carregado, inicializamos manualmente; do contrário, setamos a variável
     * global HW_config para que o Headway inicialize automaticamente quando
     * carregar.
     */
    if (window.Headway) {
      window.Headway.init(config);
    } else {
      window.HW_config = config;
    }
  }, [selector, setHasUnseen]);

  return (
    <div className='position-absolute top-0 end-0 me-3 pe-none'>
      <Badge
        id='HW_outer_badge'
        variant='primary'
        // Utiliza as classes do Headway para ter as mesmas transições.
        className={classnames(
          'HW_badge rounded-circle p-1 border border-primary',
          { 'HW_visible': hasUnseen }
        )}
      >
        {' '}
      </Badge>
    </div>
  );
}

HeaderNews.propTypes = propTypes;

export default HeaderNews;
