export function canCreateProduct({ user, _data }) {
  let effect, reason;

  if (!user.admin || user.groupType === 'Colaboradores') {
    effect = 'disable';
    reason = `Você não possui privilégio para adicionar produtos. Contate o
      administrador de sua conta.`;
  } else {
    effect = 'allow';
  }

  return { effect, reason };
}

export function canUpdateProduct({ user, _data }) {
  let effect, reason;

  if (!user.admin) {
    effect = 'disable';
    reason = 'Apenas administradores podem editar produtos.';
  } else {
    effect = 'allow';
  }

  return { effect, reason };
}


export function canDeleteProduct({ user, _data }) {
  let effect, reason;

  if (!user.admin) {
    effect = 'disable';
    reason = 'Apenas administradores podem excluir produtos.';
  } else {
    effect = 'allow';
  }

  return { effect, reason };
}

export function canCreateProductCategory({ user, _data }) {
  let effect, reason;

  if (!user.admin) {
    effect = 'disable';
    reason = 'Apenas administradores podem criar categorias de produtos.';
  } else {
    effect = 'allow';
  }

  return { effect, reason };
}

export function canUpdateProductCategory({ user, _data }) {
  let effect, reason;

  if (!user.admin) {
    effect = 'disable';
    reason = 'Apenas administradores podem editar categorias de produtos.';
  } else {
    effect = 'allow';
  }

  return { effect, reason };
}

export function canDeleteProductCategory({ user, _data }) {
  let effect, reason;

  if (!user.admin) {
    effect = 'disable';
    reason = 'Apenas administradores podem excluir categorias de produtos.';
  } else {
    effect = 'allow';
  }

  return { effect, reason };
}
