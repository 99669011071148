import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import times from 'lodash/times';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Icon from '@/components/Icon';
import { pluralize } from '@/utils';

const DEFAULT_MAX = 5;

const propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  max: PropTypes.number
};

const defaultProps = {
  value: 0,
  onChange: () => {},
  max: DEFAULT_MAX
};

function Rating({ value, max, onChange }) {
  const [selectedRating, setSelectedRating] = useState(value);
  const [isHoverRating, setIsHoverRating] = useState(false);
  const [isChanging, setIsChanging] = useState(false);

  function handleResetRating() {
    if (!isChanging) {
      setIsHoverRating(false);
      setSelectedRating(value);
    }
  }

  function handleHoverRating(hoverRating) {
    if (!isChanging) {
      setIsHoverRating(true);
      setSelectedRating(hoverRating);
    }
  }

  function handleChangeRating(currentRating) {
    setIsChanging(true);
    setIsHoverRating(false);
    // Duplo click na mesma estrela zera rating
    const clickedRating = currentRating === value ? 0 : currentRating;
    setSelectedRating(clickedRating);
    onChange(clickedRating, () => setIsChanging(false));
  }

  return (
    <div className='d-inline p-0'>
      {times(max, (index) => {
        const currentRating = index + 1;
        const tooltipText = `${currentRating} ${pluralize('estrela', currentRating)}`;
        const isActive = selectedRating >= currentRating;
        const isHover = isHoverRating && currentRating === selectedRating;
        return (
          <div
            key={currentRating}
            className='me-1 cursor-pointer d-inline-block'
          >
            <OverlayTrigger
              key={currentRating}
              placement='top'
              show={isHover}
              trigger={['hover', 'focus']}
              overlay={<Tooltip className='fw-bold'>{tooltipText}</Tooltip>}
            >
              <div
                onMouseEnter={() => handleHoverRating(currentRating)}
                onMouseLeave={() => handleResetRating()}
                onClick={() => handleChangeRating(currentRating)}
              >
                <Icon
                  className={classnames(
                    { 'text-light-gray': !isActive },
                    { 'text-flat-green': isActive }
                  )}
                  name={isActive ? 'star' : 'star-outlined'}
                  size='md'
                />
              </div>
            </OverlayTrigger>
          </div>
        );
      })}
    </div>
  );
}

Rating.propTypes = propTypes;
Rating.defaultProps = defaultProps;

export default Rating;
