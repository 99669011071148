import React from 'react';
import PropTypes from 'prop-types';
import Base from 'react-bootstrap/ToggleButton';
import Tooltip from '@/components/Tooltip';

const propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  tooltip: PropTypes.string,
  className: PropTypes.string
};

const defaultProps = {
  tooltip: '',
  className: '',
  variant: 'toggle'
};

const ToggleButton = React.forwardRef(
  ({ children, ...props }, ref) => {
    const { tooltip, ...rest } = props;
    const base =
      <Base { ...rest } ref={ref}>
        {children}
      </Base>;

    if (tooltip) {
      return (
        <Tooltip content={tooltip}>
          {base}
        </Tooltip>
      );
    } else {
      return base;
    }
  }
);

ToggleButton.propTypes = propTypes;
ToggleButton.defaultProps = defaultProps;
ToggleButton.displayName = 'ToggleButton';

export default ToggleButton;
