import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '@/components/Icon';
import InputAdornment from '@/components/InputAdornment';

const propTypes = {
  isFilled: PropTypes.bool.isRequired,
  onClear: PropTypes.func
};

const defaultProps = {};

function RangeAdornment({ isFilled, onClear }) {
  const onClick = isFilled ? onClear : undefined;
  const iconName = isFilled ? 'close' : 'arrow-down';

  return (
    <InputAdornment
      onClick={onClick}
      className={classnames({
        'pe-none': !isFilled,
        'text-primary': !isFilled,
        'text-darker-gray': isFilled
      })}
    >
      <Icon name={iconName} />
    </InputAdornment>
  );
}

RangeAdornment.propTypes = propTypes;
RangeAdornment.defaultProps = defaultProps;

export default RangeAdornment;
