import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Stack from 'react-bootstrap/Stack';
import Card from '@/components/Card';
import Badge from '@/components/Badge';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import EmailDetailPopover from '@/components/Email/EmailDetailPopover';
import { RichTextInput } from '@/components/Inputs';
import { useUsers } from '@/api';
import strings from '@/strings';
import { pluralize } from '@/utils';
import size from 'lodash/size';
import find from 'lodash/find';

const propTypes = {
  params: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.array,
    cc: PropTypes.array,
    bcc: PropTypes.array,
    subject: PropTypes.string,
    body_text: PropTypes.string,
    attachments: PropTypes.array
  }),
  onClick: PropTypes.func
};

function EmailActionPreview(props) {
  const { params, onClick } = props;

  const { data: users } = useUsers();
  const getLabel = useCallback((value) => {
    const defaultLabel = strings.labels.automation[value];
    if (defaultLabel) {
      return defaultLabel;
    }

    const user = find(users?.data, { id: value });
    if (user) {
      return user.name;
    }
  }, [users]);

  const preview = useMemo(() => ({
    from: getLabel(params.from),
    to: params.to?.map(getLabel),
    cc: params.cc?.map(getLabel),
    bcc: params.bcc?.map(getLabel),
    subject: params.subject,
    body: params.body,
    attachments: size(params.attachments)
  }), [getLabel, params]);

  return (
    <div>
      <Card bg='dark-light' border='dark-light' className='p-4'>
        <Stack gap={2}>
          <h5 className='mb-0'>
            {preview.subject}
          </h5>

          <dl className='d-flex gap-2 align-items-start text-small text-dark-gray mb-0'>
            <div>
              <dt className='d-inline'>Para: </dt>
              <dd className='d-inline'>{preview.to?.join(', ')}</dd>
            </div>

            <EmailDetailPopover email={preview} />
          </dl>

          <hr className='m-0' />

          {/**
            *
            * Foi usado o próprio Quill para gerar a visualização do corpo
            * porque o corpo é HTML é a visualização é apenas texto.
            *
            * Na criação de automação, é possível obter o texto usando uma ref
            * para o `RichTextInput` e o método `getText`[1], mas isso não
            * funciona na edição de automação, pois nesse cenário a visualização
            * aparece antes do `RichTextInput`.
            *
            * Outra possibilidade seria fazer essa conversão para texto com o
            * DOMParser[2], mas isso demandaria tratamento para separar os
            * blocos de texto, que é algo que o Quill já faz.
            *
            * Por isso, foi usado ele mesmo, só que em modo de leitura, sem
            * toolbar e sem formatação.
            *
            * Como ele gera uma estrutura HTML já com algumas classes e estilos
            * próprios, para poder limitar a visualização para apenas a primeira
            * linha, não foi possível usar o componente `Truncate` e foi
            * necessário CSS customizado nessa classe `ql-preview`.
            *
            * [1]: https://quilljs.com/docs/api/#gettext
            * [2]: https://developer.mozilla.org/en-US/docs/Web/API/DOMParser
            *
            * ATUALIZAÇÃO: alterado para a lib Jodit pois o Qull foi removido.
            *
            **/}
          <RichTextInput
            readOnly={true}
            value={preview?.body}
            className='jodit-preview'
          />

          {
            preview.attachments > 0 && (
              <Badge variant='light' className='align-self-start'>
                <Icon name='paperclip' size='sm' className='me-1' />

                <span>
                  {preview.attachments} {pluralize('anexo', preview.attachments)}
                </span>
              </Badge>
            )
          }
        </Stack>
      </Card>

      <Button
        variant='outline-primary'
        href='email'
        onClick={onClick}
        className='mt-2'
      >
        <Icon name='edit-field' className='me-2' />
        <span>Editar e-mail</span>
      </Button>
    </div>
  );
}

EmailActionPreview.propTypes = propTypes;

export default EmailActionPreview;
