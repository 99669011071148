import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Tooltip from '@/components/Tooltip';
import { getInitials } from '@/text';

const propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['md', 'lg']),
  light: PropTypes.bool,
  slide: PropTypes.bool,
  label: PropTypes.string,
  tooltip: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.oneOf([false])
  ])
};

const defaultProps = {
  url: null,
  name: '',
  className: null,
  size: 'md',
  light: false,
  slide: false,
  label: null,
  tooltip: null
};

function Avatar({ url, name, className, size, light, slide, label, tooltip }) {
  const [imgError, setImgError] = useState(false);

  const classNames = classnames(
    `avatar-${size}`,
    'border',
    'border-2',
    'border-white',
    'rounded-circle',
    'd-inline-flex',
    'align-items-center',
    'justify-content-center',
    'text-smaller',
    'text-uppercase',
    'fw-bold',
    'lh-1',
    'overflow-hidden',
    { 'increase-hover-area': Boolean(tooltip) },
    className,
    {
      'bg-dark-gray text-white': !light,
      'bg-light-gray text-dark-gray': light,
      'hover-slide-up': slide
    }
  );
  const text = label || getInitials(name);

  return (
    <Tooltip content={tooltip || name} hide={tooltip === false}>
      {url && !imgError
        ? (
          <span className={classNames}>
            <img
              className='img-fluid'
              src={url}
              alt={text}
              onError={() => setImgError(true)}
              loading='lazy'
            />
          </span>
        )
        : <span className={classNames}>{text}</span>
      }
    </Tooltip>
  );
}

Avatar.propTypes = propTypes;
Avatar.defaultProps = defaultProps;

export default Avatar;
