import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import Popover from '@/components/Popover';
import DatePicker from '@/components/DatePicker';
import Icon from '@/components/Icon';
import { Select, TextArea, LossReasonSelect } from '@/components/Inputs';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import LoadingButton from '@/components/LoadingButton';
import { now } from '@/date';
import Tooltip from '@/components/Tooltip';
import classnames from 'classnames';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import { getString } from '@/utils';

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired
};

const defaultProps = {
  value: '',
  onChange: () => {}
};

const ACTIVE = 1;
const WON = 2;
const LOST = 3;

/* eslint-disable no-magic-numbers */
const lossReasonSchema = Yup.object().shape({
  loss_reason_id: Yup.number().nullable(true),
  description: Yup.string().max(
    250,
    'O comentário deve ter no máximo 250 caracteres.'
  )
});
/* eslint-enable no-magic-numbers */

const lossReasonDefaultValues = {
  loss_reason_id: null,
  description: ''
};

const STATUSES = [
  {
    label: 'Em andamento',
    value: ACTIVE,
    leftAdornment: <Icon name='active-deal' size='sm' className='me-1' />,
    controlClassName: 'bg-light-yellow text-warning'
  },
  {
    label: 'Ganho',
    value: WON,
    leftAdornment: <Icon name='won-deal' size='sm' className='me-1' />,
    controlClassName: 'bg-light-green text-success'
  },
  {
    label: 'Perdido',
    value: LOST,
    leftAdornment: <Icon name='lost-deal' size='sm' className='me-1' />,
    controlClassName: 'bg-light-red text-danger'
  }
];

function getControlClassNameBy(dealStatus) {
  const status = STATUSES.find(({ value }) => dealStatus === value);
  return status?.controlClassName ?? '';
}

function FullDealStatusSelect({ value, onChange }) {
  const inputRef = useRef(null);
  const [dealStatus, setDealStatus] = useState(value);
  const [showPopover, setShowPopover] = useState(false);
  const [finishedAt, setFinishedAt] = useState(now());
  const { user } = useAuth();
  const tracker = useTracking();

  function handleActiveDeal() {
    onChange({ deal_status_id: ACTIVE, finished_at: null });
  }

  function handleWonDeal() {
    onChange({ deal_status_id: WON, finished_at: finishedAt });
  }

  function handleLostDeal(dealLossReason) {
    onChange({
      deal_status_id: LOST,
      finished_at: finishedAt,
      deal_loss_reason: dealLossReason
    });
  }

  function handleOnSubmit(formData) {
    setShowPopover(false);
    if (dealStatus === WON) {
      return handleWonDeal();
    }
    if (dealStatus === LOST) {
      return handleLostDeal(formData);
    }
    return null;
  }

  function isLostDeal() {
    return dealStatus === LOST;
  }

  function handleOnChangeStatus(newDealStatus) {
    if (newDealStatus !== value) {
      tracker.trackDealStatusChanged({
        user,
        status: getString(['models', 'deal_status_id', newDealStatus]),
        page: 'Nova Listagem'
      });
      setDealStatus(newDealStatus);
      setShowPopover(true);
      if (newDealStatus === ACTIVE) {
        return handleActiveDeal();
      }
      /*
       * Esse input button oculto existe para garantir que
       * o popover vai abrir após efetuar um click num option do select
       * pois popover ativa evento click antes do onChange iniciar
       * e nao garantia corretamente sua abertura msmo usando showPopover
       */
      inputRef?.current.click();
    }
    return null;
  }

  function resetState() {
    if (showPopover) {
      setDealStatus(value);
    }
    setShowPopover(false);
    setFinishedAt(now());
  }

  function renderDealStatusReasonForm({ onHide }) {
    return (
      <Form
        name='deal-status-reason-form'
        onSubmit={(values) => {
          handleOnSubmit(values);
          onHide();
        }}
        defaultValues={lossReasonDefaultValues}
        validationSchema={lossReasonSchema}
        className='d-flex'
      >
        {({ handleBlur, handleSubmit, isSubmitting, values }) => {
          const isLostReasonFilled = Boolean(values.loss_reason_id);

          return (
            <>
              <div className='d-flex flex-column bg-white px-4 py-6'>
                <h4>{`Quando foi ${isLostDeal() ? 'perdido' : 'ganho'}?`}</h4>
                <DatePicker
                  selected={finishedAt}
                  onDayClick={setFinishedAt}
                  name='finished_at'
                  mode='single'
                />
                {!isLostDeal() && (
                  <LoadingButton
                    onClick={handleSubmit}
                    isLoading={isSubmitting}
                    className='min-width-4 px-8'
                  >
                    Salvar
                  </LoadingButton>
                )}
              </div>
              {isLostDeal() && (
                <div className='d-flex flex-column min-width-5 bg-light px-4 py-6'>
                  <h4 className='mb-6'>Qual motivo da perda?</h4>
                  <FormField
                    as={LossReasonSelect}
                    name='loss_reason_id'
                    controlClassName='bg-white'
                    onBlur={handleBlur}
                  />
                  <FormField
                    as={TextArea}
                    name='description'
                    variant='white'
                    placeholder='Comentário (opcional)'
                    maxLength={250}
                    onBlur={handleBlur}
                  />
                  <Tooltip
                    placement='bottom'
                    content='O motivo de perda é obrigatório'
                    trigger={['hover']}
                    hide={isLostReasonFilled}
                  >
                    <div>
                      <LoadingButton
                        disabled={!isLostReasonFilled}
                        onClick={handleSubmit}
                        isLoading={isSubmitting}
                        className={classnames('w-100', {
                          'pe-none': !isLostReasonFilled
                        })}
                      >
                        Salvar
                      </LoadingButton>
                    </div>
                  </Tooltip>
                </div>
              )}
            </>
          );
        }}
      </Form>
    );
  }

  return (
    <>
      <Select
        name='deal_status'
        label=''
        placeholder=''
        className='py-0 my-0'
        value={dealStatus}
        controlClassName={getControlClassNameBy(dealStatus)}
        onChange={handleOnChangeStatus}
        options={STATUSES}
        isSearchable={false}
      />
      <Popover
        rootClose
        rootCloseEvent='mousedown'
        placement='right'
        onExit={resetState}
        trigger={['click']}
        contentClassName='p-0'
        hide={!showPopover}
        content={renderDealStatusReasonForm}
      >
        <input ref={inputRef} type='button' className='hidden-button' />
      </Popover>
    </>
  );
}

FullDealStatusSelect.propTypes = propTypes;
FullDealStatusSelect.defaultProps = defaultProps;

export default FullDealStatusSelect;
