import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { useIntercomUserData } from '@/lib/intercom/hooks/use-intercom-user-data';

export function IntercomLocationTracker({ children }) {
  const userData = useIntercomUserData();
  const location = useLocation();
  const { update } = useIntercom();

  useEffect(() => update(userData), [location]);

  return children;
}
