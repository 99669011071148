import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import InfiniteScroll from '@/components/InfiniteScroll';

const propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderItem: PropTypes.func.isRequired,
  fetchMore: PropTypes.func,
  canFetchMore: PropTypes.bool,
  isFetchingMore: PropTypes.bool,
  className: PropTypes.string,
  innerRef: PropTypes.any,
  innerProps: PropTypes.object,
  innerPlaceholder: PropTypes.node
};

const defaultProps = {
  fetchMore: () => {},
  canFetchMore: false,
  isFetchingMore: false,
  className: '',
  innerRef: null,
  innerProps: {},
  innerPlaceholder: null
};

function CardList(props) {
  const {
    items, renderItem, fetchMore, canFetchMore,
    isFetchingMore, className, innerRef, innerProps
  } = props;

  return (
    <div className={classnames(
      'card-list',
      'd-flex',
      'flex-column',
      'flexible-height',
      'position-relative',
      'flex-grow-1',
      'text-break-all',
      className
    )}>
      <div
        className={classnames(
          'flexible-height',
          'flex-grow-1',
          'd-flex',
          'flex-column',
          'rounded-3',
          'card-list-body'
        )}
      >
        <div
          className='flex-grow-1 d-flex flexible-height flexible-width'
          ref={innerRef}
          { ...innerProps }
        >
          <InfiniteScroll
            className={classnames(
              'flex-grow-1',
              'd-flex',
              'flex-column',
              'flexible-height',
              'overflow-y-scroll'
            )}
            length={items.length}
            loadItems={fetchMore}
            hasMore={canFetchMore}
            isLoading={isFetchingMore}
          >
            <CardListInner items={items} renderItem={renderItem} />

            {props.innerPlaceholder}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
}

/**
 *
 * This component is meant to avoid re-rendering all of the items when the
 * outer list is re-rendered.
 * For the memoization to work, we can't receive the contents as prebuilt nodes
 * in the `children` prop; that's why we receive an items array and a function
 * to render them. It's important that this function doesn't change between
 * renders!
 *
 */
const CardListInner = React.memo(({ items, renderItem }) => (
  items.map(renderItem)
));
CardListInner.displayName = 'CardListInner';

CardList.propTypes = propTypes;
CardList.defaultProps = defaultProps;

export default CardList;
