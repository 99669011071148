import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@/components/Skeleton';
import ActivitiesNavs from '@/components/EntityModal/Deal/Activities/DealActivitiesNavs';
import Form from '@/components/EntityModal/Activities/Form';

DealActivities.defaultProps = {
  deal: {},
  activities: [],
  dealCustomFields: [],
  voip: {},
  entity: {},
  entityType: '',
  entityCustomFields: [],
  loading: false,
  isFetchingActivities: false,
  isFetchingNextPageActivities: false,
  fetchNextPageActivities: () => {},
  hasNextPageActivities: false,
  onCreateActivity: () => {},
  onUpdateActivity: () => {},
  onDeleteActivity: () => {},
  onUpdateDeal: () => {},
  onEditEntity: () => {}
};

DealActivities.propTypes = {
  deal: PropTypes.object,
  activities: PropTypes.array,
  dealCustomFields: PropTypes.array,
  voip: PropTypes.object,
  entity: PropTypes.object,
  entityType: PropTypes.string,
  entityCustomFields: PropTypes.array,
  loading: PropTypes.bool,
  isFetchingActivities: PropTypes.bool,
  isFetchingNextPageActivities: PropTypes.bool,
  fetchNextPageActivities: PropTypes.func,
  hasNextPageActivities: PropTypes.bool,
  onCreateActivity: PropTypes.func,
  onUpdateActivity: PropTypes.func,
  onDeleteActivity: PropTypes.func,
  onUpdateDeal: PropTypes.func,
  onEditEntity: PropTypes.func
};

function DealActivities({
  deal, activities, isFetchingActivities, onCreateActivity, onUpdateActivity, onDeleteActivity,
  isFetchingNextPageActivities, fetchNextPageActivities, hasNextPageActivities, voip,
  dealCustomFields, entity, entityType, entityCustomFields, loading, onUpdateDeal, onEditEntity
}) {
  if (loading) {
    return (
      <>
        <Skeleton width='100%' height={108} className='mb-6' />
        <Skeleton width={210} height={20} className='mb-3' />
        <Skeleton width='100%' height={275} />
      </>
    );
  }
  return (
    <div>
      <Form entityName='deal' entityId={deal.id} onCreate={onCreateActivity} />
      <ActivitiesNavs
        deal={deal}
        activities={activities}
        voip={voip}
        dealCustomFields={dealCustomFields}
        entity={entity}
        entityType={entityType}
        entityCustomFields={entityCustomFields}
        loading={loading}
        isFetchingActivities={isFetchingActivities}
        isFetchingNextPageActivities={isFetchingNextPageActivities}
        fetchNextPageActivities={fetchNextPageActivities}
        hasNextPageActivities={hasNextPageActivities}
        onDeleteActivity={onDeleteActivity}
        onUpdateActivity={onUpdateActivity}
        onUpdateDeal={onUpdateDeal}
        onEditEntity={onEditEntity}
      />
    </div>
  );
}

export default DealActivities;
