import React, { useState } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import * as Yup from 'yup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormField from '@/components/FormField';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import { TextInput, WhatsAppInput, MaskedInput } from '@/components/Inputs';
import { useDeletePerson } from '@/api';
import ConfirmationModal from '@/components/ConfirmationModal';
import { useAlert } from 'react-alert';
import APIErrorMessage from '@/components/APIErrorMessage';

const propTypes = {};

const defaultProps = {
  InitialAddButton: AddButton
};

/* eslint-disable no-magic-numbers */
export const schema = Yup.array().of(
  Yup.object().shape({
    name: Yup.string()
      .max(60, 'O nome da pessoa deve ter no máximo 60 caracteres.')
      .required('Por favor, informe o nome da pessoa.'),
    job_title: Yup.string()
      .max(60, 'O cargo da pessoa deve ter no máximo 60 caracteres.'),
    contact: Yup.object().shape({
      whatsapp: Yup.string()
        .min(12, 'Número inválido.')
        .max(16, 'Número inválido.'),
      email: Yup.string().email('E-mail inválido.')
    })
  })
);
/* eslint-enable no-magic-numbers */

export const defaultValues = {
  name: '',
  job_title: '',
  contact: {
    whatsapp: '',
    email: ''
  }
};

function PersonRow({ personId, index, removeFunction, handleBlur }) {
  const [showModal, setShowModal] = useState(false);
  const deletePersonMutation = useDeletePerson();
  const alert = useAlert();

  const handleRemovePerson = () => {
    if (personId) {
      setShowModal(true);
    } else {
      removeFunction(index);
    }
  };

  const handleConfirmDeletePerson = () => {
    deletePersonMutation.mutate({ personId }, {
      onSuccess: () => {
        removeFunction(index);
        alert.show(
          'Pessoa removida com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        setShowModal(false);
      },
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='person' action='delete' />,
          { variant: 'danger' }
        );
      }
    });
  };

  return (
    <fieldset >
      {index > 0 && <hr className='mb-4' />}

      <Row className='gx-3'>
        <Col sm={5}>
          <FormField
            as={TextInput}
            name={`people.${index}.name`}
            label='Nome'
            placeholder='Digite o nome'
            onBlur={handleBlur}
            autoComplete='off'
            maxLength={60}
          />
        </Col>

        <Col sm={5}>
          <FormField
            as={WhatsAppInput}
            name={`people.${index}.contact.whatsapp`}
            label='WhatsApp'
            placeholder='+00 (00) 00000-0000'
            onBlur={handleBlur}
            autoComplete='off'
          />
        </Col>
      </Row>

      <Row className='gx-3'>
        <Col sm={5}>
          <FormField
            as={MaskedInput}
            maskType='email'
            name={`people.${index}.contact.email`}
            label='E-mail'
            placeholder='exemplo@email.com'
            onBlur={handleBlur}
            autoComplete='off'
          />
        </Col>

        <Col sm={5}>
          <FormField
            as={TextInput}
            name={`people.${index}.job_title`}
            label='Cargo'
            placeholder='Digite o cargo'
            onBlur={handleBlur}
            autoComplete='off'
            maxLength={60}
          />
        </Col>

        <Col sm={2}>
          <Button
            variant='outline-danger'
            size='sm'
            className='px-1 mt-8'
            onClick={
              () => handleRemovePerson()
            }
            /*
             * Caso o clique dispare o `blur` de um input e isso gere
             * um erro de validação, o clique é cancelado.
             * ref: https://github.com/formium/formik/issues/1332
             */
            onMouseDown={(e) => e.preventDefault()}
          >
            Remover
          </Button>
        </Col>

      </Row>

      <ConfirmationModal
        confirmationText='Excluir'
        show={showModal}
        onConfirm={handleConfirmDeletePerson}
        onHide={() => setShowModal(false)}
      >
        <ConfirmationModal.Title>
          Deseja excluir esta pessoa?
        </ConfirmationModal.Title>
      </ConfirmationModal>
    </fieldset>
  );
}

function PeopleFields({ InitialAddButton }) {
  const { values: { people: items }, handleBlur } = useFormikContext();

  const isEmpty = !items || items.length === 0;

  return (
    <FieldArray
      name='people'
    >
      {({ remove, push }) => (
        isEmpty
          ? <InitialAddButton first onClick={() => push(defaultValues)} />
          : <div>
            {items.map((person, index) => (
              <PersonRow
                key={index}
                personId={person.id}
                index={index}
                removeFunction={remove}
                handleBlur={handleBlur}
              />
            ))}

            <AddButton onClick={() => push(defaultValues)} />
          </div>
      )}
    </FieldArray>
  );
}

function AddButton({ onClick, first }) {
  const label = first
    ? 'Adicionar pessoas/contatos dessa empresa'
    : 'Adicionar outra';

  return (
    <Button
      variant='link'
      size='sm'
      className='px-0 fw-bold'
      onClick={onClick}
    >
      <Icon name='plus' size='sm' className='me-1' />

      <span>
        {label}
      </span>
    </Button>
  );
}

PeopleFields.propTypes = propTypes;
PeopleFields.defaultProps = defaultProps;

export default PeopleFields;
