import { useMutation, useQueryClient, useQuery } from 'react-query';
import { get, post, patch, _delete } from '@/lib/fetch';
import { updateListItem } from '@/utils';

export const automationsKeys = {
  all: ['automations'],
  lists: () => [...automationsKeys.all, 'list'],
  list: (params) => [...automationsKeys.lists(), params]
};

export async function getAutomations(params = {}) {
  const { data } = await get('/automations', params);

  return data;
}

export async function createAutomation(params = {}) {
  const { data } = await post('/automations', params);

  return data;
}

export async function updateAutomation({ id, params = {} }) {
  const { data } = await patch(`/automations/${id}`, params);

  return data;
}

export async function deleteAutomation({ id }) {
  const { data } = await _delete(`/automations/${id}`);

  return data;
}

export function useAutomations({ params = { per_page: 200 }, config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: automationsKeys.list(params),
    queryFn: () => getAutomations(params)
  });
}

export function useCreateAutomation({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries(automationsKeys.all);

  return useMutation(
    createAutomation,
    {
      onSuccess,
      ...config
    }
  );
}

export function useUpdateAutomation({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries(automationsKeys.all);

  const onError = (_err, _params, rollback) => rollback();

  const onMutate = ({ id, params }) => {
    queryClient.cancelQueries(automationsKeys.lists());

    const queries = queryClient.getQueriesData(automationsKeys.lists());

    queries.forEach((query) => {
      const [key, data] = query;

      queryClient.setQueryData(
        key,
        { data: updateListItem(data.data, id, params) }
      );
    });

    return () => {
      queries.forEach((query) => {
        const [key, data] = query;

        queryClient.setQueryData(key, data);
      });
    };
  };

  return useMutation(
    updateAutomation,
    {
      onSuccess,
      onError,
      onMutate,
      ...config
    }
  );
}

export function useDeleteAutomation({ config = {} } = {}) {
  const queryClient = useQueryClient();

  /**
   * Como após a invalidação o componente que foi excluído pode ser desmontado,
   * o callback de `onSuccess` passado como configuração precisa ser chamado
   * antes da invalidação.
   */
  const onSuccess = (data, variables, context) => {
    config.onSuccess?.(data, variables, context);
    return queryClient.invalidateQueries(automationsKeys.all);
  };

  return useMutation(
    deleteAutomation,
    {
      ...config,
      onSuccess
    }
  );
}
