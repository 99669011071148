import { useAuth } from '@/lib/auth';

export function useIntercomUserData() {
  const { user } = useAuth();

  if (!user) {
    return {};
  }

  return {
    userId: user.id,
    email: user.email,
    name: user.name,
    userHash: user.intercomHash
  };
}
