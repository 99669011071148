import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '@/components/Icon';

const propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'full'])
};

const defaultProps = {
  className: '',
  size: 'md'
};

function LoadSpinner({ className, size }) {
  return (
    <div className={classnames('opacity-50', className)}>
      <Icon
        className='animation-rotate'
        name='loading'
        size={size}
      />
    </div>
  );
}

LoadSpinner.propTypes = propTypes;
LoadSpinner.defaultProps = defaultProps;

export default LoadSpinner;
