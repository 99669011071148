import React, { useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import History from '@/components/EntityModal/Activities/History';
import Details from '@/components/EntityModal/Deal/Details';
import IconLabel from '@/components/IconLabel';
import Skeleton from '@/components/Skeleton';
import { useScreenSize } from '@/hooks';
import { canEditDeal } from '@/policies';

DealActivitiesNavs.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    createdBy: PropTypes.shape({
      name: PropTypes.string
    }),
    dueAt: PropTypes.string,
    createdAt: PropTypes.string,
    text: PropTypes.string,
    finished: PropTypes.bool,
    finishedAt: PropTypes.string,
    finishedBy: PropTypes.shape({
      name: PropTypes.string
    }),
    assignedUsers: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      active: PropTypes.bool,
      name: PropTypes.string,
      avatarUrl: PropTypes.string
    }))
  })),
  deal: PropTypes.object,
  dealCustomFields: PropTypes.array,
  voip: PropTypes.object,
  entity: PropTypes.object,
  entityType: PropTypes.string,
  entityCustomFields: PropTypes.array,
  isFetchingActivities: PropTypes.bool,
  isFetchingNextPageActivities: PropTypes.bool,
  fetchNextPageActivities: PropTypes.func,
  hasNextPageActivities: PropTypes.bool,
  loading: PropTypes.bool,
  onDeleteActivity: PropTypes.func,
  onUpdateActivity: PropTypes.func,
  onUpdateDeal: PropTypes.func,
  onEditEntity: PropTypes.func
};

DealActivitiesNavs.defaultProps = {
  activities: [],
  deal: {},
  dealCustomFields: [],
  voip: {},
  entity: {},
  entityType: '',
  entityCustomFields: [],
  isFetchingActivities: false,
  isFetchingNextPageActivities: false,
  fetchNextPageActivities: () => {},
  hasNextPageActivities: false,
  loading: false,
  onDeleteActivity: () => {},
  onUpdateActivity: () => {},
  onUpdateDeal: () => {},
  onEditEntity: () => {}
};

const NAVS = {
  activitiesHistory: 'activitiesHistory',
  dealDetails: 'dealDetails'
};

function ActivityNavItem({ label, iconName, active, loading, onClick }) {
  if (loading) {
    return <Skeleton width={200} height={30} />;
  }

  return (
    <Nav.Item>
      <Nav.Link
        onClick={onClick}
        className={classnames(
          'me-4 px-2',
          { 'border-bottom border-darker-gray': active },
          { 'text-dark-gray': !active }
        )}
      >
        <IconLabel
          icon={{ name: iconName }}
          text={label}
          textSize='h4'
        />
      </Nav.Link>
    </Nav.Item>
  );
}

function DealActivitiesNavs({
  activities, isFetchingActivities, loading, onDeleteActivity, onUpdateActivity,
  isFetchingNextPageActivities, fetchNextPageActivities, hasNextPageActivities,
  deal, dealCustomFields, entity, entityType, entityCustomFields, voip, onUpdateDeal,
  onEditEntity
}) {
  const { isMD } = useScreenSize();
  const [navKey, setNavKey] = useState(NAVS.activitiesHistory);

  return (
    <Tab.Container
      activeKey={navKey}
      defaultActiveKey={NAVS.activitiesHistory}
    >
      <Nav>
        <ActivityNavItem
          label='Histórico de atividades'
          iconName='task'
          active={navKey === NAVS.activitiesHistory}
          loading={loading}
          onClick={() => setNavKey(NAVS.activitiesHistory)}
        />
        {
          isMD
            ? (
              <ActivityNavItem
                label='Detalhes do negócio'
                iconName='info'
                active={navKey === NAVS.dealDetails}
                loading={loading}
                onClick={() => setNavKey(NAVS.dealDetails)}
              />
            )
            : null
        }
      </Nav>

      <Tab.Content
        style={{ marginTop: '-1px' }}
        className={classnames(
          'p-4',
          'border-light-gray border-top',
          { 'bg-white': navKey === NAVS.activitiesHistory }
        )}
      >
        <Tab.Pane eventKey={NAVS.activitiesHistory}>
          <History
            entity={deal}
            activities={activities}
            isFetchingActivities={isFetchingActivities}
            isFetchingNextPageActivities={isFetchingNextPageActivities}
            fetchNextPageActivities={fetchNextPageActivities}
            hasNextPageActivities={hasNextPageActivities}
            loading={loading}
            onUpdateActivity={onUpdateActivity}
            onDeleteActivity={onDeleteActivity}
            entityName='deal'
          />
        </Tab.Pane>
        {
          isMD
            ? (
              <Tab.Pane eventKey={NAVS.dealDetails}>
                <Details
                  deal={deal}
                  dealCustomFields={dealCustomFields}
                  voip={voip}
                  entity={entity}
                  entityType={entityType}
                  entityCustomFields={entityCustomFields}
                  loading={loading}
                  onEditDeal={onUpdateDeal}
                  onEditEntity={onEditEntity}
                  canEditPolicy={canEditDeal}
                />
              </Tab.Pane>
            )
            : null
        }
      </Tab.Content>
    </Tab.Container>
  );
}

export default DealActivitiesNavs;
