import React, { useState, useMemo } from 'react';
import { useAuth } from '@/lib/auth';
import { settingsManageUpdatePlanPath } from '@/routes';
import { useNavigate } from 'react-router-dom';
import Button from '@/components/Button';
import PerformanceUpgradeDeclinedModal from '@/components/Billing/PerformanceUpgradeDeclinedModal';
import celebration from 'images/billing/celebration.svg';
import { useUpgradeStatus } from '@/hooks';
import { parseDate } from '@/date';

const performanceNoticeKey = 'HeaderPerformanceWarningOct';
const OCTOBER_CAMPAIGN = new Date('2024-10-01T03:00:00.000Z');

export function useHeaderPerformanceWarningOct() {
  const { user } = useAuth();
  const isAdmin = user.admin;
  const { legacyPro, performanceFeatures, expiresAt } = user.account;

  const {
    upgradeStatus
  } = useUpgradeStatus(performanceNoticeKey);

  const meetsConditions = legacyPro &&
    parseDate(expiresAt) > OCTOBER_CAMPAIGN &&
    ((performanceFeatures && !(upgradeStatus.declined && upgradeStatus.user === user.id)));

  return isAdmin && meetsConditions;
}

function HeaderPerformanceWarningOct() {
  const { user } = useAuth();

  const [showDeclineUpgradeModal, setShowDeclineUpgradeModal] = useState(false);

  const upgradePath = settingsManageUpdatePlanPath({ new_plan_code: 'performance_plan' });

  const { legacyPro, performanceFeatures, expiresAt } = user.account;

  const navigate = useNavigate();

  const {
    handleUpgradeStatusChange,
    upgradeStatus
  } = useUpgradeStatus(performanceNoticeKey);

  const showUpgradeWarning = useMemo(
    () => legacyPro &&
        parseDate(expiresAt) > OCTOBER_CAMPAIGN &&
       ((performanceFeatures && !(upgradeStatus.declined && upgradeStatus.user === user.id))),
    [user, upgradeStatus]
  );

  const handleConfirmUpgrade = () => {
    navigate(upgradePath);
    setShowDeclineUpgradeModal(false);
  };

  if (!showUpgradeWarning) {
    return null;
  }

  return (
    <div className='d-flex ps-0 pb-0 pe-8 bg-light'>
      <img className='h-100 max-width-5' src={celebration} />
      <div className='p-7'>
        <div className='d-flex'>
          <div className='px-4'>
            <h4 className='mb-1 text-medium fw-bold'>Oba, o plano Performance chegou!</h4>
            <div className='text-dark-gray'>
              <div className='mb-2'>
                Algumas funcionalidades que você usa hoje serão <span className='fw-bold'>
                exclusivas</span> do Plano Performance: <span className='fw-bold'>
                Automações + Modelos de Propostas Ilimitados + Telefone Virtual Inteligente
                com ChatGPT + Qualificação Obrigatória de Negócios.</span>
              </div>
              <div>
                Aproveite até a o dia <span className='fw-bold'>
                01 de outubro</span> para fazer o upgrade e manter os recursos
                que já são essenciais para seu sucesso!
              </div>
            </div>
          </div>
          <div className='d-flex
            flex-column
            align-items-end
            justify-content-center ms-4
            text-nowrap'
          >
            <Button
              className='w-100 text-small py-1'
              href={upgradePath}
              size='sm'
            >
              Quero manter as funcionalidades
            </Button>
            <Button
              onClick={() => setShowDeclineUpgradeModal(true)}
              variant='white'
              className='mt-2 w-100 text-darker-gray text-small py-1'
              size='sm'
            >
              Perder essa oportunidade
            </Button>
          </div>
        </div>
      </div>
      <PerformanceUpgradeDeclinedModal
        show={showDeclineUpgradeModal}
        onHide={() => setShowDeclineUpgradeModal(false)}
        expirationDate='01/10/2024'
        onDecline={() => handleUpgradeStatusChange(true)}
        onConfirm={handleConfirmUpgrade}
      />
    </div>
  );
}

export default HeaderPerformanceWarningOct;
