import React, { useMemo } from 'react';
import pullAllBy from 'lodash/pullAllBy';
import PropTypes from 'prop-types';
import Select from '@/components/Inputs/Select';

export function defaultTransformer(fetchedData) {
  return (
    fetchedData.data.map((item) => (
      { label: item.name, value: item.id }
    ))
  );
}

const propTypes = {
  query: PropTypes.func.isRequired,
  queryArgs: PropTypes.object,
  transformer: PropTypes.func,
  defaultOptions: PropTypes.arrayOf(PropTypes.object),
  defaultQueryParams: PropTypes.object,
  isCreatable: PropTypes.bool,
  onCreateOption: PropTypes.func,
  error: PropTypes.string
};

const defaultProps = {
  queryArgs: {},
  transformer: defaultTransformer,
  defaultOptions: [],
  defaultQueryParams: {},
  isCreatable: false,
  onCreateOption: undefined,
  error: null
};

function FetchableSelect(props) {
  const {
    query,
    queryArgs: {
      params: queryParams = {},
      config: queryConfig = {},
      ...queryArgs
    },
    transformer,
    defaultOptions,
    defaultQueryParams,
    isLoading,
    isCreatable,
    onCreateOption,
    error,
    ...selectProps
  } = props;

  const { data, isSuccess } = query({
    ...queryArgs,
    config: queryConfig,
    params: { ...defaultQueryParams, ...queryParams }
  });

  const transformedOptions = useMemo(
    () => {
      const transformedData = transformer(data || { data: [] });
      const isNotGroup = (item) => !item.options;
      const pullOptions = defaultOptions.filter(isNotGroup);
      const filteredData = pullAllBy(transformedData, pullOptions, 'value');
      return defaultOptions.concat(filteredData);
    },
    [data]
  );

  return (
    <Select
      { ...selectProps }
      options={transformedOptions}
      isLoading={!isSuccess || isLoading}
      isCreatable={isCreatable}
      onCreateOption={onCreateOption}
      error={error}
    />
  );
}

FetchableSelect.propTypes = propTypes;
FetchableSelect.defaultProps = defaultProps;

export default FetchableSelect;
