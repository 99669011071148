import React from 'react';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import Modal from '@/components/Modal';
import error from 'images/billing/pessoa-triste.svg';

const propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  errorMessage: PropTypes.string
};

function PaymentFailureModal({ show, onHide, errors }) {
  const getErrorMessage = () => {
    const accountErrors = errors?.account || [];

    if (accountErrors.includes('min_users')) {
      return 'Não é possível contratar um plano inferior a quantidade de usuários ativos da conta.';
    } else {
      return 'Por favor, verifique se os dados do cartão estão corretos e se ele ainda é válido. ' +
       'Caso contrário, tente usar outro cartão de crédito ou outra forma de pagamento disponível.';
    }
  };

  return (
    <Modal
      size='md'
      show={show}
      onHide={onHide}
      bodyClassName='d-flex flex-column p-8 align-items-center text-center'
      backdrop='static'
    >
      <img src={error} className='mb-2 width-5 height-5' alt='Error' />
      <h2 className='text-center'>
        Infelizmente, não conseguimos processar seu pagamento
      </h2>
      <span className='mt-2 mb-5 text-dark-gray'>
        {getErrorMessage()}
      </span>
      <Button
        variant='light'
        className='mb-2 rounded text-small'
        onClick={onHide}
      >
        Fechar
      </Button>
    </Modal>
  );
}

PaymentFailureModal.propTypes = propTypes;

export default PaymentFailureModal;
