import React from 'react';
import PropTypes from 'prop-types';
import Base from 'react-bootstrap/Card';

const propTypes = {
  children: PropTypes.node
};

function Info({ children }) {
  return (
    <Base.Text as='div' className='mt-1 text-small'>
      { children }
    </Base.Text>
  );
}

Info.propTypes = propTypes;
Info.displayName = 'Card.Info';

export default Info;
