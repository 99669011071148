import React from 'react';
import classnames from 'classnames';
import fullPageGif from 'images/full-page-loading.gif';

function FullPageLoading() {
  return (
    <div className={classnames(
      'd-flex',
      'flex-column',
      'container-full-height',
      'justify-content-center',
      'align-items-center'
    )}>
      <div className='mt-auto'>
        <img className='width-4 height-4' src={fullPageGif} />
      </div>

      <h4 className='mt-auto mb-9'>
        Carregando...
      </h4>
    </div>
  );
}

export default FullPageLoading;
