import React, { useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { distanceFromNowInCalendarDays, parseDate, toTextualMonthDateString } from '@/date';
import { legacyUsersUrl, settingsManageCurrentPlanPath } from '@/routes';
import { pluralize } from '@/utils';
import { useUpdateSubscriptionContext } from '@/contexts';
import PerformanceUpgradeDeclinedModal from '@/components/Billing/PerformanceUpgradeDeclinedModal';

import Alert from '@/components/Alert';
import CurrentSubscriptionPreview from '@/components/Billing/CurrentSubscriptionPreview';
import ExternalLink from '@/components/ExternalLink';
import Form from '@/components/Form';
import ManagePlanFooter from '@/components/Billing/ManagePlanFooter';
import { TextInput } from '@/components/Inputs';
import UpdatePlanFields from '@/components/Billing/UpdatePlanFields';
import UpdatedSubscriptionPreview from '@/components/Billing/UpdatedSubscriptionPreview';
import { useUpgradeStatus } from '@/hooks';
import { useHeaderPerformanceWarningOct } from '@/components/HeaderPerformanceWarningOct';

const schema = Yup.object().shape({
  users_count: Yup.number()
    .required('Número de usuários é obrigatório.')
    .positive('A quantidade mínima de usuários é 1.'),
  plan_select: Yup.string()
    .required('Por favor, selecione um plano.')
    .oneOf(['pro_plan', 'performance_plan', 'corporate_plan'], '')
});

const periodicityText = {
  monthly: 'mensal',
  quarterly: 'trimestral',
  half_yearly: 'semestral',
  yearly: 'anual'
};

function PreviewProratedPlanStep() {
  const {
    currentPlanData,
    selectedMaxUsers,
    selectedPlan,
    nextStep,
    updatedSubscriptionPreview
  } = useUpdateSubscriptionContext();

  const navigate = useNavigate();

  const remainingDays = distanceFromNowInCalendarDays(currentPlanData.expirationDate);

  const renovationDate = remainingDays === 0
    ? 'Hoje'
    : toTextualMonthDateString(parseDate(currentPlanData.expirationDate));

  const actualMaxUsers =
    `${currentPlanData.maxUsersCount} ${pluralize('usuário', currentPlanData.maxUsersCount)}`;

  const isDifferentPlan = selectedPlan !== currentPlanData.productCode;
  const isDifferentMaxUsers = selectedMaxUsers !== currentPlanData.maxUsersCount;
  const isUpgradeOrDowngrade = isDifferentPlan || isDifferentMaxUsers;

  const needReduceUsers = selectedMaxUsers > 0 &&
  currentPlanData.currentActiveUsersCount > selectedMaxUsers;

  const diffUsersDowngrade = currentPlanData.currentActiveUsersCount - selectedMaxUsers;

  const pluralizedActiveUsersText = `
   ${pluralize('usuário', selectedMaxUsers)} ${pluralize('ativo', selectedMaxUsers)}.
  `;

  const initialValues = useMemo(() => ({
    users_count: selectedMaxUsers,
    plan_select: selectedPlan
  }), [selectedMaxUsers, selectedPlan]);

  const {
    handleUpgradeStatusChange
  } = useUpgradeStatus('HeaderPerformanceWarningOct');

  const [showDeclineUpgradeModal, setShowDeclineUpgradeModal] = useState(false);

  const shouldShowPerformanceNotice = useHeaderPerformanceWarningOct();

  const handlePrevClick = () => {
    if (shouldShowPerformanceNotice) {
      setShowDeclineUpgradeModal(true);
    } else {
      navigate(settingsManageCurrentPlanPath());
    }
  };

  return (
    <>
      <PerformanceUpgradeDeclinedModal
        show={showDeclineUpgradeModal}
        onHide={() => setShowDeclineUpgradeModal(false)}
        expirationDate='1 de outubro'
        onDecline={() => {
          handleUpgradeStatusChange(true);
          window.location.reload();
        }}
        onConfirm={() => setShowDeclineUpgradeModal(false)}
      />
      <Form
        name='form'
        validationSchema={schema}
        initialValues={initialValues}
        initialSubmit
      >
        {({ isValid }) => (
          <>
            <Row>
              <Col sm={5} className='pe-0'>
                <span className='fw-bold'>PLANO ATUAL</span>
                <TextInput
                  name='actual_plan'
                  value={currentPlanData.name}
                  className='my-3'
                  disabled
                />
                <TextInput
                  name='actual_max_users'
                  value={actualMaxUsers}
                  disabled
                />
              </Col>

              <Col
                sm={1}
                className='d-flex justify-content-center align-items-center text-primary'
              >
                Para
              </Col>

              <Col sm={6} className='ps-0'>
                <UpdatePlanFields />
              </Col>
            </Row>
            <ManagePlanFooter
              buttonNextText='Avançar'
              onClickPrev={handlePrevClick}
              onClickNext={nextStep}
              disabled={!isValid || !isUpgradeOrDowngrade || needReduceUsers}
            />
          </>
        )}
      </Form>
      {isUpgradeOrDowngrade && (
        <>
          <Row>
            <Col sm={6} className='pe-0 fw-bold text-small'>
              <CurrentSubscriptionPreview
                periodicityText={periodicityText[currentPlanData.periodicity]}
                renovationDate={renovationDate}
              />
            </Col>
            <Col sm={6} className='ps-0 fw-bold text-small'>
              <UpdatedSubscriptionPreview
                periodicityText={periodicityText[currentPlanData.periodicity]}
                renovationDate={renovationDate}
              />
            </Col>
          </Row>

          {needReduceUsers && (
            <Alert variant='warning' className='text-warning mt-auto'>
              Para reduzir seu plano para o {updatedSubscriptionPreview?.name} {selectedMaxUsers},
              é necessário ter no máximo {selectedMaxUsers} {pluralizedActiveUsersText}
              <b>Sua conta possui {currentPlanData.currentActiveUsersCount} usuários ativos. </b>
              Para desativar {diffUsersDowngrade} deles{' '}
              <ExternalLink
                variant='link'
                target={'_blank'}
                href={legacyUsersUrl()}
                className='p-0 text-warning text-small'
              >
                <u>clique aqui</u>
              </ExternalLink>.
            </Alert>
          )}
        </>
      )}
    </>
  );
}

export default PreviewProratedPlanStep;
