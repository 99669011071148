import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@/components/Icon';
import classnames from 'classnames';
import Tooltip from '@/components/Tooltip';
import { parseDate, toDateString } from '@/date';
import { toCurrencyString } from '@/number';

const VARIANTS = {
  none: {
    secondaryIcon: null,
    getTooltipText: () => ''
  },
  ongoing: {
    secondaryIcon: null,
    getTooltipText:
      (date, funnel, value) => renderTooltipContent('Negócio em andamento', { date, funnel, value })
  },
  won: {
    secondaryIcon: {
      name: 'won-deal',
      color: 'body',
      variant: 'flat-green'
    },
    getTooltipText:
      (date, funnel, value) => renderTooltipContent('Negócio ganho', { date, funnel, value })
  },
  lost: {
    secondaryIcon: {
      name: 'lost-deal',
      color: 'white',
      variant: 'danger'
    },
    getTooltipText:
      (date, funnel, value) => renderTooltipContent('Negócio perdido', { date, funnel, value })
  },
  frozen: {
    secondaryIcon: {
      name: 'frozen',
      color: 'white',
      variant: 'primary'
    },
    getTooltipText:
      (date, funnel, value) => renderTooltipContent('Negócio congelado', { date, funnel, value })
  }
};

const propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['organization', 'person', 'deal', 'product']).isRequired,
  data: PropTypes.shape({
    finishedAt: PropTypes.string,
    value: PropTypes.number,
    freezing: PropTypes.shape({
      active: PropTypes.bool,
      createdAt: PropTypes.string
    }),
    dealStatus: PropTypes.shape({
      key: PropTypes.string.isRequired
    })
  }),
  light: PropTypes.bool
};

const defaultProps = {
  className: null,
  data: {}
};

function EntityIcon({ className, type, data, light }) {
  const {
    finishedAt, freezing, value, dealStatus: { key: status } = {}
  } = data;
  const { active: frozen, createdAt: frozenAt } = freezing || {};

  const variantKey = frozen && status === 'ongoing' ? 'frozen' : status || 'none';

  const {
    secondaryIcon,
    getTooltipText
  } = VARIANTS[variantKey];

  const statusDateString = frozen ? frozenAt : finishedAt;
  const statusDate = statusDateString && parseDate(statusDateString);
  const valueFormatted = value && toCurrencyString(value);
  const funnel = data?.stage?.funnel?.name;
  const tooltipText = getTooltipText(statusDate, funnel, valueFormatted);

  return (
    <Tooltip
      placement='top'
      content={tooltipText}
      hide={!tooltipText}
      className='pe-none'
    >
      <div className={classnames(className, 'd-inline-block', 'p-1')}>
        <div className={classnames(
          'p-1',
          'rounded',
          'bg-opacity-10',
          'lh-0',
          'position-relative',
          {
            'bg-primary': !light,
            'text-dark-gray': !light,
            'text-medium-gray': light
          }
        )}>
          <Icon name={type} />

          { secondaryIcon &&
            <div
              className={classnames(
                'position-absolute',
                'top-50',
                'start-50',
                'p-0',
                'rounded-circle',
                `bg-${secondaryIcon.variant}`,
                'border',
                `border-${secondaryIcon.variant}`
              )}
            >
              <Icon
                className={`text-${secondaryIcon.color}`}
                name={secondaryIcon.name}
                size='sm'
              />
            </div>
          }
        </div>
      </div>
    </Tooltip>
  );
}

function renderTooltipContent(title, { date, funnel, value }) {
  return (
    <>
      {appendFormattedDate(title, date)}
      <br />
      {funnelInfo(funnel)}
      {formatValue(value)}
    </>
  );
}

function formatValue(value) {
  return `Valor: ${value || 'Indefinido'}`;
}

function funnelInfo(funnel) {
  return funnel && (<React.Fragment>{`Funil: ${funnel}`}<br /></React.Fragment>);
}

function appendFormattedDate(string, date) {
  if (date) {
    return `${string} em ${toDateString(date)}`;
  } else {
    return string;
  }
}

EntityIcon.propTypes = propTypes;
EntityIcon.defaultProps = defaultProps;

export default EntityIcon;
