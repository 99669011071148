import { useQuery, useMutation, useQueryClient } from 'react-query';
import { get, post, patch, _delete } from '@/lib/fetch';

export const peopleCustomFieldsKeys = {
  all: ['people-custom-fields']
};

export async function getPeopleCustomFields() {
  const { data } = await get('/people/custom_fields');

  return data;
}

export function usePeopleCustomFields({ config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: peopleCustomFieldsKeys.all,
    queryFn: () => getPeopleCustomFields()
  });
}

export async function createPeopleCustomField(params = {}) {
  const { data } = await post('/people/custom_fields', params);

  return data;
}

export function useCreatePeopleCustomField({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await queryClient.invalidateQueries(peopleCustomFieldsKeys.all);
  };

  return useMutation(
    createPeopleCustomField,
    {
      onSuccess,
      ...config
    }
  );
}

export async function updatePeopleCustomField({ customFieldId, params = {} }) {
  const { data } = await patch(`/people/custom_fields/${customFieldId}`, params);

  return data;
}

export function useUpdatePeopleCustomField({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await queryClient.invalidateQueries(peopleCustomFieldsKeys.all);
  };

  return useMutation(
    updatePeopleCustomField,
    {
      onSuccess,
      ...config
    }
  );
}

export async function deletePeopleCustomField({ customFieldId }) {
  const { data } = await _delete(`/people/custom_fields/${customFieldId}`);

  return data;
}

export function useDeletePeopleCustomField({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await queryClient.invalidateQueries(peopleCustomFieldsKeys.all);
  };

  return useMutation(
    deletePeopleCustomField,
    {
      onSuccess,
      ...config
    }
  );
}
