import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useFormikContext } from 'formik';
import pick from 'lodash/pick';
import Popover from '@/components/Popover';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Tooltip from '@/components/Tooltip';
import { PopoverProvider } from '@/contexts';
import { objectHasValue } from '@/utils';

const propTypes = {
  children: PropTypes.node.isRequired,
  defaultValues: PropTypes.object.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['light', 'white']),
  label: PropTypes.string,
  onFilter: PropTypes.func
};

const defaultProps = {
  className: '',
  variant: 'light',
  label: 'Mostrar filtros'
};

function FieldsPopover({ children, defaultValues, variant, label, className, onFilter }) {
  const container = useRef(null);
  const { values, submitForm, resetForm } = useFormikContext();

  const isDirty = useMemo(() => {
    const { custom_fields: _customFields, ...defaultFilters } = defaultValues;
    const popoverValues = pick(values, Object.keys(defaultFilters));
    const defaultPropertiesDirty = Object.entries(popoverValues).some(([key, value]) => (
      defaultValues[key] !== value
    ));
    if (defaultPropertiesDirty) {
      return true;
    }

    return objectHasValue(values.custom_fields);
  }, [defaultValues, values]);

  const popoverOnClose = (event) => {
    event.stopPropagation();

    resetForm({ values: { ...values, ...defaultValues } });
    submitForm();
  };

  return (
    <Popover
      className={className}
      content={
        ({ onHide }) => (
          <PopoverProvider value={{ container }}>
            <div ref={container} className='py-2' >
              <h4>Filtrar</h4>

              <div className={classnames(
                'filter-list-content-max-height',
                'min-height-4',
                'overflow-y-scroll',
                'mt-4'
              )}>
                {children}
              </div>

              <Button
                className='w-100 mt-4'
                onClick={() => {
                  submitForm();
                  onFilter?.(values);
                  onHide();
                }}
              >
                Filtrar
              </Button>
            </div>
          </PopoverProvider>
        )
      }
      trigger={['click']}
      placement='right-start'
      rootClose
      rootCloseEvent='mousedown'
      flip
      closeButton
    >
      <div className='position-relative ms-2'>
        <Tooltip content='Filtrar os resultados' placement='top'>
          <Button
            className={classnames(
              'px-2',
              'mb-0',
              'fields-popover-button',
              'text-darker-gray',
              {
                'bg-white': variant === 'white'
              }
            )}
            variant={variant}
            aria-label={label}
          >
            <Icon size='sm' name='filter' />
            <span className='ms-1'>Filtros</span>
          </Button>
        </Tooltip>

        {
          isDirty &&
            <Tooltip content='Limpar filtros' placement='top'>
              <Button
                className={
                  'position-absolute bottom-0 end-0 p-0 border-0 mb-n2 me-n2'
                }
                variant={null}
                onClick={popoverOnClose}
                aria-label='Limpar filtros'
              >
                <Icon
                  className={classnames(
                    'text-white',
                    'bg-primary',
                    'rounded',
                    'border',
                    'border-white'
                  )}
                  name='close'
                />
              </Button>
            </Tooltip>
        }
      </div>
    </Popover>
  );
}

FieldsPopover.propTypes = propTypes;
FieldsPopover.defaultProps = defaultProps;

export default FieldsPopover;
