import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { useScreenSize } from '@/hooks';

import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';

import automations from 'images/billing/automations.svg';
import proposalModels from 'images/billing/proposal-models.svg';
import requiredFields from 'images/billing/required-fields.svg';
import voipGpt from 'images/billing/voip-gpt.svg';
import logo from 'images/logo-typography.svg';

const propTypes = {
  expirationDate: PropTypes.string.isRequired,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onDecline: PropTypes.func,
  onConfirm: PropTypes.func,
  isLoading: PropTypes.bool
};

const defaultProps = {
  show: false,
  onHide: () => {},
  onDecline: () => {},
  onConfirm: () => {},
  isLoading: false
};

const PERFORMANCE_FEATURES = [
  {
    feature: 'Qualificação Obrigatória',
    description: 'Avance no funil de vendas com dados completos.',
    image: requiredFields
  },
  {
    feature: 'Automações',
    description: 'Simplifique tarefas repetitivas e foque no que realmente importa.',
    image: automations
  },
  {
    feature: 'Modelos de Proposta',
    description: 'Crie propostas personalizadas em minutos.',
    image: proposalModels
  },
  {
    feature: 'Telefone Virtual Inteligente',
    description: 'Transcrição e resumo automático de chamadas com ChatGPT',
    image: voipGpt
  }
];

const BannerItem = ({ item, index, screenSize }) => (
  <Card
    key={index}
    className={classnames(
      'p-7',
      'mx-2',
      'border-0',
      'w-100',
      `${index % 2 === 0 ? 'bg-light-blue' : 'bg-light-green'}`
    )}
  >
    <div className='d-flex flex-column justify-content-between h-100'>
      <div className='d-flex align-self-center h-100'>
        <img src={item.image} className='w-100 max-height-100'/>
      </div>
      <div className='pb-8'>
        <div className={classnames(
          'd-flex',
          'flex-column',
          'justify-content-between',
          'pt-4',
          `${screenSize.isLG ? 'height-4' : 'height-3'}`
        )}>
          <h4>{item.feature}</h4>
          <div className='mt-1 text-dark-gray'>{item.description}</div>
        </div>
      </div>
    </div>
  </Card>
);

function PerformanceUpgradeDeclinedModal(props) {
  const { show, onHide, expirationDate, onDecline, onConfirm, isLoading } = props;
  const screenSize = useScreenSize();
  const modalSize = screenSize.isLG ? 'xxl' : 'xxxl';

  return (
    <Modal
      size={modalSize}
      show={show}
      onHide={onHide}
      bodyClassName='d-flex flex-column p-8 align-items-center text-center'
      backdrop='static'
    >
      <div className={classnames(`${screenSize.isLG ? 'px-0' : 'px-8'}`, 'w-100')}>
        <img src={logo} className='d-flex align-self-start ms-2 mb-5'/>
        <h1 className='text-center mb-4'>
          Atenção, você vai perder funcionalidades importantes
        </h1>

        <Card className='py-3 mb-5 mx-2 border-0 bg-light text-dark-gray'>
          <span>
            Se você não fizer o upgrade para o Plano Performance até a
            sua próxima renovação em <span className='fw-bold'>{expirationDate}</span>,
          </span>
          <span>
            perderá o acesso a funcionalidades que são fundamentais para o sucesso das suas vendas.
          </span>
        </Card>

        <div className='d-flex'>
          {PERFORMANCE_FEATURES.map((item, index) => (
            <BannerItem key={index} item={item} index={index} screenSize={screenSize} />
          ))}
        </div>

        <div className='d-flex mt-6 mx-2 justify-content-between'>
          <span className='text-small text-dark-gray text-start'>
            Clicando em Fazer upgrade, você autoriza a migração para o Plano Performance
            por R$ 75 <br />por usuário/mês e mantém o sistema funcionando da forma que está hoje.
          </span>
          <div>
            <Button
              variant='light'
              className='mb-2 me-2'
              onClick={onDecline}
              disabled={isLoading}
            >
              Continuar sem upgrade
            </Button>

            <Button
              className='mb-2'
              onClick={onConfirm}
              disabled={isLoading}
            >
              Fazer upgrade
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

PerformanceUpgradeDeclinedModal.propTypes = propTypes;
PerformanceUpgradeDeclinedModal.defaultProps = defaultProps;

export default PerformanceUpgradeDeclinedModal;
