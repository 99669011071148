import React from 'react';

export const Body = 'p';

export { default as ConcurrentSessionError } from './ConcurrentSessionError';

import Stack from 'react-bootstrap/Stack';
export const Container = (props) => <Stack gap={4} {...props} />;

export { default as Content } from './Content';

import unauthorizedImage from 'images/unauthorized.svg';
export const Illustration = () => <img src={unauthorizedImage} className='img-fluid mx-auto' />;

export const Title = 'h2';
