import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as Yup from 'yup';
import FormField from '@/components/FormField';
import Form from '@/components/Form';
import { TextInput } from '@/components/Inputs';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Authorization from '@/components/Authorization';
import InputAdornment from '@/components/InputAdornment';
import { canEditFunnel } from '@/policies';
import { toDigitsString } from '@/number';
import { settingsFunnelStagesPath } from '@/routes';

const propTypes = {
  funnel: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    initials: PropTypes.string.isRequired
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
  innerRef: PropTypes.any,
  innerProps: PropTypes.object,
  dragProps: PropTypes.object,
  isDragging: PropTypes.bool,
  funnelDelete: PropTypes.node
};

const defaultProps = {
  className: '',
  innerRef: null,
  innerProps: {},
  dragProps: {},
  isDragging: false
};

/* eslint-disable no-magic-numbers */
const schema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(30, 'Nome do funil deve ter no máximo 30 caracteres.')
    .required('Nome do funil é obrigatório.'),
  initials: Yup.string()
    .trim()
    .length(3, 'Sigla do funil deve conter 3 caracteres.')
    .required('Sigla do funil é obrigatória.')
});
/* eslint-enable no-magic-numbers */

function EditFunnelForm(props) {
  const {
    onSubmit, className, funnel, innerRef, innerProps, dragProps, isDragging,
    funnelDelete
  } = props;

  return (
    <Form
      className={className}
      defaultValues={funnel}
      onSubmit={onSubmit}
      name={`edit-funnel-form-${funnel.id}`}
      validationSchema={schema}
      submitOnDefaultChange={false}
    >
      {({ submitForm }) => (
        <div
          className={classnames(
            'd-flex',
            'align-items-start',
            'hover-parent',
            'hover-bg-light',
            'border-1',
            'border-bottom',
            { 'bg-light': isDragging }
          )}
          ref={innerRef}
          { ...innerProps}
        >
          <div className='width-3 px-3 py-2 ms-2 text-dark-gray'>
            <div className={classnames(
              'py-2',
              'align-self-center',
              'd-flex',
              'align-items-center',
              'justify-content-between'
            )}>
              <span
                className='draggable'
                { ...dragProps}
              >
                <Icon
                  name='drag'
                  className='hover-child-text-body'
                  withStroke
                />
              </span>

              <div className='ms-auto'>
                {toDigitsString(funnel.order, 2)}
              </div>
            </div>
          </div>
          <div className='width-5 px-3 py-2'>
            <Authorization policy={canEditFunnel}>
              <FormField
                as={TextInput}
                name='name'
                className='mb-0'
                placeholder='Nome do funil'
                autoComplete='off'
                variant='transparent'
                onBlur={submitForm}
                rightAdornment={({ focus }) => (
                  <InputAdornment
                    alignment='right'
                    className='input-edit invisible'
                    onClick={focus}
                  >
                    <Icon className='text-primary' name='edit-field' />
                  </InputAdornment>
                )}
              />
            </Authorization>
          </div>
          <div className='width-4 px-3 py-2'>
            <Authorization policy={canEditFunnel}>
              <FormField
                as={TextInput}
                name='initials'
                className='mb-0'
                placeholder='ex: VEN'
                autoComplete='off'
                variant='transparent'
                normalize={(value) => (value || '').toUpperCase()}
                onBlur={submitForm}
                rightAdornment={({ focus }) => (
                  <InputAdornment
                    alignment='right'
                    className='input-edit invisible'
                    onClick={focus}
                  >
                    <Icon className='text-primary' name='edit-field' />
                  </InputAdornment>
                )}
              />
            </Authorization>
          </div>
          <div className='px-3 py-2'>
            <Button
              variant='transparent-light'
              className='py-2 px-4 border-0'
              href={settingsFunnelStagesPath(funnel.id)}
            >
              <Icon name='funnel-stage-add' className='me-2' />

              <span>Editar etapas</span>
            </Button>
          </div>
          <div className='px-3 py-2'>
            {funnelDelete}
          </div>
        </div>
      )}
    </Form>
  );
}

EditFunnelForm.propTypes = propTypes;
EditFunnelForm.defaultProps = defaultProps;

export default EditFunnelForm;
