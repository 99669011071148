import React from 'react';
import { Navigate } from 'react-router-dom';
import { isNotFoundError } from '@/errors';
import { useLastViewedFunnel } from '@/hooks';
import { useDefaultFunnel, useFunnel } from '@/api';

function LastViewedFunnel() {
  const { id: lastViewedFunnelId } = useLastViewedFunnel();
  const hasLastViewedFunnel = Boolean(lastViewedFunnelId);

  const { error, isFetchedAfterMount } = useFunnel({
    funnelId: lastViewedFunnelId,
    config: { enabled: hasLastViewedFunnel }
  });

  const isNotFound = error && isNotFoundError(error);
  const fetchedAndNotFound = isFetchedAfterMount && isNotFound;
  const isDefaultFunnel = !hasLastViewedFunnel || fetchedAndNotFound;

  const {
    isLoading,
    data: { data = {} } = {}
  } = useDefaultFunnel({ config: { enabled: !lastViewedFunnelId } });

  const { id: defaultFunnelId } = data;
  const funnelPath = `/funnels/${isDefaultFunnel ? defaultFunnelId : lastViewedFunnelId}`;

  if ((hasLastViewedFunnel && !isFetchedAfterMount) || isLoading) {
    return <div className='flex-grow-1 bg-light' />;
  }

  return <Navigate to={funnelPath} replace />;
}

export default LastViewedFunnel;
