import { useQuery } from 'react-query';
import { get } from '@/lib/fetch';

export const emailRecipientsKeys = {
  all: ['email-recipients'],
  list: (params) => [...emailRecipientsKeys.all, params]
};

export async function getEmailRecipients(params = {}) {
  const { data } = await get('/email_recipients', params);

  return data;
}


export function useEmailRecipients({ params = {}, config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: emailRecipientsKeys.list(params),
    queryFn: () => getEmailRecipients(params)
  });
}
