import { useMutation, useQuery, useQueryClient } from 'react-query';
import { accessoryCallCache } from '@/utils';
import { get, patch } from '@/lib/fetch';

export const usersKeys = {
  all: ['users'],
  lists: () => [...usersKeys.all, 'list'],
  list: (params) => [...usersKeys.lists(), params],
  currentUser: ['current-user']
};

export async function getUsers(params = {}) {
  const { data } = await get('/users', params);

  return data;
}

export async function getCurrentUser() {
  const { data } = await get('/users/me');

  return data;
}

export async function getAccountAdmins() {
  const { data } = await get('/users/admins');

  return data;
}

export async function updateProfile({ params }) {
  const { data } = await patch('/profile', params);

  return data;
}

export function useUsers({ params = {}, config = {} } = {}) {
  return useQuery({
    ...accessoryCallCache,
    ...config,
    queryKey: usersKeys.list(params),
    queryFn: () => getUsers(params)
  });
}

export function useCurrentUser({ config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: usersKeys.currentUser,
    queryFn: () => getCurrentUser()
  });
}

export function useUpdateProfile({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = (data, variables, context) => {
    config.onSuccess?.(data, variables, context);

    /**
     *
     * Aqui foi trocado `invalidateQueries` por `setQueryData` porque a
     * resposta dessa requisição é a mesma da `useCurrentUser`, e já pode ser
     * usada para atualizar o usuário, sem necessidade de fazer uma nova
     * requisição.
     *
     */
    return queryClient.setQueryData(usersKeys.currentUser, data);
  };

  return useMutation(
    updateProfile,
    {
      ...config,
      onSuccess
    }
  );
}

export function useAccountAdmins({ config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: usersKeys.all,
    queryFn: () => getAccountAdmins()
  });
}
