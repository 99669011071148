import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '@/components/Icon';

export function Header(props) {
  const { children } = props;

  return (
    <div className='text-center mb-4'>
      {children}
    </div>
  );
}

Header.propTypes = {
  children: PropTypes.node.isRequired
};

export function Illustration(props) {
  const { addon } = props;

  return (
    <div className={classnames(
      'd-inline-block',
      'p-3',
      'rounded-circle',
      'bg-dark-gray',
      'text-white',
      'lh-0',
      'position-relative'
    )}>
      <Icon name='deal' size='lg' />

      <div
        className={classnames(
          'position-absolute',
          'end-0',
          'bottom-0',
          'p-0',
          'rounded-circle',
          'bg-primary',
          'border',
          'border-primary'
        )}
      >
        <Icon name={addon} size='sm' />
      </div>
    </div>
  );
}

Illustration.propTypes = {
  addon: PropTypes.oneOf(['plus', 'duplication']).isRequired
};

export function Title(props) {
  const { children } = props;

  return (
    <h2 className='mt-4'>
      {children}
    </h2>
  );
}

Title.propTypes = {
  children: PropTypes.node.isRequired
};

export function Subtitle(props) {
  const { children } = props;

  return (
    <p className='mt-2 mb-6'>
      {children}
    </p>
  );
}

Subtitle.propTypes = {
  children: PropTypes.node.isRequired
};

export default Header;
