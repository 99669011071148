import React from 'react';
import { useParams, useLocation, Navigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useAlert } from 'react-alert';
import APIErrorMessage from '@/components/APIErrorMessage';
import { useUpdateRequiredField } from '@/api';
import { settingsFunnelStagesPath } from '@/routes';
import RequiredFieldForm from '@/components/RequiredFieldForm';
import { goBack } from '@/browser';
import { useTracking } from '@/lib/tracking';
import { useAuth } from '@/lib/auth';

/* eslint-disable no-magic-numbers */
const validationSchema = Yup.object().shape({
  question: Yup.string().max(150, 'Pergunta deve ter no máximo 150 caracteres.')
});
/* eslint-enable no-magic-numbers */

function FunnelStagesRequiredFieldsEdit() {
  const { funnelId } = useParams();
  const location = useLocation();
  const data = location.state?.data;
  const target = location.state?.target;

  const alert = useAlert();
  const tracker = useTracking();
  const { user } = useAuth();
  const updateRequiredField = useUpdateRequiredField();
  const onSubmit = ({ ...formData }) => (
    updateRequiredField.mutateAsync({ id: data.id, params: formData })
      .then((response) => {
        alert.show(
          'Pergunta alterada com sucesso.',
          { variant: 'success', timeout: 5000 }
        );
        tracker.trackRequiredFieldUpdated({
          user,
          funnelId,
          target,
          field: response.data,
          formData
        });

        goBack();
      })
      .catch((err) => {
        alert.show(
          <APIErrorMessage
            err={err}
            resource='required_field'
            action='update'
          />,
          { variant: 'danger' }
        );
      })
  );

  if (!data) {
    return <Navigate to={settingsFunnelStagesPath(funnelId)} />;
  }

  return (
    <RequiredFieldForm
      validationSchema={validationSchema}
      initialValues={getInitialValues(data)}
      onSubmit={onSubmit}
      target={target}
      disabledField
    />
  );
}

function getInitialValues(data) {
  const id = data.customField?.id ?? data.defaultField;

  return {
    field: `${data.entity}.${id}`,
    question: data.question ?? ''
  };
}

export default FunnelStagesRequiredFieldsEdit;
