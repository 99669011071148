import { useQuery } from 'react-query';
import { get } from '@/lib/fetch';

export const customerKeys = {
  details: (id, params) => ['customer', id, params]
};

export async function getCustomer(customerId, params = {}) {
  const { data } = await get(`/customers/${customerId}`, params);

  return data;
}

export function useCustomer({ customerId, config = {}, params }) {
  return useQuery({
    ...config,
    queryFn: () => getCustomer(customerId, params),
    queryKey: customerKeys.details(customerId, params)
  });
}
