import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useMediaQuery } from '@/hooks';
import Button from '@/components/Button';
import Icon from '@/components/Icon';

const propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  bodyText: PropTypes.string,
  buttonText: PropTypes.string,
  buttonProps: PropTypes.shape({
    as: PropTypes.elementType,
    href: PropTypes.string,
    onClick: PropTypes.func,
    target: PropTypes.string
  }),
  className: PropTypes.string,
  icon: PropTypes.string
};

function ImportGuideCard({ image, title, bodyText, buttonProps, buttonText, className, icon }) {
  const xlLower = useMediaQuery('(max-width: 1200px)');
  return (
    <div
      className={
        classnames(
          'd-flex flex-column flex-1 py-3 ps-3 pe-5 align-items-start rounded',
          className
        )
      }
    >
      <img className={xlLower ? 'height-3' : 'height-4'} src={image} />
      <div className='flex-fill'>
        <h4 className='mt-3 text-darker-gray'>{title}</h4>
        <div className='my-2'>{bodyText}</div>
      </div>
      <Button
        variant='outline-dark-gray'
        size='sm'
        className='mb-1'
        { ...buttonProps }
      >
        <Icon name={icon} className='me-1'/>
        <span>{buttonText}</span>
      </Button>
    </div>
  );
}

ImportGuideCard.propTypes = propTypes;

export default ImportGuideCard;
