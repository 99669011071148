import React from 'react';
import FormField from '@/components/FormField';
import Icon from '@/components/Icon';
import Tooltip from '@/components/Tooltip';
import { Checkbox } from '@/components/Inputs';
import {
  queryStringKeys as dealQueryStringKeys
} from '@/components/Columns/DealColumns';
import {
  queryStringKeys as organizationQueryStringKeys
} from '@/components/Columns/OrganizationColumns';
import {
  queryStringKeys as personQueryStringKeys
} from '@/components/Columns/PersonColumns';

export function renderColumnsFiltersFrom(columns = []) {
  return columns.map((column) => (
    column.name !== 'name' &&
      <FormField
        as={Checkbox}
        key={column.name}
        name={column.name}
        label={
          <div className='d-flex align-items-stretch'>
            <span>{column.humanizedName}</span>

            {column.filter &&
              <Tooltip content='Esta coluna é filtrável'>
                <div className='cursor-pointer'>
                  <Icon className='ms-1' name='filter' size='sm' />
                </div>
              </Tooltip>
            }
          </div>
        }
      />
  ));
}

export function buildQueryStringKeysFrom(columns = []) {
  return columns
    .filter((column) => column.queryString)
    .reduce(
      (result, column) => ({ ...result, ...column.queryString }),
      {
        q: {
          filterKey: 'q',
          set: (value) => value,
          concat: (oldValue, newValue) => `${oldValue} ${newValue}`
        }
      }
    );
}

export function buildTableDefaultValuesFrom(columns = []) {
  return columns
    .filter((column) => column.filter)
    .reduce((result, column) => {
      result[column.filter.name] = column.filter.defaultValue;

      return result;
    }, {});
}

export function buildColumnsDefaultValuesFrom(columns = []) {
  return columns.reduce((result, column) => {
    result[column.name] = column.default;

    return result;
  }, {});
}

export function getFilterFromQueryString(type, queryParams = []) {
  const entityQueryStringKeys = {
    deal: dealQueryStringKeys,
    person: personQueryStringKeys,
    organization: organizationQueryStringKeys
  };

  const queryStringKeys = entityQueryStringKeys[type];

  return transformQueryStringToFilter(queryParams, queryStringKeys);
}

export function getColumnsFromQueryString(type, queryParams = []) {
  const entityQueryStringKeys = {
    deal: dealQueryStringKeys,
    person: personQueryStringKeys,
    organization: organizationQueryStringKeys
  };

  const queryStringKeys = entityQueryStringKeys[type];

  const columns = {};

  if (queryParams?.length) {
    queryParams.forEach(([id]) => {
      const queryStringKey = queryStringKeys[id];
      if (queryStringKey) {
        const { columnName } = queryStringKey;
        columns[columnName] = true;
      }
    });
  }

  return columns;
}

export function transformQueryStringToFilter(queryParams, queryStringKeys) {
  if (queryParams?.length) {
    const output = queryParams.reduce((query, item) => {
      const [id, value] = item;
      const queryStringKey = queryStringKeys[id];
      if (queryStringKey) {
        const { filterKey, set, concat } = queryStringKey;
        if (query[filterKey]) {
          query[filterKey] = concat(query[filterKey], set(value));
        } else {
          query[filterKey] = set(value);
        }
      }
      return query;
    }, {});
    return output;
  }

  return {};
}
