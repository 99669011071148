import React, { useState } from 'react';
import DetailsCard from '@/components/EntityModal/DetailsCard';
import Icon from '@/components/Icon';
import PropTypes from 'prop-types';
import Tooltip from '@/components/Tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Skeleton from '@/components/Skeleton';
import { TextInput } from '@/components/Inputs';
import Button from '@/components/Button';
import ExternalLink from '@/components/ExternalLink';
import { helpBlogUrl } from '@/routes';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';

DetailsCode.defaultProps = {
  deal: {},
  loading: false
};

DetailsCode.propTypes = {
  deal: PropTypes.object,
  loading: PropTypes.bool
};

const TEXT_TOOLTIP =
  'Use este endereço de e-mail para gravar suas mensagens neste histórico automaticamente';

function DetailsCode({ deal, loading }) {
  const DEFAULT_STATE = 'Copiar';
  const tracker = useTracking();
  const { user } = useAuth();

  const [tooltipContent, setTooltipContent] = useState(DEFAULT_STATE);

  const handleOnCopy = () => {
    setTooltipContent('Copiado!');
    tracker.trackDealEmailCopied({ user });
  };

  const handleOnExited = () => {
    setTooltipContent(DEFAULT_STATE);
  };

  if (loading || !deal?.dropboxEmail) {
    return <Skeleton width='100%' height={200} />;
  }

  return (
    <DetailsCard
      actionButton={
        <Tooltip content={TEXT_TOOLTIP}>
          <Button
            as={ExternalLink}
            target='_blank'
            href={helpBlogUrl('deal-email')}
            variant='link'
          >
            <Icon name='help-circle' />
          </Button>
        </Tooltip>
      }
      title='Código do negócio'
      className='mt-3'
    >
      <div className='text-small rounded-1 bg-light d-flex align-items-center'>
        <TextInput name='dropbox' className='mb-0 w-100' value={deal.dropboxEmail} readOnly />
        <Tooltip onExited={handleOnExited} content={tooltipContent}>
          <div>
            <CopyToClipboard onCopy={handleOnCopy} text={deal.dropboxEmail}>
              <Button variant='link'>
                <Icon size='sm' name='copy' />
              </Button>
            </CopyToClipboard>
          </div>
        </Tooltip>
      </div>
    </DetailsCard>
  );
}
export default DetailsCode;
