import React, { useMemo, useState } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';

import { settingsCurrentPlanPath } from '@/routes';
import { useFreePlan, currentPlanKeys } from '@/api';

import { Checkbox, TextInput } from '@/components/Inputs';
import ConfirmationModal from '@/components/ConfirmationModal';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import { useQueryClient } from 'react-query';
import APIErrorMessage from '@/components/APIErrorMessage';

const CONFIRMATION_STEPS = [
  'Usuários que ultrapassaram os limites do Plano Gratuito serão desativados.',
  'Empresa, pessoas e negócios que ultrapassaram os limites do Plano Gratuito serão excluídos.',
  'Empresas e pessoas que possuem categoria personalizada ficarão sem categoria definida.',
  'Empresas e pessoas que possuem origem personalizada ficarão sem origem definida.',
  'Empresas que possuem setor personalizado ficarão sem setor definido.',
  'Negócios que estão em funis personalizados irão para o funil padrão.',
  'Negócios em etapas personalizadas voltarão para etapas padrão.',
  'Negócios que possuem motivo de perda personalizado ficarão sem motivo de perda definido.',
  'Todas as automações e campos obrigatórios serão excluídos.'
];

function DowngradeFreeModal({ show, setShow }) {
  const downgradeMutation = useFreePlan();
  const navigate = useNavigate();
  const alert = useAlert();
  const queryClient = useQueryClient();

  const initialValues = useMemo(() => (
    {
      activation_code: '',
      ...CONFIRMATION_STEPS.reduce((acc, _, index) => {
        acc[`step-${index}`] = false;
        return acc;
      }, {})
    }
  ), []);

  const [canConfirm, setCanConfirm] = useState(false);
  const [activationCode, setActivationCode] = useState('');

  const handleFormSubmit = (formData) => {
    const checkboxValues = Object.keys(formData)
      .filter((key) => key.startsWith('step-'))
      .map((key) => formData[key]);

    const allFilled = checkboxValues.length === CONFIRMATION_STEPS.length;
    const allTrue = checkboxValues.every((value) => value);

    const activationCodeFilled = formData.activation_code && formData.activation_code.trim() !== '';

    setActivationCode(formData.activation_code);

    if (allFilled && allTrue && activationCodeFilled) {
      setCanConfirm(true);
    } else {
      setCanConfirm(false);
    }
  };

  const handleDowngrade = () => {
    downgradeMutation.mutate(
      { activation_code: activationCode },
      {
        onError: (err) => {
          alert.show(
            <APIErrorMessage err={err} resource='downgrade' action='create' />,
            { variant: 'danger' }
          );
        },
        onSuccess: async () => {
          await Promise.all([queryClient.invalidateQueries(currentPlanKeys.currentPlan)]);
          alert.show('Plano Gratuito ativado com sucesso!', { variant: 'success', timeout: 5000 });
          navigate(settingsCurrentPlanPath());
        }
      }
    );
  };

  return (
    <ConfirmationModal
      confirmationText='Confirmar perdas e reduzir plano'
      show={show}
      onConfirm={handleDowngrade}
      onHide={() => setShow(false)}
      disabled={!canConfirm}
      size='lg'
      buttonClassName='px-3'
    >
      <ConfirmationModal.Title>
        <h1>Atenção!</h1>
      </ConfirmationModal.Title>

      <ConfirmationModal.Info>
        <h4>A redução de plano pode causar perda de informações de sua conta.</h4>
      </ConfirmationModal.Info>

      <Form
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        className='d-flex'
        name='confirm-downgrade-form'
      >
        {({ handleSubmit, debouncedSubmitForm }) => (
          <div>
            {CONFIRMATION_STEPS.map((stepText, index) => (
              <FormField
                key={index}
                as={Checkbox}
                name={`step-${index}`}
                label={stepText}
                className='text-start mb-1'
                onChange={handleSubmit}
              />
            ))}

            <h4 className='my-4'>
              Para segurança e integridade de seus dados, caso queira prosseguir, entre em contato
              com a nossa equipe pelo e-mail contato@agendor.com.br
            </h4>

            <FormField
              as={TextInput}
              name='activation_code'
              label='Código de ativação'
              placeholder='Digite seu código aqui'
              variant='light'
              onChange={debouncedSubmitForm}
              className='m-n1'
            />
          </div>
        )}
      </Form>
    </ConfirmationModal>
  );
}

export default DowngradeFreeModal;
