import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from 'react-bootstrap';
import Icon from '@/components/Icon';
import Badge from '@/components/Badge';

function ActivityFile({ id, progress, name, onRemove }) {
  return (
    <Badge variant='light' className='me-2 position-relative p-0 rounded'>
      <ProgressBar
        isChild
        className='opacity-50 position-absolute h-100 rounded'
        variant='primary'
        now={progress}
      />

      <div className='mx-1 position-relative'>
        <span>{name}</span>

        <Icon
          className='text-primary cursor-pointer'
          onClick={() => onRemove(id)}
          name='close'
          aria-label='Remover anexo'
        />
      </div>
    </Badge>
  );
}

ActivityFile.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  progress: PropTypes.number,
  name: PropTypes.string,
  onRemove: PropTypes.func
};

ActivityFile.defaultProps = {
  id: '',
  progress: 100,
  name: '',
  onRemove: () => {}
};

function ActivitiesFiles({ files, hide, onFileRemove }) {
  if (files?.length && !hide) {
    return (
      <div className='d-block mb-4'>
        <div className='w-200'>
          <div className='p-2 rounded border-dashed'>
            {files.map((file, index) => (
              <ActivityFile
                key={index}
                id={file.id}
                name={file?.meta?.name || file?.fileName || file?.metadata?.filename}
                progress={file?.progress?.percentage}
                onRemove={onFileRemove}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }

  return null;
}

ActivitiesFiles.propTypes = {
  files: PropTypes.array,
  hide: PropTypes.bool,
  onFileRemove: PropTypes.func
};

ActivitiesFiles.defaultProps = {
  files: [],
  hide: false,
  onFileRemove: () => {}
};

export default ActivitiesFiles;
