import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useCheckoutContext } from '@/contexts';
import BillingFooter from '@/components/Billing/BillingFooter';
import AccountDataForm from '@/components/Billing/AccountDataForm';
import { useUpdateCurrentAccount } from '@/api';

const propTypes = {
  onCancel: PropTypes.func
};

function AccountData({ onCancel }) {
  const formRef = useRef(null);
  const { nextStep, prevStep, accountData } = useCheckoutContext();
  const updateMutation = useUpdateCurrentAccount();

  const initialValues = useMemo(() => ({
    legal_name: accountData.legalName ?? '',
    cpf_cnpj: accountData.cpfCnpj ?? '',
    billing_emails: accountData.billingEmails?.join(',') ?? '',
    contact: {
      work_phone: accountData.contact.workPhone ?? ''
    },
    address: {
      city_id: accountData.address.city?.id ?? '',
      additional_info: accountData.address.additionalInfo ?? '',
      country: accountData.address.country ?? '',
      district: accountData.address.district ?? '',
      state: accountData.address.state ?? '',
      postal_code: accountData.address.postalCode ?? '',
      street_number: (accountData.address.streetNumber ?? '').toString(),
      street_name: accountData.address.streetName ?? ''
    }
  }), [accountData]);

  const onSubmit = (values) => updateMutation.mutate(values, {
    onSuccess: () => {
      nextStep();
    }
  });

  return (
    <>
      <AccountDataForm
        ref={formRef}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />

      <BillingFooter
        buttonNextText='Avançar'
        onClickPrev={onCancel || prevStep}
        onClickNext={() => formRef.current?.submitForm()}
      />
    </>
  );
}

AccountData.propTypes = propTypes;
export default AccountData;
