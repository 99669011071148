import React from 'react';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import { settingsManagePaymentMethodPath } from '@/routes';
import { useNextVisibleTime } from '@/hooks';
import { parseDate, toTextualDateString } from '@/date';
import { useAuth } from '@/lib/auth';

export const paymentMethodKey = 'headerPaymentMethod';

export function useHeaderPaymentMethod(currentPlanData) {
  const { user } = useAuth();
  const { isWarningVisible, handleWarningClose } = useNextVisibleTime(paymentMethodKey);

  const isAdmin = user.admin;
  const incompleteCreditCard = currentPlanData?.paymentInfo?.method === 'credit_card' &&
    !currentPlanData?.paymentInfo?.paymentMethodId;

  if (isAdmin && incompleteCreditCard && isWarningVisible) {
    return { show: true, onClose: handleWarningClose };
  }

  return { show: false };
}

function HeaderPaymentMethod({ currentPlanData, onClose }) {
  const expirationDateFormatted = toTextualDateString(parseDate(currentPlanData.expirationDate));

  return (
    <div className='p-4 bg-light-yellow'>
      <div className='p-0 d-flex align-items-center'>
        <div className='row w-100'>
          <div className='ms-6 col-auto d-flex align-items-center'>
            <Icon name='deal' size='sm' />
          </div>
          <div className='col d-flex flex-column justify-content-center'>
            <h4 className='mb-1 text-medium fw-bold'>
              Stripe é a nova parceira de pagamentos do Agendor!
            </h4>
            <div className='text-dark-gray text-small'>
              Nosso novo parceiro de pagamentos é a Stripe. Infelizmente, não
              conseguimos mais processar pagamentos com cartões de <br />
              bandeiras Elo, AmEx e Diners. Por isso, precisamos coletar novamente os dados do
              seu cartão <b>para evitar o bloqueio da sua <br /> conta</b>.
              Só iremos cobrar o valor na renovação, em <b>{expirationDateFormatted}</b>.
              Esse é um processo seguro do Agendor.
            </div>
          </div>
          <div className='col-auto d-flex flex-column align-items-end'>

            <Button
              href={settingsManagePaymentMethodPath()}
              variant='primary'
              className='w-100'
              size='sm'
            >
              <Icon
                name='loading'
                size='sm'
                className='me-2'
              />
              Atualizar cartão
            </Button>

            <Button
              onClick={onClose}
              variant='outline-dark-gray'
              className='mt-2 w-100'
              size='sm'
            >
              <Icon
                name='clock'
                size='sm'
                className='me-2'
              />
              Me lembre depois
            </Button>

          </div>
          <div className='col-auto d-flex flex-column justify-content-center cursor-pointer'>
            <Icon
              name='close'
              size='md'
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderPaymentMethod;
