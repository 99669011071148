import React from 'react';
import Skeleton from '@/components/Skeleton';

function ItemSkeleton() {
  return (
    <div className='mb-2 lh-1'>
      <Skeleton width={44} height={44} borderRadius='.75rem' />
    </div>
  );
}

function FunnelNavSkeleton() {
  return (
    <div className='flex-column align-items-center p-4 border-end'>
      <ItemSkeleton />
      <ItemSkeleton />
      <ItemSkeleton />
    </div>
  );
}

export default FunnelNavSkeleton;
