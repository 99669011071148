import { useQuery, useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { get, post, patch, _delete } from '@/lib/fetch';

export const emailTemplatesKeys = {
  all: ['emailTemplates'],
  lists: () => [...emailTemplatesKeys.all, 'list'],
  list: (params) => [...emailTemplatesKeys.lists(), params],
  infiniteAll: (params) => [...emailTemplatesKeys.lists(), 'infinite', params]
};

export async function getEmailTemplates(params = {}) {
  const { data } = await get('/email_templates', params);

  return data;
}

export async function getEmailTemplate({ id }) {
  const { data } = await get(`/email_templates/${id}`);

  return data;
}

export async function deleteEmailTemplate({ id }) {
  const { data } = await _delete(`/email_templates/${id}`);

  return data;
}

export async function createEmailTemplate(params = {}) {
  const { data } = await post('/email_templates', params);

  return data;
}

export async function updateEmailTemplate({ emailTemplateId, params = {} } = {}) {
  const { data } = await patch(`/email_templates/${emailTemplateId}`, params);

  return data;
}

export function useEmailTemplates({ params = {}, config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: emailTemplatesKeys.list(params),
    queryFn: () => getEmailTemplates(params)
  });
}

export function useInfiniteEmailTemplates({ params = {}, config = {} } = {}) {
  return useInfiniteQuery(
    emailTemplatesKeys.infiniteAll(params),
    ({ pageParam: page = 1 }) => getEmailTemplates({ ...params, page }),
    {
      ...config,
      getNextPageParam: (lastPage) => lastPage.meta.next
    }
  );
}

export function useCreateEmailTemplate({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries(emailTemplatesKeys.all);

  return useMutation(
    createEmailTemplate,
    {
      onSuccess,
      ...config
    }
  );
}

export function useUpdateEmailTemplate({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries(emailTemplatesKeys.all);

  return useMutation(
    updateEmailTemplate,
    {
      onSuccess,
      ...config
    }
  );
}

export function useDeleteEmailTemplate({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries(emailTemplatesKeys.all);

  return useMutation(
    deleteEmailTemplate,
    {
      onSuccess,
      ...config
    }
  );
}


