import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import TableFormFieldsPopover from '@/components/TableFormFieldsPopover';
import {
  organizationColumns,
  organizationTableDefaultValues,
  personColumns,
  personTableDefaultValues,
  dealColumns,
  dealTableDefaultValues,
  customFieldColumns,
  customFieldTableDefaultValues
} from '@/components/Columns';
import { getColumnsFromQueryString, getFilterFromQueryString } from '@/components/Columns/Utils';
import { TextInput, DealSortInput, CustomerSortInput } from '@/components/Inputs';
import FormField from '@/components/FormField';
import InputAdornment from '@/components/InputAdornment';
import Icon from '@/components/Icon';
import { useScreenSize } from '@/hooks';
import { useFormikContext } from 'formik';

const propTypes = {
  type: PropTypes.oneOf(['organization', 'person', 'deal']).isRequired,
  columns: PropTypes.object,
  customFields: PropTypes.array,
  onSubmit: PropTypes.func,
  openColumnsForm: PropTypes.func,
  filterButtonRef: PropTypes.any,
  clearFilterRef: PropTypes.any,
  clearSearchRef: PropTypes.any,
  isLoading: PropTypes.bool,
  filterParams: PropTypes.object
};

const defaultProps = {
  columns: {},
  customFields: [],
  onSubmit: () => {},
  openColumnsForm: () => {},
  filterButtonRef: null,
  clearFilterRef: null,
  clearSearchRef: null,
  isLoading: false,
  filterParams: {}
};

const searchHints = {
  deal: 'Buscar por título, nome do contato ou descrição',
  person: 'Buscar por nome, nome da empresa, CPF ou descrição',
  organization: 'Buscar por nome, razão social, CNPJ ou descrição'
};

const sortInputComponents = {
  deal: DealSortInput,
  person: CustomerSortInput,
  organization: CustomerSortInput
};

function TableFilters({ columnsList, selectedColumns, values }) {
  const filteredColumns = useMemo(
    () => (
      columnsList
        .filter((column) => selectedColumns[column.name] && column.filter)
        .map((column) => column.filter)
    ),
    [selectedColumns]
  );

  return (
    <React.Fragment>
      {filteredColumns.map((filter) => filter.renderComponent(filter, values[filter.name]))}
    </React.Fragment>
  );
}

function TableForm({
  debouncedSubmitForm, type, customFields, columns,
  filterButtonRef, clearSearchRef, clearFilterRef, isLoading, openColumnsForm
}) {
  const { values, submitForm } = useFormikContext();
  const { isSM, isMD, isLG } = useScreenSize();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [expandedSearch, setExpandedSearch] = useState(false);

  const queryParams = [...searchParams];
  const SortInput = sortInputComponents[type];

  const queryStringValues = useMemo(
    () => getFilterFromQueryString(type, queryParams),
    [type]
  );
  const queryStringColumns = useMemo(
    () => getColumnsFromQueryString(type, queryParams),
    [type]
  );
  const [fixedFiltersValues, fixedColumnsList] = useMemo(
    () => getTableFiltersAndColumns(type),
    [type]
  );
  /*
   * Foi necessário stringify para evitar bug do formik de render infinito
   */
  const memoKey = JSON.stringify({ type, queryStringValues, customFields });
  const columnsMemo = JSON.stringify({ queryStringColumns, columns });

  const columnsList = useMemo(
    () => fixedColumnsList.concat(customFieldColumns(customFields)),
    [memoKey]
  );

  const selectedColumns = useMemo(
    () => ({ ...columns, ...queryStringColumns }),
    [columnsMemo]
  );

  const formFieldPopoverDefaultValues = useMemo(
    () => {
      const tableFiltersValues = {
        ...fixedFiltersValues,
        ...customFieldTableDefaultValues(customFields)
      };
      return {
        q: '',
        sorts: 'created_at desc',
        custom_fields: {},
        ...tableFiltersValues
      };
    },
    [memoKey]
  );
  // Limpa querystrings ao resetar filtros
  const handleClearQueryString = () => navigate(location.pathname, { replace: true });

  const searchInputSizeClass = useMemo(
    () => {
      let sizeClass = 'table-form-search-input';

      if (isLG) {
        sizeClass = 'width-5';
      }
      if (isMD) {
        sizeClass = 'width-4';
      }

      return `me-2 ${sizeClass}`;
    },
    [isLG, isMD]
  );

  return (
    <>
      <FormField
        as={TextInput}
        onChange={debouncedSubmitForm}
        onFocus={() => isSM && setExpandedSearch(true)}
        onBlur={() => isSM && setExpandedSearch(false)}
        onClear={handleClearQueryString}
        name='q'
        placeholder={searchHints[type]}
        isClearable
        clearRef={clearSearchRef}
        autoComplete='off'
        className={classnames(
          'mb-0',
          searchInputSizeClass,
          { 'table-form-search-input-expanded': expandedSearch }
        )}
        rightAdornment={({ focus }) => (
          <InputAdornment alignment='right' onClick={focus}>
            <Icon className='text-primary' name='search' />
          </InputAdornment>
        )}
      />

      {
        expandedSearch
          ? null
          : (
            <TableFormFieldsPopover
              columnsFilter={selectedColumns}
              columnsList={columnsList}
              className='width-6'
              variant='light'
              defaultValues={formFieldPopoverDefaultValues}
              openColumnsForm={openColumnsForm}
              filterButtonRef={filterButtonRef}
              clearFilterRef={clearFilterRef}
              onResetFilter={handleClearQueryString}
              onSubmitFilter={handleClearQueryString}
              disabled={isLoading}
            >
              <TableFilters
                columnsList={columnsList}
                selectedColumns={selectedColumns}
                values={values}
              />
            </TableFormFieldsPopover>
          )
      }
      {
        expandedSearch
          ? null
          : (
            <FormField
              as={SortInput}
              innerClassName='ms-2'
              name='sorts'
              onChange={submitForm}
              disabled={isLoading}
            />
          )
      }
    </>
  );
}

function getTableFiltersAndColumns(type) {
  switch (type) {
    case 'deal':
      return [dealTableDefaultValues, dealColumns];
    case 'organization':
      return [organizationTableDefaultValues, organizationColumns];
    case 'person':
      return [personTableDefaultValues, personColumns];
    default:
      throw new Error(`Invalid Column type ${type}`);
  }
}

TableForm.propTypes = propTypes;
TableForm.defaultProps = defaultProps;

export default TableForm;
