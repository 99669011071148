import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import FunnelNavSkeleton from '@/components/FunnelNavSkeleton';
import SkeletonWrapper from '@/components/SkeletonWrapper';
import Pills from '@/components/Pills';
import Icon from '@/components/Icon';
import Item from '@/components/FunnelNav/Item';
import { funnelPath, settingsFunnelsPath } from '@/routes';
import { useFunnels } from '@/api';

const propTypes = {
  className: PropTypes.string
};

const defaultProps = {
  className: ''
};

function FunnelNav({ className }) {
  const { isLoading, data: { data = [] } = {} } = useFunnels();

  return (
    <SkeletonWrapper
      isLoading={isLoading}
      SkeletonComponent={FunnelNavSkeleton}
    >
      <Pills
        className={classnames(
          'flex-column p-4 shadow-right z-index-2',
          className
        )}
      >
        {data.map((item) => (
          <FunnelNav.Item
            key={item.id}
            href={funnelPath(item.id)}
            title={item.name}
          >
            {item.initials}
          </FunnelNav.Item>
        ))}

        <FunnelNav.Item title='Personalizar funis' href={settingsFunnelsPath()}>
          <Icon name='config' size='lg' />
        </FunnelNav.Item>
      </Pills>
    </SkeletonWrapper>
  );
}

FunnelNav.propTypes = propTypes;
FunnelNav.defaultProps = defaultProps;

FunnelNav.Item = Item;
FunnelNav.Item.displayName = 'FunnelNav.Item';

export default FunnelNav;
