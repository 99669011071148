import React from 'react';
import AppProvider from '@/components/AppProvider';
import Routes from '@/components/Routes';

function App() {
  return (
    <AppProvider>
      <Routes />
    </AppProvider>
  );
}

export default App;
