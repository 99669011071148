import React, { useEffect, useMemo, useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useAlert } from 'react-alert';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import capitalize from 'lodash/capitalize';
import {
  useInfiniteOrganizations, useDeleteOrganization,
  useUpdateOrganization, useOrganizationCustomFields
} from '@/api';
import APIErrorMessage from '@/components/APIErrorMessage';
import Button from '@/components/Button';
import ConfirmationModal from '@/components/ConfirmationModal';
import ColumnsForm from '@/components/ColumnsForm';
import Dropdown from '@/components/Dropdown';
import ExportButton from '@/components/ExportButton';
import Icon from '@/components/Icon';
import ImportButton from '@/components/ImportButton';
import ListEntity from '@/components/List/ListEntity';
import Main from '@/components/Main';
import TableForm from '@/components/TableForm';
import { convertDateTimeString } from '@/date';
import { useDocumentTitle, useMediaQuery } from '@/hooks';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import { applyMask } from '@/masks';
import { getSocialMedias,
  getListTypeByParams,
  getString,
  getForgottenFilters } from '@/utils';
import { organizationsImportPath } from '@/routes';
import classnames from 'classnames';
import LeadsGenerationButton from '@/components/LeadsGenerationButton';
import ListForm from '@/components/ListForm';

const ENTITY = 'organization';
const ENTITY_I18N = getString(['models', ENTITY]);
const ENTITY_I18N_PLURAL = `${ENTITY_I18N}s`;

function OrganizationsIndex() {
  useDocumentTitle(capitalize(ENTITY_I18N_PLURAL));

  const { user } = useAuth();
  const tracker = useTracking();
  const [params, setParams] = useState({});
  const [paramsLoaded, setParamsLoaded] = useState(false);
  const columnsButtonRef = useRef(null);
  const filterButtonRef = useRef(null);
  const clearSearchRef = useRef(null);
  const clearFilterRef = useRef(null);
  const isSmall = useMediaQuery('(max-width: 574px)');

  useEffect(() => {
    tracker.trackNewOrganizationListViewed({ user });
  }, [user]);

  const {
    data: organizations,
    isLoading: isLoadingOrganizations,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = useInfiniteOrganizations({
    params,
    config: {
      enabled: paramsLoaded,
      staleTime: 1000
    }
  });

  const {
    data: customFields,
    isLoading: isLoadingCustomFields
  } = useOrganizationCustomFields();

  const initialValues = useMemo(
    () => {
      const organizationsData = organizations?.pages.flatMap((page) => page.data);
      return prepareData(organizationsData);
    },
    [organizations]
  );

  const exportButtonFilters = {
    sorts: params?.sorts,
    products_id: params?.products_id_in,
    forgotten: getForgottenFilters(params),
    users_id: params?.user_id_in,
    created_at_date: params?.created_at_utc_date_within,
    states: params?.state_in,
    cities_id: params?.city_id_in,
    area_codes: params?.area_code_in,
    district: params?.district_i_cont,
    sectors_id: params?.sector_id_in,
    origins_id: params?.lead_origin_id_in,
    teams_id: params?.team_id_in,
    custom_fields: params?.custom_fields,
    categories_id: params?.category_id_in,
    organization_id: params.organization_id_eq,
    imports_id: params.import_id_in,
    ranking: params?.ranking_in
  };

  const totalItems = organizations?.pages[0].meta.count;
  const customFieldsList = customFields?.data || [];
  const isLoading = isLoadingOrganizations || isLoadingCustomFields;

  const alert = useAlert();
  const [organizationId, setOrganizationId] = useState();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [columns, setColumns] = useState({});
  const [columnsLoaded, setColumnsLoaded] = useState(false);
  const deleteOrganizationMutation = useDeleteOrganization();
  const updateOrganizationMutation = useUpdateOrganization();

  const onClickDeleteOrganization = (selectedOrganizationId) => {
    setOrganizationId(selectedOrganizationId);
    setShowDeleteConfirm(true);
  };

  const onCancelDelete = () => {
    setOrganizationId();
    setShowDeleteConfirm(false);
  };

  const onConfirmDelete = () => {
    setIsDeleting(true);

    deleteOrganizationMutation.mutate({ organizationId }, {
      onError: (err) => {
        setIsDeleting(false);
        setOrganizationId();
        setShowDeleteConfirm(false);
        alert.show(
          <APIErrorMessage err={err} resource={ENTITY} action='delete' />,
          { variant: 'danger', timeout: 5000 }
        );
      },
      onSuccess: () => {
        setIsDeleting(false);
        setOrganizationId();
        setShowDeleteConfirm(false);
        alert.show(
          `${capitalize(ENTITY_I18N)} removida com sucesso!`,
          { variant: 'success', timeout: 5000 }
        );
      }
    });
  };

  const onUpdateOrganization = (id, updateParams, done = () => {}) => {
    updateOrganizationMutation.mutate({ organizationId: id, params: updateParams }, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource={ENTITY} action='update' />,
          { variant: 'danger', timeout: 5000 }
        );
        done();
      },
      onSuccess: () => {
        alert.show(
          `${capitalize(ENTITY_I18N)} atualizada com sucesso!`,
          { variant: 'success', timeout: 5000 }
        );
        done();
      }
    });
  };

  const onChangeColumns = (filteredColumns) => {
    const hasChanged = !isEmpty(columns) && !isEqual(columns, filteredColumns);
    if (hasChanged) {
      alert.show(
        'Colunas atualizadas com sucesso!',
        { variant: 'success', timeout: 5000 }
      );
    }

    setColumns(filteredColumns);
    setColumnsLoaded(true);
  };

  const onChangeFilters = (filterParams) => {
    setParams(filterParams);
    setParamsLoaded(true);
  };

  const clearSearch = () => clearSearchRef?.current?.click?.();
  const clearFilter = () => clearFilterRef?.current?.click?.();
  const openColumnsForm = () => columnsButtonRef?.current?.click?.();
  const openFiltersForm = () => filterButtonRef?.current?.click?.();

  return (
    <ListForm
      type={ENTITY}
      onSubmit={onChangeFilters}
      customFields={customFieldsList}
      filterParams={params}
    >
      {({ values, debouncedSubmitForm }) => (
        <Main fluid flex overflow={false} className='flexible-height'>
          <Container
            fluid
            className='d-flex flex-column flex-grow-1 flexible-height px-6 pt-6 pb-2'
          >
            <Row>
              <Col className={classnames(
                isSmall
                  ? 'flex-wrap ms-4'
                  : 'd-flex align-items-center justify-content-between'
              )}>
                <div className='d-flex align-items-start ms-4 mb-4'>
                  <TableForm
                    type={ENTITY}
                    columns={columns}
                    debouncedSubmitForm={debouncedSubmitForm}
                    openColumnsForm={openColumnsForm}
                    filterButtonRef={filterButtonRef}
                    clearSearchRef={clearSearchRef}
                    clearFilterRef={clearFilterRef}
                    customFields={customFieldsList}
                    isLoading={isLoading}
                  />
                  <ColumnsForm
                    className='ms-2 me-2 text-nowrap'
                    type={ENTITY}
                    onSubmit={onChangeColumns}
                    buttonRef={columnsButtonRef}
                    isLoading={isLoading}
                    customFields={customFieldsList}
                  />
                </div>

                <div className='d-flex align-items-end mb-4'>
                  <div className='d-none d-xxl-block'>

                    <ImportButton
                      path={organizationsImportPath()}
                      entity={ENTITY}
                      entityNamePlural={ENTITY_I18N_PLURAL}
                    />

                    <ExportButton
                      variant='transparent-light'
                      className='me-2'
                      tooltipText='Exporte esta lista em formato de planilha de Excel (.xlsx)'
                      exportEntity={ENTITY}
                      filters={exportButtonFilters}
                    />
                  </div>

                  <Button
                    href='/organizations/new'
                    id='add-organization'
                    aria-label={`Adicionar ${ENTITY_I18N}`}
                    className='text-nowrap me-2'
                  >
                    <Icon name='add' className='me-2' />
                    <span>
                      Adicionar {' '}
                      <span className='d-md-inline-block d-none'>{ENTITY_I18N}</span>
                    </span>
                  </Button>

                  <LeadsGenerationButton className='ms-2 me-3' />

                  <Dropdown align='end' className='align-self-center me-3 d-xxl-none'>
                    <Dropdown.Toggle
                      id='menu-toggle'
                      variant='lighter-gray'
                    >
                      <span>...</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      renderOnMount
                      className='min-width-5 z-index-1090'
                    >

                      <ImportButton
                        as={Dropdown.Item}
                        iconClassName='me-2 text-primary'
                        buttonClassNames='d-flex fw-bold'
                        spanClassName='text-dark-gray'
                        entity={ENTITY}
                        entityNamePlural={ENTITY_I18N_PLURAL}
                        path={organizationsImportPath()}
                        buttonText={`Importar ${ENTITY_I18N_PLURAL}`}
                      />

                      <ExportButton
                        as={Dropdown.Item}
                        href=''
                        className='text-primary fw-bold'
                        tooltipText='Exporte esta lista em formato de planilha de Excel (.xlsx)'
                        exportEntity={ENTITY}
                        filters={exportButtonFilters}
                        buttonText={`Exportar ${ENTITY_I18N_PLURAL}`}
                        spanClassName='text-dark-gray'
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
            </Row>
            <Row className='d-flex flex-column flex-grow-1 flexible-height'>
              <Col className='d-flex flex-column flex-grow-1 flexible-height'>
                <ListEntity
                  entity={ENTITY}
                  items={initialValues}
                  customFields={customFieldsList}
                  isLoading={isLoading}
                  isFetchingNextPage={isFetchingNextPage}
                  fetchNextPage={fetchNextPage}
                  hasNextPage={hasNextPage}
                  totalItems={totalItems}
                  columns={columns}
                  columnsLoaded={columnsLoaded}
                  onDelete={onClickDeleteOrganization}
                  onUpdate={onUpdateOrganization}
                  onClearSearch={clearSearch}
                  onClearFilter={clearFilter}
                  onOpenFilter={openFiltersForm}
                  variant={getListTypeByParams(values)}
                />
              </Col>
            </Row>
          </Container>

          <ConfirmationModal
            confirmationText='Excluir'
            show={showDeleteConfirm}
            onConfirm={onConfirmDelete}
            onHide={onCancelDelete}
            disabled={isDeleting}
          >
            <ConfirmationModal.Title>
              Deseja excluir esta {ENTITY_I18N}?
            </ConfirmationModal.Title>

            <ConfirmationModal.Info>
              Todo o histórico de relacionamento será perdido.
            </ConfirmationModal.Info>
          </ConfirmationModal>
        </Main>
      )}
    </ListForm>
  );
}

function prepareData(organizations) {
  if (organizations?.length) {
    return organizations.map((organization) => {
      const { cnpj, contact, createdAt, updatedAt } = organization;

      return {
        ...organization,
        cnpj: applyMask(cnpj, 'cnpj'),
        socialMedias: getSocialMedias(contact),
        createdAt: convertDateTimeString(createdAt),
        updatedAt: convertDateTimeString(updatedAt),
        importedAt: convertDateTimeString(organization?.import?.importedAt)
      };
    });
  }
  return [];
}

export default OrganizationsIndex;
