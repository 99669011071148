import React from 'react';
import { useWatch } from 'react-hook-form';
import { TextArea } from '@/components/Inputs';
import HookFormField from '@/components/HookFormField';
import NeedInputCard from '@/feature/profile_form/NeedInputCard';

const option = {
  icon: '💭',
  title: 'Outros',
  value: 'Outros',
  id: 'profile-form-need-check-other'
};

function OtherInputCard(props) {
  const checks = useWatch({ name: 'user.need.checks' });
  const checked = checks?.includes(option.value);

  return (
    <NeedInputCard option={option} {...props}>
      {
        checked
          ? (
            <HookFormField
              as={TextArea}
              name='user.need.other'
              className='mb-0'
              innerClassName='resize-none'
              placeholder='Quero melhorar/resolver...'
              autoComplete='off'
              autoFocus
              maxLength={50}
            />
          )
          : <p className='text-small'>(Descreva)</p>
      }
    </NeedInputCard>
  );
}

export default OtherInputCard;
