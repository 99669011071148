import {
  parseISO, formatISO,
  startOfMonth, endOfMonth, subMonths, startOfYear, endOfYear
} from 'date-fns';

export function parseRangeISO(range) {
  if (!range) {
    return;
  }

  try {
    return range
      .split('/')
      .map((string) => (string ? parseISO(string) : undefined));
  } catch (e) {
    console.warn(e, range);
  }
}

export function formatRangeISO(range) {
  if (!range) {
    return;
  }

  try {
    return range
      .map((date) => (date ? formatISO(date) : undefined))
      .join('/');
  } catch (e) {
    console.warn(e, range);
  }
}

export function currentMonth() {
  const today = new Date();

  return [startOfMonth(today), endOfMonth(today)];
}

export function lastMonth() {
  const today = new Date();
  const previousMonth = subMonths(startOfMonth(today), 1);

  return [previousMonth, endOfMonth(previousMonth)];
}

export function currentYear() {
  const today = new Date();

  return [startOfYear(today), endOfYear(today)];
}

export const DATE_RANGE_DEFAULT_PRESETS = [
  { label: 'Este mês', getValue: currentMonth },
  { label: 'Mês passado', getValue: lastMonth },
  { label: 'Este ano', getValue: currentYear }
];
