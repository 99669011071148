import React from 'react';
import PropTypes from 'prop-types';
import Base from 'react-bootstrap/Nav';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

const defaultProps = {
  className: ''
};

function Pills({ children, className, ...props }) {
  return (
    <Base
      variant='pills'
      className={className}
      { ...props }
    >
      {children}
    </Base>
  );
}

Pills.propTypes = propTypes;
Pills.defaultProps = defaultProps;

Pills.Item = Base.Item;
Pills.Item.displayName = 'Pills.Item';

export default Pills;
