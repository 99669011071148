import React from 'react';
import {
  AreaCodeSelect,
  CategorySelect,
  CitySelect,
  DateRangeInput,
  ForgottenSelect,
  ImportSelect,
  LeadOriginSelect,
  ProductSelect,
  SectorSelect,
  StateSelect,
  TeamSelect,
  UserSelect
} from '@/components/Inputs';
import FormField from '@/components/FormField';
import DependentFormField from '@/components/DependentFormField';
import { DATE_RANGE_DEFAULT_PRESETS } from '@/date-range';
import { useOrganizationCustomFields } from '@/api';
import CustomFieldsFilters from '@/components/Filters/CustomFieldsFilters';
import LoadingWrapper from '@/components/LoadingWrapper';

function BacklogOrganizationFilters() {
  const {
    data: {
      data = []
    } = {},
    isSuccess
  } = useOrganizationCustomFields();

  return (
    <LoadingWrapper isLoading={!isSuccess}>
      <FormField
        as={ProductSelect}
        name='products_id_in'
        multiple
        isSearchable
        allowNull
        queryArgs={{ params: { per_page: 600 } }}
      />

      <FormField
        as={UserSelect}
        name='user_id_in'
        label='Responsável'
        multiple
        allowNull
      />

      <FormField
        as={CategorySelect}
        name='category_id_in'
        multiple
        isSearchable
        allowNull
      />

      <FormField
        as={ForgottenSelect}
        name='forgotten'
      />

      <FormField
        as={StateSelect}
        name='state_in'
        multiple
        isSearchable
        allowNull
      />

      <DependentFormField
        as={CitySelect}
        name='city_id_in'
        source='state_in'
        target='state'
        multiple
        isSearchable
        allowNull
      />

      <FormField
        as={AreaCodeSelect}
        name='area_code_in'
        multiple
        isSearchable
        allowNull
      />

      <FormField
        as={SectorSelect}
        name='sector_id_in'
        multiple
        isSearchable
        allowNull
      />

      <FormField
        as={LeadOriginSelect}
        name='lead_origin_id_in'
        multiple
        isSearchable
        allowNull
      />

      <FormField
        as={TeamSelect}
        name='team_id_in'
        multiple
        isSearchable
      />

      <FormField
        as={ImportSelect}
        name='import_id_in'
        entity='organizations'
        multiple
        allowNull
      />

      <FormField
        as={DateRangeInput}
        name='created_at_utc_date_within'
        label='Data de cadastro'
        presets={DATE_RANGE_DEFAULT_PRESETS}
      />

      <CustomFieldsFilters fields={data} />
    </LoadingWrapper>
  );
}

const defaultValues = {
  products_id_in: null,
  user_id_in: null,
  category_id_in: null,
  forgotten: null,
  state_in: null,
  city_id_in: null,
  area_code_in: null,
  sector_id_in: null,
  lead_origin_id_in: null,
  team_id_in: null,
  import_id_in: null,
  created_at_utc_date_within: null,
  custom_fields: {}
};

export default BacklogOrganizationFilters;
export { defaultValues };
