import React from 'react';
import Base from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

const Tabs = React.forwardRef(
  (props, ref) => (
    <Base ref={ref} variant='tabs' {...props} />
  )
);

Tabs.displayName = 'Tabs';

Tabs.Item = Base.Item;
Tabs.Item.displayName = 'Tabs.Item';

Tabs.Container = Tab.Container;
Tabs.Container.displayName = 'Tabs.Container';

Tabs.Pane = Tab.Pane;
Tabs.Pane.displayName = 'Tabs.Pane';

Tabs.Content = Tab.Content;
Tabs.Content.displayName = 'Tabs.Content';

export default Tabs;
