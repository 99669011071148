import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { positions, Provider as AlertProvider } from 'react-alert';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary as SentryErrorBoundary } from '@/lib/sentry';
import { IntercomProvider } from '@/lib/intercom';
import { MixpanelProvider } from '@/lib/mixpanel';
import { AuthenticationProvider, UnauthorizedErrorWrapper } from '@/lib/auth';
import { queryClient } from '@/lib/react-query';
import Alert from '@/components/Alert';
import { setupBetaUrlCookie } from '@/hooks';

const propTypes = {
  children: PropTypes.node.isRequired
};

function AppProvider({ children }) {
  useEffect(() => {
    setupBetaUrlCookie();
  }, []);

  return (
    <SentryErrorBoundary fallback={<div>Ops, algo deu errado :(</div>}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AlertProvider
            className='z-index-1090 p-8'
            template={({ message, options: { variant }, close }) => (
              <Alert variant={variant} onClose={close} className='shadow-lg max-width-6'>
                {message}
              </Alert>
            )}
            position={positions.TOP_RIGHT}
          >
            <AuthenticationProvider>
              <IntercomProvider>
                <MixpanelProvider>
                  <UnauthorizedErrorWrapper>
                    {children}
                  </UnauthorizedErrorWrapper>
                </MixpanelProvider>
              </IntercomProvider>
            </AuthenticationProvider>
          </AlertProvider>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </SentryErrorBoundary>
  );
}

AppProvider.propTypes = propTypes;

export default AppProvider;
