import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  header: PropTypes.object.isRequired,
  table: PropTypes.object.isRequired,
  onResize: PropTypes.func.isRequired
};

function ListHeaderResizer({ header, table, onResize }) {
  const column = header.column;
  const columnDef = column.columnDef;

  const size = column.getSize();
  const isSizeOnLimit = size >= columnDef.minSize && size <= columnDef.maxSize;
  const isHeaderResizing = column.getIsResizing() && isSizeOnLimit;

  const [resizeEnded, setResizeEnded] = useState();

  const { columnSizingInfo: { isResizingColumn, deltaOffset } } = table.getState();
  const allColumns = table.getAllColumns();

  useEffect(() => {
    // Validacao necessaria para ignorar estado inicial ao carregar tela
    if (resizeEnded !== undefined) {
      setResizeEnded(!isResizingColumn);
    }
    if (resizeEnded) {
      const columnsSnapshot = allColumns.reduce((snapshot, col) => {
        snapshot[col.id] = { size: col.getSize() };
        return snapshot;
      }, {});
      onResize(columnsSnapshot);
    }
  }, [isResizingColumn, resizeEnded]);

  return (
    <>
      <div
        className={classnames(
          'th-border-resizer',
          { 'non-fixed-border-right': header.index },
          { 'border-end-1': !header.index }
        )}
      />
      <div
        className='th-border-resizer-tick'
        style={{
          transform: isHeaderResizing && `translateX(${deltaOffset}px)`
        }}
        onMouseDown={(event) => {
          event.preventDefault();
          if (event.buttons === 1) {
            setResizeEnded(false);
            header.getResizeHandler()(event);
          }
        }}
      />
    </>
  );
}

ListHeaderResizer.propTypes = propTypes;

export default ListHeaderResizer;
