import React, { useEffect, useRef } from 'react';
import TextInput from '@/components/Inputs/TextInput';
import Inputmask from 'inputmask';

function formatValue(value) {
  return Number(value).toFixed(2);
}

function DecimalInput(props) {
  const { value, onChange, onKeyUp, maskOptions, readOnly, disabled, ...rest } = props;

  const ref = useRef(null);

  const handleChange = () => {
    const newValue = ref.current?.inputmask?.unmaskedvalue?.();
    if (typeof newValue === 'number') {
      onChange?.(formatValue(newValue));
    } else {
      onChange?.(newValue?.toString());
    }
  };

  const handleKeyUp = (event) => {
    onKeyUp?.(event);
    /*
     * Quando o valor está como "0,00" ele possui um bug que não apaga o valor e no estado fica
     * salvo o como "0.00", essa verificação faz com que ele limpe o valor nessa situação
     */
    const canEdit = !readOnly && !disabled;
    if (canEdit && event.key === 'Backspace' && Number(value) === 0) {
      onChange?.('');
    }
  };

  useEffect(() => {
    if (ref.current) {
      const mask = new Inputmask({
        alias: 'decimal',
        rightAlign: false,
        greedy: false,
        groupSeparator: '.',
        radixPoint: ',',
        allowMinus: true,
        digitsOptional: false,
        numericInput: true,
        showMaskOnFocus: false,
        showMaskOnHover: false,
        unmaskAsNumber: true,
        onBeforePaste: (pasted) => {
          if (!pasted) {
            return '';
          }
          return formatValue(pasted.replaceAll('.', '').replaceAll(',', '.'));
        },
        onBeforeMask: (initial) => {
          if (!initial) {
            return '';
          }
          return formatValue(initial);
        },
        oncleared: () => {
          const newValue = ref.current?.inputmask?.unmaskedvalue?.();
          if (typeof newValue === 'number') {
            onChange?.(formatValue(newValue));
          } else {
            onChange?.('');
          }
        },
        ...maskOptions
      });
      mask.mask(ref.current);
    }
  }, []);

  return (
    <TextInput
      ref={ref}
      {...rest}
      onChange={handleChange}
      value={value}
      onKeyUp={handleKeyUp}
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}

export default DecimalInput;
