import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Avatar from '@/components/Avatar';
import Truncate from '@/components/Truncate';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    text: PropTypes.string,
    user: PropTypes.shape({
      name: PropTypes.string,
      avatarUrl: PropTypes.string
    })
  })
};

const MAX_TEXT_LINES = 10;

function ActivityTemplatePreview({ data }) {
  const textLines = data.text.split('\n').slice(0, MAX_TEXT_LINES);

  return (
    <div className={classnames(
      'width-5'
    )}
    >
      <h5 className='text-dark-gray'>{data.name}</h5>
      <div className={classnames(
        'pt-2',
        'text-dark-gray',
        'text-small'
      )}>
        <Truncate lines={MAX_TEXT_LINES} disableTooltip>
          {textLines.map((line, index) => (
            <div key={index}>
              {line}
              <br />
            </div>
          ))}
        </Truncate>
      </div>
      <div className={classnames(
        'border-top',
        'border-1',
        'border-light-gray',
        'd-flex',
        'justify-content-start',
        'align-items-center',
        'pt-2'
      )}
      >
        <span className={classnames(
          'text-small',
          'text-dark-gray',
          'me-1'
        )}
        >
        Criado por
        </span>
        <Avatar
          name={data.user.name}
          url={data.user.avatarUrl}
          tooltip={false}
          size='lg'
        />
        <span className={classnames(
          'text-small',
          'text-dark-gray',
          'ms-1'
        )}
        >{data.user.name}</span>
      </div>
    </div>
  );
}

ActivityTemplatePreview.propTypes = propTypes;
export default ActivityTemplatePreview;
