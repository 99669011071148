import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { Col, Container, Row } from 'react-bootstrap';
import classnames from 'classnames';
import Button from '@/components/Button';
import LoadingButton from '@/components/LoadingButton';
import Tooltip from '@/components/Tooltip';
import { toDigitsString } from '@/number';
import { useLeadsGenerationContext } from '@/contexts';
import LoadSpinner from '@/components/LoadSpinner';

const propTypes = {
  onClickNext: PropTypes.func.isRequired,
  buttonNextText: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  loadingTextButton: PropTypes.string,
  onClickPrev: PropTypes.func,
  disabled: PropTypes.bool,
  nextButtonDisabledMessage: PropTypes.string,
  isCounterVisible: PropTypes.bool,
  isVisibleBackButton: PropTypes.bool
};

const defaultProps = {
  isCounterVisible: false,
  disabled: false,
  isBackButtonVisible: true
};

function Footer(props) {
  const [isLeadsFirstLoad, setIsLeadsFirstLoad] = useState(false);

  const {
    onClickNext,
    buttonNextText,
    isLoading,
    loadingTextButton,
    onClickPrev,
    disabled,
    nextButtonDisabledMessage,
    isCounterVisible,
    isBackButtonVisible
  } = props;

  const {
    isLeadsFetched,
    leadsCount,
    decisionMakersCount
  } = useLeadsGenerationContext();

  useEffect(() => {
    if (isLeadsFetched) {
      setIsLeadsFirstLoad(true);
    }
  }, [isLeadsFetched]);

  const element = document.getElementById('leads-generation-footer');

  if (!element) {
    return null;
  }

  return createPortal(
    <Container className='pt-6 px-0'>
      <Row className='m-0 align-items-center'>
        <Col md={6} className='p-0'>
          {isCounterVisible && (
            <div>
              <h1 className='me-1 text-dark-gray fw-normal'>Resultados em potencial:</h1>

              <div className='d-flex align-items-center'>
                {
                  isLeadsFirstLoad && (
                    <div className={classnames(
                      'd-flex me-2 height-2',
                      { 'opacity-50': !isLeadsFetched }
                    )}>
                      <div className='d-flex align-items-center'>
                        <h1 className='me-1'>{toDigitsString(leadsCount || 0)}</h1>

                        <h2>empresas</h2>
                      </div>

                      <h4 className='mx-1'>.</h4>

                      <div className='d-flex align-items-center'>
                        <h1 className='me-1'>{toDigitsString(decisionMakersCount || 0)}</h1>

                        <h2>decisores</h2>
                      </div>
                    </div>
                  )
                }

                {!isLeadsFetched && <LoadSpinner className='height-2 text-primary' />}
              </div>
            </div>
          )}
        </Col>

        <Col md={6} className='p-0'>
          <div className='d-flex justify-content-end'>
            {isBackButtonVisible && (
              <Button
                className='me-3'
                onClick={onClickPrev}
                variant='outline-dark-gray'
                disabled={isLoading}
              >
                <h4>Voltar</h4>
              </Button>
            )}

            <Tooltip
              hide={!disabled || !nextButtonDisabledMessage}
              content={nextButtonDisabledMessage}
            >
              <div>
                <LoadingButton
                  className='min-width-4 max-width-5 py-2'
                  disabled={disabled}
                  onClick={onClickNext}
                  isLoading={isLoading}
                  loadingText={loadingTextButton}
                >
                  <h4>{buttonNextText}</h4>
                </LoadingButton>
              </div>
            </Tooltip>

          </div>
        </Col>
      </Row>
    </Container>,
    element
  );
}

const closePropTypes = {
  onClick: PropTypes.func
};

const closeDefaultProps = {
  onClick: () => { }
};

function Close({ onClick }) {
  const element = document.getElementById('leads-generation-footer');

  if (!element) {
    return null;
  }

  return createPortal(
    <Container className='pt-6 px-0'>
      <Row className='m-0 align-items-center'>
        <Col className='d-flex justify-content-end'>
          <Button
            className='px-6'
            onClick={onClick}
            variant='light'
          >
            Fechar
          </Button>
        </Col>
      </Row>
    </Container>,
    element
  );
}

Close.propTypes = closePropTypes;
Close.defaultProps = closeDefaultProps;

Footer.Close = Close;

Footer.defaultProps = defaultProps;
Footer.propTypes = propTypes;

export default Footer;
