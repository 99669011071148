import { useQuery } from 'react-query';
import { get } from '@/lib/fetch';

export const personValidationKeys = {
  all: ['person-validation'],
  lists: () => [...personValidationKeys.all, 'list'],
  list: (params) => [...personValidationKeys.lists(), params]
};

export async function getPersonValidation(params) {
  const { data } = await get('/person_validation', params);

  return data;
}

export function usePersonValidation({ excludedId, params = {}, config = {} }) {
  return useQuery({
    ...config,
    queryKey: personValidationKeys.list(params),
    queryFn: () => getPersonValidation({ validations: params, excluded_id: excludedId })
  });
}
