import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Card from '@/components/Card';
import ExternalLink from '@/components/ExternalLink';
import Icon from '@/components/Icon';
import { settingsVoipCallsPath } from '@/routes';
import { useInfiniteCalls } from '@/api';
import { parseDate, toDateString, secondsToDuration } from '@/date';

function CallHistory() {
  const { data, isLoading } = useInfiniteCalls({
    params: {
      per_page: 19
    }
  });

  const calls = data?.pages.flatMap((page) => page.data);

  return (
    <Card className='border-lighter-gray mb-3 card-voip'>
      <div className={
        `d-flex
        justify-content-between
        text-dark-gray
        py-3 px-3 overflow-hidden`
      }>
        Últimas chamadas
        <ExternalLink href={settingsVoipCallsPath()} className='fw-bold'>
          <Icon name='external-link' className='me-2' size='sm' />
          Ver todas
        </ExternalLink>
      </div>

      <hr className='mx-4' />

      <div className='px-3 py-1 overflow-x-hidden'>
        <Row className='text-darker-gray text-nowrap'>
          <Col sm={3}>
            Data
          </Col>
          <Col sm={3}>
            Responsável
          </Col>
          <Col sm={3}>
            Número cliente
          </Col>
          <Col sm={3}>
            Duração
          </Col>
        </Row>

        {
          !isLoading && calls.map((call, index) => (
            <Row className='text-dark-gray text-nowrap' key={index}>
              <Col sm={3}>
                <div className='mt-3 fw-bold text-dark-gray'>
                  {toDateString(parseDate(call?.createdAt))}
                </div>
              </Col>
              <Col sm={3}>
                <div className='mt-3 fw-bold text-dark-gray'>{call?.user?.name}</div>
              </Col>
              <Col sm={3}>
                <div className='mt-3 fw-bold text-dark-gray'>
                  ({call?.targetDdd}) {call?.targetPhone}
                </div>
              </Col>
              <Col sm={3}>
                <div className='mt-3 fw-bold text-dark-gray'>
                  {secondsToDuration(call?.durationInSeconds)}
                </div>
              </Col>
            </Row>
          ))
        }
      </div>
    </Card>
  );
}

export default CallHistory;
