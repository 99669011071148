import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import notFoundImage from 'images/404.png';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import { useDocumentTitle } from '@/hooks';

const PAGE_TITLE = 'Página não encontrada';

function NotFound() {
  useDocumentTitle(PAGE_TITLE);

  return (
    <Container className='d-flex flex-column flex-grow-1'>
      <Row className='flex-fill'>
        <Col sm={4} className='d-flex flex-column'>
          <div className='d-flex flex-fill flex-column justify-content-center  align-items-start'>
            <h1 className='display-4 text-darker-gray'>Oops!</h1>
            <h2 className='text-dark-gray'>
              Parece que a página que você está procurando não existe.
            </h2>

            <Button className='mt-4' href='/'>
              <Icon
                className='me-2'
                name='home'
                size='sm'
              />
              <span>
                Voltar para o início
              </span>
            </Button>
          </div>
        </Col>
        <Col sm={8} className='d-flex flex-column'>
          <div className='d-flex flex-fill flex-column justify-content-center align-items-end'>
            <img src={notFoundImage} className='img-fluid' />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default NotFound;
