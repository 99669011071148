/*
 * Essa funcao normaliza qualquer tipo de dados para number como default 0
 * Atualmente o JS tem comportamentos bizarros do tipo:
 * Number(null) = 0
 * Number(undefined) = NaN
 * Number('') = 0
 * Number('ab') = 0
 * Number([]) = 0
 * Number([123]) = 123
 * Number([123, 321]) = NaN
 * Number({}) = NaN
 */
export function toNumber(value) {
  return isNaN(Number(value)) ? 0 : Number(value);
}

export function toCurrencyString(value, options = {}) {
  const { currency = 'BRL', removeTrailingZeros = false } = options;
  return value?.toLocaleString('pt-BR', {
    style: 'currency',
    currency,
    trailingZeroDisplay: removeTrailingZeros ? 'stripIfInteger' : 'auto'
  }) ?? '';
}

export function toDigitsString(value, minDigits = 1) {
  return Number(value).toLocaleString('pt-BR', {
    minimumIntegerDigits: minDigits
  });
}

export function toDecimalString(value) {
  return Number(value).toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
}

export function formatRange(from, to, formatter = toDigitsString) {
  if (!from && !to) {
    return '';
  }

  if (from === to) {
    return formatter(from);
  }

  if (from && !to) {
    return `${formatter(from)} ou mais`;
  }

  if (!from && to) {
    return `${formatter(to)} ou menos`;
  }

  return `${formatter(from)} → ${formatter(to)}`;
}

export function toPercentageString(value) {
  return `${toDecimalString(value)}%`;
}

export function calculatePercentage(value, reference, ceil = false) {
  const percentage = reference ? 100 * Number(value) / Number(reference) : 0;

  return ceil ? Math.ceil(percentage) : percentage;
}
