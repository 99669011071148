import React from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import classnames from 'classnames';
import Tooltip from '@/components/Tooltip';
import Paywall from '@/components/Paywall';
import { useAuth } from '@/lib/auth';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  policy: PropTypes.func.isRequired,
  data: PropTypes.object,
  tooltipClassName: PropTypes.string,
  tooltipContainerClassName: PropTypes.string
};

const defaultProps = {
  data: {},
  tooltipClassName: '',
  tooltipContainerClassName: '',
  fallback: null
};

function Authorization({ children, policy, data,
  tooltipClassName, tooltipContainerClassName, fallback }) {
  const { user } = useAuth();
  const { effect, reason, planName, redirectToUpgrade } = policy({ user, data });

  /**
   * Para aceitar a sintaxe de children sendo JSX e Function em casos que precisamos saber
   * quando o elemento está desabilitado
   */
  const render = (props = {}) => {
    if (isFunction(children)) {
      return children(props);
    }

    const child = React.Children.only(children);
    return React.cloneElement(child, {
      ...props,
      className: classnames(child.props.className, props.className)
    });
  };

  switch (effect) {
    case 'allow':
      return render();

    case 'disable':
      // https://getbootstrap.com/docs/4.1/components/tooltips/#disabled-elements
      return (
        <Tooltip content={reason} placement='right' className={tooltipClassName}>
          <div
            className={classnames(tooltipContainerClassName, 'd-inline-block')}
            tabIndex='0'
          >
            {render({
              disabled: true,
              className: 'pe-none'
            })}
          </div>
        </Tooltip>
      );

    case 'paywall':
      return (
        <Paywall content={reason} planName={planName} redirectToUpgrade={redirectToUpgrade}>
          {render()}
        </Paywall>
      );

    default:
      return fallback;
  }
}

Authorization.propTypes = propTypes;
Authorization.defaultProps = defaultProps;

export default Authorization;
