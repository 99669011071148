import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from '@/components/Icon';
import Popover from '@/components/Popover';

import { toCurrencyString } from '@/number';

const propTypes = {
  remainingDays: PropTypes.number.isRequired,
  planFullName: PropTypes.string.isRequired,
  currentPlanFullName: PropTypes.string.isRequired,
  valuePerMonth: PropTypes.number.isRequired,
  currentValuePerMonth: PropTypes.number.isRequired,
  diffValuePerMonth: PropTypes.number.isRequired,
  diffValuePerDay: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function ProratedSummary(props) {
  const {
    remainingDays,
    planFullName,
    currentPlanFullName,
    valuePerMonth,
    currentValuePerMonth,
    diffValuePerMonth,
    diffValuePerDay,
    value
  } = props;


  return (
    <Popover
      content={() => (
        <div
          id='prorated-summary'
          className={
            classnames(
              'w-100',
              'text-small',
              'bg-light'
            )
          }
        >
          <h4 className='mt-2 mb-4 text-dark-gray'>Resumo do pagamento da pró-rata</h4>

          <RowContent
            title={`Plano ${currentPlanFullName}:`}
            content={`${toCurrencyString(currentValuePerMonth)}/mês`}
          />

          <RowContent
            title={`Plano ${planFullName}:`}
            content={`${toCurrencyString(valuePerMonth)}/mês`}
          />

          <RowContent
            title='Diferença de valor:'
            content={`${toCurrencyString(diffValuePerMonth)}/mês (30 dias)`}
          />

          <RowContent
            title='Valor por dia:'
            content={`
              ${toCurrencyString(diffValuePerMonth)} / 30 = ${toCurrencyString(diffValuePerDay)}
            `}
          />

          <RowContent
            title='Quantidade de dias restantes:'
            content={remainingDays}
          />

          <RowContent
            title={`Valor da pró-rata para os ${remainingDays} dias restantes:`}
            content={`
              ${remainingDays} * ${toCurrencyString(diffValuePerDay)} = ${toCurrencyString(value)}
            `}
          />

          <hr className='mt-3 mb-4 opacity-100' />

          <div className='d-flex align-items-baseline justify-content-between mb-1'>
            <h4 className='text-dark-gray'>Valor total</h4>

            <h5>{toCurrencyString(value)}</h5>
          </div>

        </div>
      )}
      className='mw-100'
      contentClassName='px-3 py-2 bg-light rounded-1'
      trigger={['click']}
      placement='left-start'
      rootClose
      rootCloseEvent='mousedown'
      flip
    >
      <div className='d-flex align-items-center'>
        <Icon className='cursor-pointer' name='info' size='sm' />
      </div>
    </Popover>
  );
}

function RowContent({ title, content }) {
  return (
    <div className='d-flex align-items-baseline justify-content-between'>
      <div className='fw-bold text-dark-gray me-8'>{title}</div>

      <div className='ms-2'>{content}</div>
    </div>
  );
}

ProratedSummary.propTypes = propTypes;

export default ProratedSummary;
