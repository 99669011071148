import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { distanceFromNowInCalendarDays, pluralizeDaysRemainingText } from '@/date';
import { toCurrencyString } from '@/number';

import Button from '@/components/Button';
import Card from '@/components/Card';
import FeatureList from '@/components/Billing/FeatureList';
import Icon from '@/components/Icon';
import Badge from '@/components/Badge';

const propTypes = {
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  buttonText: PropTypes.string.isRequired,
  valueDescription: PropTypes.string,
  valueExtraDescription: PropTypes.string,
  featureList: PropTypes.array,
  featureListTitle: PropTypes.string,
  recommended: PropTypes.bool,
  customerSupportText: PropTypes.string,
  customerSupportImage: PropTypes.string,
  customerSupportDescription: PropTypes.string,
  expirationDate: PropTypes.string,
  actual: PropTypes.bool,
  trialActive: PropTypes.bool,
  onClick: PropTypes.func
};

function CardPlan({
  name,
  code,
  description,
  value,
  valueDescription,
  valueExtraDescription,
  featureList,
  featureListTitle,
  recommended,
  customerSupportText,
  customerSupportImage,
  customerSupportDescription,
  actual,
  trialActive,
  buttonText,
  expirationDate,
  onClick
}) {
  const formattedPrice = toCurrencyString(value, { removeTrailingZeros: true });
  const [finalPrice, finalCents] = formattedPrice.split(',');

  const isFree = code === 'free';

  const paidWithTrial = actual && trialActive && !isFree;
  const actualIsFree = actual && isFree;

  return (
    <div
      className={classnames('plan-card position-relative', { 'trial-active': paidWithTrial })}
    >
      {paidWithTrial && trialBadge(expirationDate)}

      <Card
        className={classnames(
          'w-100 h-100 bg-white rounded-top-0',
          {
            'border-primary': paidWithTrial,
            'border-white': !paidWithTrial
          }
        )}
      >
        <div className='d-flex flex-column px-6 py-4 h-100'>
          <div className='d-flex align-items-center'>
            <h2 className='flex-fill'>{name}</h2>

            {recommended && (
              <Badge
                pill
                className='align-self-center ms-2 py-1 px-3'
                variant='light-green'
              >
                Recomendado
              </Badge>
            )}
          </div>

          <div className='description mt-2 mb-4 text-dark-gray'>
            <p>{description}</p>
          </div>

          <div className='d-flex align-items-end'>
            <span className='display-6 fw-bold text-end lh-1'>{finalPrice}</span>
            <span className='mb-1 lh-1'>
              {Boolean(finalCents) && <b>,{finalCents}</b>}
              {Boolean(value) && '/mês'}
            </span>
          </div>

          <small className='text-dark-gray'>
            {valueDescription}
          </small>

          <Button
            variant={recommended ? 'primary' : 'outline-primary'}
            onClick={onClick}
            className='my-1 w-100'
            disabled={actualIsFree}
          >
            {actualIsFree ? 'Plano atual' : buttonText}
          </Button>

          <small className='text-dark-gray'>
            {valueExtraDescription}
          </small>

          <hr className='my-3' />

          <h4>{featureListTitle}</h4>

          <div className='flex-fill'>
            <FeatureList
              items={featureList}
            />
          </div>
          <hr className='my-3' />

          <FeatureList.Item
            text={customerSupportText}
            image={customerSupportImage}
            description={customerSupportDescription}
            icon={{
              name: 'check',
              className: 'text-primary'
            }}
          />
        </div>
      </Card>
    </div>
  );
}

function trialBadge(expirationDate) {
  const remainingDays = distanceFromNowInCalendarDays(expirationDate);
  const pluralizedDaysRemainingText = pluralizeDaysRemainingText(remainingDays);

  return (
    <div
      className={
        `trial-active-badge d-flex align-items-center justify-content-center
          bg-primary px-2 py-1 text-white fw-bold rounded-top`
      }
    >
      <Icon name='product' className='me-1 text-yellow' />

      Teste grátis ativo - {pluralizedDaysRemainingText}
    </div>
  );
}

CardPlan.propTypes = propTypes;

export default CardPlan;
