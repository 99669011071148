import { useQuery, useMutation, useQueryClient } from 'react-query';
import { get, post, _delete } from '@/lib/fetch';
import { accessoryCallCache } from '@/utils';

export const activityTemplatesKeys = {
  all: ['activity-templates'],
  lists: () => [...activityTemplatesKeys.all, 'list'],
  list: (params) => [...activityTemplatesKeys.lists(), params]
};

export async function getActivityTemplates(params = {}) {
  const { data } = await get('/activity_templates', params);
  return data;
}

export async function createActivityTemplate(params = {}) {
  const { data } = await post('/activity_templates', params);

  return data;
}

export async function deleteActivityTemplate({ id }) {
  const { data } = await _delete(`/activity_templates/${id}`);

  return data;
}

export function useActivityTemplates({ params = {}, config = {} } = {}) {
  return useQuery({
    ...accessoryCallCache,
    ...config,
    queryKey: activityTemplatesKeys.list(params),
    queryFn: () => getActivityTemplates(params)
  });
}

export function useCreateActivityTemplate({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries(activityTemplatesKeys.all);

  return useMutation(
    createActivityTemplate,
    {
      onSuccess,
      ...config
    }
  );
}

export function useDeleteActivityTemplate({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries(activityTemplatesKeys.all);

  return useMutation(
    deleteActivityTemplate,
    {
      onSuccess,
      ...config
    }
  );
}
