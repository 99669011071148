import React from 'react';
import PropTypes from 'prop-types';
import Select from '@/components/Inputs/Select';

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func
};

const defaultProps = {
  name: 'next_task',
  label: 'Próxima tarefa',
  multiple: false,
  isClearable: true,
  isSearchable: false,
  defaultValue: null,
  onChange: () => {}
};

const options = [
  { label: 'Próxima tarefa dentro do prazo', value: 'on_time' },
  { label: 'Próxima tarefa está atrasada', value: 'overdue' },
  { label: 'Sem tarefa agendada', value: 'no_schedule' }
];

function NextTaskSelect(props) {
  return <Select { ...props } options={options} />;
}

NextTaskSelect.propTypes = propTypes;
NextTaskSelect.defaultProps = defaultProps;

export default NextTaskSelect;
