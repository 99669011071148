import { useEffect, useRef } from 'react';
import { useTracking } from '@/lib/tracking';
import { useStoredState } from '@/hooks';

export function useHorizontalScrollTracker(filteredColumns) {
  const scrollableElementRef = useRef(null);
  const tracker = useTracking();
  const { value: hasDoneScrollWithDrag } = useStoredState('has_done_scroll_with_drag', false);

  useEffect(() => {
    if (!filteredColumns.length || !scrollableElementRef.current) {
      return;
    }

    const scrollableElement = scrollableElementRef.current;
    const hasHorizontalScrollbar = scrollableElement.scrollWidth > scrollableElement.clientWidth;

    if (hasHorizontalScrollbar) {
      tracker.trackListHorizontalScrollViewed({ hasDoneScrollWithDrag });
    }
  }, [filteredColumns]);

  return { scrollableElementRef };
}
