import React from 'react';

function SectionTitle({ actionName }) {
  let titleText;

  switch (actionName) {
    case 'schedule_activity':
      titleText = 'Agendar atividade automaticamente';
      break;
    case 'duplicate_deal':
      titleText = 'Criar negócio automaticamente';
      break;
    case 'send_email':
      titleText = 'Enviar e-mail automaticamente';
      break;
    default:
      titleText = 'Automações criadas';
  }

  return <h4 className='my-4'>{titleText}</h4>;
}

export default SectionTitle;
