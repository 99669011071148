import React from 'react';
import classnames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { Checkbox } from '@/components/Inputs';
import Footer from '@/components/LeadsGeneration/Footer';
import SearchSubSection from '@/components/LeadsGeneration/Segmentation/SearchSubSection';
import Icon from '@/components/Icon';
import Tooltip from '@/components/Tooltip';
import { useLeadsGenerationContext } from '@/contexts';

const DEPARTMENTS_OPTIONS = [
  {
    label: 'Administrativo',
    name: 'administrative'
  },
  {
    label: 'Comercial',
    name: 'commercial'
  },
  {
    label: 'Financeiro',
    name: 'financial'
  },
  {
    label: 'Gestão',
    name: 'management'
  },
  {
    label: 'Industrial',
    name: 'industrial'
  },
  {
    label: 'Jurídico',
    name: 'legal'
  },
  {
    label: 'Marketing',
    name: 'marketing'
  },
  {
    label: 'Proprietários',
    name: 'owners'
  },
  {
    label: 'RH',
    name: 'human_resources'
  },
  {
    label: 'Suprimento',
    name: 'supply'
  },
  {
    label: 'Operacional',
    name: 'operational'
  },
  {
    label: 'TI',
    name: 'it'
  },
  {
    label: 'Outros',
    name: 'others'
  }
];

const LEVEL_OPTIONS = [
  {
    label: 'Não classificado',
    name: 'no_defined',
    info: <>
      O nome do cargo pode ser
      desconhecido ou estar fora
      do critério de classificação do sistema.
      <br/>
      Marque essa opção
      para incluir mais decisores
      que podem estar em qualquer nível.
    </>
  },
  {
    label: 'Baixo',
    name: 'low',
    info: <>
      Ex: Assistente<br/>
      Analista<br/>
      Técnico
    </>
  },
  {
    label: 'Médio',
    name: 'medium',
    info: <>
      Ex: Gerente<br/>
      Coordenador<br/>
      Supervisor
    </>
  },
  {
    label: 'Alto',
    name: 'high',
    info: <>
      Ex: Diretor<br/>
      Executivo<br/>
      Proprietário
    </>
  }
];

function DecisionMakers() {
  const {
    decisionMakersDepartmentsData: departmentData,
    setDecisionMakersDepartmentsData: setDepartmentData,
    decisionMakersLevelsData: levelsData,
    setDecisionMakersLevelsData: setLevelsData,
    nextStep,
    prevStep,
    callMixpanelEvent
  } = useLeadsGenerationContext();

  const onClickNext = () => {
    callMixpanelEvent('trackLeadsGenerationSegmented');

    nextStep();
  };

  return (
    <>
      <SearchSubSection
        title='Você busca por decisores de quais departamentos?'
        icon='task'
      >
        {renderCheckList(DEPARTMENTS_OPTIONS, handleChange, departmentData, setDepartmentData)}
      </SearchSubSection>

      <SearchSubSection
        title='Nível do decisor'
        icon='task'
      >
        {renderCheckList(LEVEL_OPTIONS, handleChange, levelsData, setLevelsData)}
      </SearchSubSection>

      <Footer
        buttonNextText='Avançar'
        onClickPrev={prevStep}
        onClickNext={onClickNext}
        isCounterVisible
      />
    </>
  );
}

function renderCheckList(options, onChange, state, setState) {
  return (
    <Row className='d-flex flex-wrap ps-2 mb-6'>
      {options.map(({ label, name, info }, index) => {
        const itemsPerRow = 4;
        const defaultColSize = 2;
        const fisrtColSize = 3;
        const isFirstItem = (index % itemsPerRow) === 0;

        const colSize = isFirstItem ? fisrtColSize : defaultColSize;
        const colMarginEnd = isFirstItem ? 'me-4' : 'me-8';

        return (
          <Col
            key={index}
            sm={colSize}
            className={classnames('ps-1', 'pe-0', colMarginEnd)}
          >
            <div className='d-flex mb-4'>
              <Checkbox
                label={label}
                name={name}
                className='ms-0 mb-0 text-dark-gray fw-bold'
                value={state.includes(name)}
                onChange={(value) => onChange(name, value, setState)}
              />

              {info &&
                <Tooltip
                  placement='bottom'
                  content={<div className='my-n1 mx-n3'>{info}</div>}
                >
                  <div className='ms-1'>
                    <Icon className='text-dark-gray' name='info' />
                  </div>
                </Tooltip>
              }
            </div>
          </Col>
        );
      })}
    </Row>
  );
}

function handleChange(name, value, setState) {
  setState((prevData) => {
    const selectedItems = [...prevData];
    const index = selectedItems.indexOf(name);

    if (value && index === -1) {
      selectedItems.push(name);
    } else if (!value && index !== -1) {
      selectedItems.splice(index, 1);
    }

    return selectedItems;
  });
}

export default DecisionMakers;
