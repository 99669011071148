import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string
};

const defaultProps = {
  subtitle: ''
};

function PresentationCard({ image, title, subtitle, className }) {
  return (
    <div
      className={classnames(
        'd-flex',
        'align-items-center',
        'width-3',
        'p-3',
        'text-small',
        'border-0',
        'rounded-3',
        'presentation-card',
        className
      )}>
      <div className='d-flex gap-3 align-items-center'>
        <div className='align-self-center p-3 bg-lighter-gray rounded-1'>
          <img src={image} />
        </div>

        <div>
          <div className='fw-bold'>{title}</div>

          { subtitle && <span className='text-nowrap'>{subtitle}</span> }
        </div>
      </div>
    </div>
  );
}

PresentationCard.propTypes = propTypes;
PresentationCard.defaultProps = defaultProps;

export default PresentationCard;
