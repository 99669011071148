import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { RadioButton } from '@/components/Inputs';
import { useCheckoutContext } from '@/contexts';
import Badge from '@/components/Badge';
import { toDigitsString } from '@/number';

function DiscountBadge({ value }) {
  if (!value) {
    return null;
  }

  // `undefined` como segundo parâmetro para não exibir casas decimais em valores inteiros
  const percentageValue = toDigitsString(value, undefined);

  return (
    <Badge variant='light-green' className='rounded text-small'>
      -{percentageValue}%
    </Badge>
  );
}

function PeriodSelect() {
  const {
    setPeriod,
    period,
    paymentData,
    usersCount,
    plan,
    coupon
  } = useCheckoutContext();

  const options = buildOptions(coupon?.billingDiscount, plan, usersCount, paymentData.type);

  return (
    <Row id='period-select'>
      {
        options.map(({ label, value, discountValue, isDisabled }) => (
          <Col
            key={value}
            onClick={() => {
              if (!isDisabled) {
                setPeriod(value);
              }
            }}
            sm={6}
            className='p-2 cursor-pointer'
          >
            <div className='d-flex align-items-center justify-content-between bg-white p-2 rounded'>
              <RadioButton
                name='period'
                id={value}
                checkClassName='mb-0'
                label={<h5>{label}</h5>}
                radioValue={value}
                value={period}
                noFlex
                disabled={isDisabled}
              />

              <DiscountBadge value={discountValue} />
            </div>
          </Col>
        ))
      }
    </Row>
  );
}

function getCurrentDiscount(discount, period, usersCount, selectedPlan) {
  const periodDiscounts = {
    yearly: 10,
    half_yearly: 5,
    quarterly: 3
  };

  const defaultDiscount = periodDiscounts[period];

  const currentRule = discount?.billingDiscountRules
    .find((rule) => !rule.billingProductrule || rule.billingProduct.code === selectedPlan);

  if (!discount || !discount.discountType === 'percentage' || !currentRule) {
    return defaultDiscount;
  }

  const periodMonths = {
    yearly: 12,
    half_yearly: 6,
    quarterly: 3,
    monthly: 1
  };

  const months = periodMonths[period];
  if (Boolean(currentRule.minMonthsQuantity) && currentRule.minMonthsQuantity > months) {
    return defaultDiscount;
  }

  if (Boolean(currentRule.minQuantity) && currentRule.minQuantity > usersCount) {
    return defaultDiscount;
  }

  if (defaultDiscount > discount.percentOff) {
    return defaultDiscount;
  }

  return discount.percentOff;
}

function buildOptions(discount, selectedPlan, usersCount, paymentType) {
  return [
    {
      label: 'Anual',
      value: 'yearly',
      discountValue: getCurrentDiscount(discount, 'yearly', usersCount, selectedPlan)
    },
    {
      label: 'Semestral',
      value: 'half_yearly',
      discountValue: getCurrentDiscount(discount, 'half_yearly', usersCount, selectedPlan)
    },
    {
      label: 'Trimestral',
      value: 'quarterly',
      discountValue: getCurrentDiscount(discount, 'quarterly', usersCount, selectedPlan)
    },
    {
      label: 'Mensal',
      value: 'monthly',
      isDisabled: paymentType !== 'credit_card',
      discountValue: getCurrentDiscount(discount, 'monthly', usersCount, selectedPlan)
    }
  ];
}

export default PeriodSelect;
