import React from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import FetchableSelect from '@/components/Inputs/FetchableSelect';
import { useStages } from '@/api';

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  funnelId: PropTypes.number
};

const defaultProps = {
  name: 'stage_id_eq',
  label: 'Etapa',
  placeholder: 'Qualquer etapa',
  multiple: false,
  isClearable: false,
  isSearchable: false,
  defaultValue: null,
  onChange: () => {},
  funnelId: null
};

function itemTransformer(item) {
  return { label: `${item.order}. ${item.name}`, value: item.id };
}

function arrayTransformer(fetchedData) {
  return fetchedData.data.map(itemTransformer);
}

function groupTransformer(fetchedData) {
  return (
    sortBy(fetchedData.data, 'funnel.order').reduce((array, item) => {
      const groupName = item.funnel.name;
      const option = itemTransformer(item);

      const groupIndex = array.findIndex((group) => group.label === groupName);

      if (groupIndex === -1) {
        array.push({ label: groupName, options: [option] });
      } else {
        array[groupIndex].options.push(option);
      }

      return array;
    }, [])
  );
}

function StageSelect({ funnelId, ...props }) {
  let params = {};
  let transformer = groupTransformer;

  if (funnelId) {
    params = { funnel_id_eq: funnelId };
    transformer = arrayTransformer;
  }

  return (
    <FetchableSelect
      { ...props }
      transformer={transformer}
      query={useStages}
      queryArgs={{ params }}
    />
  );
}

StageSelect.propTypes = propTypes;
StageSelect.defaultProps = defaultProps;

export default StageSelect;
