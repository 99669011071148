/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import FormField from '@/components/FormField';
import {
  DecimalRangeInput,
  FetchableSelect,
  IntegerRangeInput,
  TextInput
} from '@/components/Inputs';
import { useQuery } from 'react-query';

function CustomFieldFormField({ field }) {
  function transformer(fetchedData) {
    return (
      fetchedData.data.map((item) => (
        { label: item.option, value: item.id }
      ))
    );
  }

  const fetchOptions = () => ({ data: field.customFieldOptions });

  function query() {
    return (
      useQuery(`options-${field.identifier}`, fetchOptions)
    );
  }
  switch (field.type) {
    case 'text':
      return (
        <FormField
          as={TextInput}
          name={`custom_fields.${field.id}.text_value_cont`}
          label={field.name}
          placeholder='Digite aqui'
          variant='light'
        />
      );
    case 'integer':
      return (
        <FormField
          as={IntegerRangeInput}
          name={`custom_fields.${field.id}`}
          label={field.name}
          fromName='integer_value_gteq'
          toName='integer_value_lteq'
        />
      );
    case 'decimal':
      return (
        <FormField
          as={DecimalRangeInput}
          name={`custom_fields.${field.id}`}
          label={field.name}
          fromName='decimal_value_gteq'
          toName='decimal_value_lteq'
        />
      );
    case 'select':
      return (
        <FormField
          as={FetchableSelect}
          multiple={true}
          isSearchable
          transformer={transformer}
          query={query}
          name={`custom_fields.${field.id}.select_value_in`}
          label={field.name}
          placeholder='Selecione uma ou mais opções'
          variant='light'
        />
      );
    case 'multiselect':
      return (
        <FormField
          as={FetchableSelect}
          multiple={true}
          isSearchable
          transformer={transformer}
          query={query}
          name={`custom_fields.${field.id}.multi_select_value_in`}
          label={field.name}
          placeholder='Selecione uma ou mais opções'
          variant='light'
        />
      );
    default:
      return null;
  }
}


function CustomFieldsFilters({ fields }) {
  return fields.map((field) => (
    <CustomFieldFormField field={field} key={field.id} />
  ));
}

export default CustomFieldsFilters;
export { CustomFieldFormField };
