import React from 'react';
import PropTypes from 'prop-types';
import Base from 'react-bootstrap/Card';
import Title from '@/components/Card/Title';
import Content from '@/components/Card/Content';
import FlexibleArea from '@/components/Card/FlexibleArea';
import Info from '@/components/Card/Info';
import Status from '@/components/Card/Status';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

const defaultProps = {
  children: null,
  className: ''
};

const Card = React.forwardRef((props, ref) => (
  <Base ref={ref} {...props} />
));

Card.displayName = 'Card';


Card.propTypes = propTypes;
Card.defaultProps = defaultProps;

Card.Header = Base.Header;
Card.Body = Base.Body;
Card.Title = Title;
Card.Content = Content;
Card.FlexibleArea = FlexibleArea;
Card.Info = Info;
Card.Status = Status;
Card.Text = Base.Text;

export default Card;
