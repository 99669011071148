import React, { useState, useRef, useMemo, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import APIErrorMessage from '@/components/APIErrorMessage';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import Wrapper from '@/components/SearchBar/Wrapper';
import { TextInput } from '@/components/Inputs';
import { useInfiniteSearch } from '@/api';
import classnames from 'classnames';

const propTypes = {
  ItemComponent: PropTypes.elementType.isRequired,
  name: PropTypes.string.isRequired,
  resultsFooter: PropTypes.func,
  types: PropTypes.arrayOf(PropTypes.oneOf(['organization', 'person', 'deal'])),
  perPage: PropTypes.number,
  inputProps: PropTypes.object,
  popoverProps: PropTypes.object,
  className: PropTypes.string,
  onSelect: PropTypes.func,
  onSearchStart: PropTypes.func
};

const defaultProps = {
  resultsFooter: () => {},
  types: ['organization', 'person', 'deal'],
  perPage: 10,
  inputProps: {},
  popoverProps: {},
  className: '',
  onSelect: () => {},
  onSearchStart: () => {}
};

const MIN_LENGTH = 2;

const SearchBar = forwardRef((props, ref) => {
  const {
    name, types, className, perPage, resultsFooter, ItemComponent,
    inputProps, popoverProps,
    onSelect, onSearchStart, searchValue
  } = props;

  const [enabled, setEnabled] = useState(false);
  const [params, setParams] = useState({ types, per_page: perPage });
  const [term, setTerm] = useState('');
  const alert = useAlert();
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    hasSearchText: () => Boolean(term)
  }), [term]);

  const defaultValues = useMemo(() => ({
    q: searchValue || ''
  }), [searchValue]);

  const {
    isFetching, hasNextPage, fetchNextPage, data = { pages: [] }
  } = useInfiniteSearch({
    params,
    config: {
      enabled,
      cacheTime: 0,
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='search' action='show' />,
          { variant: 'danger' }
        );
      }
    }
  });

  const results = data.pages.flatMap((page) => page.data);

  const onSubmit = (submitParams) => {
    const value = submitParams.q.trim();

    if (value.length >= MIN_LENGTH) {
      setParams({ ...params, q: value });
      setTerm(value);
      setEnabled(true);
      onSearchStart();
    } else {
      setTerm(value);
      setEnabled(false);
    }
  };

  return (
    <Wrapper
      isFetching={isFetching}
      canFetchMore={hasNextPage}
      fetchMore={fetchNextPage}
      hide={!enabled}
      items={results}
      term={term}
      footer={resultsFooter}
      ItemComponent={ItemComponent}
      popoverProps={popoverProps}
      formRef={formRef}
      onSelect={onSelect}
    >
      <div className={classnames('position-relative', className)} ref={formRef}>
        <Form
          onSubmit={onSubmit}
          name={name}
          defaultValues={defaultValues}
        >
          {({ debouncedSubmitForm }) => (
            <FormField
              as={TextInput}
              onChange={debouncedSubmitForm}
              name='q'
              className='mb-0'
              { ...inputProps }
            />
          )}
        </Form>
      </div>
    </Wrapper>
  );
});

SearchBar.displayName = 'SearchBar';
SearchBar.propTypes = propTypes;
SearchBar.defaultProps = defaultProps;

export default SearchBar;
