import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Collapse from '@/components/Collapse';

const propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
  className: PropTypes.string
};

const defaultProps = {
  open: true,
  className: ''
};

function FlexCollapse({ children, open, className }) {
  const [opened, setOpened] = useState(open);
  const [exiting, setExiting] = useState(false);

  return (
    <div className={classnames(
      'd-flex',
      'position-relative',
      'flexible-height',
      { 'flex-grow-1': opened && !exiting }
    )}>
      <Collapse in={open} dimension='height'
        onEnter={() => setOpened(true)}
        onExiting={() => setExiting(true)}
        onExited={() => {
          setOpened(false);
          setExiting(false);
        }}
        timeout={200}
      >
        <div className={classnames(
          className,
          { 'd-flex flex-grow-1 flexible-height': opened }
        )}>
          {children}
        </div>
      </Collapse>
    </div>
  );
}

FlexCollapse.propTypes = propTypes;
FlexCollapse.defaultProps = defaultProps;

export default FlexCollapse;
