import { addHours } from 'date-fns';
import Cookies from 'universal-cookie';
import { COOKIE_DOMAIN } from '@/config';

const ALL_DOMAINS = 'all';
const LEGACY_ALERT_COOKIE_NAME = 'formAlerta';

export function selectElement(e) {
  e.stopPropagation();

  const range = document.createRange();
  range.selectNodeContents(e.target);

  const selection = window.getSelection();
  selection.removeAllRanges();
  selection.addRange(range);
}

export function redirect(path) {
  window.location.href = path;
}

export function openNewTab(path) {
  window.open(path, '_blank');
}

export function goBack() {
  window.history.go(-1);
}

export function getCookie(name, options = {}) {
  const cookies = new Cookies();

  return cookies.get(name, options);
}
/**
 * Salva mensagem de alerta para no cookie para o sistema legado exibir
 *
 * @param {string} message mensagem a ser exibida
 * @param {'success'|'error'} type tipo de alerta
 */
export function setLegacyAlertMessage(message, type) {
  setCookie(LEGACY_ALERT_COOKIE_NAME, encodeURI(`${type}|${message}`), {
    domain: 'all',
    expires: addHours(new Date(), 1)
  });
}

export function setCookie(name, value, options = {}) {
  const cookies = new Cookies();

  if (isForAllDomains(options.domain)) {
    options.domain = COOKIE_DOMAIN;
  }

  cookies.set(name, value, { path: '/', ...options });
}

function isForAllDomains(domain) {
  return domain && domain === ALL_DOMAINS;
}
