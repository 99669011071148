import React, { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useCreateImportViews, useCreateImportConfirms } from '@/api';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import Alert from '@/components/Alert';
import APIErrorMessage from '@/components/APIErrorMessage';
import EntityIcon from '@/components/EntityIcon';
import ImportTable from '@/components/Imports/ImportTable';
import DuplicationCriteriasForm from '@/components/Imports/DuplicationCriteriasForm';

const propTypes = {
  returnStep: PropTypes.func,
  nextStepButtonRef: PropTypes.object,
  previousStepButtonRef: PropTypes.object,
  mappedData: PropTypes.object,
  resource: PropTypes.string,
  file: PropTypes.object
};

const RESOURCE_VARIANTS = {
  people: 'person',
  organizations: 'organization',
  products: 'product',
  activities: 'task',
  deals: 'deal'
};

const RESOURCE_LABELS = {
  people: 'Contato/Pessoa da empresa',
  organizations: 'Empresa',
  products: 'Produto',
  activities: 'Comentário',
  deals: 'Negócio'
};

const RESOURCE_DUPLICATION = {
  organizations: [
    {
      value: 'name',
      title: 'Nome',
      isRequired: true,
      isUnique: true
    },
    {
      value: 'legal_name',
      title: 'Razão Social',
      isRequired: false,
      isUnique: false
    },
    {
      value: 'cnpj',
      title: 'CNPJ',
      isRequired: false,
      isUnique: true
    },
    {
      value: 'email',
      title: 'E-mail',
      isRequired: false,
      isUnique: false
    },
    {
      value: 'whatsapp',
      title: 'WhatsApp',
      isRequired: false,
      isUnique: false
    },
    {
      value: 'work_phone',
      title: 'Telefone',
      isRequired: false,
      isUnique: false
    },
    {
      value: 'mobile_phone',
      title: 'Celular',
      isRequired: false,
      isUnique: false
    },
    {
      value: 'website',
      title: 'Website',
      isRequired: false,
      isUnique: false
    },
    {
      value: 'linkedin',
      title: 'LinkedIn',
      isRequired: false,
      isUnique: false
    }
  ],
  people: [
    {
      value: 'cpf',
      title: 'CPF',
      isRequired: false,
      isUnique: true
    },
    {
      value: 'email',
      title: 'E-mail',
      isRequired: false,
      isUnique: false
    },
    {
      value: 'whatsapp',
      title: 'WhatsApp',
      isRequired: false,
      isUnique: false
    },
    {
      value: 'work_phone',
      title: 'Telefone',
      isRequired: false,
      isUnique: false
    },
    {
      value: 'mobile_phone',
      title: 'Celular',
      isRequired: false,
      isUnique: false
    },
    {
      value: 'linkedin',
      title: 'LinkedIn',
      isRequired: false,
      isUnique: false
    }
  ],
  products: null,
  activities: null,
  deals: null
};

function ConfirmationStep(props) {
  const {
    returnStep, previousStepButtonRef, nextStepButtonRef,
    mappedData, resource, file, customFieldsColumns, onSuccess
  } = props;

  const [viewData, setViewData] = useState(null);
  const [showAlert, setShowAlert] = useState(true);
  const [duplicationCriterias, setDuplicationCriterias] = useState([]);

  const alert = useAlert();

  const { user } = useAuth();
  const tracker = useTracking();

  const createImportViews = useCreateImportViews();
  const createImportConfirms = useCreateImportConfirms();

  const mappings = mappedData.mappings.map(
    (element) => (element.target !== 'ignore' ? element : { target: null })
  );

  useEffect(() => {
    createImportViews.mutate(
      {
        resource,
        file: file.response.body,
        mappings
      },
      { onSuccess: ({ data }) => setViewData(data) }
    );
  }, []);

  if (!viewData) {
    return null;
  }

  const finalColumns = { ...viewData.defaultColumns, ...customFieldsColumns };

  const mappedColumnsTargets = viewData.mapping.map((map) => map.target);
  const tableColumnsNames = mappedColumnsTargets.map((target) => {
    const column = finalColumns[target];

    const columnName = column.name;
    const requiredSignal = column.required ? '*' : '';

    return `${columnName}${requiredSignal}`;
  });

  const duplicationCriteriasOptions = RESOURCE_DUPLICATION[resource];
  const showDuplicationForms = Boolean(duplicationCriteriasOptions);

  const handleConfimation = () => {
    tracker.trackNewImportVisualization({ user, entity: resource });
    createImportConfirms.mutate(
      {
        resource,
        file: file.response.body,
        mappings,
        duplication: {
          fields: duplicationCriterias
        }
      },
      {
        onSuccess: ({ data: { id } }) => {
          onSuccess(id);
        },
        onError: (err) => {
          if (err.data?.errors?.account?.includes('importing')) {
            alert.show(
              'Uma importação já está em andamento. Aguarde ela finalizar para realizar uma nova.',
              { variant: 'warning' }
            );
          } else {
            alert.show(
              <APIErrorMessage err={err} resource='import' action='create' />,
              { variant: 'danger' }
            );
          }
        }
      }
    );
  };

  return (
    <>
      {showDuplicationForms && (
        <div className='mt-8 text-dark-gray'>
          <h5>
            Deseja ignorar registros duplicados?
          </h5>
          <div className='text-small mt-2'>
            Configure mais critérios no botão <b>Configurar duplicidade</b>.
          </div>
        </div>
      )}
      {
        showAlert &&
        <Alert
          variant='light'
          className='mt-4 mb-0 w-100 bg-light-yellow'
          onClose={() => setShowAlert(false)}
        >
          O quadro abaixo mostra como seus dados ficarão cadastrados.
          Esta tabela mostra as 50 primeiras linhas de sua planilha.
        </Alert>
      }

      <div
        className={classnames(
          'd-flex',
          'flex-column',
          'flex-grow-1',
          'min-height-6',
          'px-8',
          'pt-8',
          'mt-4',
          'border',
          'border-light'
        )}
      >
        <div
          className={classnames(
            'd-flex',
            'flex-column',
            'flex-grow-1',
            'flexible-height'
          )}
        >
          <h5 className='mb-4 text-dark-gray'>
            Confira se está tudo correto na tabela e finalize sua importação
          </h5>

          <div className='mb-6 d-flex justify-content-between'>
            <div>
              <EntityIcon type={RESOURCE_VARIANTS[resource]}/>

              <span className='ms-4 fw-bold text-dark-gray'>
                {RESOURCE_LABELS[resource]}
              </span>
            </div>

            {showDuplicationForms &&
              <DuplicationCriteriasForm
                options={duplicationCriteriasOptions}
                duplicationCriterias={duplicationCriterias}
                onSubmit={setDuplicationCriterias}
              />
            }
          </div>

          <div
            className={classnames(
              'd-flex',
              'flex-column',
              'flex-grow-1',
              'flexible-height',
              'overflow-x-scroll',
              'overflow-y-scroll',
              'me-n4',
              'ps-3'
            )}
          >
            <ImportTable
              fixedHead
              tableColumnsData={viewData.mapping}
              tableColumnsNames={tableColumnsNames}
            />
          </div>
        </div>
      </div>

      <input
        ref={previousStepButtonRef}
        type='button'
        className='hidden-button'
        onClick={returnStep}
      />

      <input
        ref={nextStepButtonRef}
        type='button'
        className='hidden-button'
        onClick={handleConfimation}
      />
    </>
  );
}

ConfirmationStep.propTypes = propTypes;

export default ConfirmationStep;
