function canPerform({ label, user, _data }) {
  let effect, reason;
  const planName = 'Performance';
  let isNewBillingPro = false;

  if (!user.account.legacyPro && !user.account.performanceOrHigher) {
    if (user.account.newBilling && user.account.paid) {
      isNewBillingPro = true;
    }
    effect = 'paywall';
    reason =
      'Para personalizar as etapas do seu processo comercial, ' +
      'é necessário fazer upgrade para o Plano Performance.';
  } else if (!user.admin) {
    effect = 'disable';
    reason = `Apenas administradores podem ${label} regras de campos obrigatórios.`;
  } else {
    effect = 'allow';
  }

  return { effect, reason, planName, redirectToUpgrade: isNewBillingPro };
}

export function canCreateRequiredField(params) {
  return canPerform({ label: 'criar', ...params });
}

export function canUpdateRequiredField(params) {
  return canPerform({ label: 'editar', ...params });
}

export function canDeleteRequiredField(params) {
  return canPerform({ label: 'excluir', ...params });
}
