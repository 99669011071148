import { useQuery } from 'react-query';
import { get } from '@/lib/fetch';

export const personCalculationsKeys = {
  all: ['person-calculations'],
  allParams: (params) => ([...personCalculationsKeys.all, params])
};

export async function getPersonCalculations(params = {}) {
  const { data } = await get('/person_calculations', params);

  return data;
}

export function usePersonCalculations({ params = {}, config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: personCalculationsKeys.allParams(params),
    queryFn: () => getPersonCalculations(params)
  });
}
