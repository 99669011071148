import React from 'react';
import PropTypes from 'prop-types';
import Base from 'react-bootstrap/FormCheck';

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  className: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func
};

const defaultProps = {
  className: null,
  value: null,
  onChange: null
};

const Checkbox = React.forwardRef(
  (props, ref) => {
    const { name, value, onChange, ...rest } = props;

    const handleChange = onChange
      ? (event) => onChange(event.target.checked)
      : null;
    const checked = onChange ? Boolean(value) : value;

    return (
      <Base
        type='checkbox'
        checked={checked}
        onChange={handleChange}
        ref={ref}
        name={name}
        id={name}
        { ...rest }
      />
    );
  }
);

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;
Checkbox.displayName = 'Checkbox';

export default Checkbox;
