import React from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useAlert } from 'react-alert';
import APIErrorMessage from '@/components/APIErrorMessage';
import RequiredFieldForm from '@/components/RequiredFieldForm';
import { settingsFunnelStagesPath } from '@/routes';
import { goBack } from '@/browser';
import { useCreateRequiredField, useUpdateRequiredField } from '@/api';
import { useTracking } from '@/lib/tracking';
import { useAuth } from '@/lib/auth';

/* eslint-disable no-magic-numbers */
const validationSchema = Yup.object().shape({
  field: Yup.string().nullable()
    .required('Por favor, selecione um campo.'),
  question: Yup.string().max(150, 'Pergunta deve ter no máximo 150 caracteres.')
});
/* eslint-enable no-magic-numbers */

function FunnelStagesRequiredFieldsNew() {
  const { funnelId } = useParams();
  const location = useLocation();
  const target = location.state?.target;

  const alert = useAlert();
  const tracker = useTracking();
  const { user } = useAuth();
  const createRequiredField = useCreateRequiredField();
  const updateRequiredField = useUpdateRequiredField();
  const onSubmit = async ({ duplication, ...formData }) => {
    const targetParam = target.type === 'stage'
      ? 'stage_id'
      : 'deal_status_id';

    let action = { name: 'create', label: 'criada' };
    try {
      if (duplication) {
        action = { name: 'update', label: 'atualizada' };

        const response = await updateRequiredField.mutateAsync({
          id: duplication.id,
          params: {
            [targetParam]: target.id,
            ...formData
          }
        });
        tracker.trackRequiredFieldUpdated({
          user,
          funnelId,
          target,
          field: response.data,
          formData
        });
      } else {
        const response = await createRequiredField.mutateAsync({
          funnel_id: funnelId,
          [targetParam]: target.id,
          ...formData
        });
        tracker.trackRequiredFieldCreated({
          user,
          funnelId,
          target,
          field: response.data,
          formData
        });
      }

      alert.show(
        `Regra de campo obrigatório ${action.label} com sucesso.`,
        { variant: 'success', timeout: 5000 }
      );

      goBack();
    } catch (err) {
      alert.show(
        <APIErrorMessage
          err={err}
          resource='required_field'
          action={action.name}
        />,
        { variant: 'danger' }
      );
    }
  };

  if (!target) {
    return <Navigate to={settingsFunnelStagesPath(funnelId)} />;
  }

  return (
    <RequiredFieldForm
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      target={target}
    />
  );
}

export default FunnelStagesRequiredFieldsNew;
