import React, { useRef } from 'react';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormField from '@/components/FormField';
import DependentFormField from '@/components/DependentFormField';
import {
  CitySelect, MaskedInput, StateSelect, TextInput
} from '@/components/Inputs';
import { useAutoCompleteCep } from '@/hooks';

const propTypes = {};

const defaultProps = {};

/* eslint-disable no-magic-numbers */
export const schema = Yup.object().shape({
  postal_code: Yup.string()
    .length(8, 'CEP deve ter 8 dígitos.'),
  country: Yup.string()
    .max(60, 'País deve ter no máximo 60 caracteres.'),
  district: Yup.string()
    .max(60, 'Bairro deve ter no máximo 60 caracteres.'),
  street_name: Yup.string()
    .max(255, 'Rua deve ter no máximo 255 caracteres.'),
  street_number: Yup.number().integer(),
  additional_info: Yup.string()
    .max(255, 'Complemento deve ter no máximo 255 caracteres.')
});
/* eslint-enable no-magic-numbers */

export const defaultValues = {
  address: {
    postal_code: '',
    country: '',
    district: '',
    street_name: '',
    street_number: '',
    additional_info: '',
    state: '',
    city_id: ''
  }
};

/**
 * Conjunto de campos que compõem um endereço, com a funcionalidade de completar
 * os campos de país, estado, cidade, bairro e rua automaticamente após o
 * preenchimento do campo de CEP.
 *
 * Deve ser utilizado dentro de um `Form`, e seus campos serão incluídos na
 * chave `address`.
 */
function AddressFields() {
  const numberRef = useRef(null);

  const { setFieldValue, handleBlur } = useFormikContext();

  const { isFetching, onPostalCodeBlur } = useAutoCompleteCep({
    postalCodeFieldName: 'address.postal_code',
    onSuccess: (data) => {
      ['district', 'state', 'country'].forEach((key) => {
        setFieldValue(`address.${key}`, data[key]);
      });

      setFieldValue('address.street_name', data.streetName);
      setFieldValue('address.city_id', data.city?.id);

      if (numberRef.current) {
        numberRef.current.focus();
      }
    }
  });

  return (
    <fieldset>
      <Row className='gx-3'>
        <Col sm={3}>
          <FormField
            as={MaskedInput}
            name='address.postal_code'
            label='CEP'
            onBlur={onPostalCodeBlur}
            maskType='postalCode'
            placeholder='00000-000'
            readOnly={isFetching}
            autoComplete='off'
            loading={isFetching}
            loadingMessage='Buscando...'
          />
        </Col>

        <Col sm={6}>
          <FormField
            as={TextInput}
            name='address.country'
            label='País'
            placeholder='Brasil'
            maxLength={60}
            onBlur={handleBlur}
            autoComplete='off'
          />
        </Col>

        <Col sm={3}>
          <FormField
            as={StateSelect}
            name='address.state'
            placeholder='Selecione'
          />
        </Col>
      </Row>

      <Row className='gx-3'>
        <Col sm={6}>
          <DependentFormField
            as={CitySelect}
            name='address.city_id'
            placeholder='Selecione'
            source='address.state'
            target='state'
            isSearchable
          />
        </Col>

        <Col sm={6}>
          <FormField
            as={TextInput}
            name='address.district'
            label='Bairro'
            placeholder='Bairro X'
            maxLength={60}
            onBlur={handleBlur}
            autoComplete='off'
          />
        </Col>
      </Row>

      <Row className='gx-3'>
        <Col sm={6}>
          <FormField
            as={TextInput}
            name='address.street_name'
            label='Rua'
            placeholder='Rua Y'
            maxLength={255}
            onBlur={handleBlur}
            autoComplete='off'
          />
        </Col>

        <Col sm={2}>
          <FormField
            as={MaskedInput}
            name='address.street_number'
            label='Número'
            unmask='typed'
            placeholder='77'
            maskType='natural'
            inputRef={numberRef}
            onBlur={handleBlur}
            autoComplete='off'
          />
        </Col>

        <Col sm={4}>
          <FormField
            as={TextInput}
            name='address.additional_info'
            label='Complemento'
            placeholder='Sala 153, Bloco B'
            maxLength={255}
            onBlur={handleBlur}
            autoComplete='off'
          />
        </Col>
      </Row>
    </fieldset>
  );
}

AddressFields.propTypes = propTypes;
AddressFields.defaultProps = defaultProps;

export default AddressFields;
