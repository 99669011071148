import React, { useState } from 'react';
import classnames from 'classnames';
import { Col, Container, Row } from 'react-bootstrap';
import { useIntercom } from 'react-use-intercom';
import { useNavigate } from 'react-router-dom';

import { useBillingProducts, useCurrentPlan } from '@/api';
import { useDocumentTitle } from '@/hooks';
import { checkoutPath } from '@/routes';

import Badge from '@/components/Badge';
import Button from '@/components/Button';
import CardPlan from '@/components/Billing/CardPlan';
import CurrentPlanTrialCard from '@/components/Billing/CurrentPlanTrialCard';
import DowngradeFreeModal from '@/components/Billing/DowngradeFreeModal';
import Main from '@/components/Main';
import PlanConversionModal from '@/components/PlanConversionModal';
import { Switch } from '@/components/Inputs';

const PAGE_TITLE = 'Planos e preços';

const proPlanInfo = [
  {
    image: 'https://agendor-files.s3.sa-east-1.amazonaws.com/product/plans-features/customizations.png',
    text: 'Personalizações de funis e campos de cadastro',
    description: `
      Crie múltiplos funis para gerenciar diferentes processos e personalize
      suas etapas e motivos de perda. Cadastre campos personalizados para clientes.
    `,
    icon: {
      name: 'check',
      className: 'text-primary'
    }
  },
  {
    image: 'https://agendor-files.s3.sa-east-1.amazonaws.com/product/plans-features/products.gif',
    text: 'Portfólio de produtos e serviços',
    description: `
      Cadastre seus produtos e serviços no Agendor e agilize o preenchimento de
      informações de oportunidades.
    `,
    icon: {
      name: 'check',
      className: 'text-primary'
    }
  },
  {
    image: 'https://agendor-files.s3.sa-east-1.amazonaws.com/product/plans-features/dashboard.png',
    text: 'Relatório de vendas e dashboard comercial',
    description: `
      Visualize, em tempo real, suas principais métricas de vendas e o
      desempenho da equipe comercial.
    `,
    icon: {
      name: 'check',
      className: 'text-primary'
    }
  },
  {
    image: 'https://agendor-files.s3.sa-east-1.amazonaws.com/product/plans-features/integrations.png',
    text: 'Integração com +1.000 softwares e API ',
    description: `
      Conecte o Agendor ao seu site e outros softwares e sistemas internos através da nossa
      API aberta ou dos nossos parceiros de integração (Zapier e Pluga).
    `,
    icon: {
      name: 'check',
      className: 'text-primary'
    }
  }
];

const performancePlanInfo = [
  {
    image: 'https://agendor-files.s3.sa-east-1.amazonaws.com/product/plans-features/automations.gif',
    text: 'Automações de tarefas, negócios e e-mail',
    description: `
      Automatize tarefas repetitivas no Agendor e reduza o trabalho manual da sua equipe.
    `,
    icon: {
      name: 'check',
      className: 'text-primary'
    }
  },
  {
    image: 'https://agendor-files.s3.sa-east-1.amazonaws.com/product/plans-features/required_fields.png',
    text: 'Qualificação obrigatória de negócios',
    description: `
      Defina quais campos devem ser preenchidos para avançar seus negócios no funil
      de vendas.
    `,
    icon: {
      name: 'check',
      className: 'text-primary'
    }
  },
  {
    image: 'https://agendor-files.s3.sa-east-1.amazonaws.com/product/plans-features/modelos-de-proposta.png',
    text: 'Modelos de propostas comerciais',
    description: `
      Salve modelos de propostas personalizados e utilize os modelos pré-definidos já criados pelo
      Agendor
    `,
    icon: {
      name: 'check',
      className: 'text-primary'
    }
  },
  {
    image: 'https://agendor-files.s3.sa-east-1.amazonaws.com/product/plans-features/telefone-virtual-inteligente.gif',
    text: 'Telefone virtual inteligente',
    description: `
      Automatize o preenchimento do CRM após uma ligação com uso de Inteligência Artificial
      que gera um resumo da conversa
    `,
    icon: {
      name: 'check',
      className: 'text-primary'
    }
  }
];

const corporatePlanInfo = [
  {
    text: 'Envio de Nota Fiscal antecipada',
    dashed: false,
    icon: {
      name: 'check',
      className: 'text-primary'
    }
  },
  {
    text: 'Contrato específico',
    description: `
      Possibilita assinarmos os contratos específicos de sua empresa, além dos contratos
      padrão que já disponibilizamos para todos os clientes, nossos Termos de Uso e
      Políticas de Privacidade.
    `,
    icon: {
      name: 'check',
      className: 'text-primary'
    }
  },
  {
    text: 'Gerente de conta',
    description: `
      Conte com o apoio e o atendimento exclusivos de uma especialista selecionada
      pelo nosso time de Sucesso do Cliente.
    `,
    icon: {
      name: 'check',
      className: 'text-primary'
    }
  }
];

const genericCustomerSupportInfo = {
  text: 'Suporte via e-mail, chat e telefone',
  image: 'https://agendor-files.s3.sa-east-1.amazonaws.com/product/plans-features/customer_support.png',
  description: `
    Para dúvidas, esclarecimentos ou sugestões, chame nosso time de suporte por e-mail,
    telefone ou chat na plataforma. Atendemos em horário comercial.
  `
};

function Plans() {
  const [yearly, setYearly] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showPlanConversionModal, setShowPlanConversionModal] = useState(false);
  const [showDowngradeModal, setShowDowngradeModal] = useState(false);
  const navigate = useNavigate();
  const { showNewMessages } = useIntercom();

  useDocumentTitle(PAGE_TITLE);

  const { isSuccess, data } = useCurrentPlan({
    params: {
      reduced: true,
      migrate: true
    }
  });

  const { data: { data: planProducts } = {}, isSuccess: isProductsSuccess } = useBillingProducts({
    params: { from_plan: true }
  });

  if (!isSuccess || !isProductsSuccess) {
    return <div className='flex-grow-1 bg-light' />;
  }

  const {
    expired,
    subscriptionType,
    name,
    signupIntention,
    expirationDate,
    maxUsersCount,
    trialAvailable,
    code
  } = data?.data || {};

  const isFree = subscriptionType === 'free';
  const isTrial = subscriptionType === 'trial';
  const trialExpired = expired && isTrial;
  const trialActive = !expired && isTrial;

  const redirectToCheckout = (param) => {
    const queryParams = new URLSearchParams();
    const period = yearly ? 'yearly' : 'monthly';

    if (param) {
      queryParams.set('plan', param);
    }
    queryParams.set('period', period);

    const url = `${checkoutPath()}?${queryParams.toString()}`;

    navigate(url);
  };

  const calculateValue = (value, yearlyValue) => {
    if (yearly) {
      return yearlyValue;
    }

    return value;
  };

  const paidValueDescription = `Por usuário, pagando ${yearly ? 'anualmente' : 'mensalmente'}`;

  const paidButtonText =
    isFree && trialAvailable ? 'Experimentar grátis por 7 dias' : 'Escolher plano';

  const handleOnClick = (product) => {
    if (isFree && trialAvailable) {
      setSelectedProduct({
        productCode: product.code
      });
      setShowPlanConversionModal(true);
    } else {
      redirectToCheckout(product.code);
    }
  };

  const plansData = {
    pro: {
      name: 'Pro',
      description: 'Com recursos essenciais para profissionalizar a área comercial',
      valueDescription: paidValueDescription,
      featureListTitle: 'Tudo do Plano Gratuito, mais:',
      featureList: proPlanInfo,
      buttonText: paidButtonText,
      customerSupportInfo: genericCustomerSupportInfo,
      onClick: handleOnClick,
      order: {
        sm: 2,
        md: 3,
        xl: 1
      }
    },
    performance: {
      recommended: true,
      name: 'Performance',
      description: 'Com recursos avançados para equipes que buscam alta performance em vendas',
      valueDescription: paidValueDescription,
      featureListTitle: 'Tudo do Plano Pro, mais:',
      featureList: performancePlanInfo,
      buttonText: paidButtonText,
      customerSupportInfo: genericCustomerSupportInfo,
      onClick: handleOnClick,
      order: {
        sm: 1,
        md: 0,
        xl: 2
      }
    },
    corporate: {
      name: 'Corporativo',
      description: 'Para a sua empresa que precisa de um atendimento personalizado',
      valueDescription: paidValueDescription,
      valueExtraDescription: 'Mínimo 10 usuários',
      featureListTitle: 'Tudo do Plano Performance, mais:',
      featureList: corporatePlanInfo,
      buttonText: 'Fale com nosso time de vendas',
      customerSupportInfo: {
        text: 'Suporte prioritário por e-mail, chat e telefone',
        description: `Receba prioridade no atendimento do nosso time
          de suporte por e-mail, telefone ou chat na plataforma.`
      },
      onClick: () => showNewMessages(),
      order: {
        sm: 0,
        md: 1,
        xl: 3
      }
    }
  };

  return (
    <Main fluid className='py-8 ps-4 pe-8 bg-light'>
      <Container>
        {
          !trialExpired && (
            <>
              <div className='mb-6'>
                <h1 className='mb-2'>{PAGE_TITLE}</h1>
                <h3 className='fw-normal text-dark-gray'>
                  O CRM com o melhor custo-benefício do mercado.
                </h3>
              </div>

              {isTrial && (
                <CurrentPlanTrialCard
                  planName={name}
                  signupIntention={signupIntention}
                  expirationDate={expirationDate}
                  maxUsersCount={maxUsersCount}
                />
              )}
            </>
          )
        }

        {
          trialExpired && (
            <div className='p-3 rounded mb-2 bg-light-primary'>
              <h2>Seu período de teste terminou</h2>
              <span className='fs-4 text-dark-gray'>
                Escolha um plano que se encaixe no seu processo comercial e
                continue sua jornada com a gente.
              </span>
            </div>
          )
        }

        <div className='d-flex align-items-center my-6'>
          <span className={classnames('fs-4', { 'fw-bold': !yearly })}>Mensal</span>
          <Switch
            name='yearly'
            label=''
            checked={yearly}
            onChange={setYearly}
            className='me-2 mb-0'
          />
          <span className={classnames('fs-4', { 'fw-bold': yearly })}>Anual</span>

          <Badge
            pill
            className='align-self-center ms-2 py-1 px-2'
            variant={yearly ? 'light-green' : 'gray'}
          >
            10% DESCONTO
          </Badge>

          <hr className='flex-fill border-dark-gray' />
        </div>

        <Row className='mt-4'>
          {
            planProducts.map((product) => {
              const planData = plansData[product.planCode];

              if (!planData) {
                return null;
              }

              const isActual = product.planCode === code;

              return (
                <Col
                  key={product.planCode}
                  xs={12}
                  sm={{ span: 12, order: planData.order.sm }}
                  md={{ span: 6, order: planData.order.md }}
                  xl={{ span: 4, order: planData.order.xl }}
                >
                  <CardPlan
                    code={product.planCode}
                    name={planData.name}
                    description={planData.description}
                    value={calculateValue(product.value, product.yearlyValue)}
                    valueDescription={planData.valueDescription}
                    valueExtraDescription={planData.valueExtraDescription}
                    featureListTitle={planData.featureListTitle}
                    featureList={planData.featureList}
                    buttonText={planData.buttonText}
                    recommended={planData.recommended}
                    actual={isActual}
                    trialActive={trialActive}
                    expirationDate={expirationDate}
                    customerSupportText={planData.customerSupportInfo.text}
                    customerSupportDescription={planData.customerSupportInfo.description}
                    customerSupportImage={planData.customerSupportInfo.image}
                    onClick={() => planData.onClick(product)}
                  />
                </Col>
              );
            })
          }
        </Row>

        {isTrial && (
          <>
            <Row className='mt-2'>
              <Col className='text-center'>
                <span>
                  Você também pode
                  <Button
                    onClick={() => setShowDowngradeModal(true)}
                    variant='link'
                    className='fw-bold px-0'
                  >
                    reduzir para o Plano Gratuito
                  </Button>
                </span>
              </Col>
            </Row>

            <DowngradeFreeModal
              show={showDowngradeModal}
              setShow={setShowDowngradeModal}
            />
          </>
        )}

        {isFree && (
          <PlanConversionModal
            show={showPlanConversionModal}
            onHide={() => setShowPlanConversionModal(false)}
            productCode ={selectedProduct?.productCode}
            yearly={yearly}
          />
        )}
      </Container>
    </Main>
  );
}

export default Plans;
