import React from 'react';
import * as Yup from 'yup';
import {
  CategorySelect,
  CNPJField,
  CurrencyInput,
  DatePickerInput,
  MaskedInput,
  LeadOriginSelect,
  LossReasonSelect,
  ProductSelect,
  SectorSelect,
  StateSelect,
  CitySelect,
  TextArea,
  TextInput,
  WhatsAppInput
} from '@/components/Inputs';
import FormField from '@/components/FormField';
import DependentFormField from '@/components/DependentFormField';
import CustomField from '@/components/CustomFields/CustomField';
import { fromDMA } from '@/date';
import {
  decimalCustomFieldValidations,
  integerCustomFieldValidations,
  textCustomFieldValidations
} from '@/components/CustomFields/Types';
import RequiredFieldLabel from '@/components/RequiredFieldLabel';

/* eslint-disable no-magic-numbers */
export const defaultFields = {
  deal: {
    finished_at: {
      component: DatePickerInput,
      name: 'deal.finished_at',
      schema: Yup.string()
        .test(
          'isValidDate',
          'Data inválida.',
          (value) => !value || Boolean(fromDMA(value))
        )
    },
    value: {
      component: CurrencyInput,
      name: 'deal.value',
      schema: Yup.number()
        .typeError('O valor do negócio deve ser um número.')
        .moreThan(0, 'O valor do negócio deve ser maior que zero.')
    },
    description: {
      component: TextArea,
      name: 'deal.description',
      schema: Yup.string()
        .max(2000, 'A descrição do negócio deve ter no máximo 2000 caracteres.')
    },
    loss_reason: {
      component: LossReasonSelect,
      name: 'deal.deal_loss_reason.loss_reason_id',
      schema: Yup.string()
    },
    loss_reason_description: {
      component: TextArea,
      name: 'deal.deal_loss_reason.description',
      schema: Yup.string()
        .max(250, 'A descrição do motivo de perda deve ter no máximo 250 caracteres')
    },
    products: {
      component: ProductSelect,
      name: 'deal.products_id',
      schema: Yup.array(Yup.string()),
      props: {
        queryArgs: { params: { active_true: true } },
        multiple: true
      }
    }
  },
  person: {
    cpf: {
      component: MaskedInput,
      name: 'person.cpf',
      schema: Yup.string()
        .length(11, 'CPF inválido.'),
      props: {
        maskType: 'cpf'
      }
    },
    job_title: {
      component: TextInput,
      name: 'person.job_title',
      schema: Yup.string()
        .max(60, 'O cargo da pessoa deve ter no máximo 60 caracteres.')
    },
    birthday: {
      component: MaskedInput,
      name: 'person.birthday',
      schema: Yup.string()
        .length(5, 'Data de aniversário inválida.'),
      props: {
        maskType: 'monthDay'
      }
    },
    birth_year: {
      component: MaskedInput,
      name: 'person.birth_year',
      schema: Yup.number('Ano inválido.'),
      props: {
        maskType: 'natural'
      }
    },
    category: {
      component: CategorySelect,
      name: 'person.category_id',
      schema: Yup.mixed()
    },
    lead_origin: {
      component: LeadOriginSelect,
      name: 'person.lead_origin_id',
      schema: Yup.mixed()
    },
    description: {
      component: TextArea,
      name: 'person.description',
      schema: Yup.string()
        .max(700, 'A descrição da pessoa deve ter no máximo 700 caracteres.')
    },
    email: {
      component: TextInput,
      name: 'person.contact.email',
      schema: Yup.string()
        .email('E-mail inválido.')
    },
    whatsapp: {
      component: WhatsAppInput,
      name: 'person.contact.whatsapp',
      schema: Yup.string()
        .min(12, 'Número inválido.')
        .max(16, 'Número inválido.')
    },
    work_phone: {
      component: MaskedInput,
      name: 'person.contact.work_phone',
      schema: Yup.string()
        .min(10, 'Número inválido.')
        .max(11, 'Número inválido.'),
      props: {
        maskType: 'phone'
      }
    },
    mobile_phone: {
      component: MaskedInput,
      name: 'person.contact.mobile_phone',
      schema: Yup.string()
        .min(10, 'Número inválido.')
        .max(11, 'Número inválido.'),
      props: {
        maskType: 'phone'
      }
    },
    fax_phone: {
      component: MaskedInput,
      name: 'person.contact.fax_phone',
      schema: Yup.string()
        .min(10, 'Número inválido.')
        .max(11, 'Número inválido.'),
      props: {
        maskType: 'phone'
      }
    },
    phone_extension: {
      component: MaskedInput,
      name: 'person.contact.phone_extension',
      schema: Yup.string()
        .max(5, 'Ramal inválido'),
      props: {
        maskType: 'natural'
      }
    },
    postal_code: {
      component: MaskedInput,
      name: 'person.address.postal_code',
      schema: Yup.string()
        .length(8, 'CEP deve ter 8 dígitos.'),
      props: {
        maskType: 'postalCode'
      }
    },
    country: {
      component: TextInput,
      name: 'person.address.country',
      schema: Yup.string()
        .max(60, 'País deve ter no máximo 60 caracteres.')
    },
    state: {
      component: StateSelect,
      name: 'person.address.state',
      schema: Yup.string()
    },
    city: {
      component: CitySelect,
      name: 'person.address.city_id',
      schema: Yup.number().integer()
    },
    district: {
      component: TextInput,
      name: 'person.address.district',
      schema: Yup.string()
        .max(60, 'Bairro deve ter no máximo 60 caracteres.')
    },
    street_name: {
      component: TextInput,
      name: 'person.address.street_name',
      schema: Yup.string()
        .max(255, 'Rua deve ter no máximo 255 caracteres.')
    },
    street_number: {
      component: MaskedInput,
      name: 'person.address.street_number',
      schema: Yup.number().integer(),
      props: {
        maskType: 'natural'
      }
    },
    additional_info: {
      component: TextInput,
      name: 'person.address.additional_info',
      schema: Yup.string()
        .max(255, 'Complemento deve ter no máximo 255 caracteres.')
    },
    products: {
      component: ProductSelect,
      name: 'person.products_id',
      schema: Yup.array(Yup.string()),
      props: {
        queryArgs: { params: { active_true: true } },
        multiple: true
      }
    },
    facebook: {
      component: TextInput,
      name: 'person.contact.facebook',
      schema: Yup.string()
        .max(255, 'Facebook deve ter no máximo 255 caracteres.')
    },
    twitter: {
      component: TextInput,
      name: 'person.contact.twitter',
      schema: Yup.string()
        .max(255, 'Twitter deve ter no máximo 255 caracteres.')
    },
    linkedin: {
      component: TextInput,
      name: 'person.contact.linkedin',
      schema: Yup.string()
        .max(255, 'LinkedIn deve ter no máximo 255 caracteres.')
    },
    skype: {
      component: TextInput,
      name: 'person.contact.skype',
      schema: Yup.string()
        .max(255, 'Skype deve ter no máximo 255 caracteres.')
    },
    instagram: {
      component: TextInput,
      name: 'person.contact.instagram',
      schema: Yup.string()
        .max(255, 'Instagram deve ter no máximo 255 caracteres.')
    }
  },
  organization: {
    legal_name: {
      component: TextInput,
      name: 'organization.legal_name',
      schema: Yup.string()
        .max(255, 'A razão social da empresa deve ter no máximo 255 caracteres.')
    },
    cnpj: {
      component: CNPJField,
      name: 'organization.cnpj',
      schema: Yup.string()
        .length(14, 'CNPJ inválido.')
    },
    category: {
      component: CategorySelect,
      name: 'organization.category_id',
      schema: Yup.mixed()
    },
    lead_origin: {
      component: LeadOriginSelect,
      name: 'organization.lead_origin_id',
      schema: Yup.mixed()
    },
    sector: {
      component: SectorSelect,
      name: 'organization.sector_id',
      schema: Yup.mixed()
    },
    description: {
      component: TextArea,
      name: 'organization.description',
      schema: Yup.string()
        .max(700, 'A descrição da empresa deve ter no máximo 700 caracteres.')
    },
    email: {
      component: TextInput,
      name: 'organization.contact.email',
      schema: Yup.string()
        .email('E-mail inválido.')
    },
    whatsapp: {
      component: WhatsAppInput,
      name: 'organization.contact.whatsapp',
      schema: Yup.string()
        .min(12, 'Número inválido.')
        .max(16, 'Número inválido.')
    },
    work_phone: {
      component: MaskedInput,
      name: 'organization.contact.work_phone',
      schema: Yup.string()
        .min(10, 'Número inválido.')
        .max(11, 'Número inválido.'),
      props: {
        maskType: 'phone'
      }
    },
    mobile_phone: {
      component: MaskedInput,
      name: 'organization.contact.mobile_phone',
      schema: Yup.string()
        .min(10, 'Número inválido.')
        .max(11, 'Número inválido.'),
      props: {
        maskType: 'phone'
      }
    },
    fax_phone: {
      component: MaskedInput,
      name: 'organization.contact.fax_phone',
      schema: Yup.string()
        .min(10, 'Número inválido.')
        .max(11, 'Número inválido.'),
      props: {
        maskType: 'phone'
      }
    },
    phone_extension: {
      component: MaskedInput,
      name: 'organization.contact.phone_extension',
      schema: Yup.string()
        .max(5, 'Ramal inválido'),
      props: {
        maskType: 'natural'
      }
    },
    postal_code: {
      component: MaskedInput,
      name: 'organization.address.postal_code',
      schema: Yup.string()
        .length(8, 'CEP deve ter 8 dígitos.'),
      props: {
        maskType: 'postalCode'
      }
    },
    country: {
      component: TextInput,
      name: 'organization.address.country',
      schema: Yup.string()
        .max(60, 'País deve ter no máximo 60 caracteres.')
    },
    state: {
      component: StateSelect,
      name: 'organization.address.state',
      schema: Yup.string()
    },
    city: {
      component: CitySelect,
      name: 'organization.address.city_id',
      schema: Yup.number().integer()
    },
    district: {
      component: TextInput,
      name: 'organization.address.district',
      schema: Yup.string()
        .max(60, 'Bairro deve ter no máximo 60 caracteres.')
    },
    street_name: {
      component: TextInput,
      name: 'organization.address.street_name',
      schema: Yup.string()
        .max(255, 'Rua deve ter no máximo 255 caracteres.')
    },
    street_number: {
      component: MaskedInput,
      name: 'organization.address.street_number',
      schema: Yup.number().integer(),
      props: {
        maskType: 'natural'
      }
    },
    additional_info: {
      component: TextInput,
      name: 'organization.address.additional_info',
      schema: Yup.string()
        .max(255, 'Complemento deve ter no máximo 255 caracteres.')
    },
    products: {
      component: ProductSelect,
      name: 'organization.products_id',
      schema: Yup.array(Yup.string()),
      props: {
        queryArgs: { params: { active_true: true } },
        multiple: true
      }
    },
    facebook: {
      component: TextInput,
      name: 'organization.contact.facebook',
      schema: Yup.string()
        .max(255, 'Facebook deve ter no máximo 255 caracteres.')
    },
    twitter: {
      component: TextInput,
      name: 'organization.contact.twitter',
      schema: Yup.string()
        .max(255, 'Twitter deve ter no máximo 255 caracteres.')
    },
    linkedin: {
      component: TextInput,
      name: 'organization.contact.linkedin',
      schema: Yup.string()
        .max(255, 'LinkedIn deve ter no máximo 255 caracteres.')
    },
    skype: {
      component: TextInput,
      name: 'organization.contact.skype',
      schema: Yup.string()
        .max(255, 'Skype deve ter no máximo 255 caracteres.')
    },
    instagram: {
      component: TextInput,
      name: 'organization.contact.instagram',
      schema: Yup.string()
        .max(255, 'Instagram deve ter no máximo 255 caracteres.')
    }
  }
};
/* eslint-enable no-magic-numbers */

function RequiredFieldInput(props) {
  const { field, ...otherProps } = props;
  const name = getFieldName(field);
  const label = (
    <RequiredFieldLabel
      item={field}
      icon
      question
      light
      className='d-flex align-items-center'
    />
  );

  if (field.customField) {
    return (
      <FormField
        as={CustomField}
        field={field.customField}
        name={name}
        label={label}
        { ...otherProps }
      />
    );
  } else if (field.defaultField === 'city') {
    const source = `${field.entity}.address.state`;

    return (
      <DependentFormField
        as={CitySelect}
        name={name}
        source={source}
        target='state'
        isSearchable
        label={label}
        { ...otherProps }
      />
    );
  } else {
    const params = defaultFields[field.entity]?.[field.defaultField];
    if (!params) {
      return null;
    }

    const { component, props: fieldProps } = params;

    return (
      <FormField
        as={component}
        name={name}
        label={label}
        { ...fieldProps }
        { ...otherProps }
      />
    );
  }
}

export function getFieldName(field) {
  if (field.customField) {
    return `${field.entity}.custom_fields.${field.customField.identifier}`;
  } else {
    return defaultFields[field.entity]?.[field.defaultField]?.name;
  }
}

export function getFieldSchema(field) {
  if (field.customField) {
    switch (field.customField.type) {
      case 'text':
        return textCustomFieldValidations;

      case 'integer':
        return integerCustomFieldValidations;

      case 'decimal':
        return decimalCustomFieldValidations;

      default:
        return Yup.mixed();
    }
  } else {
    return defaultFields[field.entity]?.[field.defaultField]?.schema ??
      Yup.mixed();
  }
}

export default RequiredFieldInput;
