import React, { useState, useEffect, useRef } from 'react';
import MultiRangeSlider from '@/lib/multirangeslider';
import PropTypes from 'prop-types';
import inRange from 'lodash/inRange';
import {
  useDebounce
} from '@/hooks';

const LABELS = ['0', '250', '500', '750', '+1000'];

const MIN_VALUE = 0;
const MAX_VALUE = 1000;
const DEBOUNCE_TIME = 600;

const propTypes = {
  onInputChange: PropTypes.func,
  initialMinValue: PropTypes.number,
  initialMaxValue: PropTypes.number
};
const defaultProps = {
  onInputChange: () => { }
};

function Base({ onInputChange, initialMinValue, initialMaxValue }) {
  const [minValue, setMinValue] = useState(initialMinValue || MIN_VALUE);
  const [maxValue, setMaxValue] = useState(initialMaxValue || MAX_VALUE);

  const ref = useRef(null);

  useEffect(() => {
    const labelEls = Array.from(ref.current.getElementsByClassName('label'));
    const maxCaptionEls = ref.current.querySelector('.max-caption');

    maxCaptionEls.innerText = `${maxValue === MAX_VALUE ? '+1000' : maxValue}`;

    labelEls.forEach((labelEl) => labelEl.classList.remove('active'));

    const labelsInRange = LABELS.filter(
      // Foi necessário adicionar (maxValue + 1) para considerar o valor 1000+
      (label) => inRange(label.replace('+', ''), minValue, maxValue + 1)
    );

    const labelElsObject = labelEls.reduce((accumulatorLabelEl, actualLabelEl) => {
      accumulatorLabelEl[actualLabelEl.innerText] = actualLabelEl;
      return accumulatorLabelEl;
    }, {});

    labelsInRange.forEach((label) => labelElsObject[label].classList.add('active'));
  }, [initialMinValue, initialMinValue]);

  const handleInput = useDebounce((e) => {
    setMinValue(e.minValue);
    setMaxValue(e.maxValue);

    onInputChange({ minValue: e.minValue, maxValue: e.maxValue });
  }, DEBOUNCE_TIME);

  return (
    <div id='multi-range-slider'>
      <MultiRangeSlider
        ref={ref}
        min={MIN_VALUE}
        max={MAX_VALUE}
        step={20}
        minValue={minValue}
        maxValue={maxValue}
        labels={LABELS}
        ruler={true}
        onInput={handleInput}
      />
    </div>
  );
}

Base.defaultProps = defaultProps;
Base.propTypes = propTypes;

export default Base;
