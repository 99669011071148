import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import { useCreateProduct, useProducts } from '@/api';
import { useDebounce } from '@/hooks';
import { canCreateProduct } from '@/policies';
import { useAuth } from '@/lib/auth';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import APIErrorMessage from '@/components/APIErrorMessage';
import FetchableSelect from '@/components/Inputs/FetchableSelect';

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  allowNull: PropTypes.bool,
  isClearable: PropTypes.bool,
  isCreatable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  creatable: PropTypes.bool
};

const defaultProps = {
  name: 'products_id_in',
  label: 'Produtos',
  placeholder: 'Qualquer produto',
  multiple: false,
  allowNull: false,
  isClearable: true,
  isSearchable: true,
  isCreatable: false,
  defaultValue: null,
  defaultOptions: [],
  onChange: () => {},
  creatable: false
};

function transformer(fetchedData, allowNull) {
  const result = [];
  if (allowNull) {
    result.push({ label: 'Indefinido', value: -1 });
  }
  const options = fetchedData.data.map(({ name, code, category = {}, id, price }) => {
    const valueWithPipe = (value) => (value ? `| ${value}` : '');
    const codeWithPipe = valueWithPipe(code);
    const categoryWithPipe = valueWithPipe(category?.name);
    const label = `${name} ${codeWithPipe} ${categoryWithPipe}`;

    return {
      label,
      price,
      value: id
    };
  });

  return result.concat(options);
}

const debounceTime = 600;

function ProductSelect(props) {
  const { defaultOptions, allowNull, ...rest } = props;

  const { user } = useAuth();
  const [inputValue, setValue] = useState('');

  const alert = useAlert();
  const mutation = useCreateProduct();
  const handleInputChange = useDebounce((value) => setValue(value), debounceTime);

  const onCreate = (name) => {
    /**
     * Para selecionar uma opção, é preciso usar o seu `value`, que por padrão
     * é obtido do `id`. A nova opção criada ainda não terá `id`, por isso, para
     * selecioná-la imediatamente, é utilizado um `id` temporário igual ao nome.
     */
    mutation.mutate({ id: name, name }, {
      onSuccess: ({ data }) => {
        props.onChange?.(data.id, data);

        alert.show(
          'Produto adicionado com sucesso.',
          { variant: 'success', timeout: 5000 }
        );
      },
      onError: (err) => {
        alert.show(
          <APIErrorMessage
            err={err}
            resource='product'
            action='create'
          />,
          { variant: 'danger' }
        );
      }
    });
  };

  const { effect, reason } = canCreateProduct({ user });
  const isDisabled = effect !== 'allow';

  return (
    <FetchableSelect
      { ...rest }
      query={useProducts}
      transformer={(data) => transformer(data, allowNull)}
      defaultQueryParams={{ q: inputValue }}
      cacheOptions
      onInputChange={handleInputChange}
      defaultOptions={defaultOptions}
      loadingMessage={() => 'Carregando Produtos...'}
      isLoading={mutation.isLoading}
      formatCreateLabel={(inputText) => (
        <Button
          variant='link'
          disabled={isDisabled}
          className='p-0 border-0 justify-content-start fw-bold'
        >
          <Icon name='plus' size='sm' className='me-2' />

          <span>
            Adicionar novo produto {`"${inputText}"`}
          </span>
        </Button>
      )}
      getNewOptionData={(value, label) => (
        { value, label, isDisabled, tooltip: reason }
      )}
      onCreateOption={onCreate}
    />
  );
}

ProductSelect.propTypes = propTypes;
ProductSelect.defaultProps = defaultProps;

export default ProductSelect;
