import React from 'react';
import PropTypes from 'prop-types';
import FetchableSelect from '@/components/Inputs/FetchableSelect';
import { useFunnels } from '@/api';

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func
};

const defaultProps = {
  name: 'funnel_id_in',
  label: 'Funis',
  placeholder: 'Qualquer funil',
  multiple: false,
  isClearable: false,
  isSearchable: false,
  defaultValue: null,
  onChange: () => {}
};

function FunnelSelect(props) {
  return (
    <FetchableSelect
      { ...props }
      query={useFunnels}
    />
  );
}

FunnelSelect.propTypes = propTypes;
FunnelSelect.defaultProps = defaultProps;

export default FunnelSelect;
