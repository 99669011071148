import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import Button from '@/components/Button';
import Dropdown from '@/components/Dropdown';
import ToggleButton from '@/components/ToggleButton';
import Icon from '@/components/Icon';
import FunnelInfo from '@/components/FunnelInfo';
import FunnelForm from '@/components/FunnelForm';
import FunnelStage from '@/components/FunnelStage';
import FunnelBoardSkeleton from '@/components/FunnelBoardSkeleton';
import DealsNav from '@/components/DealsNav';
import Tooltip from '@/components/Tooltip';
import DragScrollable from '@/components/DragScrollable';
import SkeletonWrapper from '@/components/SkeletonWrapper';
import ExportButton from '@/components/ExportButton';
import ExternalLink from '@/components/ExternalLink';
import { FunnelProvider } from '@/contexts';
import { legacyDealsMapUrl, dealsPath, dealsImportPath, dealPath } from '@/routes';
import { useDidUpdateEffect, useFunnelBoard, useMediaQuery } from '@/hooks';
import CreateDealEntityModal from '@/components/CreateDealEntityModal';
import { DEAL_STATUSES, getString } from '@/utils';
import ImportButton from '@/components/ImportButton';
import LeadsGenerationButton from '@/components/LeadsGenerationButton';

const propTypes = {
  funnel: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    stages: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })).isRequired,
    default: PropTypes.bool
  }).isRequired,
  isBacklogOpen: PropTypes.bool.isRequired,
  color: PropTypes.string
};

const defaultProps = {
  color: 'primary'
};

const ENTITY = 'deal';
const ENTITY_I18N = getString(['models', ENTITY]);
const ENTITY_I18N_PLURAL = `${ENTITY_I18N}s`;
const EXPORT_TOOLTIP = 'Exporte os negócios do funil em formato de planilha de Excel (.xlsx)';
const EXPORT_ALERT = 'A exportação considera apenas os negócios em andamento no funil';

function FunnelBoard({ funnel, color }) {
  const { id, name, stages, default: isDefault } = funnel;
  const {
    columns, dispatch, getColumn, showFrozen, setShowFrozen,
    data, isFetching, frozenData, isFetchingFrozen, params, onChangeFilters,
    totalAmount, totalCount, totalFrozenAmount, totalFrozenCount
  } = useFunnelBoard({ funnelId: id, stages });
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  useDidUpdateEffect(() => {
    if (!isFetching) {
      setIsLoading(false);
    }
  }, [isFetching]);

  const context = {
    columns,
    dispatch,
    getColumn,
    showFrozen,
    setShowFrozen,
    data,
    isFetching,
    frozenData,
    isFetchingFrozen,
    params
  };

  const [showCreateDealModal, setShowCreateDealModal] = useState(false);

  const onCancelCreateDeal = () => setShowCreateDealModal(false);
  const onFinishCreateDeal = (cardData) => {
    dispatch({ type: 'add_item', payload: cardData });
    setShowCreateDealModal(false);
    navigate(dealPath(cardData.id.replace('deal-', '')));
  };
  const [firstStage] = stages;

  const expandedFunnelHeader = useMediaQuery('(min-width: 1280px)');
  const showImportExportButtons = useMediaQuery('(min-width: 1440px)');

  const exportButtonFilters = {
    funnel_id: [id],
    deal_status_id: DEAL_STATUSES.ONGOING,
    sorts: params?.sorts,
    products_id: params?.products_id_in,
    forgotten: params?.forgotten,
    started_at_date: params?.started_at_local_date_within,
    finished_at_date: params?.finished_at_local_date_within,
    users_id: params?.owner_user_id_in,
    states: params?.state_in,
    cities_id: params?.city_id_in,
    area_codes: params?.area_code_in,
    sectors_id: params?.sector_id_in,
    origins_id: params?.lead_origin_id_in,
    teams_id: params?.team_id_in,
    custom_fields: params?.custom_fields
  };

  return (
    <FunnelProvider value={context}>
      <div
        className={classnames(
          'd-flex',
          'flex-column',
          'flex-grow-1',
          'bg-light',
          'flexible-width',
          'funnel-board'
        )}
      >
        <div
          className={classnames(
            'd-flex',
            'position-relative',
            'pe-4',
            'flex-shrink-0',
            'border-1',
            'border-bottom',
            'border-light-gray',
            'justify-content-between',
            'align-items-center'
          )}
        >
          <FunnelInfo
            className='py-4 ps-8'
            name={name}
            totalAmount={totalAmount}
            totalCount={totalCount}
            isLoading={isLoading}
            frozenAmount={totalFrozenAmount}
            frozenCount={totalFrozenCount}
            showFrozen={showFrozen}
            isDefault={isDefault}
          />
          <LeadsGenerationButton isLoading={isFetching} />
        </div>

        <div className='d-flex align-items-center position-relative pe-4 flex-shrink-0 '>
          <FunnelForm
            className='ms-4'
            name={`funnel_${id}_items`}
            onSubmit={onChangeFilters}
          />

          <ToggleButton
            id='show_frozen'
            value='1'
            checked={showFrozen}
            type='checkbox'
            tooltip={showFrozen
              ? 'Negócios congelados estão visíveis. Clique para escondê-los.'
              : 'Negócios congelados estão invisíveis. Clique para exibi-los.'
            }
            onChange={() => setShowFrozen(!showFrozen)}
            className='p-1 rounded ms-auto me-2'
          >
            <Icon name='frozen' />
          </ToggleButton>

          {
            expandedFunnelHeader
              ? (
                <>
                  <DealsNav />
                  {
                    showImportExportButtons
                      ? (
                        <>
                          <ImportButton
                            path={dealsImportPath()}
                            entity={ENTITY}
                            entityNamePlural={ENTITY_I18N_PLURAL}
                          />

                          <ExportButton
                            variant='transparent-light'
                            className='text-nowrap'
                            tooltipText={EXPORT_TOOLTIP}
                            exportEntity='deal'
                            filters={exportButtonFilters}
                            alertMessage={EXPORT_ALERT}
                          />
                        </>
                      )
                      : null
                  }

                </>
              )
              : null
          }

          <Tooltip content='Adicionar negócio'>
            <Button
              className='ms-3 me-3 text-nowrap'
              onClick={() => setShowCreateDealModal(true)}
            >
              <Icon name='add' />

              <span className='ms-2'>
                Adicionar negócio
              </span>
            </Button>
          </Tooltip>

          {
            expandedFunnelHeader && showImportExportButtons
              ? null
              : (
                <Dropdown align='end' className='me-3'>
                  <Dropdown.Toggle
                    id='menu-toggle'
                    variant='white'
                    className='px-3 py-2 border-0'
                  >
                    <span className='text-darker-gray fw-bold'>...</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    renderOnMount
                    className='min-width-5'
                  >
                    <ImportButton
                      as={Dropdown.Item}
                      iconClassName='me-2'
                      buttonClassNames='d-flex fw-bold'
                      spanClassName='text-dark-gray'
                      entity={ENTITY}
                      entityNamePlural={ENTITY_I18N_PLURAL}
                      path={dealsImportPath()}
                      buttonText={`Importar ${ENTITY_I18N_PLURAL}`}
                    />

                    <Dropdown.Divider />

                    <ExportButton
                      as={Dropdown.Item}
                      href='#'
                      className='text-dark-gray fw-bold'
                      tooltipText={EXPORT_TOOLTIP}
                      exportEntity='deal'
                      filters={exportButtonFilters}
                      alertMessage={EXPORT_ALERT}
                    />

                    {showImportExportButtons || !expandedFunnelHeader
                      ? (
                        <>
                          <Dropdown.Divider />

                          <Dropdown.Item
                            id='menu-item-listing'
                            href={dealsPath()}
                            className='text-dark-gray fw-bold'
                          >
                            <Icon
                              name='listing'
                              size='sm'
                              className='me-2 align-middle'
                              withStroke
                            />
                            <span className='align-middle'>Visualizar listagem</span>
                          </Dropdown.Item>

                          <Dropdown.Divider />

                          <Dropdown.Item
                            id='menu-item-map'
                            as={ExternalLink}
                            href={legacyDealsMapUrl()}
                            className='text-dark-gray fw-bold'
                          >
                            <Icon
                              name='map'
                              size='sm'
                              className='me-2 align-middle'
                              withStroke
                            />
                            <span className='align-middle'>Visualizar mapa</span>
                          </Dropdown.Item>
                        </>
                      )
                      : null
                    }
                  </Dropdown.Menu>
                </Dropdown>
              )
          }

        </div>

        <SkeletonWrapper
          isLoading={isLoading}
          SkeletonComponent={FunnelBoardSkeleton}
        >
          <DragScrollable
            className={classnames(
              'flex-grow-1',
              'flexible-height',
              'd-flex',
              'align-items-stretch',
              'ps-8',
              'overflow-x-scroll'
            )}
          >
            {stages.map((stage, index) => (
              <FunnelStage
                funnelId={id}
                id={stage.id}
                name={stage.name}
                color={color}
                key={index}
              />
            ))}
          </DragScrollable>
        </SkeletonWrapper>
      </div>

      <CreateDealEntityModal
        funnel={funnel}
        stageId={firstStage.id}
        show={showCreateDealModal}
        onHide={onCancelCreateDeal}
        onFinish={onFinishCreateDeal}
      />
    </FunnelProvider>
  );
}

FunnelBoard.propTypes = propTypes;
FunnelBoard.defaultProps = defaultProps;

export default FunnelBoard;
