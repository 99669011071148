import { useMutation } from 'react-query';
import { post } from '@/lib/fetch';

export async function createFreePlan(params = {}) {
  const { data } = await post('/billing/free_plans', params);

  return data;
}

export function useFreePlan({ config = {} } = {}) {
  return useMutation(
    createFreePlan,
    { ...config }
  );
}
