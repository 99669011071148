import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

const propTypes = {
  as: PropTypes.elementType.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

const defaultProps = {
  onChange: () => {}
};

const HookFormField = React.forwardRef((props, ref) => {
  const { as: Component, name, onChange, ...componentProps } = props;

  const { control } = useFormContext();

  return (
    <Controller
      ref={ref}
      name={name}
      control={control}
      render={({
        field,
        fieldState: { error }
      }) => (
        <Component
          ref={ref}
          error={error?.message}
          {...field }
          {...componentProps}
          onChange={(newValue, extra = {}) => {
            field.onChange(newValue);
            onChange(newValue, extra);
          }}
        />
      )}
    />
  );
});

HookFormField.propTypes = propTypes;
HookFormField.defaultProps = defaultProps;
HookFormField.displayName = 'HookFormField';

export default HookFormField;
