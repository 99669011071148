import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Stack from 'react-bootstrap/Stack';
import Collapse from '@/components/Collapse';
import CollapseToggle from '@/components/CollapseToggle';
import CardFormField from '@/feature/automation/CardFormField';
import Authorization from '@/components/Authorization';
import { canAccessLatestAutomationFeatures } from '@/policies/automations';

const propTypes = {
  template: PropTypes.object
};

function ActivityActionFields(props) {
  const { template } = props;
  const { triggerName } = template ?? {};

  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = () => setShowMore((prev) => !prev);

  return (
    <>
      <Stack direction='horizontal' gap={4} className='align-items-start mb-4'>
        <CardFormField type='activity' />
        <CardFormField type='duration' />
      </Stack>

      <Collapse in={showMore}>
        <div>
          <CardFormField type='activity_description' />

          <Authorization policy={canAccessLatestAutomationFeatures}>
            <CardFormField type='activity_owners' event={triggerName} />
          </Authorization>

          <Authorization policy={canAccessLatestAutomationFeatures}>
            <span className='text-dark-gray text-small'>
            Todas as pessoas selecionadas serão responsáveis pela atividade.
            </span>
          </Authorization>
        </div>
      </Collapse>

      <CollapseToggle
        open={showMore}
        onToggle={toggleShowMore}
        closedIcon={null}
        closedLabel='Mais detalhes'
        openIcon={null}
        openLabel='Ver menos'
      />
    </>
  );
}

ActivityActionFields.propTypes = propTypes;

export default ActivityActionFields;
