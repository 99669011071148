import React from 'react';
import { createStore } from 'zustand';
import merge from 'lodash/merge';

export function useOrganizationModalStore({ onUpdate, onDelete, onUpdatePerson, organization }) {
  return React.useState(() => createStore((set) => ({
    onUpdate: (props, done) => {
      const finalCallback = ({ error, data }) => {
        done?.(error);

        if (data) {
          set((state) => ({ organization: merge({}, state.organization, data) }));
        }
      };
      return onUpdate(props, finalCallback);
    },
    onUpdatePerson: (props, done, personData) => {
      const finalCallback = ({ error, data }) => {
        done?.(error);

        if (data && organization.people) {
          set((state) => ({
            organization: merge(
              {},
              state.organization,
              {
                people: state.organization.people.map((person) => {
                  if (person.id !== data.id) {
                    return person;
                  }
                  return data;
                })
              }
            )
          }));
        }
      };
      return onUpdatePerson(props, finalCallback, personData);
    },
    onCreatePerson: (data) => {
      set((state) => ({
        organization: {
          ...state.organization,
          people: [
            ...(state.organization.people || []),
            data
          ]
        }
      }));
    },
    onDelete,
    organization
  })));
}
