import { useQuery } from 'react-query';
import { get } from '@/lib/fetch';

export const teamsKeys = {
  all: ['teams'],
  lists: () => [...teamsKeys.all, 'list'],
  list: (params) => [...teamsKeys.lists(), params]
};

export async function getTeams(params = {}) {
  const { data } = await get('/teams', params);

  return data;
}

export function useTeams({ params = {}, config = {} }) {
  return useQuery({
    ...config,
    queryKey: teamsKeys.list(params),
    queryFn: () => getTeams(params)
  });
}
