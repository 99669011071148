import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

const defaultProps = {
  className: ''
};

function Footer({ id, children, className }) {
  return (
    <footer
      id={id}
      className={classnames(
        'position-sticky',
        'bottom-0',
        'z-index-1',
        className
      )}
    >
      {children}
    </footer>
  );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
