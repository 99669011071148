import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  fluid: PropTypes.bool,
  overflow: PropTypes.bool,
  flex: PropTypes.bool
};

const defaultProps = {
  className: '',
  fluid: false,
  overflow: true,
  flex: false
};

function Main({ children, className, overflow, fluid, flex }) {
  return (
    <main className={classnames(
      'flex-grow-1',
      {
        'p-8': !fluid,
        'overflow-y-scroll': overflow,
        'd-flex flex-column': flex
      },
      className
    )}>
      {children}
    </main>
  );
}

Main.propTypes = propTypes;
Main.defaultProps = defaultProps;

export default Main;
