import React from 'react';
import { useField, useFormikContext } from 'formik';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import Card from '@/components/Card';
import { useAutomationContext } from '@/pages/settings/automations/modal';
import EmailActionPreview from '@/feature/automation/EmailActionPreview';
import Alert from '@/components/Alert';

function EmailActionFields() {
  const { values } = useFormikContext();
  const { setInitialValues } = useAutomationContext();
  const [{ value: params }, { touched, error }] = useField('action_params');

  const onClick = () => {
    setInitialValues(values);
  };

  if (!params) {
    return (
      <>
        {
          touched && error &&
          <Alert variant='danger' className='w-100'>
            Não é possível salvar sem configurar o conteúdo do e-mail.
          </Alert>
        }
        <Card body bg='dark-light' border='dark-light' className='p-4'>
          <div>
            Nenhuma mensagem adicionada
          </div>

          <div className='text-small'>
            Você precisa adicionar detalhes como assunto, conteúdo e anexos.
          </div>

          <Button
            variant='outline-dark-gray'
            className='mt-3 gap-2'
            href='email'
            onClick={onClick}
          >
            <Icon name='plus' />

            <span>Adicionar mensagem</span>
          </Button>
        </Card>
      </>
    );
  }

  return (
    <EmailActionPreview params={params} onClick={onClick} />
  );
}

export default EmailActionFields;
