import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SearchBar from '@/components/SearchBar';
import CustomerSearch from '@/components/CustomerSearch';
import { useAuth } from '@/lib/auth';
import { useCreateOrganization } from '@/api';

function FooterActions(props) {
  const {
    isFetching, isEmpty, isCreating, createFailed, term, items, onButtonClick
  } = props;

  if (isFetching) {
    return null;
  }

  return (
    <div
      className={classnames(
        'd-flex',
        'flex-column',
        'align-items-center',
        { 'pt-5': !isEmpty }
      )}
    >
      <div className='text-darker-gray fw-bold'>
        {`Criar "${term}" como novo(a):`}
      </div>

      { createFailed &&
        (
          <div className='text-center w-100 mt-5 py-2 bg-light-red'>
            Não foi possível criar esta empresa
          </div>
        )
      }

      <div className='d-flex align-items-center pt-5 pb-3 fw-bold'>
        <CustomerSearch.FooterActionButton
          disabled={isCreating}
          type='organization'
          items={items}
          term={term}
          onClick={onButtonClick}
        />
      </div>
    </div>
  );
}

function Footer(props) {
  const {
    isFetching, isEmpty, isCreating, createFailed, term, items,
    onButtonClick, onHideOptions, creatable
  } = props;

  const handleButtonClick = (data) => onButtonClick({ ...data, onHideOptions });

  return (
    <div>
      <CustomerSearch.FooterStatus
        isFetching={isFetching}
        isEmpty={isEmpty}
        label='Empresa ou pessoa'
      />

      {
        creatable && (
          <FooterActions
            isFetching={isFetching}
            isEmpty={isEmpty}
            isCreating={isCreating}
            createFailed={createFailed}
            term={term}
            items={items}
            onButtonClick={handleButtonClick}
          />
        )
      }
    </div>
  );
}

const propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  onCreate: PropTypes.func,
  className: PropTypes.string,
  creatable: PropTypes.bool
};

const defaultProps = {
  onCreate: () => {},
  className: '',
  creatable: true
};

const defaultValues = {
  privacy: {
    type: 'default'
  }
};

function OrganizationSelect(props) {
  const { onChange, onCreate, defaultValue, className, creatable } = props;

  const { user } = useAuth();

  const [value, setValue] = useState(defaultValue);
  const [selected, setSelected] = useState(Boolean(defaultValue));

  const mutation = useCreateOrganization();

  const inputProps = useMemo(() => ({
    placeholder: 'Digite o nome da empresa',
    label: 'Empresa',
    autoComplete: 'off',
    maxLength: 150,
    isClearable: selected,
    disabled: selected,
    onClear: () => {
      setValue('');
      setSelected(false);
      onChange(null);
    }
  }), [selected, onChange]);

  const popoverProps = {
    className: 'popover-padding-0 w-100 max-width-100',
    size: 'lg',
    placement: 'bottom-start'
  };

  const onSelect = ({ id, name, onHideOptions }) => {
    setValue(name);
    setSelected(true);
    onChange(id);
    onHideOptions();
  };

  const onButtonClickCreateOrganization = ({ term, onHideOptions }) => {
    mutation.mutate({ ...defaultValues, owner_user_id: user.id, name: term }, {
      onSuccess: ({ data }) => {
        onCreate({ data });
        onSelect({ ...data, onHideOptions });
      }
    });
  };

  // Reseta erros da mutation de criação sempre que uma nova busca iniciar
  const onSearchStart = () => mutation.reset();

  const resultsFooter = (resultsFooterProps) => (
    <Footer
      { ...resultsFooterProps }
      isCreating={mutation.isLoading}
      createFailed={mutation.isError}
      onButtonClick={onButtonClickCreateOrganization}
      creatable={creatable}
    />
  );

  return (
    <SearchBar
      name='customer-search'
      types={['organization']}
      perPage={5}
      resultsFooter={resultsFooter}
      ItemComponent={CustomerSearch.Item}
      inputProps={inputProps}
      popoverProps={popoverProps}
      onSelect={onSelect}
      onSearchStart={onSearchStart}
      className={className}
      searchValue={value}
    />
  );
}

OrganizationSelect.propTypes = propTypes;
OrganizationSelect.defaultProps = defaultProps;

export default OrganizationSelect;
