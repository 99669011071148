import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '@/components/Icon';
import Popover from '@/components/Popover';

function Item({ text, image, description, icon, dashed, size }) {
  const hasDescriptionOrImage = Boolean(description || image);

  return (
    <Popover
      hide={!hasDescriptionOrImage}
      trigger={['hover']}
      placement='right'
      showArrow
      flip
      className={classnames(size === 'sm' ? 'feature-list-sm' : 'feature-list')}
      content={() => (
        <div className='feature-list__item text-center'>
          <div className='mb-2 fw-bold text-dark-gray'>{text}</div>

          {image && <img src={image} className='item-image' />}
          <div className='mt-2 text-dark-gray'>{description}</div>
        </div>
      )}
    >
      <div className='d-flex mt-2 align-items-center'>
        {icon && (
          <div className={classnames('me-1', icon.wrapperClassName)}>
            <Icon name={icon.name} className={icon.className} />
          </div>
        )}
        <div
          className={classnames('text-dark-gray flex-fill', { 'text-dashed': dashed })}
        >
          {text}
        </div>
      </div>

    </Popover>
  );
}

const itemPropTypes = {
  text: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.shape({
    name: PropTypes.string,
    className: PropTypes.string
  }),
  dashed: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md'])
};
Item.propTypes = itemPropTypes;
Item.defaultProps = {
  dashed: true,
  size: 'md'
};

function FeatureList({ items }) {
  return (
    <div className='d-flex flex-column align-items-start'>
      {items.map((item, index) => (
        <Item
          key={index}
          {...item}
        />
      ))}
    </div>
  );
}

FeatureList.Item = Item;

FeatureList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(itemPropTypes))
};
FeatureList.defaultProps = {
  items: []
};

export default FeatureList;
