import { useQuery } from 'react-query';
import { get } from '@/lib/fetch';

export const lossReasonsKey = {
  all: ['loss-reasons'],
  lists: () => [...lossReasonsKey.all, 'list'],
  list: (params) => [...lossReasonsKey.lists(), params]
};

export async function getLossReasons(params = {}) {
  const { data } = await get('/loss_reasons', params);

  return data;
}

export function useLossReasons({ params = {}, config = {} }) {
  return useQuery({
    ...config,
    queryKey: lossReasonsKey.list(params),
    queryFn: () => getLossReasons()
  });
}
