import React from 'react';
import PropTypes from 'prop-types';
import Keyword from '@/feature/automation/Keyword';

const propTypes = {
  active: PropTypes.bool,
  params: PropTypes.shape({
    stageId: PropTypes.number,
    funnelId: PropTypes.number
  })
};

const defaultProps = {
  active: true,
  params: {}
};

function TriggerContent(props) {
  const { active, params, template } = props;
  const { triggerIdentifier, triggerName } = template ?? {};
  const {
    funnel_id: funnelId,
    funnel_name: funnelName = 'Funil indefinido',
    stage_id: stageId,
    stage_name: stageName = 'Indefinida'
  } = params ?? {};

  const triggerPreposition = triggerName === 'on_deal_stage_arrived' ? 'do' : 'no';

  if (funnelId && !stageId) {
    return (
      <span>{triggerIdentifier} {triggerPreposition}
        <Keyword custom active={active}> {funnelName}</Keyword>
      </span>
    );
  }

  if (!funnelId || !stageId) {
    return (
      <span>{triggerIdentifier}</span>
    );
  }

  return (
    <span>
      {triggerIdentifier}{' '}
      <Keyword custom active={active}>{stageName} do {funnelName}</Keyword>
    </span>
  );
}

TriggerContent.propTypes = propTypes;
TriggerContent.defaultProps = defaultProps;

export default TriggerContent;
