import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { MaskedInput } from '@/components/Inputs';

const propTypes = {
  field: PropTypes.object.isRequired,
  name: PropTypes.string,
  label: PropTypes.string
};

/* eslint-disable no-magic-numbers */
const NUMBER_MAX_DIGITS = 12;
const INTEGER_MIN_VALUE = -(10 ** NUMBER_MAX_DIGITS) + 1;
const INTEGER_MAX_VALUE = (10 ** NUMBER_MAX_DIGITS) - 1;
/* eslint-enable no-magic-numbers */

export const integerCustomFieldValidations = Yup
  .number()
  .nullable()
  .transform((value, originalValue) => (originalValue === '' ? null : value))
  .typeError('Este campo deve ser um número.')
  .integer('Este campo deve ser um número inteiro')
  .min(INTEGER_MIN_VALUE, `Este campo deve ter no máximo ${NUMBER_MAX_DIGITS} dígitos`)
  .max(INTEGER_MAX_VALUE, `Este campo deve ter no máximo ${NUMBER_MAX_DIGITS} dígitos`);

export function IntegerCustomField({ field, label, ...otherProps }) {
  return (
    <MaskedInput
      maskType='integer'
      label={label ?? field.name}
      placeholder='0'
      variant='light'
      autoComplete='off'
      { ...otherProps }
    />
  );
}

IntegerCustomField.propTypes = propTypes;

export {
  NUMBER_MAX_DIGITS,
  INTEGER_MIN_VALUE,
  INTEGER_MAX_VALUE
};
