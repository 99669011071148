import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import { canSendEmail } from '@/policies/emails';
import CreateEmailModal from '@/components/Email/CreateEmailModal';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Authorization from '@/components/Authorization';
import { useMediaQuery } from '@/hooks';

DetailsEmail.defaultProps = {
  dealId: 0
};

DetailsEmail.propTypes = {
  dealId: PropTypes.number
};

function DetailsEmail({ dealId }) {
  const [showModal, setShowModal] = useState(false);
  const { user } = useAuth();
  const tracker = useTracking();
  const isBelow1200 = useMediaQuery('(max-width: 1200px)');

  const handleEmailButtonClick = () => {
    tracker.trackActionButtonClicked({ user, action: 'Enviar email' });
    setShowModal(true);
  };

  return (
    <>
      <Authorization policy={canSendEmail} tooltipContainerClassName='w-100'>
        <Button
          id='create-email-modal'
          className={
            `d-flex align-items-center text-nowrap w-100
            ${isBelow1200 ? 'ms-0' : 'me-8'}`
          }
          onClick={handleEmailButtonClick}
        >
          <Icon name='email' className='me-2' size='fixed' />
          <span className='me-2'>Enviar e-mail</span>
        </Button>
      </Authorization>

      <CreateEmailModal
        show={showModal}
        onClose={() => setShowModal(false)}
        dealId={dealId}
        isNewEmail
      />
    </>
  );
}

export default DetailsEmail;
