/* eslint-disable no-magic-numbers */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Card from '@/components/Card';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import { CurrencyInput } from '@/components/Inputs';
import { Col, Row } from 'react-bootstrap';
import { toCurrencyString } from '@/number';

const propTypes = {
  value: PropTypes.number,
  onChangeRechargeValue: PropTypes.func,
  pricePerMinute: PropTypes.number,
  isLoading: PropTypes.bool
};

const defaultProps = {
  value: 0,
  onChangeRechargeValue: () => { },
  pricePerMinute: null,
  isLoading: true
};

const MIN_RECHARGE_VALUE = 100;
const CHARGE_FEE = 2;
const RECHARGE_VALUES = [250.0, 500.0, 800.0, 1000.0, 1500.0, 2000.0];

/*
 * Const TOOLTIP_RECHARGE_TEXT = `Ao ativar a opção de recarga automática, o usuário fornece
 * as informações necessárias para que o Agendor possa realizar a recarga de forma automática,
 * dispensando a necessidade de intervenção manual por parte do usuário.`;
 */
function RechargeCard({
  value,
  onChangeRechargeValue,
  pricePerMinute,
  isLoading
}) {
  return (
    <Card className='border-lighter-gray mb-2'>
      <div className='px-4'>
        <div className='d-flex justify-content-between text-dark-gray py-3'>
          Recarregar créditos
        </div>
        <hr />
      </div>

      <div className='px-2'>
        {/* <Card className='border-3 border-lighter-gray p-4 m-2'>
          <div className='d-flex'>
            <h5 className='text-primary fw-bold me-1 mt-1'>
              Recarga automática
            </h5>
            <Tooltip content={TOOLTIP_RECHARGE_TEXT} placement='right-start'>
              <div>
                <Icon name='info' className='ms-1 text-medium-gray' size='sm' />
              </div>
            </Tooltip>
            <div className='d-flex flex-grow-1 justify-content-end'>
              <Icon name='arrow-right' />
            </div>
          </div>
        </Card> */}
        <Card className='border-3 border-lighter-gray p-4 m-2 mb-3'>
          <div className='container p-0 mb-2'>
            <Row className='g-2'>
              {RECHARGE_VALUES.map((rechargeValue) => (
                <Col sm={4} key={rechargeValue}>
                  <Button
                    variant={value === rechargeValue ? 'primary' : 'light'}
                    className={classnames(
                      'mb-2 py-5 rounded border-0 text-nowrap w-100',
                      { 'text-dark-gray': value !== rechargeValue },
                      { 'text-white': value === rechargeValue }
                    )}
                    onClick={() => onChangeRechargeValue(rechargeValue)}
                  >
                    R$ {Number(rechargeValue).toFixed(0)}
                  </Button>
                </Col>
              ))}
            </Row>
          </div>
          <div className='d-flex fw-bold mb-2'>Outro valor:</div>
          <div>
            <CurrencyInput
              name='recharge_value'
              digits={0}
              value={value}
              onChange={onChangeRechargeValue}
            />
            {
              value < MIN_RECHARGE_VALUE
                ? (
                  <div className='d-flex justify-content-between text-dark-gray mb-2'>
                    Valores abaixo de {toCurrencyString(MIN_RECHARGE_VALUE)} terão uma
                    taxa de {toCurrencyString(CHARGE_FEE)} por transação.
                  </div>
                )
                : null
            }
          </div>
          <Button href={`/voip_checkout?value=${value}`} disabled={value <= 0}>
            <Icon name='add' className='me-2' size='sm' />
            <span>Adicionar créditos</span>
          </Button>
        </Card>
        {
          isLoading
            ? null
            : (
              <div className='text-center text-dark-gray mb-2'>
                Custo por minuto {toCurrencyString(pricePerMinute)} para todo o Brasil
              </div>
            )
        }
      </div>
    </Card>
  );
}

RechargeCard.propTypes = propTypes;
RechargeCard.defaultProps = defaultProps;

export default RechargeCard;
