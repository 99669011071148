import React from 'react';
import Button from '@/components/Button';
import ExternalLink from '@/components/ExternalLink';
import * as UnauthorizedError from '@/lib/auth/UnauthorizedError';
import { useAuth } from '@/lib/auth';
import { helpUrl, legacyLoginUrl } from '@/routes';
import { useTracking } from '@/lib/tracking';

function ConcurrentSessionError() {
  const { user } = useAuth();
  const helpButtonUrl = helpUrl('concurrent-session');
  const loginButtonUrl = legacyLoginUrl();
  const tracker = useTracking();

  return (
    <UnauthorizedError.Container>
      <UnauthorizedError.Illustration />

      <UnauthorizedError.Title>
        Acesso de sua conta em outro navegador
      </UnauthorizedError.Title>

      <UnauthorizedError.Body>
        Um novo acesso com seu email {user?.email} foi iniciado em outro
        navegador.
        Só é possível um acesso por vez.
      </UnauthorizedError.Body>

      <UnauthorizedError.Container direction='horizontal' className='mx-auto'>
        <Button
          as={ExternalLink}
          href={helpButtonUrl}
          variant='outline-primary'
          target='_blank'
          onClick={() => {
            tracker.trackSessionModalInterruption({
              user,
              buttonText: 'Iniciar novo acesso'
            });
          }}
        >
          Veja como solucionar
        </Button>

        <Button
          as={ExternalLink}
          href={loginButtonUrl}
          onClick={() => {
            tracker.trackSessionModalInterruption({
              user,
              buttonText: 'Veja como solucionar'
            });
          }}
        >
          Iniciar novo acesso
        </Button>
      </UnauthorizedError.Container>
    </UnauthorizedError.Container>
  );
}

export default ConcurrentSessionError;
