import React from 'react';
import Stack from 'react-bootstrap/Stack';

import Card from '@/components/Card';
import Icon from '@/components/Icon';
import CardFormField from '@/feature/automation/CardFormField';

function TriggerFields(props) {
  const {
    template: { triggerIdentifier, triggerIcon, triggerForm, triggerDependant }
  } = props ?? {};
  return (
    <Card body className='p-4 border-1'>
      <Stack direction='horizontal' gap={2} className='mb-4'>
        <Card body bg='light' border='light'>
          <Icon name={triggerIcon} className='text-dark-gray' />
        </Card>

        <h4>{triggerIdentifier}</h4>
      </Stack>
      {triggerForm && (
        <Stack direction='horizontal' gap={4} className='align-items-start'>
          <CardFormField type={triggerForm} dependant={triggerDependant}/>
        </Stack>
      )}
    </Card>
  );
}

export default TriggerFields;
