import React from 'react';
import PropTypes from 'prop-types';
import Base from 'react-bootstrap/Dropdown';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

const defaultProps = {
  className: ''
};

const OFFSET = 8;

const Menu = React.forwardRef(
  ({ children, ...props }, ref) => (
    <Base.Menu
      ref={ref}
      popperConfig={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: () => [0, OFFSET]
            }
          }
        ]
      }}
      { ...props }
    >
      {children}
    </Base.Menu>
  )
);

Menu.propTypes = propTypes;
Menu.defaultProps = defaultProps;
Menu.displayName = 'Dropdown.Menu';

export default Menu;
