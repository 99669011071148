import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import FetchableSelect from '@/components/Inputs/FetchableSelect';
import Avatar from '@/components/Avatar';
import { useUsers } from '@/api';
import { useAuth } from '@/lib/auth';
import Icon from '@/components/Icon';

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  allowNull: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func
};

const defaultProps = {
  name: 'user_id_in',
  label: 'Usuário',
  placeholder: 'Qualquer usuário',
  multiple: false,
  allowNull: false,
  isClearable: true,
  isSearchable: true,
  defaultValue: null,
  onChange: () => {}
};

function UserSelect(props) {
  const { user } = useAuth();

  const transformer = useCallback((fetchedData) => {
    const userOptions = fetchedData.data.reduce((result, item) => {
      const groupIndex = item.active ? 1 : 2;
      const option = {
        label: item.name,
        value: item.id,
        leftAdornment: <Avatar name={item.name} url={item.avatarUrl} tooltip={false} />
      };

      if (user.id === item.id) {
        option.label = `Eu (${item.name})`;
        result[0].options.push(option);
      } else {
        result[groupIndex].options.push(option);
      }

      return result;
    }, [
      { options: [] },
      { label: 'Ativos', options: [] },
      { label: 'Inativos', options: [] }
    ]);

    if (props.createdBy) {
      const automationOption = {
        label: 'Automação',
        value: 0,
        leftAdornment: <Icon name='lightning' />
      };
      userOptions[0].options.splice(1, 0, automationOption);
    }
    if (props.allowNull) {
      const nullOption = {
        label: 'Indefinido',
        value: -1,
        leftAdornment: <Icon name='person' className='me-1' />
      };
      userOptions[0].options.splice(0, 0, nullOption);
    }
    return userOptions;
  }, [user]);


  return (
    <FetchableSelect
      { ...props }
      transformer={transformer}
      query={useUsers}
    />
  );
}

UserSelect.propTypes = propTypes;
UserSelect.defaultProps = defaultProps;

export default UserSelect;
