import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import HookForm from '@/components/HookForm';
import HookFormField from '@/components/HookFormField';
import { Select } from '@/components/Inputs';
import Badge from '@/components/Badge';
import FooterButtons from '@/feature/profile_form/FooterButtons';
import { useUpdateProfile } from '@/api';
import ToggleButtonGroup from '@/components/ToggleButtonGroup';
import ToggleButton from '@/components/ToggleButton';
import { useAuth } from '@/lib/auth';
import HookFormFeedback from '@/components/HookFormFeedback';
import { useTracking } from '@/lib/tracking';

const propTypes = {
  advanceStep: PropTypes.func,
  returnStep: PropTypes.func
};

const defaultProps = {
  advanceStep: () => {},
  returnStep: () => {}
};

const schema = Yup.object().shape({
  account: Yup.object().shape({
    industry: Yup.string()
      .required('Por favor, informe o ramo de atividade da sua empresa.'),
    salesperson_number: Yup.string()
      .required('Por favor, informe a quantidade de pessoas.')
  })
});

const industryOptions = [
  {
    label: 'Agricultura e pecuária',
    value: 'Agricultura e pecuária'
  },
  {
    label: 'Alimentação empresarial',
    value: 'Alimentação empresarial'
  },
  {
    label: 'Associações empresariais',
    value: 'Associações empresariais'
  },
  {
    label: 'Audiovisual e eventos',
    value: 'Audiovisual e eventos'
  },
  {
    label: 'Autônomo',
    value: 'Autônomo'
  },
  {
    label: 'Beleza',
    value: 'Beleza'
  },
  {
    label: 'Comércio varejista',
    value: 'Comércio varejista'
  },
  {
    label: 'Construção e infraestrutura',
    value: 'Construção e infraestrutura'
  },
  {
    label: 'Consultoria',
    value: 'Consultoria'
  },
  {
    label: 'Corretoras de seguros',
    value: 'Corretoras de seguros'
  },
  {
    label: 'Distribuidoras e revendedoras',
    value: 'Distribuidoras e revendedoras'
  },
  {
    label: 'E-commerce',
    value: 'E-commerce'
  },
  {
    label: 'Editoras',
    value: 'Editoras'
  },
  {
    label: 'Educação',
    value: 'Educação'
  },
  {
    label: 'Energia e gás',
    value: 'Energia e gás'
  },
  {
    label: 'Engenharia, arquitetura e urbanismo',
    value: 'Engenharia, arquitetura e urbanismo'
  },
  {
    label: 'Esporte e lazer',
    value: 'Esporte e lazer'
  },
  {
    label: 'Hotelaria',
    value: 'Hotelaria'
  },
  {
    label: 'Imobiliárias',
    value: 'Imobiliárias'
  },
  {
    label: 'Indústrias e fábricas',
    value: 'Indústrias e fábricas'
  },
  {
    label: 'Internet',
    value: 'Internet'
  },
  {
    label: 'Limpeza e paisagismo',
    value: 'Limpeza e paisagismo'
  },
  {
    label: 'Locadoras e franqueadoras',
    value: 'Locadoras e franqueadoras'
  },
  {
    label: 'Lojista / Loja Física',
    value: 'Lojista / Loja Física'
  },
  {
    label: 'Maquinário e equipamentos automotivos',
    value: 'Maquinário e equipamentos automotivos'
  },
  {
    label: 'Marketing e publicidade',
    value: 'Marketing e publicidade'
  },
  {
    label: 'Outros',
    value: 'Outros'
  },
  {
    label: 'Recrutamento e outsourcing',
    value: 'Recrutamento e outsourcing'
  },
  {
    label: 'Saneamento',
    value: 'Saneamento'
  },
  {
    label: 'Saúde e serviços sociais',
    value: 'Saúde e serviços sociais'
  },
  {
    label: 'Seguradoras',
    value: 'Seguradoras'
  },
  {
    label: 'Segurança e vigilância',
    value: 'Segurança e vigilância'
  },
  {
    label: 'Serviços administrativos',
    value: 'Serviços administrativos'
  },
  {
    label: 'Serviços de informática',
    value: 'Serviços de informática'
  },
  {
    label: 'Serviços financeiros',
    value: 'Serviços financeiros'
  },
  {
    label: 'Serviços jurídicos e contábeis',
    value: 'Serviços jurídicos e contábeis'
  },
  {
    label: 'Software e TI',
    value: 'Software e TI'
  },
  {
    label: 'Telecomunicação',
    value: 'Telecomunicação'
  },
  {
    label: 'Transporte, armazenagem e logística',
    value: 'Transporte, armazenagem e logística'
  },
  {
    label: 'Turismo corporativo',
    value: 'Turismo corporativo'
  }
];

const salespeopleNumberOptions = [
  { label: '1 pessoa', value: '1 pessoa' },
  { label: '2 pessoas', value: '2 pessoas' },
  { label: '3 pessoas', value: '3 pessoas' },
  { label: '4 pessoas', value: '4 pessoas' },
  { label: '5 pessoas', value: '5 pessoas' },
  { label: '6 pessoas', value: '6 pessoas' },
  { label: '7 pessoas', value: '7 pessoas' },
  { label: '8 pessoas', value: '8 pessoas' },
  { label: '9 pessoas', value: '9 pessoas' },
  { label: '10 pessoas', value: '10 pessoas' },
  { label: '11 até 20 pessoas', value: '11 até 20 pessoas' },
  { label: '21 até 50 pessoas', value: '21 até 50 pessoas' },
  { label: '51 até 100 pessoas', value: '51 até 100 pessoas' },
  { label: 'mais que 101 pessoas', value: 'mais que 101 pessoas' }
];

function IndustryStep({ returnStep, advanceStep }) {
  const { user } = useAuth();
  const tracker = useTracking();

  useEffect(() => {
    tracker.trackProfileStepViewed({ user, step: 3 });
  }, []);

  const defaultValues = useMemo(() => ({
    account: {
      industry: user.account.industry ?? '',
      salesperson_number: user.account.salespersonNumber ?? ''
    }
  }), [user]);

  const { mutate, isLoading } = useUpdateProfile();
  const handleNext = (values) => {
    mutate({ params: values }, {
      onSuccess: ({ data }) => {
        /**
         * Aqui é usada a resposta da requisição (`data`) ao invés do objeto de
         * usuário (`user`) porque no momento que essa função é chamada, o `user`
         * ainda não está atualizado com os dados que foram preenchidos no formulário.
         */
        tracker.trackProfileStepCompleted({ user: data });

        advanceStep();
      }
    });
  };

  return (
    <HookForm
      onSubmit={handleNext}
      name='profile-form'
      validationSchema={schema}
      defaultValues={defaultValues}
      className='mt-4'
    >
      {
        () => (
          <Row>
            <Col lg={{ span: 6, offset: 3 }}>
              <Badge variant='light' className='text-small mb-3'>
                Passo 3 de 4
              </Badge>

              <HookFormField
                as={Select}
                name='account.industry'
                placeholder='Selecione'
                options={industryOptions}
                className='mb-6'
                label={<h1 className='mb-3'>Qual é o ramo de atividade da sua empresa?</h1>}
              />

              <h1 className='mb-3'>
                Quantas pessoas da sua empresa usariam o Agendor nessa conta?
              </h1>
              <HookFormField
                as={ToggleButtonGroup}
                name='account.salesperson_number'
                className='flex-wrap gap-3'
              >
                {salespeopleNumberOptions.map((option, index) => (
                  <ToggleButton
                    key={option.value}
                    id={`salesperson_number_${index}`}
                    value={option.value}
                    className='rounded-1 flex-grow-0'
                  >
                    {option.label}
                  </ToggleButton>
                ))}
              </HookFormField>
              <HookFormFeedback name='account.salesperson_number' />

              <FooterButtons handlePrevious={returnStep} disabled={isLoading} />
            </Col>
          </Row>
        )
      }
    </HookForm>
  );
}

IndustryStep.propTypes = propTypes;
IndustryStep.defaultProps = defaultProps;

export default IndustryStep;
