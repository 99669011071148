import { useMemo } from 'react';
import { now, tomorrow } from '@/date';
import { useStoredState } from '@/hooks';

const WarningTime = 6;

export function useNextVisibleTime(keyPrefix) {
  const key = `${keyPrefix}-next-visible-time`;
  const { value: nextVisibleTime, store: storeNextVisibleTime } = useStoredState(key, null);

  const isWarningVisible = useMemo(() => {
    if (!nextVisibleTime) {
      return true;
    }
    return now() >= new Date(nextVisibleTime);
  }, [nextVisibleTime]);

  const handleWarningClose = () => {
    const visibleTime = now();
    visibleTime.setDate(tomorrow().getDate());
    visibleTime.setHours(WarningTime, 0, 0, 0);
    storeNextVisibleTime(visibleTime.toISOString());
  };

  return {
    isWarningVisible,
    handleWarningClose
  };
}
