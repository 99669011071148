import React from 'react';
import Card from '@/components/Card';
import Button from '@/components/Button';
import Icon from '@/components/Icon';

function PaymentInfoStep({ nextStep, onHide }) {
  return (
    <div>
      <div className='h2 fw-bold mt-6'>Informações da contratação</div>
      <div className='text-medium-gray mt-4'>Mensalidade</div>
      <div className='text-primary-gray mt-2 h2'>R$ 30,00 /mês</div>
      <div className='text-dark-gray mt-2'>+ taxa de ativação de R$ 30 no primeiro mês.</div>
      <hr className='mt-4 text-dark opacity-100'></hr>
      <div className='text-medium-gray mt-4'>Método de pagamento</div>
      <div className='text-primary mt-2'></div>
      <div className='h3 fw-bold mt-2 h3'>Boleto bancário emitido pela Zenvia</div>
      <div className='text-medium-gray mt-2'>
        <span className='text-dark-gray fw-bold'>Você não pagará nada ao Agendor.
        </span> Todos os pagamentos relacionados a contratação
        do número serão realizados diretamente para a Zenvia.
      </div>
      <Card className='p-4 bg-light border-0 mt-4'>
        <div className='d-flex align-items-center'>
          <Icon
            name='info'
            className='text-dark-gray'
          />
          <span className='text-dark-gray ms-2'>
            Após a solicitação, a Zenvia pode levar de 1 a 5 dias
            para disponibilizar o número de telefone.
          </span>
        </div>
      </Card>
      <div className='d-flex justify-content-end mt-4'>
        <Button variant='outline-dark-gray' className='me-2' onClick={onHide}>
          Cancelar
        </Button>
        <Button variant='primary' className='ms-2' onClick={nextStep}>
          Selecionar o DDD
        </Button>
      </div>
    </div>
  );
}

export default PaymentInfoStep;
