import React, { useMemo } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import EditCustomFields from '@/components/EditCustomFields';
import DeleteCustomFields from '@/components/DeleteCustomFields';
import {
  InputPreview
} from '@/components/CustomFields';
import {
  TextInput
} from '@/components/Inputs';

const propTypes = {
  section: PropTypes.string.isRequired,
  useRowContainer: PropTypes.bool,
  updateMutation: PropTypes.object.isRequired,
  deleteMutation: PropTypes.object.isRequired,
  entity: PropTypes.oneOf(['organization', 'person', 'deal']).isRequired,
  fields: PropTypes.array
};

const defaultProps = {
  fields: [],
  useRowContainer: true
};

const INPUT_TYPES = {
  text: TextInput,
  integer: TextInput,
  decimal: TextInput,
  multiselect: TextInput,
  select: TextInput
};

function FieldsContainer({ children, useRowContainer, className, id }) {
  if (!useRowContainer) {
    return (<>{children}</>);
  }
  return (
    <Row id={id} className={className}>
      {children}
    </Row>
  );
}

export function CustomFieldsSectionPreview({
  section, fields = [], updateMutation, deleteMutation, entity, useRowContainer
}) {
  const filteredFields = useMemo(
    () => fields.filter((field) => field.section === section),
    [section, fields]
  );

  if (filteredFields.length) {
    return (
      <FieldsContainer
        useRowContainer={useRowContainer}
        className='mb-2'
        id={`custom-fields-${section}`}
      >
        {
          filteredFields.map((field) => {
            // Incluindo actionProps somente para text input para evitar warnings no textarea
            const actionProps = {
              'data-field-type': field.type
            };
            if (field.type !== 'textarea') {
              actionProps.rightAdornment = () => (
                <div className={classnames(
                  'position-absolute',
                  'top-50',
                  'end-0',
                  'translate-middle-y',
                  'pe-2',
                  'cursor-default'
                )}>
                  <EditCustomFields data={field} mutation={updateMutation} entity={entity} />
                  <DeleteCustomFields data={field} mutation={deleteMutation} entity={entity} />
                </div>
              );
            }
            return (
              <Col sm={6} key={field.identifier}>
                <InputPreview
                  label={field.name}
                  name={`custom_fields.${field.identifier}`}
                  as={INPUT_TYPES[field.type]}
                  { ...actionProps }
                />
              </Col>
            );
          })
        }
      </FieldsContainer>
    );
  }
  return <React.Fragment />;
}

CustomFieldsSectionPreview.propTypes = propTypes;
CustomFieldsSectionPreview.defaultProps = defaultProps;
