import React, { useState, useRef, useCallback, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import Overlay from 'react-bootstrap/Overlay';
import { contains, matches } from 'dom-helpers';

const propTypes = {
  overlay: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  trigger: PropTypes.arrayOf(PropTypes.oneOf([
    'hover',
    'focus',
    'click',
    'clickIn',
    'focusIn',
    'none'
  ])),
  hide: PropTypes.bool
};

const defaultProps = {
  trigger: ['hover', 'focus'],
  hide: false,
  initialShow: false
};

const triggerPropNames = {
  hover: {
    onShow: 'onMouseEnter',
    onHide: 'onMouseLeave'
  },
  focus: {
    onShow: 'onFocus',
    onHide: 'onBlur'
  },
  click: {
    onToggle: 'onClick'
  },
  clickIn: {
    onShow: 'onClick'
  },
  focusIn: {
    onShow: 'onFocus'
  },
  none: {}
};

const OverlayWrapper = React.forwardRef(
  ({ overlay, children, hide, trigger, onShow, ...props }, ref) => {
    const child = React.Children.only(children);

    const target = useRef(null);
    // Mantém a ref anterior (https://github.com/facebook/react/issues/8873)
    const { ref: previousRef } = child;
    const targetRef = useCallback((value) => {
      target.current = value;

      if (typeof previousRef === 'function') {
        previousRef(value);
      } else if (previousRef !== null) {
        previousRef.current = value;
      }
    }, [previousRef]);

    const [show, setShow] = useState(props.initialShow);
    const handleShow = () => {
      setShow(true);
      onShow?.();
    };
    const onHide = (event) => {
      if (!isRootCloseInvalid(trigger, props.rootCloseEvent, event, target)) {
        setShow(false);
      }
    };
    const onToggle = () => {
      setShow((prevShow) => !prevShow);
      if (!show) {
        onShow?.();
      }
    };

    const triggerProps = getTriggerProps(trigger, child, {
      onShow: handleShow, onHide, onToggle
    });

    useImperativeHandle(ref, () => ({
      setShow
    }), [setShow]);

    return (
      <React.Fragment>
        {React.cloneElement(child, { ref: targetRef, ...triggerProps })}

        <Overlay
          { ...props }
          show={!hide && show}
          onHide={onHide}
          target={target.current}
        >
          {overlay({ onShow, onHide })}
        </Overlay>
      </React.Fragment>
    );
  }
);

function getTriggerProps(triggers, element, handlers) {
  const props = {};

  Array.from(triggers).forEach((trigger) => {
    Object.entries(handlers).forEach(([handlerName, handler]) => {
      const propName = triggerPropNames[trigger][handlerName];

      if (propName) {
        const originalHandler = element.props[propName];

        props[propName] = (event) => {
          handler(event);

          if (originalHandler) {
            originalHandler(event);
          }
        };
      }
    });
  });

  return props;
}

function isRootCloseEventMouseDown(trigger, rootCloseEvent, event) {
  return (
    (trigger.includes('click') || trigger.includes('clickIn')) &&
    rootCloseEvent === 'mousedown' &&
    event.type === rootCloseEvent
  );
}

function isRootCloseOnToggle(event, target) {
  return contains(target.current, event.target);
}

export function isRootCloseOnSubOverlay(event) {
  return matches(event.target, '.sub-overlay *');
}

function isRootCloseInvalid(trigger, rootCloseEvent, event, target) {
  return (
    event &&
      isRootCloseEventMouseDown(trigger, rootCloseEvent, event) &&
      (
        isRootCloseOnToggle(event, target) ||
        isRootCloseOnSubOverlay(event)
      )
  );
}

OverlayWrapper.displayName = 'OverlayWrapper';
OverlayWrapper.propTypes = propTypes;
OverlayWrapper.defaultProps = defaultProps;

export default OverlayWrapper;
