export { useUppy } from '@uppy/react';
import Uppy from '@uppy/core';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import XHRUpload from '@uppy/xhr-upload';
import AwsS3 from '@uppy/aws-s3';
import { getCsrfHeader } from '@/lib/fetch';
import { presign, uploadUrl } from '@/api/attachments';
import { UPLOAD_BACKEND } from '@/config';

const MEGABYTE = 1048576;
const USE_S3 = UPLOAD_BACKEND === 'S3';

export function createUppy(options) {
  const {
    allowedFileTypes,
    maxFileSizeMega = 1,
    thumbnailGenerator,
    locale,
    maxNumberOfFiles = 1
  } = options;

  const uppy = new Uppy({
    restrictions: {
      allowedFileTypes,
      maxNumberOfFiles,
      maxFileSize: maxFileSizeMega * MEGABYTE
    },
    autoProceed: true,
    locale
  });

  if (thumbnailGenerator) {
    uppy.use(ThumbnailGenerator, {
      thumbnailWidth: 200,
      thumbnailType: 'image/png',
      waitForThumbnailsBeforeUpload: true
    });
  }

  if (USE_S3) {
    uppy.use(AwsS3, {
      async getUploadParameters(file) {
        const { data } = await presign({
          filename: file.name,
          type: file.type
        });

        return data;
      }
    }).on('upload-success', (file) => {
      const { id } = file.meta.key.match(/^tmp\/(?<id>.+)/u).groups;
      const body = {
        id,
        storage: 'cache',
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type
        }
      };

      uppy.setFileState(file.id, {
        response: { body }
      });
    });
  } else {
    uppy.use(XHRUpload, {
      endpoint: uploadUrl(),
      headers: getCsrfHeader()
    });
  }

  return uppy;
}
