import { useMemo, useState, useCallback } from 'react';
import { isBlank } from '@/utils';
import storage from '@/lib/storage';

export function useStoredState(key, defaultValue) {
  const initialValue = useMemo(() => storage.get(key), [key]);

  const [value, setValue] = useState(
    isBlank(initialValue) ? defaultValue : initialValue
  );

  const store = useCallback((newValue) => {
    storage.set(key, newValue);
    setValue(newValue);
  }, [key]);

  const remove = useCallback(() => {
    storage.remove(key);
    setValue(null);
  }, [key]);

  return { value, store, initialValue, remove };
}
