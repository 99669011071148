import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Popover from '@/components/Popover';
import Icon from '@/components/Icon';

EmailDetailPopover.propTypes = {
  email: PropTypes.object
};

EmailDetailPopover.defaultProps = {
  email: {}
};

function EmailDetailPopover({ email }) {
  return (
    <Popover
      flip
      className='width-7'
      content={() => (
        <>
          <Row>
            <Col sm={2} className='text-end fw-light'>de:</Col>
            <Col sm={10} className='fw-bold'>{email.from}</Col>
          </Row>

          <Row>
            <Col sm={2} className='text-end fw-light'>para:</Col>
            <Col sm={10} className='fw-bold'>{email.to?.join(', ')}</Col>
          </Row>

          <Row>
            <Col sm={2} className='text-end fw-light'>cc:</Col>
            <Col sm={10} className='fw-bold'>{email.cc?.join(', ')}</Col>
          </Row>

          <Row>
            <Col sm={2} className='text-end fw-light'>cco:</Col>
            <Col sm={10} className='fw-bold'>{email.bcc?.join(', ')}</Col>
          </Row>
        </>
      )}
    >
      <a><Icon name='arrow-down' className='mb-1 cursor-pointer' /></a>
    </Popover>
  );
}

export default EmailDetailPopover;
