import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { goBack } from '@/browser';
import { useDocumentTitle } from '@/hooks';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import Main from '@/components/Main';
import ImportHistory from '@/components/ImportHistory';
import { ImportGuide } from '@/components/Imports';
import ImportSteps from '@/components/Imports/ImportSteps';
import Footer from '@/components/Footer';
import Button from '@/components/Button';
import Icon from '@/components/Icon';

const PAGE_TITLES = {
  organizations: 'Importação de empresas',
  people: 'Importação de pessoas',
  products: 'Importação de produtos',
  activities: 'Importação de comentários',
  deals: 'Importação de negócios'
};

const propTypes = {
  entity: PropTypes.oneOf(['organizations', 'people', 'products', 'activities', 'deals'])
};

const FIRST_IMPORT_STEP = 0;
const LAST_IMPORT_STEP = 2;

function ImportsIndex({ entity }) {
  const { user } = useAuth();
  const tracker = useTracking();

  const title = PAGE_TITLES[entity];

  useDocumentTitle(title);

  const nextStepButtonRef = useRef(null);
  const previousStepButtonRef = useRef(null);

  const nextStep = () => nextStepButtonRef?.current?.click?.();
  const previousStep = () => previousStepButtonRef?.current?.click?.();

  const [importStep, setImportStep] = useState(FIRST_IMPORT_STEP);
  const isFirstImportStep = importStep === FIRST_IMPORT_STEP;
  const isLastImportStep = importStep === LAST_IMPORT_STEP;

  const [file, setFile] = useState(null);

  useEffect(() => {
    tracker.trackNewImportViewed({ user, entity });
  }, []);

  return (
    <>
      <Main fluid flex className='flexible-height'>
        <Container className='d-flex flex-column flex-grow-1 flexible-height mt-4'>
          <div>
            <div
              onClick={() => goBack()}
              className='cursor-pointer d-inline-flex align-items-center mb-5'
            >
              <Icon name='arrow-left' size='lg' className='ms-2 text-primary fw-bold' />
              <h4 className='ms-2 fw-bold'>{title}</h4>
            </div>
          </div>
          <Row className={classnames({
            'd-flex flex-column flex-grow-1 flexible-height': !isFirstImportStep
          })}>
            <Col
              className='d-flex flex-column flex-grow-1 flexible-height mb-4'
              sm={12}
              md={12}
              lg={(isFirstImportStep ? '9' : '12')}
            >
              <ImportGuide
                entity={entity}
                defaultOpen={isFirstImportStep}
                /**
                 * Altera a key para que o componente seja remontado e feche
                 * automaticamente ao sair do primeiro passo, mas sem animação.
                 */
                key={isFirstImportStep}
              />

              <ImportSteps
                resource={entity}
                nextStepButtonRef={nextStepButtonRef}
                previousStepButtonRef={previousStepButtonRef}
                onChange={setImportStep}
                file={file}
                setFile={setFile}
              />
            </Col>
            {
              (isFirstImportStep) &&
              <Col sm={12} md={12} lg={3}>
                <h4 className='mb-4'>Histórico de importações</h4>

                <ImportHistory entity={entity} />
              </Col>
            }
          </Row>
        </Container>
      </Main>

      <Footer className='mt-auto flex-shrink-0 py-4 shadow-up bg-white'>
        <Container>
          <Row>
            <Col
              md={12}
              className='d-flex justify-content-end'
            >
              {
                !isFirstImportStep &&
                <Button
                  type='submit'
                  variant='outline-dark-gray'
                  onClick={previousStep}
                >
                  Voltar
                </Button>
              }
              <Button
                className='ms-3'
                type='submit'
                disabled={!file}
                onClick={nextStep}
              >
                {isLastImportStep ? 'Realizar importação' : 'Próximo'}
              </Button>
            </Col>
          </Row>
        </Container>
      </Footer>
    </>
  );
}

ImportsIndex.propTypes = propTypes;

export default ImportsIndex;
