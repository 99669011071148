import React, { useMemo } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Modal from '@/components/Modal';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import { TextInput, RichTextInput } from '@/components/Inputs';
import LoadingButton from '@/components/LoadingButton';

const propTypes = {
  show: PropTypes.bool,
  onCancel: PropTypes.func,
  isCreating: PropTypes.bool,
  emailTemplate: PropTypes.object
};

const defaultProps = {
  show: false,
  onCancel: () => {},
  isCreating: true,
  emailTemplate: {}
};

const schema = Yup.object()
  .shape({
    name: Yup.string().required('Informe nome do modelo de e-mail.'),
    subject: Yup.string().required('Informe o assunto do modelo de e-mail.'),
    body: Yup.string().required('Corpo do modelo de e-mail é obrigatório.')
  });

const defaultValues = {
  name: '',
  subject: '',
  body: ''
};

function EmailTemplateModal({ show, onCancel, onSubmit, isCreating, emailTemplate }) {
  const TITLE = `${isCreating ? 'Criar' : 'Editar'} um modelo de e-mail`;

  const initialValues = useMemo(() => ({
    name: emailTemplate?.name ?? '',
    subject: emailTemplate?.subject ?? '',
    body: emailTemplate?.body ?? ''
  }), [emailTemplate]);

  return (
    <Modal onHide={onCancel} size='lg' bodyClassName='p-8' show={show}>
      <div className='mb-8 text-center'>
        <div className='d-inline-block
          p-3
          rounded-circle
          bg-dark-gray
          text-white
          lh-0
          position-relative'
        >
          <Icon size='lg' name='email'></Icon>
        </div>
        <h2 className='mt-4'>{TITLE}</h2>
        <p className='text-dark-gray'>
          No assunto e no modelo, use {'{{nome}}, {{nome-completo}} ou {{nome-empresa}}'} para
          substituir pelos dados do destinatário.
        </p>
      </div>

      <Form
        name='create-email-template-form'
        validationSchema={schema}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        initialValues={initialValues}
      >
        {({ isSubmitting }) => (
          <>
            <Row>
              <Col sm={12}>
                <FormField
                  as={TextInput}
                  name='name'
                  label='Nome'
                  autoComplete='off'
                  placeholder='Dê um nome para o modelo de e-mail'
                  hint='Obrigatório'
                  maxLength={60}
                  variant='light'
                />
              </Col>
            </Row>

            <Row className='mt-3 gx-3'>
              <Col sm={12}>
                <FormField
                  as={TextInput}
                  name='subject'
                  label='Assunto'
                  autoComplete='off'
                  placeholder='Digite aqui o assunto do modelo'
                  hint='Obrigatório'
                  maxLength={120}
                  variant='light'
                />
              </Col>
            </Row>
            <Row className='gx-3'>
              <Col sm={12}>
                <FormField
                  as={RichTextInput}
                  className='mb-4'
                  name='body'
                  label='Modelo'
                  hint='Obrigatório'
                  variant='light'
                />
              </Col>
            </Row>

            <div className='mt-6 d-flex justify-content-end'>
              <Button
                onClick={onCancel}
                variant='outline-dark-gray'
                className='me-3'
              >
                Cancelar
              </Button>

              <LoadingButton
                isLoading={isSubmitting}
                type='submit'
              >
                Salvar
              </LoadingButton>
            </div>
          </>
        )}
      </Form>
    </Modal>
  );
}

EmailTemplateModal.propTypes = propTypes;
EmailTemplateModal.defaultProps = defaultProps;

export default EmailTemplateModal;
