import React, { useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from '@/components/Button';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import {
  TextInput, MaskedInput, WhatsAppInput, OrganizationSelect,
  CategorySelect, LeadOriginSelect, UserSelect, AddressFields
} from '@/components/Inputs';
import LoadingButton from '@/components/LoadingButton';
import Collapse from '@/components/Collapse';
import CollapseToggle from '@/components/CollapseToggle';
import AsyncFormField from '@/components/AsyncFormField';
import { useAuth } from '@/lib/auth';
import { usePersonValidation } from '@/api';
import {
  schema as addressSchema, defaultValues as addressDefaultValues
} from '@/components/Inputs/AddressFields';
import { schema as privacySchema } from '@/components/Inputs/PrivacyInput';
import { CustomFieldsSection, customFieldsSchema } from '@/components/CustomFields';

const propTypes = {
  initialData: PropTypes.shape({
    name: PropTypes.string,
    organization_id: PropTypes.number
  }),
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  customFields: PropTypes.array,
  className: PropTypes.string,
  focusName: PropTypes.bool,
  hideCollapse: PropTypes.bool,
  saveMessageModal: PropTypes.bool
};

const defaultProps = {
  initialData: { name: '', organization_id: null },
  onSubmit: () => { },
  onCancel: () => { },
  customFields: [],
  className: '',
  focusName: false,
  hideCollapse: false,
  saveMessageModal: false
};

/* eslint-disable no-magic-numbers */
const schema = Yup.object().shape({
  name: Yup.string()
    .max(60, 'O nome da pessoa deve ter no máximo 60 caracteres.')
    .required('Por favor, informe o nome da pessoa.'),
  cpf: Yup.string()
    .length(11, 'CPF inválido.')
    .when('cpf_async', ([value], fieldSchema) => (
      value
        ? fieldSchema.notOneOf([value], 'Já existe uma pessoa com esse CPF.')
        : fieldSchema
    )),
  birthday: Yup.string()
    .length(5, 'Data de aniversário inválida.'),
  birth_year: Yup.number('Ano inválido.'),
  job_title: Yup.string()
    .max(60, 'O cargo da pessoa deve ter no máximo 60 caracteres.'),
  contact: Yup.object().shape({
    email: Yup.string().email('E-mail inválido.'),
    whatsapp: Yup.string()
      .min(12, 'Número inválido.')
      .max(16, 'Número inválido.'),
    work_phone: Yup.string()
      .min(10, 'Número inválido.')
      .max(11, 'Número inválido.')
  }),
  address: addressSchema,
  privacy: privacySchema,
  custom_fields: customFieldsSchema
});
/* eslint-enable no-magic-numbers */

const defaultValues = {
  name: '',
  cpf: '',
  birthday: '',
  birth_year: '',
  job_title: '',
  organization_id: '',
  owner_user_id: '',
  category_id: '',
  lead_origin_id: '',
  contact: {
    email: '',
    whatsapp: '',
    work_phone: ''
  },
  privacy: {
    type: 'default'
  },
  custom_fields: {},
  ...addressDefaultValues
};

function CreatePersonForm({
  initialData, onSubmit, onCancel, saveMessageModal,
  className, customFields, focusName, hideCollapse
}) {
  const { user } = useAuth();

  const initialValues = useMemo(() => (
    {
      name: initialData.name,
      organization_id: initialData.organization_id,
      owner_user_id: user.id
    }
  ), [initialData, user]);

  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = () => setShowMore((prev) => !prev);

  const birthdayRef = useRef();
  const onShowMore = () => {
    if (birthdayRef.current && !focusName) {
      birthdayRef.current.focus();
    }
  };

  return (
    <Form
      name='create-person-form'
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      initialValues={initialValues}
      validationSchema={schema}
      validationContext={{ customFields }}
    >
      {
        ({ handleBlur, handleSubmit, isSubmitting }) => (
          <div className={className}>
            <Row>
              <Col sm={12}>
                <FormField
                  as={TextInput}
                  autoFocus={focusName}
                  data-autofocus={focusName?.toString()}
                  name='name'
                  label='Nome'
                  autoComplete='off'
                  placeholder=''
                  maxLength={60}
                  variant='light'
                  onBlur={handleBlur}
                />
              </Col>
            </Row>

            <h4 className='my-4'>
              Dados da pessoa
            </h4>

            <Row className='gx-3'>
              <Col sm={6}>
                <FormField
                  as={MaskedInput}
                  name='contact.email'
                  maskType='email'
                  label='E-mail'
                  autoComplete='off'
                  placeholder='exemplo@email.com'
                  variant='light'
                  onBlur={handleBlur}
                  data-autofocus={!focusName?.toString()}
                  autoFocus={!focusName}
                />
              </Col>
              <Col sm={6}>
                <AsyncFormField
                  as={MaskedInput}
                  query={usePersonValidation}
                  maskType='cpf'
                  name='cpf'
                  label='CPF'
                  autoComplete='off'
                  placeholder='000.000.000-00'
                  maxLength={14}
                  variant='light'
                  onBlur={handleBlur}
                />
              </Col>
            </Row>

            <Row className='gx-3'>
              <Col sm={6}>
                <FormField
                  as={WhatsAppInput}
                  name='contact.whatsapp'
                  label='WhatsApp'
                  autoComplete='off'
                  variant='light'
                  onBlur={handleBlur}
                />
              </Col>
              <Col sm={6}>
                <FormField
                  as={MaskedInput}
                  maskType='phone'
                  name='contact.work_phone'
                  label='Telefone'
                  autoComplete='off'
                  placeholder='(00) 0000-0000'
                  variant='light'
                  onBlur={handleBlur}
                />
              </Col>
            </Row>

            <CustomFieldsSection
              section='basic_data'
              fields={customFields}
              className='gx-3'
            />
            <CustomFieldsSection
              section='contact'
              fields={customFields}
              className='gx-3'
            />
            <CustomFieldsSection
              section='address'
              fields={customFields}
              className='gx-3'
            />

            <Collapse
              in={showMore}
              onEntered={onShowMore}
            >
              <div>
                <Row className='gx-3'>
                  <Col sm={4}>
                    <FormField
                      as={MaskedInput}
                      maskType='monthDay'
                      name='birthday'
                      label='Data de aniversário'
                      placeholder='00/00'
                      onBlur={handleBlur}
                      inputRef={birthdayRef}
                      autoComplete='off'
                    />
                  </Col>
                  <Col sm={2}>
                    <FormField
                      as={MaskedInput}
                      maskType='natural'
                      name='birth_year'
                      label='Ano'
                      placeholder='0000'
                      onBlur={handleBlur}
                      autoComplete='off'
                    />
                  </Col>
                  <Col sm={6}>
                    <FormField
                      as={CategorySelect}
                      name='category_id'
                      label='Categoria'
                      placeholder='Selecione'
                    />
                  </Col>
                </Row>

                <Row className='gx-3'>
                  <Col sm={6}>
                    <FormField
                      as={LeadOriginSelect}
                      name='lead_origin_id'
                      label='Origem'
                      placeholder='Selecione'
                    />
                  </Col>
                  <Col sm={6}>
                    <FormField
                      as={UserSelect}
                      name='owner_user_id'
                      label='Responsável'
                      placeholder='Selecione'
                    />
                  </Col>
                </Row>

                <Row className='gx-3'>
                  <Col sm={6}>
                    <FormField
                      as={OrganizationSelect}
                      name='organization_id'
                    />
                  </Col>
                  <Col sm={6}>
                    <FormField
                      as={TextInput}
                      name='job_title'
                      label='Cargo'
                      placeholder='Digite o cargo'
                      onBlur={handleBlur}
                      maxLength={60}
                      autoComplete='off'
                    />
                  </Col>
                </Row>

                <h4 className='my-4'>
                  Dados de endereço
                </h4>

                <AddressFields />
              </div>
            </Collapse>

            {hideCollapse
              ? null
              : (
                <CollapseToggle
                  open={showMore}
                  onToggle={toggleShowMore}
                  closedLabel='Cadastrar mais dados'
                  openLabel='Ver menos dados'
                />
              )}

            <Row className='mt-8'>
              <Col sm={6}>
                <Button
                  className='w-100'
                  variant='outline-dark-gray'
                  onClick={onCancel}
                  disabled={isSubmitting}
                >
                  Cancelar
                </Button>
              </Col>
              <Col sm={6}>
                <LoadingButton
                  isLoading={isSubmitting}
                  className='w-100'
                  variant='primary'
                  onClick={handleSubmit}
                >
                  {saveMessageModal ? 'Salvar' : 'Salvar e continuar'}
                </LoadingButton>
              </Col>
            </Row>
          </div>
        )
      }
    </Form>
  );
}

CreatePersonForm.propTypes = propTypes;
CreatePersonForm.defaultProps = defaultProps;

export default CreatePersonForm;
