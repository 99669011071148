import React, { createContext, useContext, useMemo } from 'react';
import FullPageLoading from '@/components/FullPageLoading';
import { isUnauthorizedRequestError } from '@/errors';
import { useCurrentUser } from '@/api';

export const AuthenticationContext = createContext();

export function AuthenticationProvider({ children }) {
  const { isLoading, error, data: { data: user } = {} } = useCurrentUser({
    config: {
      retry: false,
      staleTime: Infinity
    }
  });

  const value = useMemo(() => ({ user }), [user]);

  if (error && !isUnauthorizedRequestError(error)) {
    return <div>Error :(</div>;
  }

  if (isLoading) {
    return <FullPageLoading />;
  }

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthenticationContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within AuthenticationProvider');
  }

  return context;
}
