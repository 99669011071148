import { useQuery, useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { get, patch, post, _delete } from '@/lib/fetch';
import { backlogItemsKeys, personValidationKeys, searchesKeys, organizationsKeys } from '@/api';

export const peopleKeys = {
  all: ['people'],
  infiniteAll: ['infinite-people'],
  infiniteLists: () => [...peopleKeys.infiniteAll, 'list'],
  infiniteList: (params) => ([...peopleKeys.infiniteLists(), params]),
  details: () => [...peopleKeys.all, 'detail'],
  detail: (personId) => [...peopleKeys.details(), personId]
};

export async function getPeople(params = {}) {
  const { data } = await get('/people', params);

  return data;
}

export async function getPerson(personId, params = {}) {
  const { data } = await get(`/people/${personId}`, params);

  return data;
}

export async function createPerson(params = {}) {
  const { data } = await post('/people', params);

  return data;
}

export async function updatePerson({ personId, params = {} }) {
  const { data } = await patch(`/people/${personId}`, params);

  return data;
}

export async function deletePerson({ personId }) {
  const { data } = await _delete(`/people/${personId}`);

  return data;
}

export function useInfinitePeople({ params = {}, config = {} } = {}) {
  return useInfiniteQuery(
    peopleKeys.infiniteList(params),
    ({ pageParam: page = 1 }) => getPeople({ ...params, page }),
    {
      ...config,
      getNextPageParam: (lastPage) => lastPage.meta.next
    }
  );
}

export function usePerson({ personId, params = {}, config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: peopleKeys.detail(personId),
    queryFn: () => getPerson(personId, params)
  });
}

export function useCreatePerson({ config = {} } = {}) {
  return useMutation(
    createPerson,
    { ...config }
  );
}

export function useUpdatePerson({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async ({ data }) => {
    const promises = [
      queryClient.invalidateQueries(peopleKeys.all),
      queryClient.invalidateQueries(peopleKeys.infiniteAll)
    ];

    if (data.organization) {
      promises.push(queryClient.invalidateQueries(organizationsKeys.detail(data.organization.id)));
    }

    await Promise.all(promises);
  };

  return useMutation(
    updatePerson,
    {
      onSuccess,
      ...config
    }
  );
}

export function useDeletePerson({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await Promise.all([
      queryClient.invalidateQueries(peopleKeys.infiniteAll),
      queryClient.invalidateQueries(personValidationKeys.all),
      queryClient.invalidateQueries(searchesKeys.infiniteAll),
      queryClient.invalidateQueries(backlogItemsKeys.infiniteAll)
    ]);
  };

  return useMutation(
    deletePerson,
    {
      onSuccess,
      ...config
    }
  );
}
