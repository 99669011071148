import React, { useState, useMemo, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useAlert } from 'react-alert';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import ProposalModelsModal from '@/components/Proposal/ProposalModelsModal';
import {
  useCreateProposalModel, useUpdateProposalModel,
  useProposalModel, proposalModelKeys
} from '@/api';
import { formatAddressLine, getScreenSizeLabel } from '@/utils';
import { useMediaQuery, useScreenSize } from '@/hooks';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import ProposalModal from '@/components/Proposal/ProposalModal';

DetailsProposal.defaultProps = {
  deal: null,
  account: null,
  entity: null,
  entityType: '',
  onCreateProposal: () => {},
  onCancel: () => {}
};

DetailsProposal.propTypes = {
  deal: PropTypes.object,
  account: PropTypes.object,
  entity: PropTypes.object,
  entityType: PropTypes.string,
  onCreateProposal: PropTypes.func,
  onCancel: PropTypes.func
};

const LIMITED_MODAL_SIZES = {
  sm: 'lg',
  md: 'xxl',
  lg: 'xxl',
  xl: 'xxxl',
  xxxl: 'xxxl'
};

const downloadFile = async (key, url) => {
  if (!url) {
    return { [key]: url };
  }
  const response = await fetch(url, { mode: 'cors' });
  const blob = await response.blob();
  return { [key]: URL.createObjectURL(blob) };
};

DetailsProposal.defaultProps = {
  deal: null,
  account: null,
  entity: null,
  entityType: '',
  onCreateProposal: () => { }
};

function DetailsProposal({ deal, account, entity, entityType, onCreateProposal }) {
  const queryClient = useQueryClient();
  const alert = useAlert();
  const { user } = useAuth();
  const tracker = useTracking();
  const [proposalModelId, setProposalModelId] = useState();
  const [layoutMode, setLayoutMode] = useState(false);
  const [isLoadingProposalImages, setIsLoadingProposalImages] = useState(false);
  const [showProposalModal, setShowProposalModal] = useState(false);
  const [showProposalModelModal, setShowProposalModelModal] = useState(false);
  const [proposalModel, setProposalModel] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const screenSize = useScreenSize();
  const modalSize = getScreenSizeLabel(screenSize);
  const isBelow1200 = useMediaQuery('(max-width: 1200px)');

  const defaultProposalModelValues = {
    title: 'PROPOSTA',
    proposalModelSections: [
      { title: 'Introdução e apresentação', sectionType: 'rich_text', content: '', position: 0 },
      { title: 'Produtos e serviços', sectionType: 'products', content: '', position: 1 },
      { title: 'Detalhes da proposta', sectionType: 'rich_text', content: '', position: 2 }
    ],
    banner: null,
    logo: null,
    legalName: account?.legalName,
    cpfCnpj: account?.cpfCnpj,
    address: formatAddressLine(account?.address)
  };

  const { data: proposalModelData, isFetching: isLoadingProposalModel } = useProposalModel({
    id: proposalModelId,
    config: { enabled: Boolean(showProposalModal && proposalModelId) }
  });

  const createProposalModelMutation = useCreateProposalModel();
  const updateProposalModelMutation = useUpdateProposalModel();

  const currentProposalModel = useMemo(() => {
    const result = proposalModelData?.data ?? defaultProposalModelValues;
    return result;
  }, [proposalModelData]);

  /*
   * A lib de preparar pdf sofre problemas com cors,
   * foi necessário forçar download para carregamento em blob
   */
  useEffect(() => {
    let isMounted = true;

    async function prepareProposal(fromProposalModel) {
      setIsLoadingProposalImages(true);
      try {
        let proposalModelSections = fromProposalModel.proposalModelSections;

        const downloads = await Promise.all([
          downloadFile('banner', fromProposalModel.banner?.url),
          downloadFile('logo', fromProposalModel.logo?.url)
        ]);

        const files = downloads?.reduce((acc, item) => ({ ...item, ...acc }), {}) ?? {};

        if (!proposalModelSections?.length) {
          proposalModelSections = defaultProposalModelValues.proposalModelSections;
        }

        const output = {
          ...fromProposalModel,
          banner: files?.banner && { ...fromProposalModel.banner, preview: files.banner },
          logo: files?.logo && { ...fromProposalModel.logo, preview: files.logo },
          proposalModelSections
        };

        if (isMounted) {
          setProposalModel(output);
          setIsLoadingProposalImages(false);
        }
      } catch (err) {
        if (isMounted) {
          console.error(err);
          setIsLoadingProposalImages(false);
        }
      }
    }

    if (showProposalModal && !isLoadingProposalModel) {
      if (currentProposalModel?.id) {
        prepareProposal(currentProposalModel);
      } else {
        prepareProposal(defaultProposalModelValues);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [proposalModelData, showProposalModal, isLoadingProposalModel]);

  const handleUpsertProposalModel = (data, done) => {
    setIsSubmitting(true);

    if (isEqual(data, proposalModel)) {
      return;
    }

    if (isEqual(data?.logo, proposalModel?.logo)) {
      delete data?.logo;
    }

    if (isEqual(data?.banner, proposalModel?.banner)) {
      delete data?.banner;
    }

    if (data?.id) {
      updateProposalModelMutation.mutate({ id: data.id, params: data }, {
        onSuccess: () => {
          alert.show(
            'Modelo atualizado com sucesso!',
            { variant: 'success', timeout: 5000 }
          );
          setIsSubmitting(false);
          done?.();
        },
        onError: (err) => {
          setIsSubmitting(false);
          done?.(err);
        }
      });
    } else {
      createProposalModelMutation.mutate(data, {
        onSuccess: () => {
          alert.show(
            'Modelo criado com sucesso!',
            { variant: 'success', timeout: 5000 }
          );
          setIsSubmitting(false);
          done?.();
        },
        onError: (err) => {
          setIsSubmitting(false);
          done?.(err);
        }
      });
    }
  };

  const handleHideProposal = (done) => {
    setLayoutMode(false);
    setShowProposalModelModal(true);
    setShowProposalModal(false);
    setProposalModelId(null);
    done?.();
  };

  const handleOpenProposal = (id) => {
    setLayoutMode(false);
    setShowProposalModal(true);
    setProposalModelId(id);
    setProposalModel(null);
  };

  const handleNewProposalModel = () => {
    setLayoutMode(true);
    setShowProposalModal(true);
    setProposalModelId(null);
    setProposalModel(null);
  };

  const handleEditProposalModel = (id) => {
    setLayoutMode(true);
    setShowProposalModal(true);
    setProposalModelId(id);
    setProposalModel(null);
  };

  const handleGenerateProposal = (activity, done) => {
    setLayoutMode(false);
    setShowProposalModelModal(false);
    setShowProposalModal(false);
    setProposalModelId(null);
    onCreateProposal(activity, done);
  };

  const handleOpenProposalModels = async () => {
    await queryClient.invalidateQueries(proposalModelKeys.all);
    setLayoutMode(false);
    setShowProposalModelModal(true);
    setShowProposalModal(false);
    setProposalModelId(null);
    if (tracker && tracker.trackActionButtonClicked) {
      tracker.trackActionButtonClicked({ user, action: 'Criar proposta' });
    }
  };

  const handleHideProposalModel = () => {
    setShowProposalModelModal(false);
  };

  return (
    <>
      <Button
        id='proposal-modal'
        className={
          `mt-2 d-flex align-items-center text-nowrap w-100
          ${isBelow1200 ? 'ms-0' : 'me-8'}`
        }
        onClick={handleOpenProposalModels}
        aria-label='Gerar proposta'
      >
        <Icon name='proposal' className='me-2' size='fixed' />
        <span>Gerar proposta</span>
      </Button>

      <ProposalModelsModal
        show={showProposalModelModal}
        modalSize={LIMITED_MODAL_SIZES[modalSize]}
        onRename={handleUpsertProposalModel}
        onOpenProposal={handleOpenProposal}
        onNewProposalModel={handleNewProposalModel}
        onEditProposalModel={handleEditProposalModel}
        onHide={handleHideProposalModel}
      >
        <ProposalModal
          key={proposalModelId || `proposal-model-${Math.random()}`}
          layoutMode={layoutMode}
          deal={deal}
          proposalModel={proposalModel}
          account={account}
          show={showProposalModal}
          loading={isLoadingProposalModel || isLoadingProposalImages || isSubmitting}
          entity={entity}
          entityType={entityType}
          modalSize={LIMITED_MODAL_SIZES[modalSize]}
          onHide={handleHideProposal}
          onUpsertProposalModel={handleUpsertProposalModel}
          onCreateProposal={handleGenerateProposal}
        />
      </ProposalModelsModal>
    </>
  );
}

export default DetailsProposal;
