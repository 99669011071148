import { useMutation, useQueryClient } from 'react-query';
import { post, _delete } from '@/lib/fetch';
import { dealsKeys } from '@/api';

export async function createDealFreezings(params = {}) {
  const { data } = await post('/deal_freezings', params);

  return data;
}

export async function deleteDealFreezing(params = {}) {
  const { data } = await _delete('/deal_freezings', params);

  return data;
}

export function useCreateDealFreezings({ config = {} } = {}) {
  const queryClient = useQueryClient();
  const onSuccess = async () => {
    await Promise.all([
      queryClient.invalidateQueries(dealsKeys.all),
      queryClient.invalidateQueries(dealsKeys.infiniteAll)
    ]);
  };
  return useMutation(
    createDealFreezings,
    { ...config, onSuccess }
  );
}

export function useDeleteDealFreezing({ config = {} } = {}) {
  const queryClient = useQueryClient();
  const onSuccess = async () => {
    await Promise.all([
      queryClient.invalidateQueries(dealsKeys.all),
      queryClient.invalidateQueries(dealsKeys.infiniteAll)
    ]);
  };
  return useMutation(
    deleteDealFreezing,
    { ...config, onSuccess }
  );
}
