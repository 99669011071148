import React from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import Feedback from 'react-bootstrap/Feedback';
import classnames from 'classnames';

const propTypes = {
  name: PropTypes.string.isRequired
};

function HookFormFeedback({ name, ...props }) {
  const { fieldState: { error } } = useController({ name });

  return (
    <Feedback
      type='invalid'
      className={classnames({ 'd-block': Boolean(error) })}
      {...props}
    >
      {error?.message}
    </Feedback>
  );
}

HookFormFeedback.propTypes = propTypes;
HookFormFeedback.displayName = 'HookFormFeedback';

export default HookFormFeedback;
