import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '@/components/Icon';
import { toCurrencyString } from '@/number';

const propTypes = {
  serviceCharge: PropTypes.number,
  rechargeValue: PropTypes.number,
  finalValue: PropTypes.number
};

const defaultProps = {
  serviceCharge: 0,
  rechargeValue: 0,
  finalValue: 0
};

function PaymentSummary({ serviceCharge, rechargeValue, finalValue }) {
  return (
    <div className='w-100 text-small bg-light p-6 rounded'>
      <h4>Resumo</h4>
      <div className={classnames(
        'd-flex',
        'align-items-center',
        'mb-2',
        'mt-1',
        'py-2',
        'ps-2',
        'pe-8',
        'bg-white',
        'rounded',
        'text-dark-gray'
      )}>
        <div>
          <Icon name='info' />
        </div>
        <div className='ms-3'>
          A nota fiscal será gerada e enviada para o seu e-mail
          automaticamente em até 24 horas após a confirmação do pagamento
        </div>
      </div>

      <div className='mt-4 d-flex flex-column align-items-baseline justify-content-between mb-1'>
        <div className='d-flex align-items-baseline justify-content-between mb-1 w-100'>
          <div className='fw-bold text-dark-gray me-8'>Sua recarga</div>
          <div className='ms-2'>{toCurrencyString(rechargeValue)}</div>
        </div>
        {
          serviceCharge
            ? (
              <div className='d-flex align-items-baseline justify-content-between mb-1 w-100'>
                <div className='fw-bold text-dark-gray me-8'>Taxa de serviço</div>
                <div className='ms-2'>{toCurrencyString(serviceCharge)}</div>
              </div>

            )
            : null
        }

        <hr className='mt-3 mb-4 opacity-100 w-100' />
        <div className='mt-1 d-flex align-items-baseline justify-content-between mb-1 w-100'>
          <h4 className='text-dark-gray'>Valor total</h4>
          <h5>{toCurrencyString(finalValue)}</h5>
        </div>
      </div>
    </div>
  );
}

PaymentSummary.propTypes = propTypes;
PaymentSummary.defaultProps = defaultProps;

export default PaymentSummary;
