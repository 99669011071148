import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import PresentationCard from '@/components/LeadsGeneration/Presentation/PresentationCard';
import decisionMakersImage from 'images/leads_generation/decision-makers-info.svg';
import organizationContactsImage from 'images/leads_generation/organization-contacts.svg';
import organizationInfoImage from 'images/leads_generation/organization-info.svg';
import { useTracking } from '@/lib/tracking';
import { useAuth } from '@/lib/auth';

const propTypes = {
  setShowPresentationStep: PropTypes.func.isRequired
};

const VIDEO_SRC = 'https://www.youtube.com/embed/ijTFW5EIQjQ?si=UJnG31cQXWmYqHFr';
const VIDEO_TITLE = 'Geração de leads';

const PRESENTATION_CARDS = [
  {
    image: organizationContactsImage,
    title: 'Telefone + e-mail dos contatos das empresas',
    subtitle: '(sem dados de contadores)'
  },
  {
    image: decisionMakersImage,
    title: 'Informações sobre os contatos dos decisores'
  },
  {
    image: organizationInfoImage,
    title: 'CNPJ, Razão Social, endereço e outros dados da Receita Federal'
  }
];

function ResumeContent({ setShowPresentationStep }) {
  const tracker = useTracking();
  const { user } = useAuth();

  useEffect(() => {
    tracker.trackLeadsGenerationPresented({ user });
  }, []);

  return (
    <div className='d-flex flex-column align-items-center' id='resume-content'>
      <div className='d-flex'>
        <h1>
          <span className='border-bottom border-flat-green border-4'>
            Aumente suas vendas em até 35%
          </span>
          {' '}com uma lista de
        </h1>
      </div>

      <h1>clientes qualificada</h1>

      <iframe
        className='video-size my-5 rounded'
        src={VIDEO_SRC}
        title={VIDEO_TITLE}
        allowFullScreen
      />

      <div className='d-flex flex-column align-items-center'>
        <span className='mb-1 text-dark-gray fw-bold'>
          O que vem incluso na sua lista de leads?
        </span>
        <div className='mb-4 width-4 border-bottom border-flat-green border-4' />
      </div>

      <div className='d-flex justify-content-center px-9 mb-8 box-shadow-lg gap-5'>
        {
          PRESENTATION_CARDS.map(({ image, title, subtitle }) => (
            <PresentationCard
              key={title}
              image={image}
              title={title}
              subtitle={subtitle}
            />
          ))
        }
      </div>

      <Button
        size='md'
        onClick={() => setShowPresentationStep('howItWorksContent')}
        className='px-9 py-3'
      >
        <h4 className='text-normal'>Saiba mais</h4>
        <Icon name='double-arrow-right' size='sm' className='ms-2' />
      </Button>
    </div>
  );
}

ResumeContent.propTypes = propTypes;

export default ResumeContent;
