import React from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';

import Modal from '@/components/Modal';
import Button from '@/components/Button';
import emailFree from 'images/email/email_free.svg';
import postmark from 'images/email/postmark.gif';
import { useCreateEmailSender, useCurrentUser } from '@/api';
import LoadingButton from '@/components/LoadingButton';

const propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  isList: PropTypes.bool
};

const defaultProps = {
  show: false,
  onHide: () => {},
  isList: false
};

function EmailVerifyModal({ show, onHide, isList }) {
  const { data: currentUser } = useCurrentUser();

  const alert = useAlert();
  const { mutate: resend, isLoading: isResending } = useCreateEmailSender({
    config: {
      onSuccess: () => {
        alert.show(
          'E-mail reenviado! Verifique sua caixa de entrada.',
          { variant: 'success', timeout: 5000 }
        );
      }
    }
  });

  const modealHeaderText = isList
    ? `Agora falta pouco, peça para seus usuários verificarem a caixa de entrada
      e confirmarem seus e-mails (e-mail usado para login no Agendor)`
    : `Verifique a caixa de entrada de ${currentUser?.data.email}`;

  return (
    <div>
      <Modal
        size='xxl'
        show={show}
        onHide={onHide}
        bodyClassName='d-flex flex-column p-8 align-items-center text-center'
        backdrop='static'
      >
        <div>
          <img src={emailFree} />

          <h2 className='mb-4'>
            {modealHeaderText}
          </h2>

          {
            !isList && (
              <div className='mb-3 text-dark-gray'>
                Este é um exemplo do e-mail de verificação do Postmark (support@postmarkapp.com),
                realize a confirmação através dele.
              </div>
            )
          }

          <img className='mb-1' src={postmark} />

          {
            isList && (
              <div>
                Eles vão receber uma mensagem do Postmark, nosso provedor de serviço de e-mail.
                O e-mail é assinado por &quot;The Postmark Team&quot;.
                Eles devem clicar no botão Confirm Sender Signature
                para confirmar a autenticidade do endereço.
              </div>
            )
          }

          <div className='mt-6'>
            {
              !isList && (
                <LoadingButton
                  className='me-3'
                  variant='outline-primary'
                  onClick={resend}
                  isLoading={isResending}
                  loadingText='Reenviando...'
                >
                  Reenviar e-mail
                </LoadingButton>
              )
            }
            <Button onClick={onHide}>
              {
                isList ? 'Entendi' : 'Confirmei o e-mail'
              }
            </Button>
          </div>
        </div>

      </Modal>
    </div>
  );
}

EmailVerifyModal.propTypes = propTypes;
EmailVerifyModal.defaultProps = defaultProps;

export default EmailVerifyModal;
