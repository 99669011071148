import React from 'react';
import PropTypes from 'prop-types';
import {
  Header, Illustration, Title, Subtitle
} from '@/components/CreateDealModal/Header';
import { getString } from '@/utils';

const propTypes = {
  /** Entidade 'origem' do negócio que será criado. */
  entity: PropTypes.shape({
    type: PropTypes.oneOf(['organization', 'person', 'deal']).isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  /** Funil onde o negócio será criado. */
  funnel: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired
};

const defaultProps = {};

function DealStepHeader(props) {
  const { entity } = props;

  if (isDuplication(entity)) {
    return <DuplicateDealHeader { ...props } />;
  } else {
    return <AddDealHeader { ...props } />;
  }
}

function DuplicateDealHeader(props) {
  const { entity, funnel } = props;

  return (
    <Header>
      <Illustration addon='duplication' />

      <Title>
        Este negócio será duplicado
      </Title>

      <Subtitle>
        Você está criando uma cópia de {entity.name} em {funnel.name}.
      </Subtitle>
    </Header>
  );
}

function AddDealHeader(props) {
  const { entity, funnel } = props;

  const displayType = getString(['models', entity.type]);

  return (
    <Header>
      <Illustration addon='plus' />

      <Title>
        Adicionar novo negócio
      </Title>

      <Subtitle>
        Você está criando um negócio para
        a {displayType} {entity.name} em {funnel.name}.
      </Subtitle>
    </Header>
  );
}

function isDuplication({ type }) {
  return type === 'deal';
}

DealStepHeader.propTypes = propTypes;
DealStepHeader.defaultProps = defaultProps;

export default DealStepHeader;
