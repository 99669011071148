import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import Popover from '@/components/Popover';
import ExternalLink from '@/components/ExternalLink';

const propTypes = {
  entityUrl: PropTypes.string.isRequired,
  emptyListMessage: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string
    })
  )
};

const defaultProps = {
  products: []
};

function ProductsPopover({ entityUrl, emptyListMessage, products }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <Popover
      flip
      hide={!showModal}
      className='max-width-6'
      placement='bottom-start'
      rootCloseEvent='mousedown'
      content={({ onHide }) => (
        <div className='mx-2'>
          <h4 className='mb-3'>Produtos/Serviços</h4>

          <div className='min-width-5 max-height-5 overflow-y-auto'>
            { products.length
              ? renderProductsList(products)
              : renderEmptyList(emptyListMessage)
            }
          </div>

          <Button
            as={ExternalLink}
            variant='primary'
            className='mt-4'
            href={entityUrl}
            onClick={onHide}
          >
            <Icon name='plus' size='sm' className='me-1' />

            Adicionar produtos
          </Button>
        </div>
      )}
    >
      <Button
        variant='link'
        className='fw-bold p-0 border-0'
        onClick={() => setShowModal(true)}
      >
        <Icon name='product' size='sm' className='me-1' />

        Ver produtos
      </Button>
    </Popover>
  );
}

function renderProductsList(products) {
  return (
    <div className='d-flex flex-wrap max-height-4'>
      {products.map((product) => (
        <div
          key={product.id}
          className={classnames(
            'bg-darker-gray',
            'text-white',
            'fw-bold',
            'lh-1',
            'rounded-1',
            'px-2',
            'py-1',
            'me-1',
            'mb-1',
            'text-small'
          )}
        >
          {product.name}
        </div>
      ))}
    </div>
  );
}

function renderEmptyList(emptyListMessage) {
  return <div className='text-muted'>{emptyListMessage}</div>;
}

ProductsPopover.propTypes = propTypes;
ProductsPopover.defaultProps = defaultProps;

export default ProductsPopover;
