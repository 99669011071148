import React, { useState } from 'react';
import Modal from '@/components/Modal';
import StepperHeader from '@/components/StepperHeader';
import HowWorksStep from '@/components/Voip/CustomNumber/HowWorksStep';
import PaymentInfoStep from '@/components/Voip/CustomNumber/PaymentInfoStep';
import SuccessStep from '@/components/Voip/CustomNumber/SuccessStep';
import SelectDddStep from '@/components/Voip/CustomNumber/SelectDddStep';
import { useStoredState } from '@/hooks';
import { compareDates } from '@/date';
import ErrorStep from '@/components/Voip/CustomNumber/ErrorStep';

const HEADER_LABELS = ['Como funciona', 'Info. de pagamento', 'Selecionar DDD'];

const MIN_DAYS = 5;

function PanelModal({ show, onHide, data }) {
  const { value: submitAt, store: setSubmitAt } = useStoredState(
    'custom-number-voip',
    null
  );
  const { value: emails, store: setEmails } = useStoredState(
    'custom-number-voip-emails',
    []
  );
  const [step, setStep] = useState(0);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const LAST_LABEL_INDEX = HEADER_LABELS.length - 1;
  const nextStep = () => {
    setStep(Math.min(step + 1, LAST_LABEL_INDEX));
  };
  const onCloseModal = () => {
    setStep(0);
    setSuccess(false);
    onHide();
  };
  const value = {
    step,
    nextStep
  };
  const StepComponents = [
    <HowWorksStep key={1} nextStep={nextStep} onHide={onCloseModal} />,
    <PaymentInfoStep key={2} nextStep={nextStep} onHide={onCloseModal} />,
    <SelectDddStep
      key={3}
      onHide={onCloseModal}
      nextStep={nextStep}
      zenviaId={data.zenvia_id}
      onSuccess={(successData) => {
        setSubmitAt(new Date());
        setSuccess(true);
        setEmails(
          [
            successData.email_billing,
            successData.email_responsible,
            successData.email_user
          ].filter(Boolean)
        );
      }}
      onError={() => {
        setError(true);
      }}
    />
  ];

  const isSuccess =
    success || compareDates(new Date(), new Date(submitAt)) < MIN_DAYS;

  const renderContent = () => {
    if (success || compareDates(new Date(), new Date(submitAt)) < MIN_DAYS) {
      return (
        <SuccessStep onHide={onHide} emails={emails} submitAt={submitAt} />
      );
    }
    if (error) {
      return <ErrorStep />;
    } else {
      return StepComponents[step];
    }
  };

  return (
    <Modal
      value={value}
      show={show}
      onHide={onCloseModal}
      size='xl'
      bodyClassName='p-8'
    >
      {!isSuccess && !error && (
        <div className='bg-dark-light p-4'>
          <StepperHeader
            headerClassName='d-flex justify-content-center align-items-center fw-bold'
            currentStep={step}
            headerLabels={HEADER_LABELS}
          />
        </div>
      )}
      {renderContent()}
    </Modal>
  );
}

export default PanelModal;
