import React, { useState } from 'react';
import classnames from 'classnames';
import { useAlert } from 'react-alert';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Main from '@/components/Main';
import InfiniteScroll from '@/components/InfiniteScroll';
import Table from '@/components/Table';
import TableCell from '@/components/TableCell';
import TableIndex from '@/components/TableIndex';
import PaginationCounter from '@/components/List/PaginationCounter';
import DragScrollable from '@/components/DragScrollable';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import LoadSpinner from '@/components/LoadSpinner';
import EntitySkeleton from '@/components/List/EntitySkeleton';
import Authorization from '@/components/Authorization';
import ConfirmationModal from '@/components/ConfirmationModal';
import EmailTemplateModal from '@/components/Email/EmailTemplateModal';
import APIErrorMessage from '@/components/APIErrorMessage';
import { TextInput } from '@/components/Inputs';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import InputAdornment from '@/components/InputAdornment';
import {
  canCreateEmailTemplate, canDeleteEmailTemplate, canEditEmailTemplate
} from '@/policies';
import { useDocumentTitle } from '@/hooks';
import {
  useInfiniteEmailTemplates, useCreateEmailTemplate,
  useDeleteEmailTemplate, useUpdateEmailTemplate
} from '@/api';
import { getValidationErrors } from '@/utils';

const PAGE_TITLE = 'Modelos de e-mail';
const PAGE_SUBTITLE = 'Cadastre seus modelos de email';

const defaultValues = { q: '' };

function SettingsEmailTemplates() {
  const [formParams, setFormParams] = useState({});
  const [showEmailTemplateModal, setShowEmailTemplateModal] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState(null);
  const [isCreating, setIsCreating] = useState(true);
  const [emailTemplate, setEmailTemplate] = useState();
  const createMutation = useCreateEmailTemplate();
  const updateMutation = useUpdateEmailTemplate();
  const deleteMutation = useDeleteEmailTemplate();
  const alert = useAlert();

  useDocumentTitle(`${PAGE_TITLE} - Configurações`);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading
  } = useInfiniteEmailTemplates({
    params: formParams
  });

  const items = data?.pages.flatMap((page) => page.data);
  const totalItems = data?.pages?.[0]?.meta?.count ?? 0;

  const openModal = (mode, item) => {
    setShowEmailTemplateModal(true);

    if (mode === 'create') {
      setIsCreating(true);
    }

    if (mode === 'update') {
      setIsCreating(false);
      setEmailTemplate(item);
    }
  };

  const closeModal = () => {
    setShowEmailTemplateModal(false);
    setEmailTemplate();
  };

  const handleCancel = () => closeModal();

  const handleCreate = (formData, { setFieldError, setSubmitting }) => {
    createMutation.mutate(formData, {
      onSuccess: () => {
        closeModal();

        alert.show(
          'Modelo de email adicionado com sucesso',
          { variant: 'success', timeout: 5000 }
        );
      },
      onError: (err) => {
        const errors = getValidationErrors(err, 'emailTemplate');

        Object.entries(errors).forEach(([key, message]) => {
          setFieldError(key, message);
        });

        setSubmitting(false);

        alert.show(
          <APIErrorMessage err={err} resource='emailTemplate' action='create' />,
          { variant: 'danger' }
        );
      }
    });
  };

  const handleUpdate = (formData, { setFieldError, setSubmitting }) => {
    updateMutation.mutate({ emailTemplateId: emailTemplate.id, params: formData }, {
      onSuccess: () => {
        closeModal();

        alert.show(
          'Modelo de email editado com sucesso',
          { variant: 'success', timeout: 5000 }
        );
      },
      onError: (err) => {
        const errors = getValidationErrors(err, 'emailTemplate');

        Object.entries(errors).forEach(([key, message]) => {
          setFieldError(key, message);
        });

        setSubmitting(false);

        alert.show(
          <APIErrorMessage err={err} resource='emailTemplate' action='update' />,
          { variant: 'danger' }
        );
      }
    });
  };

  const onCloseDelete = () => setToBeDeleted(null);
  const handleDelete = () => {
    deleteMutation.mutate({ id: toBeDeleted.id }, {
      onSuccess: () => {
        alert.show(
          'Modelo de email excluído com sucesso',
          { variant: 'success', timeout: 5000 }
        );
        onCloseDelete();
      },
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='emailTemplate' action='delete' />,
          { variant: 'danger' }
        );
        onCloseDelete();
      }
    });
  };

  const handleSubmit = (formData) => {
    setFormParams(formData);
  };

  return (
    <Main
      fluid
      flex
      overflow={false}
      className='pt-8 px-4 overflow-hidden position-relative'
    >
      <h1>{PAGE_TITLE}</h1>
      <p className='subtitle text-dark-gray max-width-7'>
        {PAGE_SUBTITLE}
      </p>
      <Row className='mb-4 d-flex justify-content-between align-items-baseline'>
        <Col sm={12} md={6}>
          <Form
            defaultValues={defaultValues}
            onSubmit={handleSubmit}
            name='emailTemplate-search-form'
          >
            {({ debouncedSubmitForm }) => (
              <FormField
                as={TextInput}
                name='q'
                maxLength={60}
                isClearable
                onChange={debouncedSubmitForm}
                placeholder='Buscar por nome'
                leftAdornment={({ focus }) => (
                  <InputAdornment alignment='left' onClick={focus}>
                    <Icon className='text-primary' name='search' />
                  </InputAdornment>
                )}
              />
            )}
          </Form>
        </Col>
        <Col sm={12} md={6} className='d-md-flex justify-content-end'>
          <Authorization policy={canCreateEmailTemplate}>
            <Button
              className='me-4'
              onClick={() => openModal('create')}
            >
              <Icon name='add' className='me-2'></Icon>

              <span>Adicionar</span>
            </Button>
          </Authorization>
        </Col>
      </Row>

      <InfiniteScroll
        length={items?.length || 0}
        loadItems={fetchNextPage}
        hasMore={hasNextPage || false}
      >
        <DragScrollable>
          <Table className='table-border-separate'>
            <thead className='position-sticky top-0 bg-white z-index-1'>
              <tr className='text-nowrap'>
                <th className={classnames(
                  'width-6',
                  'min-width-6',
                  'border-end',
                  'border-1',
                  'position-sticky',
                  'start-0',
                  'bg-white'
                )}>
                  <span className='px-3 invisible'>
                    <TableIndex index={2} />
                  </span>
                  <span className='px-3'>
                    Nome
                  </span>
                </th>
                <th>Assunto</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                isLoading
                  ? <EntitySkeleton maxCols={4} iconless />
                  : (
                    items?.map((item, index) => (
                      <tr key={index}>
                        <td className={classnames(
                          'align-middle border-end border-1',
                          'position-sticky start-0 bg-white'
                        )}>
                          <span className='px-3'>
                            <TableIndex index={index + 1} />
                          </span>
                          <span className='px-3'>
                            {item.name}
                          </span>
                        </td>
                        <td className='align-middle'>
                          <TableCell value={item.subject} />
                        </td>
                        <td className='d-flex justify-content-end align-self-end'>
                          <Authorization policy={canEditEmailTemplate}>
                            <Button
                              onClick={() => openModal('update', item)}
                              variant='link'
                              size='sm'
                              className='text-nowrap'
                            >
                              <Icon className='me-1' name='edit-field' />
                              <span className='fw-bold'>
                                Editar
                              </span>
                            </Button>
                          </Authorization>
                          <Authorization policy={canDeleteEmailTemplate}>
                            <Button
                              variant='link'
                              size='sm'
                              className='text-nowrap'
                              onClick={() => setToBeDeleted(item)}
                            >
                              <Icon className='me-1 text-danger' name='delete' />
                              <span className='text-dark-gray fw-bold'>
                                Excluir
                              </span>
                            </Button>
                          </Authorization>
                        </td>
                      </tr>
                    ))
                  )
              }
            </tbody>
          </Table>
        </DragScrollable>
      </InfiniteScroll>
      {
        isFetchingNextPage
          ? (
            <LoadSpinner
              size='lg'
              className={classnames(
                'd-flex text-dark-gray align-items-center',
                'justify-content-center opacity-100 py-3',
                'overflow-hidden'
              )}
            />
          )
          : null
      }

      <div className='py-2'>
        <PaginationCounter
          show={!isLoading}
          model='emailTemplate'
          count={items?.length}
          max={totalItems}
        />
      </div>

      <EmailTemplateModal
        onCancel={handleCancel}
        onSubmit={isCreating ? handleCreate : handleUpdate}
        emailTemplate={emailTemplate}
        show={showEmailTemplateModal}
        isCreating={isCreating}
      />

      <ConfirmationModal
        show={Boolean(toBeDeleted)}
        onHide={onCloseDelete}
        confirmationText='Sim, excluir'
        onConfirm={handleDelete}
        disabled={deleteMutation.isLoading}
      >
        <ConfirmationModal.Title>
          Você realmente deseja excluir este modelo de email?
        </ConfirmationModal.Title>

        <ConfirmationModal.Info>
          Após confirmação, o
          Modelo de email <span className='fw-bold'>{toBeDeleted?.name}</span> será
          excluído.
        </ConfirmationModal.Info>
      </ConfirmationModal>
    </Main>
  );
}

export default SettingsEmailTemplates;
