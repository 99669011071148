import React from 'react';
import Button from '@/components/Button';
import relax from 'images/billing/relax.svg';
import { Card } from 'react-bootstrap';
import { compareDates } from '@/date';

const MIN_DAYS = 5;

function SuccessStep({ onHide, emails = [], submitAt }) {
  const diffDays = MIN_DAYS - compareDates(new Date(), new Date(submitAt));

  return (
    <div className='text-center'>
      <div>
        <img className='width-5 height-5' src={relax} />
      </div>
      <div className='h3 fw-bold mt-n5'>
        Já enviamos os seus dados para o nosso parceiro.
      </div>
      <div className='text-dark-gray mt-3 mb-2'>
        Agora é só aguardar, eles entrarão em contato com você <br />
        <span className='fw-bold'> em até 5 dias úteis </span> pelos seguintes
        e-mails:
      </div>
      {emails.map((email, index) => (
        <div key={index} className='d-flex justify-content-center'>
          <Card className='bg-light text-dark-gray fw-bold min-width-5 mb-2 border-light p-1 mt-1'>
            {email}
          </Card>
        </div>
      ))}
      <div className='text-medium-gray mt-2'>
        A opção de contratar um novo número estará disponível após {diffDays}{' '}
        dia(s).
      </div>
      <Button variant='primary' className='mt-4' onClick={onHide}>
        Ok, fechar
      </Button>
    </div>
  );
}

export default SuccessStep;
