import React from 'react';
import PropTypes from 'prop-types';
import { getString, pluralize } from '@/utils';
import { convertDateTimeString } from '@/date';
import { useImports } from '@/api';
import FetchableSelect from '@/components/Inputs/FetchableSelect';

const propTypes = {
  entity: PropTypes.oneOf(['deals', 'organizations', 'people']).isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  allowNull: PropTypes.bool,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func
};

const defaultProps = {
  name: 'import_id_in',
  label: 'Importações',
  placeholder: 'Qualquer importação',
  multiple: false,
  isClearable: false,
  isSearchable: false,
  allowNull: false,
  defaultValue: null,
  onChange: () => {}
};

function ImportSelect({ entity, ...props }) {
  function transformer(fetchedData) {
    const result = [];
    if (props.allowNull) {
      result.push({ label: 'Indefinido', value: -1 });
    }

    const options = fetchedData.data.map((item) => {
      const entityName = getString(['attributes', 'imports', 'entity', item.entity]);
      const entityNamePluralized = pluralize(entityName, item.recordsCount);

      const importDate = convertDateTimeString(item.importedAt);

      return {
        label: `${importDate} (${item.recordsCount} ${entityNamePluralized})`,
        value: item.id
      };
    });

    return result.concat(options);
  }

  return (
    <FetchableSelect
      { ...props }
      transformer={transformer}
      query={useImports}
      queryArgs={{ params: { entity, all: true } }}
    />
  );
}

ImportSelect.propTypes = propTypes;
ImportSelect.defaultProps = defaultProps;

export default ImportSelect;
