import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { EMAIL_STATUSES } from '@/utils';
import { parseDate, toDateTimeString } from '@/date';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import Popover from '@/components/Popover';
import LoadingButton from '@/components/LoadingButton';
import FileList from '@/components/FileList';
import EmailBodyContent from '@/components/Email/EmailBodyContent';
import EmailActionButtons from '@/components/Email/EmailActionButtons';
import EmailDetailPopover from '@/components/Email/EmailDetailPopover';

function EmailActivity({ deal, activity, onDelete }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  if (!activity.email) {
    return null;
  }

  const email = activity.email;
  const createdAtDate = parseDate(activity.createdAt);
  const hasAttachments = activity.images.length || activity.documents.length;

  const handleDelete = (done) => {
    setIsSubmitting(true);
    onDelete(activity.id, () => {
      setIsSubmitting(false);
      done?.();
    });
  };

  const isEmailIn = email.status === EMAIL_STATUSES.RECEIVED;
  const emailIcon = isEmailIn ? 'email-in' : 'email-out';

  return (
    <>
      <Row>
        <Col sm={1}>
          <div style={{ width: 40, height: 40 }} className='rounded bg-light'>
            <div style={{ position: 'relative', left: 8, top: 8 }}>
              <Icon className='align-self-center' name={emailIcon} size='lg' />
            </div>
          </div>
        </Col>
        <Col sm={11}>
          <Row>
            <Col sm={6}>
              <span className='fw-bold'>{email.subject}</span>
            </Col>
            <Col sm={12} className='d-flex justify-content-end mt-n5'>
              <span className='text-dark-gray'>
                {toDateTimeString(createdAtDate)}
              </span>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <span className='text-dark-gray mt-2'>para: {email.to?.join(', ')}</span>
              <EmailDetailPopover email={email} />
            </Col>
            <Col sm={6} className='d-flex justify-content-end align-items-end mt-n3'>
              <EmailActionButtons small deal={deal?.id} email={email} />
              <Popover
                rootCloseEvent='mousedown'
                placement='top'
                showArrow
                contentClassName='pt-4 pb-4 px-3'
                content={({ onHide }) => (
                  <>
                    <h4 className='ms-3 me-3 mb-3 text-darker-gray fw-bold'>
                      Deseja realmente excluir esta <br /> atividade?
                    </h4>
                    <Button
                      className='ms-3 me-3'
                      variant='light'
                      onClick={onHide}
                    >
                      Agora não
                    </Button>
                    <LoadingButton
                      className='me-3'
                      isLoading={isSubmitting}
                      variant='danger'
                      type='submit'
                      onClick={() => {
                        handleDelete(() => {
                          onHide();
                        });
                      }}
                      loadingText='Excluindo...'
                    >
                      Sim, excluir
                    </LoadingButton>
                  </>
                )}
              >
                <Button
                  className='px-1 text-dark-gray p-0 mb-1'
                  variant='link'
                >
                  <Icon name='delete' size='sm'/>
                </Button>
              </Popover>
            </Col>
            <Row>
              <Col sm={12}>
                <EmailBodyContent email={email}/>
              </Col>
            </Row>
            {
              hasAttachments
                ? (
                  <Row className='mb-2'>
                    <Col sm={12}>
                      <FileList
                        documents={activity.documents}
                        images={activity.images}
                      />
                    </Col>
                  </Row>
                )
                : null
            }
          </Row>
        </Col>
      </Row>
      <hr className='m-0'/>
    </>
  );
}

export default EmailActivity;
