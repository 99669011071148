import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Offcanvas from '@/components/Offcanvas';
import Button from '@/components/Button';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import { FieldSelect, TextInput } from '@/components/Inputs';
import Alert from '@/components/Alert';
import LoadingButton from '@/components/LoadingButton';
import { goBack } from '@/browser';
import { useRequiredFields } from '@/api';

const propTypes = {
  validationSchema: PropTypes.object.isRequired,
  initialValues: PropTypes.shape({
    field: PropTypes.object,
    question: PropTypes.string
  }),
  onSubmit: PropTypes.func.isRequired,
  target: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  disabledField: PropTypes.bool
};

const defaultProps = {
  initialValues: {},
  disabledField: false
};

const defaultValues = {
  field: null,
  question: '',
  duplication: null
};

function RequiredFieldForm(props) {
  const {
    validationSchema,
    initialValues,
    target,
    disabledField
  } = props;

  const strings = target.type === 'stage'
    ? {
      of: 'da etapa',
      in: 'nesta etapa',
      action: 'mover o negócio para a próxima etapa'
    }
    : {
      of: 'do status',
      in: 'neste status',
      action: `marcar o negócio como ${target.name.toLowerCase()}`
    };

  const [duplication, setDuplication] = useState(null);
  const { funnelId } = useParams();
  const { data: { data: requiredFields = [] } = {} } = useRequiredFields({
    params: { funnel_id_eq: funnelId }
  });
  const onChangeField = (newField) => {
    if (target.type !== 'stage') {
      return;
    }

    if (!newField) {
      setDuplication(null);
      return;
    }

    const existingRequiredField = requiredFields.find(
      (item) => (
        item.stage &&
          item.stage?.id !== target.id &&
          isSameField({ value: newField, item })
      )
    );
    setDuplication(existingRequiredField);
  };

  const onSubmit = (formData, formMethods) => (
    props.onSubmit?.({ ...formData, duplication }, formMethods)
  );

  return (
    <>
      <Offcanvas.Header
        closeButton
        closeLabel='Fechar'
        className='flex-column-reverse align-items-end'
      >
        <Offcanvas.Title className='w-100'>
          <h2>
            Configurar campos {strings.of} {strings.model} {' '}
            &quot;<span className='text-primary'>{target.name}</span>&quot;
          </h2>
        </Offcanvas.Title>
      </Offcanvas.Header>

      <Offcanvas.Body className='mt-0 pt-0'>
        <Form
          defaultValues={defaultValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          initialValues={initialValues}
        >
          {({ isSubmitting }) => (
            <>
              <h5 className='mb-4'>
                Escolha um campo de negócio, empresa ou pessoa que será
                obrigatório {strings.in} {strings.model}.
              </h5>

              <p>
                O usuário não poderá {strings.action} até que o campo esteja
                preenchido.
              </p>

              <div className='mb-8'>
                <FormField
                  as={FieldSelect}
                  name='field'
                  className='w-50 mb-4'
                  disabled={disabledField}
                  onChange={onChangeField}
                />

                {
                  duplication && (
                    <Alert variant='warning'>
                      Esse campo já foi configurado como obrigatório na etapa
                      &quot;<span className='fw-bold'>{duplication.stage.name}</span>&quot;.
                      Ao salvar, ele será atualizado para a etapa
                      &quot;<span className='fw-bold'>{target.name}</span>&quot;.
                    </Alert>
                  )
                }
              </div>

              <FormField
                as={TextInput}
                name='question'
                label='Pergunta para qualificação desse campo'
                placeholder='Ex: Qual o prazo final desta negociação?'
                autoComplete='off'
                hint='Opcional'
              />

              <div className='border-top border-1 border-light pt-8 text-end'>
                <Button
                  variant='outline-dark-gray'
                  onClick={goBack}
                  className='mt-8 me-4'
                >
                  Cancelar
                </Button>

                <LoadingButton
                  className='mt-8 px-6'
                  type='submit'
                  isLoading={isSubmitting}
                >
                  Salvar
                </LoadingButton>
              </div>
            </>
          )}
        </Form>
      </Offcanvas.Body>
    </>
  );
}

function isSameField({ value, item }) {
  const [entity, id] = value.split('.');

  if (item.entity !== entity) {
    return false;
  }

  if (item.customField) {
    return item.customField.id === id;
  }

  return item.defaultField === id;
}

RequiredFieldForm.propTypes = propTypes;
RequiredFieldForm.defaultProps = defaultProps;

export default RequiredFieldForm;
