import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { getPhonesMasked } from '@/utils';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import LoadingButton from '@/components/LoadingButton';
import Truncate from '@/components/Truncate';
import Tooltip from '@/components/Tooltip';
import Card from '@/components/Card';
import DetailsContactForm from '@/components/EntityModal/Deal/DetailsContactForm';

DetailsVoipContactItem.defaultProps = {
  deal: {},
  entity: {},
  entityType: '',
  disabled: false,
  onClick: () => { },
  onUpdateEntity: () => { },
  showForm: false,
  setShowForm: () => { },
  isLastItem: false
};

DetailsVoipContactItem.propTypes = {
  deal: PropTypes.object,
  entity: PropTypes.object,
  entityType: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onUpdateEntity: PropTypes.func,
  showForm: PropTypes.bool,
  setShowForm: PropTypes.func,
  isLastItem: PropTypes.bool
};

function DetailsVoipContactItem({
  deal,
  entity,
  entityType,
  disabled,
  onClick,
  onUpdateEntity,
  showForm,
  setShowForm,
  isLastItem
}) {
  const [loadingKey, setLoadingKey] = useState(false);
  const { contact } = entity;
  const contactPhones = getPhonesMasked(contact);
  // Array vazio para manter layout consistente em contatos sem telefone
  const phones = contactPhones?.length ? contactPhones : [''];
  return (
    <>
      {
        !showForm
          ? (
            <Card className='p-3 border-0 bg-lighter-gray'>
              <div key={entity.id} className='d-flex'>
                <div className='d-flex bg-light-gray rounded p-2 align-self-baseline'>
                  <Icon name={entityType} />
                </div>
                <div className='ms-2 flex-fill'>
                  <Truncate as='div' className='width-5 text-darker-gray fw-bold mt-2 mb-1'>
                    {entity.name}
                  </Truncate>
                  {phones?.map((phone, index) => (
                    <Row key={`${index}_${phone}`}>
                      {index ? <hr className='my-2 text-light-gray opacity-50' /> : null}
                      <Col sm={8} className='mt-1 d-flex text-dark-gray'>
                        {phone}
                      </Col>
                      {
                        phone
                          ? (
                            <Col sm={4} className='d-flex justify-content-end'>
                              <Tooltip placement='top' content='Ligar'>
                                <span>
                                  <LoadingButton
                                    onClick={() => {
                                      setLoadingKey(`${index}_${phone}`);
                                      onClick({
                                        entity_id: deal.id,
                                        entity: 'deal',
                                        voip_to: phone?.replace?.(/[\D]+/gu, '')
                                      }, () => {
                                        setLoadingKey();
                                      });
                                    }}
                                    isLoading={loadingKey === `${index}_${phone}`}
                                    loadingText='Iniciando'
                                    disabled={disabled}
                                    className='align-self-baseline ms-auto border-0'
                                  >
                                    <Icon name='call' />
                                  </LoadingButton>
                                </span>
                              </Tooltip>
                            </Col>
                          )
                          : null
                      }
                    </Row>
                  ))}
                  <Row>
                    <div className='text-start text-primary fw-bold'>
                      <Button
                        variant='link'
                        className='text-primary ms-n5 fw-bold text-nowrap'
                        onClick={() => setShowForm(entity.id)}
                      >
                        <Icon name='plus' />
                      Adicionar telefone
                      </Button>
                      {isLastItem ? null : <hr className='my-3 text-light-gray opacity-50' />}
                    </div>
                  </Row>
                </div>
              </div>
            </Card>
          )
          : null
      }
      <DetailsContactForm
        show={showForm === entity.id}
        entity={entity}
        entityType={entityType}
        onSubmit={onUpdateEntity}
        onCancel={() => setShowForm(false)}
      />
    </>
  );
}

export default DetailsVoipContactItem;
