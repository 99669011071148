import React from 'react';
import PropTypes from 'prop-types';
import { applyMask } from '@/masks';
import { getDocType } from '@/utils';

ProposalDocumentFooter.propTypes = {
  proposal: PropTypes.object,
  logoBase64: PropTypes.string
};

ProposalDocumentFooter.defaultProps = {
  proposal: {},
  logoBase64: ''
};
function ProposalDocumentFooter({ user, proposal, logoBase64 }) {
  const docType = getDocType(proposal?.cpfCnpj);
  const maskedCpfCnpj = applyMask(proposal?.cpfCnpj, docType);
  const css = document.querySelector('link[rel=stylesheet]')?.href;
  const hidePoweredBy = user.account.legacyPro || user.account.performanceOrHigher;

  return (
    <html>
      <head>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <link href={css} rel='stylesheet' />
      </head>
      <body className='proposal-body'>
        <div className='position-absolute top-0 start-0 w-100 pt-8 mb-2'>
          <hr className='proposal-hr' />
          <div className='w-100 mt-2 p-4 mx-1 text-darker-gray text-small'>
            <div className='d-inline-block' style={{ width: '40rem' }}>
              <span>
                {proposal?.legalName} | {maskedCpfCnpj}
                <br />
                Endereço: {proposal?.address}
              </span>
            </div>
            {
              hidePoweredBy
                ? null
                : (
                  <div className='d-inline-block float-end clearfix mt-2 me-2 pb-4 ps-2'>
                    <span className='text-medium-gray' style={{ color: '#9393C2' }}>
                      Powered by <img src={logoBase64} width={67} height={17} />
                    </span>
                  </div>
                )
            }
          </div>
        </div>
      </body>
    </html>
  );
}

export default ProposalDocumentFooter;
