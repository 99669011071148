/* eslint-disable complexity */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { sortBy } from 'lodash';
import ProposalIconLabel from '@/components/Proposal/ProposalIconLabel';
import ProposalLogo from '@/components/Proposal/ProposalLogo';
import { ProposalProducts } from '@/components/Proposal/ProposalProducts';
import { ProposalRichText } from '@/components/Proposal/ProposalRichText';
import { toShortTextualDateString } from '@/date';
import { applyMask } from '@/masks';
import { PROPOSAL_SECTIONS_TYPE, getDocType } from '@/utils';

const MEDIUM_OPACITY = 0.50;
const BANNER_WIDTH = '1280px';
const BANNER_HEIGHT = '320px';

ProposalDocumentBody.propTypes = {
  user: PropTypes.object,
  proposal: PropTypes.object,
  version: PropTypes.string,
  layoutMode: PropTypes.bool,
  bannerBase64: PropTypes.string,
  logoBase64: PropTypes.string
};

ProposalDocumentBody.defaultProps = {
  user: {},
  proposal: {},
  version: '',
  layoutMode: false,
  bannerBase64: '',
  logoBase64: ''
};

const ProposalSectionComponent = {
  [PROPOSAL_SECTIONS_TYPE.RICH_TEXT]: ProposalRichText,
  [PROPOSAL_SECTIONS_TYPE.PRODUCTS]: ProposalProducts
};

function ProposalDocumentBody({
  user, proposal, version, layoutMode, bannerBase64, logoBase64
}) {
  const docType = getDocType(proposal?.cpfCnpj);
  const maskedCpfCnpj = applyMask(proposal?.cpfCnpj, docType);
  const allProposalModelSections = proposal?.proposalModelSections ?? [];
  const proposalModelSections = allProposalModelSections.filter(
    (section) => Boolean(!section?._destroy)
  );
  const orderedProposalModelSections = sortBy(proposalModelSections, 'position');
  const bgColor = proposal?.bgColor;
  const textColor = proposal?.textColor;
  const titleColorClass = layoutMode ? 'text-dark-gray' : 'text-darker-gray';
  const contentColorClass = layoutMode ? 'text-medium-gray' : 'text-darker-gray';
  const hasEntityBox = proposal?.entityName || proposal?.entityWorkphone || proposal?.entityEmail;
  const hasOrganization = (
    proposal?.deal?.organization || proposal?.entityAddress || proposal?.entityCnpj
  );
  const currentDate = toShortTextualDateString(new Date());
  const css = document.querySelector('link[rel=stylesheet]')?.href;

  return (
    <html>
      <head>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <link href={css} rel='stylesheet' />
      </head>
      <body className='proposal-body'>
        <div className='position-absolute top-0 start-0 bg-white w-100 mb-3'>
          <div className='w-100 mw-100 mx-0 px-0'>
            {/*
              Como precisávamos implementar uma cor de filtro juntamente com
              a opção de ter imagem ao fundo e isso não era abrangido pela lib
              pdf-to-react via CSS backgroundBlendMode: 'overlay', tivemos que
              fazer essa sobreposição de elementos manualmente conforme as duas
              positions abaixo.
            */}
            <div
              className='w-100 mx-0 p-0 position-absolute object-fit-cover top-0 start-0'
              style={{
                width: BANNER_WIDTH,
                maxWidth: BANNER_WIDTH,
                minWidth: BANNER_WIDTH,
                height: BANNER_HEIGHT,
                maxHeight: BANNER_HEIGHT,
                minHeight: BANNER_HEIGHT,
                opacity: bannerBase64 ? 1 : MEDIUM_OPACITY,
                backgroundImage: bannerBase64 ? `url(${bannerBase64})` : null,
                backgroundSize: 'cover'
              }}
            />
            <div className='position-relative'>
              <div
                className={classnames(
                  'w-100 mx-0 p-0',
                  { 'bg-light': !bannerBase64 && !bgColor }
                )}
                style={{
                  height: BANNER_HEIGHT,
                  maxHeight: BANNER_HEIGHT,
                  minHeight: BANNER_HEIGHT,
                  backgroundColor: bgColor,
                  color: textColor
                }}
              >
                <div className='float-end clearfix pt-6 pe-8 w-50 text-end'>
                  {layoutMode ? 'Código da versão' : version}
                </div>
                <div className='d-block'>
                  <div
                    className='d-inline-block text-center mx-auto float-start clearfix'
                    style={{ marginTop: '4rem', width: '16rem' }}
                  >
                    {logoBase64 ? <ProposalLogo src={logoBase64} /> : null}
                  </div>
                  <div className='d-inline-block w-auto mt-2'>
                    <span className='fw-bold mb-2'>{proposal?.title}</span>
                    <h1 className='fw-bold mb-2' style={{ fontSize: '1.4rem' }}>
                      {proposal?.legalName}
                    </h1>
                    <span className='d-block fw-bold'>
                      {docType ? `${docType.toUpperCase()}: ` : ''}
                      {maskedCpfCnpj}
                    </span>
                    <span className='d-block mb-2 fw-bold'>{proposal?.address}</span>
                    {
                      layoutMode
                        ? null
                        : (
                          <div className='d-inline fw-bold mb-2'>
                            <span className='text-capitalize'>{currentDate}</span>
                            &nbsp;|&nbsp;Valida até:&nbsp;
                            {proposal?.expiredAt}
                          </div>
                        )
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className='px-4'>
              <div className='d-block mx-4 mt-8'>
                <div className='d-inline-block mb-2 align-top' style={{ width: '32%' }}>
                  <span className={classnames(titleColorClass, 'fw-bold mb-2')}>
                    {
                      layoutMode
                        ? 'Dados do vendedor'
                        : `Proposta enviada por ${user?.name}`
                    }
                  </span>
                  <ProposalIconLabel
                    text={layoutMode ? 'E-mail do vendedor' : user?.email}
                    icon='proposal-icon-email'
                    className={classnames(contentColorClass, 'mb-1')}
                  />
                  <ProposalIconLabel
                    text={layoutMode ? 'Telefone do vendedor' : user?.workPhone}
                    icon='proposal-icon-call'
                    className={classnames(contentColorClass, 'mb-1')}
                  />
                </div>
                <div
                  className='mb-2 align-top'
                  style={{ width: '32%', display: hasEntityBox ? 'inline-block' : 'none' }}
                >
                  <span className={classnames(titleColorClass, 'fw-bold mb-2')}>
                    {
                      layoutMode
                        ? 'Dados do cliente'
                        : `Para: ${proposal?.entityName || 'não informado'}`
                    }
                  </span>
                  <ProposalIconLabel
                    text={layoutMode ? 'E-mail do cliente' : proposal?.entityEmail}
                    icon='proposal-icon-email'
                    className={classnames(contentColorClass, 'mb-1')}
                  />
                  <ProposalIconLabel
                    text={
                      layoutMode
                        ? 'Telefone do cliente'
                        : applyMask(proposal?.entityWorkPhone, 'phone')
                    }
                    icon='proposal-icon-call'
                    className={classnames(contentColorClass, 'mb-1')}
                  />
                </div>
                <div
                  className='mb-2 align-top'
                  style={{ width: '32%', display: hasOrganization ? 'inline-block' : 'none' }}
                >
                  <span className={classnames(titleColorClass, 'fw-bold mb-2')}>
                    Dados da empresa
                  </span>
                  <ProposalIconLabel
                    text={layoutMode ? 'Nome da empresa' : proposal?.entityName}
                    icon='proposal-icon-org'
                    className={classnames(contentColorClass, 'mb-1')}
                  />
                  <ProposalIconLabel
                    text={layoutMode ? 'CNPJ da empresa' : applyMask(proposal?.entityCnpj, 'cnpj')}
                    icon='proposal-icon-org'
                    className={classnames(contentColorClass, 'mb-1')}
                  />
                  <ProposalIconLabel
                    text={layoutMode ? 'Endereço da empresa' : proposal?.entityAddress}
                    icon='proposal-icon-visit'
                    className={classnames(contentColorClass, 'mb-1')}
                  />
                </div>
              </div>
              {
                orderedProposalModelSections?.map((section, index) => {
                  const { sectionType, title, content } = section;
                  const SectionComponent = ProposalSectionComponent[sectionType];

                  return (
                    <SectionComponent
                      key={`${sectionType}_${index}`}
                      layoutMode={layoutMode}
                      title={title}
                      content={content}
                      items={proposal?.entity_products}
                    />
                  );
                })
              }
            </div>
          </div>
        </div>
      </body>
    </html>
  );
}

export default ProposalDocumentBody;
