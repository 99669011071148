import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { useAlert } from 'react-alert';
import { QRCodeSVG } from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useQueryClient } from 'react-query';

import Button from '@/components/Button';
import Card from '@/components/Card';
import Icon from '@/components/Icon';
import Main from '@/components/Main';
import PaymentSuccessModal from '@/components/Billing/PaymentSuccessModal';
import { parseDate, toDateTimeString } from '@/date';
import { settingsCurrentPlanPath } from '@/routes';
import { currentPlanKeys, useCurrentPlan, useTransaction } from '@/api';

const REFETCH_INTERVAL = 5000;

function Pix() {
  const [pollingEnabled, setPollingEnabled] = useState(true);
  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const alert = useAlert();
  const queryClient = useQueryClient();

  const { id: transactionId, transactionDetails, planName } = location.state;
  const { qr_code: qrCode, expires_at: expiresAt } = transactionDetails;

  const handlePixCopy = () => {
    alert.show(
      'Código copiado!',
      { variant: 'success', timeout: 5000 }
    );
  };

  const handlePaymentCompleted = async () => {
    setPollingEnabled(false);

    await queryClient.invalidateQueries(currentPlanKeys.currentPlan);

    setShowPaymentSuccess(true);
  };

  const handlePaymentFailed = () => {
    setPollingEnabled(false);

    alert.show(
      <div>
        <h6>Infelizmente, não conseguimos processar seu pagamento</h6>
        <div>Aguarde alguns minutos e tente novamente.</div>
        <div>Se o erro persistir, entre em contato com o nosso suporte.</div>
      </div>,
      { variant: 'danger' }
    );
  };

  // Foi preciso usar essa query para poder invalidá-la aqui no componente.
  useCurrentPlan();

  useTransaction({
    billingTransactionId: transactionId,
    config: {
      refetchInterval: REFETCH_INTERVAL,
      cacheTime: 0,
      enabled: Boolean(pollingEnabled && transactionId),
      onSuccess: ({ data }) => {
        switch (data.statusName) {
          case 'Aguardando Pagto':
          case 'Em análise':
            return;
          case 'Aprovado':
          case 'Completo':
            return handlePaymentCompleted();
          default:
            return handlePaymentFailed();
        }
      }
    }
  });

  return (
    <Main fluid className='p-9 container-full-height'>
      <Container className='py-9'>
        <Row>
          <Col md={{ span: 7, offset: 3 }}>
            <Card className='mb-6 p-5 border-light'>
              <div className='d-flex flex-column align-items-center mb-2'>
                <h2 className='mb-4'>Código do Pix gerado</h2>

                <div className='mb-4 text-body'>
                  <QRCodeSVG
                    fgColor='currentColor'
                    value={qrCode}
                    size='244'
                  />
                </div>

                <CopyToClipboard
                  onCopy={handlePixCopy}
                  text={qrCode}>
                  <Button
                    variant='lighter-blue'
                    className='mb-2 rounded-sm text-primary'
                  >
                    <Icon name='copy' size='sm' className='me-2' />

                    Copiar código Pix
                  </Button>
                </CopyToClipboard>

                <span className='text-small text-dark-gray'>
                  Vencimento: {toDateTimeString(parseDate(expiresAt))}
                </span>
              </div>

              <div className='mb-2 text-small fw-bold'>
                Informações importantes:
              </div>

              <ol type='1' className='mb-0 ps-4 text-dark-gray'>
                <li>
                  Acesse o app do seu banco ou internet banking e escolha &quot;Pagar com Pix&quot;.
                </li>

                <li>
                  Copie e cole o código acima ou escaneie o QR code.
                </li>

                <li>
                  Confira as informações.
                </li>

                <li>
                  Finalize o pagamento.
                </li>
              </ol>
            </Card>

            <div className='d-flex justify-content-center'>
              <Button
                variant='outline-dark-gray align-self-center'
                className=''
                onClick={() => navigate(settingsCurrentPlanPath())}
              >
                Ir para Agendor
              </Button>
            </div>
          </Col>
        </Row>
      </Container>

      <PaymentSuccessModal
        show={showPaymentSuccess}
        title={`Seu Plano ${planName} está liberado!`}
        message={`Agora você já pode utilizar os recursos avançados do Agendor
          para agilizar a operação do seu time e vender mais.`}
        redirectPath={settingsCurrentPlanPath()}
      />
    </Main>
  );
}

export default Pix;
