import React from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classnames from 'classnames';
import APIErrorMessage from '@/components/APIErrorMessage';
import EditStageForm from '@/components/EditStageForm';
import FunnelStagesNew from '@/pages/funnels/stages/_new';
import FunnelStagesDelete from '@/pages/funnels/stages/_delete';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import { getValidationErrors } from '@/utils';
import { useUpdateStage } from '@/api';
import { toDigitsString } from '@/number';
import { settingsFunnelStagesRequiredFieldsPath } from '@/routes';

const propTypes = {
  funnelId: PropTypes.number.isRequired,
  stages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    order: PropTypes.number
  })).isRequired
};

const defaultProps = {
};

function FunnelStagesList({ funnelId, stages }) {
  const mutation = useUpdateStage({ funnelId });
  const alert = useAlert();

  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    if (!destination || source.index === destination.index) {
      return;
    }

    mutation.mutate({
      stageId: draggableId,
      params: { order: destination.index + 1 }
    }, {
      onSuccess: () => {
        alert.show(
          'Etapa editada com sucesso.',
          { variant: 'success', timeout: 5000 }
        );
      },
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='stage' action='update' />,
          { variant: 'danger' }
        );
      }
    });
  };

  const onEdit = (formData, { setFieldError }) => {
    mutation.mutate({
      stageId: formData.id,
      params: formData
    }, {
      onSuccess: () => {
        alert.show(
          'Etapa editada com sucesso.',
          { variant: 'success', timeout: 5000 }
        );
      },
      onError: (err) => {
        const errors = getValidationErrors(err, 'stage');

        Object.entries(errors).forEach(([key, message]) => {
          setFieldError(key, message);
        });

        alert.show(
          <APIErrorMessage err={err} resource='stage' action='update' />,
          { variant: 'danger' }
        );
      }
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className='stages-list'>
        <div className={classnames(
          'd-flex',
          'align-items-center',
          'border-1',
          'border-bottom',
          'border-darker-gray',
          'text-uppercase',
          'fw-bold'
        )}>
          <div className='width-3 p-3 flex-shrink-0'></div>
          <div className='width-6 p-3'>Nome</div>
          <div className='width-5 p-3'></div>
          <div className='width-5 p-3'></div>
        </div>

        <Droppable
          droppableId='stages-list'
          type='row'
        >
          {(droppableProvided, droppableSnapshot) => (
            <div
              className={classnames(
                { 'pe-none': droppableSnapshot.isDraggingOver }
              )}
              ref={droppableProvided.innerRef}
              { ...droppableProvided.droppableProps }
            >
              {stages.map((item, index) => (
                <Draggable
                  draggableId={String(item.id)}
                  index={index}
                  key={item.id}
                  isDragDisabled={item.isDragDisabled}
                >
                  {(draggableProvided, draggableSnapshot) => (
                    <div
                      className={classnames(
                        'stages-item',
                        'd-flex',
                        'align-items-start',
                        'hover-parent',
                        'hover-bg-light',
                        'border-1',
                        'border-bottom',
                        'text-nowrap',
                        { 'bg-light': draggableSnapshot.isDragging }
                      )}
                      ref={draggableProvided.innerRef}
                      { ...draggableProvided.draggableProps }
                    >
                      <div className='width-3 px-3 py-2 text-dark-gray'>
                        <div className={classnames(
                          'py-2',
                          'align-self-center',
                          'd-flex',
                          'align-items-center'
                        )}>
                          <span
                            className='draggable'
                            { ...draggableProvided.dragHandleProps }
                          >
                            <Icon
                              name='drag'
                              className='hover-child-text-body'
                              withStroke
                            />
                          </span>

                          <div className='ms-2'>
                            {toDigitsString(item.order, 2)}
                          </div>
                        </div>
                      </div>

                      <EditStageForm
                        stage={item}
                        onSubmit={onEdit}
                      />

                      <div className='d-flex width-5 px-3 py-2 align-self-center'>
                        <Button
                          variant='transparent-light'
                          href={settingsFunnelStagesRequiredFieldsPath(funnelId)}
                          state={{ target: { ...item, type: 'stage' } }}
                        >
                          <Icon name='listing' className='me-1'/>
                          <span>Editar campos da etapa</span>
                        </Button>
                      </div>

                      <div className='width-5 px-3 py-2'>
                        <FunnelStagesDelete
                          funnelId={funnelId}
                          stage={item}
                          totalCount={stages.length}
                        />
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}

              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </div>

      <FunnelStagesNew
        totalCount={stages.length}
        funnelId={funnelId}
      />
    </DragDropContext>
  );
}

FunnelStagesList.propTypes = propTypes;
FunnelStagesList.defaultProps = defaultProps;

export default FunnelStagesList;
