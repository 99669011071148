import React from 'react';
import PropTypes from 'prop-types';
import Base from 'react-bootstrap/Card';

const propTypes = {
  children: PropTypes.node
};

function Title({ children }) {
  return (
    <Base.Title>
      {children}
    </Base.Title>
  );
}

Title.propTypes = propTypes;
Title.displayName = 'Card.Title';

export default Title;
