import React from 'react';
import PropTypes from 'prop-types';
import { isBefore, parseISO } from 'date-fns';
import Badge from '@/components/Badge';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import { useAuth } from '@/lib/auth';

DealActivitiesBanner.propTypes = {
  message: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonAction: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

function DealActivitiesBanner({ message, buttonText, buttonAction, onClose }) {
  const { user } = useAuth();
  const { account } = user;

  const CUT_OFF_DATE = '2024-06-24T00:00:00.000Z';

  const canVisualizeBanner = () => {
    if (!account || !account.createdAt) {
      return false;
    }

    const creationDate = parseISO(account.createdAt);
    const cutOffDate = parseISO(CUT_OFF_DATE);

    const result = isBefore(creationDate, cutOffDate);
    return result;
  };

  if (!canVisualizeBanner()) {
    return null;
  }

  return (
    <div className='d-flex bg-light px-3 py-2 mb-2'>
      <div className='mt-2'>
        <Badge variant='primary' className='py-1 px-2 me-2'>NOVO</Badge>
      </div>
      <span className='fw-bold text-darker-gray mt-2'>{message}</span>
      <div className='ms-auto'>
        <Button
          variant='white'
          className='me-2'
          onClick={buttonAction}
        >
          {buttonText}
        </Button>
        <Button
          className='text-dark-gray p-0'
          onClick={onClose}
          variant='link'
        >
          <Icon name='close' />
        </Button>
      </div>
    </div>
  );
}

export default DealActivitiesBanner;
