import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import Modal from '@/components/Modal';
import { plansPath, settingsManageUpdatePlanPath } from '@/routes';

const propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  planName: PropTypes.string,
  redirectToUpgrade: PropTypes.bool
};

const defaultProps = {
  planName: 'Pro',
  redirectToUpgrade: false
};

function Paywall({ planName, children, content, redirectToUpgrade }) {
  const [show, setShow] = useState(false);

  const onHide = () => setShow(false);
  const onEventCapture = (event) => {
    event.stopPropagation();
    event.target.blur();

    setShow(true);
  };

  const buttonText = redirectToUpgrade ? 'Contratar agora' : 'Ver planos e preços';
  const upgradePath = settingsManageUpdatePlanPath({ new_plan_code: 'performance_plan' });
  const path = redirectToUpgrade ? upgradePath : plansPath();

  return (
    <React.Fragment>
      <Modal show={show} onHide={onHide} size='sm'>
        <div className='pt-4 px-4 text-center'>
          <h4 className='mb-4'>
            Essa é uma funcionalidade do Plano {planName}
          </h4>

          <div className='mb-4'>
            {content}
          </div>

          <div className='d-grid gap-2'>
            <Button href={path}>
              {buttonText}
            </Button>

            <Button variant='link' onClick={onHide}>
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>

      {React.cloneElement(children, {
        onClickCapture: onEventCapture,
        onFocusCapture: onEventCapture
      })}
    </React.Fragment>
  );
}

Paywall.propTypes = propTypes;
Paywall.defaultProps = defaultProps;

export default Paywall;
