import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Avatar from '@/components/Avatar';
import Icon from '@/components/Icon';

const propTypes = {
  disableArrow: PropTypes.bool,
  className: PropTypes.string,
  slide: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string
  })).isRequired
};

const defaultProps = {
  className: null,
  disableArrow: false,
  slide: true,
  items: []
};

const SHOWN_ITEMS = 3;

function AvatarList({ items, disableArrow, slide, className }) {
  const shown = items.slice(0, SHOWN_ITEMS).reverse();
  const collapsed = items.slice(SHOWN_ITEMS);

  return (
    <div className={classnames('d-flex', className)}>
      {
        !disableArrow && (
          <Icon
            name='arrow-turn-down-right'
            className='text-light-purple me-2'
          />
        )
      }

      <div className={classnames(
        'd-inline-flex',
        'flex-row-reverse',
        'justify-content-start',
        'ms-1'
      )}>
        {collapsed.length > 0 &&
          <Avatar
            light
            slide={slide}
            className='ms-n1'
            label={`+${collapsed.length}`}
            tooltip={
              <ul className='list-unstyled mb-0 text-start'>
                {collapsed.map((item, index) => (
                  <li key={index}>{item.name}</li>
                ))}
              </ul>
            }
          />
        }

        {shown.map((item, index) => (
          <Avatar slide={slide} key={index} className='ms-n1' { ...item} />
        ))}
      </div>
    </div>
  );
}

AvatarList.propTypes = propTypes;
AvatarList.defaultProps = defaultProps;

export default AvatarList;
