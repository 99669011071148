import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import { LeadsGenerationModal } from '@/components/LeadsGeneration';
import { useScreenSize, useStoredState } from '@/hooks';

const propTypes = {
  className: PropTypes.string
};

const defaultProps = {
  className: ''
};

function LeadsGenerationButton({ className }) {
  const { isLG } = useScreenSize();
  const [showModalLeadsGeneration, setShowModalLeadsGeneration] = useState(false);

  const {
    value: leadsGenPresentationFinished,
    store: setLeadsGenPresentationFinished
  } = useStoredState('leads-gen-presentation-finished', false);

  return (
    <>
      <Button
        variant='dark'
        onClick={() => setShowModalLeadsGeneration(true)}
        id='leads-gen-cta'
        className={classnames(
          'text-nowrap',
          { 'rounded': !isLG },
          { 'py-2': isLG },
          'align-self-center',
          className
        )}
      >
        <Icon name='rocket' size='sm' />
        <span
          id='leads-gen-cta-span'
          className='d-none text-nowrap d-xl-block ms-2'
        >
          Gerar leads
        </span>
      </Button>

      <LeadsGenerationModal
        skipPresentation={leadsGenPresentationFinished}
        show={showModalLeadsGeneration}
        setShow={setShowModalLeadsGeneration}
        onPresentationFinished={() => setLeadsGenPresentationFinished(true)}
        onHide={() => setShowModalLeadsGeneration(false)}
      />
    </>
  );
}

LeadsGenerationButton.propTypes = propTypes;
LeadsGenerationButton.defaultProps = defaultProps;

export default LeadsGenerationButton;
