import React from 'react';
import MobileAppsAddonsCard from '@/components/MobileAppsAddonsCard';
import WhatsappAddonsCard from '@/components/WhatsappAddonsCard';

function PlanAddonsCard() {
  return (
    <div className='p-4 border rounded border-light text-dark-gray mb-2'>
      <h5>Complementos</h5>
      <WhatsappAddonsCard />
      <hr />
      <MobileAppsAddonsCard />
    </div>
  );
}

export default PlanAddonsCard;
