import { baseLegacyUrl } from '@/routes';
import { getUrlWithQueryString } from '@/utils';

export function personDealsPath(id) {
  return `${peoplePath()}/${id}/deals`;
}

export function personEditPath(id) {
  return `${peoplePath()}/${id}/edit`;
}

export function peoplePath(params) {
  return getUrlWithQueryString('/people', params);
}

export function personPath(id, base = '') {
  return `${base}?personId=${id}`;
}

export function personCreatePath(organizationId) {
  const searchParams = new URLSearchParams();

  if (organizationId) {
    searchParams.set('organization_id', organizationId);
  }

  return `${peoplePath()}/new?${searchParams.toString()}`;
}

export function legacyPeopleUrl() {
  return `${baseLegacyUrl()}/pessoas`;
}

export function legacyPersonUrl(id, forceLegacy = false) {
  return `${baseLegacyUrl()}/pessoas/historico.php?id=${id}${forceLegacy ? '&legado=1' : ''}`;
}

export function legacyPeopleImportUrl() {
  return `${baseLegacyUrl()}/importar/?env=pessoas`;
}
