import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Main from '@/components/Main';
import Funnel from '@/components/Funnel';
import { setDealsViewCookie, useDocumentTitle, useLastViewedFunnel } from '@/hooks';
import NotFound from '@/pages/not-found';
import { isNotFoundError } from '@/errors';
import { useFunnel } from '@/api';

const PAGE_TITLE = 'Funil';

function FunnelsShow() {
  const { funnelId } = useParams();

  const { data: { data } = {}, isSuccess, error } = useFunnel({ funnelId });

  const { store: storeLastViewedFunnel } = useLastViewedFunnel();

  useDocumentTitle(PAGE_TITLE);

  useEffect(() => {
    setDealsViewCookie('funnel');
  }, []);

  useEffect(() => {
    if (isSuccess) {
      storeLastViewedFunnel(funnelId);
    }
  }, [storeLastViewedFunnel, funnelId, isSuccess]);

  if (error && isNotFoundError(error)) {
    return <NotFound />;
  }

  if (!isSuccess) {
    return <div className='flex-grow-1 bg-light' />;
  }

  /*
   * Ao trocar entre funis, em alguns casos devido ao algoritmo de reconciliação
   * do React [1], a mesma instância do componente `Funnel` é reutilizada.
   * Isso faz com que alguns componentes internos não sejam remontados, mas sim
   * atualizados.
   * Esse comportamento gera alguns desafios, pois os componentes foram escritos
   * para a situação de sempre estarem montando num novo funil. Por exemplo:
   * - As colunas do funil são inicializadas no `useBoardDrag` apenas no mount;
   * - O `FunnelForm` apenas carrega os filtros salvos no `localStorage` no
   * mount.
   * Por isso, estamos incluindo aqui uma `key`, para forçar que o `Funnel` seja
   * sempre remontado quando seu `id` mudar.
   * Caso refatoremos os componentes para serem menos dependentes do mount,
   * eventualmente essa `key` poderá ser removida.
   *
   * [1] https://reactjs.org/docs/reconciliation.html
   */
  return (
    <Main
      fluid
      overflow={false}
      className='d-flex flexible-height flexible-width'
    >
      <Funnel key={data.id} data={data} />
    </Main>
  );
}

export default FunnelsShow;
