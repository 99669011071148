export function canEditDealStage({ user, _data }) {
  let effect, reason;
  const privilege = user.privileges.deals.create_update;

  if (!privilege) {
    effect = 'disable';
    reason = `Você não possui privilégio para editar as etapas.
      Contate o administrador de sua conta.`;
  } else {
    effect = 'allow';
  }

  return { effect, reason };
}

export function canEditDeal({ user, _data }) {
  let effect, reason;
  const privilege = user.privileges.deals.create_update;

  if (!privilege) {
    effect = 'disable';
    reason = `Você não possui privilégio para editar negócios.
      Contate o administrador de sua conta.`;
  } else {
    effect = 'allow';
  }

  return { effect, reason };
}

export function canEditDealValue({ user, data }) {
  let effect, reason;
  const privilege = user.privileges.deals.create_update;

  if (!privilege) {
    effect = 'disable';
    reason = `Você não possui privilégio para editar negócios.
      Contate o administrador de sua conta.`;
    return { effect, reason };
  }
  if (data.hasQuantity) {
    effect = 'disable';
    reason = `Este campo reflete o valor dos produtos e serviços.
    O valor total precisa ser igual a zero para editá-lo.`;
    return { effect, reason };
  }
  return { effect: 'allow', reason };
}

