import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  as: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string
};

const defaultProps = {
  placeholder: ''
};

export function InputPreview({ as: Component, label, ...componentProps }) {
  return (
    <Component
      label={label}
      placeholder=''
      name=''
      disabled
      { ...componentProps }
    />
  );
}

InputPreview.propTypes = propTypes;
InputPreview.defaultProps = defaultProps;
