import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

const defaultProps = {
  className: ''
};

function Title({ className, children }) {
  return (
    <h2 className={classnames('mb-4', className)}>
      {children}
    </h2>
  );
}

Title.propTypes = propTypes;
Title.defaultProps = defaultProps;
Title.displayName = 'ConfirmationModal.Title';

export default Title;
