import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@/components/Button';
import { distanceFromNowInCalendarDays, pluralizeDaysRemainingText } from '@/date';
import PlanConversionModal from '@/components/PlanConversionModal';
import { plansPath } from '@/routes';

import bannerTrial from 'images/billing/banner-trial.svg';
import bannerFree from 'images/billing/banner-free.svg';

const propTypes = {
  subscriptionType: PropTypes.oneOf(['free', 'trial', 'paid']),
  expirationDate: PropTypes.string,
  canceled: PropTypes.bool,
  name: PropTypes.string,
  trialAvailable: PropTypes.bool
};

const defaultProps = {
  subscriptionType: null
};

function CurrentAccountBanner(props) {
  const { subscriptionType, expirationDate, canceled, name, trialAvailable } = props;
  const [showPlanConversionModal, setShowPlanConversionModal] = useState(false);

  const navigate = useNavigate();

  const isPaid = subscriptionType === 'paid';
  const hideBanner = isPaid && !canceled;
  const isFree = subscriptionType === 'free';

  const bannerData = useMemo(() => {
    const remainingDays = distanceFromNowInCalendarDays(expirationDate);
    let pluralizedDaysRemainingText = pluralizeDaysRemainingText(remainingDays);
    let paidDescription;

    const description = `
      Obtenha agora todos os recursos necessários para equipes que buscam
      alta performance em vendas.
    `;

    if (remainingDays < 0) {
      const daysBeenExpired = Math.abs(remainingDays);
      pluralizedDaysRemainingText = `Conta expirada há ${daysBeenExpired} dias`;
      paidDescription = `
        Sua assinatura expirou. Para continuar utilizando o Agendor,
        escolha um plano para renovação.
      `;
    } else {
      paidDescription = `
        Sua assinatura estará disponível até o final do período pago. Após esse prazo,
        o cancelamento será efetivado e você não será mais cobrado.
      `;
    }

    const BANNER_DATA = {
      free: {
        classes: 'bg-light-green',
        img: bannerFree,
        title: trialAvailable
          ? 'Experimente todos os recursos do Agendor!'
          : 'Você está utilizando apenas 20% do potencial do Agendor',
        description,
        button: {
          label: trialAvailable
            ? 'Experimentar'
            : 'Ver planos'
        },
        onButtonClick: () => {
          if (trialAvailable) {
            return setShowPlanConversionModal(true);
          }
          navigate(plansPath());
        }
      },
      trial: {
        classes: 'bg-primary bg-opacity-10',
        img: bannerTrial,
        title: pluralizedDaysRemainingText,
        description,
        button: {
          label: `Assine o plano ${name}`
        },
        onButtonClick: () => setShowPlanConversionModal(true)
      },
      paid: {
        classes: 'bg-danger bg-opacity-10',
        img: bannerTrial,
        title: pluralizedDaysRemainingText,
        description: paidDescription,
        button: {
          label: 'Renove seu plano'
        },
        onButtonClick: () => navigate(plansPath())
      }
    };

    return BANNER_DATA[subscriptionType];
  }, [subscriptionType, expirationDate]);

  if (hideBanner) {
    return null;
  }

  const { classes, img, title, description, button, onButtonClick } = bannerData;

  return (
    <div className={classNames('d-flex py-3 px-8 rounded mb-2', classes)}>
      <div className='d-flex'>
        <img src={img} className='me-2 width-4 height-4' />

        <div className='align-self-center'>
          <h4 className='mb-2'>{title}</h4>

          <div className='max-width-6 text-dark-gray text-small'>
            {description}
          </div>
        </div>
      </div>

      <Button
        className='align-self-center white-space ms-auto'
        onClick={onButtonClick}
      >
        {button.label}
      </Button>

      {isFree && (
        <PlanConversionModal
          show={showPlanConversionModal}
          onHide={() => setShowPlanConversionModal(false)}
        />
      )}
    </div>
  );
}

CurrentAccountBanner.propTypes = propTypes;
CurrentAccountBanner.defaultProps = defaultProps;

export default CurrentAccountBanner;
