import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { CurrencyInput } from '@/components/Inputs';
import Tooltip from '@/components/Tooltip';
import HookFormField from '@/components/HookFormField';

function ValueField(props) {
  const [readOnly, setReadOnly] = useState(false);
  const [calculatedValue, entityProducts, syncValue, value] = useWatch(
    { name: ['calculated_value', 'entity_products', 'sync_value', 'value'] }
  );

  const { setValue } = useFormContext();

  useEffect(() => {
    const hasQuantity = entityProducts?.some((product) => Number(product.quantity) > 0);

    if (!hasQuantity) {
      setReadOnly(false);
    } else {
      setReadOnly(true);

      if (syncValue) {
        setValue(props.name, calculatedValue);
      }
    }
  }, [calculatedValue]);

  return (
    <Tooltip
      hide={!readOnly}
      trigger={['focus']}
      placement='bottom'
      content={
        calculatedValue !== value
          ? `Algum produto desse negócio foi excluído do Agendor.
            Esse campo reflete o valor dos produtos e serviços antes da exclusão.`
          : `Este campo reflete o valor dos produtos e serviços.
            O valor total precisa ser igual a zero para editá-lo.`
      }
    >
      <div>
        <HookFormField
          as={CurrencyInput}
          label='Valor total'
          autoComplete='off'
          placeholder='R$ 0,00'
          variant='light'
          readOnly={readOnly}
          { ...props }
        />
      </div>
    </Tooltip>
  );
}

export default ValueField;
