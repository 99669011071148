import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as Yup from 'yup';

import DragScrollable from '@/components/DragScrollable';
import Table from '@/components/Table';
import TableIndex from '@/components/TableIndex';
import EntitySkeleton from '@/components/List/EntitySkeleton';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import InputAdornment from '@/components/InputAdornment';
import Icon from '@/components/Icon';
import { TextInput, TextArea, Select } from '@/components/Inputs';
import Authorization from '@/components/Authorization';
import Button from '@/components/Button';
import Modal from '@/components/Modal';
import LoadingButton from '@/components/LoadingButton';

import emptyImage from 'images/empty-list-deals.png';
import { getString } from '@/utils';

const propTypes = {
  query: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
  createPolicy: PropTypes.func,
  updatePolicy: PropTypes.func,
  deletePolicy: PropTypes.func,
  resource: PropTypes.string
};

const defaultProps = {
  maxLength: 60
};

const searchDefaultValues = { q: '' };
const createDefaultValues = { names: '' };
const updateDefaultValues = { name: '' };
const deleteDefaultValues = { destination_id: '' };

const createSchema = Yup.object().shape({
  names: Yup.string().required('Por favor, informe os nomes.')
});

/**
 * `updateSchema` é criado dentro do componente porque depende da prop
 * `maxLength`.
 */

const deleteSchema = Yup.object().shape({
  destination_id: Yup.number()
    .required('Por favor, informe o item que deve substituir o excluído.')
});

function BasicList(props) {
  const {
    query, maxLength, createPolicy, updatePolicy, deletePolicy, resource
  } = props;

  /** Listagem. */
  const [searchParams, setSearchParams] = useState(searchDefaultValues);
  const { data, isLoading } = query({});
  const items = data?.data;

  // Como a query não é paginada, a busca é feita localmente.
  const searchTerm = searchParams.q?.toLowerCase() ?? '';
  const filteredItems = items?.filter(
    (item) => item.name.toLowerCase().includes(searchTerm)
  );

  /** Criação. */
  const [showCreateModal, setShowCreateModal] = useState(false);

  const onClickCreate = () => setShowCreateModal(true);
  const onCancelCreate = () => setShowCreateModal(false);
  /**
   * Nesse e nos demais handlers, foi passado um callback extra que deve ser
   * chamado no sucesso para fechar o modal. Uma alternativa para isso seria
   * transformar numa função assíncrona.
   */
  const onCreate = (formData, formMethods) => {
    props.onCreate(formData, formMethods).then(onCancelCreate, () => {});
  };

  /** Edição. */
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updateItem, setUpdateItem] = useState();

  const onClickUpdate = (item) => {
    setUpdateItem(item);
    setShowUpdateModal(true);
  };
  const onCancelUpdate = () => setShowUpdateModal(false);
  const onUpdate = (formData, formMethods) => {
    props.onUpdate(formData, formMethods).then(onCancelUpdate, () => {});
  };

  const updateSchema = Yup.object().shape({
    name: Yup.string()
      .required('Por favor, informe o nome.')
      .max(maxLength, `Nome deve ter no máximo ${maxLength} caracteres.`)
  });

  /** Exclusão. */
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState();

  const shouldRelocate = items?.length > 1 && !deleteItem?.empty;
  const relocationOptions = items
    ?.filter((item) => item.id !== deleteItem?.id)
    .map((item) => ({ value: item.id, label: item.name }));

  const onClickDelete = (item) => {
    setDeleteItem(item);
    setShowDeleteModal(true);
  };
  const onCancelDelete = () => setShowDeleteModal(false);
  const onDelete = (formData, formMethods) => {
    props.onDelete(formData, formMethods).then(onCancelDelete, () => {});
  };

  const labels = {
    singular: getString(['models', resource]),
    plural: getString(['models', 'plural', resource])
  };

  return (
    <>
      <Row className='mb-4 d-flex justify-content-between align-items-baseline'>
        <Col sm={12} md={6}>
          <Form
            defaultValues={searchDefaultValues}
            onSubmit={setSearchParams}
            name='basic-list-search-form'
          >
            {({ submitForm }) => (
              <FormField
                as={TextInput}
                name='q'
                isClearable
                onChange={submitForm}
                placeholder='Buscar por nome'
                autoComplete='off'
                leftAdornment={({ focus }) => (
                  <InputAdornment alignment='left' onClick={focus}>
                    <Icon className='text-primary' name='search' />
                  </InputAdornment>
                )}
              />
            )}
          </Form>
        </Col>

        <Col sm={12} md={6} className='d-md-flex justify-content-end'>
          <Authorization policy={createPolicy}>
            <Button
              className='me-4'
              onClick={onClickCreate}
            >
              <Icon name='add' className='me-2'></Icon>

              <span>Adicionar</span>
            </Button>
          </Authorization>
        </Col>
      </Row>

      <DragScrollable className='overflow-auto flex-grow-1'>
        <Table className='table-border-separate'>
          <thead className='position-sticky top-0 bg-white z-index-1'>
            <tr className='text-nowrap'>
              <th className={classnames(
                'border-end',
                'border-1',
                'position-sticky',
                'start-0',
                'bg-white'
              )}>

                <span className='px-3 invisible'>
                  <TableIndex index={0} />
                </span>

                <span className='px-3'>
                  Nome
                </span>
              </th>

              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {
              isLoading
                ? <EntitySkeleton maxCols={0} iconless />
                : (
                  filteredItems?.length && filteredItems.map((item, index) => (
                    <tr key={index}>
                      <td className={classnames(
                        'align-middle',
                        'border-end',
                        'border-1',
                        'position-sticky',
                        'start-0',
                        'bg-white'
                      )}>
                        <span className='px-3'>
                          <TableIndex index={index + 1} />
                        </span>

                        <span className='px-3'>
                          {item.name}
                        </span>
                      </td>

                      <td className='align-middle'>
                        <Authorization policy={updatePolicy}>
                          <Button
                            size='sm'
                            variant='transparent-light'
                            className='text-nowrap'
                            onClick={() => onClickUpdate(item)}
                          >
                            <Icon className='me-1' name='edit-field'/>

                            <span className='fw-bold'>
                              Editar
                            </span>
                          </Button>
                        </Authorization>
                      </td>

                      <td className='align-middle'>
                        <Authorization policy={deletePolicy}>
                          <Button
                            size='sm'
                            variant='transparent-light'
                            className='text-nowrap'
                            onClick={() => onClickDelete(item)}
                          >
                            <Icon className='me-1 text-danger' name='delete'/>
                            <span className='text-dark-gray fw-bold'>
                              Excluir
                            </span>
                          </Button>
                        </Authorization>
                      </td>
                    </tr>
                  ))
                )
            }
          </tbody>
        </Table>

        {
          items?.length === 0 && (
            // Isso é quase igual o EntityEmpty, podemos unificar futuramente.
            <div className='d-flex flex-column text-center justify-content-center h-100'>
              <img className='width-4 mx-auto mb-4' src={emptyImage} />

              <h4 className='mb-4'>
                Crie novos itens
              </h4>

              <p className='font-weigth-bold mb-5 text-dark-gray'>
                Você ainda não cadastrou nenhum item, que tal começar
                <br />
                adicionando agora mesmo!
              </p>

              <div>
                <Authorization policy={createPolicy}>
                  <Button size='sm' onClick={onClickCreate}>
                    <Icon name='add' className='me-2' />

                    Adicionar
                  </Button>
                </Authorization>
              </div>
            </div>
          )
        }
      </DragScrollable>

      <Modal
        show={showCreateModal}
        onHide={onCancelCreate}
        size='md'
        bodyClassName='p-8'
      >
        <div className='mb-4 text-center'>
          <div className='d-inline-block
            p-3
            rounded-circle
            bg-dark-gray
            text-white
            lh-0
            position-relative'
          >
            <Icon size='lg' name='add'></Icon>
          </div>

          <h2 className='mt-4'>
            Adicionar {labels.plural}
          </h2>
        </div>

        <p className='text-dark-gray'>
          Insira os nomes separados por vírgula, como no exemplo:
          Maquiagem, Perfumaria, Acessórios.
        </p>

        <Form
          name='basic-list-create-form'
          onSubmit={onCreate}
          validationSchema={createSchema}
          defaultValues={createDefaultValues}
        >
          {({ isSubmitting }) => (
            <>
              <FormField
                as={TextArea}
                name='names'
                autoComplete='off'
                placeholder='Digite os nomes'
              />

              <div className='mt-4 d-flex justify-content-end'>
                <Button
                  onClick={onCancelCreate}
                  variant='outline-dark-gray'
                  className='me-3'
                >
                  Cancelar
                </Button>

                <LoadingButton
                  isLoading={isSubmitting}
                  type='submit'
                >
                  Salvar
                </LoadingButton>
              </div>
            </>
          )}
        </Form>
      </Modal>

      <Modal
        show={showUpdateModal}
        onHide={onCancelUpdate}
        size='md'
        bodyClassName='p-8'
      >
        <div className='mb-4 text-center'>
          <div className='d-inline-block
            p-3
            rounded-circle
            bg-dark-gray
            text-white
            lh-0
            position-relative'
          >
            <Icon size='lg' name='edit-field'></Icon>
          </div>

          <h2 className='mt-4'>
            Editar {labels.singular}
          </h2>
        </div>

        <Form
          name='basic-list-update-form'
          onSubmit={onUpdate}
          validationSchema={updateSchema}
          defaultValues={updateDefaultValues}
          initialValues={updateItem}
        >
          {({ isSubmitting }) => (
            <>
              <FormField
                as={TextInput}
                name='name'
                autoComplete='off'
                placeholder='Nome'
                label='Nome'
              />

              <div className='mt-4 d-flex justify-content-end'>
                <Button
                  onClick={onCancelUpdate}
                  variant='outline-dark-gray'
                  className='me-3'
                >
                  Cancelar
                </Button>

                <LoadingButton
                  isLoading={isSubmitting}
                  type='submit'
                >
                  Salvar
                </LoadingButton>
              </div>
            </>
          )}
        </Form>
      </Modal>

      <Modal
        show={showDeleteModal}
        onHide={onCancelDelete}
        size='md'
        bodyClassName='p-8'
      >
        <h2 className='mt-9 text-center mb-3'>
          Confirmar exclusão de {deleteItem?.name}
        </h2>

        <div>
          <p className='text-center text-dark-gray'>
            {
              shouldRelocate &&
              'Para excluir esse item, você precisará substituí-lo por outro. '
            }
            Ao excluir, esta ação não poderá ser desfeita.
          </p>

          <Form
            name='basic-list-delete-form'
            onSubmit={onDelete}
            validationSchema={shouldRelocate ? deleteSchema : Yup.object()}
            defaultValues={deleteDefaultValues}
            initialValues={deleteItem}
          >
            {({ isSubmitting }) => (
              <>
                {
                  shouldRelocate && (
                    <FormField
                      as={Select}
                      name='destination_id'
                      options={relocationOptions}
                    />
                  )
                }

                <div className='mt-4 d-grid gap-3'>
                  <LoadingButton
                    isLoading={isSubmitting}
                    type='submit'
                    variant='danger'
                  >
                    Confirmar exclusão
                  </LoadingButton>

                  <Button
                    onClick={onCancelDelete}
                    variant='link'
                  >
                    Cancelar
                  </Button>
                </div>
              </>
            )}
          </Form>
        </div>
      </Modal>
    </>
  );
}

BasicList.propTypes = propTypes;
BasicList.defaultProps = defaultProps;

export default BasicList;
