import React from 'react';
import PropTypes from 'prop-types';
import { Keyword } from '@/feature/automation';
import strings from '@/strings';
import { formatDurationMessage } from '@/date';
import Icon from '@/components/Icon';
import Tooltip from '@/components/Tooltip';

const propTypes = {
  active: PropTypes.bool,
  tooltip: PropTypes.bool,
  params: PropTypes.shape({
    type: PropTypes.string,
    dueIn: PropTypes.object,
    funnelId: PropTypes.number
  })
};

const defaultProps = {
  active: true,
  tooltip: false,
  params: {}
};

function ActionContent(props) {
  const { active, params, template, tooltip } = props;
  const { actionIdentifier, actionForm } = template ?? {};
  const { type, due_in: dueIn } = params ?? {};
  const typeLabel = type
    ? strings.models.activity.type[type].toLowerCase()
    : '';
  const dueInLabel = formatDurationMessage(dueIn).toLowerCase();
  const {
    funnel_id: funnelId,
    funnel_name: funnelName = 'Funil indefinido'
  } = params ?? {};

  let identifier = actionIdentifier;
  let actionLabel = '';
  let extraParams = '';

  switch (actionForm) {
    case 'activity':
      actionLabel = `${typeLabel} ${dueInLabel}`;
      break;
    case 'action_funnel':
      if (funnelId) {
        identifier = 'Duplicá-lo para o ';
        actionLabel = funnelName;
      }
      break;
    case 'email':
      identifier = actionIdentifier;
      actionLabel = 'imediatamente';
      if (tooltip) {
        extraParams = (
          <Tooltip
            placement='bottom'
            content= 'O e-mail será enviado assim que o gatilho for executado'
          >
            <span>
              <Icon name='info' size='md' className='text-dark-gray' />
            </span>
          </Tooltip>
        );
      }
      break;
    default:
      identifier = actionIdentifier;
      actionLabel = '';
  }

  return (
    <span>
      {identifier}{' '}
      <Keyword custom active={active}>{actionLabel} {extraParams}</Keyword>
    </span>
  );
}

ActionContent.propTypes = propTypes;
ActionContent.defaultProps = defaultProps;

export default ActionContent;
