import React from 'react';
import backgroundImage from 'images/news/whatsapp-web-background.png';
import screenImage from 'images/news/whatsapp-web.png';
import whatsappImage from 'images/news/whatsapp-web.gif';
import whatsappImage2 from 'images/news/whatsapp-web-2.gif';
import Icon from '@/components/Icon';
import Main from '@/components/Main';
import Button from '@/components/Button';
import ExternalLink from '@/components/ExternalLink';
import { funnelsPath, helpUrl } from '@/routes';

const INTEGRATION_URL = 'https://chromewebstore.google.com/detail/crm-de-vendas-para-whatsa/gmpkcemlmfebbppdonfmfanggndocjec';

function Whatsapp() {
  return (
    <Main overflow className='container-full-height'>
      <div className='news mx-auto rounded-2 border border-1 overflow-hidden pb-6'>
        <div className='d-flex mb-9 position-relative text-white'>
          <img src={backgroundImage} className={
            `position-absolute
            top-0
            start-0
            h-100
            w-100
            z-index-n1`
          } />

          <div className='py-7'>
            <img src={screenImage} />
          </div>

          <div className='p-7 w-50'>
            <div className='p-7'>
              <div className='mb-6'>
                <Icon name='agendor-logo' className='w-25' />
                <span className='text-flat-green px-2'>+</span>
                <Icon name='whatsapp' className='text-flat-green' />
              </div>

              <h1 className='mb-5'>
                Vocês pediram e nós atendemos!
              </h1>
              <p className='fs-4 mb-0'>
                Confira mais um lançamento Agendor para alavancar suas vendas por WhatsApp.
              </p>
            </div>
          </div>
        </div>

        <div className='px-8 pt-1'>
          <div className='mb-9'>
            <h2 className='mb-4'>
              Cadastre clientes e salve conversas direto pelo WhatsApp
            </h2>
            <p className='text-dark-gray fs-4'>
              Agora você pode centralizar no Agendor o relacionamento
              de todos os vendedores com os clientes via WhatsApp.
            </p>
          </div>

          <div className='d-flex flex-column gap-6 mb-9'>
            <div>
              <h4 className='mb-2'>Cadastro prático.</h4>
              <p className='text-dark-gray'>
                Cadastre clientes e leads de uma forma prática sem sair do WhatsApp.
              </p>

              <ExternalLink
                target='_blank'
                href={INTEGRATION_URL}
              >
                <img src={whatsappImage} className='w-100' />
              </ExternalLink>
            </div>

            <div>
              <h4 className='mb-2'>Salve as mensagens no Agendor.</h4>
              <p className='text-dark-gray'>
                Identifique os clientes cadastrados no Agendor e envie as conversas do
                WhatsApp direto para o histórico do cliente.
              </p>

              <ExternalLink
                target='_blank'
                href={INTEGRATION_URL}
              >
                <img src={whatsappImage2} className='w-100' />
              </ExternalLink>
            </div>
          </div>

          <div className='d-flex flex-column'>
            <p className='text-dark-gray'>
              Leve o CRM para dentro do WhatsApp,
              facilitando o dia-a-dia da equipe de vendas e muito mais!
            </p>

            <ExternalLink
              target='_blank'
              href={INTEGRATION_URL}
              className='mb-4'
            >
              <Button>Ativar Integração</Button>
            </ExternalLink>

            <p className='text-dark-gray'>
              Obs: A integração funciona somente no WhatsApp Web pelo navegador Google Chrome.
              Para mais detalhes de como funciona a integração,
              <ExternalLink
                target='_blank'
                href={helpUrl('whatsapp-extension')}
              > acesse esse artigo da nossa central de ajuda.</ExternalLink>
            </p>

            <div className='d-flex justify-content-end'>
              <Button variant='outline-primary' href={funnelsPath()}>
                Acesse sua conta
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Main >
  );
}

export default Whatsapp;
