import React, { createContext, useContext, useState } from 'react';
import ModalPage from '@/components/ModalPage';

const AutomationContext = createContext({});

function AutomationsModal() {
  const [initialValues, setInitialValues] = useState({});

  return (
    <AutomationContext.Provider value={{ initialValues, setInitialValues }}>
      <ModalPage />
    </AutomationContext.Provider>
  );
}

export function useAutomationContext() {
  return useContext(AutomationContext);
}

export default AutomationsModal;
