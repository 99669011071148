import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import APIErrorList from '@/components/APIErrorList';
import strings from '@/strings';
import get from 'lodash/get';
import { HTTP_STATUSES } from '@/errors';

const propTypes = {
  err: PropTypes.instanceOf(Error).isRequired,
  action: PropTypes.string,
  resource: PropTypes.string,
  target: PropTypes.string,
  customContent: PropTypes.string
};

const defaultProps = {
  customContent: null
};

function APIErrorMessage({ err, action, resource, target, customContent }) {
  const { status, data: { errors } = {} } = err;
  let title, content;

  const actionMessages = useMemo(() => get(strings.actions, resource, {}), [resource]);
  const actionDescription = actionMessages[action] || 'realizar essa ação';

  switch (status) {
    case HTTP_STATUSES.FORBIDDEN:
    case HTTP_STATUSES.UNPROCESSABLE_ENTITY:
      if (status === HTTP_STATUSES.FORBIDDEN && containsPrivilegeError(errors)) {
        title = `Você não tem permissão para ${actionDescription}`;
        content = 'Solicite permissão ao administrador da conta.';
      } else {
        title = `Não foi possível ${actionDescription}`;
        content =
          <APIErrorList errors={errors} status={status} scope={target || resource} />;
      }

      break;

    default:
      title = `Não foi possível ${actionDescription}`;
      content = customContent ?? `Código do erro: ${status || '000'}`;
  }

  return (
    <div>
      <h6>{title}</h6>

      {content}
    </div>
  );
}

function containsPrivilegeError(errors) {
  return errors && errors.user && errors.user.includes('no_privilege');
}

APIErrorMessage.propTypes = propTypes;
APIErrorMessage.defaultProps = defaultProps;

export default APIErrorMessage;
