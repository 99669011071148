import { useMutation, useQueryClient } from 'react-query';
import { funnelStagesKeys, funnelsKeys, stagesKeys } from '@/api';
import { post } from '@/lib/fetch';

export async function createStageDealsRelocation({ stageId, params = {} }) {
  const { data } = await post(`/stages/${stageId}/deals_relocation`, params);

  return data;
}

export async function createFunnelDealsRelocation({ funnelId, params = {} }) {
  const { data } = await post(`/funnels/${funnelId}/deals_relocation`, params);

  return data;
}

export function useCreateStageDealsRelocation({ funnelId, stageId, config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await Promise.all([
      queryClient.invalidateQueries(funnelStagesKeys.funnelList(funnelId)),
      queryClient.invalidateQueries(funnelsKeys.all),
      queryClient.invalidateQueries(funnelsKeys.detail(funnelId))
    ]);

    queryClient.removeQueries(stagesKeys.detail(stageId));
    queryClient.removeQueries(stagesKeys.all);
    queryClient.removeQueries(funnelStagesKeys.infiniteStageItems(stageId));
  };

  return useMutation(
    (params) => createStageDealsRelocation({ stageId, params }),
    {
      onSuccess,
      ...config
    }
  );
}

export function useCreateFunnelDealsRelocation({ funnelId, config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await Promise.all([
      queryClient.invalidateQueries(funnelStagesKeys.funnelList(funnelId)),
      queryClient.invalidateQueries(funnelsKeys.all)
    ]);

    queryClient.removeQueries(funnelsKeys.detail(funnelId));
  };

  return useMutation(
    (params) => createFunnelDealsRelocation({ funnelId, params }),
    {
      onSuccess,
      ...config
    }
  );
}
