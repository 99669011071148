import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import emptyListCustomersImage from 'images/empty-list-customers.png';
import emptyListDealsImage from 'images/empty-list-deals.png';
import emptyFilterImage from 'images/empty-filter.png';
import emptySearchImage from 'images/empty-search.png';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import { LIST_TYPE, getString } from '@/utils';

const propTypes = {
  variant: PropTypes.oneOf(Object.values(LIST_TYPE)).isRequired,
  entity: PropTypes.oneOf(['person', 'organization', 'deal']).isRequired,
  buttonAddPath: PropTypes.string,
  className: PropTypes.string,
  show: PropTypes.bool,
  onClearSearch: PropTypes.func,
  onClearFilter: PropTypes.func,
  onOpenFilter: PropTypes.func
};

const defaultProps = {
  variant: LIST_TYPE.LIST,
  entity: 'person',
  className: '',
  show: false,
  onClearSearch: () => {},
  onClearFilter: () => {},
  onOpenFilter: () => {}
};

const isDeal = (entity) => entity === 'deal';

function EmptyList({ entity, buttonAddPath }) {
  const image = isDeal(entity) ? emptyListDealsImage : emptyListCustomersImage;
  const title = isDeal(entity) ? 'Crie oportunidade de vendas' : 'Monte sua carteira de clientes';
  const entityName = getString(['models', entity]);

  return (
    <div className='d-flex flex-column text-center justify-content-center'>
      <img className='width-4 mx-auto mb-4' src={image} />
      <h4 className='mb-4'>{title}</h4>
      <p className='text-dark-gray'>
        Você ainda não cadastrou {entityName}s, que tal começar
        <br />
        adicionando agora mesmo!
      </p>
      <div>
        <Button
          size='sm'
          href={buttonAddPath}
          aria-label={`Adicionar ${entityName}`}
          className='me-2'
        >
          <Icon name='add' className='me-1' size='sm' />
          Adicionar {entityName}
        </Button>
      </div>
    </div>
  );
}

function EmptyFilter({ entity, onOpenFilter, onClearFilter }) {
  const entityName = getString(['models', entity]);
  return (
    <div className='d-flex flex-column text-center justify-content-center'>
      <img className='width-4 mx-auto mb-4' src={emptyFilterImage} />
      <h4 className='mb-4'>Não encontramos resultados</h4>
      <p className='text-dark-gray'>
        Não existe dados de {entityName} para os filtros aplicados,
        <br />
        experimente alterar os filtros ativos.
      </p>
      <div>
        <Button
          size='sm'
          onClick={onOpenFilter}
          aria-label='Alterar filtros'
          className='me-2'
        >
          <Icon name='filter' className='me-1' size='sm' />
          <span>Alterar filtros</span>
        </Button>
        <Button
          size='sm'
          variant='light'
          onClick={onClearFilter}
          aria-label='Limpar filtros'
        >
          <Icon name='close' className='me-1' size='sm' />
          <span>Limpar filtros</span>
        </Button>
      </div>
    </div>
  );
}

function EmptySearch({ entity, buttonAddPath, onClearSearch }) {
  const entityName = getString(['models', entity]);
  return (
    <div className='d-flex flex-column text-center justify-content-center'>
      <img className='width-4 mx-auto mb-4' src={emptySearchImage} />
      <h4 className='mb-4'>Nenhum resultado na busca</h4>
      <p className='text-dark-gray'>
        Busque por outro termo ou adicione
        <br />
        um novo cadastro de {entityName}
      </p>
      <div>
        <Button
          size='sm'
          href={buttonAddPath}
          aria-label={`Adicionar ${entityName}`}
          className='me-2'
        >
          <Icon name='add' className='me-1' size='sm' />
          <span>Adicionar {entityName}</span>
        </Button>
        <Button
          size='sm'
          variant='light'
          onClick={onClearSearch}
          aria-label='Limpar busca'
        >
          <Icon name='close' className='me-1' size='sm' />
          <span>Limpar busca</span>
        </Button>
      </div>
    </div>
  );
}

function EntityEmpty(props) {
  const { variant, entity, show, className } = props;
  const VARIANTS = {
    [LIST_TYPE.LIST]: <EmptyList entity={entity} { ...props } />,
    [LIST_TYPE.FILTER]: <EmptyFilter entity={entity} { ...props } />,
    [LIST_TYPE.SEARCH]: <EmptySearch entity={entity} { ...props } />
  };

  if (show) {
    return (
      <div
        id='entity-empty'
        className={classnames(
          className,
          'd-flex',
          'align-items-center',
          'justify-content-center',
          'overflow-hidden',
          'height-5'
        )}
      >
        {VARIANTS[variant]}
      </div>
    );
  }
  return null;
}


EntityEmpty.propTypes = propTypes;
EntityEmpty.defaultProps = defaultProps;

export default EntityEmpty;
