import React from 'react';
import PropTypes from 'prop-types';
import { getString, pluralize } from '@/utils';

const propTypes = {
  show: PropTypes.bool,
  model: PropTypes.string.isRequired,
  count: PropTypes.number,
  max: PropTypes.number
};

const defaultProps = {
  show: false,
  model: '',
  count: 0,
  max: 0
};

function PaginationCounter({ show, model, count, max }) {
  if (show) {
    const modelName = getString(['models', model]);
    return (
      <span className='ms-2 w-100 text-darker-gray fw-bold'>
        Exibindo {count} de {max} {pluralize(modelName, max)}
      </span>
    );
  }
  return null;
}

PaginationCounter.propTypes = propTypes;
PaginationCounter.defaultProps = defaultProps;

export default PaginationCounter;
