import React from 'react';
import PropTypes from 'prop-types';
import LoadSpinner from '@/components/LoadSpinner';

const propTypes = {
  isFetching: PropTypes.bool,
  isEmpty: PropTypes.bool
};

const defaultProps = {
  isFetching: false,
  isEmpty: false
};

function FooterStatus({ isFetching, isEmpty }) {
  let text = null;
  let icon = null;

  if (isFetching) {
    text = 'Carregando';
    icon = <LoadSpinner className='me-1 text-dark-gray opacity-100' />;
  } else if (isEmpty) {
    text = 'Nenhum resultado encontrado';
    icon = null;
  } else {
    return null;
  }

  return (
    <div className='d-flex align-items-center justify-content-center p-5'>
      {icon}

      <span className='text-dark-gray fw-bold'>
        {text}
      </span>
    </div>
  );
}

FooterStatus.propTypes = propTypes;
FooterStatus.defaultProps = defaultProps;

export default FooterStatus;
