import { useQuery, useMutation, useQueryClient } from 'react-query';
import { get, post, patch, _delete } from '@/lib/fetch';
import { updateListItem } from '@/utils';

export const funnelsKeys = {
  all: ['funnels'],
  lists: () => [...funnelsKeys.all, 'list'],
  list: (params) => [...funnelsKeys.lists(), params],
  details: () => [...funnelsKeys.all, 'detail'],
  detail: (funnelId) => [...funnelsKeys.details(), funnelId],
  defaultFunnel: () => [...funnelsKeys, 'default']
};

export async function getFunnels(params = {}) {
  const { data } = await get('/funnels', params);

  return data;
}

export async function getFunnel(funnelId) {
  const { data } = await get(`/funnels/${funnelId}`);

  return data;
}

export async function getDefaultFunnel() {
  const { data } = await get('/funnels/default');

  return data;
}

export async function createFunnel(params = {}) {
  const { data } = await post('/funnels', params);

  return data;
}

export async function updateFunnel({ funnelId, params = {} }) {
  const { data } = await patch(`/funnels/${funnelId}`, params);

  return data;
}

export async function deleteFunnel({ funnelId }) {
  const { data } = await _delete(`/funnels/${funnelId}`);

  return data;
}

export function useFunnels({ params = {}, config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: funnelsKeys.list(params),
    queryFn: () => getFunnels(params)
  });
}

export function useFunnel({ funnelId, config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: funnelsKeys.detail(funnelId),
    queryFn: () => getFunnel(funnelId)
  });
}

export function useDefaultFunnel({ config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: funnelsKeys.defaultFunnel,
    queryFn: () => getDefaultFunnel()
  });
}

export function useCreateFunnel({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries(funnelsKeys.all);

  return useMutation(
    createFunnel,
    {
      onSuccess,
      ...config
    }
  );
}

export function useUpdateFunnel({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries(funnelsKeys.all);

  const onError = (_err, _params, rollback) => rollback();

  const onMutate = ({ funnelId, params = {} }) => {
    queryClient.cancelQueries(funnelsKeys.list({}));

    const previousFunnels = queryClient.getQueryData(funnelsKeys.list({}));

    queryClient.setQueryData(
      funnelsKeys.list({}),
      (old) => ({ data: updateListItem(old.data, funnelId, params) })
    );

    return () => queryClient.setQueryData(funnelsKeys.list({}), previousFunnels);
  };

  return useMutation(
    updateFunnel,
    {
      onSuccess,
      onError,
      onMutate,
      ...config
    }
  );
}

export function useDeleteFunnel({ funnelId, onDelete, config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await Promise.all([
      queryClient.invalidateQueries(funnelsKeys.all),
      queryClient.removeQueries(funnelsKeys.detail(funnelId))
    ]);

    /*
     * Nas listagens, quando um item é removido, o componente que o exibe é desmontado
     * e, com isso, o side-effect `onSuccess` que pode ser passado na chamada a função `mutate`
     * não é executado. Para contornar isso, esse callback `onDelete` pode ser passado na montagem
     * da mutation e será chamado nos casos de "success".
     *
     * Ref do problema: https://react-query.tanstack.com/guides/mutations#mutation-side-effects
     */
    if (onDelete) {
      onDelete();
    }
  };

  return useMutation(
    () => deleteFunnel({ funnelId }),
    {
      onSuccess,
      ...config
    }
  );
}
