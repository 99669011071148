import React from 'react';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import classnames from 'classnames';

const propTypes = {
  onClose: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

const defaultProps = {
  disabled: false
};

function OverlayClose({ onClose, disabled }) {
  return (
    <Button
      className={classnames(
        'position-absolute',
        'end-0',
        'top-0',
        'rounded',
        'border-0',
        'px-2',
        'py-1',
        'mt-2',
        'mt-n8'
      )}
      variant='dark'
      type='button'
      aria-label='Fechar'
      onClick={onClose}
      disabled={disabled}
    >
      <Icon className='text-white' name='close' />
    </Button>
  );
}

OverlayClose.propTypes = propTypes;
OverlayClose.defaultProps = defaultProps;

export default OverlayClose;
