import React from 'react';
import { Link as Base, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const propTypes = {
  href: PropTypes.string.isRequired
};

const Link = React.forwardRef(
  (props, ref) => {
    const { children, href, state, ...rest } = props;
    const location = useLocation();

    return (
      <Base
        { ...rest }
        ref={ref}
        to={href}
        state={{ ...state, previous: location }}
      >
        {children}
      </Base>
    );
  }
);

Link.propTypes = propTypes;
Link.displayName = 'Link';

export default Link;
