import React, { useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import Form from '@/components/Form';
import Collapse from '@/components/Collapse';
import CollapseToggle from '@/components/CollapseToggle';
import Button from '@/components/Button';
import LoadingButton from '@/components/LoadingButton';
import {
  AddressFields,
  CategorySelect,
  LeadOriginSelect,
  MaskedInput,
  PeopleFields,
  TextArea,
  TextInput,
  UserSelect,
  WhatsAppInput,
  CNPJField
} from '@/components/Inputs';
import FormField from '@/components/FormField';
import AsyncFormField from '@/components/AsyncFormField';
import {
  schema as addressSchema, defaultValues as addressDefaultValues
} from '@/components/Inputs/AddressFields';
import { schema as peopleSchema } from '@/components/Inputs/PeopleFields';
import { schema as privacySchema } from '@/components/Inputs/PrivacyInput';
import { useAuth } from '@/lib/auth';
import { useOrganizationValidation } from '@/api';
import { customFieldsSchema, CustomFieldsSection } from '@/components/CustomFields';

const propTypes = {
  initialData: PropTypes.shape({
    name: PropTypes.string
  }),
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  customFields: PropTypes.array,
  className: PropTypes.string
};

const defaultProps = {
  initialData: { name: '' },
  customFiels: [],
  onSubmit: () => { },
  onCancel: () => { },
  className: ''
};

/* eslint-disable no-magic-numbers */
const schema = Yup.object().shape({
  name: Yup.string()
    .max(150, 'O nome da empresa deve ter no máximo 150 caracteres.')
    .required('Por favor, informe o nome da empresa.')
    .when('name_async', ([value], fieldSchema) => (
      value
        ? fieldSchema.notOneOf([value], 'Já existe uma empresa com esse nome.')
        : fieldSchema
    )),
  legal_name: Yup.string()
    .max(255, 'A razão social da empresa deve ter no máximo 255 caracteres.'),
  cnpj: Yup.string()
    .length(14, 'CNPJ inválido.')
    .when('cnpj_async', ([value], fieldSchema) => (
      value
        ? fieldSchema.notOneOf([value], 'Já existe uma empresa com esse CNPJ.')
        : fieldSchema
    )),
  website: Yup
    .string()
    .max(150, 'O website da empresa deve ter no máximo 150 caracteres.'),
  description: Yup
    .string()
    .max(700, 'A descrição da empresa deve ter no máximo 700 caracteres.'),
  owner_user_id: Yup.string().nullable(),
  contact: Yup.object().shape({
    email: Yup.string().email('E-mail inválido.'),
    whatsapp: Yup.string()
      .min(12, 'Número inválido.')
      .max(16, 'Número inválido.'),
    work_phone: Yup.string()
      .min(10, 'Número inválido.')
      .max(11, 'Número inválido.')
  }),
  privacy: privacySchema,
  address: addressSchema,
  people: peopleSchema,
  custom_fields: customFieldsSchema
});
/* eslint-enable no-magic-numbers */

const defaultValues = {
  name: '',
  cnpj: '',
  legal_name: '',
  website: '',
  description: '',
  owner_user_id: '',
  category_id: '',
  lead_origin_id: '',
  privacy: {
    type: 'default'
  },
  contact: {
    email: '',
    whatsapp: '',
    work_phone: ''
  },
  ...addressDefaultValues,
  people: [],
  custom_fields: {}
};

function CreateOrganizationForm({ initialData, onSubmit, onCancel, className, customFields }) {
  const { user } = useAuth();

  const initialValues = useMemo(() => {
    const { name } = initialData;
    return { name, owner_user_id: user.id };
  }, [initialData, user]);

  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = () => setShowMore((prev) => !prev);
  const websiteRef = useRef();
  const onShowMore = () => {
    if (websiteRef.current) {
      websiteRef.current.focus();
    }
  };

  return (
    <Form
      name='create-organization-form'
      initialValues={initialValues}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      validationSchema={schema}
      validationContext={{ customFields }}
    >
      {({ handleSubmit, handleBlur, isSubmitting }) => (
        <div className={className}>
          <Row>
            <Col sm={12}>
              <AsyncFormField
                as={TextInput}
                query={useOrganizationValidation}
                name='name'
                label='Empresa'
                autoComplete='off'
                placeholder='Nome da empresa'
                maxLength={150}
                variant='light'
                onBlur={handleBlur}
              />
            </Col>
          </Row>

          <h4 className='my-4'>
            Dados da empresa
          </h4>

          <Row className='gx-3'>
            <Col sm={6}>
              <CNPJField autoFocus onAutoFill={() => setShowMore(true)} />
            </Col>
            <Col sm={6}>
              <FormField
                as={TextInput}
                name='legal_name'
                label='Razão social'
                autoComplete='off'
                placeholder='Digite a razão social'
                variant='light'
                onBlur={handleBlur}
              />
            </Col>
          </Row>

          <Row className='gx-3'>
            <Col sm={6}>
              <FormField
                as={MaskedInput}
                maskType='phone'
                name='contact.work_phone'
                label='Telefone'
                autoComplete='off'
                placeholder='(00) 0000-0000'
                variant='light'
                onBlur={handleBlur}
              />
            </Col>
            <Col sm={6}>
              <FormField
                as={WhatsAppInput}
                name='contact.whatsapp'
                label='WhatsApp'
                autoComplete='off'
                placeholder='+00 00 00000-0000'
                onBlur={handleBlur}
              />
            </Col>
          </Row>

          <Row className='gx-3'>
            <Col sm={12}>
              <FormField
                as={MaskedInput}
                name='contact.email'
                maskType='email'
                label='E-mail'
                autoComplete='off'
                placeholder='exemplo@email.com'
                variant='light'
                onBlur={handleBlur}
              />
            </Col>
          </Row>

          <CustomFieldsSection
            fields={customFields}
            section='basic_data'
            perRow
            className='gx-3'
          />
          <CustomFieldsSection
            fields={customFields}
            section='contact'
            perRow
            className='gx-3'
          />
          <CustomFieldsSection
            fields={customFields}
            section='address'
            perRow
            className='gx-3'
          />

          <Collapse
            in={showMore}
            onEntered={onShowMore}
          >
            <div>
              <Row className='gx-3'>
                <Col sm={6}>
                  <FormField
                    as={TextInput}
                    name='website'
                    label='Website'
                    autoComplete='off'
                    placeholder='www.exemplo.com'
                    variant='light'
                    ref={websiteRef}
                    onBlur={handleBlur}
                  />
                </Col>
                <Col sm={6}>
                  <FormField
                    as={UserSelect}
                    name='owner_user_id'
                    label='Responsável'
                    placeholder='Selecione'
                  />
                </Col>
              </Row>

              <Row className='gx-3'>
                <Col sm={6}>
                  <FormField
                    as={CategorySelect}
                    name='category_id'
                    label='Categoria'
                    placeholder='Selecione'
                  />
                </Col>
                <Col sm={6}>
                  <FormField
                    as={LeadOriginSelect}
                    name='lead_origin_id'
                    label='Origem'
                    placeholder='Selecione'
                  />
                </Col>
              </Row>

              <Row>
                <Col sm={12}>
                  <FormField
                    as={TextArea}
                    name='description'
                    label='Descrição'
                    placeholder='Escreva detalhes importantes sobre essa empresa'
                    variant='light'
                    onBlur={handleBlur}
                  />
                </Col>
              </Row>

              <h4 className='my-4'>
                Dados de endereço
              </h4>

              <AddressFields />
            </div>
          </Collapse>

          <CollapseToggle
            open={showMore}
            onToggle={toggleShowMore}
            closedLabel='Cadastrar mais dados'
            openLabel='Ver menos dados'
          />

          <hr />

          <h4 className='my-4'>
            Pessoas da empresa
          </h4>

          <PeopleFields />

          <Row className='mt-8'>
            <Col sm={6}>
              <Button
                className='w-100'
                variant='outline-dark-gray'
                onClick={onCancel}
                disabled={isSubmitting}
              >
                Cancelar
              </Button>
            </Col>
            <Col sm={6}>
              <LoadingButton
                isLoading={isSubmitting}
                className='w-100'
                variant='primary'
                onClick={handleSubmit}
              >
                Salvar e continuar
              </LoadingButton>
            </Col>
          </Row>
        </div>
      )}
    </Form>
  );
}

CreateOrganizationForm.propTypes = propTypes;
CreateOrganizationForm.defaultProps = defaultProps;

export default CreateOrganizationForm;
