import React, { createContext, useContext } from 'react';

const PopoverContext = createContext({
  container: null
});

export function usePopover() {
  const context = useContext(PopoverContext);

  if (context === undefined) {
    throw new Error('usePopover must be used within PopoverProvider');
  }

  return context;
}

export function PopoverProvider({ children, value }) {
  return (
    <PopoverContext.Provider value={value}>
      {children}
    </PopoverContext.Provider>
  );
}
