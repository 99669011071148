import React from 'react';
import PropTypes from 'prop-types';
import Link from '@/components/Link';
import ExternalLink from '@/components/ExternalLink';
import {
  dealPath,
  organizationPath,
  personPath,
  legacyOrganizationUrl,
  legacyPersonUrl,
  legacyDealUrl
} from '@/routes';
import { useAuth } from '@/lib/auth';
import { isOptin } from '@/utils';


const propTypes = {
  entity: PropTypes.shape({
    type: PropTypes.oneOf(['organization', 'person', 'deal']),
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }),
  origin: PropTypes.string
};

function EntityLink({ entity, origin, ...props }) {
  let Component, href, target;
  const { user } = useAuth();

  switch (entity?.type) {
    case 'organization':
      if (isOptin(user?.features?.organizationModalEnabled)) {
        Component = Link;
        href = organizationPath(entity.id);
      } else {
        target = '_blank';
        Component = ExternalLink;
        href = legacyOrganizationUrl(entity.id, true);

        if (origin) {
          href = `${href}&origin=${origin}`;
        }
      }

      break;

    case 'person':
      if (isOptin(user?.features?.organizationModalEnabled)) {
        Component = Link;
        href = personPath(entity.id);
      } else {
        target = '_blank';
        Component = ExternalLink;
        href = legacyPersonUrl(entity.id, true);

        if (origin) {
          href = `${href}&origin=${origin}`;
        }
      }

      break;

    case 'deal':
      if (isOptin(user?.features?.dealModalEnabled)) {
        Component = Link;
        href = dealPath(entity.id);
      } else {
        Component = ExternalLink;
        href = legacyDealUrl(entity.id, true);
      }
      break;

    default:
      Component = ExternalLink;
  }

  return <Component { ...props } href={href ?? ''} target={target} />;
}

EntityLink.propTypes = propTypes;

export default EntityLink;
