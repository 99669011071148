import { useMutation, useQuery, useQueryClient } from 'react-query';
import { get, _delete } from '@/lib/fetch';

export const importsKeys = {
  all: ['imports'],
  lists: () => [...importsKeys.all, 'list'],
  list: (params) => [...importsKeys.lists(), params],
  details: () => ['imports', 'detail'],
  detail: (id) => [...importsKeys.details(), id]
};

export async function getImports(params = {}) {
  const { data } = await get('/imports', params);

  return data;
}

export async function deleteImport({ importId, params = {} }) {
  const { data } = await _delete(`/imports/${importId}`, params);

  return data;
}

export function useImports({ params = {}, config = {} }) {
  return useQuery({
    ...config,
    queryKey: importsKeys.list(params),
    queryFn: () => getImports(params)
  });
}

export async function getImport(importId) {
  const { data } = await get(`/imports/${importId}`);

  return data;
}

export function useImport({ importId, config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: importsKeys.detail(importId),
    queryFn: () => getImport(importId)
  });
}

export function useDeleteImport({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await Promise.all([queryClient.invalidateQueries(importsKeys.all)]);
  };

  return useMutation(
    deleteImport,
    {
      onSuccess,
      ...config
    }
  );
}
