import React, { useState } from 'react';
import FetchableSelect from '@/components/Inputs/FetchableSelect';
import { useCities } from '@/api';
import { useDebounce } from '@/hooks';
import PropTypes from 'prop-types';

const propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.array
};

const defaultProps = {
  onChange: () => {},
  value: null
};

function transformer(fetchedData) {
  return fetchedData.data.map((item) => (
    { label: `${item.name}, ${item.state}`, value: item.id }
  ));
}

function IndependentCitySelect({ onChange, value }) {
  const debounceTime = 600;
  const [inputValue, setValue] = useState(null);
  const [currentSelectedOptions, setCurrentSelectedOptions] = useState([]);

  const handleInputChange = useDebounce(
    (debouncedInputValue) => {
      if (debouncedInputValue) {
        setValue(debouncedInputValue);
      }
    },
    debounceTime
  );

  const handleOnChange = (currentValue, { option }) => {
    setCurrentSelectedOptions(option || []);
    onChange(currentValue, option);
  };

  return (
    <FetchableSelect
      multiple
      isClearable
      isSearchable
      autoAdjustValues
      value={value}
      onChange={handleOnChange}
      placeholder='Qualquer cidade'
      transformer={transformer}
      query={useCities}
      defaultOptions={currentSelectedOptions}
      defaultQueryParams={{ name_start: inputValue }}
      queryArgs={{ params: { per_page: 60 } }}
      onInputChange={handleInputChange}
    />
  );
}

IndependentCitySelect.propTypes = propTypes;
IndependentCitySelect.defaultProps = defaultProps;

export default IndependentCitySelect;
