/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect } from 'react';

export function useFirstRenderEffect(fn, deps) {
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      fn();

      isFirstRender.current = false;
    }
  }, deps);
}
