import { useQuery } from 'react-query';
import { get } from '@/lib/fetch';

export const privacyCandidatesKeys = {
  all: ['privacy-candidates'],
  lists: () => [...privacyCandidatesKeys.all, 'list'],
  list: (params) => [...privacyCandidatesKeys.lists(), params]
};

export async function getPrivacyCandidates(params = {}) {
  const { data } = await get('/privacy_candidates', params);

  return data;
}

export function usePrivacyCandidates({ params = {}, config = {} }) {
  return useQuery({
    ...config,
    queryKey: privacyCandidatesKeys.list(params),
    queryFn: () => getPrivacyCandidates(params)
  });
}
