import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FileInput, DragDrop } from '@uppy/react';
import prettierBytes from 'prettier-bytes';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import classnames from 'classnames';
import { useUppy, createUppy } from '@/lib/uppy';

const propTypes = {
  initialFile: PropTypes.object,
  onChange: PropTypes.func
};

const defaultProps = {
  onChange: () => {}
};

const ERROR_VARIANTS = {
  default: {
    title: 'Arquivo não compatível com nosso sistema',
    iconName: 'close',
    iconVariant: 'danger'
  },
  quantity: {
    title: 'Importe um arquivo de cada vez',
    iconName: 'close',
    iconVariant: 'danger'
  },
  size: {
    title: 'O arquivo excede o tamanho permitido de 1 MB',
    iconName: 'close',
    iconVariant: 'danger'
  },
  type: {
    title: 'Arquivo não compatível com nosso sistema',
    subtitle: 'O formato deve ser .xlsx',
    iconName: 'close',
    iconVariant: 'danger'
  },
  upload: {
    title: 'Ocorreu um erro ao enviar o arquivo',
    iconName: 'alert',
    iconVariant: 'dark-gray'
  }
};

function SpreadsheetUploader({ initialFile, onChange }) {
  const [selectedFile, setSelectedFile] = useState(initialFile);
  const [error, setError] = useState();

  const uppy = useUppy(() => {
    const newUppy = createUppy({
      allowedFileTypes: ['.xlsx'],
      maxFileSizeMega: 1,
      locale: {
        strings: {
          youCanOnlyUploadX: 'quantity',
          exceedsSize: 'size',
          youCanOnlyUploadFileTypes: 'type'
        }
      }
    });

    newUppy
      .on('upload-success', (uploadedFile) => {
        const file = newUppy.getFile(uploadedFile.id);
        setSelectedFile(file);
        onChange(file);
        setError();
      })
      .on('upload-error', (file) => {
        newUppy.removeFile(file.id);

        setError('upload');
      })
      .on('restriction-failed', (_file, err) => {
        setError(err.message);
      });

    return newUppy;
  });

  const onRemove = () => {
    uppy.reset();

    setSelectedFile();
    onChange();
  };

  const renderUploadTip = () => (
    <>
      <Icon className='text-dark-gray' name='upload' size='lg' />

      <div className='fw-bold text-dark-gray mt-3'>
        Arraste e solte aqui ou clique no botão para
        <br/>
        selecionar um arquivo do seu computador...
      </div>

      <Button
        as='label'
        className='mt-3 mb-0 btn-sm position-relative z-index-1'
      >
        <FileInput inputName='spread-sheet' uppy={uppy} pretty={false} />
        Carregar arquivo
      </Button>

      <div className='text-small text-dark-gray mt-3'>
        Formato do arquivo deve ser .xlsx e ter até 1MB. <br />
        Se preferir, baixe nossa planilha de exemplo.
      </div>
    </>
  );

  const renderFileInfo = () => {
    const fileName = getFileName(selectedFile);
    const fileSize = getFileSize(selectedFile);

    return (
      <>
        <Icon className='text-flat-green' name='check' size='lg' />

        <div className='text-dark-gray mt-3'>{fileName}</div>
        <div className='text-dark-gray'>{fileSize}</div>

        <Button
          variant='link'
          className='text-dark-gray mt-3 p-0 position-relative z-index-1'
          onClick={onRemove}
        >
          <u>Remover</u>
        </Button>
      </>
    );
  };

  const renderErrorMessage = () => {
    const {
      title, subtitle, iconName, iconVariant
    } = ERROR_VARIANTS[error] || ERROR_VARIANTS.default;

    return (
      <>
        <Icon className={`text-${iconVariant}`} name={iconName} size='lg' />

        <div className='fw-bold text-dark-gray mt-3'>
          {title}
        </div>

        {subtitle && (
          <div className='text-dark-gray mt-3'>
            {subtitle}
          </div>
        )}

        <Button
          as='label'
          className='mt-3 mb-0 btn-sm position-relative z-index-1'
        >
          <FileInput inputName='spread-sheet' uppy={uppy} pretty={false} />
          Carregar novamente
        </Button>
      </>
    );
  };

  const renderContent = () => {
    if (error) {
      return renderErrorMessage();
    }

    return selectedFile ? renderFileInfo() : renderUploadTip();
  };

  return (
    <div
      className={classnames(
        'spread-sheet-uploader',
        'height-5',
        'd-flex',
        'align-items-center',
        'justify-content-center',
        'rounded',
        'position-relative'
      )}
    >
      <div className='text-center'>
        {renderContent()}
      </div>

      <DragDrop
        className={classnames(
          'position-absolute',
          'start-0',
          'top-0',
          'w-100',
          'h-100',
          { 'pe-none': Boolean(selectedFile) }
        )}
        uppy={uppy}
      />
    </div>
  );
}

function getFileSize(file) {
  const fileSize = prettierBytes(file.size);

  return fileSize;
}

function getFileName(file) {
  const fileName = file.meta.name || file.meta.key?.split('/').pop();

  return fileName;
}

SpreadsheetUploader.propTypes = propTypes;
SpreadsheetUploader.defaultProps = defaultProps;

export default SpreadsheetUploader;
