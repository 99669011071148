import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DotList from '@/components/DotList';
import EntityIcon from '@/components/EntityIcon';
import Tooltip from '@/components/Tooltip';
import Icon from '@/components/Icon';
import { compact } from '@/array';

const relatedEntityShape = PropTypes.shape({
  name: PropTypes.string.isRequired
});

const propTypes = {
  onSelect: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    allowed: PropTypes.bool,
    data: PropTypes.shape({
      name: PropTypes.string,
      title: PropTypes.string,
      value: PropTypes.number,
      organization: relatedEntityShape,
      person: relatedEntityShape,
      category: relatedEntityShape
    })
  }).isRequired
};

const defaultProps = {};

function Item({ item, onSelect }) {
  const {
    id, type, allowed,
    data: { name, title, organization, person, category }
  } = item;

  const onClick = () => {
    if (allowed && onSelect) {
      onSelect({ type, id, name });
    }
  };

  return (
    <div
      className={classnames(
        'search-result-item',
        'd-flex',
        'flex-row',
        'text-body',
        'hover-bg-light',
        'p-2',
        'rounded',
        'border-bottom',
        'border-light',
        'border-1',
        { 'cursor-pointer': allowed }
      )}
      onClick={onClick}
    >
      <EntityIcon
        className={classnames('me-3', { 'opacity-50': !allowed })}
        type={type} data={item.data}
      />

      <div className={classnames(
        'd-flex',
        'flex-column',
        'justify-content-center',
        { 'opacity-50': !allowed }
      )}>
        <span>
          {name || title}
        </span>

        <DotList
          className='text-muted'
          items={compact([
            person && person.name,
            organization && organization.name,
            category && category.name
          ])}
        />
      </div>

      <div className='width-2 d-flex align-items-center ms-auto flex-shrink-0'>
        {!allowed &&
          <Tooltip
            placement='right'
            content={
              <span className='fw-normal'>
                <h6 className='mb-2'>
                  Esse registro possui privacidade fechada.
                </h6>

                <p className='mb-2'>
                  Isso significa que apenas os responsáveis por esse cliente
                  podem acessar seus dados.
                </p>

                <p className='mb-0'>
                  Para obter acesso, solicite ao seu gestor.
                </p>
              </span>
            }
          >
            <div className='ms-1'>
              <Icon name='lock' size='sm' />
            </div>
          </Tooltip>
        }
      </div>
    </div>
  );
}

Item.propTypes = propTypes;
Item.defaultProps = defaultProps;

export default Item;
