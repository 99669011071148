import React from 'react';
import { useAlert } from 'react-alert';
import Main from '@/components/Main';
import BasicList from '@/components/BasicList';
import APIErrorMessage from '@/components/APIErrorMessage';
import Icon from '@/components/Icon';
import Popover from '@/components/Popover';
import Button from '@/components/Button';
import ExternalLink from '@/components/ExternalLink';
import {
  canCreateProductCategory, canUpdateProductCategory, canDeleteProductCategory
} from '@/policies';
import {
  useProductCategories, useUpdateProductCategory,
  useDeleteProductCategory, useCreateManualImport, useCreateRelocation,
  productsKeys
} from '@/api';
import { getValidationErrors } from '@/utils';
import { helpUrl } from '@/routes';

const resource = 'product_category';

function SettingsProductCategories() {
  const alert = useAlert();

  const createManualImport = useCreateManualImport({
    keys: [productsKeys.categories]
  });
  const onCreate = (formData, { setFieldError, setSubmitting }) => (
    createManualImport.mutateAsync({ type: resource, ...formData })
      .then(({ data: { count } }) => {
        alert.show(
          // Não foi usado o `pluralize` porque o verbo também muda.
          count === 1
            ? 'Foi adicionada 1 nova categoria de produtos.'
            : `Foram adicionadas ${count} novas categorias de produtos.`,
          { variant: 'success', timeout: 5000 }
        );
      })
      .catch((err) => {
        const errors = getValidationErrors(err, resource);

        Object.entries(errors).forEach(([key, message]) => {
          setFieldError(key, message);
        });

        setSubmitting(false);

        alert.show(
          <APIErrorMessage
            err={err}
            resource={resource}
            action='manualImport'
          />,
          { variant: 'danger' }
        );

        throw err;
      })
  );


  const updateProductCategory = useUpdateProductCategory();
  const onUpdate = ({ id, ...params }, { setFieldError, setSubmitting }) => (
    updateProductCategory.mutateAsync({ id, params })
      .then(() => {
        alert.show(
          'Categoria de produto atualizada com sucesso.',
          { variant: 'success', timeout: 5000 }
        );
      })
      .catch((err) => {
        const errors = getValidationErrors(err, resource);

        Object.entries(errors).forEach(([key, message]) => {
          setFieldError(key, message);
        });

        setSubmitting(false);

        alert.show(
          <APIErrorMessage
            err={err}
            resource={resource}
            action='update'
          />,
          { variant: 'danger' }
        );

        throw err;
      })
  );

  const deleteProductCategory = useDeleteProductCategory();
  const createRelocation = useCreateRelocation({
    keys: [productsKeys.categories]
  });
  const onDelete = ({ id, ...params }, { setFieldError, setSubmitting }) => {
    let promise;

    if (params.destination_id) {
      promise = createRelocation.mutateAsync({
        ...params,
        type: resource,
        origin_id: id
      });
    } else {
      promise = deleteProductCategory.mutateAsync({ id, params });
    }

    return promise
      .then(() => {
        alert.show(
          'Categoria de produto excluída com sucesso.',
          { variant: 'success', timeout: 5000 }
        );
      })
      .catch((err) => {
        const errors = getValidationErrors(err, resource);

        Object.entries(errors).forEach(([key, message]) => {
          setFieldError(key, message);
        });

        setSubmitting(false);

        alert.show(
          <APIErrorMessage
            err={err}
            resource={resource}
            action='delete'
          />,
          { variant: 'danger' }
        );

        throw err;
      });
  };

  return (
    <Main
      fluid
      flex
      overflow={false}
      className='pt-8 px-4 overflow-hidden position-relative'
    >
      <h1>
        Categorias de produtos

        <Popover
          content={() => (
            <>
              <h4 className='mb-2'>
                Descubra mais sobre categorias de produtos
              </h4>
              <p>
                Você pode ver quais categorias são as mais vendidas e aparecem
                em maior quantidade na aba de relatórios.
              </p>

              <Button
                as={ExternalLink}
                href={helpUrl('products')}
                size='sm'
                target='_blank'
              >
                <Icon name='external-link' className='me-2' size='sm' />
                <span>Saiba mais</span>
              </Button>
            </>

          )}
          placement='right-start'
        >
          <Button className='ms-2 p-0' variant='link'>
            <Icon name='help-circle' />
          </Button>
        </Popover>
      </h1>

      <p className='subtitle text-dark-gray max-width-7'>
        Adicione os nomes das categorias dos seus produtos, como grupos de itens,
        e personalize o Agendor de acordo com o seu processo comercial.
        Exemplo de categoria: Tecnologia (produtos: jogos, celulares, câmeras, etc).
      </p>

      <BasicList
        query={useProductCategories}
        onCreate={onCreate}
        onUpdate={onUpdate}
        onDelete={onDelete}
        createPolicy={canCreateProductCategory}
        updatePolicy={canUpdateProductCategory}
        deletePolicy={canDeleteProductCategory}
        resource={resource}
      />
    </Main>
  );
}

export default SettingsProductCategories;
