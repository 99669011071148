import React from 'react';
import Base from 'react-slick';
import PropTypes from 'prop-types';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  settings: PropTypes.object
};

const ReactSlider = React.forwardRef(({ children, className, settings }, ref) => (
  <Base ref={ref} className={className} {...settings}>
    {children}
  </Base>
));

ReactSlider.propTypes = propTypes;
ReactSlider.displayName = 'ReactSlider';

export default ReactSlider;
