import { useQuery, useInfiniteQuery, useQueryClient } from 'react-query';
import { get } from '@/lib/fetch';

export const funnelStagesKeys = {
  all: ['funnel-stages'],
  infiniteAll: ['infinite-funnel-stages'],
  lists: () => [...funnelStagesKeys.all, 'list'],
  funnelList: (funnelId) => [...funnelStagesKeys.lists(), funnelId],
  list: (funnelId, params) => [...funnelStagesKeys.funnelList(funnelId), params],
  infiniteAllItems: () => [...funnelStagesKeys.infiniteAll, 'items'],
  infiniteStageItems: (stageId) => [...funnelStagesKeys.infiniteAllItems(), stageId],
  infiniteItems: (stageId, params) => [...funnelStagesKeys.infiniteStageItems(stageId), params]
};

export const defaultFunnelStageParams = { frozen: false };

export const defaultFunnelStageFrozenParams = {
  frozen: true,
  sorts: 'current_deal_freezing_unfreeze_at asc'
};

export async function getFunnelStages(funnelId, params = {}) {
  const { data } = await get(`/funnels/${funnelId}/stages`, params);

  return data;
}

export async function getFunnelStageItems(funnelId, stageId, params = {}) {
  const { data } = await get(`/funnels/${funnelId}/stages/${stageId}/items`, params);

  return data;
}

export function useFunnelStages({ funnelId, params = {}, config = {} }) {
  const queryClient = useQueryClient();

  const { onSuccess = () => {}, ...restConfig } = config;

  const populateInfiniteStageItems = ({ data }) => {
    data.forEach((stage) => {
      queryClient.setQueryData(
        funnelStagesKeys.infiniteItems(stage.id, params),
        {
          pages: [
            {
              data: stage.items,
              meta: { next: stage.items.length < stage.totalCount ? 2 : undefined }
            }
          ],
          pageParams: [1]
        }
      );
    });
  };

  return useQuery({
    onSuccess: (data) => {
      populateInfiniteStageItems(data);
      onSuccess(data);
    },
    ...restConfig,
    queryKey: funnelStagesKeys.list(funnelId, params),
    queryFn: () => getFunnelStages(funnelId, params)
  });
}

export function useInfiniteFunnelStageItems({
  funnelId,
  stageId,
  params = {},
  config = {}
}) {
  return useInfiniteQuery(
    funnelStagesKeys.infiniteItems(stageId, params),
    ({ pageParam: page = 1 }) => getFunnelStageItems(funnelId, stageId, { ...params, page }),
    {
      ...config,
      getNextPageParam: (lastPage) => lastPage.meta.next
    }
  );
}
