import React from 'react';
import { useLocation } from 'react-router-dom';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Authorization from '@/components/Authorization';
import emptyActivities from 'images/empty-activities.png';
import { canCreateRequiredField } from '@/policies';

function Empty() {
  const location = useLocation();

  const label = location.state?.target?.type === 'stage'
    ? 'esta etapa'
    : 'este status';

  return (
    <div className='text-center'>
      <img className='mt-6 mb-4' src={emptyActivities} />

      <h3>
        Crie regra de campos obrigatórios
      </h3>

      <p className='mx-8 my-4 text-dark-gray'>
        Você ainda não criou nenhuma regra de campos obrigatórios para {label}.
        Que tal começar adicionando uma agora mesmo?
      </p>

      <Authorization policy={canCreateRequiredField}>
        <Button
          className='mb-4'
          href='new'
          state={location.state}
        >
          <Icon name='add' className='me-2' />
          Adicionar campo
        </Button>
      </Authorization>
    </div>
  );
}

export default Empty;
