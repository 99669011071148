import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { parseDate, toTextualDateString } from '@/date';
import { toCurrencyString } from '@/number';

import DiscountBanner from '@/components/Billing/DiscountBanner';
import Icon from '@/components/Icon';
import PeriodSelect from '@/components/Billing/PeriodSelect';

const propTypes = {
  periodicity: PropTypes.oneOf(['monthly', 'quarterly', 'half_yearly', 'yearly']).isRequired,
  finalValue: PropTypes.number.isRequired,
  expirationDate: PropTypes.string.isRequired,
  currentValuePerUser: PropTypes.number,
  finalDiscountAmount: PropTypes.number,
  withoutDiscountValue: PropTypes.number,
  defaultDiscount: PropTypes.shape({
    title: PropTypes.string,
    percentage: PropTypes.number,
    finalDiscountAmount: PropTypes.number
  }),
  customDiscount: PropTypes.shape({
    title: PropTypes.string,
    percentage: PropTypes.number,
    finalDiscountAmount: PropTypes.number
  }),
  maxUsersCount: PropTypes.number,
  isPopoverContent: PropTypes.bool,
  showPeriod: PropTypes.bool,
  showInfo: PropTypes.bool,
  monthlyInfo: PropTypes.shape({
    value: PropTypes.number,
    valuePerUser: PropTypes.number,
    valueWithoutDiscount: PropTypes.number
  })
};

const periodicityText = {
  monthly: 'mensal',
  quarterly: 'trimestral',
  half_yearly: 'semestral',
  yearly: 'anual'
};

function RowContent({ title, content }) {
  return (
    <div className='d-flex align-items-baseline justify-content-between mb-1'>
      <div className='fw-bold text-dark-gray me-8'>{title}</div>

      <div className='ms-2'>{content}</div>
    </div>
  );
}

function PaymentSummary({
  periodicity,
  currentValuePerUser,
  finalValue,
  expirationDate,
  withoutDiscountValue,
  discounts,
  maxUsersCount,
  isPopoverContent,
  showPeriod,
  showInfo,
  monthlyInfo
}) {
  const hasDiscount = (discounts || []).length > 0;

  const monthlyValuePerUserFormatted = toCurrencyString(monthlyInfo.valuePerUser);
  const currentValuePerUserFormatted = toCurrencyString(currentValuePerUser);
  const withoutDiscountValueFormatted = toCurrencyString(withoutDiscountValue);
  const expirationDateFormatted = toTextualDateString(parseDate(expirationDate));
  const finalValueFormatted = toCurrencyString(finalValue);

  return (
    <div
      id='payment-summary'
      className={
        classnames(
          'w-100',
          'text-small',
          'bg-light',
          { 'p-6 rounded': !isPopoverContent }
        )
      }
    >
      {showPeriod && (
        <>
          <h5 className='text-dark-gray'>Assinatura do plano</h5>

          <div className='mt-2'>
            <PeriodSelect />
          </div>

          <hr className='mt-4 mb-2' />
        </>
      )}

      {!isPopoverContent && showInfo &&
        <div className={classnames(
          'd-flex',
          'align-items-center',
          'mb-2',
          'mt-1',
          'py-2',
          'ps-2',
          'pe-8',
          'bg-white',
          'rounded',
          'text-dark-gray'
        )}>
          <div>
            <Icon name='info' />
          </div>
          <div className='ms-3'>
            A nota fiscal será gerada e enviada para o seu e-mail
            automaticamente em até 24 horas após a confirmação do pagamento
          </div>
        </div>
      }

      <h4 className='mt-2 mb-4'>Resumo</h4>

      <RowContent
        title={
          `${!isPopoverContent ? 'Recorrência' : 'Assinatura'} ${periodicityText[periodicity]}`
        }
        content={`Até ${expirationDateFormatted}`}
      />

      <RowContent
        title='Quantidade de usuários'
        content={maxUsersCount}
      />

      <RowContent
        title='Valor por usuário'
        content={
          <>
            { hasDiscount &&
            <span className='me-1'>
              <s>{currentValuePerUserFormatted}</s>
            </span>
            }

            <span>{monthlyValuePerUserFormatted}</span>
          </>
        }
      />

      <hr className='mt-3 mb-2 opacity-100' />

      { !isPopoverContent && hasDiscount &&
        discounts.map((discount) => (
          <DiscountBanner
            key={discount.id}
            discount={discount}
            periodicityText={periodicityText[periodicity]}
          />
        ))
      }

      { !isPopoverContent && hasDiscount &&
          <div className='d-flex justify-content-end mt-3'>
            <s>{withoutDiscountValueFormatted}</s>
          </div>
      }
      <div className='mt-2 d-flex align-items-baseline justify-content-between mb-1'>
        <h4 className='text-dark-gray'>Valor total</h4>

        {!isPopoverContent
          ? <h2 className={classnames({ 'text-success': hasDiscount })}>{finalValueFormatted}</h2>
          : <h5>{finalValueFormatted}</h5>
        }
      </div>

    </div>
  );
}

PaymentSummary.propTypes = propTypes;

export default PaymentSummary;
