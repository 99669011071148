import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { TextInput } from '@/components/Inputs';
import Icon from '@/components/Icon';
import InputAdornment from '@/components/InputAdornment';
import { toDigitsString } from '@/number';
import Button from '@/components/Button';
import ConfirmationModal from '@/components/ConfirmationModal';

const propTypes = {
  option: PropTypes.shape({
    option: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  innerRef: PropTypes.any,
  innerProps: PropTypes.object,
  dragProps: PropTypes.object,
  isDragging: PropTypes.bool,
  optionDelete: PropTypes.func.isRequired
};

const defaultProps = {
  className: '',
  innerRef: null,
  innerProps: {},
  dragProps: {},
  isDragging: false
};

function EditOptionForm(props) {
  const {
    option, innerRef, innerProps, dragProps, isDragging, optionDelete, onChange
  } = props;

  const handleOnChangeName = (vlr) => {
    const newItem = { ...option };
    // eslint-disable-next-line no-magic-numbers
    if (vlr.length <= 80) {
      newItem.option = vlr;
    }
    onChange(newItem);
  };

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div
      className={classnames(
        'd-flex',
        'align-items-start',
        'hover-parent',
        'hover-bg-light',
        'border-1',
        'border-bottom',
        { 'bg-light': isDragging }
      )}
      ref={innerRef}
      { ...innerProps }
    >
      <div className='col-sm-2 px-3 py-2 ms-2 text-dark-gray'>
        <div className={classnames(
          'py-2',
          'align-self-center',
          'd-flex',
          'align-items-center',
          'justify-content-between'
        )}>
          <span
            className='draggable'
            { ...dragProps }
          >
            <Icon
              name='drag'
              className='hover-child-text-body'
              withStroke
            />
          </span>

          <div className='ms-auto'>
            {toDigitsString(option.order, 2)}
          </div>
        </div>
      </div>
      <div className='col-sm-6 px-3 py-2'>
        <TextInput
          name='option-name'
          className='mb-0'
          value={option.option}
          placeholder='Nome da opção'
          onChange={handleOnChangeName}
          variant='transparent'
          rightAdornment={({ focus }) => (
            <InputAdornment
              alignment='right'
              className='input-edit invisible'
              onClick={focus}
            >
              <Icon className='text-primary' name='edit-field' />
            </InputAdornment>
          )}
        />
      </div>
      <div className='col-sm-4 px-0 py-2 text-center'>
        <Button
          variant='transparent-light'
          className='py-2 px-4 border-0'
          onClick={() => {
            handleOpenModal();
          }}
        >
          <Icon name='delete' className='me-2' />

          <span>Excluir</span>
        </Button>
      </div>

      <ConfirmationModal
        confirmationText='Excluir'
        show={showModal}
        onHide={handleCloseModal}
        onConfirm={() => {
          optionDelete(option);
          handleCloseModal();
        }}
      >
        <ConfirmationModal.Title>
          Deseja excluir essa opção?
        </ConfirmationModal.Title>

        <ConfirmationModal.Info>
          Todo o histórico relacionado à opção será perdido.
        </ConfirmationModal.Info>
      </ConfirmationModal>

    </div>
  );
}

EditOptionForm.propTypes = propTypes;
EditOptionForm.defaultProps = defaultProps;

export default EditOptionForm;
