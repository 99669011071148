import { useQuery } from 'react-query';
import { get } from '@/lib/fetch';

export const postalCodeLookupsKeys = {
  all: ['postal-code-lookups'],
  lists: () => [...postalCodeLookupsKeys.all, 'list'],
  list: (params) => [...postalCodeLookupsKeys.lists(), params]
};

export async function getPostalCodeLookup(params) {
  const { data } = await get('/postal_code_lookups', params);

  return data;
}

export function usePostalCodeLookup({ params = {}, config = {} }) {
  return useQuery({
    ...config,
    queryKey: postalCodeLookupsKeys.list(params),
    queryFn: () => getPostalCodeLookup(params)
  });
}
