import React, { useState } from 'react';
import classnames from 'classnames';
import { useAlert } from 'react-alert';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Main from '@/components/Main';
import InfiniteScroll from '@/components/InfiniteScroll';
import Table from '@/components/Table';
import TableCell from '@/components/TableCell';
import TableIndex from '@/components/TableIndex';
import PaginationCounter from '@/components/List/PaginationCounter';
import DragScrollable from '@/components/DragScrollable';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import LoadSpinner from '@/components/LoadSpinner';
import EntitySkeleton from '@/components/List/EntitySkeleton';
import Authorization from '@/components/Authorization';
import ConfirmationModal from '@/components/ConfirmationModal';
import ProductModal from '@/components/ProductModal';
import APIErrorMessage from '@/components/APIErrorMessage';
import { TextInput } from '@/components/Inputs';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import InputAdornment from '@/components/InputAdornment';
import ExportButton from '@/components/ExportButton';
import ImportButton from '@/components/ImportButton';
import * as Settings from '@/feature/settings';

import { canCreateProduct, canDeleteProduct, canUpdateProduct } from '@/policies';
import { useDocumentTitle } from '@/hooks';
import { useInfiniteProducts, useCreateProduct, useDeleteProduct, useUpdateProduct } from '@/api';
import { convertDateTimeString } from '@/date';
import { toCurrencyString } from '@/number';
import { getValidationErrors } from '@/utils';
import { useTracking } from '@/lib/tracking';
import { productsImportPath } from '@/routes';

const PAGE_TITLE = 'Produtos e serviços';
const PAGE_SUBTITLE = `Cadastre seus produtos e/ou serviços para poder extrair
  informações estratégicas do Agendor, tais como: Quais clientes possuem interesse
  em determinado produto? Quais produtos e serviços foram vendidos neste negócio?
`;

const defaultValues = { q: '' };

function SettingsProducts() {
  const [formParams, setFormParams] = useState({});
  const [showProductModal, setShowProductModal] = useState(false);
  const deleteMutation = useDeleteProduct();
  const [toBeDeleted, setToBeDeleted] = useState(null);
  const [isCreating, setIsCreating] = useState(true);
  const [product, setProduct] = useState();
  const createProduct = useCreateProduct();
  const updateProduct = useUpdateProduct();
  const alert = useAlert();

  useDocumentTitle(`${PAGE_TITLE} - Configurações`);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading
  } = useInfiniteProducts({
    params: formParams
  });

  const tracker = useTracking();

  const items = data?.pages.flatMap((page) => page.data);
  const totalItems = data?.pages[0].meta.count;

  const onCloseDelete = () => setToBeDeleted(null);
  const onDelete = () => {
    deleteMutation.mutate({ id: toBeDeleted.id }, {
      onSuccess: () => {
        tracker.trackProductDeleted();

        alert.show(
          'Produto excluído com sucesso',
          { variant: 'success', timeout: 5000 }
        );
        setToBeDeleted(null);
      },
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='product' action='delete' />,
          { variant: 'danger' }
        );
        setToBeDeleted(null);
      }
    });
  };

  const openModal = (mode, item) => {
    setShowProductModal(true);

    if (mode === 'create') {
      setIsCreating(true);
    }

    if (mode === 'update') {
      setIsCreating(false);
      setProduct(item);
    }
  };

  const closeModal = () => {
    setShowProductModal(false);
    setProduct();
  };

  const handleOnCancel = () => closeModal();

  const handleCreate = (formData, { setFieldError, setSubmitting }) => {
    createProduct.mutate(formData, {
      onSuccess: () => {
        tracker.trackProductCreated({ formData });

        closeModal();

        alert.show(
          'Produto adicionado com sucesso',
          { variant: 'success', timeout: 5000 }
        );
      },
      onError: (err) => {
        const errors = getValidationErrors(err, 'product');

        Object.entries(errors).forEach(([key, message]) => {
          setFieldError(key, message);
        });

        setSubmitting(false);

        alert.show(
          <APIErrorMessage err={err} resource='product' action='create' />,
          { variant: 'danger' }
        );
      }
    });
  };

  const handleUpdate = (formData, { setFieldError, setSubmitting }) => {
    updateProduct.mutate({ productId: product.id, params: formData }, {
      onSuccess: () => {
        tracker.trackProductEdited({ formData });

        closeModal();

        alert.show(
          'Produto editado com sucesso',
          { variant: 'success', timeout: 5000 }
        );
      },
      onError: (err) => {
        const errors = getValidationErrors(err, 'product');

        Object.entries(errors).forEach(([key, message]) => {
          setFieldError(key, message);
        });

        setSubmitting(false);

        alert.show(
          <APIErrorMessage err={err} resource='product' action='update' />,
          { variant: 'danger' }
        );
      }
    });
  };

  const handleSubmit = (formData) => {
    setFormParams(formData);
  };

  return (
    <Main
      fluid
      flex
      overflow={false}
      className='pt-8 px-4 overflow-hidden position-relative'
    >
      <h1>
        {PAGE_TITLE}
      </h1>

      <Settings.PageSubtitle>
        {PAGE_SUBTITLE}
      </Settings.PageSubtitle>

      <Row className='mb-4 d-flex justify-content-between align-items-baseline'>
        <Col sm={12} md={6}>
          <Form
            defaultValues={defaultValues}
            onSubmit={handleSubmit}
            name='product-search-form'
          >
            {({ debouncedSubmitForm }) => (
              <FormField
                as={TextInput}
                name='q'
                maxLength={60}
                isClearable
                onChange={debouncedSubmitForm}
                placeholder='Buscar por nome, categoria ou código'
                leftAdornment={({ focus }) => (
                  <InputAdornment alignment='left' onClick={focus}>
                    <Icon className='text-primary' name='search' />
                  </InputAdornment>
                )}
              />
            )}
          </Form>
        </Col>

        <Col sm={12} md={6} className='d-md-flex justify-content-end'>


          <ImportButton
            path={productsImportPath()}
            entity='product'
            entityNamePlural='produtos'
          />

          <ExportButton
            exportEntity='product'
            modalSize='sm'
            className='me-2'
            variant='transparent-light'
            tooltipText='Exporte os produtos em formato de planilha de Excel (.xlsx)'
            withoutOptions={true}
          />

          <Authorization policy={canCreateProduct}>
            <Button
              className='me-4'
              onClick={() => openModal('create')}
            >
              <Icon name='add' className='me-2'></Icon>

              <span>Adicionar</span>
            </Button>
          </Authorization>
        </Col>
      </Row>

      <InfiniteScroll
        length={items?.length || 0}
        loadItems={fetchNextPage}
        hasMore={hasNextPage || false}
      >
        <DragScrollable>
          <Table className='table-border-separate'>
            <thead className='position-sticky top-0 bg-white z-index-1'>
              <tr className='text-nowrap'>
                <th className={classnames(
                  'width-6',
                  'min-width-6',
                  'border-end',
                  'border-1',
                  'position-sticky',
                  'start-0',
                  'bg-white'
                )}>

                  <span className='px-3 invisible'>
                    <TableIndex index={0} />
                  </span>

                  <span className='px-3'>
                    Nome
                  </span>
                </th>
                <th>Código</th>
                <th>Preço</th>
                <th>Categoria</th>
                <th>Data de cadastro</th>
                <th>Status</th>
                <th></th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {
                isLoading && <EntitySkeleton maxCols={4} iconless/>
              }
              {
                !isLoading && items?.length && items.map((item, index) => (
                  <tr key={index}>
                    <td className={classnames(
                      'align-middle',
                      'border-end',
                      'border-1',
                      'position-sticky',
                      'start-0',
                      'bg-white'
                    )}>
                      <span className='px-3'>
                        <TableIndex index={index + 1} />
                      </span>

                      <span className='px-3'>
                        {item.name}
                      </span>
                    </td>

                    <td className='align-middle'>
                      <TableCell value={item.code} />
                    </td>

                    <td className='align-middle'>
                      {toCurrencyString(item.price)}
                    </td>

                    <td className='align-middle'>
                      <TableCell value={item.category?.name} />
                    </td>

                    <td className='align-middle'>
                      {convertDateTimeString(item.createdAt)}
                    </td>

                    <td className='align-middle text-nowrap'>
                      {
                        item.active
                          ? (
                            <div className='text-success pe-3'>
                              <Icon name='visible' className='me-3' />
                              <span>Ativo</span>
                            </div>
                          )
                          : (
                            <div className='text-medium-gray'>
                              <Icon name='hidden' className='me-3' />
                              <span>Inativo</span>
                            </div>
                          )
                      }
                    </td>

                    <td className='align-middle'>
                      <Authorization policy={canUpdateProduct}>
                        <Button
                          onClick={() => openModal('update', item)}
                          variant='link'
                          size='sm'
                          className='text-nowrap'
                        >
                          <Icon className='me-1' name='edit-field'/>

                          <span className='fw-bold'>
                          Editar
                          </span>
                        </Button>
                      </Authorization>
                    </td>

                    <td className='align-middle'>
                      <Authorization policy={canDeleteProduct}>
                        <Button
                          variant='link'
                          size='sm'
                          className='text-nowrap'
                          onClick={() => setToBeDeleted(item)}
                        >
                          <Icon className='me-1 text-danger' name='delete'/>
                          <span className='text-dark-gray fw-bold'>
                            Excluir
                          </span>
                        </Button>
                      </Authorization>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </DragScrollable>
      </InfiniteScroll>
      { isFetchingNextPage && <LoadSpinner size='lg' className={classnames(
        'd-flex',
        'text-dark-gray',
        'align-items-center',
        'justify-content-center',
        'opacity-100',
        'overflow-hidden',
        'py-3'
      )}/> }

      <div className='py-2'>
        <PaginationCounter
          show={!isLoading}
          model='product'
          count={items?.length}
          max={totalItems}
        />
      </div>

      <ProductModal
        onCancel={handleOnCancel}
        onSubmit={isCreating ? handleCreate : handleUpdate}
        product={product}
        show={showProductModal}
        isCreating={isCreating}
      />

      <ConfirmationModal
        show={Boolean(toBeDeleted)}
        onHide={onCloseDelete}
        confirmationText='Sim, excluir'
        onConfirm={onDelete}
        disabled={deleteMutation.isLoading}
      >
        <ConfirmationModal.Title>
          Você realmente deseja excluir este produto?
        </ConfirmationModal.Title>

        <ConfirmationModal.Info>
          Após confirmação, o
          produto <span className='fw-bold'>{toBeDeleted?.name}</span> será
          excluído de todos os negócios e contatos anteriores e do painel de
          relatórios.
        </ConfirmationModal.Info>
      </ConfirmationModal>
    </Main>
  );
}

export default SettingsProducts;
