import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useAlert } from 'react-alert';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Authorization from '@/components/Authorization';
import { canCreateCustomField } from '@/policies';
import CustomFieldFormModal from '@/components/CustomFieldFormModal';
import { useTracking } from '@/lib/tracking';
import { useAuth } from '@/lib/auth';

const propTypes = {
  section: PropTypes.string.isRequired,
  mutation: PropTypes.object.isRequired,
  entity: PropTypes.string.isRequired
};

const defaultProps = {};

function CreateCustomFields({ section, mutation, entity }) {
  const alert = useAlert();
  const tracker = useTracking();
  const { user } = useAuth();

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleFormSuccess = ({ data }) => {
    alert.show(
      'Campo customizado criado!',
      { variant: 'success', timeout: 5000 }
    );
    tracker.trackCustomFieldCreated({ user, entity, field: data });
    handleCloseModal();
  };

  return (
    <Row className='mb-4'>
      <Col xs={3} id={`create-custom-fields-${section}`}>
        <div className='ms-0'>
          <Authorization policy={canCreateCustomField}>
            <Button
              onClick={handleOpenModal}
              variant='link'
              className='fw-bold px-0'
            >
              <Icon
                name='add'
                size='sm'
                className='me-2'
              />
                Adicionar campo
            </Button>
          </Authorization>
        </div>
        <CustomFieldFormModal
          title='Criar campo customizado'
          show={showModal}
          section={section}
          mutation={mutation}
          initialData={{
            type: 'text'
          }}
          onFormSuccess={handleFormSuccess}
          onHide={handleCloseModal}
        />
      </Col>
    </Row>
  );
}

CreateCustomFields.propTypes = propTypes;
CreateCustomFields.defaultProps = defaultProps;

export default CreateCustomFields;
