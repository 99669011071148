import { useQuery } from 'react-query';
import { get } from '@/lib/fetch';

export const organizationCalculationsKeys = {
  all: ['organization-calculations'],
  allParams: (params) => ([...organizationCalculationsKeys.all, params])
};

export async function getOrganizationCalculations(params = {}) {
  const { data } = await get('/organization_calculations', params);

  return data;
}

export function useOrganizationCalculations({ params = {}, config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: organizationCalculationsKeys.allParams(params),
    queryFn: () => getOrganizationCalculations(params)
  });
}
