import React, { useCallback, useState } from 'react';
import { useAlert } from 'react-alert';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { useOrganizationLookup } from '@/api';
import { MaskedInput } from '@/components/Inputs';
import FormField from '@/components/FormField';
import { isNotFoundError } from '@/errors';
import ExternalLink from '@/components/ExternalLink';
import { helpUrl } from '@/routes';
import trimEnd from 'lodash/trimEnd';
import { isBlank } from '@/utils';

const propTypes = {
  autoFocus: PropTypes.bool,
  onAutoFill: PropTypes.func,
  name: PropTypes.string
};

const defaultProps = {
  autoFocus: false,
  onAutoFill: () => { },
  name: 'cnpj'
};

function getFields(namePrefix, govData, descriptionValue) {
  return [
    {
      field: `${namePrefix}legal_name`,
      value: govData.legalName
    },
    {
      field: `${namePrefix}contact.work_phone`,
      value: govData.phone
    },
    {
      field: `${namePrefix}contact.email`,
      value: govData.email
    },
    {
      field: `${namePrefix}address.postal_code`,
      value: govData.addressPostalCode
    },
    {
      field: `${namePrefix}address.country`,
      value: govData.addressCountry
    },
    {
      field: `${namePrefix}address.state`,
      value: govData.addressState
    },
    {
      field: `${namePrefix}address.district`,
      value: govData.addressDistrict
    },
    {
      field: `${namePrefix}address.street_name`,
      value: govData.addressStreetName
    },
    {
      field: `${namePrefix}address.street_number`,
      value: govData.addressNumber
    },
    {
      field: `${namePrefix}address.additional_info`,
      value: govData.addressAdditionalInfo
    },
    {
      field: `${namePrefix}address.city_id`,
      value: govData.addressCityId
    },
    {
      field: `${namePrefix}description`,
      value: `${descriptionValue && `${descriptionValue}\n\n`}${govData.summary}`
    }
  ];
}

function CNPJField({ autoFocus, onAutoFill, organizationId, name }) {
  const namePrefix = trimEnd(name, 'cnpj');
  const [paramsChanged, setParamsChanged] = useState(false);
  const [params, setParams] = useState({});
  const [found, setFound] = useState(true);
  const [foundGov, setFoundGov] = useState(false);
  const { getFieldMeta, setFieldValue, handleBlur, setFieldTouched } = useFormikContext();
  const { value, error } = getFieldMeta(name);
  const { value: descriptionValue } = getFieldMeta(`${namePrefix}description`);
  const alert = useAlert();

  const { isFetching } = useOrganizationLookup({
    params,
    config: {
      enabled: paramsChanged,
      onSuccess: ({ meta, data }) => {
        setFound(true);
        if (meta.origin === 'agendor') {
          setFieldValue(`${namePrefix}cnpj_async`, value);
        }
        if (meta.origin === 'gov') {
          setFoundGov(true);
          const { govData } = data;
          let wasFieldSkipped = false;

          const fields = getFields(namePrefix, govData, descriptionValue);

          fields.forEach(({ field, value: newValue }) => {
            const fieldMeta = getFieldMeta(field);

            const isFieldPresent = !isBlank(fieldMeta.initialValue);
            if (!isFieldPresent) {
              return;
            }

            const isFieldNotPrefilled = !fieldMeta.value;
            if (field === 'description' || isFieldNotPrefilled) {
              setFieldValue(field, newValue || '');
            } else {
              wasFieldSkipped = true;
            }
          });

          if (wasFieldSkipped) {
            alert.show(
              <div>
                <h6>Dados do CNPJ importados com sucesso!</h6>
                <div>Alguns campos não foram importados por já estarem preenchidos!</div>
              </div>,
              { variant: 'success', timeout: 5000 }
            );
          } else {
            alert.show(
              <h6>Dados do CNPJ importados com sucesso!</h6>,
              { variant: 'success', timeout: 5000 }
            );
          }
        }

        onAutoFill();
      },
      onError: (queryError) => {
        if (isNotFoundError(queryError)) {
          setFound(false);
        }
        alert.show(
          <div>
            <h6>
              Não foi possível preencher os dados com o CNPJ!
            </h6>
            <div>
              <span className='me-1'>
                Para mais detalhes, recomendamos a leitura deste
              </span>
              <ExternalLink target='_blank' href={helpUrl('cnpj-not-found')}>
                artigo.
              </ExternalLink>
            </div>
          </div>,
          { variant: 'warning', timeout: 5000 }
        );
      }
    }
  });

  const helperOnNotFoundText = (!found && paramsChanged)
    ? <span className='text-small text-dark-gray'>Não encontramos o CNPJ</span>
    : null;

  const helperOnSuccessText = (found && foundGov && paramsChanged)
    ? <span className='text-small text-success'>Dados importados com sucesso.</span>
    : null;

  const onCnpjFocus = () => {
    setFieldTouched(name, false);
  };

  const onCnpjBlur = useCallback((e) => {
    handleBlur(e);

    if (value && !error) {
      setParams({ cnpj: value, excluded_id: organizationId });
      setParamsChanged(true);
    }
  });

  return (
    <div>
      <FormField
        as={MaskedInput}
        maskType='cnpj'
        name={name}
        label='CNPJ'
        autoComplete='off'
        placeholder='00.000.000/0000-00'
        maxLength={18}
        variant='light'
        onFocus={onCnpjFocus}
        onBlur={onCnpjBlur}
        loading={isFetching}
        helperText={helperOnNotFoundText || helperOnSuccessText}
        autoFocus={autoFocus}
      />
    </div>
  );
}

CNPJField.propTypes = propTypes;
CNPJField.defaultProps = defaultProps;

export default CNPJField;
