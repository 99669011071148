import { useQuery } from 'react-query';
import { get } from '@/lib/fetch';

export const organizationLookupsKeys = {
  all: ['organization-lookups'],
  lists: () => [...organizationLookupsKeys.all, 'list'],
  list: (params) => [...organizationLookupsKeys.lists(), params]
};

export async function getOrganizationLookup(params = {}) {
  const { data } = await get('/organization_lookups', params);

  return data;
}

export function useOrganizationLookup({ params = {}, config = {} }) {
  return useQuery({
    ...config,
    queryKey: organizationLookupsKeys.list(params),
    queryFn: () => getOrganizationLookup(params)
  });
}
