import React from 'react';
import MaskedInput from '@/components/Inputs/MaskedInput';

function WhatsAppInput({ name, value, onBlur, onChange, ...props }) {
  const handleFocus = () => {
    if (value === '') {
      onChange('55');
    }
  };

  const handleBlur = (event) => {
    if (value === '+55') {
      onChange('');
    }
    onBlur?.(event);
  };

  return (
    <MaskedInput
      maskType='whatsapp'
      placeholder='+00 00 00000-0000'
      maxLength={21}
      onFocus={handleFocus}
      onBlur={handleBlur}
      name={name}
      value={value}
      onChange={onChange}
      { ...props }
    />
  );
}

export default WhatsAppInput;
