/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect } from 'react';

export function useDidUpdateEffect(fn, deps) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      fn();
    } else {
      didMountRef.current = true;
    }
  }, deps);
}
