import React from 'react';
import PropTypes from 'prop-types';
import { useCities } from '@/api';
import { STATES } from '@/components/Inputs/StateSelect';
import FetchableSelect, { defaultTransformer } from '@/components/Inputs/FetchableSelect';

const propTypes = {
  state: PropTypes.oneOfType([
    PropTypes.oneOf([...STATES, '']),
    PropTypes.arrayOf(PropTypes.oneOf(STATES))
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  allowNull: PropTypes.bool,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

const defaultProps = {
  name: 'city_id_in',
  label: 'Cidade',
  placeholder: 'Qualquer cidade',
  multiple: false,
  isClearable: true,
  isSearchable: false,
  allowNull: false,
  defaultValue: null,
  onChange: () => {},
  disabled: false
};

function withStateTransformer(fetchedData, allowNull) {
  const result = [];
  if (allowNull) {
    result.push({ label: 'Indefinido', value: -1 });
  }

  const options = fetchedData.data.map((item) => (
    { label: `${item.name}, ${item.state}`, value: item.id }
  ));

  return result.concat(options);
}

function CitySelect({ state, ...props }) {
  let queryArgs;
  let transformer = defaultTransformer;
  let overrides = {};

  if (Array.isArray(state)) {
    queryArgs = { params: { state_in: state } };
    transformer = withStateTransformer;
  } else if (state) {
    queryArgs = { params: { state_in: [state] } };
  } else {
    queryArgs = { config: { enabled: false } };
    overrides = {
      placeholder: 'Primeiro, selecione o estado',
      disabled: true
    };
  }

  return (
    <FetchableSelect
      { ...props }
      transformer={(data) => transformer(data, props.allowNull)}
      query={useCities}
      queryArgs={queryArgs}
      autoAdjustValues
      { ...overrides }
    />
  );
}

CitySelect.propTypes = propTypes;
CitySelect.defaultProps = defaultProps;

export default CitySelect;
