import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import FormField from '@/components/FormField';
import { StateSelect } from '@/components/Inputs';

function FilterState({ name }) {
  const { setFieldValue } = useFormikContext();

  return (
    <FormField
      key={name}
      name={name}
      onChange={(e) => {
        if (!e) {
          setFieldValue('city_id_in', null);
        }
      }
      }
      as={StateSelect}
      allowNull
      multiple
      isSearchable
    />
  );
}

FilterState.propTypes = {
  name: PropTypes.string.isRequired
};


export default FilterState;
