import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

const propTypes = {
  as: PropTypes.elementType.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  normalize: PropTypes.func,
  onBlur: PropTypes.func
};

const defaultProps = {
  onChange: () => {}
};

const FormField = React.forwardRef((props, ref) => {
  const { as: Component, name, onChange, normalize, ...componentProps } = props;

  const [{ value, onChange: handleChange, onBlur }, { touched, error }] = useField(name);

  const componentOnChange = useCallback((newValue, extra = {}) => {
    const normalizedValue = normalize ? normalize(newValue) : newValue;

    handleChange({ target: { name, value: normalizedValue } });
    onChange(normalizedValue, extra);
  }, [name, handleChange, onChange, normalize]);

  return (
    <Component
      ref={ref}
      onBlur={onBlur}
      { ...componentProps }
      name={name}
      value={value}
      onChange={componentOnChange}
      error={touched ? error : null}
    />
  );
});

FormField.propTypes = propTypes;
FormField.defaultProps = defaultProps;
FormField.displayName = 'FormField';

export default FormField;
