import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

const defaultProps = {
  className: 'ms-2'
};

function Status({ children, className }) {
  return (
    <div className={className}>
      {children}
    </div>
  );
}

Status.propTypes = propTypes;
Status.defaultProps = defaultProps;
Status.displayName = 'Card.Status';

export default Status;
