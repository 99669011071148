import React from 'react';
import PropTypes from 'prop-types';
import { parseDate, toDateString } from '@/date';
import { DEAL_STATUSES_KEY } from '@/utils';
import { find, sortBy } from 'lodash/';

StatusDescription.defaultProps = {
  deal: {},
  loading: true,
  className: ''
};

StatusDescription.propTypes = {
  deal: PropTypes.object,
  loading: PropTypes.bool,
  className: PropTypes.string
};

function formatDate(date) {
  return date ? toDateString(parseDate(date)) : null;
}

function StatusDescription({ deal, className, loading }) {
  if (loading) {
    return null;
  }

  const status = deal?.dealStatus?.key;
  const isLost = status === DEAL_STATUSES_KEY.lost;
  const isWon = status === DEAL_STATUSES_KEY.won;
  const lastAudits = sortBy(deal?.audits, 'finishedBy').reverse();
  const lastAuditByStatus = find(lastAudits, (lastAudit) => Boolean(lastAudit?.dealStatus));
  const userName = lastAuditByStatus?.user?.name;
  const dealLossReason = deal?.dealLossReason;
  const lossReason = dealLossReason?.lossReason;
  const finishedAt = formatDate(deal?.finishedAt);
  const byUserName = userName ? ` por ${userName}` : '';

  if (isLost && finishedAt) {
    const lossReasonTitle = lossReason?.name ? ` Motivo da perda: ${lossReason?.name}.` : '';
    return (
      <div className={className}>
        <div className='bg-light-red px-4 py-3 rounded w-100'>
          <h5 className='text-danger'>
            Esse negócio foi marcado como perdido em {finishedAt}{byUserName}.{lossReasonTitle}
          </h5>
          {
            dealLossReason?.description
              ? (
                <div className='text-small text-danger lh-1 mt-2'>
                  {dealLossReason?.description}
                </div>
              )
              : null
          }
        </div>
      </div>
    );
  }

  if (isWon && finishedAt) {
    return (
      <div className={className}>
        <div className='bg-light-green px-4 py-3 rounded w-100'>
          <h5 className='text-success'>
            Esse negócio foi marcado como ganho em {finishedAt}{byUserName}.
          </h5>
        </div>
      </div>
    );
  }

  return null;
}

export default StatusDescription;
