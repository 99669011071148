import React from 'react';
import PropTypes from 'prop-types';
import Select from '@/components/Inputs/Select';
import { STATES as STATES_UTILS } from '@/utils';

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  allowNull: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func
};

const defaultProps = {
  name: 'state_in',
  label: 'Estado',
  placeholder: 'Qualquer estado',
  multiple: false,
  allowNull: false,
  isClearable: true,
  isSearchable: false,
  defaultValue: null,
  onChange: () => { }
};

const stateAcronym = ({ acronym }) => ({ label: acronym, value: acronym });

const options = [{ label: 'Indefinido', value: 'null' }].concat(STATES_UTILS.map(stateAcronym));

export const STATES = options.slice(1).map(({ value }) => value);

function StateSelect(props) {
  return (
    <Select
      {...props}
      options={props.allowNull ? options : options.slice(1)}
    />
  );
}

StateSelect.propTypes = propTypes;
StateSelect.defaultProps = defaultProps;

export default StateSelect;
