import { ENTITIES } from '@/utils';
import {
  personColumns,
  organizationColumns,
  dealColumns,
  customFieldColumns
} from '@/components/Columns';
import {
  personEditPath,
  organizationEditPath,
  dealEditPath
} from '@/routes';

export function getDataColumnsBy(entity, customFields) {
  const fixedColumns = getFixedColumnsBy(entity);
  return fixedColumns.concat(customFieldColumns(customFields));
}

export function getFixedColumnsBy(entity) {
  switch (entity) {
    case ENTITIES.person:
      return personColumns;
    case ENTITIES.organization:
      return organizationColumns;
    case ENTITIES.deal:
      return dealColumns;
    default:
      return [];
  }
}

export function getAddPathBy(entity) {
  switch (entity) {
    case ENTITIES.person:
      return '/people/new';
    case ENTITIES.organization:
      return '/organizations/new';
    case ENTITIES.deal:
      return '/deals/new';
    default:
      return null;
  }
}

export function getEditPathBy(entity, id) {
  switch (entity) {
    case ENTITIES.person:
      return personEditPath(id);
    case ENTITIES.organization:
      return organizationEditPath(id);
    case ENTITIES.deal:
      return dealEditPath(id);
    default:
      return null;
  }
}
