import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';

import Button from '@/components/Button';
import Card from '@/components/Card';
import Icon from '@/components/Icon';
import Main from '@/components/Main';
import ExternalLink from '@/components/ExternalLink';
import checkImage from 'images/check.svg';
import { parseDate, toTextualDateString, toDayOfWeek } from '@/date';
import { settingsCurrentPlanPath } from '@/routes';
import { toCurrencyString } from '@/number';

function BankSlip() {
  const navigate = useNavigate();
  const location = useLocation();

  const { transactionDetails, value, planName } = location.state;

  const expiresAt = transactionDetails.expiresAt || transactionDetails.expires_at;
  const expiresDate = parseDate(expiresAt);
  const number = transactionDetails.reference || transactionDetails.number;
  const externalUrl = transactionDetails.downloadUrl || transactionDetails.pdf;

  const handleClick = () => {
    navigator.clipboard.writeText(number);
  };

  return (
    <Main fluid className='p-8 container-full-height'>
      <Container className='pb-8'>
        <Row>
          <Col md={{ span: 7, offset: 3 }}>
            <div className='text-center'>
              <img className='my-6' src={checkImage}/>

              <h2 className='mb-2'>Agora falta pouco, seu boleto foi gerado</h2>

              <p className='subtitle text-dark-gray'>
                Liberamos o seu plano {planName} para uso enquanto
                aguardamos o pagamento ser processado.
              </p>

              <Card body className='p-3 my-6 text-dark-gray border-light'>
                <span className='fw-bold text-small'>Cód. do boleto</span>
                <h2 className='my-2'>{number}</h2>

                <div>
                  <span className='fw-bold'>Vencimento: </span>
                  <span>
                    {
                      `${toDayOfWeek(expiresDate)}, ${
                        toTextualDateString(expiresDate)}`
                    }
                  </span>
                </div>
                <div className='mb-3'>
                  <span className='fw-bold'>Valor: </span>
                  <span>{toCurrencyString(value)}</span>
                </div>

                <div>
                  <Button
                    variant='outline-dark-gray'
                    className='width-5 me-2'
                    onClick={handleClick}
                  >
                    <Icon name='copy' size='sm' />
                    <span className='ms-2'>Copiar código do boleto</span>
                  </Button>
                  <Button
                    className='width-5'
                    as={ExternalLink}
                    target= '_blank'
                    href={externalUrl}
                  >
                    <Icon name='download' size='sm' />
                    <span className='ms-2'>Baixar boleto</span>
                  </Button>
                </div>
              </Card>

            </div>

            <div className='mb-6'>
              <span className='form-label mb-1'>Informações importantes:</span>

              <ul className='text-dark-gray'>
                <li>
                  Seu pagamento será confirmado em até 2 dias uteis após o pagamento do Boleto.
                </li>

                <li>
                  Caso o pagamento não seja realizado até a data de vencimento,
                  é necessário emitir segunda via ou alterar a forma de pagamento.
                </li>
              </ul>
            </div>

            <div className='d-flex justify-content-center'>
              <Button
                variant='outline-dark-gray'
                className='py-2 px-3 mt-6'
                onClick={() => navigate(settingsCurrentPlanPath())}
              >
                <Icon name='arrow-left' size='sm' />
                <span className='ms-2'>Voltar ao sistema</span>
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Main>

  );
}

export default BankSlip;
