export function canGenerateLeads({ user }) {
  let effect, reason;

  if (!user.admin) {
    effect = 'disable';
    reason =
    `Por enquanto, somente os administradores podem gerar e comprar leads.
    Solicite a um administrador de sua conta para realizar esse processo.`;
  } else {
    effect = 'allow';
  }

  return { effect, reason };
}
