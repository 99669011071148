import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Truncate from '@/components/Truncate';
import Icon from '@/components/Icon';
import Avatar from '@/components/Avatar';


IconLabel.propTypes = {
  text: PropTypes.any,
  textSize: PropTypes.oneOf(['text-small', 'text-medium', 'h4', 'h5']),
  emptyText: PropTypes.string
};

IconLabel.defaultProps = {
  text: '',
  textSize: 'text-small',
  emptyText: 'Indefinido'
};

function IconLabel({ icon, avatar, text, textSize, className, emptyText }) {
  return (
    <div className={classnames('d-flex align-items-center', className)}>
      {icon
        ? <Icon
          className='me-1'
          name={icon.name}
          size={icon.size || 'sm'}
          style={{ minWidth: '16px' }}
        />
        : null}
      {avatar ? <Avatar className='me-1' name={avatar.name} url={avatar.url} /> : null}
      {text
        ? <Truncate as='span' className={textSize}>{text}</Truncate>
        : <span className='text-medium-gray'>{emptyText}</span>
      }
    </div>
  );
}

export default IconLabel;
