import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import classnames from 'classnames';
import { toDateString, toTimeString } from '@/date';

const propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  variant: PropTypes.oneOf(['success', 'warning', 'light']),
  className: PropTypes.string,
  allDay: PropTypes.bool
};

const defaultProps = {
  variant: 'success',
  className: null,
  allDay: false
};

function DateAlert({ date, variant, className, allDay }) {
  return (
    <Alert
      className={classnames(
        'd-inline-block',
        'rounded',
        'px-4',
        'py-2',
        'mb-0',
        'text-nowrap',
        'text-small',
        'lh-1',
        'date-alert',
        className
      )}
      variant={variant}
    >
      <span className='fw-bold'>{toDateString(date, true)}</span>

      {
        !allDay &&
        <span className='text-muted'> {toTimeString(date)}</span>
      }
    </Alert>
  );
}

DateAlert.propTypes = propTypes;
DateAlert.defaultProps = defaultProps;

export default DateAlert;
