import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  active: PropTypes.bool
};

const defaultProps = {
  children: null,
  className: '',
  active: true
};

function Keyword({ children, custom, active }) {
  return (
    <b className={classnames(
      { 'text-primary': custom && active }
    )}>
      {children}
    </b>
  );
}

Keyword.propTypes = propTypes;
Keyword.defaultProps = defaultProps;

export default Keyword;
