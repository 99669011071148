import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { toCurrencyString } from '@/number';
import Card from '@/components/Card';
import EntityLink from '@/components/EntityLink';
import TaskIcon from '@/components/TaskIcon';
import Truncate from '@/components/Truncate';
import DealFreezeIcon from '@/components/DealFreezeIcon';
import { DealIcon } from '@/feature/automation';
import { parseDate } from '@/date';
import { useQueryParams } from '@/hooks';

const propTypes = {
  card: PropTypes.object.isRequired,
  className: PropTypes.string
};

function FunnelStageCard({ card, className }) {
  const queryParams = useQueryParams();
  const [highlight, setHighlight] = useState(false);
  const cardId = card?.id?.replace(/[\D]+/gu, '');
  const entity = { type: 'deal', id: cardId };

  useEffect(() => {
    if (queryParams?.dealId) {
      setHighlight(cardId === queryParams?.dealId);
    }
  }, [queryParams?.dealId]);

  return (
    <Card className={classnames('px-3 py-2 border-0', className)}>
      <Card.Body className='d-flex p-0 justify-content-between align-items-center'>
        <Card.FlexibleArea>
          <Card.Title>
            <EntityLink entity={entity} className={classnames({ 'text-primary': highlight })}>
              <Truncate lines={2}>
                <div className='d-flex'>
                  <DealIcon deal={card} />

                  {card?.title}
                </div>
              </Truncate>
            </EntityLink>
          </Card.Title>

          <Card.Content>
            <Truncate lines={2}>{card?.details?.relatedEntity}</Truncate>
          </Card.Content>

          <Card.Info>
            <Truncate disableTooltip>
              {card?.value > 0 ? toCurrencyString(card.value) : null}
            </Truncate>
          </Card.Info>
        </Card.FlexibleArea>

        <Card.Status>
          <Status card={card} />
        </Card.Status>
      </Card.Body>
    </Card>
  );
}

function Status({ card }) {
  const { status, id } = card;
  const [cardType, cardId] = id.split('-');
  const showStatus = cardType === 'deal';

  if (!showStatus) {
    return null;
  }
  if (status?.freezing) {
    return (
      <DealFreezeIcon
        unfreezeDate={parseDate(status.freezing.unfreezeAt)}
        id={cardId}
      />
    );
  }

  return (
    <TaskIcon
      task={card?.status?.task}
      entity={{ type: cardType, id: cardId }}
    />
  );
}

FunnelStageCard.propTypes = propTypes;

export default FunnelStageCard;
