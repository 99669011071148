import React from 'react';
import Container from 'react-bootstrap/Container';

import Main from '@/components/Main';
import Stepper from '@/components/Stepper';
import Header from '@/feature/profile_form/Header';
import NeedStep from '@/feature/profile_form/NeedStep';
import CRMExperienceStep from '@/feature/profile_form/CRMExperienceStep';
import IndustryStep from '@/feature/profile_form/IndustryStep';
import FindUs from '@/feature/profile_form/FindUsStep';

function ProfileForm() {
  return (
    <Main
      overflow
      fluid
      flex
      className='justify-content-lg-center align-items-center bg-profile container-full-height'
    >
      <Container>
        <Stepper Header={Header}>
          <NeedStep />
          <CRMExperienceStep />
          <IndustryStep />
          <FindUs />
        </Stepper>
      </Container>
    </Main >
  );
}

export default ProfileForm;
