import React from 'react';
import HeaderPaymentWarning, { useHeaderPaymentWarning } from '@/components/HeaderPaymentWarning';
import HeaderPaymentMethod, { useHeaderPaymentMethod } from '@/components/HeaderPaymentMethod';
import HeaderPerformanceWarning, {
  useHeaderPerformance
} from '@/components/HeaderPerformanceWarning';
import HeaderPerformanceWarningOct, {
  useHeaderPerformanceWarningOct
} from '@/components/HeaderPerformanceWarningOct';

import { useCurrentPlan } from '@/api';
import { useAuth } from '@/lib/auth';

function HeaderWarning() {
  const { user } = useAuth();
  const { isLoading, data: { data: currentPlanData } = {} } = useCurrentPlan({
    config: { enabled: user.admin }
  });

  const {
    show: showPaymentWarning, onClose: onClosePaymentWarning
  } = useHeaderPaymentWarning();
  const {
    show: showPaymentMethod, onClose: onClosePaymentMethod
  } = useHeaderPaymentMethod(currentPlanData);
  const shouldShowPerformanceWarning = useHeaderPerformance();
  const shouldShowPerformanceNotice = useHeaderPerformanceWarningOct();

  let HeaderComponent;
  let onClose;

  if (showPaymentWarning) {
    HeaderComponent = HeaderPaymentWarning;
    onClose = onClosePaymentWarning;
  } else if (showPaymentMethod) {
    HeaderComponent = HeaderPaymentMethod;
    onClose = onClosePaymentMethod;
  } else if (shouldShowPerformanceWarning) {
    HeaderComponent = HeaderPerformanceWarning;
  } else if (shouldShowPerformanceNotice) {
    HeaderComponent = HeaderPerformanceWarningOct;
  }

  if (isLoading || !HeaderComponent) {
    return null;
  }

  return (
    <HeaderComponent currentPlanData={currentPlanData} onClose={onClose} />
  );
}

export default HeaderWarning;
