import React from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';

import APIErrorMessage from '@/components/APIErrorMessage';
import Button from '@/components/Button';
import FetchableSelect from '@/components/Inputs/FetchableSelect';
import Icon from '@/components/Icon';

import { useAuth } from '@/lib/auth';
import { canCreateProductCategory } from '@/policies';
import { useProductCategories, useCreateProductCategory } from '@/api';

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func
};

const defaultProps = {
  name: 'product_category_id',
  label: 'Categoria',
  placeholder: 'Selecione',
  multiple: false,
  isClearable: true,
  isSearchable: true,
  defaultValue: null,
  onChange: () => {}
};

function ProductCategorySelect(props) {
  const { user } = useAuth();

  /**
   * Foi preciso usar a policy diretamente ao invés do componente
   * `Authorization` porque o `Select` encapsula o label numa `Option` e é ela
   * que precisa ser desabilitada, o que é feito pelo `getNewOptionData`.
   */
  const { effect, reason } = canCreateProductCategory({ user });
  const isDisabled = effect !== 'allow';

  const alert = useAlert();
  const mutation = useCreateProductCategory();
  const onCreate = (name) => {
    /**
     * Para selecionar uma opção, é preciso usar o seu `value`, que por padrão
     * é obtido do `id`. A nova opção criada ainda não terá `id`, por isso, para
     * selecioná-la imediatamente, é utilizado um `id` temporário igual ao nome.
     */
    const temporaryId = name;

    mutation.mutate({ id: temporaryId, name }, {
      onSuccess: ({ data }) => {
        props.onChange?.(data.id);

        alert.show(
          'Categoria adicionada com sucesso.',
          { variant: 'success', timeout: 5000 }
        );
      },
      onError: (err) => {
        alert.show(
          <APIErrorMessage
            err={err}
            resource='product_category'
            action='create'
          />,
          { variant: 'danger' }
        );
      }
    });

    props.onChange?.(temporaryId);
  };

  return (
    <FetchableSelect
      { ...props }
      query={useProductCategories}
      isCreatable
      isLoading={mutation.isLoading}
      formatCreateLabel={(inputText) => (
        <Button
          variant='link'
          disabled={isDisabled}
          className='p-0 border-0 justify-content-start fw-bold'
        >
          <Icon name='plus' size='sm' className='me-2' />

          <span>
            Adicionar nova categoria {`"${inputText}"`}
          </span>
        </Button>
      )}
      getNewOptionData={(value, label) => (
        { value, label, isDisabled, tooltip: reason }
      )}
      onCreateOption={onCreate}
      maxLength={60}
    />
  );
}

ProductCategorySelect.propTypes = propTypes;
ProductCategorySelect.defaultProps = defaultProps;

export default ProductCategorySelect;
