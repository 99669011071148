import React from 'react';
import PropTypes from 'prop-types';
import Select from '@/components/Inputs/Select';

const propTypes = {
  field: PropTypes.object.isRequired,
  name: PropTypes.string,
  label: PropTypes.string
};

export function SelectCustomField({ field, label, ...otherProps }) {
  const options = field.customFieldOptions?.map((item) => (
    { label: item.option, value: item.id }
  ));

  const isMultiple = field.type === 'multiselect';

  return (
    <Select
      options={options}
      multiple={isMultiple}
      isSearchable
      label={label ?? field.name}
      placeholder={isMultiple
        ? 'Selecione uma ou mais opções'
        : 'Selecione uma opção'
      }
      variant='light'
      { ...otherProps }
    />
  );
}

SelectCustomField.propTypes = propTypes;
