import { useQuery } from 'react-query';
import { get } from '@/lib/fetch';

export const categoriesKeys = {
  all: ['categories'],
  lists: () => [...categoriesKeys.all, 'list'],
  list: (params) => [...categoriesKeys.lists(), params]
};

export async function getCategories(params = {}) {
  const { data } = await get('/categories', params);

  return data;
}

export function useCategories({ params = {}, config = {} }) {
  return useQuery({
    ...config,
    queryKey: categoriesKeys.list(params),
    queryFn: () => getCategories(params)
  });
}
