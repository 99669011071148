import React from 'react';

import { useCheckoutContext } from '@/contexts';
import BillingFooter from '@/components/Billing/BillingFooter';
import PaymentConfirmation from '@/components/Billing/PaymentConfirmation';

function StripeConfirmation() {
  const {
    prevStep, paymentData, period, checkoutResumeData, accountData,
    isLoadingCreatePayment, transactionPollingEnabled, onCreatePayment
  } = useCheckoutContext();

  return (
    <>
      <PaymentConfirmation
        legalName={accountData.legalName}
        cpfCnpj={accountData.cpfCnpj}
        onClickEdit={prevStep}
        paymentInfo={paymentData}
        periodicity={period}
        startDate={checkoutResumeData.startDate}
        expirationDate={checkoutResumeData.expirationDate}
        address={accountData.address}
        isPlanPayment={checkoutResumeData.isPlanPayment}
      />

      <BillingFooter
        buttonNextText='Finalizar'
        loadingTextButton='Finalizando...'
        onClickNext={onCreatePayment}
        isLoading={isLoadingCreatePayment || transactionPollingEnabled}
      />
    </>
  );
}

export default StripeConfirmation;
