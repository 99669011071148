import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '@/components/Icon';

const propTypes = {
  currentStep: PropTypes.number.isRequired,
  headerLabels: PropTypes.array.isRequired,
  headerClassName: PropTypes.string
};

function StepperHeader({ currentStep, headerLabels, headerClassName }) {
  const divConcludeStepStyle = ['text-white', 'bg-primary', 'border border-primary'];
  const divActiveStepStyle = ['text-white border', 'border-primary', 'bg-primary'];
  const divPendingStepStyle = ['text-medium-gray', 'border', 'border-medium-gray', 'bg-white'];
  const spanActiveStepStyle = ['text-primary', 'me-5'];
  const spanPendingSteptyle = ['text-medium-gray', 'me-5'];

  const divStyle = (step) => {
    let styles = [
      'position-relative',
      'd-flex',
      'justify-content-center',
      'align-items-center',
      'text-center',
      'rounded-circle',
      'z-index-3',
      'p-4',
      'me-3'
    ];

    if (step < currentStep) {
      styles = [...styles, ...divConcludeStepStyle];
    }
    if (step === currentStep) {
      styles = [...styles, ...divActiveStepStyle];
    }
    if (step > currentStep) {
      styles = [...styles, ...divPendingStepStyle];
    }
    return classnames(styles);
  };
  const spanStyle = (step) => {
    const styles = step <= currentStep ? spanActiveStepStyle : spanPendingSteptyle;

    return classnames(styles);
  };

  const contentCurrentStep = (step) => (
    step < currentStep ? <Icon name='check' size='lg' /> : step + 1
  );

  const renderLabel = (step, label, isLastLabel) => (
    <React.Fragment key={step}>
      <div className={divStyle(step, isLastLabel)}>
        <span className='position-absolute'>{contentCurrentStep(step)}</span>
      </div>
      <h4 className={spanStyle(step)}>
        {label}
      </h4>
    </React.Fragment>
  );

  return (
    <div className={classnames('position-relative', headerClassName)}>
      {headerLabels.map((label, index) => (renderLabel(index, label)))}
    </div>
  );
}

StepperHeader.propTypes = propTypes;

export default StepperHeader;
