import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import blockedAccountImage from 'images/billing/pessoa-triste.svg';
import { useDocumentTitle } from '@/hooks';
import { useAccountAdmins } from '@/api';
import Avatar from '@/components/Avatar';
import { useAuth } from '@/lib/auth';
import { legacyPlansUrl } from '@/routes';
import { redirect } from '@/browser';
import Layout from '@/components/Layout';
import Tooltip from '@/components/Tooltip';

const PAGE_TITLE = 'Conta bloqueada';

function BlockedAccess() {
  useDocumentTitle(PAGE_TITLE);
  const { data: { data: admins } = {}, isSuccess } = useAccountAdmins();
  const { user } = useAuth();

  useEffect(() => {
    if (user.admin) {
      redirect(legacyPlansUrl());
    }
  }, [user.admin]);

  if (!isSuccess) {
    return <div className='flex-grow-1 bg-light' />;
  }

  return (
    <Layout>
      <Container className='d-flex flex-column flex-grow-1'>
        <Row className='flex-fill justify-content-center align-items-center'>
          <Col sm={7} className='d-flex flex-column justify-content-center align-items-start'>
            <div className='d-flex flex-column'>
              <h1 className='display-5 fw-bold'>{PAGE_TITLE}</h1>
              <span className='fs-3 text-dark-gray mt-2'>
                Fale com algum administrador da conta. Para acessar o
                <br />Agendor, é necessário renovar a assinatura.
              </span>
              <span className='fs-3 mt-4 text-dark-gray fw-bold'>Administradores:</span>
              <Row className='d-flex flex-wrap'>
                {admins.map((admin, index) => (
                  <Col key={index} className='m-1 p-1 flex-grow-1 flex-shrink-1'
                    xs={12} sm={6} md={4} lg={3}
                  >
                    <div className='d-flex align-items-center'>
                      <Avatar
                        name={admin.name}
                        url={admin.avatarUrl}
                        tooltip={false}
                        size='lg'
                      />
                      <Tooltip
                        placement='top'
                        trigger={['hover']}
                        content={admin.email}
                      >
                        <div className='ms-2 fw-bold'>{admin.name}</div>
                      </Tooltip>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
          <Col sm={2} className='d-flex flex-column'>
            <div className='d-flex flex-fill flex-column justify-content-start align-items-start'>
              <img src={blockedAccountImage} style={{ width: '280px' }} alt='Blocked Account'/>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default BlockedAccess;
