import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import compact from 'lodash/compact';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import { useCreateVoipStartCall } from '@/api';
import { VOIP_MAIN_ERROR, ZENVIA_ERRORS } from '@/utils';
import Button from '@/components/Button';
import VoipMessageBalance from '@/components/EntityModal/VoipMessageBalance';
import DetailsVoipContactItem from '@/components/EntityModal/Deal/DetailsVoipContactItem';

DetailsVoipContactList.defaultProps = {
  deal: {},
  voip: {},
  entity: {},
  entityType: '',
  onRefreshModal: () => { },
  onUpdateEntity: () => { }
};

DetailsVoipContactList.propTypes = {
  deal: PropTypes.object,
  voip: PropTypes.object,
  entity: PropTypes.object,
  entityType: PropTypes.string,
  onRefreshModal: PropTypes.func,
  onUpdateEntity: PropTypes.func
};

function DetailsVoipContactList({
  deal, voip, entity, entityType, onRefreshModal, onUpdateEntity
}) {
  const [submitting, setSubmitting] = useState(false);
  const [calling, setCalling] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const { user } = useAuth();
  const tracker = useTracking();
  const alert = useAlert();
  const mutation = useCreateVoipStartCall();

  const entities = compact([entity].concat(entity?.people ?? null));

  const handleStartVoipCall = (voipData, done) => {
    setSubmitting(true);
    setCalling(false);

    function alertError(error) {
      alert.show(
        <div>
          <h6>Não foi possível realizar a ligação!</h6>
          <ul className='mb-0 pl-0'>
            <li>{error}</li>
          </ul>
        </div>
        ,
        { variant: 'danger', timeout: 5000 }
      );
    }

    mutation.mutate(voipData, {
      onError: () => {
        setSubmitting(false);
        alertError(VOIP_MAIN_ERROR);
        done();
      },
      onSuccess: (data) => {
        setSubmitting(false);
        if (data?.success) {
          setCalling(true);
          tracker.trackVoipCallStarted({ user });
          window.open(data.url, '_blank');
        } else {
          alertError(ZENVIA_ERRORS?.[`${data?.code}`] ?? VOIP_MAIN_ERROR);
        }
        done();
      }
    });
  };

  if (calling) {
    return (
      <div className='text-center'>
        <h4 className='mb-3'>Recarregue a página para visualizar a ligação</h4>
        <hr />
        <div className='my-3'>
          Caso a ligação tenha sido bem sucedida, atualize
          <br />
          clicando no botão abaixo ou pressionando F5.
        </div>
        <Button
          onClick={() => {
            setCalling(false);
            onRefreshModal();
          }}
        >
          Recarregar página
        </Button>
      </div>
    );
  }

  return (
    <div className='deal-voip-height overflow-hidden overflow-y-auto'>
      <VoipMessageBalance />
      {
        showForm
          ? null
          : (
            <div className='d-flex justify-content-between mb-4'>
              <h4>Contatos</h4>
            </div>
          )
      }

      {entities?.map((entityData, index) => (
        <DetailsVoipContactItem
          key={entityData.id}
          deal={deal}
          voip={voip}
          entity={entityData}
          isLastItem={index === entities.length - 1}
          entityType={index === 0 ? entityType : 'person'}
          disabled={submitting}
          onClick={handleStartVoipCall}
          onUpdateEntity={onUpdateEntity}
          showForm={showForm}
          setShowForm={setShowForm}
        />
      ))}
    </div>
  );
}

export default DetailsVoipContactList;
