import { useQuery, useMutation } from 'react-query';
import { get, post } from '@/lib/fetch';

export const exportsKeys = {
  details: () => ['exports', 'detail'],
  detail: (id) => [...exportsKeys.details(), id]
};

export async function getExport(exportId) {
  const { data } = await get(`/exports/${exportId}`);

  return data;
}

export async function createExport(params = {}) {
  const { data } = await post('/exports', params);

  return data;
}

export function useExport({ exportId, config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: exportsKeys.detail(exportId),
    queryFn: () => getExport(exportId)
  });
}

export function useCreateExport({ config = {} } = {}) {
  return useMutation(createExport, { ...config });
}
