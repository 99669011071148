import { useQuery } from 'react-query';
import { get } from '@/lib/fetch';

export const billingProductsKeys = {
  all: ['billing-products'],
  lists: () => [...billingProductsKeys.all, 'list'],
  list: (params) => [...billingProductsKeys.lists(), params],
  details: () => [...billingProductsKeys.all, 'detail'],
  detail: (code) => [...billingProductsKeys.details(), code]
};

export async function getBillingProducts(params) {
  const { data } = await get('/billing/products', params);

  return data;
}

export async function getBillingProduct(code) {
  const { data } = await get(`/billing/products/${code}`);

  return data;
}

export function useBillingProducts({ params = {}, config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: billingProductsKeys.list(params),
    queryFn: () => getBillingProducts(params)
  });
}

export function useBillingProduct({ code, config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: billingProductsKeys.detail(code),
    queryFn: () => getBillingProduct(code)
  });
}
