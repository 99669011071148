import React from 'react';
import classnames from 'classnames';

import Main from '@/components/Main';
import InfiniteScroll from '@/components/InfiniteScroll';
import Table from '@/components/Table';
import TableCell from '@/components/TableCell';
import PaginationCounter from '@/components/List/PaginationCounter';
import DragScrollable from '@/components/DragScrollable';
import LoadSpinner from '@/components/LoadSpinner';
import EntitySkeleton from '@/components/List/EntitySkeleton';
import Avatar from '@/components/Avatar';
import Icon from '@/components/Icon';
import Button from '@/components/Button';

import { useDocumentTitle } from '@/hooks';
import { parseDate, toDateString, toTimeString } from '@/date';
import { useInfiniteCalls } from '@/api/calls';
import AudioPlayer from '@/components/AudioPlayer';
import { toCurrencyString } from '@/number';

const PAGE_TITLE = 'Histórico de chamadas';

const TARGET_TYPE = {
  movel: 'Celular',
  fixo: 'Fixo',
  ramal: 'Ramal'
};

function SettingsCallsHistory() {
  useDocumentTitle(PAGE_TITLE);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading
  } = useInfiniteCalls({
    params: {
      per_page: 20
    }
  });

  const items = data?.pages.flatMap((page) => page.data) ?? [];
  const totalItems = data?.pages[0].meta.count;

  return (
    <Main
      fluid
      flex
      overflow={false}
      className='pt-8 px-4 overflow-hidden position-relative'
    >

      <h1 className='mb-6'>
        {PAGE_TITLE}
      </h1>

      <InfiniteScroll
        length={items?.length || 0}
        loadItems={fetchNextPage}
        hasMore={hasNextPage || false}
      >
        <DragScrollable>
          <Table>
            <thead>
              <tr className='text-nowrap'>
                <th className='border-end border-1 position-sticky'>Audio</th>
                <th className='border-end border-1 position-sticky'>Data</th>
                <th className='border-end border-1 position-sticky'>Hora</th>
                <th className='border-end border-1 position-sticky'>Tipo</th>
                <th className='border-end border-1 position-sticky'>Número de Destino</th>
                <th className='border-end border-1 position-sticky'>Responsável</th>
                <th>Preço</th>
              </tr>
            </thead>

            <tbody>
              {isLoading
                ? <EntitySkeleton maxCols={7} noIndex />
                : (
                  items.map((item, index) => (
                    <tr key={index}>
                      <td className='align-middle border-end border-1 position-sticky'>
                        <span>
                          <AudioPlayer src={item?.audioUrl} >
                            <Button variant='link' className='p-0'>
                              <Icon name='play' className='me-1' size='sm' />
                              Escutar
                            </Button>
                          </AudioPlayer>
                        </span>
                      </td>

                      <td className='align-middle border-end border-1 position-sticky'>
                        {toDateString(parseDate(item?.createdAt))}
                      </td>

                      <td className='align-middle border-end border-1 position-sticky'>
                        {toTimeString(parseDate(item?.createdAt))}
                      </td>

                      <td className='align-middle border-end border-1 position-sticky'>
                        <TableCell value={TARGET_TYPE[item?.targetType]} />
                      </td>

                      <td className='align-middle border-end border-1 position-sticky'>
                        <TableCell value={`(${item?.targetDdd}) ${item?.targetPhone}`}/>
                      </td>

                      <td className='align-middle border-end border-1 position-sticky'>
                        <Avatar
                          name={item.user.name}
                          tooltip={`Responsável: ${item?.user?.name}`}
                          url={item?.user?.avatarUrl}
                          className='me-2'
                        />
                        <TableCell value={item?.user?.name} />
                      </td>

                      <td>
                        <TableCell value={toCurrencyString(Number(item?.value))} />
                      </td>
                    </tr>
                  ))
                )
              }
            </tbody>
          </Table>
        </DragScrollable>
      </InfiniteScroll>
      { isFetchingNextPage && <LoadSpinner size='lg' className={classnames(
        'd-flex',
        'text-dark-gray',
        'align-items-center',
        'justify-content-center',
        'opacity-100',
        'overflow-hidden',
        'py-3'
      )}/> }

      <div className='py-2'>
        <PaginationCounter
          show={!isLoading}
          model='call'
          count={items?.length}
          max={totalItems}
        />
      </div>
    </Main>
  );
}

export default SettingsCallsHistory;
