import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Base, { components } from 'react-select';
import Creatable from 'react-select/creatable';
import Form from 'react-bootstrap/Form';
import classnames from 'classnames';
import { hasClass } from 'dom-helpers';
import Icon from '@/components/Icon';
import Tooltip from '@/components/Tooltip';
import Truncate from '@/components/Truncate';
import { isBlank } from '@/utils';

const optionShape = PropTypes.shape({
  label: PropTypes.oneOfType([PropTypes.node]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  leftAdornment: PropTypes.node,
  rightAdornment: PropTypes.node
});

const groupOptionShape = PropTypes.shape({
  label: PropTypes.string,
  options: PropTypes.arrayOf(optionShape).isRequired
});

const propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(optionShape),
    PropTypes.arrayOf(groupOptionShape)
  ]).isRequired,
  multiple: PropTypes.bool,
  autoAdjustValues: PropTypes.bool,
  disabled: PropTypes.bool,
  controlClassName: PropTypes.string,
  optionsClassName: PropTypes.string,
  menuListClassName: PropTypes.string,
  multiValueClassName: PropTypes.string,
  isCreatable: PropTypes.bool,
  onCreateOption: PropTypes.func,
  createLabel: PropTypes.string,
  variant: PropTypes.oneOf(['light', 'white']),
  footer: PropTypes.node,
  inputProps: PropTypes.object,
  showAdornmentOnSelectedOption: PropTypes.bool
};

const defaultProps = {
  options: [],
  multiple: false,
  placeholder: 'Selecione',
  autoAdjustValues: false,
  disabled: false,
  controlClassName: '',
  optionsClassName: '',
  menuListClassName: '',
  multiValueClassName: '',
  maxMenuHeight: 256,
  isCreatable: false,
  onCreateOption: undefined,
  createLabel: 'Adicionar',
  variant: 'light',
  className: 'mb-4',
  inputProps: {},
  showAdornmentOnSelectedOption: true
};

const customComponents = {
  Group: (props) => (
    <components.Group
      { ...props }
      className='pt-0'
    />
  ),
  GroupHeading: (props) => (
    <components.GroupHeading
      { ...props }
      className={classnames({
        'mb-0': !props.data.label,
        'pt-2 sticky-top bg-white': Boolean(props.data.label)
      })}
    />
  ),
  ClearIndicator: (props) => {
    const { innerProps: { ref, ...restInnerProps } } = props;

    return (
      <div { ...restInnerProps } ref={ref} >
        <Icon ref={ref} className='text-primary' name='close' />
      </div>
    );
  },
  DropdownIndicator: (_props) => (
    <Icon className='text-primary' name='arrow-down' />
  ),
  IndicatorsContainer: (props) => (
    !props.isMulti && <components.IndicatorsContainer { ...props } />
  ),
  IndicatorSeparator: () => null,
  Input: (props) => (
    <components.Input
      { ...props }
      maxLength={props.selectProps?.maxLength}
      {...props.selectProps?.inputProps}
    />
  ),
  Control: (props) => {
    const color = props.selectProps.variant;
    return (
      <components.Control
        { ...props }
        className={classnames(
          'overflow-auto',
          'px-3',
          'py-0',
          'shadow-none',
          'min-height-input',
          'max-height-select',
          {
            'border-primary': props.isFocused,
            'border-light': !props.isFocused,
            [`bg-${color}`]: !props.isDisabled,
            'bg-lighter-gray': props.isDisabled
          },
          props.selectProps?.controlClassName
        )}
      />
    );
  },
  ValueContainer: (props) => {
    const { selectProps, children, ...rest } = props;
    const { leftAdornment, rightAdornment, ...otherSelectProps } = selectProps;

    return (
      <components.ValueContainer
        { ...rest }
        { ...otherSelectProps }
        className={classnames(
          'p-0',
          {
            'pt-1': rest.isMulti,
            'flex-nowrap': !rest.isMulti
          }
        )}
      >
        {rest.hasValue && leftAdornment}
        {children}
        {rest.hasValue && rightAdornment}

      </components.ValueContainer>
    );
  },
  Menu: (props) => (
    <components.Menu
      { ...props }
      className='mt-1 shadow-none z-index-2 sub-overlay'
    />
  ),
  MenuList: (props) => {
    const { children, selectProps, ...rest } = props;
    return (
      <>
        <components.MenuList
          { ...rest }
          className={classnames(
            'select-menu-list p-0 border rounded-1',
            selectProps?.menuListClassName
          )}
        >
          {children}
        </components.MenuList>
        {selectProps.footer}
      </>
    );
  },
  Option: (props) => {
    const { tooltip } = props.data;

    const option =
      <div
        { ...props.innerProps }
        className={classnames(
          'select__option',
          'd-flex',
          'align-items-center',
          'px-4',
          'py-2',
          'border-top',
          'border-1',
          'border-light',
          'cursor-default',
          {
            'bg-transparent': !props.isFocused && !props.isDisabled,
            'bg-light': props.isFocused && !props.isDisabled,
            'fw-bold': props.isSelected,
            'text-medium-gray': props.isDisabled
          },
          props.selectProps?.optionsClassName
        )}
      >
        {
          props.data.leftAdornment &&
          <div className='me-2'>
            {props.data.leftAdornment}
          </div>
        }

        <Truncate className='flex-fill'>
          {props.children}
        </Truncate>

        {
          props.data.rightAdornment &&
          <div className='ms-2'>
            {props.data.rightAdornment}
          </div>
        }

        {
          props.isSelected &&
          <Icon
            className='ms-2'
            name='check'
            size='md'
          />
        }
      </div>;

    if (tooltip) {
      return (
        <Tooltip content={tooltip} placement='right'>
          {option}
        </Tooltip>
      );
    }

    return option;
  },
  SingleValue: (props) => (
    <div
      { ...props.innerProps }
      className={classnames(
        'd-flex',
        'align-items-center',
        'justify-content-between'
      )}
      /**
       * Essa margem foi colocada para alinhar o valor único ao input, nos casos
       * de selects com `isSearchable=true`, pois o react-select insere essa
       * margem no input e no placeholder.
       */
      style={{ marginLeft: '2px' }}
    >
      {
        props.data.leftAdornment &&
        <div className='me-2 lh-1'>
          {props.data.leftAdornment}
        </div>
      }

      <div className='text-nowrap'>
        {props.children}
      </div>

      { props.data.showAdornmentOnSelectedOption &&
        props.data.rightAdornment &&
        <div className='ms-2 lh-1'>
          {props.data.rightAdornment}
        </div>
      }
    </div>
  ),
  MultiValue: (props) => (
    <div
      { ...props.innerProps }
      className={classnames(
        'd-flex',
        'align-items-center',
        'justify-content-between',
        'bg-darker-gray',
        'rounded-1',
        'fw-bold',
        'px-2',
        'py-1',
        'text-small',
        'me-1',
        'mb-1',
        props.selectProps?.multiValueClassName
      )}
    >
      <div className='text-white'>
        {props.children}
      </div>

      <div
        { ...props.removeProps }
        className='ms-2 text-gray cursor-pointer'
      >
        <Icon name='close' size='sm' />
      </div>
    </div>
  ),
  NoOptionsMessage: (_props) => null,
  Placeholder: (props) => (
    <components.Placeholder
      { ...props }
      className='text-medium-gray text-nowrap'
    />
  )
};

const Select = React.forwardRef((props, ref) => {
  const {
    name, options, multiple, value, onChange, className, label, helperText,
    autoAdjustValues, disabled, error, isCreatable, onCreateOption, ...otherProps
  } = props;

  // If options are grouped, flatten them to make it easier to look up a value
  const flattenedOptions = useMemo(
    () => options.flatMap((item) => (item.options ? item.options : item)),
    [options]
  );

  const handleChange = onChange
    ? (option) => onChange(getValue(option), { option })
    : undefined;
  const selected = isBlank(value)
    ? value
    : getOption(flattenedOptions, value, isCreatable);

  useEffect(() => {
    if (!autoAdjustValues) {
      return;
    }

    if (handleChange && flattenedOptions.length && hasInconsistentValue(selected, value)) {
      handleChange(selected);
    }
  }, [autoAdjustValues, options, value, selected, handleChange]);

  const Component = isCreatable ? Creatable : Base;

  return (
    <Form.Group className={classnames(className, 'select')} controlId={name}>
      {(label || helperText) &&
        <div className='d-flex align-items-base justify-content-between'>
          <Form.Label>{label}</Form.Label>

          <Form.Text className='text-medium-gray fst-italic text-small mt-0'>
            {helperText}
          </Form.Text>
        </div>
      }

      <Component
        ref={ref}
        inputId={name}
        className='w-100'
        classNamePrefix='select'
        isMulti={multiple}
        options={options}
        value={selected}
        onChange={handleChange}
        components={customComponents}
        formatGroupLabel={formatGroupLabel}
        onCreateOption={onCreateOption}
        /*
         * Devido ao comportamento apontado na issue abaixo, foi necessário
         * fazer com que o menu do Select seja montado na DOM usando o
         * body como *Portal*. Esse problema acontece conosco quando um Select
         * é exibido dentro de um FilterList com scroll.
         *
         * https://github.com/JedWatson/react-select/issues/3473
         */
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        styles={{
          // Sobrescreve z-index do portal visando sobrepor popovers
          menuPortal: (menuPortalProps) => ({ ...menuPortalProps, zIndex: 1075 })
        }}
        closeMenuOnScroll={(e) => (
          isBlank(e.target.className) || !hasClass(e.target, 'select-menu-list')
        )}
        isDisabled={disabled}
        formatCreateLabel={formatCreateLabel}
        { ...otherProps }
      />

      {/* Feedback apenas é exibido quando está ao lado do elemento com `is-invalid`. */}
      <div className={classnames({ 'is-invalid': Boolean(error) })} />

      <Form.Control.Feedback type='invalid'>
        {error}
      </Form.Control.Feedback>
    </Form.Group>
  );
});


function formatGroupLabel(data) {
  if (!data.label) {
    return null;
  }

  return <div>{data.label}</div>;
}

function formatCreateLabel(data) {
  return `Adicionar "${data}"`;
}

function getOption(options, value, isCreatable) {
  let option = null;

  const findOption = (targetValue) => (
    // eslint-disable-next-line eqeqeq
    options.find((item) => item.value == targetValue && !item.isDisabled)
  );

  if (Array.isArray(value)) {
    /*
     * Fazendo com `flatMap` em value ao invés de `filter` em options para manter
     * a ordem recebida em value e desconsiderar valores não presentes em options.
     */
    option = value.flatMap((valueItem) => {
      // eslint-disable-next-line eqeqeq
      const optionItem = findOption(valueItem);
      if (isCreatable && optionItem === undefined) {
        const newOptionItem = {
          label: valueItem,
          value: valueItem
        };
        return newOptionItem;
      } else {
        return optionItem ? [optionItem] : [];
      }
    });
  } else {
    option = findOption(value);
  }

  return option;
}

function getValue(option) {
  let value = null;

  if (Array.isArray(option)) {
    value = option.map((item) => item.value);
  } else if (option) {
    value = option.value;
  }

  return value;
}

function hasInconsistentValue(selected, value) {
  let isInconsistent = false;

  // Apenas testa Selects múltiplos por enquanto
  if (Array.isArray(value)) {
    isInconsistent = selected.length !== value.length;
  }

  return isInconsistent;
}

Select.displayName = 'Select';
Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export default Select;
