import React, { useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import {
  BacklogDealFilters,
  BacklogOrganizationFilters,
  BacklogPersonFilters,
  dealFiltersDefaulValues,
  organizationFiltersDefaulValues,
  personFiltersDefaulValues
} from '@/components/Filters';
import FieldsPopover from '@/components/FieldsPopover';
import FormField from '@/components/FormField';
import StoredForm from '@/components/StoredForm';
import { BacklogTypeSelect, TextInput, Switch } from '@/components/Inputs';
import Tooltip from '@/components/Tooltip';
import InputAdornment from '@/components/InputAdornment';
import Icon from '@/components/Icon';

const propTypes = {
  backlogId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string
};

const defaultProps = {
  className: ''
};

const searchHints = {
  deal: 'Busque por título, nome do contato ou descrição',
  person: 'Busque por nome, nome da empresa, CPF ou descrição',
  organization: 'Busque por nome, razão social, CNPJ ou descrição'
};

function BacklogForm({ backlogId, onSubmit, name, className, funnelId, onChangeBacklogId }) {
  const { user } = useAuth();
  const tracker = useTracking();
  const [type] = backlogId.split('-');
  const [Filters, typeFilters] = getTypeFilters(type);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const {
    activeTooltipText,
    inactiveTooltipText,
    label: switchLabel,
    name: switchName
  } = getSwitchProps(type);

  const defaultValues = useMemo(
    () => ({
      q: '',
      backlogId,
      [switchName]: true,
      ...typeFilters
    }),
    [backlogId, typeFilters, switchName]
  );

  const onSwitchChange = (values, submitForm) => {
    tracker.trackBacklogFormToggle({ values, user, type });
    submitForm();
  };

  const onFilter = (values) => {
    tracker.trackBacklogFilterUsed({ user, params: values });
  };

  const backlogSearchBlur = (values) => {
    if (values.q.length > 0) {
      tracker.trackBacklogSearchUsed({ user });
    }
    setIsInputFocused(false);
  };

  const ref = useRef(null);

  return (
    <StoredForm
      className={className}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      name={name}
      submitOnDefaultChange
    >
      {
        ({ debouncedSubmitForm, submitForm, values }) => (
          <>
            <div className='px-4 mb-3'>
              <div ref={ref} className='d-flex mb-4 position-relative'>
                <BacklogTypeSelect
                  isSearchable={false}
                  funnelId={funnelId}
                  value={backlogId}
                  onChange={onChangeBacklogId}
                  className='mb-0 flex-fill min-width-0'
                  /*
                   * Aqui foi necessário modificar o target e estilos para aproveitar o
                   * 'position-relative' do componente pai para alinhar as opções do Select
                   * entre o início do Select e o final do botão de filtros.
                   * Para o zIndex foi utilizado o mesmo do Select.
                   */
                  menuPortalTarget={ref.current}
                  menuPosition={'absolute'}
                  styles={{
                    menuPortal: (menuPortalProps) => ({
                      ...menuPortalProps,
                      zIndex: 1065,
                      right: 0,
                      left: 0,
                      top: '100%',
                      width: 'auto'
                    })
                  }}
                />

                <FieldsPopover
                  className='width-6'
                  defaultValues={typeFilters}
                  onFilter={onFilter}
                >
                  <Filters />
                </FieldsPopover>
              </div>

              <Tooltip
                placement='right'
                trigger={['hover']}
                content={
                  values[switchName] ? activeTooltipText : inactiveTooltipText
                }
              >
                <div>
                  <FormField
                    as={Switch}
                    name={switchName}
                    label={switchLabel}
                    className='mb-2 text-small text-dark-gray text-start'
                    onChange={() => onSwitchChange(values, submitForm)}
                  />
                </div>
              </Tooltip>
            </div>

            <hr className='mb-5 text-light-gray opacity-50'/>

            <div>
              <FormField
                as={TextInput}
                onChange={debouncedSubmitForm}
                onBlur={() => backlogSearchBlur(values)}
                onFocus={() => setIsInputFocused(true)}
                name='q'
                placeholder='Buscar'
                isClearable
                autoComplete='off'
                className='mx-4 mb-2'
                helperText={
                  <span
                    className={classnames(
                      'text-smaller',
                      'text-dark-gray',
                      { 'invisible': !isInputFocused }
                    )}>
                    {searchHints[type]}
                  </span>
                }
                leftAdornment={({ focus }) => (
                  <InputAdornment alignment='left' onClick={focus}>
                    <Icon className='text-primary' name='search' />
                  </InputAdornment>
                )}
              />
            </div>
          </>
        )
      }
    </StoredForm>
  );
}

function getSwitchProps(type) {
  switch (type) {
    case 'deal':
      return {
        name: 'exclude_duplicated',
        label: 'Mostrar apenas negócios que não foram copiados para este funil',
        activeTooltipText: 'Desative para listar negócios que foram ou não copiados.',
        inactiveTooltipText: 'Ative para listar apenas negócios que foram copiados.'
      };
    case 'person':
    case 'organization':
      return {
        name: 'without_deals',
        label: 'Mostrar apenas contatos sem negócios',
        activeTooltipText: 'Desative para listar contatos com ou sem negócios.',
        inactiveTooltipText: 'Ative para listar apenas contatos sem negócios.'
      };
    default:
      return null;
  }
}

function getTypeFilters(type) {
  switch (type) {
    case 'deal':
      return [BacklogDealFilters, dealFiltersDefaulValues];
    case 'organization':
      return [BacklogOrganizationFilters, organizationFiltersDefaulValues];
    case 'person':
      return [BacklogPersonFilters, personFiltersDefaulValues];
    default:
      throw new Error(`Invalid backlog type ${type}`);
  }
}

BacklogForm.propTypes = propTypes;
BacklogForm.defaultProps = defaultProps;

export default BacklogForm;
