import React from 'react';
import Icon from '@/components/Icon';
import { settingsManagePaymentMethodPath } from '@/routes';

function PaymentMethod({ method, lastFourDigits }) {
  let content;
  switch (method) {
    case 'bank_slip':
      content = (
        <>
          <Icon name='bar-code' className='me-2' />
          <span>Boleto</span>
        </>
      );
      break;
    case 'pix':
      content = (
        <>
          <Icon name='pix' className='me-2' />
          <span>PIX</span>
        </>
      );
      break;
    case 'credit_card':
      content = (
        <>
          <Icon
            name={lastFourDigits ? 'card-pay' : 'alert'}
            className={`${lastFourDigits ? '' : 'text-warning'} me-2`}
          />
          <span>
            {lastFourDigits
              ? `•••• •••• •••• ${lastFourDigits}`
              : (
                <>
                  <span className='text-warning'>Ação pendente: </span>
                  <a href={settingsManagePaymentMethodPath()} >
                    Atualizar dados do cartão
                  </a>
                </>
              )
            }
          </span>
        </>
      );
      break;
    default:
      content = (
        <span>
          Nenhum método de pagamento ativo no momento.
        </span>
      );
  }

  if (!content) {
    return null;
  }

  return (
    <div className='d-flex align-items-center text-dark-gray'>
      {content}
    </div>
  );
}

export default PaymentMethod;
