import { useQuery, useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { get, post, patch, _delete } from '@/lib/fetch';

export const emailSignaturesKeys = {
  all: ['emailSignatures'],
  lists: () => [...emailSignaturesKeys.all, 'list'],
  list: (params) => [...emailSignaturesKeys.lists(), params],
  infiniteAll: (params) => [...emailSignaturesKeys.lists(), 'infinite', params]
};

export async function getEmailSignatures(params = {}) {
  const { data } = await get('/email_signatures', params);

  return data;
}

export async function getEmailSignature({ id }) {
  const { data } = await get(`/email_signatures/${id}`);

  return data;
}

export async function deleteEmailSignature({ id }) {
  const { data } = await _delete(`/email_signatures/${id}`);

  return data;
}

export async function createEmailSignature(params = {}) {
  const { data } = await post('/email_signatures', params);

  return data;
}

export async function updateEmailSignature({ id, params = {} } = {}) {
  const { data } = await patch(`/email_signatures/${id}`, params);

  return data;
}

export function useEmailSignatures({ params = {}, config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: emailSignaturesKeys.list(params),
    queryFn: () => getEmailSignatures(params)
  });
}

export function useInfiniteEmailSignatures({ params = {}, config = {} } = {}) {
  return useInfiniteQuery(
    emailSignaturesKeys.infiniteAll(params),
    ({ pageParam: page = 1 }) => getEmailSignatures({ ...params, page }),
    {
      ...config,
      getNextPageParam: (lastPage) => lastPage.meta.next
    }
  );
}

export function useCreateEmailSignature({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries(emailSignaturesKeys.all);

  return useMutation(
    createEmailSignature,
    {
      onSuccess,
      ...config
    }
  );
}

export function useUpdateEmailSignature({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries(emailSignaturesKeys.all);

  return useMutation(
    updateEmailSignature,
    {
      onSuccess,
      ...config
    }
  );
}

export function useDeleteEmailSignature({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries(emailSignaturesKeys.all);

  return useMutation(
    deleteEmailSignature,
    {
      onSuccess,
      ...config
    }
  );
}


