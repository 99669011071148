import React, { useEffect } from 'react';

import { useDebounce } from '@/hooks';
import { Col, Row } from 'react-bootstrap';
import { EmailSelect, RichTextInput, TextInput } from '@/components/Inputs';
import FormField from '@/components/FormField';
import Tooltip from '@/components/Tooltip';
import Button from '@/components/Button';
import EmailTemplateList from '@/components/Email/EmailTemplateList';
import Popover from '@/components/Popover';
import Authorization from '@/components/Authorization';
import {
  canCreateEmailSignature,
  canDeleteEmailSignature,
  canEditEmailSignature
} from '@/policies';
import Icon from '@/components/Icon';
import ConfirmationModal from '@/components/ConfirmationModal';
import AttachmentUpload from '@/components/EntityModal/Activities/AttachmentUpload';
import LoadingButton from '@/components/LoadingButton';
import PropTypes from 'prop-types';

const DEBOUNCE_DELAY = 400;

const propTypes = {
  from: PropTypes.string,
  dealId: PropTypes.number,
  ccHidden: PropTypes.bool,
  showCC: PropTypes.bool,
  bccHidden: PropTypes.bool,
  showBCC: PropTypes.bool,
  handleBlur: PropTypes.func,
  setShowEmailSignatureModal: PropTypes.func,
  clearFiles: PropTypes.bool,
  setClearFiles: PropTypes.func,
  isSubmitting: PropTypes.bool,
  disableAction: PropTypes.bool,
  setDisableAction: PropTypes.func,
  handleCancel: PropTypes.func,
  setHasTemplate: PropTypes.func,
  emailSignatures: PropTypes.array,
  setBodyValue: PropTypes.func,
  values: PropTypes.object,
  setShowEmailSignatureDeleteModal: PropTypes.func,
  handleEmailSignatureDelete: PropTypes.func,
  showEmailSignatureDeleteModal: PropTypes.bool,
  storedEmail: PropTypes.object,
  storeEmail: PropTypes.func,
  isNewEmail: PropTypes.bool
};

const EmailFormContent = ({
  from, dealId, ccHidden, showCC, bccHidden, showBCC, handleBlur, setShowEmailSignatureModal,
  clearFiles, isSubmitting, disableAction, setClearFiles, setDisableAction, handleCancel,
  setHasTemplate, emailSignatures, setBodyValue, values, setShowEmailSignatureDeleteModal,
  handleEmailSignatureDelete, showEmailSignatureDeleteModal, isNewEmail, storedEmail, storeEmail
}) => {
  const debounceHandler = useDebounce((fn) => fn(), DEBOUNCE_DELAY);

  useEffect(() => {
    if (isNewEmail) {
      const newStoredEmail = {
        ...storedEmail,
        [dealId]: {
          subject: '',
          body: '',
          to: [],
          cc: [],
          bcc: []
        }
      };
      return debounceHandler(() => {
        newStoredEmail[dealId].to = values.to;
        newStoredEmail[dealId].subject = values.subject;
        newStoredEmail[dealId].cc = values.cc;
        newStoredEmail[dealId].bcc = values.bcc;
        newStoredEmail[dealId].body = values.body;

        storeEmail(newStoredEmail);
      });
    }
  }, [JSON.stringify(values)]);

  return (
    <>
      <Row className='mt-6 gx-3'>
        <Col sm={12}>
          <TextInput
            name='from'
            label='De'
            disabled
            variant='light'
            value={from}
          />
        </Col>
      </Row>
      <Row className='gx-0'>
        <Col className='vwidth'>
          <FormField
            name='to'
            as={EmailSelect}
            className='col-md-auto'
            label='Para'
            placeholder='Selecione'
            multiple
            autoAdjustValues
            isSearchable
            dealId={dealId}
          />
        </Col>
        <Col className='col-auto mt-6 gx-0'>
          <span className={`${ccHidden ? '' : 'd-none'}`}>
            <Tooltip content='Com cópia'>
              <Button
                variant='outline-dark-gray'
                className='rounded-1 ms-3'
                onClick={showCC}
              >
                Cc
              </Button>
            </Tooltip>
          </span>
          <span className={`${bccHidden ? '' : 'd-none'}`}>
            <Tooltip content='Com cópia oculta'>
              <Button
                variant='outline-dark-gray'
                className='rounded-1 ms-2'
                onClick={showBCC}
              >
                Cco
              </Button>
            </Tooltip>
          </span>
        </Col>
      </Row>
      <Row className={`${ccHidden ? 'd-none' : ''} mt-3 gx-3`}>
        <Col sm={12}>
          <FormField
            name='cc'
            as={EmailSelect}
            className='col-md-auto'
            label='Cc'
            placeholder='Selecione'
            multiple
            autoAdjustValues
            isSearchable
            dealId={dealId}
          />
        </Col>
      </Row>
      <Row className={`${bccHidden ? 'd-none' : ''} mt-3 gx-3`}>
        <Col sm={12}>
          <FormField
            name='bcc'
            as={EmailSelect}
            className='col-md-auto'
            label='Cco'
            placeholder='Selecione'
            multiple
            autoAdjustValues
            isSearchable
            dealId={dealId}
          />
        </Col>
      </Row>
      <Row className='mt-3 gx-3'>
        <Col className='vwidth'>
          <FormField
            as={TextInput}
            name='subject'
            label='Assunto'
            autoComplete='off'
            placeholder='Digite aqui...'
            maxLength={150}
            variant='light'
            onBlur={handleBlur}
          />
        </Col>
        <Col className='col-auto mt-7 mb-4'>
          <EmailTemplateList
            dealId={dealId}
            onSelect={(template) => setHasTemplate(Boolean(template))}
          />
        </Col>
      </Row>
      <Row className='gx-3'>
        <Col sm={12}>
          <FormField
            as={RichTextInput}
            className='mb-4'
            name='body'
            autoFocus={true}
          />
        </Col>
      </Row>
      <Row className='gx-3'>
        <Col sm={12}>
          {
            emailSignatures.length
              ? (
                <Popover
                  placement='right'
                  contentClassName='width-5'
                  trigger={['click']}
                  content={({ onHide: onHidePopover }) => (
                    emailSignatures.map((signature) => (
                      <Row key={signature.id}>
                        <Col sm={6} className='d-flex'>
                          <Button
                            className='px-1 text-dark' setSubmitting
                            onClick={() => {
                              setBodyValue(`${values.body}<br/>${signature.body}`);
                              onHidePopover();
                            }}
                          >
                            {signature.name}
                          </Button>
                        </Col>
                        <Col sm={6} className='d-flex justify-content-end'>
                          <Authorization policy={canEditEmailSignature}>
                            <Button
                              className='p-1'
                              variant='link'
                              onClick={() => {
                                setShowEmailSignatureModal(true);
                                onHidePopover();
                              }}
                              aria-label='Editar assinatura'
                            >
                              <Icon name='edit-field' />
                            </Button>
                          </Authorization>
                          <Authorization policy={canDeleteEmailSignature}>
                            <Button
                              className='text-danger p-0'
                              variant='link'
                              onClick={() => setShowEmailSignatureDeleteModal(true)}
                              aria-label='Excluir assinatura'
                            >
                              <Icon name={'delete'} />
                            </Button>
                          </Authorization>
                          <ConfirmationModal
                            confirmationText='Sim, excluir'
                            show={showEmailSignatureDeleteModal}
                            onConfirm={handleEmailSignatureDelete}
                            onHide={() => setShowEmailSignatureDeleteModal(false)}
                          >
                            <ConfirmationModal.Title>
                              Deseja excluir?
                            </ConfirmationModal.Title>

                            <ConfirmationModal.Info>
                              Você deseja realmente excluir essa assinatura?
                            </ConfirmationModal.Info>
                          </ConfirmationModal>
                        </Col>
                      </Row>
                    ))
                  )}
                >
                  <Button
                    variant='dark-gray'
                    className='me-2'
                    aria-label='Assinatura'
                  >
                    <Icon name='signature' className='me-2' />
                    Assinatura
                  </Button>
                </Popover>
              )
              : (
                <Authorization policy={canCreateEmailSignature}>
                  <Button
                    variant='link'
                    className='fw-bold px-2 ms-n3'
                    onClick={() => setShowEmailSignatureModal(true)}
                  >
                    <Icon name='edit-field' className='me-2' />
                    Adicionar assinatura
                  </Button>
                </Authorization>
              )
          }
        </Col>
      </Row>

      <Row className='mt-3 gx-0'>
        <Col sm={6}>
          <FormField
            as={AttachmentUpload}
            name='attachments'
            clearFiles={clearFiles}
            disabled={isSubmitting || disableAction}
            onClearFiles={() => setClearFiles(false)}
            onUploadStart={() => setDisableAction(true)}
            onUploadFinish={() => setDisableAction(false)}
            onUploadError={() => setDisableAction(false)}
          />
        </Col>

        <Col sm={6} className='d-flex justify-content-end align-self-end'>
          <Button
            variant='outline-dark-gray'
            className='me-2'
            disabled={isSubmitting || disableAction}
            onClick={handleCancel}
          >
            Cancelar
          </Button>
          <LoadingButton
            isLoading={isSubmitting || disableAction}
            type='submit'
            loadingText='Aguarde...'
          >
            Enviar e-mail
          </LoadingButton>
        </Col>
      </Row>
    </>
  );
};

EmailFormContent.propTypes = propTypes;

export default EmailFormContent;
