import { useQuery, useMutation } from 'react-query';
import { get, post } from '@/lib/fetch';

export const billingTransactionTemplateKeys = {
  details: () => ['detail'],
  detail: (id) => [...billingTransactionTemplateKeys.details(), id]
};

export async function getTransaction(id) {
  const { data } = await get(`/billing/billing_transactions/${id}`);

  return data;
}

export async function createTransaction(params = {}) {
  const { data } = await post('/billing/billing_transactions', params);

  return data;
}

export function useTransaction({ billingTransactionId, config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: billingTransactionTemplateKeys.detail(billingTransactionId),
    queryFn: () => getTransaction(billingTransactionId)
  });
}

export function useCreateTransaction({ config = {} } = {}) {
  return useMutation(
    createTransaction,
    { ...config }
  );
}
