import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { useAlert } from 'react-alert';
import Button from '@/components/Button';
import {
  backlogItemsKeys,
  backlogsKeys,
  funnelStagesKeys,
  funnelsKeys,
  stagesKeys,
  useImport
} from '@/api';
import { useTimeout } from '@/hooks';
import image from 'images/billing/relax.svg';
import loading from 'images/full-page-loading.gif';

const propTypes = {
  selectedLeadsCount: PropTypes.number,
  importId: PropTypes.number,
  onFinishImport: PropTypes.func
};

const REFETCH_INTERVAL = 5000;
const TIMEOUT = 300_000;

function LeadsGenerationSuccess({
  selectedLeadsCount, importId, onFinishImport, onClick
}) {
  const alert = useAlert();

  const [timeoutFinished, setTimeoutFinished] = useState(false);

  useTimeout(() => setTimeoutFinished(true), TIMEOUT);

  const queryClient = useQueryClient();

  useImport({
    importId,
    config: {
      refetchInterval: REFETCH_INTERVAL,
      cacheTime: 0,
      enabled: !timeoutFinished && Boolean(importId),
      onSuccess: async ({ data }) => {
        if (data.status !== 'finished') {
          return;
        }

        await Promise.all([
          queryClient.invalidateQueries(funnelsKeys.all),
          queryClient.invalidateQueries(stagesKeys.all),
          queryClient.invalidateQueries(funnelStagesKeys.all),
          queryClient.invalidateQueries(backlogsKeys.all),
          queryClient.invalidateQueries(backlogItemsKeys.infiniteAll),
          queryClient.invalidateQueries(funnelStagesKeys.infiniteAll)
        ]);

        const { info } = data;

        if (info.organizationsCreated) {
          alert.show(
            `${info.organizationsCreated} empresas criadas com sucesso.`,
            { variant: 'success', timeout: 5000 }
          );
        }

        if (info.organizationsUpdated) {
          alert.show(
            `${info.organizationsUpdated} empresas atualizadas com sucesso.`,
            { variant: 'success', timeout: 5000 }
          );
        }

        alert.show(
          `${info.dealsCreated} negócios criados com sucesso.`,
          { variant: 'success', timeout: 5000 }
        );

        onFinishImport(data);
      }
    }
  });

  if (timeoutFinished) {
    return (
      <>
        <img src={image} width={190} className='mb-2 width-5 height-5'/>
        <h2 className='mb-2'>Seus leads estão sendo gerados</h2>

        <p className='text-dark-gray mb-7'>
          Este processo pode ser um pouco demorado, fique tranquilo que
          enviaremos um e-mail avisando quando for finalizado.
        </p>

        <Button onClick={onClick}>Fechar</Button>
      </>
    );
  }

  return (
    <>
      <img width={190} src={image} className='mb-2' />

      <h2 className='mb-2'>Pagamento efetuado com sucesso!</h2>

      <div className='text-dark-gray mb-7'>
        {
          `Estamos criando ${selectedLeadsCount} empresas e
          ${selectedLeadsCount} negócios no funil escolhido`
        }
      </div>

      <img width={120} src={loading} className='mb-7' />

      <div className='text-small text-dark-gray'>
        Você será avisado por e-mail quando tudo estiver pronto
      </div>
    </>
  );
}

LeadsGenerationSuccess.prototype = propTypes;

export default LeadsGenerationSuccess;
