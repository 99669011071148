import React, { useMemo } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import Button from '@/components/Button';
import Modal from '@/components/Modal';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import LoadingButton from '@/components/LoadingButton';
import { TextInput, RichTextInput } from '@/components/Inputs';

const propTypes = {
  show: PropTypes.bool,
  onCancel: PropTypes.func,
  isCreating: PropTypes.bool,
  emailSignature: PropTypes.object
};

const defaultProps = {
  show: false,
  onCancel: () => {},
  isCreating: true,
  emailSignature: {}
};


const emailSignatureSchema = Yup.object()
  .shape({
    name: Yup.string().required('Informe nome da assinatura de e-mail.'),
    body: Yup.string().required('Corpo da assinatura é obrigatório.')
  });

const defaultValues = {
  name: 'Principal',
  body: ''
};

function EmailSignatureModal({ show, onCancel, onSubmit, isCreating, emailSignature }) {
  const TITLE = `${isCreating ? 'Criar' : 'Editar'} uma assinatura de e-mail`;

  const initialValues = useMemo(() => ({
    name: emailSignature?.name ?? defaultValues.name,
    body: emailSignature?.body ?? defaultValues.body
  }), [emailSignature]);

  return (
    <Modal onHide={onCancel} size='xl' bodyClassName='p-8' show={show}>
      <h2 className='text-darker-gray me-2 mb-4'>{TITLE}</h2>
      <Form
        name='create-email-signature-form'
        validationSchema={emailSignatureSchema}
        defaultValues={defaultValues}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <>
            <Row>
              <Col sm={12}>
                <FormField
                  as={TextInput}
                  name='name'
                  label='Nome'
                  autoComplete='off'
                  placeholder='Dê um nome para a assinatura de e-mail'
                  hint='Obrigatório'
                  maxLength={60}
                  variant='light'
                />
              </Col>
            </Row>
            <Row className='gx-3'>
              <Col sm={12}>
                <FormField
                  as={RichTextInput}
                  className='mb-4'
                  name='body'
                  label='Assinatura'
                  hint='Obrigatório'
                  variant='light'
                />
              </Col>
            </Row>

            <div className='mt-6 d-flex justify-content-end'>
              <Button
                onClick={onCancel}
                variant='outline-dark-gray'
                className='me-3'
              >
                Cancelar
              </Button>

              <LoadingButton
                isLoading={isSubmitting}
                type='submit'
              >
                Salvar
              </LoadingButton>
            </div>
          </>
        )}
      </Form>
    </Modal>
  );
}

EmailSignatureModal.propTypes = propTypes;
EmailSignatureModal.defaultProps = defaultProps;

export default EmailSignatureModal;
