import React, { useContext, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Form from 'react-bootstrap/Form';
import TextareaAutosize from 'react-textarea-autosize';

const propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
  rows: PropTypes.number,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  variant: PropTypes.oneOf(['light', 'white']),
  toolbar: PropTypes.node,
  innerClassName: PropTypes.string
};

const defaultProps = {
  className: 'mb-4',
  placeholder: '',
  label: null,
  hint: null,
  onChange: () => {},
  error: null,
  rows: 2,
  minRows: 2,
  maxRows: 4,
  variant: 'light',
  toolbar: null,
  innerClassName: ''
};

const TextAreaContext = React.createContext({});

const TextArea = React.forwardRef(
  (props, forwardedRef) => {
    const {
      className,
      name,
      label,
      hint,
      onChange,
      value,
      error,
      variant,
      toolbar,
      innerClassName,
      ...rest
    } = props;

    const newRef = useRef(null);
    const ref = forwardedRef ?? newRef;

    const handleChange = (event) => onChange(event.target.value);
    const id = useMemo(() => `${name}-${Date.now()}`, [name]);

    const update = (updater) => {
      const newValue = updater(value);

      onChange(newValue);

      ref.current?.focus();
    };

    return (
      <TextAreaContext.Provider value={{ update }}>
        <Form.Group className={className} controlId={id}>
          {(label || hint) &&
            <div className='d-flex align-items-base justify-content-between'>
              <Form.Label>{label}</Form.Label>
              <Form.Text className='text-medium-gray fst-italic text-small mt-0'>
                {hint}
              </Form.Text>
            </div>
          }

          <div className={classnames(
            'position-relative',
            { 'is-invalid': Boolean(error) }
          )}>
            <Form.Control
              as={TextareaAutosize}
              ref={ref}
              name={name}
              value={value}
              onChange={handleChange}
              isInvalid={Boolean(error)}
              className={classnames(
                { 'bg-white': variant === 'white' },
                { 'pb-8 min-height-3': Boolean(toolbar) },
                innerClassName
              )}
              { ...rest }
            />

            {toolbar}
          </div>

          <Form.Control.Feedback type='invalid'>
            {error}
          </Form.Control.Feedback>
        </Form.Group>
      </TextAreaContext.Provider>
    );
  }
);

TextArea.propTypes = propTypes;
TextArea.defaultProps = defaultProps;
TextArea.displayName = 'TextArea';

export default TextArea;

export function useTextArea() {
  const context = useContext(TextAreaContext);

  if (context === undefined) {
    throw new Error('useTextArea must be used within TextArea');
  }

  return context;
}
