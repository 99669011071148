import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@/components/Tooltip';
import Icon from '@/components/Icon';

const propTypes = {
  tooltip: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string
};

const defaultProps = {
  tooltip: 'Automação',
  className: 'text-primary me-1',
  size: 'sm'
};

function AutomationTag({ tooltip, className, size }) {
  return (
    <>
      <Tooltip content={tooltip}>
        <div className='d-inline-flex align-items-center'>
          <Icon
            name='lightning'
            className={className}
            size={size}
          />
        </div>
      </Tooltip>

      Automação
    </>
  );
}

AutomationTag.propTypes = propTypes;
AutomationTag.defaultProps = defaultProps;

export default AutomationTag;
