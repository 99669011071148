import React from 'react';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Authorization from '@/components/Authorization';
import { canGenerateLeads } from '@/policies/leads-generation';

const propTypes = {
  onPresentationFinished: PropTypes.func.isRequired
};

function HowItWorksContent({ onPresentationFinished }) {
  return (
    <div className='d-flex flex-column align-items-center' id='how-it-works-content'>
      <div className='d-flex flex-column align-items-center pb-6 text-center'>
        <h2 className='mb-1 text-center'>Como funciona?</h2>
        <div className='width-4 border-bottom border-flat-green border-4 text-center'/>
      </div>

      <div className='d-flex justify-content-center pt-6'>
        <div className='d-flex position-relative'>

          <div className='mb-9'>
            <div className='d-flex'>
              <div className='d-flex flex-column align-items-center me-6'>
                <div className='time-line-circle rounded-circle border border-5' />
                <div className='time-line-box border-start border-1' />
              </div>
              <div>
                <span className='text-dark-gray fw-bold'>PASSO 1</span>
                <h4 className='mt-3 pb-9 mb-2'>
                  Você insere informações relacionadas ao seu processo de<br />
                  prospecção e CNPJs de clientes ideais
                </h4>
              </div>
            </div>

            <div className='d-flex'>
              <div className='d-flex flex-column align-items-center me-6'>
                <div className='time-line-circle rounded-circle border border-5' />
                <div className='time-line-box border-start border-1' />
              </div>
              <div>
                <span className='text-dark-gray fw-bold'>PASSO 2</span>
                <h4 className='mt-3 pb-9 mb-2'>
                  Nós buscamos empresas com um perfil semelhante ao dos<br />
                  seus clientes e montamos uma lista de leads personalizada<br />
                  para você com informações de contato e decisores.
                </h4>
              </div>
            </div>

            <div className='d-flex'>
              <div className='me-6'>
                <div className='time-line-circle rounded-circle border border-5' />
              </div>
              <div>
                <span className='text-dark-gray fw-bold'>PASSO 3</span>
                <h4 className='mt-3 pb-9 mb-2'>
                  Você escolhe os leads que deseja gerar e em qual funil eles<br />
                  devem ser criados. Assim que o pagamento for compensado,<br />
                  os leads são criados como empresas e negócios na sua conta.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Authorization policy={canGenerateLeads}>
        <Button
          onClick={onPresentationFinished}
          className='py-3'
        >
          <span className='h4'>Experimentar</span>
          <Icon name='double-arrow-right' size='sm' className='ms-2' />
        </Button>
      </Authorization>
    </div>
  );
}

HowItWorksContent.propTypes = propTypes;

export default HowItWorksContent;
