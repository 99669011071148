import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import Collapse from '@/components/Collapse';
import ExternalLink from '@/components/ExternalLink';
import Icon from '@/components/Icon';
import imgImportGuide1 from 'images/import-guide-1.svg';
import imgImportGuide2 from 'images/import-guide-2.svg';
import imgImportGuide3 from 'images/import-guide-3.svg';
import ImportGuideCard from '@/components/ImportGuideCard';
import Modal from '@/components/Modal';
import { sampleImportFileName, sampleImportUrl } from '@/routes';

const propTypes = {
  entity: PropTypes.string.isRequired,
  defaultOpen: PropTypes.bool.isRequired
};

function ImportGuide({ entity, defaultOpen }) {
  const [showVideoTutorialModal, setShowVideoTutorialModal] = useState(false);
  const [showExpandedView, setShowExpandedView] = useState(defaultOpen);

  return (
    <div className='p-4 border rounded border-light'>
      <div className='d-flex align-items-center'>
        <div className='bg-lighter-gray text-dark-gray rounded'>
          <Icon name='help' className='m-1' />
        </div>
        <h4 className='flex-fill ms-2 fw-bold text-dark-gray'>
          Guia para tirar dúvidas como importar dados no Agendor
        </h4>
        <Button
          variant='link'
          className='px-0 ms-4'
          onClick={() => (setShowExpandedView(!showExpandedView))}
        >
          <Icon name={showExpandedView ? 'hidden' : 'visible'} />
          <span className='ms-1 fw-bold'>
            {showExpandedView ? 'Esconder' : 'Exibir'}
          </span>
        </Button>
      </div>

      <Collapse
        in={showExpandedView}
        dimension='height'
        timeout={300}
      >
        <div>
          <div className='mt-1'>
            <h5 className='mb-2 text-dark-gray'>
              Confira essas dicas para ter sucesso na importação
            </h5>
          </div>
          <div className='d-flex'>
            <ImportGuideCard
              className='bg-primary bg-opacity-10'
              bodyText='Baixe nossa planilha que vai te ajudar neste momento.'
              image={imgImportGuide3}
              icon='download'
              title='Planilha de exemplo'
              buttonText='Baixar planilha'
              buttonProps={{
                as: ExternalLink,
                href: sampleImportUrl(entity),
                download: sampleImportFileName(entity)
              }}
            />
            <ImportGuideCard
              title='Vídeo passo a passo'
              className='mx-5 bg-light-yellow'
              bodyText='Confira nosso vídeo explicativo sobre como fazer a importação.'
              image={imgImportGuide2}
              icon='reload'
              buttonText='Ver vídeo'
              buttonProps={{ onClick: () => setShowVideoTutorialModal(true) }}
            />
            <ImportGuideCard
              title='Saiba mais'
              className='bg-light-green'
              bodyText='Leia nosso artigo sobre importação no Agendor.'
              image={imgImportGuide1}
              icon='external-link'
              buttonText='Ler artigo'
              buttonProps={{
                as: ExternalLink,
                target: '_blank',
                href: 'https://ajuda.agendor.com.br/pt-BR/collections/1748934-importacao-e-exportacao'
              }}
            />
          </div>
        </div>
      </Collapse>

      <Modal
        show={showVideoTutorialModal}
        onHide={() => (setShowVideoTutorialModal(false))}
        size='xl'
        bodyClassName='p-0 height-6'
        backdrop='static'
        restoreFocus={false}
        onExited={() => (setShowVideoTutorialModal(false))}
      >
        <iframe
          width='100%'
          height='100%'
          src='https://www.youtube.com/embed/GeqqYjqYBLY'
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; picture-in-picture'
          allowFullScreen
        >
        </iframe>
      </Modal>

    </div>
  );
}

ImportGuide.propTypes = propTypes;

export default ImportGuide;
