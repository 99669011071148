import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import * as Yup from 'yup';

import { plansPath } from '@/routes';
import { useAuth } from '@/lib/auth';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import HookFormField from '@/components/HookFormField';
import Icon from '@/components/Icon';
import { RadioButton, PrivacyCandidateSelect } from '@/components/Inputs';

export const schema = Yup.object().shape({
  privacy: Yup.object().shape({
    type: Yup.string()
      .oneOf(['open', 'closed', 'default'])
  })
});

export const defaultValues = {
  privacy: {
    type: 'default',
    additional_users_ids: null
  }
};

export const getInitialValues = (entity) => {
  const { privacy: { type, additionalPrivacies } } = entity;

  const privacyUsersIds = additionalPrivacies.map((privacy) => {
    const { user } = privacy;

    return user.id;
  });

  return {
    privacy: {
      type,
      additional_users_ids: privacyUsersIds
    }
  };
};

function OpenLabel() {
  return (
    <h5 className='ms-3'>
      <div className='d-flex align-items-center'>
        <Icon name='person' size='sm' className='me-2' />
        <div>Todos</div>
      </div>

      <div className='text-muted fw-normal mt-1'>
        Todos os usuários da conta terão acesso.
      </div>
    </h5>
  );
}

function ClosedLabel() {
  return (
    <h5 className='ms-3'>
      <div className='d-flex align-items-center'>
        <Icon name='lock' size='sm' className='me-2' />
        <div>Acesso restrito</div>
      </div>

      <div className='text-muted fw-normal mt-1'>
        <div>
          O responsável, seus líderes e os administradores da conta sempre terão acesso.
        </div>

        <div>
          Você também pode conceder acesso a outros usuários.
        </div>
      </div>
    </h5>
  );
}

function FreeUserWarning({ user }) {
  return (
    <>
      {!user.account.paid && (
        <Alert
          variant='warning'
          className='mb-4 w-100'
        >
          <div>
            Esta funcionalidade só está disponível a partir do Plano Pro.
          </div>

          <Button variant='link' className='border-0 p-0' href={plansPath()}>
            Clique aqui para avaliar o Plano Pro gratuitamente por 7 dias
          </Button>
        </Alert>
      )}
    </>
  );
}

const OPEN = 'open';
const CLOSED = 'closed';

function PrivacyFields() {
  const { setValue, formState } = useFormContext();
  const { user } = useAuth();
  const hasChanged = formState.isDirty;
  const [ownerUserId, privacyType, additionalUserIds] = useWatch({
    name: ['owner_user_id', 'privacy.type', 'privacy.additional_users_ids']
  });

  const hasAccessUsers = ownerUserId || additionalUserIds?.length;

  useEffect(() => {
    if (!user.account.paid) {
      return;
    }

    if (privacyType !== CLOSED) {
      setValue('privacy.additional_users_ids', null);
    }
  }, [privacyType]);

  useEffect(() => {
    const shouldNotRecalculatePrivacy = formState.isSubmitting || user.account.privacy === OPEN;

    if (shouldNotRecalculatePrivacy || !hasChanged) {
      return;
    }

    setValue('privacy.type', hasAccessUsers ? CLOSED : OPEN);
  }, [hasChanged, hasAccessUsers]);

  return (
    <>
      <FreeUserWarning user={user} />
      <fieldset>
        <div className='d-flex align-items-center mb-3'>
          <HookFormField
            as={RadioButton}
            id='privacy-type-open'
            name='privacy.type'
            radioValue={OPEN}
            label={<OpenLabel />}
          />
        </div>

        <hr />

        <div>
          <div className='d-flex align-items-center mt-3'>
            <HookFormField
              as={RadioButton}
              id='privacy-type-closed'
              name='privacy.type'
              radioValue={CLOSED}
              disabled={!user.account.paid}
              label={<ClosedLabel />}
            />
          </div>

          <div className='mt-4'>
            <HookFormField
              as={PrivacyCandidateSelect}
              name='privacy.additional_users_ids'
              ownerUserId={ownerUserId}
              isDisabled={privacyType !== CLOSED}
            />
          </div>
        </div>
      </fieldset>
    </>
  );
}

export default PrivacyFields;
