import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@/components/Icon';
import Select from '@/components/Inputs/Select';
import Tooltip from '@/components/Tooltip';

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func
};

const defaultProps = {
  name: 'type',
  label: 'Tipo do campo',
  placeholder: 'Selecione um tipo de campo',
  multiple: false,
  isClearable: false,
  isSearchable: false,
  defaultValue: null,
  onChange: () => {}
};

const options = [
  {
    leftAdornment: <Icon name='types-text' size='md' />,
    label: 'Texto',
    value: 'text'
  },
  {
    leftAdornment: <Icon name='types-number' size='md' />,
    label: 'Numérico inteiro',
    value: 'integer'
  },
  {
    leftAdornment: <Icon name='types-number-decimal' size='md' />,
    label: 'Numérico decimal',
    value: 'decimal'
  },
  {
    leftAdornment: <Icon name='types-multiselect' size='md' />,
    label: 'Seleção múltipla',
    value: 'multiselect'
  },
  {
    leftAdornment: <Icon name='types-select' size='md' />,
    label: 'Seleção única',
    value: 'select'
  }
];

const getSelect = (props) => <Select { ...props } options={options} />;

function CustomFieldSelect(props) {
  return (
    <>
      {props.disabled
        ? <Tooltip
          content='O tipo do campo customizado não pode ser editado.'
          placement='top'
        >
          <div>
            {getSelect(props)}
          </div>
        </Tooltip>
        : getSelect(props)
      }
    </>
  );
}

CustomFieldSelect.propTypes = propTypes;
CustomFieldSelect.defaultProps = defaultProps;

export default CustomFieldSelect;
