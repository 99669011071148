import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { Col, Container, Row } from 'react-bootstrap';

import { useUpdateSubscriptionContext } from '@/contexts';

import Button from '@/components/Button';
import LoadingButton from '@/components/LoadingButton';

const closePropTypes = {
  onClick: PropTypes.func
};

const closeDefaultProps = {
  onClick: () => { }
};

function Close({ onClick }) {
  return createPortalWithContent(
    <Button
      className='px-6'
      onClick={onClick}
      variant='light'
    >
      Fechar
    </Button>
  );
}

const propTypes = {
  onClickNext: PropTypes.func.isRequired,
  onClickPrev: PropTypes.func,
  buttonNextText: PropTypes.string,
  loadingTextButton: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool
};

/**
 * Esse componente usa Portais do react para renderizar o seu conteúdo no elemento
 * que possuir o ID `manage-plan-footer` pois em alguns casos o botão de "Próximo" deste
 * componente precisava ser usado dentro de outro componente e com ação diferente ao clicar
 */
function ManagePlanFooter(props) {
  const {
    onClickNext,
    buttonNextText,
    isLoading,
    loadingTextButton,
    onClickPrev,
    disabled
  } = props;

  const { prevStep } = useUpdateSubscriptionContext();

  return createPortalWithContent(
    <>
      <Button
        className='me-3'
        onClick={onClickPrev || prevStep}
        variant='outline-dark-gray'
        disabled={isLoading}
      >
        Voltar
      </Button>

      <LoadingButton
        className='width-4'
        onClick={onClickNext}
        isLoading={isLoading}
        loadingText={loadingTextButton}
        disabled={disabled}
      >
        {buttonNextText}
      </LoadingButton>
    </>
  );
}

function createPortalWithContent(content) {
  const element = document.getElementById('manage-plan-footer');

  if (!element) {
    return null;
  }

  return createPortal(
    <Container className='pb-4'>
      <Row>
        <Col className='d-flex justify-content-end'>
          {content}
        </Col>
      </Row>
    </Container>,
    element
  );
}

Close.propTypes = closePropTypes;
Close.defaultProps = closeDefaultProps;

ManagePlanFooter.Close = Close;

ManagePlanFooter.propTypes = propTypes;

export default ManagePlanFooter;
