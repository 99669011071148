import React, { useEffect, useRef } from 'react';
import TextInput from '@/components/Inputs/TextInput';
import Inputmask from 'inputmask';

function formatValue(value, digits) {
  return Number(value).toFixed(digits);
}

function CurrencyInput(props) {
  const { value, onChange, onKeyUp, readOnly, disabled, digits = 2, ...rest } = props;

  const ref = useRef(null);

  const handleChange = () => {
    const newValue = ref.current?.inputmask?.unmaskedvalue?.();
    if (typeof newValue === 'number') {
      onChange?.(formatValue(newValue, digits));
    } else {
      onChange?.(newValue?.toString());
    }
  };

  const handleKeyUp = (event) => {
    onKeyUp?.(event);
    /*
     * Quando o valor está como "0,00" ele possui um bug que não apaga o valor e no estado fica
     * salvo o como "0.00", essa verificação faz com que ele limpe o valor nessa situação
     */
    const canEdit = !readOnly && !disabled;
    if (canEdit && event.key === 'Backspace' && Number(value) === 0) {
      onChange?.('');
    }
  };

  useEffect(() => {
    if (ref.current) {
      const mask = new Inputmask({
        alias: 'decimal',
        rightAlign: false,
        prefix: 'R$ ',
        groupSeparator: '.',
        radixPoint: ',',
        digits,
        allowMinus: false,
        digitsOptional: true,
        numericInput: true,
        unmaskAsNumber: true,
        onBeforePaste: (pasted) => {
          if (!pasted) {
            return '';
          }
          return formatValue(pasted.replaceAll('.', '').replaceAll(',', '.'), digits);
        },
        onBeforeMask: (initial) => {
          if (!initial) {
            return '';
          }
          return formatValue(initial, digits);
        },
        oncleared: () => {
          const newValue = ref.current?.inputmask?.unmaskedvalue?.();
          if (typeof newValue === 'number') {
            onChange?.(formatValue(newValue, digits));
          } else {
            onChange?.('');
          }
        }
      });
      mask.mask(ref.current);
    }
  }, []);

  return (
    <TextInput
      ref={ref}
      { ...rest }
      onChange={handleChange}
      value={value}
      onKeyUp={handleKeyUp}
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}

export default CurrencyInput;
