import React from 'react';
import PropTypes from 'prop-types';
import Button from '@/components/Button';

const propTypes = {
  handlePrevious: PropTypes.func,
  buttonText: PropTypes.string,
  disabled: PropTypes.bool
};

const defaultProps = {
  buttonText: 'Prosseguir',
  disabled: false
};

function FooterButtons({ handlePrevious, buttonText, disabled }) {
  return (
    <div className='d-flex justify-content-end mt-9'>
      {
        handlePrevious && (
          <Button
            onClick={handlePrevious}
            variant='outline-dark-gray'
            className='me-2 rounded'
            size='lg'
            disabled={disabled}
          >
            Voltar
          </Button>
        )
      }

      <Button
        type='submit'
        size='lg'
        className='rounded'
        disabled={disabled}
      >
        {buttonText}
      </Button>
    </div>
  );
}

FooterButtons.propTypes = propTypes;
FooterButtons.defaultProps = defaultProps;

export default FooterButtons;
