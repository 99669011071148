import React, { useEffect } from 'react';
import { useAuthenticationStore } from '@/lib/auth/authenticationStore';
import Modal from '@/components/Modal';
import { setupLoginRedirect } from '@/hooks';
import { subscribeTo } from '@/lib/websocket';
import * as UnauthorizedError from '@/lib/auth/UnauthorizedError';

export function onUnauthorizedError(error) {
  setupLoginRedirect(window.location.pathname);
  useAuthenticationStore.getState().setUnauthorizedError(error);
}

export function UnauthorizedErrorWrapper({ children }) {
  const { unauthorizedError, setUnauthorizedError } = useAuthenticationStore();
  const show = Boolean(unauthorizedError);

  useEffect(() => {
    const subscription = subscribeTo('SessionsChannel');
    subscription.on('message', (message) => {
      if (message === 'invalidated_concurrent') {
        setUnauthorizedError({ isConcurrentSession: true });
      } else {
        console.info('[SessionsChannel] Websocket message: ', message);
      }
    });
  }, []);

  return (
    <>
      {children}

      <Modal
        show={show}
        disableClose
        hideClose
        className='z-index-1090'
        bodyClassName='text-center p-5'
        size='md'
        backdrop='static'
        backdropClassName='z-index-1060'
      >
        <UnauthorizedError.Content />
      </Modal>
    </>
  );
}
