import React, { createContext, useContext } from 'react';
import { useStore } from 'zustand';

import { usePersonModalStore } from '@/store/person-modal';

const PersonModalContext = createContext(undefined);

export function usePersonContext(selector) {
  const context = useContext(PersonModalContext);

  if (context === undefined) {
    throw new Error('usePersonModalContext must be used within PersonModalProvider');
  }

  return useStore(context, selector);
}

export const usePerson = () => usePersonContext(
  (state) => state.person
);

export const useOnUpdate = () => usePersonContext(
  (state) => state.onUpdate
);

export const useOnDelete = () => usePersonContext(
  (state) => state.onDelete
);

export function PersonModalProvider({ children, value }) {
  const [store] = usePersonModalStore(value);

  return (
    <PersonModalContext.Provider value={store}>
      {children}
    </PersonModalContext.Provider>
  );
}
