import React from 'react';
import { useAudioTranscription } from '@/api';
import Modal from '@/components/Modal';
import LoadSpinner from '@/components/LoadSpinner';
import VoipGptFeedback from '@/components/VoipGptFeedback';

function AudioTranscriptionModal({ audioTranscriptionId, show, onHide }) {
  const { data, isLoading } = useAudioTranscription({
    audioTranscriptionId,
    config: {
      enabled: Boolean(audioTranscriptionId && show)
    }
  });

  const audioTranscription = data?.data ?? {};
  const audioContent = audioTranscription?.content ?? '';

  return (
    <Modal
      show={show}
      onHide={onHide}
      size='lg'
      className='audio-transcription-modal'
    >
      <h2 className='m-4'>Transcrição completa</h2>
      {isLoading
        ? (
          <div className='d-flex justify-content-center text-medium-gray ms-4 mt-4 mb-8'>
            <LoadSpinner size='sm' className='d-inline-block me-2' />
              Carregando transcrição...
          </div>
        )
        : (
          <div
            className='audio-transcription-content text-align-justify text-dark-gray ms-4 mt-4 mb-8'
            dangerouslySetInnerHTML={{ __html: audioContent }}
          />
        )}
      <hr className='mx-4 text-light-gray opacity-75 mt-n4 mb-4' />
      <VoipGptFeedback data={audioTranscription} entity='audioTranscription' />
    </Modal>
  );
}

export default AudioTranscriptionModal;
