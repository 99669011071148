import React from 'react';
import PropTypes from 'prop-types';

import { toCurrencyString } from '@/number';
import { useUpdateSubscriptionContext } from '@/contexts';

const propTypes = {
  periodicityText: PropTypes.string,
  renovationDate: PropTypes.string
};

const defaultProps = {
  periodicityText: '',
  renovationDate: ''
};

function CurrentSubscriptionPreview(props) {
  const {
    periodicityText,
    renovationDate
  } = props;

  const {
    currentSubscritionPreview,
    loadedCurrentSubscriptionPreview
  } = useUpdateSubscriptionContext();


  if (!loadedCurrentSubscriptionPreview || !currentSubscritionPreview) {
    return null;
  }

  const planFullName =
  `${currentSubscritionPreview.name} ${currentSubscritionPreview.maxUsersCount}`;

  return (
    <div className='animation-fade-in-delay'>
      <h5>Plano {planFullName}</h5>

      <div>
        <span className='text-dark-gray'>Pago até: </span>
        {renovationDate}
      </div>

      <div>
        <span className='text-dark-gray'>Valor {periodicityText}: </span>
        {toCurrencyString(currentSubscritionPreview.finalValue)}
      </div>
    </div>
  );
}

CurrentSubscriptionPreview.propTypes = propTypes;
CurrentSubscriptionPreview.defaultProps = defaultProps;

export default CurrentSubscriptionPreview;
