import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf([
    'standard',
    'spoiler'
  ])
};

const defaultProps = {
  variant: 'standard'
};

function TemplateCardBox(props) {
  const { children, variant } = props;
  return (
    <div
      className={classnames(
        'p-2',
        'rounded',
        'w-100',
        'overflow-hidden',
        {
          'bg-dark-gray': variant === 'standard',
          'bg-gray': variant === 'spoiler'
        }
      )}
    >
      <div className='d-flex align-items-center height-3'>
        {children}
      </div>
    </div>
  );
}

TemplateCardBox.propTypes = propTypes;
TemplateCardBox.defaultProps = defaultProps;

export default TemplateCardBox;
