import { useMutation } from 'react-query';
import { post } from '@/lib/fetch';

export async function createImportConfirms(params = {}) {
  const { data } = await post('/imports/confirms', params);

  return data;
}

export function useCreateImportConfirms({ config = {} } = {}) {
  return useMutation(
    createImportConfirms,
    { ...config }
  );
}

