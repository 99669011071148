/**
 *
 * Simple wrapper around `localStorage` with JSON serialization.
 *
 */
const storage = {
  get(key) {
    try {
      const raw = localStorage.getItem(key);
      return JSON.parse(raw);
    } catch (error) {
      console.error(error);
    }
  },

  set(key, value) {
    try {
      const json = JSON.stringify(value);
      localStorage.setItem(key, json);
    } catch (error) {
      console.error(error);
    }
  },

  remove(key) {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(error);
    }
  }
};

export default storage;
