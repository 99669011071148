import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useImports, useDeleteImport } from '@/api';
import { useMediaQuery } from '@/hooks';
import { getString } from '@/utils';
import ImportCard from '@/components/ImportCard';
import RevertingConfirmationModal from '@/components/RevertingConfirmationModal';
import APIErrorMessage from '@/components/APIErrorMessage';

const propTypes = {
  entity: PropTypes.oneOf(['deals', 'people', 'organizations', 'products', 'activities'])
};

function ImportHistory({ entity }) {
  const { data: { data } = { data: [] }, isSuccess } = useImports({
    params: { entity }
  });
  const deleteImportMutation = useDeleteImport();
  const alert = useAlert();

  const [showModal, setShowModal] = useState(false);
  const [revertingImportData, setRevertingImportData] = useState();

  const mdLower = useMediaQuery('(max-width: 991px)');

  const handleModalHide = () => setShowModal(false);

  const handleModalConfirm = () => {
    deleteImportMutation.mutate({
      importId: revertingImportData.id,
      params: { failure: revertingImportData.status === 'failed' }
    }, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='import' action='delete' />,
          { variant: 'danger' }
        );

        setShowModal(false);
      },
      onSuccess: () => {
        alert.show(
          'Importação desfeita com sucesso.',
          { variant: 'success', timeout: 5000 }
        );

        setShowModal(false);
      }
    });
  };

  const entityName = getString(['attributes', 'imports', 'entity', entity]);

  const handleImportRevert = (importData) => {
    setRevertingImportData(importData);

    setShowModal(true);
  };

  if (!isSuccess) {
    return null;
  }

  return (
    <>
      <div
        className={
          classnames('d-flex flex-wrap', {
            'flex-column': !mdLower,
            'flex-row': mdLower
          })
        }
      >
        {data.map(
          (_import) => <ImportCard
            key={_import.id}
            importId={_import.id}
            fileName={_import.fileName}
            importedAt={_import.importedAt}
            recordsCount={_import.recordsCount}
            status={_import.status}
            entity={entity}
            entityName={entityName}
            onRevert={() => handleImportRevert(_import)}
            className={
              classnames({
                'me-2': mdLower,
                'width-5': mdLower
              })
            }
          />
        )}
      </div>

      <RevertingConfirmationModal
        show={showModal}
        onHide={handleModalHide}
        onConfirm={handleModalConfirm}
        entity={entity}
        importData={revertingImportData}
      />
    </>
  );
}

ImportHistory.propTypes = propTypes;

export default ImportHistory;
