import React, { useState } from 'react';
import classnames from 'classnames';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import Offcanvas from '@/components/Offcanvas';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Authorization from '@/components/Authorization';
import ConfirmationModal from '@/components/ConfirmationModal';
import APIErrorMessage from '@/components/APIErrorMessage';
import Empty from '@/pages/funnels/stages/required_fields/_empty';
import {
  canCreateRequiredField, canUpdateRequiredField, canDeleteRequiredField
} from '@/policies';
import { settingsFunnelStagesPath } from '@/routes';
import { useRequiredFields, useDeleteRequiredField } from '@/api';
import Truncate from '@/components/Truncate';
import LoadingWrapper from '@/components/LoadingWrapper';
import { useTracking } from '@/lib/tracking';
import { useAuth } from '@/lib/auth';
import RequiredFieldLabel from '@/components/RequiredFieldLabel';
import Tooltip from '@/components/Tooltip';
import FeatureControlPopover from '@/components/FeatureControlPopover';

function FunnelStagesRequiredFieldsIndex() {
  const { funnelId } = useParams();
  const location = useLocation();
  const {
    data: { data: requiredFields = [] } = {},
    isLoading
  } = useRequiredFields({ params: { funnel_id_eq: funnelId } });

  const alert = useAlert();
  const tracker = useTracking();
  const { user } = useAuth();
  const deleteRequiredField = useDeleteRequiredField();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const onDelete = (item) => {
    setDeleteItem(item);
    setShowDeleteModal(true);
  };

  const hasAccess = user.account.legacyPro || user.account.performanceOrHigher;

  const target = location.state?.target;
  if (!target) {
    return <Navigate to={settingsFunnelStagesPath(funnelId)} />;
  }

  const onCancelDelete = () => setShowDeleteModal(false);
  const onConfirmDelete = async () => {
    try {
      await deleteRequiredField.mutateAsync({ id: deleteItem.id });
      alert.show(
        'Regra de campo obrigatório excluída com sucesso.',
        { variant: 'success', timeout: 5000 }
      );
      tracker.trackRequiredFieldDeleted({
        user,
        funnelId,
        target,
        field: deleteItem
      });
    } catch (err) {
      alert.show(
        <APIErrorMessage err={err} resource='required_field' action='delete' />,
        { variant: 'danger' }
      );
    } finally {
      setShowDeleteModal(false);
    }
  };

  const filtered = requiredFields.filter((item) => (
    target.type === 'stage'
      ? item.stage?.id === target.id
      : item.dealStatus?.id === target.id
  ));

  const hasLossReason = requiredFields.find((item) => (
    item.dealStatus?.name === 'Perdido'
  ));

  const standartItem = {
    defaultField: 'loss_reason',
    entity: 'deal'
  };

  const isEmpty = filtered.length === 0 && target.name !== 'Perdido';

  const strings = target.type === 'stage'
    ? { of: 'da etapa', in: 'nesta etapa' }
    : { of: 'do status', in: 'neste status' };

  return (
    <>
      <Offcanvas.Header
        closeButton
        closeLabel='Fechar'
        className='flex-column-reverse align-items-end'
      >
        <Offcanvas.Title className='text-start border-bottom border-1 w-100'>
          <h2>
            Configurar campos {strings.of} {' '}
            &quot;<span className='text-primary'>{target.name}</span>&quot;
          </h2>

          <p className='text-dark-gray fw-normal'>
            Escolha um campo de negócio, empresa ou pessoa que será
            obrigatório {strings.in}.
          </p>
        </Offcanvas.Title>
      </Offcanvas.Header>

      <Offcanvas.Body className='mt-0 pt-0'>
        {
          isEmpty
            ? (
              <LoadingWrapper isLoading={isLoading}>
                <Empty />
              </LoadingWrapper>
            )
            : (
              <div>
                {(hasAccess)
                  ? (
                    <Authorization policy={canCreateRequiredField}>
                      <Button
                        className='mb-5'
                        href='new'
                        state={location.state}
                      >
                        <Icon name='add' className='me-2' />
                      Adicionar campo
                      </Button>
                    </Authorization>
                  )
                  : (
                    <FeatureControlPopover user={user}>
                      <Button variant='primary'>
                        <Icon name='lock' size='sm' className='me-1' />
                        Adicionar campo
                      </Button>
                    </FeatureControlPopover>
                  )}

                <div>
                  <div className={classnames(
                    'mt-5',
                    'd-flex',
                    'align-items-center',
                    'border-1',
                    'border-bottom',
                    'border-darker-gray',
                    'text-uppercase',
                    'fw-bold'
                  )}>
                    <div className='width-6 p-3 flex-shrink-0'>Campo</div>
                  </div>

                  <div className='position-sticky top-0 bg-white z-index-1'>
                    {
                      target.name === 'Perdido' && !hasLossReason && (
                        <div className='d-flex align-items-center border-bottom border-1'>
                          <div className='width-5 ps-0 pe-2 py-2'>
                            <Truncate>
                              <RequiredFieldLabel item={standartItem} icon />
                            </Truncate>
                          </div>

                          <div className='width-4 p-3'>
                            <Tooltip
                              content={'O campo Motivo de perda é obrigatório.'}
                            >
                              <div>
                                <Button
                                  variant='transparent-light'
                                  disabled={true}
                                >
                                  <Icon name='funnel-stage-add' className='me-1'/>
                                  <span>Editar</span>
                                </Button>
                              </div>
                            </Tooltip>
                          </div>
                          <div className='width-4 p-3'>
                            <Tooltip
                              content={'O campo Motivo de perda é obrigatório.'}
                            >
                              <div>
                                <Button
                                  variant='transparent-light'
                                  disabled={true}
                                >
                                  <Icon name='delete' className='me-2' />
                                  <span>Excluir</span>
                                </Button>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      )
                    }

                    {filtered.map((item) => (
                      <div
                        key={item.id}
                        className='d-flex align-items-center border-bottom border-1'
                      >
                        <div className='width-5 ps-0 pe-2 py-2'>
                          <Truncate>
                            <RequiredFieldLabel item={item} icon />
                          </Truncate>
                        </div>

                        <div className='width-4 p-3'>
                          <Authorization policy={canUpdateRequiredField}>
                            <Button
                              variant='transparent-light'
                              href='edit'
                              state={{ data: item, target }}
                              disabled={!hasAccess}
                            >
                              <Icon name='funnel-stage-add' className='me-1'/>
                              <span>Editar</span>
                            </Button>
                          </Authorization>
                        </div>

                        <div className='width-4 p-3'>
                          <Authorization policy={canDeleteRequiredField}>
                            <Button
                              variant='transparent-light'
                              onClick={() => onDelete(item)}
                              disabled={!hasAccess}
                            >
                              <Icon name='delete' className='me-2' />
                              <span>Excluir</span>
                            </Button>
                          </Authorization>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )
        }
      </Offcanvas.Body>

      <ConfirmationModal
        show={showDeleteModal}
        onHide={onCancelDelete}
        confirmationText='Sim, excluir'
        onConfirm={onConfirmDelete}
      >
        <ConfirmationModal.Title>
          Deseja excluir?
        </ConfirmationModal.Title>

        <ConfirmationModal.Info>
          Após a confirmação, o preenchimento do campo
          &quot;<RequiredFieldLabel item={deleteItem} />&quot;
          não será mais obrigatório {strings.in}.
        </ConfirmationModal.Info>
      </ConfirmationModal>
    </>
  );
}

export default FunnelStagesRequiredFieldsIndex;
