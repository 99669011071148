import React from 'react';
import PropTypes from 'prop-types';

import ABTest from '@/components/ABTest';
import ExternalLink from '@/components/ExternalLink';
import Icon from '@/components/Icon';

import { useTracking } from '@/lib/tracking';
import { useAuth } from '@/lib/auth';
import Popover from '@/components/Popover';
import Button from '@/components/Button';
import Link from '@/components/Link';
import Tooltip from '@/components/Tooltip';
import { settingsFunnelsPath } from '@/routes';

const propTypes = {
  isDefault: PropTypes.bool
};

const defaultProps = {
  isDefault: false
};

function FunnelGift(props) {
  const { isDefault } = props;

  const { user } = useAuth();
  const tracker = useTracking();
  const onClickGift = () => tracker.trackFunnelGiftClicked({ user });
  const onClickPlaylist = () => tracker.trackFunnelGiftPlaylistClicked({ user });
  const onClickSettings = () => tracker.trackFunnelGiftSettingsClicked({ user });

  if (!isDefault) {
    return null;
  }

  return (
    <ABTest
      name='funnel_gift'
      identifier={user.account.id.toString()}
    >
      <ABTest.Variant name='A'>
        <Popover
          showArrow
          closeButton
          content={
            () => (
              <>
                <h4 className='mb-4'>
                  Aprenda tudo sobre o funil de vendas
                </h4>

                <iframe
                  width='100%'
                  height='200'
                  src='https://www.youtube.com/embed/uef13_BtlVc?si=0xWdoJl9ugrunb7b'
                  title='YouTube video player'
                  allow=
                    'accelerometer; autoplay; clipboard-write; encrypted-media; picture-in-picture'
                  referrerpolicy='strict-origin-when-cross-origin'
                  allowFullScreen
                  className='mb-4'
                >
                </iframe>

                <p className='text-dark-gray'>
                  O Funil de Vendas é a versão digital do seu processo.
                  Ele te ajuda a priorizar seus negócios para não perder nenhuma venda!
                </p>

                <div className='d-flex gap-2'>
                  <Button
                    variant='outline-dark-gray'
                    as={ExternalLink}
                    href='https://www.youtube.com/playlist?list=PLEC4Wg6WVfJAW1pvHOSmju-n9mCGNrBXE'
                    target='_blank'
                    className='flex-1 gap-2'
                    onClick={onClickPlaylist}
                  >
                    <Icon name='play' size='sm' />

                    Ver playlist
                  </Button>

                  <Button
                    as={Link}
                    href={settingsFunnelsPath()}
                    target='_blank'
                    className='flex-1 gap-2'
                    onClick={onClickSettings}
                  >
                    <Icon name='config' size='sm' />

                    Configurar funis
                  </Button>
                </div>
              </>
            )
          }
          placement='bottom-start'
        >
          <div>
            <Tooltip
              content='Clique aqui para aprender sobre funil e muito mais!'
              placement='right'
            >
              <Button
                variant='link'
                onClick={onClickGift}
                className='px-2'
              >
                <Icon name='news' size='md' />
              </Button>
            </Tooltip>
          </div>
        </Popover>
      </ABTest.Variant>

      <ABTest.Variant name='B' />
    </ABTest>
  );
}

FunnelGift.propTypes = propTypes;
FunnelGift.defaultProps = defaultProps;

export default FunnelGift;
