import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useCookies } from '@/hooks';
import { legacyLoginUrl } from '@/routes';
import { redirect } from '@/browser';

function Login() {
  const location = useLocation();
  const { setupLoginRedirect } = useCookies();

  useEffect(() => {
    setupLoginRedirect(location.pathname);
    redirect(legacyLoginUrl());
  });

  return null;
}

export default Login;
