import React from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import DealStepHeader from '@/components/CreateDealModal/DealStepHeader';
import DealForm from '@/components/CreateDealModal/DealForm';
import APIErrorMessage from '@/components/APIErrorMessage';
import { getValidationErrors } from '@/utils';
import { useFunnelStageMove } from '@/api';
import { useTracking } from '@/lib/tracking';
import { useAuth } from '@/lib/auth';

const propTypes = {
  /** Entidade 'origem' do negócio que será criado. */
  entity: PropTypes.shape({
    type: PropTypes.oneOf(['organization', 'person', 'deal']),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string
  }),
  /** Funil em que o negócio será criado. */
  funnel: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  /** ID da etapa em que o negócio será criado. */
  stageId: PropTypes.number,
  /** Dados para pré-preenchimento do formulário.   */
  template: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    value: PropTypes.string,
    entityProducts: PropTypes.arrayOf(PropTypes.object)
  }),
  /** Campos customizados de negócio. */
  customFields: PropTypes.array,
  /** Callback chamado ao cancelar a criação de negócio. */
  onCancel: PropTypes.func,
  /** Callback chamando quando o formulário é enviado. */
  onSubmit: PropTypes.func,
  /** Callback chamado quando o negócio é criado com sucesso. */
  onSuccess: PropTypes.func.isRequired,
  /** Callback chamado quando ocorre erro na criação do negócio. */
  onError: PropTypes.func,
  /** Função que avança para o próximo passo. */
  advanceStep: PropTypes.func
};

const defaultProps = {
  entity: {},
  template: {},
  customFields: [],
  onCancel: () => {},
  onSubmit: () => {},
  onError: () => {},
  advanceStep: () => {}
};

function DealStep(props) {
  const {
    entity, funnel, stageId, template, customFields,
    onSuccess, onCancel, onSubmit, onError, advanceStep
  } = props;

  const tracker = useTracking();
  const mutation = useFunnelStageMove();
  const alert = useAlert();
  const { user } = useAuth();

  const handleSubmit = (formData, { setFieldError, setSubmitting }) => {
    onSubmit(formData);

    // Isso é meio estranho, mas é para aderir à mesma API utilizada antes.
    const itemId = `${entity.type}-${entity.id}`;

    mutation.mutate({
      funnelId: funnel.id,
      stageId,
      params: { fromBacklog: true, itemId, ...formData }
    }, {
      onSuccess: ({ data }) => {
        tracker.trackDealCreated({
          source: 'funnel',
          entityCustomFields: customFields,
          formData,
          user
        });
        alert.show(
          `O negócio ${data.title} foi adicionado no ${funnel.name}`,
          { variant: 'success', timeout: 5000 }
        );

        onSuccess(data);
        advanceStep();
      },
      onError: (err) => {
        const errors = getValidationErrors(err, 'deal');

        Object.entries(errors).forEach(([key, message]) => {
          setFieldError(key, message);
        });
        setSubmitting(false);

        alert.show(
          <APIErrorMessage
            err={err}
            resource={entity.type}
            target='deal'
            action='createDeal'
          />,
          { variant: 'danger' }
        );

        onError(err);
      }
    });
  };

  return (
    <div>
      <DealStepHeader
        entity={entity}
        funnel={funnel}
      />

      <DealForm
        initialData={template}
        customFields={customFields}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        entity={entity}
      />
    </div>
  );
}

DealStep.propTypes = propTypes;
DealStep.defaultProps = defaultProps;

export default DealStep;
