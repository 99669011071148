import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import Table from '@/components/Table';
import FormField from '@/components/FormField';
import { ImportMappingSelect } from '@/components/Inputs';
import { canCreateCustomField } from '@/policies';
import Authorization from '@/components/Authorization';
import Button from '@/components/Button';
import CustomFieldFormModal from '@/components/CustomFieldFormModal';
import Icon from '@/components/Icon';
import {
  useCreateDealsCustomField,
  useCreateOrganizationsCustomField,
  useCreatePeopleCustomField
} from '@/api';
import { useAuth } from '@/lib/auth';
import { useAlert } from 'react-alert';
import { useTracking } from '@/lib/tracking';

const propTypes = {
  resource: PropTypes.oneOf([
    'people',
    'organizations',
    'products',
    'activities',
    'deals'
  ]).isRequired,
  selectedColumns: PropTypes.array,
  defaultColumns: PropTypes.object
};

const defaultProps = {
  selectedColumns: [],
  defaultColumns: {}
};

const mutations = {
  people: useCreatePeopleCustomField,
  organizations: useCreateOrganizationsCustomField,
  products: () => ({ mutate: () => {} }),
  activities: () => ({ mutate: () => {} }),
  deals: useCreateDealsCustomField
};

const entitySingular = {
  people: 'person',
  organizations: 'organization',
  products: 'product',
  activities: 'activity',
  deals: 'deal'
};

function MappingTable({ resource, selectedColumns, defaultColumns }) {
  const [showModal, setShowModal] = useState(false);
  const [fieldPosition, setFieldPosition] = useState();
  const alert = useAlert();
  const { user } = useAuth();
  const tracker = useTracking();

  const { setFieldValue } = useFormikContext();

  const selectedTargets = useMemo(() => (
    selectedColumns.map((value) => (value.target))
  ), [selectedColumns]);

  const enableCreateCustomField = ['organizations', 'people', 'deals'].includes(resource);

  const mutation = mutations[resource]();

  const onCreateCustomField = ({ data }) => {
    alert.show(
      'Campo customizado criado!',
      { variant: 'success', timeout: 5000 }
    );

    tracker.trackCustomFieldCreated({
      user,
      entity: entitySingular[resource],
      field: data
    });

    setShowModal(false);

    const fieldName = `mappings.${fieldPosition}.target`;
    const fieldValue = `custom_field.${data.identifier}`;

    setFieldValue(fieldName, fieldValue);
  };

  const onClickAddCustomField = (index) => {
    setFieldPosition(index);
    setShowModal(true);
  };

  return (
    <Table>
      <thead>
        <tr>
          {
            selectedColumns.map((selectedColumn, index) => {
              const disabledOptions = selectedTargets.filter(
                (target) => (target !== selectedColumn.target)
              );

              return (
                <th
                  key={index}
                  className='min-width-5 max-width-5 p-0 border-0 align-top'
                >
                  <FormField
                    as={ImportMappingSelect}
                    name={`mappings.${index}.target`}
                    className='w-100 mb-0 pe-3 text-no-transform fw-normal'
                    defaultColumns={defaultColumns}
                    resource={resource}
                    disabledOptions={disabledOptions}
                    footer={enableCreateCustomField && (
                      <Authorization policy={canCreateCustomField}>
                        <Button
                          variant='primary'
                          className='w-100 rounded-0 text-small py-3'
                          onClick={() => onClickAddCustomField(index)}
                        >
                          <Icon name='plus' className='me-2' size='sm' />

                          Adicionar campo customizado
                        </Button>
                      </Authorization>
                    )}
                  />
                </th>
              );
            })
          }

          {enableCreateCustomField && (
            <CustomFieldFormModal
              title='Criar campo customizado'
              show={showModal}
              mutation={mutation}
              section={resource === 'deals' ? 'basic_data' : undefined}
              onFormSuccess={onCreateCustomField}
              onHide={() => setShowModal(false)}
            />
          )}
        </tr>
      </thead>
    </Table>
  );
}

MappingTable.propTypes = propTypes;
MappingTable.defaultProps = defaultProps;

export default MappingTable;
