import { useQuery } from 'react-query';
import { get } from '@/lib/fetch';

export const freezingReasonsKey = {
  all: ['deal-freezing-reasons'],
  lists: () => [...freezingReasonsKey.all, 'list'],
  list: (params) => [...freezingReasonsKey.lists(), params]
};

export async function getFreezingReasons(params = {}) {
  const { data } = await get('/deal_freezing_reasons', params);

  return data;
}

export function useFreezingReasons({ params = {}, config = {} }) {
  return useQuery({
    ...config,
    queryKey: freezingReasonsKey.list(params),
    queryFn: () => getFreezingReasons()
  });
}
