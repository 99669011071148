import React from 'react';
import PropTypes from 'prop-types';
import Authorization from '@/components/Authorization';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import { canEditDeal } from '@/policies';
import { dealEditPath } from '@/routes';

EmptyContact.propTypes = {
  deal: PropTypes.object
};

EmptyContact.defaultProps = {
  deal: {}
};

function EmptyContact({ deal }) {
  return (
    <>
      <div className='text-dark-gray p-0'>
        Não existe nenhuma empresa ou pessoa ligada a <br /> este negócio.
      </div>
      <Authorization policy={canEditDeal}>
        <Button
          href={dealEditPath(deal?.id)}
          variant='lighter-gray'
          className='text-primary mt-3'
        >
          <Icon name='plus' size='sm' className='me-3' />
          Adicionar empresa/pessoa
        </Button>
      </Authorization>
    </>
  );
}

export default EmptyContact;

