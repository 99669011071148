import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import LoadSpinner from '@/components/LoadSpinner';
import { useDealTemplate } from '@/api';

const propTypes = {
  /** Entidade 'origem' do negócio que será criado. */
  entity: PropTypes.shape({
    type: PropTypes.oneOf(['organization', 'person', 'deal']),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string
  }),
  /** Callback chamado quando o template é buscado com sucesso. */
  onSuccess: PropTypes.func.isRequired,
  /** Função que avança para o próximo passo. */
  advanceStep: PropTypes.func
};

const defaultProps = {
  entity: {},
  advanceStep: () => {}
};

function TemplateStep({ entity, onSuccess, advanceStep }) {
  useDealTemplate({
    params: getQueryParams(entity),
    config: {
      onSuccess: ({ data }) => {
        onSuccess(data);
        advanceStep();
      }
    }
  });

  return (
    <LoadSpinner
      size='lg'
      className={classnames(
        'd-flex',
        'align-items-center',
        'justify-content-center',
        'text-dark-gray',
        'opacity-100',
        'height-4',
        'py-6'
      )}
    />
  );
}

function getQueryParams({ type, id }) {
  switch (type) {
    case 'organization':
      return { organization_id: id };
    case 'person':
      return { person_id: id };
    case 'deal':
      return { parent_deal_id: id };
    default:
      return {};
  }
}

TemplateStep.propTypes = propTypes;
TemplateStep.defaultProps = defaultProps;

export default TemplateStep;
