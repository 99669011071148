import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  INTEGER_MIN_VALUE, INTEGER_MAX_VALUE, NUMBER_MAX_DIGITS
} from '@/components/CustomFields/Types/IntegerCustomField';
import DecimalInput from '@/components/Inputs/DecimalInput';

const propTypes = {
  field: PropTypes.object.isRequired,
  name: PropTypes.string,
  label: PropTypes.string
};

/* eslint-disable no-magic-numbers */
// Quantidade de casas decimais
const DECIMAL_PRECISION = 2;
const DECIMAL_MIN_VALUE = INTEGER_MIN_VALUE / (10 ** DECIMAL_PRECISION);
const DECIMAL_MAX_VALUE = INTEGER_MAX_VALUE / (10 ** DECIMAL_PRECISION);
/* eslint-enable no-magic-numbers */

export const decimalCustomFieldValidations = Yup
  .number()
  .nullable()
  .transform((value, originalValue) => (originalValue === '' ? null : value))
  .typeError('Este campo deve ser um número.')
  .min(DECIMAL_MIN_VALUE, `Este campo deve ter no máximo ${NUMBER_MAX_DIGITS} dígitos`)
  .max(DECIMAL_MAX_VALUE, `Este campo deve ter no máximo ${NUMBER_MAX_DIGITS} dígitos`);

export function DecimalCustomField({ field, label, ...otherProps }) {
  return (
    <DecimalInput
      label={label ?? field.name}
      placeholder='0,00'
      variant='light'
      autoComplete='off'
      { ...otherProps }
    />
  );
}

DecimalCustomField.propTypes = propTypes;
