import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import APIErrorMessage from '@/components/APIErrorMessage';
import Authorization from '@/components/Authorization';
import ConfirmationModal from '@/components/ConfirmationModal';
import { canDeleteFunnel } from '@/policies';
import { settingsFunnelsDealsRelocationsPath } from '@/routes';
import { useDeleteFunnel } from '@/api';

const propTypes = {
  funnel: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    default: PropTypes.bool.isRequired,
    hasDeals: PropTypes.bool.isRequired
  }).isRequired
};

const defaultProps = {
};

function FunnelsDelete({ funnel }) {
  const {
    id: funnelId, name: funnelName, default: isDefault, hasDeals
  } = funnel;

  const [isDeleting, setIsDeleting] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const alert = useAlert();

  const mutation = useDeleteFunnel({
    funnelId,
    onDelete: () => {
      alert.show(
        'Funil removido com sucesso.',
        { variant: 'success', timeout: 5000 }
      );
    }
  });

  const handleModalHide = () => setShowModal(false);

  const handleDeleteClick = (e) => {
    e.currentTarget.blur();

    setShowModal(true);
  };

  const handleDeleteConfirm = () => {
    setIsDeleting(true);
    setShowModal(false);

    mutation.mutate({}, {
      onError: (err) => {
        setIsDeleting(false);

        alert.show(
          <APIErrorMessage err={err} resource='funnel' action='delete' />,
          { variant: 'danger' }
        );
      }
    });
  };

  const deleteButtonProps = {
    onClick: !hasDeals ? handleDeleteClick : null,
    href: hasDeals ? settingsFunnelsDealsRelocationsPath(funnelId) : null
  };

  return (
    <React.Fragment>
      <ConfirmationModal
        show={showModal}
        disabled={isDeleting}
        onHide={handleModalHide}
        onConfirm={handleDeleteConfirm}
        confirmationText='Excluir funil'
      >
        <ConfirmationModal.Title>
          Confirmar exclusão do {funnelName}
        </ConfirmationModal.Title>

        <ConfirmationModal.Info>
          Esta ação não poderá ser revertida.
        </ConfirmationModal.Info>
      </ConfirmationModal>

      <Authorization policy={canDeleteFunnel} data={{ isDefault }}>
        <Button
          variant='transparent-light'
          className='py-2 px-4 border-0 funnel-delete-button'
          disabled={isDeleting}
          { ...deleteButtonProps }
        >
          <Icon name='delete' className='me-2' />

          <span>Excluir funil</span>
        </Button>
      </Authorization>
    </React.Fragment>
  );
}

FunnelsDelete.propTypes = propTypes;
FunnelsDelete.defaultProps = defaultProps;

export default FunnelsDelete;
