import React from 'react';
import PropTypes from 'prop-types';
import image from 'images/leads_generation/illustration-projectmanager.svg';
import { funnelPath } from '@/routes';
import Button from '@/components/Button';

const propTypes = {
  createdLeads: PropTypes.object
};

function LeadsGenerationLeadsDone({ createdLeads, onClickFinish }) {
  const { info } = createdLeads;

  return (
    <>
      <img width={235} src={image} className='mb-2' />

      <h2 className='mb-2'>Leads gerados com sucesso!</h2>

      <div className='text-dark-gray mb-1'>
        ✔ {info.organizationsCreated} empresas criadas com a origem {'"Geração de leads Agendor"'}
      </div>

      {Boolean(info.organizationsUpdated) && (
        <div className='text-dark-gray mb-1'>
        ✔ {info.organizationsUpdated} empresas já existiam e foram atualizadas
        </div>
      )}

      <div className='text-dark-gray'>
        ✔ {info.dealsCreated} negócios criados no funil {'"Funil de Leads"'}
      </div>

      <Button
        href={funnelPath(info.funnelId)}
        onClick={onClickFinish}
        variant='lighter-blue'
        className='mt-6 text-primary'
      >
        Ver no funil
      </Button>
    </>
  );
}

LeadsGenerationLeadsDone.prototype = propTypes;

export default LeadsGenerationLeadsDone;
