import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { MaskedInput, TextInput } from '@/components/Inputs';
import Popover from '@/components/Popover';
import Button from '@/components/Button';
import RangeAdornment from '@/components/RangeAdornment';
import { usePopover } from '@/contexts';
import { formatRange } from '@/number';

const propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.object,
  fromName: PropTypes.string.isRequired,
  toName: PropTypes.string.isRequired
};

const defaultProps = {
  value: {}
};

function IntegerRangeInput(props) {
  const { name, onChange, value, label, fromName, toName } = props;
  const { container } = usePopover();

  const fromValue = value && value[fromName];
  const toValue = value && value[toName];

  const [selectedFrom, setSelectedFrom] = useState(fromValue);
  const [selectedTo, setSelectedTo] = useState(toValue);

  const isFilled = Boolean(fromValue || toValue);

  const previewValue = useMemo(() => (
    formatRange(fromValue, toValue)
  ), [fromValue, toValue]);

  const onApply = (callback) => {
    onChange({
      integer_value_gteq: selectedFrom,
      integer_value_lteq: selectedTo
    });
    callback();
  };

  const onClear = () => {
    onChange({});
    setSelectedFrom();
    setSelectedTo();
  };

  const onKeyUp = (event, callback) => {
    if (event.key === 'Enter') {
      onApply(callback);
    }
  };

  return (
    <Popover
      container={container && container.current}
      placement='bottom-start'
      className='max-width-6'
      content={({ onHide }) => (
        <div className='d-flex'>
          <MaskedInput
            name={`${name}_from`}
            label='de'
            placeholder='1'
            maskType='integer'
            className='width-4 me-4 d-flex align-items-baseline'
            value={selectedFrom}
            onChange={setSelectedFrom}
            onKeyUp={(event) => onKeyUp(event, onHide)}
          />
          <MaskedInput
            name={`${name}_to`}
            label='até'
            placeholder='999999'
            maskType='integer'
            className='width-4 me-4 d-flex align-items-baseline'
            value={selectedTo}
            onChange={setSelectedTo}
            onKeyUp={(event) => onKeyUp(event, onHide)}
          />
          <Button
            onClick={() => onApply(onHide)}
            size='sm'
          >
            Aplicar
          </Button>
        </div>
      )}
    >
      <TextInput
        readOnly
        name={name}
        value={previewValue}
        label={label}
        placeholder='Qualquer quantidade'
        innerClassName='cursor-default'
        rightAdornment={() => (
          <RangeAdornment isFilled={isFilled} onClear={onClear} />
        )}
      />
    </Popover>
  );
}

IntegerRangeInput.defaultProps = defaultProps;
IntegerRangeInput.propTypes = propTypes;

export default IntegerRangeInput;
