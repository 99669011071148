import React from 'react';
import PropTypes from 'prop-types';
import FetchableSelect from '@/components/Inputs/FetchableSelect';
import { useTeams } from '@/api';

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func
};

const defaultProps = {
  name: 'team_id_in',
  label: 'Equipes',
  placeholder: 'Qualquer equipe',
  multiple: false,
  isClearable: false,
  isSearchable: false,
  defaultValue: null,
  onChange: () => { }
};

function TeamSelect(props) {
  return (
    <FetchableSelect
      { ...props }
      query={useTeams}
    />
  );
}

TeamSelect.propTypes = propTypes;
TeamSelect.defaultProps = defaultProps;

export default TeamSelect;
