import { useQuery, useMutation, useQueryClient } from 'react-query';
import { funnelsKeys, funnelStagesKeys } from '@/api';
import { get, post, patch, _delete } from '@/lib/fetch';
import { updateListItem, accessoryCallCache } from '@/utils';

export const stagesKeys = {
  all: ['stages'],
  lists: () => [...stagesKeys.all, 'list'],
  list: (params) => [...stagesKeys.lists(), params],
  details: () => [...stagesKeys.all, 'detail'],
  detail: (stageId) => [...stagesKeys.details(), stageId]
};

export async function getStages(params = {}) {
  const { data } = await get('/stages', params);

  return data;
}

export async function getStage(stageId) {
  const { data } = await get(`/stages/${stageId}`);

  return data;
}

export async function createStage({ funnelId, params = {} }) {
  const { data } = await post(`/funnels/${funnelId}/stages`, params);

  return data;
}

export async function updateStage({ stageId, params = {} }) {
  const { data } = await patch(`/stages/${stageId}`, params);

  return data;
}

export async function deleteStage({ stageId }) {
  const { data } = await _delete(`/stages/${stageId}`);

  return data;
}

export function useStages({ params = {}, config = {} }) {
  return useQuery({
    ...accessoryCallCache,
    ...config,
    queryKey: stagesKeys.list(params),
    queryFn: () => getStages(params)
  });
}

export function useStage({ stageId, config = {} }) {
  return useQuery({
    ...config,
    queryKey: stagesKeys.detail(stageId),
    queryFn: () => getStage(stageId)
  });
}

export function useCreateStage({ funnelId, config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = () => Promise.all([
    queryClient.invalidateQueries(stagesKeys.all),
    queryClient.invalidateQueries(funnelsKeys.all)
  ]);

  return useMutation(
    (params) => createStage({ funnelId, params }),
    {
      onSuccess,
      ...config
    }
  );
}

export function useUpdateStage({ funnelId, config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = () => Promise.all([
    queryClient.invalidateQueries(stagesKeys.all),
    queryClient.invalidateQueries(funnelsKeys.all),
    queryClient.invalidateQueries(funnelStagesKeys.infiniteAll)
  ]);

  const onError = (_err, _params, rollback) => rollback();

  const onMutate = ({ stageId, params = {} }) => {
    queryClient.cancelQueries(stagesKeys.list({ funnel_id_eq: funnelId }));

    const previousStages = queryClient.getQueryData(stagesKeys.list({ funnel_id_eq: funnelId }));

    queryClient.setQueryData(
      stagesKeys.list({ funnel_id_eq: funnelId }),
      (old) => ({ data: updateListItem(old.data, stageId, params) })
    );

    return () => queryClient.setQueryData(
      stagesKeys.list({ funnel_id_eq: funnelId }),
      previousStages
    );
  };

  return useMutation(
    updateStage,
    {
      onSuccess,
      onError,
      onMutate,
      ...config
    }
  );
}

export function useDeleteStage({ onDelete, config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await Promise.all([
      queryClient.invalidateQueries(stagesKeys.all),
      queryClient.invalidateQueries(funnelsKeys.all),
      queryClient.invalidateQueries(funnelStagesKeys.all)
    ]);

    queryClient.removeQueries(stagesKeys.all);
    queryClient.removeQueries(funnelStagesKeys.infiniteAll);

    /*
     * Nas listagens, quando um item é removido, o componente que o exibe é desmontado
     * e, com isso, o side-effect `onSuccess` que pode ser passado na chamada a função `mutate`
     * não é executado. Para contornar isso, esse callback `onDelete` pode ser passado na montagem
     * da mutation e será chamado nos casos de "success".
     *
     * Ref do problema: https://react-query.tanstack.com/guides/mutations#mutation-side-effects
     */
    if (onDelete) {
      onDelete();
    }
  };

  return useMutation(
    deleteStage,
    {
      onSuccess,
      ...config
    }
  );
}
