import React from 'react';
import Badge from '@/components/Badge';
import Card from '@/components/Card';
import Icon from '@/components/Icon';
import { RadioButton } from '@/components/Inputs';
import { useCheckoutContext } from '@/contexts';

function PlanSelect() {
  const { plan, setPlan } = useCheckoutContext();

  const options = [
    {
      label: 'Plano Pro',
      value: 'pro_plan'
    },
    {
      label: 'Plano Performance',
      value: 'performance_plan',
      subLabel: 'Melhor custo-benefício'
    }
  ];

  return (
    <div id='plan-select'>
      {options.map(({ label, value, subLabel }) => (
        <Card
          onClick={() => setPlan(value)}
          key={value}
          className='border-light p-2 mb-2 cursor-pointer'
        >
          <div className='d-flex justify-content-between align-items-center'>
            <RadioButton
              name='plan'
              value={plan}
              id={value}
              radioValue={value}
              label={<h2>{label}</h2>}
              className='me-2 cursor-pointer'
              noFlex
              checkClassName='mb-0 cursor-pointer'
            />

            {subLabel && (
              <Badge className='px-1 bg-primary text-white d-flex align-items-center'>
                <Icon size='sm' className='text-yellow me-1' name='star' />
                <span className='text-small fw-bold'>{subLabel}</span>
              </Badge>
            )}
          </div>
        </Card>
      ))}
    </div>
  );
}

export default PlanSelect;
