import { useQuery } from 'react-query';
import { get } from '@/lib/fetch';

export const organizationValidationKeys = {
  all: ['organization-validation'],
  lists: () => [...organizationValidationKeys.all, 'list'],
  list: (params) => [...organizationValidationKeys.lists(), params]
};

export async function getOrganizationValidation(params) {
  const { data } = await get('/organization_validation', params);

  return data;
}

export function useOrganizationValidation({ excludedId, params = {}, config = {} }) {
  return useQuery({
    ...config,
    queryKey: organizationValidationKeys.list(params),
    queryFn: () => getOrganizationValidation({ validations: params, excluded_id: excludedId })
  });
}
