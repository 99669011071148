import { MutationCache, QueryCache, QueryClient } from 'react-query';
import { HTTP_STATUSES, isUnauthorizedRequestError } from '@/errors';
import { onUnauthorizedError } from '@/lib/auth';
import { usersKeys } from '@/api';
import isEqual from 'lodash/isEqual';

const MAX_RETRIES = 3;
const RETRY_STATUSES = [
  0,
  HTTP_STATUSES.BAD_GATEWAY,
  HTTP_STATUSES.SERVICE_UNAVAILABLE,
  HTTP_STATUSES.GATEWAY_TIMEOUT
];

const defaultConfig = {
  queries: {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: (count, error) => (
      count < MAX_RETRIES && (!error || RETRY_STATUSES.includes(error.status))
    )
  }
};

const queryCache = new QueryCache({
  onError: (error, query) => {
    /*
     * Essa verificação é para que o modal de sessão expirada não apareça quando acontecer
     * a request inicial de trazer os dados do usuário logado
     */
    const isCurrentUserRequest = isEqual(query.queryKey, usersKeys.currentUser);
    if (isUnauthorizedRequestError(error) && !isCurrentUserRequest) {
      onUnauthorizedError(error);
    }
  }
});
const mutationCache = new MutationCache({
  onError: (error) => {
    if (isUnauthorizedRequestError(error)) {
      onUnauthorizedError(error);
    }
  }
});

export const queryClient = new QueryClient({
  queryCache,
  mutationCache,
  defaultOptions: defaultConfig
});
