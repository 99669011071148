import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { useIntercom } from 'react-use-intercom';
import {
  checkoutPath,
  legacyPlansUrl,
  legacyRegisterUserUrl,
  settingsManageUpdatePlanPath,
  legacyUsersUrl,
  settingsManagePaymentMethodPath
} from '@/routes';

import Button from '@/components/Button';
import Badge from '@/components/Badge';
import Card from '@/components/Card';
import CreateExportModal from '@/components/CreateExportModal';
import Dropdown from '@/components/Dropdown';
import ExternalLink from '@/components/ExternalLink';
import Icon from '@/components/Icon';
import Link from '@/components/Link';
import Main from '@/components/Main';
import Modal from '@/components/Modal';
import PaymentMethod from '@/components/Billing/PaymentMethod';
import { TextInput } from '@/components/Inputs';
import GenerateBankSlipButton from '@/components/Billing/GenerateBankSlipButton';

import { useCurrentAccount, useCurrentPlan } from '@/api';
import { redirect } from '@/browser';
import { useOffcanvasPageContext } from '@/contexts';
import { isNotFoundError } from '@/errors';
import { useDocumentTitle } from '@/hooks';
import { pluralize } from '@/utils';
import chattingImage from 'images/billing/chatting.svg';
import { parseDate, toDateString } from '@/date';

const PAGE_TITLE = 'Gerenciar plano';
const PAGE_SUBTITLE = `
Gerencie seu plano no Agendor, método de pagamento e outras informações importantes.
`;

function CardDetails({ title, children }) {
  return (
    <Card className='my-2 px-4 py-3 border-light'>
      <Card.Header
        as='h5'
        className='text-dark-gray bg-transparent border-light mb-1 pb-2 px-0'
      >
        {title}
      </Card.Header>

      <Card.Body className='d-flex align-items-center px-0'>
        {children}
      </Card.Body>
    </Card>
  );
}

function CancelModal({ showModal, setShowModal }) {
  const { showNewMessages } = useIntercom();

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      size='lg'
      bodyClassName='py-9 px-8 text-center'
    >
      <img className='mb-6 img-fluid' src={chattingImage} />

      <h1 className='mb-4'>Solicitar cancelamento da assinatura</h1>

      <div className='mb-2 fw-bold text-dark-gray'>
        Caso você deseje cancelar sua assinatura, pedimos que entre em contato
        com o nosso suporte para que possamos ajudá-lo com o processo.
      </div>

      <span className='text-dark-gray'>
        Nosso horário de atendimento é de segunda a sexta, das 9h às 18h.
      </span>

      <div className='mt-9'>
        <Button onClick={() => showNewMessages()} className='me-3'>Falar com suporte</Button>

        <Button variant='light' onClick={() => setShowModal(false)}>Voltar</Button>
      </div>
    </Modal>
  );
}

function ManageCurrentPlan() {
  const [exportEntity, setExportEntity] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useDocumentTitle(PAGE_TITLE);

  const canvasContext = useOffcanvasPageContext();

  const { data: { data: planData } = {}, isSuccess: loadedCurrentPlan } = useCurrentPlan({
    config: {
      onError: (err) => {
        if (isNotFoundError(err)) {
          redirect(legacyPlansUrl());
        }
      }
    }
  });

  const isBlockedOrCanceled = planData?.blocked || planData?.canceled;
  const expiredOrCanceled = planData?.expired || isBlockedOrCanceled;

  const planButtonLabel = isBlockedOrCanceled ? 'Renovar assinatura' : 'Alterar';
  const planButtonVariant = isBlockedOrCanceled ? 'dark-gray' : 'light';
  const planButtonHref = expiredOrCanceled ? checkoutPath() : settingsManageUpdatePlanPath();
  const widthClass = isBlockedOrCanceled ? 'auto' : 'width-4';

  const canceledDaysLabel = planData?.canceledDate
    ? `em ${toDateString(parseDate(planData?.canceledDate))}`
    : null;

  const blockedDaysLabel = planData?.daysBeenBlocked === 0
    ? 'hoje'
    : `há ${planData?.daysBeenBlocked} ${pluralize('dia', planData?.daysBeenBlocked)}`;

  const warningLabel = canceledDaysLabel || blockedDaysLabel;

  const { data: accountData, isSuccess: loadedCurrentAccount } = useCurrentAccount();

  if (!loadedCurrentPlan || !loadedCurrentAccount) {
    return <div className='flex-grow-1 bg-light' />;
  }

  const { stats, billingEmails } = accountData.data;

  const createExport = (entity) => {
    setExportEntity(entity);
    setShowExportModal(true);
  };

  const recurrencyPeriod = () => {
    const map = {
      monthly: 'mensal',
      yearly: 'anual',
      quarterly: 'trimestral',
      half_yearly: 'semestral'
    };
    return map[planData.periodicity];
  };

  return (
    <Main fluid className='py-8 ps-4 pe-4 bg-light'>
      <Container>
        <div className='d-flex align-items-start'>
          <div className='flex-fill'>
            <h2 className='mb-2'>{PAGE_TITLE}</h2>
          </div>

          <Button
            className={'rounded border-0 px-2 py-1'}
            variant='light-gray'
            type='button'
            aria-label='Fechar'
            onClick={canvasContext.hide}
          >
            <Icon className='text-dark-gray' name='close' />
          </Button>
        </div>

        <p>{PAGE_SUBTITLE}</p>

        <hr className='mb-2' />

        <CardDetails title='Estatísticas da conta'>
          <div className='flex-fill'>
            <div className='my-1 text-dark-gray'>
              <Icon name='organization' className='me-1 text-dark-gray' />
              <b>{stats.organizationsCount}</b> {pluralize('empresa', stats.organizationsCount)}
            </div>

            <div className='my-1 text-dark-gray'>
              <Icon name='person' className='me-1 text-dark-gray' />
              <b>{stats.peopleCount}</b> {pluralize('pessoa', stats.peopleCount)}
            </div>

            <div className='my-1 text-dark-gray'>
              <Icon name='deal' className='me-1 text-dark-gray' />
              <b>{stats.dealsCount}</b> {pluralize('negócio', stats.dealsCount)}
            </div>
          </div>

          <div className='width-4'>
            <Dropdown align='end' className='w-100 p-0'>
              <Dropdown.Toggle
                id='menu-toggle'
                variant='light'
                className='w-100'
              >
                <Icon name='download' className='me-1' /> Exportar
              </Dropdown.Toggle>

              <Dropdown.Menu
                renderOnMount
                className='min-width-5'
              >
                <Dropdown.Item
                  id='menu-item-export-organization'
                  as='button'
                  onClick={() => createExport('organization')}
                  className='text-dark-gray fw-bold'
                >
                  <Icon
                    name='organization'
                    size='sm'
                    className='text-primary me-2 align-middle'
                    withStroke
                  />
                  <span className='align-middle'>Exportar empresas</span>
                </Dropdown.Item>

                <Dropdown.Divider />
                <Dropdown.Item
                  id='menu-item-export-people'
                  as='button'
                  onClick={() => createExport('person')}
                  className='text-dark-gray fw-bold'
                >
                  <Icon
                    name='person'
                    size='sm'
                    className='text-primary me-2 align-middle'
                    withStroke
                  />
                  <span className='align-middle'>Exportar pessoas</span>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  id='menu-item-export-deal'
                  as='button'
                  onClick={() => createExport('deal')}
                  className='text-dark-gray fw-bold'
                >
                  <Icon
                    name='deal'
                    size='sm'
                    className='text-primary me-2 align-middle'
                    withStroke
                  />
                  <span className='align-middle'>Exportar negócios</span>
                </Dropdown.Item>

                <Dropdown.Divider />
                <Dropdown.Item
                  id='menu-item-export-proposal'
                  as='button'
                  onClick={() => createExport('activity')}
                  className='text-dark-gray fw-bold'
                >
                  <Icon
                    name='proposal'
                    size='sm'
                    className='text-primary me-2 align-middle'
                    withStroke
                  />
                  <span className='align-middle'>Exportar histórico</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <CreateExportModal
            exportEntity={exportEntity}
            showExportModal={showExportModal}
            onHide={() => setShowExportModal(false)}
            initialSize={'md'}
            withoutOptions={true}
          />
        </CardDetails>

        <CardDetails title='Detalhes da assinatura'>
          <div className='flex-fill'>
            <div className='my-1 text-dark-gray' >
              <b>Assinatura: </b> Recorrência {recurrencyPeriod()}
            </div>

            <div className='my-1 text-dark-gray' >
              <b>Plano: </b> {planData.name} {planData.maxUsersCount}
            </div>
            {isBlockedOrCanceled && (
              <div className='my-1 text-danger' >
                Cancelada {warningLabel}
              </div>
            )}

            <GenerateBankSlipButton />
          </div>

          <div className={`d-flex flex-column ${widthClass}`}>
            <Button
              variant={planButtonVariant}
              className='my-1 w-100'
              as={Link}
              href={planButtonHref}
            >
              {!isBlockedOrCanceled && <Icon name='filter-settings' className='me-1' />}
              {planButtonLabel}
            </Button>

            {!isBlockedOrCanceled && (
              <Button onClick={() => setShowModal(true)} variant='light' className='my-1 w-100'>
                <Icon name='close' className='me-1' /> Cancelar
              </Button>
            )}
          </div>
        </CardDetails>

        <UsersDetails planData={planData} />

        {!isBlockedOrCanceled && (
          <CardDetails title='Detalhes do pagamento'>
            <div className='flex-fill'>
              <div className='d-flex align-items-center'>
                <div className='flex-fill'>
                  <PaymentMethod
                    method={planData.paymentInfo?.method}
                    lastFourDigits={planData.paymentInfo?.lastFourDigits}
                  />
                </div>
                <div className='width-4'>
                  <Button variant='light'
                    className='w-100'
                    as={Link}
                    href={settingsManagePaymentMethodPath()}
                  >
                    <Icon name='filter-settings' className='me-1' /> Alterar
                  </Button>
                </div>
              </div>

              <hr className='my-3' />

              <TextInput
                label='E-mails do financeiro'
                placeholder='Ex.: financeiro@agendor.com.br, jorge...'
                helperText={
                  <span
                    className='text-small text-dark-gray'
                  >
                    As faturas e notas fiscais serão enviados para este e-mail.
                  </span>
                }
                value={billingEmails?.join(',') ?? ''}
                disabled
              />
            </div>
          </CardDetails>
        )}
        <CancelModal setShowModal={setShowModal} showModal={showModal} />
      </Container>
    </Main>
  );
}

function UsersDetails({ planData }) {
  const availableInvites = planData.maxUsersCount - planData.currentActiveUsersCount;
  const hasAvailableInvites = Boolean(availableInvites);

  return (
    <CardDetails title='Usuários'>
      <div className='flex-fill'>
        <div className='my-1 text-dark-gray'>
          <b>
            {planData.currentActiveUsersCount}
            {' '}
            {pluralize('usuário', planData.currentActiveUsersCount)}
            {' '}
            {pluralize('ativo', planData.currentActiveUsersCount)}
          </b>
        </div>

        {hasAvailableInvites && (
          <Badge variant='light-green'>
            {availableInvites} {pluralize('convite', availableInvites)} de usuário{' '}
            {availableInvites > 1 ? 'disponíveis' : 'disponível'}
          </Badge>
        )}
      </div>

      <div className='d-flex flex-column width-4'>
        {hasAvailableInvites && (
          <Button
            as={ExternalLink}
            href={legacyRegisterUserUrl()}
            variant='light'
            className='my-1 w-100'
          >
            <Icon name='add' className='me-1' /> Convidar
          </Button>
        )}

        <Button
          as={ExternalLink}
          variant='light'
          className='my-1 w-100'
          href={legacyUsersUrl()}
        >
          <Icon name='filter-settings' className='me-1' /> Gerenciar
        </Button>
      </div>
    </CardDetails>
  );
}

export default ManageCurrentPlan;
