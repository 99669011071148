import React from 'react';
import PropTypes from 'prop-types';
import FetchableSelect from '@/components/Inputs/FetchableSelect';
import { useBacklogs } from '@/api';

const propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
};

const defaultProps = {
  name: 'backlog_type',
  value: 'organization',
  onChange: () => {}
};

function BacklogTypeSelect({ funnelId, ...props }) {
  return (
    <FetchableSelect
      { ...props }
      query={useBacklogs}
      queryArgs={{ funnelId }}
    />
  );
}

BacklogTypeSelect.propTypes = propTypes;
BacklogTypeSelect.defaultProps = defaultProps;

export default BacklogTypeSelect;
