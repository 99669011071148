import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import Footer from '@/components/LeadsGeneration/Footer';
import PaymentSummary from '@/components/LeadsGeneration/Payment/PaymentSummary';
import { useOrderSummary } from '@/api/order-summaries';
import { useLeadsGenerationContext } from '@/contexts';

const propTypes = {
  children: PropTypes.node.isRequired,
  buttonNextText: PropTypes.string.isRequired,
  onClickNext: PropTypes.func.isRequired,
  onClickPrev: PropTypes.func.isRequired,
  show: PropTypes.bool,
  isLoading: PropTypes.bool
};

const defaultProps = {
  show: true,
  isLoading: false
};

function PaymentWrapper(props) {
  const {
    show, children, buttonNextText, onClickNext, disabled,
    onClickPrev, isLoading, paymentCompletionData, onClose
  } = props;

  const { selectedLeads } = useLeadsGenerationContext();

  const selectedLeadsCount = selectedLeads.length;

  const { data: { data } = { data: {} }, isFetching } = useOrderSummary({
    params: {
      code: 'leads_generation',
      quantity: selectedLeadsCount
    }
  });

  return (
    <>
      <Row
        className={classnames(
          'flex-grow-1',
          'flexible-height',
          'overflow-y-scroll',
          'mt-6',
          { 'd-none': !show }
        )}
      >
        <Col sm={6} className='pe-0'>
          {children}
        </Col>

        <Col sm={6}>
          {!isFetching && <PaymentSummary data={data} />}
        </Col>
      </Row>

      {
        show && (
          paymentCompletionData
            ? <Footer.Close onClick={onClose} />
            : (
              <Footer
                buttonNextText={buttonNextText}
                onClickNext={onClickNext}
                onClickPrev={onClickPrev}
                disabled={disabled}
                isLoading={isLoading}
                loadingTextButton='Finalizando...'
              />
            )
        )
      }
    </>
  );
}

PaymentWrapper.propTypes = propTypes;
PaymentWrapper.defaultProps = defaultProps;

export default PaymentWrapper;
