import React from 'react';
import PropTypes from 'prop-types';
import Base from 'react-bootstrap/Dropdown';
import Link from '@/components/Link';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

const defaultProps = {
  className: '',
  as: Link
};

const Item = React.forwardRef(
  ({ children, ...props }, ref) => (
    <Base.Item
      ref={ref}
      { ...props }
    >
      {children}
    </Base.Item>
  )
);

Item.propTypes = propTypes;
Item.defaultProps = defaultProps;
Item.displayName = 'Dropdown.Item';

export default Item;
