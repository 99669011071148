import React from 'react';
import PropTypes from 'prop-types';
import fullPageGif from 'images/full-page-loading.gif';

ProposalLoading.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
};

ProposalLoading.defaultProps = {
  title: 'Criando proposta',
  subtitle: 'Aguarde só um pouco, estamos salvando sua proposta.'
};

function ProposalLoading({ title, subtitle }) {
  return (
    <div className='d-flex align-items-center proposal-preview'>
      <div className='w-100 mx-auto text-center text-darker-gray mt-n4'>
        <img
          alt={title}
          title={title}
          className='text-center width-4 height-4'
          src={fullPageGif}
        />
        <h2 className='mt-8'>{title}</h2>
        <p>{subtitle}</p>
      </div>
    </div>
  );
}

export default ProposalLoading;
