import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import EmptyContact from '@/components/EntityModal/Deal/EmptyContact';
import DetailsWhatsappContactItem from '@/components/EntityModal/Deal/DetailsWhatsappContactItem';

DetailsWhatsappContactList.defaultProps = {
  deal: {},
  entity: {},
  entityType: '',
  onUpdateEntity: () => { }
};

DetailsWhatsappContactList.propTypes = {
  deal: PropTypes.object,
  entity: PropTypes.object,
  entityType: PropTypes.string,
  onUpdateEntity: PropTypes.func
};

function DetailsWhatsappContactList({
  deal, entity, entityType, onUpdateEntity
}) {
  const [showForm, setShowForm] = useState(false);

  const entities = compact([entity].concat(entity?.people ?? null));

  return (
    <div className='deal-voip-height overflow-hidden overflow-y-auto'>
      {
        showForm
          ? null
          : (
            <div className='d-flex justify-content-between mb-4'>
              <h4>Contatos</h4>
            </div>
          )
      }
      {
        isEmpty(entity)
          ? <EmptyContact deal={deal} />
          : (
            entities?.map((entityData, index) => (
              <DetailsWhatsappContactItem
                key={entityData.id}
                deal={deal}
                entity={entityData}
                isLastItem={index === entities.length - 1}
                entityType={index === 0 ? entityType : 'person'}
                onUpdateEntity={onUpdateEntity}
                showForm={showForm}
                setShowForm={setShowForm}
              />
            ))
          )
      }
    </div>
  );
}

export default DetailsWhatsappContactList;
