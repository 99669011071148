import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Select from '@/components/Inputs/Select';
import Truncate from '@/components/Truncate';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import { toCurrencyString, toDecimalString } from '@/number';
import { useDealCalculations, usePersonCalculations, useOrganizationCalculations } from '@/api';
import { useFormikContext } from 'formik';

const propTypes = {
  className: PropTypes.string,
  isCurrency: PropTypes.bool,
  isDecimal: PropTypes.bool,
  entity: PropTypes.string,
  fieldName: PropTypes.string,
  customField: PropTypes.bool,
  filterParams: PropTypes.object
};

const defaultProps = {
  className: '',
  isCurrency: false,
  isDecimal: false,
  entity: '',
  fieldName: '',
  customField: false,
  filterParams: {}
};

const CALC_TYPE = {
  sum: 'sum',
  average: 'average',
  minimum: 'minimum',
  maximum: 'maximum'
};

const CALC_LABEL = {
  sum: 'Soma total',
  average: 'Média',
  minimum: 'Mínimo',
  maximum: 'Máximo'
};

const OPTIONS = Object.entries(CALC_TYPE).map(([value, key]) => ({
  value,
  label: CALC_LABEL[key]
}));

function useCalculation(entity, options) {
  const calculationMapping = {
    deal: useDealCalculations,
    person: usePersonCalculations,
    organization: useOrganizationCalculations
  };
  const calculationQuery = calculationMapping[entity];
  return calculationQuery?.(options);
}

function CalculableField({
  entity, className, fieldName, customField, isCurrency, isDecimal, hovered
}) {
  const [calcType, setCalcType] = useState(CALC_TYPE.sum);
  const [selected, setSelected] = useState(false);
  const [initial, setInitial] = useState(true);
  const [alwaysVisible, setAlwaysVisible] = useState(false);
  const { values } = useFormikContext();
  const calcParams = {
    calc_type: calcType,
    field_name: fieldName,
    is_custom_field: customField
  };

  const {
    data,
    isLoading
  } = useCalculation(
    entity,
    {
      params: { ...values, ...calcParams },
      config: { enabled: !initial }
    }
  );

  const valuePreview = useMemo(() => {
    if (isLoading) {
      return 'Calculando...';
    }
    if (initial || data?.result === undefined) {
      return 'Calcular';
    }

    if (isCurrency) {
      return toCurrencyString(data?.result);
    }
    if (isDecimal) {
      return toDecimalString(data?.result);
    }
    return Number(data?.result).toFixed(0);
  }, [isLoading, initial, data, isCurrency, isDecimal]);

  if (!hovered && !alwaysVisible) {
    return null;
  }

  return (
    <div
      onMouseEnter={() => setSelected(true)}
      onMouseLeave={() => setSelected(false)}
      onClick={() => {
        setAlwaysVisible(true);
      }}
      className={classnames(
        'd-flex',
        'align-items-center',
        'justify-flex-start',
        'w-100',
        'mw-100',
        'border-0',
        { 'bg-lighter-gray': selected },
        className
      )}>
      <Select
        onMouseEnter={() => setSelected(true)}
        onMouseLeave={() => setSelected(false)}
        className='w-50 mb-0'
        controlClassName={classnames(
          'border-0',
          'cursor-pointer',
          { 'bg-white': !selected },
          { 'bg-lighter-gray': selected }
        )}
        name='calcType'
        value={calcType}
        isSearchable={false}
        onChange={(calcTypeValue) => {
          setInitial(false);
          setSelected(false);
          setCalcType(calcTypeValue);
        }}
        options={OPTIONS}
      />
      <Truncate
        alt={valuePreview}
        title={valuePreview}
        as='div'
        className='ms-0 pe-2 mb-0 fw-bold w-50 cursor-pointer'
        onClick={() => {
          setInitial(false);
          setSelected(false);
        }}>
        {valuePreview}
      </Truncate>
      {
        !initial && !isLoading
          ? (
            <div className='py-1'>
              <Button
                variant='link'
                className='p-2 border-0 text-darker-gray'
                onClick={(event) => {
                  event.stopPropagation();
                  setAlwaysVisible(false);
                  setInitial(true);
                  setSelected(true);
                }}>
                <Icon name='close' />
              </Button>
            </div>)
          : null
      }
    </div>
  );
}

CalculableField.propTypes = propTypes;
CalculableField.defaultProps = defaultProps;

export default CalculableField;
