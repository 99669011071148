import { LEGACY_HOST } from '@/config';

export function legacyAdmUrl() {
  return `${LEGACY_HOST}/adm/clientes`;
}

export function legacyAdmAccountDetailsUrl(id) {
  return `${legacyAdmUrl()}/detalhes.php?id=${id}`;
}

export function metabaseAccountDetailsUrl(id) {
  return `https://reports.agendor.com.br/dashboard/44-adm-transacoes?id_cliente=${id}`;
}
