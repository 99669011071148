import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useAlert } from 'react-alert';
import { Header, Illustration, Title } from '@/components/CreateDealModal/Header';
import APIErrorMessage from '@/components/APIErrorMessage';
import CustomerSearch from '@/components/CustomerSearch';
import CreatePersonForm from '@/components/CreatePersonForm';
import CreateOrganizationForm from '@/components/CreateOrganizationForm';
import Icon from '@/components/Icon';
import ExternalLink from '@/components/ExternalLink';
import { getValidationErrors } from '@/utils';
import { helpUrl } from '@/routes';
import {
  useCreatePerson,
  useCreateOrganization,
  usePeopleCustomFields,
  useOrganizationCustomFields
} from '@/api';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import LoadSpinner from '@/components/LoadSpinner';

const propTypes = {
  /** Callback chamado quando uma entidade é selecionada. */
  onSelect: PropTypes.func.isRequired,
  /** Callback chamado quando é cancelado o form/passo */
  onCancel: PropTypes.func.isRequired,
  /** Função que avança para o próximo passo. */
  advanceStep: PropTypes.func
};

const defaultProps = {
  advanceStep: () => {}
};

function EntityStep(props) {
  const { onSelect, advanceStep, onCancel } = props;
  const [selectedEntity, setSelectedEntity] = useState({ type: '', name: '' });

  const handleItemSelect = (...args) => {
    onSelect(...args);
    advanceStep();
  };

  const handleNewEntitySelect = ({ type, term }) => {
    setSelectedEntity({ type, name: term });
  };

  return (
    <Content
      selectedEntity={selectedEntity}
      handleItemSelect={handleItemSelect}
      handleNewEntitySelect={handleNewEntitySelect}
      onCancel={onCancel}
    />
  );
}

function Content(props) {
  const {
    selectedEntity,
    handleItemSelect,
    handleNewEntitySelect,
    onCancel
  } = props;

  switch (selectedEntity.type) {
    case 'person':
      return (
        <PersonContent
          onCancel={onCancel}
          selectedEntity={selectedEntity}
          handleItemSelect={handleItemSelect}
        />
      );
    case 'organization':
      return (
        <OrganizationContent
          onCancel={onCancel}
          selectedEntity={selectedEntity}
          handleItemSelect={handleItemSelect}
        />
      );
    default:
      return (
        <SearchContent
          onSelect={handleItemSelect}
          onCreate={handleNewEntitySelect}
        />
      );
  }
}

function ContentWrapper({ children, isLoading }) {
  if (isLoading) {
    return (
      <LoadSpinner
        size='lg'
        className={classnames(
          'd-flex',
          'align-items-center',
          'justify-content-center',
          'text-dark-gray',
          'opacity-100',
          'height-4',
          'py-6'
        )}
      />
    );
  }

  return (
    <div>
      <Header>
        <Illustration addon='plus' />

        <Title>Com quem você irá negociar?</Title>
      </Header>
      {children}
    </div>
  );
}

function PersonContent({ selectedEntity, handleItemSelect, onCancel }) {
  const { user } = useAuth();
  const alert = useAlert();
  const createPersonMutation = useCreatePerson();
  const { data: customFields, isSuccess: loadedCustomFields } = usePeopleCustomFields();
  const tracker = useTracking();

  const handleSubmit = (formData, { setFieldError }) => {
    createPersonMutation.mutate(formData, {
      onSuccess: ({ data }) => {
        const { name, id } = data;
        tracker.trackPersonCreated({
          source: 'funnel',
          entityCustomFields: customFields?.data,
          formData,
          user
        });

        alert.show(
          `O contato "${name}" foi salvo com sucesso!`,
          { variant: 'success', timeout: 5000 }
        );

        handleItemSelect({ type: 'person', id, name });
      },
      onError: (err) => {
        const errors = getValidationErrors(err, 'person');

        Object.entries(errors).forEach(([key, message]) => {
          setFieldError(key, message);
        });

        alert.show(
          <APIErrorMessage err={err} resource='person' action='create' />,
          { variant: 'danger' }
        );
      }
    });
  };

  const initialData = { name: selectedEntity.name };

  return (
    <ContentWrapper
      isLoading={!loadedCustomFields}
    >
      <CreatePersonForm
        initialData={initialData}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        customFields={customFields?.data ?? []}
      />
    </ContentWrapper>
  );
}

function OrganizationContent({ selectedEntity, handleItemSelect, onCancel }) {
  const { user } = useAuth();
  const alert = useAlert();
  const createMutation = useCreateOrganization();
  const tracker = useTracking();
  const { data: customFields, isSuccess: loadedCustomFields } = useOrganizationCustomFields();

  const onSubmitCreateOrganization = (formData, { setFieldError }) => {
    createMutation.mutate(formData, {
      onSuccess: ({ data }) => {
        const eventMetadata = {
          source: 'funnel',
          entityCustomFields: customFields?.data,
          formData,
          user
        };
        const { name, id } = data;
        tracker.trackOrganizationCreated(eventMetadata);

        if (formData.people?.length) {
          tracker.trackPersonCreated({
            ...eventMetadata,
            quickAdded: true
          });
        }

        alert.show(
          `A empresa "${data.name}" foi adicionada`,
          { variant: 'success', timeout: 5000 }
        );

        handleItemSelect({ id, name, type: 'organization' });
      },
      onError: (err) => {
        const errors = getValidationErrors(err, 'organization');

        Object.entries(errors).forEach(([key, message]) => {
          setFieldError(key, message);
        });

        alert.show(
          <APIErrorMessage err={err} resource='organization' action='create' />,
          { variant: 'danger' }
        );
      }
    });
  };

  const initialData = { name: selectedEntity.name };

  return (
    <ContentWrapper
      isLoading={!loadedCustomFields}
    >
      <CreateOrganizationForm
        initialData={initialData}
        onSubmit={onSubmitCreateOrganization}
        onCancel={onCancel}
        customFields={customFields?.data ?? []}
      />
    </ContentWrapper>
  );
}

function SearchContent({ onSelect, onCreate }) {
  return (
    <ContentWrapper>
      <div>
        <CustomerSearch
          onItemSelect={onSelect}
          onButtonClick={onCreate}
        />

        <div className='mt-7 text-center'>
          <ExternalLink
            href={helpUrl('create-deal')}
            target='_blank'
            rel='noreferrer'
            className='fw-bold'
          >
            <Icon name='help' size='sm' className='me-1 align-middle' />

            <span className='align-middle'>
            Saiba mais sobre a criação de negócio
            </span>
          </ExternalLink>
        </div>
      </div>
    </ContentWrapper>
  );
}

EntityStep.propTypes = propTypes;
EntityStep.defaultProps = defaultProps;

export default EntityStep;
