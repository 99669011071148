import React, { useMemo } from 'react';
import Select from '@/components/Inputs/Select';
import Icon from '@/components/Icon';

const RESOURCE_VARIANTS = {
  people: 'person',
  organizations: 'organization',
  products: 'product',
  activities: 'task',
  deals: 'deal'
};

const DEFAULT_OPTION = {
  label: 'Não importar',
  value: 'ignore',
  leftAdornment: <Icon name='config'/>
};

function getOptions(defaultColumns, resource, disabledOptions) {
  const options = Object.entries(defaultColumns).map((column) => {
    const columnName = column[1].name;
    const requiredSignal = column[1].required ? '*' : '';

    const label = `${columnName}${requiredSignal}`;
    const value = column[0];
    const isDisabled = disabledOptions.includes(column[0]);

    return {
      label,
      value,
      isDisabled,
      leftAdornment: <Icon name={RESOURCE_VARIANTS[resource]} />
    };
  });

  return [
    DEFAULT_OPTION,
    ...options
  ];
}

function ImportMappingSelect(props) {
  const { defaultColumns, resource, disabledOptions, ...rest } = props;

  const options = useMemo(
    () => getOptions(defaultColumns, resource, disabledOptions),
    [resource, disabledOptions, defaultColumns]
  );

  return (
    <Select
      { ...rest }
      options={options}
      variant='white'
    />
  );
}

export default ImportMappingSelect;
