import { useMutation, useQueryClient } from 'react-query';
import { post } from '@/lib/fetch';
import { backlogItemsKeys } from '@/api';

export async function createFunnelStageMove({ funnelId, stageId, params = {} }) {
  const { fromBacklog, itemId, ...rest } = params;
  const [type, id] = itemId.split('-');
  const item = { type, id };

  const { data } = await post(
    `/funnels/${funnelId}/stages/${stageId}/moves`,
    { item, from_backlog: fromBacklog, data: rest }
  );

  return data;
}

export function useFunnelStageMove({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = (_data, { params }) => {
    if (params.fromBacklog) {
      return queryClient.invalidateQueries(backlogItemsKeys.infiniteAll);
    }
  };

  return useMutation(
    createFunnelStageMove,
    {
      onSuccess,
      ...config
    }
  );
}
