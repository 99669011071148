import React from 'react';
import PropTypes from 'prop-types';
import Select from '@/components/Inputs/Select';

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func
};

const defaultProps = {
  name: 'ranking_in',
  label: 'Ranking',
  multiple: true,
  isClearable: false,
  isSearchable: false,
  defaultValue: null,
  onChange: () => {}
};

const options = [
  { label: '0 estrelas', value: '0' },
  { label: '1 estrela', value: '1' },
  { label: '2 estrelas', value: '2' },
  { label: '3 estrelas', value: '3' },
  { label: '4 estrelas', value: '4' },
  { label: '5 estrelas', value: '5' }
];

function RankingSelect({ onChange, ...props }) {
  function handleChange(values) {
    const finalValues = values?.includes('0') ? [...values, 'null'] : values;

    onChange(finalValues);
  }

  return <Select { ...props } options={options} onChange={handleChange} />;
}

RankingSelect.propTypes = propTypes;
RankingSelect.defaultProps = defaultProps;

export default RankingSelect;
