import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useAlert } from 'react-alert';
import Modal from '@/components/Modal';
import ProposalForm from '@/components/Proposal/ProposalForm';
import ProposalPreview from '@/components/Proposal/ProposalPreview';
import ProposalPDF from '@/components/Proposal/ProposalPDF';
import ProposalLoading from '@/components/Proposal/ProposalLoading';
import ProposalRenameModal from '@/components/Proposal/ProposalRenameModal';
import { getInitialValues as getProductsInitialValues } from '@/components/Inputs/ProductsFields';
import { useAuth } from '@/lib/auth';
import { createUppy, useUppy } from '@/lib/uppy';
import { useTracking } from '@/lib/tracking';
import { useCreateProposalDeal } from '@/api';
import { FILE_EXTENSIONS, PROPOSAL_SECTIONS_TYPE, formatAddressLine } from '@/utils';
import {
  currentDateInSlash, toShortTextualDateString, toDateString,
  toUTCISOString, nextMonth
} from '@/date';

ProposalModal.propTypes = {
  deal: PropTypes.object,
  proposalModel: PropTypes.object,
  account: PropTypes.object,
  show: PropTypes.bool,
  layoutMode: PropTypes.bool,
  entity: PropTypes.object,
  entityType: PropTypes.string,
  onHide: PropTypes.func,
  onUpsertProposalModel: PropTypes.func,
  onCreateProposal: PropTypes.func,
  loading: PropTypes.bool,
  modalSize: PropTypes.string
};

ProposalModal.defaultProps = {
  deal: {},
  proposalModel: {},
  account: {},
  show: false,
  layoutMode: false,
  entity: {},
  entityType: '',
  onHide: () => { },
  onUpsertProposalModel: () => { },
  onCreateProposal: () => { },
  loading: true,
  modalSize: 'xxl'
};

const PDF_TYPE = 'application/pdf';
const MAX_FILE_SIZE = 50;

function ProposalModal(props) {
  const { deal, proposalModel, show, entity, entityType, onUpsertProposalModel,
    onHide, onCreateProposal, loading, modalSize, layoutMode } = props;

  const alert = useAlert();
  const tracker = useTracking();
  const currentDate = toShortTextualDateString(new Date());
  const versionTag = `${deal?.proposalDealCurrentVersion}-${deal?.id}-${currentDateInSlash()}`;
  const version = `proposta_v${versionTag}`;
  const filename = `${version}.pdf`;

  const proposalDealMutation = useCreateProposalDeal();

  const { user } = useAuth();

  const [processing, setProcessing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [hideDocument, setHideDocument] = useState(true);
  const [showRenameModal, setShowRenameModal] = useState(false);

  const isEditLayout = Boolean(proposalModel?.id);

  const defaultProposal = {
    ...proposalModel,
    deal,
    entity,
    entityType,
    proposalModelId: proposalModel?.id,
    entityEmail: entity?.contact?.email,
    entityWorkPhone: entity?.contact?.workPhone,
    entityCnpj: entity?.cnpj,
    entityName: entity?.name,
    entity_products: getProductsInitialValues(deal?.entityProducts),
    entityAddress: formatAddressLine(entity?.address),
    date: null,
    expiredAt: toDateString(nextMonth())
  };

  const [proposal, setProposal] = useState(defaultProposal);

  useEffect(() => {
    if (show && !loading) {
      const newProposal = {
        ...proposal,
        ...proposalModel,
        deal,
        entity,
        entityType,
        proposalModelId: proposalModel?.id,
        entityEmail: entity?.contact?.email,
        entityWorkPhone: entity?.contact?.workPhone,
        entityCnpj: entity?.cnpj,
        entityName: entity?.name,
        entityAddress: formatAddressLine(entity?.address),
        entity_products: getProductsInitialValues(deal?.entityProducts)
      };
      setProposal(newProposal);
    }
  }, [show, proposalModel, loading]);

  const uppy = useUppy(() => {
    const newUppy = createUppy({
      allowedFileTypes: FILE_EXTENSIONS,
      maxFileSizeMega: MAX_FILE_SIZE,
      maxNumberOfFiles: 1
    });

    newUppy
      .on('restriction-failed', (file) => {
        alert.show(
          `Não foi possível criar o arquivo dessa proposta.
          Tamanho máximo permitido é de ${MAX_FILE_SIZE} MB.`,
          { variant: 'danger' }
        );
        newUppy.removeFile(file.id);
        setProcessing(false);
      })
      .on('upload-error', (file) => {
        alert.show(
          `Upload falhou do arquivo ${file.name}`,
          { variant: 'danger' }
        );
        newUppy.removeFile(file.id);
        setProcessing(false);
      })
      .on('complete', () => {
        setProcessing(false);
      });
    return newUppy;
  });

  function clearMainState() {
    uppy.reset();
    setSaving(false);
    setProcessing(false);
    setPdfBlob(null);
    setHideDocument(true);
    setShowRenameModal(false);
  }

  const handleChange = (newProposal) => {
    setProposal(newProposal);
  };

  const upsertFileBlob = (fileBlob) => {
    uppy.reset();
    uppy.addFile({
      name: filename,
      type: PDF_TYPE,
      data: new Blob([fileBlob], { type: PDF_TYPE })
    });
  };

  const handleCreatePDF = (pdfBlobData) => {
    upsertFileBlob(pdfBlobData);

    setPdfBlob(pdfBlobData);
  };

  const handlePreview = (newProposal) => {
    setProcessing(true);
    setProposal(newProposal);
    setTimeout(() => setHideDocument(false), 1);
  };

  const handleUpsert = (proposalData, done) => {
    const params = {
      ...proposalData,
      id: proposalData?.proposalModelId,
      cpf_cnpj: proposalData?.cpfCnpj,
      legal_name: proposalData?.legalName,
      bg_color: proposalData?.bgColor,
      text_color: proposalData?.textColor,
      proposal_model_sections: proposalData?.proposalModelSections?.map((section) => {
        const { sectionType } = section;
        return { ...section, section_type: sectionType };
      })
    };
    onUpsertProposalModel(params, (err) => {
      if (err) {
        done?.(err);
        return;
      }
      const { proposalModelSections: sections } = proposal;
      const { PRODUCTS, RICH_TEXT } = PROPOSAL_SECTIONS_TYPE;
      const countSectionsText = sections?.filter(({
        sectionType, hidden
      }) => sectionType === RICH_TEXT && !hidden).length;
      const countSectionsProducts = sections?.filter(({
        sectionType, hidden
      }) => sectionType === PRODUCTS && !hidden).length;
      const hasLogo = proposal?.logo;
      const hasBanner = proposal?.banner;

      if (isEditLayout) {
        tracker.trackProposalModelUpdated({
          user,
          hasLogo,
          hasBanner,
          countSectionsText,
          countSectionsProducts
        });
      } else {
        tracker.trackProposalModelCreated({
          user,
          hasLogo,
          hasBanner,
          countSectionsText,
          countSectionsProducts
        });
      }
      onHide(() => {
        clearMainState();
        done?.();
      });
    });
  };

  const handleSave = async () => {
    setHideDocument(true);
    setSaving(true);

    if (layoutMode) {
      if (!isEditLayout) {
        setShowRenameModal(true);
        return;
      }

      await handleUpsert(proposal);
    } else {
      const files = uppy.getFiles();
      const documents = files.map((file) => file?.response?.body);
      const activity = {
        text: `Envio de proposta para: ${proposal?.entityName || 'não informado'}.`,
        type: 'proposal',
        assigned_user_ids: [user.id],
        due_at: toUTCISOString(new Date()),
        entity_id: deal.id,
        entity: 'deal',
        images: [],
        documents
      };

      await proposalDealMutation.mutateAsync({ deal_id: deal.id });

      onCreateProposal(activity, (err) => {
        if (err) {
          alert.show(
            `Erro ao criar atividade com proposta.
            Tamanho máximo permitido é de ${MAX_FILE_SIZE} MB por arquivo.`,
            { variant: 'danger' }
          );
          return;
        }
        const { proposalModelSections: sections } = proposal;
        const { PRODUCTS, RICH_TEXT } = PROPOSAL_SECTIONS_TYPE;
        const hasSectionProducts = sections?.some(({ sectionType }) => sectionType === PRODUCTS);
        const hasDealProducts = deal?.entityProducts.length > 0;
        const hasText = sections?.some(({ sectionType }) => sectionType === RICH_TEXT);
        tracker.trackProposalCreated({
          user,
          hasProducts: hasSectionProducts && hasDealProducts,
          hasText
        });
        clearMainState();
      });
    }
  };

  const handleClose = () => onHide(() => clearMainState());
  const handleBackToForm = () => {
    setSaving(false);
    setProcessing(false);
    setPdfBlob(null);
    setHideDocument(true);
    setShowRenameModal(false);
  };

  const renderMainContent = () => {
    if (loading) {
      return (
        <ProposalLoading
          title={`Preparando ${layoutMode ? 'modelo' : 'proposta'}`}
          subtitle='Aguarde só um pouco, estamos carregando os dados.'
        />
      );
    }
    if (pdfBlob) {
      return (
        <ProposalPreview
          layoutMode={layoutMode}
          preview={pdfBlob}
          saving={saving}
          onCancel={handleBackToForm}
          onSave={handleSave}
        />
      );
    }
    return (
      <ProposalForm
        layoutMode={layoutMode}
        user={user}
        proposal={proposal}
        version={version}
        processing={processing}
        currentDate={currentDate}
        onCancel={handleClose}
        onChange={handleChange}
        onSubmit={handlePreview}
      />
    );
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        bodyClassName='py-1 px-0 h-75 mh-75 vh-75 min-vh-75'
        backdrop='static'
        size={modalSize}
      >
        <div className={classnames(
          'modal-header modal-header-sticky w-100 rounded-0',
          'd-block bg-white mt-0 border-top-left-radius-0 px-8'
        )}>
          {
            layoutMode
              ? (
                <h2 className='text-darker-gray me-2 mt-1'>
                  {
                    isEditLayout
                      ? `Editar ${proposalModel?.name ?? 'modelo de proposta'}`
                      : 'Criar modelo de proposta'
                  }
                </h2>
              )
              : (
                <>
                  <h1 className='text-dark-gray me-2 mt-1'>
                    {proposalModel?.name ?? 'modelo de proposta'}
                  </h1>
                </>
              )
          }
        </div>
        {renderMainContent()}
      </Modal>
      <ProposalRenameModal
        show={showRenameModal}
        title='Salvar modelo de proposta'
        icon='proposal'
        onSubmit={handleUpsert}
        onHide={handleBackToForm}
        proposalModel={proposal}
      />
      <ProposalPDF
        show={!hideDocument}
        layoutMode={layoutMode}
        filename={filename}
        user={user}
        proposal={proposal}
        version={version}
        onCreate={handleCreatePDF}
      />
    </>
  );
}

export default ProposalModal;
