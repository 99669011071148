import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { useAlert } from 'react-alert';

import Modal from '@/components/Modal';
import Header from '@/components/EntityModal/Person/Header/Header';
import Activities from '@/components/EntityModal/Person/Activities/Activities';
import Sidebar from '@/components/EntityModal/Person/Sidebar/Sidebar';
import { usePerson, useUpdatePerson, useDeletePerson } from '@/api';
import { PersonModalProvider } from '@/contexts/personModal';
import APIErrorMessage from '@/components/APIErrorMessage';

const propTypes = {
  id: PropTypes.oneOfType([PropTypes.string])
};

function PersonModal({ id }) {
  const alert = useAlert();
  const navigate = useNavigate();
  const updatePersonMutation = useUpdatePerson();
  const deletePersonMutation = useDeletePerson();
  const {
    data: person,
    isLoading
  } = usePerson({ personId: id, config: { staleTime: 1000 } });

  const onUpdate = (params, done) => {
    updatePersonMutation.mutate({ personId: id, params }, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='person' action='update' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.({ error: err });
      },
      onSuccess: (data) => {
        alert.show(
          'Pessoa atualizada com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        done?.(data);
      }
    });
  };

  const handleClose = () => {
    navigate(location?.pathname, { replace: true });
  };

  const onDelete = (_params, done) => {
    deletePersonMutation.mutate({ personId: id }, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='person' action='delete' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.(err);
      },
      onSuccess: () => {
        alert.show(
          'Pessoa Excluida',
          { variant: 'success', timeout: 5000 }
        );
        done?.();
        handleClose();
      }
    });
  };

  if (!person && isLoading) {
    return null;
  }

  return (
    <Modal
      id='person-modal'
      size='xxxl'
      baseClassName='person-modal'
      bodyClassName='bg-light p-0 rounded'
      show={Boolean(id)}
      onHide={handleClose}
    >
      <PersonModalProvider value={{ person: person.data, onUpdate, onDelete }}>
        <Row className='mb-2'>
          <Col sm='12'>
            <Header />
          </Col>
          <Col sm='8'>
            <Activities />
          </Col>
          <Col sm='4'>
            <Sidebar />
          </Col>
        </Row>
      </PersonModalProvider>
    </Modal>
  );
}

PersonModal.prototype = propTypes;

export default PersonModal;
