import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { parseDate, toDateString } from '@/date';
import { toCurrencyString } from '@/number';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import { pluralize } from '@/utils';
import Badge from '@/components/Badge';
import Popover from '@/components/Popover';
import PaymentSummary from '@/components/Billing/PaymentSummary';
import ExternalLink from '@/components/ExternalLink';
import { legacyRegisterUserUrl } from '@/routes';

const propTypes = {
  data: PropTypes.object
};

const renderPlanInfoFunctions = {
  paid: renderPaidPlanInfo,
  trial: renderTrialPlanInfo,
  free: renderFreePlanInfo
};

function CurrentPlanCard({ data }) {
  const currentActiveUsersText = getCurrentActiveUsersText(data);
  const [isUsersLeft, leftUsersText] = getLeftUsersInfo(data);

  const renderPlanInfoFunction = renderPlanInfoFunctions[data.subscriptionType];

  return (
    <div
      className='d-flex flex-column h-100 p-4 border rounded border-light text-small'
    >
      <span className='text-dark-gray'>Plano atual</span>

      <hr />

      {renderPlanInfoFunction(data)}

      <hr />

      <div className='mt-2 d-flex align-items-start justify-content-between'>
        <div>
          <div className='fw-bold lh-1'>Usuários</div>

          <div className='mt-1 text-dark-gray'>
            {currentActiveUsersText}
          </div>

          {
            isUsersLeft &&
            <Badge variant='light-green' className='mt-1 fw-normal text-small'>
              {leftUsersText}
            </Badge>
          }
        </div>

        <Button
          as={ExternalLink}
          href={legacyRegisterUserUrl()}
        >
          <Icon className='me-1' name='add'/>
          Convidar usuários
        </Button>
      </div>
    </div>
  );
}

function renderPaidPlanInfo(data) {
  const finalMonthlyValue = toCurrencyString(data.monthlyInfo.value);

  return (
    <div className='mt-2 mb-2 d-flex justify-content-between'>
      <div className='flex-fill'>
        <h2>
          {data.name} {data.maxUsersCount}

          {data.canceled &&
            <Badge variant='light-red' className='text-small ms-2'>
              Cancelado
            </Badge>
          }
        </h2>
      </div>

      <div className='d-flex align-items-center'>
        <h2 className={classnames('d-inline me-1', { 'text-dark-gray': data.canceled })}>
          {data.canceled
            ? `Válido até ${toDateString(parseDate(data.expirationDate))}`
            : `${finalMonthlyValue}/mês`
          }
        </h2>

        {!data.canceled && (
          <Popover
            content={() => (
              <PaymentSummary
                isPopoverContent={true}
                monthlyInfo={data.monthlyInfo}
                currentValuePerUser={data.currentValuePerUser}
                customDiscount={data.customDiscount}
                defaultDiscount={data.defaultDiscount}
                expirationDate={data.expirationDate}
                finalDiscountAmount={data.finalDiscountAmount}
                finalValue={data.finalValue}
                periodicity={data.periodicity}
                withoutDiscountValue={data.withoutDiscountValue}
                maxUsersCount={data.maxUsersCount}
              />
            )}
            contentClassName='p-3 bg-light'
            trigger={['click']}
            placement='bottom-end'
            rootClose
            rootCloseEvent='mousedown'
            flip
          >
            <div className='d-flex align-items-center'>
              <Icon
                className='text-dark-gray cursor-pointer link-primary'
                name='info'
                size='sm'
              />
            </div>
          </Popover>
        )}
      </div>
    </div>
  );
}

function renderTrialPlanInfo(data) {
  return (
    <div className='mt-2 mb-2 d-flex justify-content-between'>
      <div className='d-flex align-items-center'>
        <h2 className='d-inline me-3'>{data.name}</h2>

        <Badge variant='primary' className='text-primary text-small px-1 py-2 bg-opacity-10'>
          período de teste
        </Badge>
      </div>

      <div className='d-flex align-items-center'>
        <h2 className='d-inline me-1'>Teste até {toDateString(parseDate(data.expirationDate))}</h2>
      </div>
    </div>
  );
}

function renderFreePlanInfo(data) {
  return (
    <div className='mt-2 mb-2 d-flex justify-content-between'>
      <div>
        <h2>Gratuito</h2>

        <div className='mt-1 text-dark-gray'>
          <span>{data.currentActiveUsersCount} </span>
          <span>{pluralize('usuário', data.currentActiveUsersCount)} </span>
          <span>x R$ 0,00</span>
        </div>
      </div>

      <div className='d-flex align-items-center'>
        <h2 className='d-inline me-1'>R$ 0</h2>
      </div>
    </div>
  );
}


function getCurrentActiveUsersText(currentPlan) {
  return `
    ${currentPlan.currentActiveUsersCount}
    ${pluralize('usuário', currentPlan.currentActiveUsersCount)}
    ${pluralize('ativo', currentPlan.currentActiveUsersCount)}
  `;
}

function getLeftUsersInfo(currentPlan) {
  let isUsersLeft = false;
  let leftUsersText = '';

  if (currentPlan.maxUsersCount) {
    const leftUsersCount = currentPlan.maxUsersCount - currentPlan.currentActiveUsersCount;

    isUsersLeft = leftUsersCount !== 0;

    const message = leftUsersCount === 1
      ? 'convite de usuário disponível'
      : 'convites de usuário disponíveis';

    leftUsersText = `${leftUsersCount} ${message}`;
  }


  return [isUsersLeft, leftUsersText];
}

CurrentPlanCard.propTypes = propTypes;

export default CurrentPlanCard;
