export function canCreateEmailSignature({ user }) {
  let effect, reason;

  if (!user.account.paid) {
    effect = 'paywall';
    reason = 'Para criar assinaturas de e-mail, é necessário assinar um plano.';
  } else {
    effect = 'allow';
  }

  return { effect, reason };
}

export function canEditEmailSignature({ user }) {
  let effect, reason;

  if (!user.account.paid) {
    effect = 'paywall';
    reason = 'Para editar assinaturas de e-mail, é necessário assinar um plano.';
  } else {
    effect = 'allow';
  }

  return { effect, reason };
}

export function canDeleteEmailSignature({ user }) {
  let effect, reason;

  if (!user.account.paid) {
    effect = 'paywall';
    reason = 'Para excluir assinaturas de e-mail, é necessário assinar um plano.';
  } else {
    effect = 'allow';
  }

  return { effect, reason };
}
