import React from 'react';
import EntityTitle from '@/components/EntityModal/EntityTitle';
import LegacyButton from '@/components/EntityModal/LegacyButton';
import Menu from '@/components/EntityModal/Menu';
import { useOrganization, useOnUpdate, useOnDelete } from '@/contexts/organizationModal';
import { canEditOrganization } from '@/policies';
import { organizationEditPath } from '@/routes';
import SelectOwnerUser from '@/components/EntityModal/SelectOwnerUser';

function Header() {
  const organization = useOrganization();
  const onUpdate = useOnUpdate();
  const onDelete = useOnDelete();

  return (
    <div className='bg-white rounded p-4 mb-4'>
      <div className='mb-4 d-flex justify-content-between'>
        <EntityTitle
          className='width-5'
          onUpdate={onUpdate}
          entity={{ title: organization.name }}
        />
        <LegacyButton id={organization.id} entityType='organization' />
      </div>
      <div className='d-flex justify-content-between'>
        <SelectOwnerUser
          ownerUser={organization.ownerUser}
          canEditPolicy={canEditOrganization}
          onUpdate={onUpdate}
        />
        <Menu
          entity={organization}
          entityType='organization'
          loading={false}
          onDelete={onDelete}
          editPath={organizationEditPath(organization.id)}
        />
      </div>
    </div>
  );
}

export default Header;
