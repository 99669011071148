import React from 'react';
import Base from 'react-bootstrap/ToggleButtonGroup';

const ToggleButtonGroup = React.forwardRef(
  ({ children, ...props }, ref) => (
    <Base { ...props } ref={ref}>
      {children}
    </Base>
  )
);

ToggleButtonGroup.displayName = 'ToggleButtonGroup';

export default ToggleButtonGroup;
