import { useEffect, useLayoutEffect, useRef } from 'react';
import { isBlank } from '@/utils';

// Ref: https://usehooks-ts.com/react-hook/use-timeout

export function useTimeout(callback, delay = null) {
  const savedCallback = useRef(callback);

  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (isBlank(delay)) {
      return;
    }

    const id = setTimeout(() => savedCallback.current(), delay);

    return () => clearTimeout(id);
  }, [delay]);
}
