import React from 'react';
import classnames from 'classnames';
import Button from '@/components/Button';
import Card from 'react-bootstrap/Card';
import Icon from '@/components/Icon';
import Badge from '@/components/Badge';
import { parseDate, toDateTimeString } from '@/date';
import PropTypes from 'prop-types';
import { pluralize } from '@/utils';
import Link from '@/components/Link';
import { importPath } from '@/routes';

const propTypes = {
  entity: PropTypes.string.isRequired,
  entityName: PropTypes.string.isRequired,
  importedAt: PropTypes.string.isRequired,
  importId: PropTypes.number,
  status: PropTypes.oneOf(['created', 'finished']),
  fileName: PropTypes.string,
  className: PropTypes.string,
  recordsCount: PropTypes.number,
  onRevert: PropTypes.func
};

const defaultProps = {
  status: 'finished',
  onRevert: () => {}
};

const BADGE_VARIANTS = {
  finished: {
    color: 'light-green',
    text: 'Concluído'
  },
  created: {
    color: 'yellow',
    text: 'Em andamento'
  }
};

function ImportCard(props) {
  const {
    entity,
    entityName,
    importedAt,
    importId,
    status,
    fileName,
    className,
    recordsCount,
    onRevert
  } = props;

  const isFinished = status === 'finished';

  const isCreated = status === 'created';

  const isImportFailed = status === 'failed';

  const badgeVariant = BADGE_VARIANTS[status];
  const formattedImportedAt = toDateTimeString(parseDate(importedAt));

  const displayEntityName = pluralize(entityName, recordsCount);

  const renderCardBody = () => (
    <Card.Body className='px-2 pb-2 pt-3'>
      <div className='d-flex justify-content-between mb-2'>
        <Link href={importPath(entity, importId)}>
          <h5 className='text-primary'>
            {recordsCount} {displayEntityName}
          </h5>
        </Link>
        <Badge variant={isImportFailed ? 'light-red' : badgeVariant.color}>
          {isImportFailed ? 'Falha na importação' : badgeVariant.text}
        </Badge>
      </div>
      <div className='text-dark-gray'>{formattedImportedAt}</div>
      <div className='text-dark-gray'>{fileName}</div>
    </Card.Body>
  );

  const renderFooter = () => (
    <Card.Footer className='p-0 bg-transparent border-lighter-gray'>
      {isFinished && (
        <Button
          className='w-100 px-7 border-0 border-top-left-radius-0 border-top-right-radius-0'
          variant='outline-danger'
          onClick={onRevert}
        >
          <Icon name='delete' className='me-1' />
          <span>Desfazer importação</span>
        </Button>
      )}
      {isCreated && (
        <p className='py-2 text-center text-dark-gray mb-0'>
          <i>Aguarde...</i>
        </p>
      )}
      {isImportFailed && (
        <Button
          className='w-100 px-7 border-0 border-top-left-radius-0 border-top-right-radius-0'
          variant='outline-danger'
          onClick={onRevert}
        >
          <Icon name='delete' className='me-1' />
          <span>Excluir registro</span>
        </Button>
      )}
    </Card.Footer>
  );

  return (
    <Card className={classnames('mb-2 border-lighter-gray', className)}>
      {renderCardBody()}
      {renderFooter()}
    </Card>
  );
}

ImportCard.propTypes = propTypes;
ImportCard.defaultProps = defaultProps;

export default ImportCard;
