import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';
import { EditInPlace, RichTextInput, TextInput } from '@/components/Inputs';

ProposalRichText.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string
};

ProposalRichText.defaultProps = {
  title: 'Seção personalizada',
  content: ''
};

const RICHT_TEXT_MAX_WIDTH = 1146;
const PDF_MAX_WIDTH = 1216;

export function ProposalRichText({ title, content }) {
  /*
   * Ao redimensionarmos a imagem para a largura máxima do RichText para que
   * seja expandida por toda tela, o mesmo comportamento não era passado ao
   * PDF. Por isso, a medida paliativa foi que, ao chegar à largura máxima
   * do editor, a imagem seja redimensionada para a largura máxima do PDF.
   * Para isso, foi utilizada a manipulação DOM in memory para ajustar
   * width / height antes de renderizar no PDF.
   */
  const contentEl = document.createElement('div');
  contentEl.innerHTML = content;
  const images = contentEl.querySelectorAll('img');

  images.forEach((img) => {
    if (img.width >= RICHT_TEXT_MAX_WIDTH) {
      const originalWidth = img.width;
      const originalHeight = img.height;
      img.width = PDF_MAX_WIDTH;
      img.height = (originalHeight / originalWidth) * PDF_MAX_WIDTH;
    }
  });

  return (
    <div className='d-block px-4 my-6'>
      <hr className='mt-4 mb-6 proposal-hr' />
      <h2 className='fw-bold my-4'>{title ?? 'Seção personalizada'}</h2>
      <div
        className='word-wrap clearfix'
        dangerouslySetInnerHTML={{ __html: contentEl.innerHTML }}
      />
    </div>
  );
}

ProposalRichTextInput.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  content: PropTypes.string,
  onChangeContent: PropTypes.func,
  onChangeTitle: PropTypes.func,
  actions: PropTypes.any
};

ProposalRichTextInput.defaultProps = {
  title: 'Seção personalizada',
  name: '',
  content: '',
  onChangeContent: () => { },
  onChangeTitle: () => { },
  actions: null
};

export function ProposalRichTextInput({
  title, content, name, onChangeContent, actions, onChangeTitle, layoutMode
}) {
  const [isExpanded, setIsExpanded] = useState(true);

  const handleAccordionToggle = (eventKey, event) => {
    if (['button', 'svg'].includes(event.target.tagName.toLowerCase())) {
      setIsExpanded((prevState) => !prevState);
    }
  };

  return (
    <Row className='mx-6'>
      <Accordion activeKey={isExpanded ? name : null} onSelect={handleAccordionToggle}>
        <Accordion.Item eventKey={name} className='border-light'>
          <div className='d-flex justify-content-between align-items-center px-5 py-4'>
            <div className='d-flex w-100'>
              <EditInPlace
                className='width-5'
                name='title'
                as={TextInput}
                hideEdit={!layoutMode}
                value={title ?? 'Seção personalizada'}
                placeholder='Dê um título para a seção'
                onConfirm={(params, done) => {
                  onChangeTitle(params?.title);
                  done();
                }}
              >
                <h2 className='text-medium fw-bold d-block'>
                  {title ?? 'Seção personalizada'}
                </h2>
              </EditInPlace>
            </div>
            <div className='d-flex align-items-center flex-grow-1'>
              {actions}
              <Accordion.Button className='p-0'/>
            </div>
          </div>
          <Accordion.Body>
            <RichTextInput
              name='content'
              value={content}
              className='mb-4 mt-n4'
              autoFocus={true}
              onChange={(newContent) => onChangeContent(newContent)}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Row>
  );
}
