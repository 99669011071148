import { baseLegacyUrl } from '@/routes';
import { getUrlWithQueryString } from '@/utils';

export function organizationPeoplePath(id) {
  return `${organizationsPath()}/${id}/people`;
}

export function organizationDealsPath(id) {
  return `${organizationsPath()}/${id}/deals`;
}

export function organizationEditPath(id) {
  return `${organizationsPath()}/${id}/edit`;
}

export function organizationsPath(params) {
  return getUrlWithQueryString('/organizations', params);
}

export function organizationPath(id, base = '') {
  return `${base}?organizationId=${id}`;
}

export function legacyOrganizationsUrl() {
  return `${baseLegacyUrl()}/empresas`;
}

export function legacyOrganizationUrl(id, forceLegacy = false) {
  return `${baseLegacyUrl()}/empresas/historico.php?id=${id}${forceLegacy ? '&legado=1' : ''}`;
}

export function legacyOrganizationsImportUrl() {
  return `${baseLegacyUrl()}/importar/?env=empresas`;
}
