import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Table from '@/components/Table';

const propTypes = {
  tableColumnsNames: PropTypes.array,
  tableColumnsData: PropTypes.array,
  fixedHead: PropTypes.bool
};

const defaultProps = {
  tableColumnsNames: [],
  tableColumnsData: [],
  fixedHead: false
};

function renderTableHead(tableColumnsNames) {
  return (
    <tr>
      {
        tableColumnsNames.map((column) => (
          <th key={column} className='min-width-5 max-width-5 pt-0 ps-0' >
            {column}
          </th>
        ))
      }
    </tr>
  );
}

function renderTableBody(tableColumnsData) {
  const rowsQuantity = tableColumnsData[0].data.length;
  return (
    Array.from({ length: rowsQuantity }).map((_, index) => (
      <tr key={index}>
        { tableColumnsData.map((map) => (
          <td
            key={map.name}
            className='min-width-5 max-width-5 ps-0 text-break-all'
          >
            {map.data[index]}
          </td>
        ))}
      </tr>
    ))
  );
}

function ImportTable({ tableColumnsData, tableColumnsNames, fixedHead }) {
  return (
    <Table>
      <thead className={classnames(
        'bg-white',
        'shadow-down',
        'text-uppercase',
        'text-dark-gray',
        { 'sticky-top': fixedHead }
      )}>
        {renderTableHead(tableColumnsNames)}
      </thead>
      <tbody className='text-dark-gray'>
        {renderTableBody(tableColumnsData)}
      </tbody>
    </Table>
  );
}

ImportTable.propTypes = propTypes;
ImportTable.defaultProps = defaultProps;

export default ImportTable;
