import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

ProposalIconLabel.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string
};

ProposalIconLabel.defaultProps = {
  text: '',
  icon: '',
  className: ''
};

function ProposalIconLabel({ icon, text, className }) {
  if (!text) {
    return null;
  }
  return (
    <div className={classnames('d-block', className)}>
      {/* Usa span para carregar base64 icons do _proposal.scss para renderizar no pdf */}
      {icon ? <span className={classnames('me-1', icon)} /> : null}
      <span className='d-inline text-wrap word-wrap'>{text}</span>
    </div>
  );
}

export default ProposalIconLabel;
