import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import AccountData from '@/components/LeadsGeneration/Payment/AccountData';
import APIErrorMessage from '@/components/APIErrorMessage';
import DecisionMakers from '@/components/LeadsGeneration/Segmentation/DecisionMakers';
import FindSimilar from '@/components/LeadsGeneration/Segmentation/FindSimilar';
import GenericStepWrapper from '@/components/LeadsGeneration/GenericStepWrapper';
import LeadsGenerationFunnel from '@/components/LeadsGeneration/LeadsGenerationFunnel';
import LeadsGenerationList from '@/components/LeadsGeneration/LeadsGenerationList';
import OrganizationSize from '@/components/LeadsGeneration/Segmentation/OrganizationSize';
import PaymentData from '@/components/LeadsGeneration/Payment/PaymentData';
import PublicRegion from '@/components/LeadsGeneration/Segmentation/PublicRegion';
import SearchStepWrapper from '@/components/LeadsGeneration/SearchStepWrapper';
import { LeadsGenerationProvider } from '@/contexts';
import { useInfinitePreview, useCurrentAccount } from '@/api';
import { useDebouncedValue } from '@/hooks';
import { useTracking } from '@/lib/tracking';
import { useAlert } from 'react-alert';
import { useAuth } from '@/lib/auth';
import { isBlank } from '@/utils';

const propTypes = {
  onStepChange: PropTypes.func,
  getSelectedLeadsCount: PropTypes.func,
  setLeadsPaymentProcess: PropTypes.func
};

const PUBLIC_REGION_DATA = {
  business_model: 'b2b',
  search_by: 'state',
  addresses: {
    states: null,
    cities_id: null,
    cities: null
  }
};

const PAYMENT_DATA = {
  type: 'credit_card',
  credit_card: {
    is_valid: false,
    end_digits: null,
    brand: null
  }
};

const FUNNEL_DATA = {
  funnel_selection: 'leads',
  funnel_id: null,
  funnel_name: null
};

const STEP_ITEMS = [
  { icon: 'deal', label: 'Público e região' },
  { icon: 'integration', label: 'Tamanho da empresa' },
  { icon: 'team', label: 'Decisores' },
  { icon: 'search', label: 'Encontrar similares' }
];

const STEP_MAX = 8;
const SEARCH_STEP_MAX = 4;
const MIN_LEADS = 50;
const PAYMENT_INDEX = 7;

const LeadsGenerationWrapper = forwardRef((props, ref) => {
  const {
    onStepChange,
    setSelectedLeadsCount,
    selectedLeadsCount,
    setLeadsPaymentProcess,
    onClose,
    setImportId
  } = props;

  const [stepItems] = useState(STEP_ITEMS);
  const [searchStep, setSearchStep] = useState(0);
  const [leadSizeData, setLeadSizeData] = useState({});
  const [leadSizePreference, setLeadSizePreference] = useState('no_preference');
  const [decisionMakersDepartmentsData, setDecisionMakersDepartmentsData] = useState([]);
  const [decisionMakersLevelsData, setDecisionMakersLevelsData] = useState([]);
  const [publicRegionData, setPublicRegionData] = useState(PUBLIC_REGION_DATA);
  const [similarCnpjsData, setSimilarCnpjsData] = useState([]);
  const [funnelData, setFunnelData] = useState(FUNNEL_DATA);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [paymentData, setPaymentData] = useState(PAYMENT_DATA);
  const [encryptedData, setEncryptedData] = useState([]);
  const { data: accountData, isLoading: isLoadingCurrentAccount } = useCurrentAccount();
  const [leadsCount, setLeadsCount] = useState(0);
  const [decisionMakersCount, setDecisionMakersCount] = useState(0);
  const [isEnabledEditingAccountData, setIsEnabledEditingAccountData] = useState(false);

  const tracker = useTracking();
  const { user } = useAuth();
  const alert = useAlert();

  const prevStep = () => setSearchStep(Math.max(searchStep - 1, 0));
  const nextStep = () => setSearchStep(Math.min(searchStep + 1, STEP_MAX));
  const goToStep = (step) => setSearchStep(step);

  useEffect(() => {
    const listStep = 4;

    onStepChange(listStep === searchStep);
  }, [searchStep]);

  const inSearchStep = searchStep < SEARCH_STEP_MAX;

  const CurrentStep = useMemo(() => {
    const steps = [
      <PublicRegion key={1} />,
      <OrganizationSize key={2} />,
      <DecisionMakers key={3} />,
      <FindSimilar key={4} />,
      <LeadsGenerationList key={5} />,
      <LeadsGenerationFunnel key={6} />,
      <AccountData key={7} />,
      <></>
    ];

    return steps[searchStep];
  }, [searchStep]);

  const debounceTimeout = 600;
  const params = useDebouncedValue({
    /*
     * Foi fixado o valor 'b2b' pois é obrigatório enviar
     * a chave business_model consequentemente evitando requisições desnecessárias.
     */
    business_model: 'b2b',
    addresses: {
      states: publicRegionData.addresses.states,
      cities_id: publicRegionData.addresses.cities_id
    },
    lead_size_preference: leadSizePreference,
    lead_size_data: {
      min_value: leadSizeData.minValue,
      max_value: leadSizeData.maxValue
    },
    decision_maker_departments: decisionMakersDepartmentsData,
    decision_maker_levels: decisionMakersLevelsData,
    cnpjs: similarCnpjsData
  }, debounceTimeout);

  const {
    isFetched: isLeadsFetched,
    isFetchingNextPage: isLeadsFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    data = { pages: [] }
  } = useInfinitePreview({
    params,
    config: {
      retry: 2,
      retryDelay: 5000,
      onError: (err) => {
        alert.show(
          <APIErrorMessage
            err={err}
            resource='leads'
            action='list'
            customContent='Tente novamente'
          />,
          { variant: 'danger' }
        );
      }
    }
  });

  const leads = data.pages.flatMap((page) => page.data);
  const leadsCountDummy = data?.pages[0]?.meta?.count;
  const decisionMakersCountDummy = data?.pages[0]?.meta?.decision_makers_count;
  const itemsPerPage = data?.pages[0]?.meta?.items;

  useEffect(() => {
    if (typeof leadsCountDummy === 'number') {
      setLeadsCount(leadsCountDummy);
    }
  }, [leadsCountDummy]);

  useEffect(() => {
    if (typeof decisionMakersCountDummy === 'number') {
      setDecisionMakersCount(decisionMakersCountDummy);
    }
  }, [decisionMakersCountDummy]);

  const mixpanelSegmentationProperties = {
    ...publicRegionData,
    leadSizeData,
    leadSizePreference,
    decisionMakersDepartmentsData,
    decisionMakersLevelsData,
    similarCnpjsData,
    leadsCount,
    selectedLeadsCount,
    ...funnelData,
    searchStep
  };

  const callMixpanelEvent = (mixpanelEvent) => {
    tracker[mixpanelEvent]({
      ...mixpanelSegmentationProperties,
      user
    });
  };

  useImperativeHandle(ref, () => ({
    callMixpanelEvent
  }), [mixpanelSegmentationProperties]);

  const requiredFields = accountData
    ? {
      name: accountData.data?.name,
      legalName: accountData.data?.legalName,
      cpfCnpj: accountData.data?.cpfCnpj,
      workPhone: accountData.data?.contact.workPhone,
      cityName: accountData.data?.address?.city?.name,
      country: accountData.data?.address.country,
      district: accountData.data?.address.district,
      state: accountData.data?.address.state,
      postalCode: accountData.data?.address.postalCode,
      streetNumber: accountData.data?.address.streetNumber?.toString() || '',
      streetName: accountData.data?.address.streetName
    }
    : {};

  const isValidAccountData = useMemo(() => Object.values(requiredFields).every(
    (value) => !isBlank(value) && value.trim()
  ), [requiredFields]);


  const value = {
    stepItems,
    searchStep,
    leadSizeData,
    setLeadSizeData,
    leadSizePreference,
    setLeadSizePreference,
    decisionMakersDepartmentsData,
    setDecisionMakersDepartmentsData,
    decisionMakersLevelsData,
    setDecisionMakersLevelsData,
    publicRegionData,
    setPublicRegionData,
    similarCnpjsData,
    setSimilarCnpjsData,
    setImportId,
    funnelData,
    setFunnelData,
    leads,
    leadsCount,
    decisionMakersCount,
    itemsPerPage,
    isLeadsFetched,
    isLeadsFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    selectedLeads,
    setSelectedLeads,
    accountData: accountData?.data,
    isLoadingCurrentAccount,
    paymentData,
    setPaymentData,
    encryptedData,
    setEncryptedData,
    setLeadsPaymentProcess,
    prevStep,
    nextStep,
    goToStep,
    callMixpanelEvent,
    isEnabledEditingAccountData,
    setIsEnabledEditingAccountData,
    isValidAccountData,
    minLeads: MIN_LEADS
  };

  useEffect(() => {
    setSelectedLeadsCount(selectedLeads.length);
  }, [selectedLeads.length]);

  const StepWrapper = inSearchStep ? SearchStepWrapper : GenericStepWrapper;

  return (
    <LeadsGenerationProvider value={value}>
      <StepWrapper
        stepItems={stepItems}
        currentStepIndex={searchStep}
        callMixpanelEvent={callMixpanelEvent}
      >
        <PaymentData show={searchStep === PAYMENT_INDEX} onClose={onClose} />

        {CurrentStep}
      </StepWrapper>
    </LeadsGenerationProvider>
  );
});

LeadsGenerationWrapper.propTypes = propTypes;
LeadsGenerationWrapper.displayName = 'LeadsGenerationWrapper';

export default LeadsGenerationWrapper;
