import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Tooltip from '@/components/Tooltip';
import {
  isPast,
  isToday,
  formatDistanceToNowStrict
} from '@/date';
import EntityLink from '@/components/EntityLink';

const propTypes = {
  unfreezeDate: PropTypes.instanceOf(Date).isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

const defaultProps = {
  id: '',
  className: ''
};

function DealFreezeIcon({ unfreezeDate, id, className }) {
  const {
    tooltipContent,
    textVariant,
    btnVariant,
    iconType
  } = getInnerProps(unfreezeDate);

  return (
    <Tooltip
      placement='top'
      content={tooltipContent}
      className='pe-none'
    >
      <div className={classnames(
        className,
        'position-relative',
        'd-inline-block',
        'p-1'
      )}>
        <Button
          className={`p-2 rounded lh-0 text-${textVariant}`}
          variant={btnVariant}
          as={EntityLink}
          entity={{ type: 'deal', id }}
          onClick={(event) => event.stopPropagation()}
        >
          <Icon name={iconType} />
        </Button>
      </div>
    </Tooltip>
  );
}

function getInnerProps(date) {
  return {
    ...getVariants(date),
    tooltipContent: getTooltipContent(date)
  };
}

function getVariants(date) {
  const isUnfrozen = isToday(date) || isPast(date);

  if (isUnfrozen) {
    return {
      textVariant: 'white',
      btnVariant: 'primary',
      iconType: 'unfrozen'
    };
  }

  return {
    textVariant: 'light-blue',
    btnVariant: 'lighter-blue',
    iconType: 'frozen'
  };
}

function getTooltipContent(date) {
  if (isToday(date)) {
    return 'A data prevista para descongelar é hoje. Clique para abrir o negócio.';
  } else if (isPast(date)) {
    const diff = formatDistanceToNowStrict(date);

    return (
      `A data prevista para descongelar foi há ${diff}.
      Clique para abrir o negócio.`
    );
  } else {
    const diff = formatDistanceToNowStrict(date);

    return `A data prevista para descongelar é em ${diff}. Clique para abrir o negócio.`;
  }
}

DealFreezeIcon.propTypes = propTypes;
DealFreezeIcon.defaultProps = defaultProps;

export default DealFreezeIcon;
