import { useQuery, useInfiniteQuery, useMutation } from 'react-query';
import { get, post } from '@/lib/fetch';

export const leadsGenerationPreviewKeys = {
  infiniteAll: ['infinite-leads-gen-preview'],
  infiniteLists: () => [...leadsGenerationPreviewKeys.infiniteAll, 'list'],
  infiniteList: (params) => ([...leadsGenerationPreviewKeys.infiniteLists(), params])
};

export async function getPreview(params = {}) {
  const { data } = await get('/leads_generation/preview', params);

  return data;
}

export async function createConfirms(params = {}) {
  const { data } = await post('/leads_generation/confirms', params);

  return data;
}

export function usePreview({ params = {}, config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: leadsGenerationPreviewKeys.infiniteLists(params),
    queryFn: () => getPreview(params)
  });
}

export function useInfinitePreview({ params = {}, config = {} }) {
  return useInfiniteQuery(
    leadsGenerationPreviewKeys.infiniteList(params),
    ({ pageParam: page = 1 }) => getPreview({ ...params, page }),
    {
      ...config,
      getNextPageParam: (lastPage) => lastPage.meta.next
    }
  );
}

export function useCreateConfirms({ config = {} } = {}) {
  return useMutation(
    createConfirms,
    { ...config }
  );
}
