import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { escapeRegExp } from '@/utils';

const propTypes = {
  text: PropTypes.string,
  term: PropTypes.string
};

const defaultProps = {
  text: '',
  term: ''
};

function Highlighter({ text, term }) {
  if (!text || !term) {
    return text;
  }

  const escapedTerm = escapeRegExp(term);

  const regex = new RegExp(`(${escapedTerm})`, 'giu');
  const parts = text.split(regex);

  return (
    <span>
      {parts.map((part, index) => (
        <span
          key={index}
          className={classnames(
            { 'fw-bold': part.toLowerCase() === term.toLowerCase() }
          )}
        >
          {part}
        </span>
      ))}
    </span>
  );
}

Highlighter.propTypes = propTypes;
Highlighter.defaultProps = defaultProps;

export default Highlighter;
