import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

const defaultProps = {
  className: ''
};

function Content({ className, children }) {
  return (
    <div className={classnames(
      'mb-8',
      'pt-2',
      'border-top',
      'border-light',
      'border-1',
      className
    )}>
      {children}
    </div>
  );
}

Content.propTypes = propTypes;
Content.defaultProps = defaultProps;
Content.displayName = 'ConfirmationModal.Content';

export default Content;
