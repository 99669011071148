import React from 'react';
import PropTypes from 'prop-types';
import Select from '@/components/Inputs/Select';

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func
};

const defaultProps = {
  name: 'latest_activity',
  label: 'Última atividade',
  multiple: false,
  isClearable: true,
  isSearchable: false,
  defaultValue: null,
  onChange: () => {}
};

const options = [
  { label: 'Nunca trabalhados (histórico vazio)', value: 'all_time' },
  { label: 'Última atividade há mais de 30 dias', value: 'over_thirthy' }
];

function LatestActivitySelect(props) {
  return <Select { ...props } options={options} />;
}

LatestActivitySelect.propTypes = propTypes;
LatestActivitySelect.defaultProps = defaultProps;

export default LatestActivitySelect;
