import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Base from 'react-bootstrap/Form';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const propTypes = {
  children: PropTypes.func,
  name: PropTypes.string,
  className: PropTypes.string,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onSubmitError: PropTypes.func,
  validationSchema: PropTypes.object,
  validationContext: PropTypes.object,
  shouldUnregister: PropTypes.bool,
  mode: PropTypes.oneOf(['onSubmit', 'onBlur', 'onChange', 'onTouched', 'all'])
};

const defaultProps = {
  className: '',
  defaultValues: {},
  shouldUnregister: false,
  mode: 'all'
};

function HookForm(props) {
  const {
    children,
    onSubmit,
    onSubmitError,
    validationSchema,
    validationContext,
    defaultValues,
    shouldUnregister,
    mode,
    ...rest
  } = props;

  const validationOptions = useMemo(() => {
    if (!validationSchema) {
      return {};
    }

    return {
      resolver: yupResolver(validationSchema),
      context: validationContext
    };
  }, [validationSchema, validationContext]);

  const methods = useForm({
    ...validationOptions,
    mode,
    defaultValues,
    shouldUnregister
  });

  return (
    <FormProvider {...methods}>
      <Base
        { ...rest }
        onSubmit={methods.handleSubmit(onSubmit, onSubmitError)}
      >
        {children(methods)}
      </Base>
    </FormProvider>
  );
}

HookForm.propTypes = propTypes;
HookForm.defaultProps = defaultProps;
HookForm.displayName = 'HookForm';

export default HookForm;
