import React, { createContext, useContext } from 'react';

const OffcanvasPageContext = createContext({
  columns: {}
});

export function useOffcanvasPageContext() {
  const context = useContext(OffcanvasPageContext);

  if (context === undefined) {
    throw new Error('useOffcanvasPage must be used within OffcanvasPageProvider');
  }

  return context;
}

export function OffcanvasPageProvider({ children, value }) {
  return (
    <OffcanvasPageContext.Provider value={value}>
      {children}
    </OffcanvasPageContext.Provider>
  );
}
