import { useQuery, useMutation, useQueryClient } from 'react-query';
import { get, patch } from '@/lib/fetch';

export const currentAccountKeys = {
  all: ['current-account']
};

export async function getCurrentAccount() {
  const { data } = await get('/current_accounts');

  return data;
}

export async function updateCurrentAccount(params) {
  const { data } = await patch('/current_accounts', params);

  return data;
}

export function useCurrentAccount({ config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: currentAccountKeys.all,
    queryFn: () => getCurrentAccount()
  });
}

export function useUpdateCurrentAccount({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries(currentAccountKeys.all);

  return useMutation(
    updateCurrentAccount,
    { onSuccess, ...config }
  );
}
