import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { TextInput } from '@/components/Inputs';

const propTypes = {
  field: PropTypes.object.isRequired,
  name: PropTypes.string,
  label: PropTypes.string
};

/* eslint-disable no-magic-numbers */
export const textCustomFieldValidations = Yup
  .string().max(150, 'Este campo deve ter no máximo 150 caracteres');
/* eslint-enable no-magic-numbers */

export function TextCustomField({ field, label, ...otherProps }) {
  return (
    <TextInput
      label={label ?? field.name}
      placeholder='Digite aqui'
      variant='light'
      autoComplete='off'
      { ...otherProps }
    />
  );
}

TextCustomField.propTypes = propTypes;
