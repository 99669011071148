import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@/components/Avatar';
import Truncate from '@/components/Truncate';
import { AutomationTag } from '@/feature/automation';

const propTypes = {
  deal: PropTypes.object.isRequired
};

function CreatedBy({ deal }) {
  if (deal.createdByAutomation) {
    return (
      <AutomationTag />
    );
  }

  if (!deal.user) {
    return null;
  }

  return (
    <>
      <Avatar
        className='me-1'
        name={deal?.user?.name}
        url={deal?.user?.avatarUrl}
      />
      <Truncate className='max-width-4 text-medium'>
        {deal?.user?.name}
      </Truncate>
    </>
  );
}

CreatedBy.propTypes = propTypes;

export default CreatedBy;
