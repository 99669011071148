import { useMutation } from 'react-query';
import { post } from '@/lib/fetch';

export async function createImportViews(params = {}) {
  const { data } = await post('/imports/views', params);

  return data;
}

export function useCreateImportViews({ config = {} } = {}) {
  return useMutation(
    createImportViews,
    { ...config }
  );
}

