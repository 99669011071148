import React, { useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import PropTypes from 'prop-types';
import ProposalDocumentBody from '@/components/Proposal/ProposalDocumentBody';
import ProposalDocumentFooter from '@/components/Proposal/ProposalDocumentFooter';
import { getCsrfHeader } from '@/lib/fetch';
import { downloadBlobToBase64 } from '@/utils';
import logoImage from 'images/logo-proposal-footer.png';

ProposalPDF.propTypes = {
  user: PropTypes.object,
  proposal: PropTypes.object,
  version: PropTypes.string,
  show: PropTypes.bool,
  filename: PropTypes.string,
  onCreate: PropTypes.func,
  layoutMode: PropTypes.bool
};

ProposalPDF.defaultProps = {
  user: {},
  proposal: {},
  version: '',
  show: false,
  filename: '',
  onCreate: () => {},
  layoutMode: false
};

const PDF_HEADERS = {
  'Content-Type': 'application/json',
  ...getCsrfHeader()
};

/*
 * Responsavel por gerar HTML string dos componentes para enviar pro backend converter para PDF.
 * Atenção: wicked_pdf não suporta CSS moderno, partes do layout foi reescrito do jeito antigo.
 */
function ProposalPDF({ user, proposal, version, show, filename, onCreate, layoutMode }) {
  useEffect(() => {
    async function createPDF() {
      const imagesBase64 = await Promise.all([
        downloadBlobToBase64(proposal?.banner?.preview),
        downloadBlobToBase64(proposal?.logo?.preview),
        downloadBlobToBase64(logoImage)
      ]);
      const [bannerBase64, logoBase64, footerLogoBase64] = imagesBase64;

      const content = `<!doctype html>${renderToString(
        <ProposalDocumentBody
          layoutMode={layoutMode}
          user={user}
          proposal={proposal}
          version={version}
          bannerBase64={bannerBase64}
          logoBase64={logoBase64}
        />
      )}`;

      const footer = `<!doctype html>${renderToString(
        <ProposalDocumentFooter
          user={user}
          proposal={proposal}
          logoBase64={footerLogoBase64}
        />
      )}`;

      const margin = { top: 4, bottom: 26, left: 0, right: 0 };
      const body = JSON.stringify({ content, footer, margin, filename });

      const response = await fetch('/api/pdf', {
        method: 'POST', body, headers: PDF_HEADERS
      });

      const pdfBlob = await response.blob();
      onCreate(pdfBlob);
    }
    if (show) {
      createPDF();
    }
  }, [show]);

  return null;
}

export default ProposalPDF;
