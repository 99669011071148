import React from 'react';
import { useAutomations } from '@/api';
import AutomationCard from '@/feature/automation/AutomationCard';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import SectionTitle from '@/feature/automation/SectionTitle';

function AutomationsIndex() {
  const { data } = useAutomations();

  const automationGroup = {};
  data?.data.forEach((automation) => {
    const actionName = automation.actionName;
    if (!automationGroup[actionName]) {
      automationGroup[actionName] = [];
    }
    automationGroup[actionName].push(automation);
  });

  return (
    <div>
      {Object.entries(automationGroup).map(([actionName, automations]) => (
        <React.Fragment key={actionName}>
          <SectionTitle actionName = {actionName} />
          <Row className='g-4 row-cols-auto'>
            {automations.map((automation) => (
              <Col key={automation.id}>
                <AutomationCard data={automation} />
              </Col>
            ))}
          </Row>
        </React.Fragment>
      ))}
    </div>
  );
}

export default AutomationsIndex;
