import React from 'react';
import Icon from '@/components/Icon';
import Pills from '@/components/Pills';
import NavLink from '@/components/NavLink';
import Tooltip from '@/components/Tooltip';
import ExternalLink from '@/components/ExternalLink';
import { dealsPath, funnelsPath, legacyDealsMapUrl } from '@/routes';

const propTypes = {};

const defaultProps = {};

function DealsNav() {
  return (
    <Pills
      className='nav-deal-options me-3 rounded-3 border border-light-gray overflow-hidden'
    >
      <Tooltip content='Listagem'>
        <Pills.Item>
          <NavLink
            href={dealsPath()}
            className='ps-2 pe-2 py-1 rounded-0'
          >
            <Icon name='listing' />
          </NavLink>
        </Pills.Item>
      </Tooltip>

      <Tooltip content='Funil'>
        <Pills.Item>
          <NavLink
            href={funnelsPath()}
            className='px-3 py-1 rounded-0'
          >
            <Icon name='funnel' />
          </NavLink>
        </Pills.Item>
      </Tooltip>

      <Tooltip content='Mapa'>
        <Pills.Item>
          <NavLink
            as={ExternalLink}
            href={legacyDealsMapUrl()}
            className='ps-2 pe-2 py-1 rounded-0'
          >
            <Icon name='map' />
          </NavLink>
        </Pills.Item>
      </Tooltip>
    </Pills>
  );
}

DealsNav.propTypes = propTypes;
DealsNav.defaultProps = defaultProps;

export default DealsNav;
