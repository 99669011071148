import React from 'react';
import PropTypes from 'prop-types';
import FetchableSelect from '@/components/Inputs/FetchableSelect';
import { useLossReasons } from '@/api';

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func
};

const defaultProps = {
  name: 'loss_reason_id',
  label: '',
  placeholder: 'Motivo da perda',
  multiple: false,
  isClearable: true,
  isSearchable: false,
  defaultValue: null,
  onChange: () => {}
};

function LossReasonSelect(props) {
  return (
    <FetchableSelect
      { ...props }
      query={useLossReasons}
    />
  );
}

LossReasonSelect.propTypes = propTypes;
LossReasonSelect.defaultProps = defaultProps;

export default LossReasonSelect;
