import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from '@/components/Card';
import Icon from '@/components/Icon';
import Button from '@/components/Button';

function HowWorksStep({ nextStep, onHide }) {
  return (
    <Row>
      <div className='h3 fw-bold mt-6'>
        Como funciona para contratar um número
      </div>
      <Col sm={4} className='mt-4'>
        <Card className='p-4 border-lighter-gray min-height-100'>
          <Icon name='visit' className='text-primary' />
          <span className='fw-bold mt-2'>Você seleciona um DDD</span>
          <span className='text-dark-gray mt-2'>
            O nosso parceiro disponibiliza o DDD de algumas regiões do Brasil.
          </span>
        </Card>
      </Col>
      <Col sm={4} className='mt-4'>
        <Card className='p-4 border-lighter-gray min-height-100'>
          <Icon name='send' className='text-primary' />
          <span className='fw-bold mt-2'>
            Enviamos os seus dados para o nosso parceiro
          </span>
          <span className='text-dark-gray mt-2'>
            A Zenvia é nossa provedora de ligações e é ela que disponibiliza os
            números.
          </span>
        </Card>
      </Col>
      <Col sm={4} className='mt-4'>
        <Card className='p-4 border-lighter-gray min-height-100'>
          <Icon name='email' className='text-primary' />
          <span className='fw-bold mt-2'>
            Nosso parceiro entrará em contato com você em até 5 dias
          </span>
          <span className='text-dark-gray mt-2'>
            Eles enviarão um contrato para efetuar a venda do telefone.
          </span>
        </Card>
      </Col>
      <div className='d-flex justify-content-end mt-4'>
        <Button variant='outline-dark-gray' className='me-2' onClick={onHide}>
          Cancelar
        </Button>
        <Button variant='primary' className='ms-2' onClick={nextStep}>
          Ver informações da contratação
        </Button>
      </div>
    </Row>
  );
}

export default HowWorksStep;
