export function canCreateActivity({ user, _data }) {
  let effect, reason;
  const privilege = user.privileges.activities.create;

  if (!privilege) {
    effect = 'disable';
    reason = `Você não possui privilégio para criar um comentário ou tarefa.
      Contate o administrador de sua conta`;
  } else {
    effect = 'allow';
  }

  return { effect, reason };
}

export function canUpdateActivity({ user, _data }) {
  let effect, reason;
  const privilege = user.privileges.activities.update;

  if (!privilege) {
    effect = 'disable';
    reason = `Você não possui privilégio para editar um comentário ou tarefa.
      Contate o administrador de sua conta`;
  } else {
    effect = 'allow';
  }

  return { effect, reason };
}

export function canDeleteActivity({ user, _data }) {
  let effect, reason;
  const privilege = user.privileges.activities.delete;

  if (!privilege) {
    effect = 'disable';
    reason = `Você não possui privilégio para excluir um comentário ou tarefa.
      Contate o administrador de sua conta`;
  } else {
    effect = 'allow';
  }

  return { effect, reason };
}

export function canFinishActivity({ user, data: activity }) {
  let effect, reason;

  const isPerformedEmail = Boolean(activity?.email);

  const isAdmin = user?.admin;
  const isAssignedUser = activity?.assignedUsers?.some((item) => item.id === user.id);
  const isCreatedByUser = activity?.createdBy?.id === user?.id;
  const hasPrivilege = user?.privileges.activities.finishOther;

  if (isPerformedEmail) {
    effect = 'disable';
    reason = 'Não é possível alterar a finalização.';
  } else if (isAdmin || isAssignedUser || isCreatedByUser || hasPrivilege) {
    effect = 'allow';
  } else {
    effect = 'disable';
    reason =
      `Você não possui privilégio para finalizar essa tarefa.
      Contate o administrador de sua conta.`;
  }

  return { effect, reason };
}
