import React from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import SpreadsheetUploader from '@/components/SpreadsheetUploader';

const propTypes = {
  advanceStep: PropTypes.func,
  nextStepButtonRef: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
  onSuccess: PropTypes.func
};

const defaultProps = {
  advanceStep: () => {},
  onSuccess: () => {}
};

function UploadFileStep({ advanceStep, nextStepButtonRef, resource, file, onSuccess }) {
  const { user } = useAuth();
  const tracker = useTracking();

  const handleClick = () => {
    tracker.trackNewImportStarted({ user, entity: resource });
    return advanceStep();
  };

  return (
    <div className='mt-3'>
      <SpreadsheetUploader
        initialFile={file}
        onChange={onSuccess}
      />

      <input
        ref={nextStepButtonRef}
        type='button'
        className='hidden-button'
        onClick={handleClick}
      />
    </div>
  );
}

UploadFileStep.propTypes = propTypes;
UploadFileStep.defaultProps = defaultProps;

export default UploadFileStep;
