import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import classnames from 'classnames';

import emptyActivities from 'images/empty-activities.png';

import Activity from '@/components/EntityModal/Activities/Activity';
import LoadSpinner from '@/components/LoadSpinner';
import EmailHistoryModal from '@/components/Email/EmailHistoryModal';

History.propTypes = {
  entity: PropTypes.object,
  activities: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    createdBy: PropTypes.shape({
      name: PropTypes.string
    }),
    dueAt: PropTypes.string,
    createdAt: PropTypes.string,
    text: PropTypes.string,
    finished: PropTypes.bool,
    finishedAt: PropTypes.string,
    finishedBy: PropTypes.shape({
      name: PropTypes.string
    }),
    assignedUsers: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      active: PropTypes.bool,
      name: PropTypes.string,
      avatarUrl: PropTypes.string
    }))
  })),
  isFetchingActivities: PropTypes.bool,
  isFetchingNextPageActivities: PropTypes.bool,
  fetchNextPageActivities: PropTypes.func,
  hasNextPageActivities: PropTypes.bool,
  onDeleteActivity: PropTypes.func,
  onUpdateActivity: PropTypes.func,
  entityName: PropTypes.string
};

History.defaultProps = {
  entity: null,
  activities: [],
  isFetchingActivities: false,
  isFetchingNextPageActivities: false,
  fetchNextPageActivities: () => {},
  hasNextPageActivities: false,
  onDeleteActivity: () => {},
  onUpdateActivity: () => {},
  entityName: ''
};

const renderHistoryEmpty = () => (
  <div className='p-6 text-center'>
    <img src={emptyActivities} />
    <h4 className='mb-2'>Nenhuma atividade registrada</h4>
    <span>Que tal agendar uma ligação para evoluir este negócio?</span>
  </div>
);

function History({
  entity,
  activities,
  isFetchingActivities,
  isFetchingNextPageActivities,
  fetchNextPageActivities,
  hasNextPageActivities,
  onDeleteActivity,
  onUpdateActivity,
  entityName
}) {
  const isLoadingOrFetching = isFetchingActivities || isFetchingNextPageActivities;

  const [showEmailHistory, setShowEmailHistory] = useState(false);

  const showEmails = () => {
    setShowEmailHistory(true);
  };

  const hideEmails = () => {
    setShowEmailHistory(false);
  };

  if (!activities.length && !isLoadingOrFetching) {
    return renderHistoryEmpty();
  }

  return (
    <>
      <InfiniteScroll
        scrollableTarget='entity-modal'
        dataLength={activities?.length}
        next={fetchNextPageActivities}
        hasMore={hasNextPageActivities}
        scrollThreshold={0.95}
        className='overflow-hidden d-flex flex-column flex-grow-1 flexible-height'
        loader={
          <LoadSpinner size='md' className={classnames(
            'text-center',
            'mx-auto',
            'text-dark-gray',
            'opacity-100'
          )}/>
        }
      >
        {activities.map((activity) => (
          <div className='mb-3' key={activity.id}>
            <Activity
              entity={entity}
              activity={activity}
              onDelete={onDeleteActivity}
              onUpdate={onUpdateActivity}
              showEmailHistory={showEmails}
              entityName={entityName}/>
          </div>
        ))}
      </InfiniteScroll>
      { entityName === 'deal'
        ? (<EmailHistoryModal
          deal={entity}
          show={showEmailHistory}
          activities={activities}
          isFetchingActivities={isFetchingActivities}
          isFetchingNextPageActivities={isFetchingNextPageActivities}
          fetchNextPageActivities={fetchNextPageActivities}
          hasNextPageActivities={hasNextPageActivities}
          onDeleteActivity={onDeleteActivity}
          onHide={hideEmails}
        />)
        : null }
    </>
  );
}

export default History;
