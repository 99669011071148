import React from 'react';
import PropTypes from 'prop-types';
import Base from 'react-bootstrap/FormCheck';

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  className: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func
};

const defaultProps = {
  className: ''
};

const Switch = React.forwardRef(
  (props, ref) => {
    const { name, value, onChange, ...rest } = props;

    const handleChange = onChange
      ? (event) => onChange(event.target.checked)
      : null;
    const checked = value ? Boolean(value) : value;

    return (
      <Base
        type='switch'
        ref={ref}
        onChange={handleChange}
        value={value}
        checked={checked}
        name={name}
        id={name}
        reverse
        { ...rest }
      />
    );
  }
);

Switch.propTypes = propTypes;
Switch.defaultProps = defaultProps;
Switch.displayName = 'Switch';

export default Switch;
