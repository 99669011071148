import React from 'react';
import PropTypes from 'prop-types';

import capitalize from 'lodash/capitalize';

import { distanceFromNowInCalendarDays, pluralizeDaysRemainingText } from '@/date';
import { pluralize } from '@/utils';
import Card from '@/components/Card';

const propTypes = {
  planName: PropTypes.string.isRequired,
  signupIntention: PropTypes.string.isRequired,
  expirationDate: PropTypes.string.isRequired,
  maxUsersCount: PropTypes.number.isRequired
};

function CurrentPlanTrialCard(props) {
  const {
    planName,
    signupIntention,
    expirationDate,
    maxUsersCount
  } = props;

  const intention = signupIntention || 'gratuito';

  const isFreeSignupIntention = signupIntention === 'gratuito';
  const daysRemaining = distanceFromNowInCalendarDays(expirationDate);
  const pluralizedDaysRemainingText = pluralizeDaysRemainingText(daysRemaining);

  return (
    <Card className='h-100 px-4 py-3 border-light'>
      <Card.Header
        as='h5'
        className='text-dark-gray bg-transparent border-light mb-1 pb-2 px-0'
      >
        Plano Atual
      </Card.Header>

      <Card.Body className='d-flex align-items-center px-0'>
        <h4>
          {`${capitalize(intention)} `}
          {isFreeSignupIntention
            ? (
              <span className='fw-normal'>- por tempo ilimitado</span>
            )
            : (
              <span className='fw-normal'>
                - {maxUsersCount} {pluralize('usuário', maxUsersCount)}
              </span>
            )}
          <span className='mx-3'>
            {'•'}
          </span>
          <span className='fw-normal'>
            Teste grátis ativo do {' '}
          </span>
          Plano {`${planName} `}
          <span className='fw-normal'>
            - <span className='text-dashed'>
              {pluralizedDaysRemainingText}
            </span>
          </span>
        </h4>
      </Card.Body>
    </Card>
  );
}

CurrentPlanTrialCard.propTypes = propTypes;

export default CurrentPlanTrialCard;
