import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import FilterColumnButton from '@/components/List/FilterColumnButton';
import ListHeaderResizer from '@/components/List/ListHeaderResizer';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const propTypes = {
  table: PropTypes.object.isRequired,
  onReorder: PropTypes.func.isRequired,
  onResize: PropTypes.func
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function ListHeader({ table, onResize, onReorder }) {
  const headerGroup = table.getHeaderGroups();
  const headers = headerGroup[0].headers || [];
  const items = headers.map((header) => header.id);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    table.setColumnOrder((_currentOrder) => newItems);
    onReorder(newItems);
  };

  return (
    <thead className='bg-white text-uppercase sticky-top'>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable' direction='horizontal'>
          {(droppableProvided) => (
            <tr
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {
                headers.map((header, index) => {
                  const columnDef = header.column.columnDef;
                  const column = header.column;
                  const size = column.getSize();

                  return (
                    <th
                      key={header.id}
                      className={classnames(
                        'bg-white',
                        'p-0',
                        'align-middle',
                        { 'position-sticky': columnDef.fixed },
                        { 'z-index-2': columnDef.fixed },
                        { 'start-0': columnDef.fixed },
                        { 'ps-5': columnDef.fixed },
                        { 'position-relative': !columnDef.fixed }
                      )}
                      style={{ minWidth: size, width: size, maxWidth: size }}
                    >
                      {
                        header.id !== 'name'
                          ? <Draggable key={header.id} draggableId={header.id} index={index}>
                            {(draggableProvided, snapshot) => (
                              <>
                                <div
                                  className={classnames(
                                    'd-flex',
                                    'align-items-center',
                                    'justify-content-between',
                                    'p-3',
                                    { 'bg-light': snapshot.isDragging }
                                  )}
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                  {...draggableProvided.dragHandleProps}
                                  style={snapshot.isDragging
                                    ? draggableProvided.draggableProps.style
                                    : {}
                                  }
                                >
                                  <div className='d-flex align-items-center'>
                                    {columnDef.cell}
                                  </div>
                                  <div>
                                    <FilterColumnButton
                                      className='p-0 me-1'
                                      filter={columnDef.filter}
                                    />
                                  </div>
                                </div>
                                <ListHeaderResizer
                                  table={table}
                                  header={header}
                                  onResize={onResize}
                                />
                              </>
                            )}
                          </Draggable>
                          : <>
                            <ListHeaderResizer
                              table={table}
                              header={header}
                              onResize={onResize}
                            />
                            <div className='p-3 ms-4'>
                              {columnDef.cell}
                            </div>
                          </>
                      }
                    </th>
                  );
                })
              }
              <th className='min-width-4'></th>
              <th className='min-width-4'></th>
              {droppableProvided.placeholder}
            </tr>
          )}
        </Droppable>
      </DragDropContext>
    </thead>
  );
}

ListHeader.propTypes = propTypes;

export default ListHeader;
