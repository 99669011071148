import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@/components/Icon';

const propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.string
};

const defaultProps = {
  subtitle: null,
  description: null
};

function SearchSubSection({ children, title, icon, subtitle, description }) {
  return (
    <>
      <div className='d-flex align-items-center mb-4'>
        <div className='bg-lighter-gray rounded me-2'>
          <Icon name={icon} className='m-1 text-medium-gray' />
        </div>

        <h4>{title}</h4>
      </div>

      {description &&
        <div className='text-small text-dark-gray mb-2 ms-6 mt-n3'>{description}</div>
      }

      {subtitle && <div className='fw-bold text-small mb-2 ms-6'>{subtitle}</div>}

      <div className='ms-6'>
        {children}
      </div>
    </>
  );
}

SearchSubSection.propTypes = propTypes;
SearchSubSection.defaultProps = defaultProps;

export default SearchSubSection;
