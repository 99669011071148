import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@/components/Icon';
import IconLabel from '@/components/IconLabel';
import Button from '@/components/Button';
import Tooltip from '@/components/Tooltip';
import { useScreenSize } from '@/hooks';
import { pluralize } from '@/utils';
import { differenceInDays, startOfToday } from 'date-fns';
import { useDeleteDealFreezing } from '@/api';
import { formatDate, parseDate } from '@/date';


Freezing.defaultProps = {
  deal: {},
  loading: true,
  className: ''
};

Freezing.propTypes = {
  deal: PropTypes.object,
  loading: PropTypes.bool,
  className: PropTypes.string
};


function Freezing({ deal, loading, className }) {
  const unfreezeDeal = useDeleteDealFreezing();
  const { isMD } = useScreenSize();

  if (loading) {
    return null;
  }

  const isFreeze = deal?.freezing?.active;
  const dealFreezingReason = deal?.freezing?.freezingReason;
  const dealFrozenDescription = deal?.freezing?.description;
  const createdAt = formatDate(deal?.freezing?.createdAt);
  const unfreezeAt = parseDate(deal?.freezing?.unfreezeAt);
  const today = startOfToday();
  const diffInDays = differenceInDays(unfreezeAt, today);

  if (isFreeze && unfreezeAt) {
    return (
      <div className={className}>
        <div className='d-flex align-items-center bg-primary bg-opacity-10 px-4 py-3 rounded w-100'>
          {isMD ? null : <Icon name='frozen' className='me-3 text-primary' />}
          <div className='flex-fill'>
            <h5 className='text-primary text-medium'>
              Negócio congelado em {createdAt}. Motivo do congelamento:
              {' '}
              <Tooltip
                content={dealFrozenDescription}
                placement='bottom'
                hide={!dealFrozenDescription}
              >
                <span className='text-underline'>
                  {dealFreezingReason}
                </span>
              </Tooltip>
              .
            </h5>

            <div className='text-medium text-primary lh-1 mt-1'>
              Previsão para descongelar: {diffInDays} {pluralize('dia', diffInDays)}.
            </div>
          </div>

          <div className='d-flex justify-content-end'>
            <Button
              className='border-primary rounded fw-bold'
              variant='link'
              size='sm'
              onClick={() => unfreezeDeal.mutate({ deal_id: deal?.id })}
            >
              <IconLabel
                icon={{ name: 'unfrozen' }}
                text={`Descongelar${isMD ? '' : ' negócio'}`}
              />
            </Button>
          </div>
        </div>
      </div>
    );
  }
  return null;
}

export default Freezing;
