import React, { useState } from 'react';
import PropTypes from 'prop-types';
import linkify from 'linkify-string';
import classnames from 'classnames';

const propTypes = {
  text: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  maxRows: PropTypes.number,
  linkifyText: PropTypes.bool,
  className: PropTypes.string,
  anchorClassName: PropTypes.string,
  fullWidth: PropTypes.bool,
  ellipsis: PropTypes.string,
  showMoreLabel: PropTypes.string,
  showLessLabel: PropTypes.string,
  onClick: PropTypes.func
};

const defaultProps = {
  text: '',
  maxLength: 175,
  maxRows: 3,
  linkifyText: true,
  className: '',
  anchorClassName: '',
  ellipsis: '...',
  showMoreLabel: 'Ver mais',
  showLessLabel: 'Ver menos',
  onClick: () => {}
};

function TruncateLinkfied({
  text, maxLength, maxRows, linkifyText, className, anchorClassName,
  ellipsis, showMoreLabel, showLessLabel, onClick, fullWidth
}) {
  const [showMore, setShowMore] = useState(false);

  const truncate = (data = '') => (
    showMore
      ? data
      : `${data
        .split('\n')
        .slice(0, maxRows)
        .join('\n')
        .slice(0, maxLength)}${ellipsis}`
  );

  const breakLine = (data = '') => data
    .split('\n')
    .map((txt) => `${txt}<br />`)
    .join('');

  const injectLinks = (data = '') => (
    linkifyText ? linkify(data, { target: '_blank', className: 'word-wrap' }) : data
  );

  const toggleShowMore = (event) => {
    event?.preventDefault?.();
    setShowMore(!showMore);
    onClick?.();
  };

  if (text.length > maxLength) {
    const formattedText = breakLine(injectLinks(truncate(text)));
    return (
      <>
        <div
          className={className}
          dangerouslySetInnerHTML={{ __html: formattedText }}
        />
        <div
          className={classnames(
            { 'd-block btn-gradient text-center': fullWidth && !showMore },
            { 'd-block text-center my-3': fullWidth && showMore }
          )}
        >
          <a
            href='#'
            className={anchorClassName || 'fw-bold'}
            onClick={toggleShowMore}
          >
            {showMore ? showLessLabel : showMoreLabel}
          </a>
          {fullWidth &&
          <div>
            <hr className={classnames(
              'opacity-25',
              { 'my-2': fullWidth }
            )}/>
          </div>
          }
        </div>
      </>
    );
  }
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: breakLine(injectLinks(text)) }}
    />
  );
}

TruncateLinkfied.propTypes = propTypes;
TruncateLinkfied.defaultProps = defaultProps;

export default TruncateLinkfied;
