import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ConfirmationModal from '@/components/ConfirmationModal';
import HowItWorksContent from '@/components/LeadsGeneration/Presentation/HowItWorksContent';
import LeadsGenerationWrapper from '@/components/LeadsGeneration/LeadsGenerationWrapper';
import Modal from '@/components/Modal';
import ResumeContent from '@/components/LeadsGeneration/Presentation/ResumeContent';
import LeadsGenerationSuccess from '@/components/LeadsGeneration/LeadsGenerationSuccess';
import LeadsGenerationLeadsDone from '@/components/LeadsGeneration/LeadsGenerationLeadsDone';
import LeadsGenerationError from '@/components/LeadsGeneration/LeadsGenerationError';

const propTypes = {
  skipPresentation: PropTypes.bool,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onPresentationFinished: PropTypes.func,
  setShow: PropTypes.func
};

const defaultProps = {
  onHide: () => { }
};

function LeadsGenerationModal({ skipPresentation, show, onHide, onPresentationFinished, setShow }) {
  const [importId, setImportId] = useState(null);
  const [selectedLeadsCount, setSelectedLeadsCount] = useState(0);
  const [isListStep, setIsListStep] = useState(false);
  const [showPresentationStep, setShowPresentationStep] = useState('resumeContent');
  const [showConfirm, setShowConfirm] = useState(false);
  const [showPaymentProcess, setShowPaymentProcess] = useState(false);
  const [leadsPaymentProcess, setLeadsPaymentProcess] = useState(null);
  const [createdLeads, setCreatedLeads] = useState(null);

  const leadsGenerationWrapperRef = useRef(null);

  const handleOnConfirm = () => {
    leadsGenerationWrapperRef.current.callMixpanelEvent('trackLeadsGenerationDiscarded');
    setShowConfirm(false);
    setShow(false);
  };

  const handleOnStepChange = (isListStepParam) => {
    setIsListStep(isListStepParam);
  };

  const handleOnHide = () => {
    if (!skipPresentation) {
      setShow(false);
      setShowPresentationStep('resumeContent');
      return onHide();
    }

    setShowConfirm(true);
  };

  const handleHidePaymentProcess = () => {
    if (leadsPaymentProcess === 'error') {
      return setLeadsPaymentProcess(null);
    }

    setShow(false);
    setLeadsPaymentProcess(null);
  };

  const onFinishImport = (importData) => {
    setShow(false);
    setCreatedLeads(importData);
    setLeadsPaymentProcess('done');
  };

  useEffect(() => {
    setShowPaymentProcess(Boolean(leadsPaymentProcess));
    if (leadsPaymentProcess && leadsPaymentProcess !== 'error') {
      setShow(false);
    }
  }, [leadsPaymentProcess]);

  const FeedbackModalContent = () => {
    switch (leadsPaymentProcess) {
      case 'importing':
        return (
          <LeadsGenerationSuccess
            importId={importId}
            selectedLeadsCount={selectedLeadsCount}
            onFinishImport={onFinishImport}
            onClick={handleHidePaymentProcess}
          />
        );
      case 'done':
        return (
          <LeadsGenerationLeadsDone
            createdLeads={createdLeads}
            onClickFinish={handleHidePaymentProcess}
          />
        );
      case 'error':
        return (
          <LeadsGenerationError closeModal={handleHidePaymentProcess} />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Modal
        bodyClassName={classnames(
          'p-8',
          'leads-generation-modal-height',
          { 'px-8 py-6 bg-light bg-opacity-25': skipPresentation },
          { 'bg-white': isListStep }
        )}
        size='xxl'
        backdrop='static'
        show={show}
        onHide={handleOnHide}
      >
        {skipPresentation
          ? (
            <LeadsGenerationWrapper
              setLeadsPaymentProcess={setLeadsPaymentProcess}
              setSelectedLeadsCount={setSelectedLeadsCount}
              selectedLeadsCount={selectedLeadsCount}
              setImportId={setImportId}
              onStepChange={handleOnStepChange}
              ref={leadsGenerationWrapperRef}
              onClose={onHide}
            />
          )
          : <>
            {showPresentationStep === 'resumeContent' && (
              <ResumeContent
                setShowPresentationStep={setShowPresentationStep}
              />
            )}

            {showPresentationStep === 'howItWorksContent' && (
              <HowItWorksContent
                onPresentationFinished={onPresentationFinished}
              />
            )}
          </>
        }

        <ConfirmationModal
          confirmationText='Sim, sair'
          hideText='Não quero'
          show={showConfirm}
          onConfirm={handleOnConfirm}
          onHide={() => setShowConfirm(false)}
        >
          <ConfirmationModal.Title>
              Deseja realmente sair?
          </ConfirmationModal.Title>

          <ConfirmationModal.Info className='mb-6'>
              Após a confirmação, você perderá todas as alterações feitas.
          </ConfirmationModal.Info>
        </ConfirmationModal>
      </Modal>

      <Modal
        show={showPaymentProcess}
        onHide={handleHidePaymentProcess}
        backdrop='static'
        bodyClassName='p-8 text-center z-index-10090'
      >
        <FeedbackModalContent />
      </Modal>
    </>
  );
}

LeadsGenerationModal.propTypes = propTypes;
LeadsGenerationModal.defaultProps = defaultProps;

export default LeadsGenerationModal;
