import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import { useSearchParams } from 'react-router-dom';
import {
  TextInput,
  UserSelect,
  TeamSelect,
  ProductSelect,
  StateSelect,
  CitySelect,
  ForgottenSelect,
  LeadOriginSelect,
  AreaCodeSelect,
  SectorSelect,
  DateRangeInput,
  DealSortInput
} from '@/components/Inputs';
import DependentFormField from '@/components/DependentFormField';
import Icon from '@/components/Icon';
import InputAdornment from '@/components/InputAdornment';
import FormField from '@/components/FormField';
import FieldsPopover from '@/components/FieldsPopover';
import StoredForm from '@/components/StoredForm';
import { DATE_RANGE_DEFAULT_PRESETS } from '@/date-range';
import { useDealsCustomFields } from '@/api';
import LoadingWrapper from '@/components/LoadingWrapper';
import CustomFieldsFilters from '@/components/Filters/CustomFieldsFilters';

import { dealQueryStringKeys } from '@/components/Columns';
import { transformQueryStringToFilter } from '@/components/Columns/Utils';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string
};

const defaultProps = {
  className: ''
};

const popoverValues = {
  owner_user_id_in: null,
  team_id_in: null,
  products_id_in: null,
  state_in: null,
  city_id_in: null,
  forgotten: null,
  lead_origin_id_in: null,
  area_code_in: null,
  sector_id_in: null,
  started_at_local_date_within: null,
  finished_at_local_date_within: null,
  custom_fields: {}
};

const defaultValues = { q: '', sorts: 'updated_at desc', ...popoverValues };

function FunnelForm({ onSubmit, name, className }) {
  const { user } = useAuth();
  const tracker = useTracking();
  const {
    data: {
      data = []
    } = {},
    isSuccess
  } = useDealsCustomFields();

  const [searchParams] = useSearchParams();
  const queryParams = [...searchParams];

  const queryStringValues = useMemo(
    () => getFilterFromQueryString(queryParams),
    []
  );

  const onFilter = (values) => {
    tracker.trackFunnelFilterUsed({ user, params: values });
  };

  return (
    <StoredForm
      className={classnames('d-flex py-3 align-items-start', className)}
      onSubmit={onSubmit}
      name={name}
      defaultValues={defaultValues}
      initialValues={queryStringValues}
      submitOnDefaultChange
    >
      {
        ({ submitForm, debouncedSubmitForm }) => (
          <React.Fragment>
            <FormField
              as={TextInput}
              onChange={debouncedSubmitForm}
              name='q'
              className='mb-0 ms-3 me-1'
              placeholder='Buscar no funil'
              variant='white'
              isClearable
              autoComplete='off'
              leftAdornment={({ focus }) => (
                <InputAdornment alignment='left' onClick={focus}>
                  <Icon className='text-light-gray' name='search' />
                </InputAdornment>
              )}
            />

            <FieldsPopover
              className='width-6 funnel-filter'
              variant='white'
              label='Mostrar filtros do funil'
              defaultValues={popoverValues}
              onFilter={onFilter}
            >

              <FormField
                as={UserSelect}
                name='owner_user_id_in'
                label='Responsável'
                multiple
                allowNull
              />

              <FormField
                as={TeamSelect}
                name='team_id_in'
                multiple
                isSearchable
                allowNull
              />

              <FormField
                as={ProductSelect}
                name='products_id_in'
                multiple
                isSearchable
                allowNull
                queryArgs={{ params: { per_page: 600 } }}
              />

              <FormField
                as={StateSelect}
                name='state_in'
                multiple
                isSearchable
                allowNull
              />

              <DependentFormField
                as={CitySelect}
                name='city_id_in'
                source='state_in'
                target='state'
                multiple
                isSearchable
                allowNull
              />

              <FormField
                as={ForgottenSelect}
                name='forgotten'
              />

              <FormField
                as={LeadOriginSelect}
                name='lead_origin_id_in'
                multiple
                isSearchable
                allowNull
              />

              <FormField
                as={AreaCodeSelect}
                name='area_code_in'
                multiple
                isSearchable
                allowNull
              />

              <FormField
                as={SectorSelect}
                name='sector_id_in'
                multiple
                isSearchable
                allowNull
              />

              <FormField
                as={DateRangeInput}
                label='Data de início'
                name='started_at_local_date_within'
                presets={DATE_RANGE_DEFAULT_PRESETS}
              />

              <FormField
                as={DateRangeInput}
                label='Data de conclusão'
                name='finished_at_local_date_within'
                presets={DATE_RANGE_DEFAULT_PRESETS}
              />

              <LoadingWrapper isLoading={!isSuccess}>
                <CustomFieldsFilters fields={data} />
              </LoadingWrapper>
            </FieldsPopover>

            <FormField
              as={DealSortInput}
              name='sorts'
              onChange={() => submitForm()}
              innerClassName='mx-3'
            />
          </React.Fragment>
        )
      }
    </StoredForm>
  );
}

function getFilterFromQueryString(queryParams = []) {
  const queryStringKeys = { ...dealQueryStringKeys };
  /*
   * Quando se trata de aplicação de filtros de negócios esquecidos ou
   * atrasados no funil, não temos suporte para receber a filterKey da
   * queryString, mas sim, receber o seu id que é 'forgotten'.
   */
  queryStringKeys.forgotten.filterKey = 'forgotten';

  return transformQueryStringToFilter(queryParams, queryStringKeys);
}

FunnelForm.propTypes = propTypes;
FunnelForm.defaultProps = defaultProps;

export default FunnelForm;
