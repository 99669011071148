import { useQuery } from 'react-query';
import { get } from '@/lib/fetch';

export const sectorsKeys = {
  all: ['sectors'],
  lists: () => [...sectorsKeys.all, 'list'],
  list: (params) => [...sectorsKeys.lists(), params]
};

export async function getSectors(params = {}) {
  const { data } = await get('/sectors', params);

  return data;
}

export function useSectors({ params = {}, config = {} }) {
  return useQuery({
    ...config,
    queryKey: sectorsKeys.list(params),
    queryFn: () => getSectors(params)
  });
}
