import React, { useMemo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Tooltip from '@/components/Tooltip';
import StoredForm from '@/components/StoredForm';
import ColumnsPopover from '@/components/ColumnsPopover';
import isEmpty from 'lodash/isEmpty';
import {
  organizationColumnsDefaultValues, organizationColumnsFilters,
  personColumnsDefaultValues, personColumnsFilters,
  dealColumnsDefaultValues, dealColumnsFilters,
  customFieldColumnsDefaultValues, customFieldColumnsFilters
} from '@/components/Columns';
import { getColumnsFromQueryString } from '@/components/Columns/Utils';
import { useSearchParams } from 'react-router-dom';

const propTypes = {
  type: PropTypes.oneOf(['organization', 'person', 'deal']).isRequired,
  onSubmit: PropTypes.func,
  className: PropTypes.string,
  buttonRef: PropTypes.any,
  isLoading: PropTypes.bool,
  customFields: PropTypes.array
};

const defaultProps = {
  onSubmit: () => {},
  className: '',
  buttonRef: null,
  isLoading: false,
  customFields: []
};

function ColumnsForm({ type, onSubmit, className, buttonRef, isLoading, customFields }) {
  const renderMainButton = () => (
    <Button
      ref={buttonRef}
      className={classnames('px-2', 'text-nowrap', { [className]: isLoading })}
      variant='transparent-light'
    >
      <Icon className='me-1' name='edit' />
      <span className='d-none d-md-inline'>
        Colunas
      </span>
    </Button>
  );

  const [searchParams] = useSearchParams();

  const queryParams = [...searchParams];

  const [entityColumnsFilters, entityDefaultValues] = getColumnsFilters(type);
  const queryParamsColumns = useMemo(() => getColumnsFromQueryString(type, queryParams), [type]);
  /*
   * Como customFields é um array, foi necessario stringify...
   * para evitar bug do formik de render infinito
   */
  const memoKey = JSON.stringify({ customFields, type, queryParamsColumns });

  const defaultValues = useMemo(
    () => ({
      ...customFieldColumnsDefaultValues(customFields),
      ...entityDefaultValues,
      ...queryParamsColumns
    }),
    [memoKey]
  );

  const columnsFilters = useMemo(
    () => entityColumnsFilters.concat(customFieldColumnsFilters(customFields)),
    [memoKey]
  );

  // No loading renderiza apenas button, para evitar inconsistencia de estado com formik
  if (isLoading) {
    return renderMainButton();
  }

  return (
    <StoredForm
      className={classnames('d-flex', className)}
      defaultValues={defaultValues}
      initialValues={!isEmpty(queryParamsColumns) && defaultValues}
      onSubmit={onSubmit}
      name={`${type}-column-filter-form`}
    >
      {() => (
        <ColumnsPopover
          columnsFilters={columnsFilters}
          defaultValues={defaultValues}
        >
          <div>
            <Tooltip content='Exibir/esconder colunas da tabela'>
              {renderMainButton()}
            </Tooltip>
          </div>
        </ColumnsPopover>
      )}
    </StoredForm>
  );
}

function getColumnsFilters(type) {
  switch (type) {
    case 'deal':
      return [dealColumnsFilters, dealColumnsDefaultValues];
    case 'organization':
      return [organizationColumnsFilters, organizationColumnsDefaultValues];
    case 'person':
      return [personColumnsFilters, personColumnsDefaultValues];
    default:
      throw new Error(`Invalid Column type ${type}`);
  }
}

ColumnsForm.propTypes = propTypes;
ColumnsForm.defaultProps = defaultProps;

export default ColumnsForm;
