import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { IMaskMixin as mixin } from 'react-imask';
import TextInput from '@/components/Inputs/TextInput';
import { MASK_CONFIGS } from '@/masks';

const propTypes = {
  maskType: PropTypes.oneOf(Object.keys(MASK_CONFIGS))
};

const Base = mixin(({ inputRef, ...props }) => <TextInput ref={inputRef} { ...props } />);

function MaskedInput(props) {
  const { maskType, onChange, inputRef, ...rest } = props;

  const handleChange = (newValue) => onChange?.(newValue);

  const maskConfig = useMemo(() => MASK_CONFIGS[maskType] || {}, [maskType]);

  const setInputRef = useCallback((element) => {
    if (!inputRef) {
      return;
    }

    if (typeof inputRef === 'function') {
      inputRef(element);
    } else {
      inputRef.current = element;
    }
  }, [inputRef]);

  return (
    <Base
      unmask={true}
      { ...maskConfig }
      { ...rest }
      onAccept={handleChange}
      onComplete={handleChange}
      inputRef={setInputRef}
      autoComplete='off'
    />
  );
}

MaskedInput.propTypes = propTypes;

export default MaskedInput;
