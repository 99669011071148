import React from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classnames from 'classnames';
import APIErrorMessage from '@/components/APIErrorMessage';
import FunnelsNew from '@/pages/funnels/_new';
import { useUpdateFunnel } from '@/api';
import EditFunnelForm from '@/components/EditFunnelForm';
import { getValidationErrors } from '@/utils';
import FunnelsDelete from '@/pages/funnels/_delete';

const propTypes = {
  funnels: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    initials: PropTypes.string.isRequired,
    order: PropTypes.number
  })).isRequired
};

const defaultProps = {
};

function FunnelsList({ funnels }) {
  const mutation = useUpdateFunnel();

  const alert = useAlert();

  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    if (!destination || source.index === destination.index) {
      return;
    }

    mutation.mutate({
      funnelId: draggableId,
      params: { order: destination.index + 1 }
    }, {
      onSuccess: () => {
        alert.show(
          'Funil editado com sucesso.',
          { variant: 'success', timeout: 5000 }
        );
      },
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='funnel' action='update' />,
          { variant: 'danger' }
        );
      }
    });
  };

  const onEdit = (formData, { setFieldError }) => {
    mutation.mutate({
      funnelId: formData.id,
      params: formData
    }, {
      onSuccess: () => {
        alert.show(
          'Funil editado com sucesso.',
          { variant: 'success', timeout: 5000 }
        );
      },
      onError: (err) => {
        const errors = getValidationErrors(err, 'funnel');

        Object.entries(errors).forEach(([key, message]) => {
          setFieldError(key, message);
        });

        alert.show(
          <APIErrorMessage err={err} resource='funnel' action='update' />,
          { variant: 'danger' }
        );
      }
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className='funnels-list'>
        <div className={classnames(
          'd-flex',
          'align-items-center',
          'border-1',
          'border-bottom',
          'border-darker-gray',
          'text-uppercase',
          'fw-bold'
        )}>
          <div className='width-3 px-6 py-3 ms-2'></div>
          <div className='width-5 px-6 py-3'>Nome</div>
          <div className='width-4 px-6 py-3'>Sigla</div>
          <div></div>
          <div></div>
        </div>

        <Droppable
          droppableId='funnels-list'
          type='row'
        >
          {(droppableProvided, droppableSnapshot) => (
            <div
              className={classnames(
                { 'pe-none': droppableSnapshot.isDraggingOver }
              )}
              ref={droppableProvided.innerRef}
              { ...droppableProvided.droppableProps }
            >
              {funnels.map((item, index) => (
                <Draggable
                  draggableId={String(item.id)}
                  index={index}
                  key={item.id}
                  isDragDisabled={item.isDragDisabled}
                >
                  {(draggableProvided, draggableSnapshot) => (
                    <EditFunnelForm
                      className='funnels-item'
                      funnel={item}
                      onSubmit={onEdit}
                      funnelDelete={<FunnelsDelete funnel={item} />}
                      innerRef={draggableProvided.innerRef}
                      innerProps={draggableProvided.draggableProps}
                      dragProps={draggableProvided.dragHandleProps}
                      isDragging={draggableSnapshot.isDragging}
                    />
                  )}
                </Draggable>
              ))}

              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </div>

      <FunnelsNew totalCount={funnels.length} />
    </DragDropContext>
  );
}

FunnelsList.propTypes = propTypes;
FunnelsList.defaultProps = defaultProps;

export default FunnelsList;

