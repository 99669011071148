import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as Yup from 'yup';
import FormField from '@/components/FormField';
import Form from '@/components/Form';
import DependentFormField from '@/components/DependentFormField';
import {
  TextInput,
  ProductsFields,
  UserSelect,
  DatePickerInput,
  TextArea,
  ValueInput
} from '@/components/Inputs';
import Button from '@/components/Button';
import LoadingButton from '@/components/LoadingButton';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import { fromDMA } from '@/date';
import {
  customFieldsSchema,
  CustomFieldsSection,
  getInitialValues as getCustomFieldsInitialValues
} from '@/components/CustomFields';
import {
  getInitialValues as getProductsInitialValues,
  schema as productsSchema
} from '@/components/Inputs/ProductsFields';

const propTypes = {
  /** Dados para pré-preenchimento do formulário. */
  initialData: PropTypes.shape({
    /** Título do negócio. */
    name: PropTypes.string,
    /** Valor do negócio em reais. */
    value: PropTypes.number,
    /** Produtos incluídos no negócio. */
    entity_products: PropTypes.arrayOf(PropTypes.object),
    /** Descrição do negócio. */
    description: PropTypes.string
  }),
  /** Campos customizados de negócio. */
  customFields: PropTypes.array,
  /** Callback chamado quando o formulário é enviado. */
  onSubmit: PropTypes.func,
  /** Callback chamado no clique do botão 'Cancelar'. */
  onCancel: PropTypes.func
};

const defaultProps = {
  initialData: {
    entityProducts: []
  },
  customFields: [],
  onSubmit: () => {}
};

/* eslint-disable no-magic-numbers */
const schema = Yup.object().shape({
  title: Yup.string()
    .max(150, 'Título do negócio deve ter no máximo 150 caracteres.')
    .required('Por favor, informe o título do negócio.'),
  value: Yup.number()
    .nullable(true)
    .typeError('Valor do negócio deve ser um número.')
    .min(0, 'Valor do negócio não pode ser negativo.'),
  description: Yup.string()
    .nullable(true)
    .max(2000, 'Descrição do negócio deve ter no máximo 2000 caracteres.'),
  finished_at: Yup.string()
    .nullable(true)
    .test(
      'isValidDate',
      'Data inválida',
      (value) => !value || Boolean(fromDMA(value))
    ),
  custom_fields: customFieldsSchema,
  entity_products: productsSchema
});
/* eslint-enable no-magic-numbers */

const defaultValues = {
  title: '',
  value: '',
  entity_products: [],
  owner_user_id: '',
  description: '',
  custom_fields: {}
};

function DealForm(props) {
  const { initialData, onSubmit, onCancel, customFields, entity } = props;

  const { user } = useAuth();
  const tracker = useTracking();
  const initialValues = useMemo(() => {
    const {
      name: title,
      value,
      entityProducts,
      description,
      customFields: customFieldsValues
    } = initialData;

    return {
      title,
      value: value ? value.toString() : '',
      description: description ?? '',
      entity_products: getProductsInitialValues(entityProducts),
      owner_user_id: user.id,
      custom_fields: getCustomFieldsInitialValues(customFieldsValues)
    };
  }, [initialData, user]);

  const trackDealFromBacklog = (...args) => {
    tracker.trackCardDraggedFromBacklog({ entity, user });
    onSubmit(...args);
  };

  return (
    <Form
      name='create-deal-form'
      defaultValues={defaultValues}
      initialValues={initialValues}
      onSubmit={trackDealFromBacklog}
      validationSchema={schema}
      validationContext={{ customFields }}
    >
      {({ handleBlur, handleSubmit, isSubmitting }) => (
        <div>
          <Row className='gx-3'>
            <Col sm={8}>
              <FormField
                as={TextInput}
                name='title'
                label='Título do negócio'
                autoComplete='off'
                placeholder=''
                maxLength={150}
                variant='light'
                onBlur={handleBlur}
                data-autofocus='true'
                autoFocus
              />
            </Col>

            <Col sm={4}>
              <DependentFormField
                as={ValueInput}
                name='value'
                label='Valor estimado'
                onBlur={handleBlur}
                source='calculated_value'
                target='calculatedValue'
              />
            </Col>
          </Row>

          <Row className='gx-3'>
            <Col sm={6}>
              <FormField
                as={DatePickerInput}
                name='finished_at'
                label='Data de conclusão'
                placeholder='Inserir data'
                variant='light'
                onBlur={handleBlur}
              />
            </Col>

            <Col sm={6}>
              <FormField
                as={UserSelect}
                name='owner_user_id'
                label='Responsável'
                isSearchable
              />
            </Col>
          </Row>

          <FormField
            as={TextArea}
            name='description'
            label='Descrição'
            autoComplete='off'
            variant='light'
            onBlur={handleBlur}
            maxLength={2000}
            placeholder='Descreva detalhes importantes sobre essa negociação'
          />

          <ProductsFields size='sm' />

          <CustomFieldsSection
            perRow
            section='basic_data'
            fields={customFields}
            className='gx-3'
          />

          <Row className='mt-4'>
            <Col sm={6}>
              <Button
                className='w-100'
                variant='outline-dark-gray'
                onClick={onCancel}
                disabled={isSubmitting}
              >
                Cancelar
              </Button>
            </Col>

            <Col sm={6}>
              <LoadingButton
                isLoading={isSubmitting}
                className='w-100'
                variant='primary'
                onClick={handleSubmit}
              />
            </Col>
          </Row>
        </div>
      )}
    </Form>
  );
}

DealForm.propTypes = propTypes;
DealForm.defaultProps = defaultProps;

export default DealForm;
