import { useQuery } from 'react-query';
import { get } from '@/lib/fetch';

export const activityEmailsKeys = {
  details: () => ['details'],
  detail: (activityId) => [...activityEmailsKeys.details(), activityId]
};

export async function getActivityEmail(activityId) {
  const { data } = await get(`/activities/${activityId}/email`);

  return data;
}

export function useActivityEmail({ activityId, config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: activityEmailsKeys.detail(activityId),
    queryFn: () => getActivityEmail(activityId)
  });
}
