import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import BankSlip from '@/components/Billing/CardCheckoutDetails/BankSlip';
import { CreditCard } from '@/components/Billing/CardCheckoutDetails/CreditCard';
import Card from '@/components/Card';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import Pix from '@/components/Billing/CardCheckoutDetails/Pix';
import { RadioButton } from '@/components/Inputs';
import Tooltip from '@/components/Tooltip';
import StripeCreditCardForm from '@/components/Billing/StripeComponents/StripeCreditCardForm';

const RADIO_OPTIONS = [
  {
    label: 'Cartão de crédito',
    value: 'credit_card'
  },
  {
    label: 'Boleto',
    value: 'bank_slip'
  }
];

const propTypes = {
  className: PropTypes.string,
  handleChange: PropTypes.func,
  initialValues: PropTypes.object,
  isRadioDisabled: PropTypes.func,
  disabledToolTipContent: PropTypes.string,
  accountData: PropTypes.object,
  setEncryptedData: PropTypes.func,
  setPaymentData: PropTypes.func
};

const defaultValues = {
  type: 'credit_card'
};

function CardCheckoutDetails(props) {
  const {
    className,
    handleChange,
    initialValues,
    isRadioDisabled,
    disabledToolTipContent,
    accountData,
    radioOptions,
    setEncryptedData,
    setPaymentData,
    isStripeComponent
  } = props;

  const paymentOptions = radioOptions ? radioOptions : RADIO_OPTIONS;

  const handleCreditCardComponentRender = () => {
    if (isStripeComponent) {
      return (
        <StripeCreditCardForm
          setPaymentData={setPaymentData}
        />
      );
    }
    return (
      <CreditCard
        setEncryptedData={setEncryptedData}
        setPaymentData={setPaymentData}
      />
    );
  };

  return (
    <Form
      name='form'
      className={classnames('w-100', className)}
      defaultValues={defaultValues}
      initialValues={initialValues}
    >
      {({ values }) => {
        const { type: paymentMethod } = values;

        return (
          <Card className='p-3 border-light'>
            <div>
              <h4 className='mb-3 text-dark-gray'>Detalhes do pagamento</h4>

              <div className='d-flex'>
                {
                  paymentOptions.map(({ label, value }, index) => (
                    <div key={index}>
                      <Tooltip hide={!isRadioDisabled(value)} content={disabledToolTipContent}>
                        <div>
                          <FormField
                            as={RadioButton}
                            disabled={isRadioDisabled(value)}
                            onChange={handleChange}
                            noFlex
                            id={value}
                            className='me-4 fw-bold text-dark-gray text-small'
                            radioValue={value}
                            name='type'
                            label={label}
                          />
                        </div>
                      </Tooltip>
                    </div>
                  ))
                }
              </div>

              {paymentMethod === 'credit_card' && handleCreditCardComponentRender()}
              {paymentMethod === 'bank_slip' && (
                <BankSlip
                  bankSlipName={accountData.legalName}
                  bankSlipCpfCnpj={accountData.cpfCnpj}
                  isStripeComponent
                  setPaymentData={setPaymentData}
                />
              )}
              {paymentMethod === 'pix' && <Pix accountData={accountData} />}
            </div>
          </Card>
        );
      }}
    </Form>
  );
}

CardCheckoutDetails.displayName = 'CardCheckoutDetails';
CardCheckoutDetails.propTypes = propTypes;

export default CardCheckoutDetails;
