import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '@/components/Icon';
import Card from '@/components/Card';
import Button from '@/components/Button';
import { toCurrencyString } from '@/number';

const propTypes = {
  balance: PropTypes.number,
  approxMinutes: PropTypes.number,
  onReload: PropTypes.func,
  isLoading: PropTypes.bool,
  pricePerMinute: PropTypes.number
};

const defaultProps = {
  onReload: () => {},
  balance: 0,
  approxMinutes: 0,
  isLoading: true,
  pricePerMinute: 0
};


function warningBalance(balance, approxMinutes, pricePerMinute) {
  if (balance >= pricePerMinute) {
    return `Você tem aproximadamente ${approxMinutes} min de créditos para chamadas.`;
  }
  if (balance < 0) {
    return 'Adicione créditos para cobrir o saldo negativo.';
  }
  return 'Adicione créditos para fazer ligações.';
}

function CurrentBalance({
  balance, approxMinutes,
  onReload, isLoading, pricePerMinute
}) {
  return (
    <Card className='border-lighter-gray height-5 mb-2'>
      <div className={
        `d-flex
        justify-content-between
        text-dark-gray max-width-7
        py-3 px-4`
      }>
        <span>Saldo atual</span>
        <Button
          onClick={onReload}
          className='p-0'
          variant='white'
        >
          <Icon
            name='loading'
            className={classnames(
              'cursor-pointer text-dark-gray',
              { 'animation-rotate': isLoading }
            )}
          />
        </Button>
      </div>

      <hr className='mx-4' />

      <div className='p-3 mt-6'>
        <p className={classnames(
          'text-center display-5 fw-bold',
          { 'text-danger': balance < 0 }
        )}>
          {isLoading ? 'R$ ---' : toCurrencyString(balance)}
        </p>
        <div className='text-center text-dark-gray mt-4 mb-4'>
          {warningBalance(balance, approxMinutes, pricePerMinute)}
        </div>
      </div>
    </Card>
  );
}

CurrentBalance.propTypes = propTypes;
CurrentBalance.defaultProps = defaultProps;

export default CurrentBalance;
