import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Badge from '@/components/Badge';
import Icon from '@/components/Icon';
import Skeleton from '@/components/Skeleton';
import SkeletonWrapper from '@/components/SkeletonWrapper';
import Truncate from '@/components/Truncate';
import { toCurrencyString, calculatePercentage } from '@/number';
import { pluralize } from '@/utils';
import Tooltip from '@/components/Tooltip';
import FunnelGift from '@/feature/growth/FunnelGift';

const propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  totalAmount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  frozenAmount: PropTypes.number.isRequired,
  frozenCount: PropTypes.number.isRequired,
  showFrozen: PropTypes.bool,
  isDefault: PropTypes.bool
};

const defaultProps = {
  className: '',
  isDefault: false
};

function TitleSkeleton() {
  return (
    <h2 className='width-5'>
      <Skeleton />
    </h2>
  );
}

function CountSkeleton() {
  return (
    <div className='width-5 ms-5'>
      <h4 className='width-5 mb-0'>
        <Skeleton />
      </h4>
    </div>
  );
}

function getDealTooltipInfo(totalAmount, frozenAmount) {
  return `
    Do total de ${toCurrencyString(totalAmount)},
    ${calculatePercentage(frozenAmount, totalAmount, true)}%
    desse valor (${toCurrencyString(frozenAmount)})
    está em negócios congelados.
  `;
}

function FunnelInfo(props) {
  const {
    className, name, totalAmount, totalCount, isLoading, isDefault,
    frozenAmount, frozenCount, showFrozen
  } = props;

  return (
    <>
      <div className={classnames(
        className,
        'funnel-info',
        'd-flex',
        'align-items-center'
      )}>
        <SkeletonWrapper isLoading={isLoading} SkeletonComponent={TitleSkeleton}>
          <div className='d-flex align-items-center gap-2 me-5'>
            <Truncate as='h2' className='text-primary mb-0'>
              {name}
            </Truncate>

            <FunnelGift isDefault={isDefault} />
          </div>
        </SkeletonWrapper>

        <SkeletonWrapper isLoading={isLoading} SkeletonComponent={CountSkeleton}>
          <div className='d-flex align-items-center me-2'>
            <Icon name='deal' size='sm' className='me-2'/>
            <Truncate as='h4' className='mb-0'>
              {toCurrencyString(totalAmount)}
            </Truncate>
          </div>

          <Badge variant='dark-gray'>
            <Truncate className={classnames(
              'text-small',
              'funnel-info-total-count'
            )}>
              {`${totalCount} ${pluralize('negócio', totalCount)}`}
            </Truncate>
          </Badge>
        </SkeletonWrapper>

        { showFrozen &&
          <SkeletonWrapper isLoading={isLoading} SkeletonComponent={CountSkeleton}>
            <div className='ms-6 me-2 text-primary d-flex align-items-center'>
              <Icon name='frozen' size='sm' className='me-2'/>
              <Truncate as='h4' className='mb-0'>
                {toCurrencyString(frozenAmount)}
              </Truncate>
            </div>

            <Badge>
              <Tooltip
                content={getDealTooltipInfo(totalAmount, frozenAmount)}
                className='text-small funnel-info-total-count'>
                <div>
                  {`${frozenCount} ${pluralize('negócio', frozenCount)}`}
                </div>
              </Tooltip>
            </Badge>
          </SkeletonWrapper>
        }
      </div>
    </>
  );
}

FunnelInfo.propTypes = propTypes;
FunnelInfo.defaultProps = defaultProps;

export default FunnelInfo;
