import React from 'react';
import Icon from '@/components/Icon';
import { RadioButton } from '@/components/Inputs';


function OpenLabel() {
  return (
    <h5 className='ms-3'>
      <div className='d-flex align-items-center'>
        <Icon name='person' size='sm' className='me-2' />
        <div>Todos</div>
      </div>

      <div className='text-muted fw-normal mt-1'>
        Todos os usuários da conta terão acesso.
      </div>
    </h5>
  );
}

function ClosedLabel() {
  return (
    <h5 className='ms-3'>
      <div className='d-flex align-items-center'>
        <Icon name='lock' size='sm' className='me-2' />
        <div>Acesso restrito</div>
      </div>

      <div className='text-muted fw-normal mt-1'>
        <div>
          O responsável, seus líderes e os administradores da conta sempre terão acesso.
        </div>

        <div>
          Você também pode conceder acesso a outros usuários.
        </div>
      </div>
    </h5>
  );
}


export function PrivacyFieldPreview() {
  return (
    <fieldset>
      <div className='d-flex align-items-center mb-3'>
        <RadioButton
          id=''
          radioValue='open'
          name=''
          disabled
        />
        <OpenLabel />
      </div>

      <hr />

      <div>
        <div className='d-flex align-items-center mt-3'>
          <RadioButton
            id=''
            radioValue='closed'
            name=''
            disabled
          />
          <ClosedLabel />
        </div>
      </div>
    </fieldset>
  );
}

