import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import classnames from 'classnames';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Modal from '@/components/Modal';
import Badge from '@/components/Badge';
import ExternalLink from '@/components/ExternalLink';
import ChromeLogo from 'images/chrome-logo.png';
import AgendorChatLogo from 'images/agendor-chat-logo.png';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';

const IntegrationOption = ({
  logo, title, features,
  link, buttonText, badge, classes, onClick
}) => (
  <Col sm={6} className={classes?.colClassName}>
    <div
      className='bg-dark-light rounded d-flex flex-column justify-content-between'
      style={{ height: 410 }}
    >
      <div>
        <div className={classnames(
          'd-flex ms-6 align-items-center',
          classes?.imageClassName
        )}>
          <img src={logo} alt={`${title} Logo`} />
          {
            badge
              ? (
                <Badge variant={badge.variant} className='p-2 ms-9 text-small'>
                  {badge?.text}
                </Badge>
              )
              : null
          }
          {
            title
              ? <h2 className='p-2 mt-2 text-dark-gray'>{title}</h2>
              : null
          }
        </div>
        {
          features.map((featureText, index) => (
            <div key={index} className='d-flex text-dark-gray mt-3 ms-6'>
              <Icon name='check' className={classes?.bulletClassName} />
              <div dangerouslySetInnerHTML={{ __html: featureText }} />
            </div>
          ))
        }
      </div>
      <div>
        <Button
          variant={link.variant}
          className={classes?.buttonClassName}
          as={ExternalLink}
          target='_blank'
          href={link.href}
          onClick={onClick}
        >
          {buttonText}
          <Icon name='full-arrow-right' />
        </Button>
      </div>
    </div>
  </Col>
);

const EXTENSION_FEATURES = [
  'Gratuita',
  'Conecta com WhatsApp Web',
  'Conversas salvas manualmente'
];

const AC_FEATURES = [
  'Centraliza as conversas de vários números',
  'Conversas salvas automaticamente no CRM',
  'Organiza conversas por setores e atendentes',
  'Organiza conversas por etiquetas',
  'Modelos de mensagens pré-definidas',
  'Relatórios de conversas e atendimento',
  'Integração com formulário do site',
  'Anotações, mensagens privadas e @menção<br>entre a equipe nas conversas'
];

function DetailsWhatsApp({ onClose, show }) {
  const { user } = useAuth();
  const tracker = useTracking();

  const handleChromeExtensionClick = () => {
    tracker.trackWhatsappModalClicked({ user, action: 'Extensao' });
  };

  const handleAgendorChatClick = () => {
    tracker.trackWhatsappModalClicked({ user, action: 'Ac' });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        size='xl'
        bodyClassName='p-8 height-6 z-index-1090'
      >
        <h1 className='d-flex text-darker-gray justify-content-center mb-2'>
          Conheça as 2 maneiras de integrar o Agendor com o WhatsApp
        </h1>
        <Row>
          <IntegrationOption
            logo={ChromeLogo}
            title='Extensão Google Chrome'
            features={EXTENSION_FEATURES}
            link={{
              href: 'https://chromewebstore.google.com/detail/crm-de-vendas-para-whatsa/gmpkcemlmfebbppdonfmfanggndocjec',
              variant: 'outline-dark'
            }}
            buttonText='Saiba mais'
            classes={{
              colClassName: 'mt-2 ps-0',
              imageClassName: 'mb-2 mt-1',
              buttonClassName: 'ms-6 mb-4',
              bulletClassName: ''
            }}
            onClick={handleChromeExtensionClick}
          />
          <IntegrationOption
            logo={AgendorChatLogo}
            features={AC_FEATURES}
            link={{
              href: 'https://www.agendor.com.br/lps/agendor-chat?cta=modal-ficha-sistema',
              variant: 'primary'
            }}
            badge={{
              variant: 'light-green',
              text: 'Novidade'
            }}
            buttonText='Saiba mais'
            classes={{
              colClassName: 'mt-2 ps-0 pe-0',
              imageClassName: 'mb-4 mt-4',
              buttonClassName: 'ms-6 mt-4 mb-4',
              bulletClassName: 'text-primary'
            }}
            onClick={handleAgendorChatClick}
          />
        </Row>
      </Modal>
    </>
  );
}

export default DetailsWhatsApp;
