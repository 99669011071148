import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import ExternalLink from '@/components/ExternalLink';
import {
  legacyNewFunnelOptoutUrl,
  legacyNewFormsOptoutUrl,
  legacyNewListsOptoutUrl
} from '@/routes';
import Tooltip from '@/components/Tooltip';
import { useQueryParams, useScreenSize } from '@/hooks';
import { showLegacyButton } from '@/utils';
import { useAuth } from '@/lib/auth';

const propTypes = {
  from: PropTypes.oneOf(['newFunnel', 'newForms', 'newLists']).isRequired,
  className: PropTypes.string,
  entity: PropTypes.shape({
    type: PropTypes.oneOf(['organization', 'person', 'deal']).isRequired,
    id: PropTypes.string
  })
};

const defaultProps = {
  className: '',
  from: 'newFunnel',
  entity: null
};

function OptoutButton({ className, from, entity }) {
  const isLG = useScreenSize()?.isLG;
  const queryParams = useQueryParams();
  const { user } = useAuth();

  const VARIANTS = {
    newFunnel: {
      buttonText: 'Voltar para o funil antigo',
      buttonURL: legacyNewFunnelOptoutUrl(),
      buttonVariant: 'transparent-dark'
    },
    newForms: {
      buttonText: 'Voltar para os formulários antigos',
      buttonURL: legacyNewFormsOptoutUrl(entity),
      buttonVariant: 'light'
    },
    newLists: {
      buttonText: 'Voltar para a listagem antiga',
      buttonURL: legacyNewListsOptoutUrl(entity),
      buttonVariant: 'transparent-dark'
    }
  };

  const { buttonText, buttonURL, buttonVariant } = VARIANTS[from];

  if ((queryParams?.legacy || showLegacyButton(user))) {
    return (
      <Tooltip hide={!isLG} content={buttonText}>
        <Button
          className={classnames(
            className,
            'align-self-center',
            'py-2',
            'px-4',
            'border-0'
          )}
          variant={buttonVariant}
          size='sm'
          as={ExternalLink}
          href={buttonURL}
        >
          <Icon name='close-beta' />

          <span className='d-none text-nowrap d-xl-block ms-2'>
            {buttonText}
          </span>
        </Button>
      </Tooltip>
    );
  }
  return null;
}

OptoutButton.propTypes = propTypes;
OptoutButton.defaultProps = defaultProps;

export default OptoutButton;
