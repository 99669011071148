import { useReducer, useEffect } from 'react';

/**
 *
 * Custom hook to handle drag state of a board of droppable columns with
 * draggable items using React's useReducer hook.
 *
 * Arguments:
 *  - initial state of the columns
 *  - a callback to be called when an item moves from one column to another,
 *    receiving and object describing the move and the dispatch function.
 *
 */
export function useBoardDrag(initialColumns, onMove) {
  const [state, dispatch] = useReducer(reducer, {
    columns: initialColumns,
    isDragging: false,
    pendingActions: [],
    lastMove: null
  });

  // Run the provided `onMove` callback on every change of `lastMove`.
  useEffect(() => {
    if (state.lastMove) {
      onMove(state.lastMove, dispatch);
    }
  }, [state.lastMove, onMove]);

  return [state, dispatch];
}

function reducer(state, action) {
  switch (action.type) {
    case 'drag_start':
      return { ...state, isDragging: true };

    case 'drag_end':
      return applyPendingUpdates(
        optimisticMove({ ...state, isDragging: false }, action.payload)
      );

    case 'move_success':
      return confirmMove(state, action.payload);

    case 'move_failure':
      return whenNotDragging(state, action, undoMove);

    case 'set_items':
      return whenNotDragging(state, action, setItems);

    case 'update_column':
      return whenNotDragging(state, action, updateColumn);

    case 'add_item':
      return whenNotDragging(state, action, addItem);

    default:
      return state;
  }
}

/**
 *
 * We can't add or remove draggables while a drag is occuring, so when we
 * receive async updates, we save them in `pendingActions` in the
 * `whenNotDragging function.`
 * When the drag finishes, we apply these actions one by one with
 * `applyPendingUpdates`.
 *
 */

function whenNotDragging(state, action, fn) {
  if (state.isDragging) {
    return {
      ...state,
      pendingActions: [...state.pendingActions, action]
    };
  } else {
    return fn(state, action.payload);
  }
}

function applyPendingUpdates(state) {
  return {
    ...state.pendingActions.reduce(reducer, state),
    pendingActions: []
  };
}

/**
 *
 * Immediately change state in response to a drop.
 * If the item changed columns, it won't be able to be dragged again while
 * this move isn't verified (either succeds or fails).
 *
 */
function optimisticMove(state, payload) {
  const { source, destination, draggableId } = payload.result;

  if (!destination) {
    return state;
  }

  const sameColumn = source.droppableId === destination.droppableId;
  if (sameColumn && source.index === destination.index) {
    return state;
  }

  const sourceIndex = source.index;
  const sourceData = state.columns[source.droppableId].items[sourceIndex];
  const sourceFunnel = state.columns[source.droppableId].funnelId;
  const sourceIsBacklog = state.columns[source.droppableId].isBacklog;

  const destinationIndex = destination.index;
  const destinationColumn = state.columns[destination.droppableId];
  const destinationFunnel = state.columns[destination.droppableId].funnelId;
  const destinationHasRequiredFields = state.columns[destination.droppableId].hasRequiredFields;

  if (destinationColumn.order !== 1 &&
    sourceIsBacklog &&
    destinationHasRequiredFields) {
    return state;
  }

  const isEditable =
    sourceFunnel !== destinationFunnel &&
    destinationFunnel === 'sale' &&
    !sourceIsBacklog;

  /*
   * We're not setting lastMove in case of reorder (same column) because this
   * won't be persisted and disabling/reenabling the drag too fast creates
   * a weird blinking effect on the card.
   */
  let lastMove = state.lastMove;
  if (!sameColumn) {
    lastMove = {
      fromBacklog: sourceIsBacklog,
      itemId: draggableId,
      sourceColumnIndex: source.droppableId,
      sourceIndex,
      sourceData,
      destinationColumnIndex: destination.droppableId,
      destinationIndex,
      destinationColumnId: destinationColumn.id,
      destinationFunnel,
      isEditable
    };
  }

  return {
    ...state,
    columns: moveItem(state.columns, {
      sourceColumnIndex: source.droppableId,
      destinationColumnIndex: destination.droppableId,
      destinationIndex,
      itemId: draggableId,
      itemProps: { isDragDisabled: !sameColumn }
    }),
    lastMove
  };
}

/**
 *
 * Move that we applied optimiscally succeded, so we just need to reenable
 * dragging of the item.
 *
 */
function confirmMove(state, payload) {
  return {
    ...state,
    columns: updateItemById(
      state.columns,
      payload.move.destinationColumnIndex,
      payload.move.itemId,
      { isDragDisabled: false, ...payload.data }
    )
  };
}

/**
 *
 * Move that we applied optimiscally failed, so we need to revert it back.
 *
 */
function undoMove(state, payload) {
  const { itemId, destinationColumnIndex, sourceColumnIndex } = payload.move;

  return {
    ...state,
    columns: moveItem(state.columns, {
      sourceColumnIndex: destinationColumnIndex,
      destinationColumnIndex: sourceColumnIndex,
      destinationIndex: 0,
      itemId,
      itemProps: { isDragDisabled: false }
    })
  };
}

/**
 *
 * Set `state.columns[payload.id].items` to `payload.items`
 *
 */
function setItems(state, payload) {
  const { id, items, nested = false } = payload;
  const column = state.columns[id];

  let newColumn;
  if (nested) {
    newColumn = { ...column, [nested]: { ...column[nested], items } };
  } else {
    newColumn = { ...column, items };
  }

  return {
    ...state,
    columns: { ...state.columns, [id]: newColumn }
  };
}

/**
 *
 * Update the column at `state.columns[payload.id]` with the data from
 * `payload.column`.
 *
 */
function updateColumn(state, payload) {
  const { id, column } = payload;
  const currentColumn = state.columns[id];

  return {
    ...state,
    columns: { ...state.columns, [id]: { ...currentColumn, ...column } }
  };
}

/**
 *
 * Move an item in columns. params expects the following options:
 * sourceColumnIndex: index of the column where the item was moved _from_
 * destinationColumnIndex: index of the column where the item was moved _to_
 * itemId: id of the moved item
 * itemProps: optional props to add to the item as a result of this move
 *
 */
function moveItem(columns, params) {
  const copy = { ...columns };

  const sourceColumn = columns[params.sourceColumnIndex];
  const destinationColumn = columns[params.destinationColumnIndex];

  const sourceItems = [...sourceColumn.items];
  const sourceIndex = sourceItems.findIndex((item) => item.id === params.itemId);

  if (sourceIndex === -1) {
    console.error(
      `Cant find item ${params.itemId} in column ${params.sourceColumnIndex}.`
    );
    return columns;
  }

  const itemProps = params.itemProps || {};
  const targetItem = { ...sourceItems[sourceIndex], ...itemProps };

  let destinationItems;
  let diff = 1;
  let diffAmount = targetItem.value || 0;

  if (params.sourceColumnIndex === params.destinationColumnIndex) {
    destinationItems = sourceItems;
    diff = 0;
    diffAmount = 0;
  } else {
    destinationItems = [...(destinationColumn.items || [])];
  }

  sourceItems.splice(sourceIndex, 1);
  destinationItems.splice(params.destinationIndex, 0, targetItem);

  copy[params.sourceColumnIndex] = {
    ...sourceColumn,
    totalCount: sourceColumn.totalCount - diff,
    totalAmount: sourceColumn.totalAmount - diffAmount,
    items: sourceItems
  };
  copy[params.destinationColumnIndex] = {
    ...destinationColumn,
    totalCount: destinationColumn.totalCount + diff,
    totalAmount: destinationColumn.totalAmount + diffAmount,
    items: destinationItems
  };

  return copy;
}

function updateColumnAt(columns, index, data) {
  const copy = { ...columns };

  copy[index] = { ...columns[index], ...data };

  return copy;
}

function updateItemById(columns, columnIndex, itemId, data) {
  let oldItemValue;
  const newItemValue = data.value || 0;

  const items = [...columns[columnIndex].items].map((item) => {
    if (item.id === itemId) {
      oldItemValue = item.value || 0;

      return { ...item, ...data };
    } else {
      return item;
    }
  });

  const totalAmount =
    columns[columnIndex].totalAmount - oldItemValue + newItemValue;

  return updateColumnAt(columns, columnIndex, { items, totalAmount });
}

function addItem(state, payload) {
  const { columns } = state;
  const { stageId, value } = payload;

  const columnIndex = `funnel-${stageId}`;
  const column = columns[columnIndex];

  if (!column) {
    console.error(`Can't add item: No column at ${columnIndex}`);
    return state;
  }

  const items = [payload, ...column.items];
  const totalCount = column.totalCount + 1;
  const totalAmount = column.totalAmount + value;

  const newColumns = updateColumnAt(columns, columnIndex, {
    items,
    totalCount,
    totalAmount
  });

  return {
    ...state,
    columns: newColumns
  };
}
