import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import LoadSpinner from '@/components/LoadSpinner';

const propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  innerRef: PropTypes.any,
  innerProps: PropTypes.object,
  innerPlaceholder: PropTypes.node
};

const defaultProps = {
  className: '',
  innerRef: null,
  innerProps: {},
  innerPlaceholder: null
};

function LoadingWrapper(props) {
  const { isLoading, children, className, innerRef, innerProps } = props;

  if (isLoading) {
    return (
      <div
        className={classnames(
          'position-relative',
          'loading-wrapper',
          'd-flex',
          'justify-content-center',
          'align-items-center',
          'text-center',
          className
        )}
        ref={innerRef}
        { ...innerProps }
      >
        <LoadSpinner className='z-index-1 text-primary' />

        {props.innerPlaceholder}
      </div>
    );
  }

  return children;
}

LoadingWrapper.propTypes = propTypes;
LoadingWrapper.defaultProps = defaultProps;

export default LoadingWrapper;
