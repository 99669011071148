import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import DotList from '@/components/DotList';
import CardList from '@/components/CardList';
import Truncate from '@/components/Truncate';
import FlexCollapse from '@/components/FlexCollapse';
import FunnelStageFrozenSection from '@/components/FunnelStageFrozenSection';
import EmptyFunnelStage from '@/components/EmptyFunnelStage';
import { toCurrencyString } from '@/number';
import { useFunnelContext } from '@/contexts';
import { useInfiniteFunnelStageItems, defaultFunnelStageParams } from '@/api';
import FunnelStageCard from '@/components/FunnelStageCard';

const propTypes = {
  funnelId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.string
};

const defaultProps = {
};

function FunnelStage({ funnelId, id, name, color }) {
  const {
    getColumn, dispatch, params: boardParams, isFetching, showFrozen
  } = useFunnelContext();

  const { items = [], totalAmount = 0, totalCount = 0 } = getColumn(`funnel-${id}`);
  const currencyTotalAmount = toCurrencyString(totalAmount);

  const [frozenOpened, setFrozenOpened] = useState(false);

  const {
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = useInfiniteFunnelStageItems({
    funnelId,
    stageId: id,
    params: { ...boardParams, ...defaultFunnelStageParams },
    config: {
      enabled: Boolean(boardParams) && !isFetching,
      // Isso é necessário para não fazer requisição, já que os dados foram pré-populados
      staleTime: 1000,
      onSuccess: ({ pages }) => {
        const fullItems = pages.flatMap((page) => page.data);

        dispatch({
          type: 'set_items',
          payload: { id: `funnel-${id}`, items: fullItems }
        });
      }
    }
  });

  useEffect(() => {
    setFrozenOpened(false);
  }, [showFrozen]);

  return (
    <div className={classnames(
      'stage-column',
      'd-flex',
      'flex-column',
      'flexible-height',
      'hover-parent',
      'position-relative',
      'min-width-5',
      'width-5',
      'overflow-hidden'
    )}>
      <div className={classnames(
        'border-top',
        'opacity-10',
        'hover-child-opacity-100',
        { [`border-${color}`]: color }
      )} />

      <div className='text-dark-gray flex-shrink-0 mt-3 mb-2 pe-2'>
        <h5 className='hover-child-text-body mb-0 text-uppercase'>
          <Truncate>{name}</Truncate>
        </h5>

        <DotList
          truncate
          items={[totalCount, currencyTotalAmount]}
        />
      </div>

      <FlexCollapse open={!frozenOpened}>
        <Droppable
          droppableId={`funnel-${id}`}
          type='card'
        >
          {(provided, snapshot) => (
            items.length === 0 && !snapshot.isDraggingOver
              ? (
                <EmptyFunnelStage
                  innerRef={provided.innerRef}
                  innerProps={provided.droppableProps}
                  innerPlaceholder={provided.placeholder}
                >
                  Arraste para cá,<br/>para adicionar negócios nessa etapa
                </EmptyFunnelStage>
              )
              : (
                <CardList
                  items={items}
                  renderItem={renderDraggable}
                  innerRef={provided.innerRef}
                  innerProps={provided.droppableProps}
                  innerPlaceholder={provided.placeholder}
                  fetchMore={fetchNextPage}
                  canFetchMore={hasNextPage}
                  isFetchingMore={Boolean(isFetchingNextPage)}
                  className='pe-2'
                />
              )
          )}
        </Droppable>
      </FlexCollapse>

      <FunnelStageFrozenSection
        funnelId={funnelId}
        stageId={id}
        show={showFrozen}
        open={frozenOpened}
        onToggle={() => setFrozenOpened(!frozenOpened)}
      />
    </div>
  );
}

function renderDraggable(card, index) {
  return (
    <Draggable
      draggableId={card.id}
      index={index}
      key={card.id}
      isDragDisabled={card.isDragDisabled}
    >
      {(provided, snapshot) => (
        <div
          className={classnames(
            'mb-1',
            'draggable',
            {
              'disabled opacity-50': card.isDragDisabled,
              'transition-delay-short':
                !snapshot.isDragging &&
                provided.draggableProps.style.transform
            }
          )}
          ref={provided.innerRef}
          { ...provided.draggableProps }
          { ...provided.dragHandleProps }
        >
          <FunnelStageCard card={card} />
        </div>
      )}
    </Draggable>
  );
}

FunnelStage.propTypes = propTypes;
FunnelStage.defaultProps = defaultProps;

export default FunnelStage;
