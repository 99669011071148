import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '@/components/Icon';

const propTypes = {
  currentStep: PropTypes.number.isRequired,
  headerLabels: PropTypes.array.isRequired,
  headerClassName: PropTypes.string
};

function StepperHeaderMini({ currentStep, headerLabels, headerClassName }) {
  const divConcludeStepStyle = ['text-white', 'bg-primary', 'border', 'border-primary'];
  const divActiveStepStyle = ['text-white', 'border', 'border-primary', 'bg-primary'];
  const divPendingStepStyle = ['text-medium-gray', 'border', 'border-medium-gray', 'bg-white'];

  const divStyle = (step, isLastLabel) => {
    let styles = [
      'position-relative',
      'd-flex',
      'justify-content-center',
      'align-items-center',
      'text-center',
      'rounded-circle',
      'z-index-3',
      'text-small',
      'p-2',
      { 'me-3': !isLastLabel }
    ];

    if (step < currentStep) {
      styles = [...styles, ...divConcludeStepStyle];
    }
    if (step === currentStep) {
      styles = [...styles, ...divActiveStepStyle];
    }
    if (step > currentStep) {
      styles = [...styles, ...divPendingStepStyle];
    }
    return classnames(styles);
  };

  const contentCurrentStep = (step) => (
    step < currentStep ? <Icon name='check' size='sm' /> : step + 1
  );

  const renderLabel = (step, label, isLastLabel) => (
    <React.Fragment key={step}>
      <div className={divStyle(step, isLastLabel)}>
        <span className='position-absolute'>{contentCurrentStep(step)}</span>
      </div>
    </React.Fragment>
  );

  return (
    <div className={classnames('position-relative', headerClassName)}>
      <div className='border-top border-medium-gray position-absolute z-index-1 w-100' />

      {
        headerLabels.map((label, index) => {
          const isLastLabel = headerLabels.length === index + 1;

          return renderLabel(index, label, isLastLabel);
        })
      }
    </div>
  );
}

StepperHeaderMini.propTypes = propTypes;

export default StepperHeaderMini;
