import React from 'react';
import PropTypes from 'prop-types';
import Brands from 'images/billing/adyen-brands.svg';

const propTypes = {
  cardType: PropTypes.string,
  className: PropTypes.string
};

const BRAND = [
  'amex',
  'diners',
  'elo',
  'hipercard',
  'mc',
  'visa',
  'cc_generic'
];

function CardBrand({ cardType, className }) {
  const cardTypeIncluded = BRAND.includes(cardType) ? cardType : 'cc_generic';

  return (
    <svg className={className}>
      <use xlinkHref={`${Brands}#icon-${cardTypeIncluded}`} />
    </svg>
  );
}

CardBrand.propTypes = propTypes;

export default CardBrand;
