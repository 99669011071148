import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Container, Col, Row } from 'react-bootstrap';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import LoadingButton from '@/components/LoadingButton';
import HookForm from '@/components/HookForm';
import HookFormField from '@/components/HookFormField';
import Footer from '@/components/Footer';
import {
  TextInput, UserSelect, FunnelSelect, DatePickerInput, TextArea, CustomerSelect,
  ValueField, PrivacyFields, HookProductsFields
} from '@/components/Inputs';
import {
  fastCustomFieldsSchema,
  HookCustomFieldsSection
} from '@/components/CustomFields';
import { schema as productsSchema } from '@/components/Inputs/ProductsFields';
import {
  schema as privacySchema, defaultValues as privacyDefaultValues
} from '@/components/Inputs/PrivacyFields';
import { fromDMA, now, toDateString } from '@/date';
import { DEAL_STATUSES } from '@/utils';

const propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  initialValues: PropTypes.object,
  className: PropTypes.string,
  action: PropTypes.oneOf(['create', 'edit'])
};

const defaultProps = {
  onSubmit: () => { },
  onCancel: () => { },
  initialValues: {},
  className: '',
  action: 'create'
};

/* eslint-disable no-magic-numbers */
const schema = Yup.object().shape({
  title: Yup.string()
    .max(150, 'O nome do negócio deve ter no máximo 150 caracteres.')
    .required('Por favor, informe o nome do negócio.'),
  description: Yup.string()
    .max(2000, 'A descrição do negócio deve ter no máximo 2000 caracteres.'),
  started_at: Yup.string()
    .test(
      'isValidDate',
      'Data inválida',
      (value) => !value || Boolean(fromDMA(value))
    ),
  finished_at: Yup.string()
    .test(
      'isValidDate',
      'Data inválida',
      (value) => !value || Boolean(fromDMA(value))
    )
    .when('deal_status', ([dealStatus], fieldSchema) => {
      switch (dealStatus?.id) {
        case DEAL_STATUSES.WON:
        case DEAL_STATUSES.LOST:
          return fieldSchema.required('Por favor, informe a data de conclusão.');

        default:
          return fieldSchema;
      }
    }),
  custom_fields: fastCustomFieldsSchema,
  entity_products: productsSchema,
  privacy: privacySchema
});
/* eslint-enable no-magic-numbers */

const defaultValues = {
  title: '',
  value: '',
  description: '',
  started_at: toDateString(now()),
  finished_at: '',
  owner_user_id: '',
  funnel_id: '',
  customer: { id: '', text: '' },
  custom_fields: {},
  ...privacyDefaultValues
};

const CUSTOMER_PARAMS = {
  organization: 'organization_id',
  person: 'person_id'
};

const VARIANTS = {
  create: {
    saveButtonText: 'Salvar',
    cancelButtonText: 'Voltar'
  },
  edit: {
    saveButtonText: 'Salvar alterações',
    cancelButtonText: 'Cancelar'
  }
};

function HookFullDealForm({
  onSubmit, onCancel, initialValues, action, onDelete, onCreateCustomer, customFields
}) {
  const {
    saveButtonText,
    cancelButtonText
  } = VARIANTS[action];

  const handleOnSubmit = useCallback(({ customer, ...formData }, helpers) => {
    const customerParam = {
      organization_id: null,
      person_id: null
    };

    if (customer && customer.id) {
      const [type, id] = customer.id.split('-');
      const param = CUSTOMER_PARAMS[type];

      customerParam[param] = id;
    }

    return onSubmit({ ...formData, ...customerParam }, helpers);
  }, [onSubmit]);

  return (
    <HookForm
      name='full-deal-form'
      onSubmit={handleOnSubmit}
      defaultValues={
        {
          ...defaultValues,
          ...initialValues
        }
      }
      validationSchema={schema}
      validationContext={{ customFields }}
      className='flex-grow-1 d-flex flex-column'
    >
      {({ formState: { isSubmitting } }) => (
        <>
          <Container className='mt-8'>
            <Row>
              <Col md={{ offset: 1, span: 10 }}>
                <h2 className='my-4'>
                  Dados básicos
                </h2>

                <Row className='gx-3'>
                  <Col sm={6}>
                    <HookFormField
                      as={TextInput}
                      name='title'
                      label='Nome do negócio'
                      autoComplete='off'
                      placeholder='Dê um nome para o negócio'
                      hint='Obrigatório'
                      maxLength={150}
                      variant='light'
                    />
                  </Col>
                  <Col sm={6}>
                    <HookFormField
                      as={CustomerSelect}
                      name='customer'
                      label='Empresa / Pessoa'
                      onCreate={onCreateCustomer}
                      initialValue={initialValues.customer}
                      className='position-relative'
                    />
                  </Col>
                </Row>

                <Row className='gx-3'>
                  <Col sm={6}>
                    <HookFormField
                      as={UserSelect}
                      name='owner_user_id'
                      label='Responsável'
                      placeholder='Selecione'
                    />
                  </Col>
                  <Col sm={6}>
                    <ValueField
                      name='value'
                    />
                  </Col>
                </Row>

                <Row className='gx-3'>
                  <Col sm={6}>
                    <HookFormField
                      as={FunnelSelect}
                      name='funnel_id'
                      label='Funil'
                      placeholder='Selecione'
                    />
                  </Col>
                  <Col sm={3}>
                    <HookFormField
                      as={DatePickerInput}
                      name='started_at'
                      label='Data de início'
                      placeholder='00/00/0000'
                      variant='light'
                    />
                  </Col>
                  <Col sm={3}>
                    <HookFormField
                      as={DatePickerInput}
                      name='finished_at'
                      label='Data de conclusão'
                      placeholder='00/00/0000'
                      variant='light'
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <HookFormField
                      as={TextArea}
                      name='description'
                      label='Descrição'
                      placeholder='Escreva detalhes importantes sobre esse cliente'
                    />
                  </Col>
                </Row>

                <HookCustomFieldsSection
                  section='basic_data'
                  fields={customFields}
                  className='gx-3'
                />

                <hr className='mb-6' />

                <h2 className='my-4'>
                  Produtos e serviços
                </h2>

                <p className='subtitle text-dark-gray'>
                  Adicione produtos ou serviços com valor e quantidade na sua
                  oportunidade de venda.
                </p>

                <HookProductsFields />

                <hr className='my-6' />

                <h2 className='my-4'>
                  Privacidade
                </h2>

                <p className='subtitle text-dark-gray'>
                  Quem pode ver o histórico e editar esse negócio?
                </p>

                <Row className='mb-2'>
                  <Col>
                    <PrivacyFields />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>

          <Footer className='mt-auto flex-shrink-0 py-4 shadow-up bg-white'>
            <Container>
              <Row>
                <Col
                  md={{ offset: 1, span: 10 }}
                  className='d-flex'
                >
                  {onDelete && (
                    <Button
                      className='text-danger fw-bold px-0'
                      variant='link'
                      onClick={onDelete}
                    >
                      <Icon name='delete' className='me-2 my-n1' />
                      <span>Excluir negócio</span>
                    </Button>
                  )}

                  <Button
                    className='ms-auto me-3'
                    variant='outline-darker-gray'
                    onClick={onCancel}
                    disabled={isSubmitting}
                  >
                    {cancelButtonText}
                  </Button>

                  <LoadingButton
                    type='submit'
                    isLoading={isSubmitting}
                    className='min-width-4 px-8'
                  >
                    {saveButtonText}
                  </LoadingButton>
                </Col>
              </Row>
            </Container>
          </Footer>
        </>
      )}
    </HookForm>
  );
}

HookFullDealForm.propTypes = propTypes;
HookFullDealForm.defaultProps = defaultProps;

export default HookFullDealForm;
