import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { PopoverProvider } from '@/contexts';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Tooltip from '@/components/Tooltip';
import Popover from '@/components/Popover';
import { useFormikContext } from 'formik';

function FilterColumnButton({ className, filter }) {
  const container = useRef(null);
  const [hideTooltip, setHideTooltip] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);
  const { values, submitForm, setFieldValue } = useFormikContext();

  if (isEmpty(filter)) {
    return null;
  }

  const filterFormKey = get(values, filter?.formKey);
  const filterFormName = get(values, filter?.name);
  const filterValue = filterFormKey || filterFormName;
  const hasActiveFilter = Boolean(filterValue);

  return (
    <div className={className}>
      <Popover
        trigger={['click']}
        placement='bottom-start'
        rootCloseEvent='mousedown'
        rootClose
        flip
        onExit={() => setMouseOver(false)}
        content={
          ({ onHide }) => (
            <PopoverProvider value={{ container }}>
              <div ref={container} className='width-5 py-1'>
                <div>
                  {filter?.renderComponent?.(filter, get(values, filter?.name))}
                </div>
                <div>
                  {
                    hasActiveFilter
                      ? (
                        <Button
                          variant='link'
                          className='position-absolute top-0 end-0 pe-5 pt-4'
                          onClick={() => {
                            setFieldValue(filter?.name, null);
                            filter.onClear?.({ setFieldValue });
                            submitForm();
                            onHide();
                          }}
                        >
                          <Icon size='sm' name='close' />
                          <span className='d-inline fw-bold'>Limpar</span>
                        </Button>
                      )
                      : null
                  }
                  <Button
                    variant='outline-darker-gray'
                    className='px-2'
                    onClick={() => {
                      submitForm();
                      onHide();
                    }}
                  >
                        OK
                  </Button>
                </div>
              </div>
            </PopoverProvider>
          )
        }
      >
        <div>
          <Tooltip
            content='Clique para filtrar'
            placement='bottom'
            hide={hideTooltip}
          >
            <Button
              size='sm'
              variant='transparent-light'
              className={classnames(
                'px-1',
                { 'text-dark-gray': !mouseOver }
              )}
              onClick={() => {
                setHideTooltip(true);
                setMouseOver(true);
              }}
              onMouseEnter={() => setMouseOver(true)}
              onMouseLeave={() => {
                setHideTooltip(false);
                setMouseOver(false);
              }}
            >
              <div className='position-relative'>
                <Icon name='filter' size='sm' className='p-0' />
                {
                  hasActiveFilter
                    ? <div className='notify-dot-icon rounded-circle bg-primary' />
                    : null
                }
              </div>
            </Button>
          </Tooltip>
        </div>
      </Popover>
    </div>
  );
}

FilterColumnButton.defaultProps = {
  type: '',
  className: '',
  filter: {},
  filterValues: null,
  onSubmit: () => {}
};

FilterColumnButton.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  filter: PropTypes.object,
  filterValues: PropTypes.object,
  onSubmit: PropTypes.func
};

export default FilterColumnButton;
