import React, { useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import Modal from '@/components/Modal';

/**
 *
 * Componente de layout que exibe seu conteúdo em um `Modal`.
 * Segue a mesma ideia da `OffcanvasPage`.
 *
 */
function ModalPage() {
  const navigate = useNavigate();

  const [show, setShow] = useState(true);
  const onHide = () => setShow(false);
  const onExited = () => navigate('./..');

  return (
    <Modal
      show={show}
      onHide={onHide}
      onExited={onExited}
      size='lg'
      bodyClassName='p-8'
      backdrop='static'
    >
      <Outlet context={{ onHide }} />
    </Modal>
  );
}

export default ModalPage;
