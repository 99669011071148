import { useQuery } from 'react-query';
import { get } from '@/lib/fetch';

export const dealTemplatesKeys = {
  all: ['deal-template'],
  lists: () => [...dealTemplatesKeys.all, 'list'],
  list: (params) => [...dealTemplatesKeys.lists(), params]
};

export async function getDealTemplate(params = {}) {
  const { data } = await get('/deal_template', params);

  return data;
}

export function useDealTemplate({ params = {}, config = {} }) {
  return useQuery({
    ...config,
    queryKey: dealTemplatesKeys.list(params),
    queryFn: () => getDealTemplate(params)
  });
}
