export function canCreateCustomField({ user, _data }) {
  let effect, reason;

  if (!user.admin) {
    effect = 'disable';
    reason = 'Apenas administradores podem criar campos.';
  } else {
    effect = 'allow';
  }

  return { effect, reason };
}

export function canEditCustomField({ user, _data }) {
  let effect, reason;

  if (!user.admin) {
    effect = 'disable';
    reason = 'Apenas administradores podem editar/excluir campos.';
  } else {
    effect = 'allow';
  }

  return { effect, reason };
}

export function canDeleteCustomField({ user, _data }) {
  let effect, reason;

  if (!user.admin) {
    effect = 'disable';
    reason = 'Apenas administradores podem editar/excluir campos.';
  } else {
    effect = 'allow';
  }

  return { effect, reason };
}
