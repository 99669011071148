import React from 'react';
import SortInput from '@/components/Inputs/SortInput';

const propTypes = {
};

const defaultProps = {
};

function CustomerSortInput(props) {
  return (
    <SortInput
      { ...props }
      options={[
        {
          label: 'Última Atualização',
          value: 'updated_at',
          direction: { type: 'date', defaultValue: 'desc' }
        },
        {
          label: 'Ordem Alfabética',
          value: 'name',
          direction: { type: 'text', defaultValue: 'asc' }
        },
        {
          label: 'Ranking',
          value: 'ranking',
          direction: { type: 'number', defaultValue: 'desc' }
        },
        {
          label: 'Data de Cadastro',
          value: 'created_at',
          direction: { type: 'date', defaultValue: 'desc' }
        }
      ]}
    />
  );
}

CustomerSortInput.propTypes = propTypes;
CustomerSortInput.defaultProps = defaultProps;

export default CustomerSortInput;
