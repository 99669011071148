require('../../src/scss/application.scss');
require('what-input');

const React = require('react');
const ReactDOM = require('react-dom');
const App = require('../../src/javascript/application/app.jsx').default;

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    React.createElement(App),
    document.getElementById('root')
  );
});

