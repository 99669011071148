import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import LoadingButton from '@/components/LoadingButton';
import Modal from '@/components/Modal';
import Info from '@/components/ConfirmationModal/Info';
import Title from '@/components/ConfirmationModal/Title';
import Content from '@/components/ConfirmationModal/Content';

const propTypes = {
  confirmationText: PropTypes.string.isRequired,
  confirmationLoadingText: PropTypes.string,
  children: PropTypes.node,
  show: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onConfirm: PropTypes.func,
  variant: PropTypes.oneOf(['danger', 'success']),
  onHide: PropTypes.func,
  hideText: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['md', 'lg']),
  buttonClassName: PropTypes.string
};

const defaultProps = {
  children: null,
  show: false,
  disabled: false,
  variant: 'danger',
  loading: false,
  hideText: '',
  onConfirm: () => {},
  onHide: () => {},
  size: 'md',
  buttonClassName: ''
};

function ConfirmationModal(props) {
  const {
    children,
    show,
    disabled,
    loading,
    confirmationText,
    confirmationLoadingText,
    onConfirm,
    onHide,
    variant,
    hideText,
    className,
    size,
    buttonClassName
  } = props;

  return (
    <Modal
      show={show}
      onHide={onHide}
      size={size}
      bodyClassName='confirmation-modal p-8'
      className={className}
    >
      <div className='pt-4 px-4 text-center'>
        {children}
      </div>

      <div className={classnames(buttonClassName, 'mt-4 d-grid gap-2')}>
        <LoadingButton
          variant={variant}
          onClick={onConfirm}
          disabled={disabled}
          isLoading={loading}
          loadingText={confirmationLoadingText}
        >
          {confirmationText}
        </LoadingButton>

        <Button
          variant='link'
          onClick={onHide}
        >
          { hideText || 'Cancelar' }
        </Button>
      </div>
    </Modal>
  );
}

ConfirmationModal.propTypes = propTypes;
ConfirmationModal.defaultProps = defaultProps;

ConfirmationModal.Info = Info;
ConfirmationModal.Title = Title;
ConfirmationModal.Content = Content;

export default ConfirmationModal;
