export function canSendEmail({ user }) {
  let effect, reason;

  if (!user.account.paid) {
    effect = 'paywall';
    reason = 'Para enviar e-mails, é necessário assinar um plano.';
  } else {
    effect = 'allow';
  }

  return { effect, reason };
}
