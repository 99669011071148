import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SearchBar from '@/components/SearchBar';
import CustomerSearch from '@/components/CustomerSearch';
import { useAuth } from '@/lib/auth';
import { useCreateOrganization, useCreatePerson } from '@/api';

function FooterActions(props) {
  const {
    isFetching, isEmpty, isCreating, createFailed, term, items, onButtonClick
  } = props;

  if (isFetching) {
    return null;
  }

  return (
    <div
      className={classnames(
        'd-flex',
        'flex-column',
        'align-items-center',
        { 'pt-5': !isEmpty }
      )}
    >
      <div className='text-darker-gray fw-bold'>
        {`Criar "${term}" como novo(a):`}
      </div>

      { createFailed &&
        (
          <div className='text-center w-100 mt-5 py-2 bg-light-red'>
            Não foi possível criar este cliente
          </div>
        )
      }

      <div className='d-flex align-items-center pt-5 pb-3 fw-bold'>
        <CustomerSearch.FooterActionButton
          className='me-5'
          disabled={isCreating}
          type='organization'
          items={items}
          term={term}
          onClick={onButtonClick}
        />

        <CustomerSearch.FooterActionButton
          disabled={isCreating}
          type='person'
          items={items}
          term={term}
          onClick={onButtonClick}
        />
      </div>
    </div>
  );
}

function Footer(props) {
  const {
    isFetching, isEmpty, isCreating, createFailed, term, items,
    onButtonClick, onHideOptions
  } = props;

  const handleButtonClick = (data) => onButtonClick({ ...data, onHideOptions });

  return (
    <div>
      <CustomerSearch.FooterStatus
        isFetching={isFetching}
        isEmpty={isEmpty}
      />
      <FooterActions
        isFetching={isFetching}
        isEmpty={isEmpty}
        isCreating={isCreating}
        createFailed={createFailed}
        term={term}
        items={items}
        onButtonClick={handleButtonClick}
      />
    </div>
  );
}

const propTypes = {
  onChange: PropTypes.func.isRequired,
  onCreate: PropTypes.func,
  className: PropTypes.string,
  initialValue: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string
  }),
  isEditInPlace: PropTypes.bool
};

const defaultProps = {
  onCreate: () => {},
  initialValue: { id: '', text: '' },
  isEditInPlace: false
};

const defaultValues = {
  privacy: {
    type: 'default'
  }
};

export function getCustomer(organization, person) {
  if (organization) {
    return {
      id: `organization-${organization.id}`,
      text: organization.name
    };
  }

  if (person) {
    return {
      id: `person-${person.id}`,
      text: person.name
    };
  }
}

function CustomerSelect(props) {
  const { onChange, onCreate, className, initialValue, label, isEditInPlace } = props;

  const { user } = useAuth();

  const [value, setValue] = useState(initialValue.text);
  const [selected, setSelected] = useState(Boolean(initialValue.text));

  const createOrganizationMutation = useCreateOrganization();
  const createPersonMutation = useCreatePerson();

  const inputProps = useMemo(() => ({
    placeholder: 'Digite o nome do cliente',
    label,
    autoComplete: 'off',
    maxLength: 100,
    isClearable: selected,
    disabled: selected,
    onClear: () => {
      setSelected(false);
      if (!isEditInPlace) {
        onChange(null);
      }
    }
  }), [selected, onChange]);

  const popoverProps = {
    className: isEditInPlace ? 'popover-padding-0 w-200' : 'popover-padding-0 w-100 max-width-100',
    size: 'lg',
    placement: 'bottom-start'
  };

  const onSelect = ({ id, name, onHideOptions, type }) => {
    setValue(name);
    setSelected(true);
    onChange({ text: name, id: `${type}-${id}` });
    onHideOptions();
  };

  const onButtonClickCreateEntity = ({ type, term, onHideOptions }) => {
    const mutation =
      type === 'organization' ? createOrganizationMutation : createPersonMutation;

    mutation.mutate({ ...defaultValues, owner_user_id: user.id, name: term }, {
      onSuccess: ({ data }) => {
        onCreate({ type });
        onSelect({ ...data, type, onHideOptions });
      }
    });
  };

  // Reseta erros da mutation de criação sempre que uma nova busca iniciar
  const onSearchStart = () => {
    createOrganizationMutation.reset();
    createPersonMutation.reset();
  };

  const resultsFooter = (resultsFooterProps) => (
    <Footer
      { ...resultsFooterProps }
      isCreating={
        createOrganizationMutation.isLoading ||
        createPersonMutation.isLoading
      }
      createFailed={
        createOrganizationMutation.isError ||
        createPersonMutation.isError
      }
      onButtonClick={onButtonClickCreateEntity}
    />
  );

  return (
    <SearchBar
      name='customer-search'
      types={['organization', 'person']}
      perPage={5}
      resultsFooter={resultsFooter}
      ItemComponent={CustomerSearch.Item}
      inputProps={inputProps}
      popoverProps={popoverProps}
      onSelect={onSelect}
      onSearchStart={onSearchStart}
      className={className}
      searchValue={value}
    />
  );
}

CustomerSelect.propTypes = propTypes;
CustomerSelect.defaultProps = defaultProps;

export default CustomerSelect;
