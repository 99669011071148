import React from 'react';
import PropTypes from 'prop-types';

import Button from '@/components/Button';
import Popover from '@/components/Popover';
import EmailVerifyForm from '@/feature/email/EmailVerifyForm';
import Tooltip from '@/components/Tooltip';
import Icon from '@/components/Icon';

const propTypes = {
  usersForVerify: PropTypes.array,
  hidePopover: PropTypes.func
};

const defaultProps = {
  usersForVerify: [],
  hidePopover: () => {}
};

function EmailVerifyPopover({ showVerifyModal, usersForVerify }) {
  return (
    <Popover
      placement='bottom-start'
      content={({ onHide }) => (
        <>
          <div className='d-flex mb-2 text-dark-gray'>
            <span className='me-2 fw-bold'>
              Selecione os usuários
            </span>

            <Tooltip content='Escolha quais usuários devem verificar o remetente'>
              <div>
                <Icon name='info' />
              </div>
            </Tooltip>
          </div>

          <hr className='mt-3' />

          <EmailVerifyForm
            hidePopover={onHide}
            showVerifyModal={showVerifyModal}
            usersForVerify={usersForVerify}
          />
        </>
      )}
    >
      <Button className='text-small ms-auto' variant='white' size='sm'>
        Verificar e-mails
      </Button>
    </Popover>
  );
}

EmailVerifyPopover.propTypes = propTypes;
EmailVerifyPopover.defaultProps = defaultProps;

export default EmailVerifyPopover;
