import React from 'react';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import LoadingButton from '@/components/LoadingButton';
import ProposalLoading from '@/components/Proposal/ProposalLoading';

ProposalPreview.propTypes = {
  layoutMode: PropTypes.bool,
  preview: PropTypes.object,
  saving: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func
};

ProposalPreview.defaultProps = {
  layoutMode: false,
  preview: {},
  saving: false,
  onCancel: () => {},
  onSave: () => {}
};

function ProposalPreview({ layoutMode, preview, saving, onCancel, onSave }) {
  const output = layoutMode ? 'o modelo' : 'a proposta';
  const previewUrl = URL.createObjectURL(preview);
  return (
    <>
      {
        saving
          ? (
            <ProposalLoading
              title={`Criando ${output}`}
              subtitle={`Aguarde só um pouco, estamos salvando ${output}.`}
            />
          )
          : (
            <object
              data={previewUrl}
              type='application/pdf'
              className='proposal-preview'
            />
          )
      }
      <div className='position-sticky bg-white shadow-up bottom-0 left-0 w-100 z-index-1030'>
        <div className='d-flex justify-content-end px-8 me-2 py-6'>
          <Button
            onClick={onCancel}
            disabled={saving}
            className='me-2'
            variant='light'
          >
            Voltar
          </Button>
          <LoadingButton
            disabled={saving}
            isLoading={saving}
            loadingText='Aguarde...'
            onClick={onSave}
          >
            {layoutMode ? 'Salvar modelo' : 'Salvar'}
          </LoadingButton>
        </div>
      </div>
    </>
  );
}

export default ProposalPreview;
