import React from 'react';
import PropTypes from 'prop-types';
import ExternalLink from '@/components/ExternalLink';
import Icon from '@/components/Icon';

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string
  })).isRequired
};

const defaultProps = {
  data: []
};

function SocialMediaIcons({ data }) {
  if (data?.length) {
    return (
      <div className='d-inline-block'>
        {data.map((socialMedia) => (
          <ExternalLink
            key={socialMedia.name}
            href={socialMedia.url}
            target='_blank'
          >
            <Icon
              name={socialMedia.name}
              size='sm'
              className='me-1 text-dark-gray'
            />
          </ExternalLink>
        ))}
      </div>
    );
  }
  return null;
}

SocialMediaIcons.propTypes = propTypes;
SocialMediaIcons.defaultProps = defaultProps;

export default SocialMediaIcons;
