import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ExternalLink from '@/components/ExternalLink';
import Tooltip from '@/components/Tooltip';
import { GOOGLE_MAPS_EMBED_KEY } from '@/config';

const propTypes = {
  initialValues: PropTypes.object
};

const defaultProps = {
  initialValues: {}
};

// https://developers.google.com/maps/documentation/maps-static/start?hl=pt-br#Zoomlevels
const ZOOM_LEVEL = 13;

function EntityAddressContent({ initialValues }) {
  const formattedAddress = [
    initialValues?.streetName,
    initialValues?.streetNumber?.toString(),
    initialValues?.district,
    initialValues?.city?.name,
    initialValues?.state,
    initialValues?.country,
    initialValues?.postalCode
  ].filter(Boolean).join(', ');

  const embedUrl = useMemo(() => {
    const url = new URL('https://www.google.com/maps/embed/v1/place');
    url.searchParams.set('key', GOOGLE_MAPS_EMBED_KEY);
    url.searchParams.set('q', formattedAddress);
    url.searchParams.set('zoom', ZOOM_LEVEL);
    return url;
  }, [formattedAddress]);

  const linkUrl = useMemo(() => {
    const url = new URL('https://www.google.com/maps');
    url.searchParams.set('q', formattedAddress);
    return url;
  }, [formattedAddress]);

  if (!formattedAddress) {
    return <span className='text-medium-gray'>Nenhum endereço registrado</span>;
  }

  if (initialValues?.streetName) {
    return (
      <div>
        <Tooltip
          placement='top'
          content='Abrir no mapa'
        >
          <ExternalLink
            href={linkUrl.toString()}
            target='_blank'
            rel='noreferrer'
          >
            {formattedAddress}
          </ExternalLink>
        </Tooltip>
        <iframe
          width='100%'
          height='120'
          className='rounded'
          allowFullScreen
          loading='lazy'
          referrerPolicy='no-referrer-when-downgrade'
          src={embedUrl}
        />
      </div>
    );
  }

  return <span>{formattedAddress}</span>;
}

EntityAddressContent.propTypes = propTypes;
EntityAddressContent.defaultProps = defaultProps;

export default EntityAddressContent;
