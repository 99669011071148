import { useInfiniteQuery } from 'react-query';
import { get } from '@/lib/fetch';

export const backlogItemsKeys = {
  infiniteAll: ['infinite-backlog-items'],
  infiniteLists: () => [...backlogItemsKeys.infiniteAll, 'list'],
  infiniteList: (funnelId, backlogId, params) => (
    [...backlogItemsKeys.infiniteLists(), funnelId, backlogId, params]
  )
};

export async function getBacklogItems(funnelId, backlogId, params = {}) {
  const { data } = await get(`/funnels/${funnelId}/backlogs/${backlogId}/items`, params);

  return data;
}

export function useInfiniteBacklogItems({
  funnelId,
  backlogId,
  params = {},
  config = {}
}) {
  return useInfiniteQuery(
    backlogItemsKeys.infiniteList(funnelId, backlogId, params),
    ({ pageParam: page = 1 }) => getBacklogItems(funnelId, backlogId, { ...params, page }),
    {
      ...config,
      getNextPageParam: (lastPage) => lastPage.meta.next
    }
  );
}
