import React from 'react';
import PropTypes from 'prop-types';
import Base from 'react-infinite-scroll-component';
import classnames from 'classnames';
import LoadSpinner from '@/components/LoadSpinner';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  length: PropTypes.number,
  loadItems: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  height: PropTypes.string
};

const defaultProps = {
  className: '',
  isLoading: false,
  height: '100%'
};

function InfiniteScroll({ children, ...props }) {
  return (
    <Base
      className={classnames('flex-grow-1', props.className)}
      dataLength={props.length}
      next={props.loadItems}
      hasMore={props.hasMore}
      height={props.height}
    >
      {children}

      {props.isLoading &&
        <LoadSpinner className={classnames(
          'd-flex',
          'justify-content-center',
          'align-items-center',
          'overflow-hidden',
          'flex-shrink-0',
          'text-primary'
        )} />
      }
    </Base>
  );
}

InfiniteScroll.propTypes = propTypes;
InfiniteScroll.defaultProps = defaultProps;

export default InfiniteScroll;
