import React from 'react';
import { useFormikContext } from 'formik';
import Icon from '@/components/Icon';
import { SummaryContent } from '@/feature/automation';
import { useFunnels, useStages } from '@/api';

function ModalSummary(props) {
  const { template } = props;
  const { values } = useFormikContext();
  /**
   * Dentro do formulário, ao selecionar funil e etapa, nos valores ficam apenas
   * os IDs.
   * Para gerar o resumo com os _nomes_ do funil e etapa, são utilizadas as
   * queries, que também são as fontes de dados dos selects, e por isso já
   * estarão em cache.
   */
  const { funnel_id: funnelId, stage_id: stageId } = values.trigger_params ?? {};
  const { funnel_id: actionFunnelId } = values.action_params ?? {};
  const { data: funnels } = useFunnels({});
  const { data: stages } = useStages({
    params: { funnel_id_eq: funnelId },
    config: { enabled: Boolean(funnelId) }
  });

  const funnel = funnels?.data.find((item) => item.id === funnelId);
  const stage = stages?.data.find((item) => item.id === stageId);
  const actionFunnel = funnels?.data.find((item) => item.id === actionFunnelId);

  const data = {
    active: true,
    triggerParams: {
      ...values.trigger_params,
      funnel_name: funnel?.name,
      stage_name: stage?.name
    },
    actionParams: {
      ...values.action_params,
      funnel_name: actionFunnel?.name
    }
  };

  return (
    <div>
      <div className='text-dark-gray mb-4'>
        Resumo
      </div>

      <div className='d-flex p-4 bg-light rounded mb-4'>
        <Icon
          name='lightning'
          size='md'
          className='text-primary align-self-center flex-shrink-0 me-3'
        />

        <h4 className='lh-base'>
          <SummaryContent data={data} template={template} tooltip />
        </h4>
      </div>
    </div>
  );
}

export default ModalSummary;
