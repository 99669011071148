import { useMediaQuery } from '@/hooks';
/*
 * Hook sobre screen size baseado no design system do agenor.
 * https://www.figma.com/file/NluquJtGqDTlbruIEVHx0B/NeoAgendor-DS---Componentes?node-id=936%3A1733
 */

export function useScreenSize() {
  return {
    // Small (>= 576px) até 767px
    isSM: useMediaQuery('(max-width: 767px)'),

    // Medium (>= 768px) até 991px
    isMD: useMediaQuery('(max-width: 991px)'),

    // Large (>= 992px) até 1199px
    isLG: useMediaQuery('(max-width: 1199px)'),

    // Extra large (>= 1200px) até 1399px
    isXL: useMediaQuery('(max-width: 1399px)')
  };
}
