import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useAlert } from 'react-alert';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import capitalize from 'lodash/capitalize';
import {
  useInfiniteDeals, useDeleteDeal,
  useUpdateDeal, useDealsCustomFields
} from '@/api';
import {
  setDealsViewCookie, useDocumentTitle,
  useStoredState, useMediaQuery
} from '@/hooks';
import APIErrorMessage from '@/components/APIErrorMessage';
import Button from '@/components/Button';
import ColumnsForm from '@/components/ColumnsForm';
import ConfirmationModal from '@/components/ConfirmationModal';
import DealsNav from '@/components/DealsNav';
import Dropdown from '@/components/Dropdown';
import ExternalLink from '@/components/ExternalLink';
import FunnelInfo from '@/components/FunnelInfo';
import Icon from '@/components/Icon';
import ImportButton from '@/components/ImportButton';
import ListEntity from '@/components/List/ListEntity';
import Main from '@/components/Main';
import TableForm from '@/components/TableForm';
import ToggleButton from '@/components/ToggleButton';
import ExportButton from '@/components/ExportButton';
import RequiredFieldsModal, { useRequiredFieldsModal } from '@/components/RequiredFieldsModal';
import { convertDateTimeString, formatUTCDate } from '@/date';
import { useAuth } from '@/lib/auth';
import { toCurrencyString } from '@/number';
import { dealsImportPath, legacyDealsMapUrl } from '@/routes';
import { isBlank, getListTypeByParams, getString } from '@/utils';
import { useTracking } from '@/lib/tracking';
import classnames from 'classnames';
import LeadsGenerationButton from '@/components/LeadsGenerationButton';
import ListForm from '@/components/ListForm';

const ENTITY = 'deal';
const ENTITY_I18N = getString(['models', ENTITY]);
const ENTITY_I18N_PLURAL = `${ENTITY_I18N}s`;
const SHOW_FROZEN_KEY = 'funnel.frozen.show';
const EXPORT_TOOLTIP = 'Exporte os negócios do funil em formato de planilha de Excel (.xlsx)';

function DealsIndex() {
  useDocumentTitle(capitalize(ENTITY_I18N_PLURAL));

  const { user } = useAuth();
  const tracker = useTracking();
  const [params, setParams] = useState({});
  const [paramsLoaded, setParamsLoaded] = useState(false);
  const columnsButtonRef = useRef(null);
  const filterButtonRef = useRef(null);
  const clearSearchRef = useRef(null);
  const clearFilterRef = useRef(null);

  useEffect(() => {
    setDealsViewCookie('list');
  }, []);

  useEffect(() => {
    tracker.trackNewDealListViewed({ user });
  }, [user]);

  const {
    data: deals,
    isLoading: isLoadingDeals,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = useInfiniteDeals({
    params,
    config: {
      enabled: paramsLoaded,
      staleTime: 1000
    }
  });

  const {
    data: customFields,
    isLoading: isLoadingCustomFields
  } = useDealsCustomFields();

  const dealsData = deals?.pages.flatMap((page) => page.data) || [];

  const initialValues = useMemo(
    () => prepareData(dealsData),
    [deals]
  );

  const exportButtonFilters = {
    sorts: params?.sorts,
    products_id: params?.products_id_in,
    forgotten: getForgottenFilters(params),
    started_at_date: params?.started_at_local_date_within,
    finished_at_date: params?.finished_at_local_date_within,
    created_at_date: params?.created_at_utc_date_within,
    users_id: params?.owner_user_id_in,
    states: params?.state_in,
    cities_id: params?.city_id_in,
    area_codes: params?.area_code_in,
    sectors_id: params?.sector_id_in,
    origins_id: params?.lead_origin_id_in,
    stage_id: params?.stage_id_in,
    teams_id: params?.team_id_in,
    custom_fields: params?.custom_fields,
    deal_status_id: params?.deal_status_id_eq,
    organization_id: params.organization_id_eq,
    person_id: params.person_id_eq,
    imports_id: params.import_id_in,
    categories_id: params.category_id_in,
    without_deals: params.without_deals,
    funnel_id: params?.funnel_id_in,
    loss_reason_id: params?.loss_reason_id_eq,
    ranking: params?.ranking_in,
    q: params?.q
  };

  const metadata = deals?.pages[0].meta ?? {};
  const totalItems = metadata.count ?? 0;
  const totalAmount = metadata.total_amount ?? 0;
  const totalCount = metadata.total_count ?? 0;
  const frozenAmount = metadata.frozen_amount ?? 0;
  const frozenCount = metadata.frozen_count ?? 0;

  const customFieldsList = customFields?.data || [];
  const isLoading = isLoadingDeals || isLoadingCustomFields;

  const alert = useAlert();
  const [dealId, setDealId] = useState();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [columns, setColumns] = useState({});
  const [columnsLoaded, setColumnsLoaded] = useState(false);
  const deleteDealMutation = useDeleteDeal();
  const updateDealMutation = useUpdateDeal();
  const isSmall = useMediaQuery('(max-width: 574px)');
  const expandedFunnelHeader = useMediaQuery('(min-width: 1280px)');
  const showImportExportButtons = useMediaQuery('(min-width: 1366px)');
  const hasAccess = user.account.legacyPro || user.account.performanceOrHigher;

  const {
    store: setShowFrozen,
    value: showFrozen
  } = useStoredState(SHOW_FROZEN_KEY, false);

  const onClickDeleteDeal = (selectedDealId) => {
    setDealId(selectedDealId);
    setShowDeleteConfirm(true);
  };

  const onCancelDelete = () => {
    setDealId();
    setShowDeleteConfirm(false);
  };

  const onConfirmDelete = () => {
    setIsDeleting(true);

    deleteDealMutation.mutate({ dealId }, {
      onError: (err) => {
        setIsDeleting(false);
        setDealId();
        setShowDeleteConfirm(false);
        alert.show(
          <APIErrorMessage err={err} resource={ENTITY} action='delete' />,
          { variant: 'danger', timeout: 5000 }
        );
      },
      onSuccess: () => {
        setIsDeleting(false);
        setDealId();
        setShowDeleteConfirm(false);
        alert.show(
          `${capitalize(ENTITY_I18N)} removido com sucesso!`,
          { variant: 'success', timeout: 5000 }
        );
      }
    });
  };

  const {
    check: checkRequiredFields,
    ...requiredFieldsModal
  } = useRequiredFieldsModal();
  const onUpdateDeal = async (deal, updateParams, done = () => {}) => {
    const funnel = deal.stage?.funnel;
    if (funnel?.hasRequiredFields && hasAccess) {
      const dealHasRequiredFields = await checkRequiredFields(deal, {
        funnel_id: funnel.id,
        ...updateParams
      });

      if (dealHasRequiredFields) {
        return;
      }
    }

    updateDealMutation.mutate({ dealId: deal.id, params: updateParams }, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource={ENTITY} action='update' />,
          { variant: 'danger', timeout: 5000 }
        );
        done(err, {});
      },
      onSuccess: () => {
        alert.show(
          `${capitalize(ENTITY_I18N)} atualizado com sucesso!`,
          { variant: 'success', timeout: 5000 }
        );
        done(null, { user, tracker });
      }
    });
  };

  const onChangeColumns = (filteredColumns) => {
    const hasChanged = !isEmpty(columns) && !isEqual(columns, filteredColumns);
    if (hasChanged) {
      alert.show(
        'Colunas atualizadas com sucesso!',
        { variant: 'success', timeout: 5000 }
      );
    }

    setColumns(filteredColumns);
    setColumnsLoaded(true);
  };

  const onChangeFilters = useCallback((filterParams) => {
    setParams(filterParams);
    setParamsLoaded(!isEmpty(filterParams));
  }, [params, paramsLoaded]);

  const clearSearch = () => clearSearchRef?.current?.click?.();
  const clearFilter = () => clearFilterRef?.current?.click?.();
  const openColumnsForm = () => columnsButtonRef?.current?.click?.();
  const openFiltersForm = () => filterButtonRef?.current?.click?.();

  return (
    <ListForm
      type={ENTITY}
      onSubmit={onChangeFilters}
      customFields={customFieldsList}
      filterParams={params}
    >
      {({ values, debouncedSubmitForm }) => (
        <Main fluid flex overflow={false} className='flexible-height'>
          <Container fluid className='d-flex flex-column flex-grow-1 flexible-height px-0 pb-2'>
            <div
              className={classnames(
                'd-flex',
                'position-relative',
                'flex-shrink-0',
                'border-1',
                'border-bottom',
                'border-light-gray',
                'mb-4',
                'px-4',
                'justify-content-between'
              )}
            >
              <FunnelInfo
                className='p-4'
                name={capitalize(ENTITY_I18N_PLURAL)}
                totalAmount={totalAmount}
                totalCount={totalCount}
                isLoading={isLoading}
                frozenAmount={frozenAmount}
                frozenCount={frozenCount}
                showFrozen={showFrozen}
              />
              <LeadsGenerationButton className='me-5' />
            </div>

            <Row className='mx-4'>
              <Col className={classnames(
                isSmall
                  ? 'flex-wrap'
                  : 'd-flex align-items-center justify-content-between'
              )}>
                <div className='d-flex align-items-start mb-4'>
                  <TableForm
                    type={ENTITY}
                    columns={columns}
                    debouncedSubmitForm={debouncedSubmitForm}
                    openColumnsForm={openColumnsForm}
                    filterButtonRef={filterButtonRef}
                    clearSearchRef={clearSearchRef}
                    clearFilterRef={clearFilterRef}
                    customFields={customFieldsList}
                    isLoading={isLoading}
                  />
                  <ColumnsForm
                    className='ms-2 me-3 text-nowrap'
                    type={ENTITY}
                    onSubmit={onChangeColumns}
                    buttonRef={columnsButtonRef}
                    isLoading={isLoading}
                    customFields={customFieldsList}
                  />
                </div>

                <div className='d-flex align-items-center mb-4'>
                  <ToggleButton
                    id='show_frozen'
                    value='1'
                    checked={showFrozen}
                    type='checkbox'
                    tooltip={showFrozen
                      ? 'Negócios congelados estão visíveis. Clique para escondê-los.'
                      : 'Negócios congelados estão invisíveis. Clique para exibi-los.'
                    }
                    onChange={() => setShowFrozen(!showFrozen)}
                    className='p-1 rounded me-3'
                  >
                    <Icon name='frozen' />
                  </ToggleButton>

                  <div className='d-none d-md-flex align-items-center mx-0'>
                    <DealsNav />

                    {
                      showImportExportButtons
                        ? (
                          <>
                            <ImportButton
                              path={dealsImportPath()}
                              entity={ENTITY}
                              entityNamePlural={ENTITY_I18N_PLURAL}
                            />

                            <ExportButton
                              variant='transparent-light'
                              className='me-2'
                              tooltipText={EXPORT_TOOLTIP}
                              exportEntity={ENTITY}
                              filters={exportButtonFilters}
                            />
                          </>
                        )
                        : null
                    }
                  </div>

                  <Button
                    href='/deals/new'
                    aria-label={`Adicionar ${ENTITY_I18N}`}
                    id='add-deal'
                    className='text-nowrap me-2'
                  >
                    <Icon name='add' className='me-2' />
                    <span>
                        Adicionar {' '}
                      <span className='d-md-inline d-none'>{ENTITY_I18N}</span>
                    </span>
                  </Button>

                  {
                    expandedFunnelHeader && showImportExportButtons
                      ? null
                      : (
                        <Dropdown align='end' className='me-3'>
                          <Dropdown.Toggle
                            id='menu-toggle'
                            variant='lighter-gray'
                          >
                            <span>...</span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            renderOnMount
                            className='min-width-5 z-index-1090'
                          >
                            <Dropdown.Item
                              href='/'
                              className='text-dark-gray fw-bold d-md-none'
                            >
                              <Icon name='funnel' className='me-2 text-primary' />
                              <span>Visualizar funil</span>
                            </Dropdown.Item>

                            <Dropdown.Item
                              href={legacyDealsMapUrl()}
                              as={ExternalLink}
                              className='text-dark-gray fw-bold d-md-none'
                            >
                              <Icon name='map' className='me-2 text-primary' />
                              <span>Visualizar mapa</span>
                            </Dropdown.Item>

                            <ImportButton
                              as={Dropdown.Item}
                              iconClassName='me-2 text-primary'
                              buttonClassNames='d-flex fw-bold'
                              spanClassName='text-dark-gray'
                              entity={ENTITY}
                              entityNamePlural={ENTITY_I18N_PLURAL}
                              path={dealsImportPath()}
                              buttonText={`Importar ${ENTITY_I18N_PLURAL}`}
                            />

                            <ExportButton
                              as={Dropdown.Item}
                              href=''
                              className='text-primary fw-bold'
                              tooltipText={EXPORT_TOOLTIP}
                              exportEntity={ENTITY}
                              filters={exportButtonFilters}
                              buttonText={`Exportar ${ENTITY_I18N_PLURAL}`}
                              spanClassName='text-dark-gray'
                            />
                          </Dropdown.Menu>
                        </Dropdown>
                      )
                  }
                </div>
              </Col>
            </Row>

            <Row className='d-flex flex-column flex-grow-1 flexible-height px-6'>
              <Col className='d-flex flex-column flex-grow-1 flexible-height'>
                <ListEntity
                  entity={ENTITY}
                  items={initialValues}
                  customFields={customFieldsList}
                  isLoading={isLoading}
                  isFetchingNextPage={isFetchingNextPage}
                  fetchNextPage={fetchNextPage}
                  hasNextPage={hasNextPage}
                  totalItems={totalItems}
                  columns={columns}
                  columnsLoaded={columnsLoaded}
                  onDelete={onClickDeleteDeal}
                  onUpdate={onUpdateDeal}
                  onClearSearch={clearSearch}
                  onClearFilter={clearFilter}
                  onOpenFilter={openFiltersForm}
                  variant={getListTypeByParams(values)}
                />
              </Col>
            </Row>
          </Container>
          <ConfirmationModal
            confirmationText='Excluir'
            show={showDeleteConfirm}
            onConfirm={onConfirmDelete}
            onHide={onCancelDelete}
            disabled={isDeleting}
          >
            <ConfirmationModal.Title>
            Deseja excluir este {ENTITY_I18N}?
            </ConfirmationModal.Title>

            <ConfirmationModal.Info>
            Todo o histórico de relacionamento será perdido.
            </ConfirmationModal.Info>
          </ConfirmationModal>

          <RequiredFieldsModal { ...requiredFieldsModal } />
        </Main>
      )}
    </ListForm>
  );
}

function getForgottenFilters(params) {
  return [params.next_task, params.latest_activity].filter(Boolean).join(',');
}

function formatCurrency(value) {
  return isBlank(value) ? null : toCurrencyString(value);
}

function prepareData(deals) {
  if (deals?.length) {
    return deals.map((deal) => {
      const { startedAt, finishedAt, createdAt, updatedAt, value } = deal;

      return {
        ...deal,
        valueRaw: value,
        finishedAtRaw: finishedAt,
        value: formatCurrency(value),
        startedAt: formatUTCDate(startedAt),
        finishedAt: formatUTCDate(finishedAt),
        createdAt: convertDateTimeString(createdAt),
        updatedAt: convertDateTimeString(updatedAt),
        importedAt: convertDateTimeString(deal?.import?.importedAt)
      };
    });
  }
  return [];
}

export default DealsIndex;
