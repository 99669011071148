import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import APIErrorMessage from '@/components/APIErrorMessage';
import CreateStageForm from '@/components/CreateStageForm';
import Authorization from '@/components/Authorization';
import { canCreateStage } from '@/policies';
import { getValidationErrors } from '@/utils';
import { useCreateStage } from '@/api';
import { useTracking } from '@/lib/tracking';
import { useAuth } from '@/lib/auth';

const propTypes = {
  totalCount: PropTypes.number.isRequired,
  funnelId: PropTypes.number.isRequired
};

const defaultProps = {
};

function FunnelStagesNew({ totalCount, funnelId }) {
  const [showForm, setShowForm] = useState(false);
  const mutation = useCreateStage({ funnelId });
  const alert = useAlert();
  const tracker = useTracking();
  const { user } = useAuth();

  const onSubmit = (formData, { setFieldError }) => {
    mutation.mutate(formData, {
      onSuccess: () => {
        setShowForm(false);

        tracker.trackFunnelStageCreated({ user });
        alert.show(
          'Etapa adicionada com sucesso.',
          { variant: 'success', timeout: 5000 }
        );
      },
      onError: (err) => {
        const errors = getValidationErrors(err, 'stage');

        Object.entries(errors).forEach(([key, message]) => {
          setFieldError(key, message);
        });

        alert.show(
          <APIErrorMessage err={err} resource='stage' action='create' />,
          { variant: 'danger' }
        );
      }
    });
  };

  const onCancel = () => setShowForm(false);

  return (
    showForm
      ? (
        <CreateStageForm
          className='stages-new my-4'
          onSubmit={onSubmit}
          onCancel={onCancel}
          nextOrder={totalCount + 1}
        />
      )
      : (
        <Authorization policy={canCreateStage} data={{ totalCount }}>
          <Button
            className='my-4 py-3 px-5'
            onClick={() => setShowForm(true)}
          >
            <Icon name='add' className='me-2' />
            Adicionar etapa
          </Button>
        </Authorization>
      )
  );
}

FunnelStagesNew.propTypes = propTypes;
FunnelStagesNew.defaultProps = defaultProps;

export default FunnelStagesNew;
