import React from 'react';
import { applyMask } from '@/masks';
import PropTypes from 'prop-types';

const propTypes = {
  accountData: PropTypes.shape({
    cpfCnpj: PropTypes.string,
    legalName: PropTypes.string,
    address: PropTypes.shape({
      postalCode: PropTypes.string,
      streetName: PropTypes.string,
      streetNumber: PropTypes.number,
      district: PropTypes.string,
      state: PropTypes.string,
      city: PropTypes.shape({
        name: PropTypes.string
      })
    })
  }).isRequired
};

function Pix({ accountData }) {
  const address = accountData.address;

  const formattedCpfCnpj = applyMask(accountData.cpfCnpj, 'cpf_cnpj');
  const formattedPostalCode = applyMask(address.postalCode, 'postalCode');

  return (
    <div className='text-small'>
      <div className='fw-bold'>Dados de faturamento</div>

      <div className='text-dark-gray'>
        <div className='fw-bold'>{accountData.legalName}</div>

        <div>{formattedCpfCnpj}</div>

        <div>
          <div>
            {address.streetName}, {address.streetNumber} - {address.district}
          </div>
          <div>
            {formattedPostalCode}, {address.city.name} - {address.state}
          </div>
        </div>
      </div>

      <hr className='w-100 my-3 text-dark-gray' />

      <span className='fw-bold mb-1'>Informações importantes:</span>

      <ul className='text-dark-gray'>
        <li>Clique em &quot;Avançar&quot; para gerar um código e QR code.</li>

        <li>O código Pix é válido por 2 horas.</li>

        <li>Seu plano será liberado após a aprovação do pagamento.</li>
      </ul>
    </div>
  );
}

Pix.propTypes = propTypes;

export default Pix;
