/* global YT */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@/components/Button';
import Card from '@/components/Card';
import Collapse from '@/components/Collapse';
import CollapseToggle from '@/components/CollapseToggle';
import ExternalLink from '@/components/ExternalLink';
import Icon from '@/components/Icon';

import { helpUrl, sampleImportFileName, sampleImportUrl } from '@/routes';
import { useTracking } from '@/lib/tracking';
import { useAuth } from '@/lib/auth';

const propTypes = {
  entity: PropTypes.oneOf([
    'organizations',
    'people',
    'deals',
    'activities',
    'products'
  ]).isRequired,
  defaultOpen: PropTypes.bool.isRequired
};

function ImportGuide({ entity, defaultOpen }) {
  const [open, onToggle] = useState(defaultOpen);

  const tracker = useTracking();
  const { user } = useAuth();

  const sampleUrl = sampleImportUrl(entity);
  const sampleFileName = sampleImportFileName(entity);
  const onClickDownload = () => {
    tracker.trackImportGuideSampleDownloadClicked({ user });
  };

  const helpCenterUrl = helpUrl('import-export');
  const onClickHelpCenter = () => {
    tracker.trackImportGuideHelpCenterClicked({ user });
  };

  useEffect(() => {
    // https://developers.google.com/youtube/iframe_api_reference
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window.onYouTubeIframeAPIReady = () => {
      // eslint-disable-next-line no-new
      new YT.Player('import-guide-video-frame', {
        events: {
          'onStateChange': (event) => {
            if (event.data === YT.PlayerState.PLAYING) {
              tracker.trackImportGuideVideoPlayed({ user });
            }
          }
        }
      });
    };
  }, []);

  return (
    <Card body border='light' className='p-3'>
      <div className='d-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center gap-2'>
          <Card bg='light' className='p-1 lh-1 border-0'>
            <Icon name='help' size='sm' />
          </Card>

          <h4>
            Guia de importação do Agendor
          </h4>
        </div>

        <CollapseToggle
          open={open}
          openIcon='hidden'
          openLabel='Esconder'
          closedIcon='visible'
          closedLabel='Exibir'
          onToggle={() => onToggle(!open)}
        />
      </div>

      <Collapse in={open}>
        <div>
          <div className='mt-4 d-flex gap-3'>
            <Card className='border-0 overflow-hidden flex-grow-1'>
              <iframe
                id='import-guide-video-frame'
                width='100%'
                height='100%'
                src='https://www.youtube.com/embed/XCbn2yuOY3I?si=oKODStn7b0aCJ47u&enablejsapi=1'
                title='YouTube video player'
                allow=
                  'accelerometer; autoplay; clipboard-write; encrypted-media; picture-in-picture'
                allowFullScreen
              >
              </iframe>
            </Card>

            <div className='d-flex flex-column gap-3'>
              <Card body border='light' className='p-3'>
                <Card.Title>
                  Planilha de exemplo
                </Card.Title>
                <Card.Text>
                  Baixe a planilha modelo para te ajudar na importação
                </Card.Text>

                <Button
                  variant='light-blue'
                  size='sm'
                  className='text-primary'
                  as={ExternalLink}
                  href={sampleUrl}
                  download={sampleFileName}
                  onClick={onClickDownload}
                >
                  Baixar planilha
                </Button>
              </Card>

              <Card body border='light' className='p-3'>
                <Card.Title>
                  Saiba mais
                </Card.Title>
                <Card.Text>
                  Veja o passo a passo sobre importação
                </Card.Text>

                <Button
                  variant='light'
                  size='sm'
                  as={ExternalLink}
                  href={helpCenterUrl}
                  target='_blank'
                  rel='noopener noreferrer'
                  onClick={onClickHelpCenter}
                >
                  Central de ajuda
                </Button>
              </Card>
            </div>
          </div>
        </div>
      </Collapse>
    </Card>
  );
}

ImportGuide.propTypes = propTypes;

export default ImportGuide;
