import React from 'react';
import Icon from '@/components/Icon';
import { SummaryContent, TemplateCard } from '@/feature/automation';
import Keyword from '@/feature/automation/Keyword';
import { extractComponentText } from '@/utils';

export const cardsData = {
  on_deal_stage_arrived_schedule_activity: {
    triggerIdentifier: (<span>Quando um <Keyword>negócio</Keyword> chegar a uma etapa</span>),
    actionIdentifier: 'Agendar uma atividade',
    triggerIcon: 'funnel',
    actionIcon: 'task',
    triggerForm: 'funnel',
    triggerDependant: 'stage',
    triggerValidation: 'funnel_stage',
    actionForm: 'activity',
    triggerName: 'on_deal_stage_arrived',
    actionName: 'schedule_activity',
    helpPath: 'task-automation'
  },
  on_deal_created_schedule_activity: {
    triggerIdentifier: (<span>Quando um <Keyword>negócio</Keyword> for adicionado</span>),
    actionIdentifier: 'Agendar uma atividade',
    triggerIcon: 'deal',
    actionIcon: 'task',
    triggerForm: 'funnel',
    triggerValidation: 'funnel',
    actionForm: 'activity',
    triggerName: 'on_deal_created',
    actionName: 'schedule_activity',
    helpPath: 'task-automation'
  },
  on_person_created_schedule_activity: {
    triggerIdentifier: (<span>Quando uma <Keyword>pessoa</Keyword> for adicionada</span>),
    actionIdentifier: 'Agendar uma atividade',
    triggerIcon: 'person',
    actionIcon: 'task',
    actionForm: 'activity',
    triggerName: 'on_person_created',
    actionName: 'schedule_activity',
    helpPath: 'task-automation'
  },
  on_organization_created_schedule_activity: {
    triggerIdentifier: (<span>Quando uma <Keyword>empresa</Keyword> for adicionada</span>),
    actionIdentifier: 'Agendar uma atividade',
    triggerIcon: 'organization',
    actionIcon: 'task',
    actionForm: 'activity',
    triggerName: 'on_organization_created',
    actionName: 'schedule_activity',
    helpPath: 'task-automation'
  },
  on_deal_won_duplicate_deal: {
    triggerIdentifier: (<span>Quando um <Keyword>negócio</Keyword> for ganho</span>),
    actionIdentifier: 'Duplicá-lo para outro funil',
    triggerIcon: 'won-deal',
    actionIcon: 'copy',
    triggerForm: 'funnel',
    triggerValidation: 'funnel',
    actionForm: 'action_funnel',
    triggerName: 'on_deal_won',
    actionName: 'duplicate_deal',
    helpPath: 'deal-automation'
  },
  on_deal_lost_duplicate_deal: {
    triggerIdentifier: (<span>Quando um <Keyword>negócio</Keyword> for perdido</span>),
    actionIdentifier: 'Duplicá-lo para outro funil',
    triggerIcon: 'lost-deal',
    actionIcon: 'copy',
    triggerForm: 'funnel',
    triggerValidation: 'funnel',
    actionForm: 'action_funnel',
    triggerName: 'on_deal_lost',
    actionName: 'duplicate_deal',
    helpPath: 'deal-automation'
  },
  on_deal_stage_arrived_send_email: {
    triggerIdentifier: (<span>Quando um <Keyword>negócio</Keyword> chegar a uma etapa</span>),
    actionIdentifier: 'Enviar um e-mail',
    triggerIcon: 'funnel',
    actionIcon: 'email-sent',
    triggerForm: 'funnel',
    triggerDependant: 'stage',
    triggerValidation: 'funnel_stage',
    actionForm: 'email',
    triggerName: 'on_deal_stage_arrived',
    actionName: 'send_email',
    helpPath: 'email-automation'
  },
  on_deal_won_send_email: {
    triggerIdentifier: (<span>Quando um <Keyword>negócio</Keyword> for ganho</span>),
    actionIdentifier: 'Enviar um e-mail',
    triggerIcon: 'won-deal',
    actionIcon: 'email-sent',
    triggerForm: 'funnel',
    triggerValidation: 'funnel',
    actionForm: 'email',
    triggerName: 'on_deal_won',
    actionName: 'send_email',
    helpPath: 'email-automation'
  },
  on_deal_lost_send_email: {
    triggerIdentifier: (<span>Quando um <Keyword>negócio</Keyword> for perdido</span>),
    actionIdentifier: 'Enviar um e-mail',
    triggerIcon: 'lost-deal',
    actionIcon: 'email-sent',
    triggerForm: 'funnel',
    triggerValidation: 'funnel',
    actionForm: 'email',
    triggerName: 'on_deal_lost',
    actionName: 'send_email',
    helpPath: 'email-automation'
  }
};

export function getAutomationName(template) {
  const { triggerIdentifier, actionIdentifier } = template;
  const triggerIdentifierText = extractComponentText(triggerIdentifier);

  return `${triggerIdentifierText} → ${actionIdentifier}`;
}

function AutomationComponent(props) {
  const { type, data, className, component } = props;
  const template = cardsData[type];

  switch (component) {
    case 'icon':
      return (
        <Icon name={template?.triggerIcon} className='text-dark-gray'/>
      );
    case 'summaryContent':
      return (
        <SummaryContent data={data} template={template} className={className} />
      );
    default:
      return (
        <TemplateCard template={template}/>
      );
  }
}

export default AutomationComponent;
