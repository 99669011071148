import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '@/components/Icon';
import { toCurrencyString } from '@/number';

const propTypes = {
  data: PropTypes.object
};

function PaymentSummary({ data }) {
  return (
    <div className={classnames(
      'w-100',
      'text-small',
      'bg-light',
      'p-6',
      'rounded'
    )}>
      <h4>Resumo</h4>
      <div className={classnames(
        'd-flex',
        'align-items-center',
        'mb-2',
        'mt-1',
        'py-2',
        'ps-2',
        'pe-8',
        'bg-white',
        'rounded',
        'text-dark-gray'
      )}>
        <div>
          <Icon name='info' />
        </div>
        <div className='ms-3'>
            A nota fiscal será gerada e enviada para o seu e-mail
            automaticamente em até 24 horas após a confirmação do pagamento
        </div>
      </div>

      <div className='mt-4 d-flex flex-column align-items-baseline justify-content-between mb-1'>

        <div className='d-flex align-items-baseline justify-content-between mb-1 w-100'>
          <div className='fw-bold text-dark-gray me-8 '>Quantidade de leads</div>

          <div className='ms-2'>{data.quantity}</div>
        </div>

        <div className='d-flex align-items-baseline justify-content-between mb-1 w-100'>
          <div className='fw-bold text-dark-gray me-8'>Valor por lead</div>

          <div className='ms-2'>
            <span>{toCurrencyString(data.unityValue)}</span>
          </div>
        </div>
        <div className='d-flex align-items-baseline justify-content-between mb-1 w-100'>
          <div className='fw-bold text-dark-gray me-8'>Subtotal</div>

          <div className='ms-2'>{toCurrencyString(data.totalValue)}</div>
        </div>

        <hr className='mt-3 mb-4 opacity-100 w-100' />
        <div className='mt-1 d-flex align-items-baseline justify-content-between mb-1 w-100'>
          <h4 className='text-dark-gray'>Valor total</h4>
          <h5>{toCurrencyString(data.totalValue)}</h5>
        </div>
      </div>
    </div>
  );
}

PaymentSummary.propTypes = propTypes;

export default PaymentSummary;
