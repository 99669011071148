import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import CreateFunnelForm from '@/components/CreateFunnelForm';
import APIErrorMessage from '@/components/APIErrorMessage';
import Authorization from '@/components/Authorization';
import { canCreateFunnel } from '@/policies';
import { getValidationErrors } from '@/utils';
import { useCreateFunnel } from '@/api';
import { useTracking } from '@/lib/tracking';
import { useAuth } from '@/lib/auth';

const propTypes = {
  totalCount: PropTypes.number.isRequired
};

const defaultProps = {
};

function FunnelsNew({ totalCount }) {
  const [showForm, setShowForm] = useState(false);
  const mutation = useCreateFunnel();
  const alert = useAlert();
  const tracker = useTracking();
  const { user } = useAuth();

  const onSubmit = (formData, { setFieldError, setSubmitting }) => {
    mutation.mutate(formData, {
      onSuccess: () => {
        setShowForm(false);

        tracker.trackFunnelCreated({ user });
        alert.show(
          'Funil adicionado com sucesso.',
          { variant: 'success', timeout: 5000 }
        );
      },
      onError: (err) => {
        setSubmitting(false);

        const errors = getValidationErrors(err, 'funnel');

        Object.entries(errors).forEach(([key, message]) => {
          setFieldError(key, message);
        });

        alert.show(
          <APIErrorMessage err={err} resource='funnel' action='create' />,
          { variant: 'danger' }
        );
      }
    });
  };

  const onCancel = () => setShowForm(false);

  return (
    showForm
      ? (
        <CreateFunnelForm
          className='funnels-new my-4'
          onSubmit={onSubmit}
          onCancel={onCancel}
          nextOrder={totalCount + 1}
        />
      )
      : (
        <Authorization policy={canCreateFunnel} data={{ totalCount }}>
          <Button
            className='my-4 py-3 px-5'
            size='md'
            onClick={() => setShowForm(true)}
          >
            <Icon name='add' className='me-2' />
            Adicionar funil
          </Button>
        </Authorization>
      )
  );
}

FunnelsNew.propTypes = propTypes;
FunnelsNew.defaultProps = defaultProps;

export default FunnelsNew;
