import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ConfirmationStep from '@/components/Imports/ConfirmationStep';
import StepperHeader from '@/components/StepperHeader';
import { MapDataStep } from '@/components/Imports';
import UploadFileStep from '@/components/Imports/UploadFileStep';
import Stepper from '@/components/Stepper';
import { importPath } from '@/routes';
import { usePeopleCustomFields, useOrganizationCustomFields, useDealsCustomFields } from '@/api';

const propTypes = {
  nextStepButtonRef: PropTypes.object.isRequired,
  previousStepButtonRef: PropTypes.object.isRequired,
  entity: PropTypes.string,
  onChange: PropTypes.func
};

const CUSTOM_FIELDS_QUERIES = {
  organizations: useOrganizationCustomFields,
  people: usePeopleCustomFields,
  products: () => ({ data: { data: [] } }),
  activities: () => ({ data: { data: [] } }),
  deals: useDealsCustomFields
};

function ImportSteps(props) {
  const {
    nextStepButtonRef,
    previousStepButtonRef,
    resource,
    onChange,
    file,
    setFile
  } = props;

  const navigate = useNavigate();

  const [currentMappedData, setCurrentMappedData] = useState();

  const useCustomFields = CUSTOM_FIELDS_QUERIES[resource];

  const { data: { data: customFields = [] } = {} } = useCustomFields();

  const customFieldsColumns = getCustomFieldsColumns(customFields);

  const handleUploadSuccess = (newFile) => {
    setFile(newFile);
    setCurrentMappedData();
  };

  const handleConfirmationSuccess = (id) => {
    navigate(importPath(resource, id));
  };

  return (
    <>
      <Stepper
        Header={StepperHeader}
        onChange={onChange}
        headerClassName='d-flex align-items-center fw-bold mt-6'
        headerLabels={[
          'Carregar arquivo',
          'Mapear campos',
          'Visualizar e concluir'
        ]}
      >
        <UploadFileStep
          nextStepButtonRef={nextStepButtonRef}
          resource={resource}
          file={file}
          onSuccess={handleUploadSuccess}
        />

        <MapDataStep
          nextStepButtonRef={nextStepButtonRef}
          previousStepButtonRef={previousStepButtonRef}
          initialData={currentMappedData}
          resource={resource}
          file={file}
          customFieldsColumns={customFieldsColumns}
          onSubmit={setCurrentMappedData}
        />

        <ConfirmationStep
          mappedData={currentMappedData}
          file={file}
          nextStepButtonRef={nextStepButtonRef}
          previousStepButtonRef={previousStepButtonRef}
          resource={resource}
          customFieldsColumns={customFieldsColumns}
          onSuccess={handleConfirmationSuccess}
        />
      </Stepper>
    </>
  );
}

function getCustomFieldsColumns(customFields) {
  return customFields.reduce((result, customField) => {
    result[`custom_field.${customField.identifier}`] = {
      name: customField.name,
      required: false
    };

    return result;
  }, []);
}

ImportSteps.propTypes = propTypes;

export default ImportSteps;
