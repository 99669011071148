import React from 'react';
import { legacyDealUrl, legacyOrganizationUrl, legacyPersonUrl } from '@/routes';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';

import ExternalLink from '@/components/ExternalLink';
import Icon from '@/components/Icon';
import Tooltip from '@/components/Tooltip';

function LegacyButton({ id, entityType }) {
  const tracker = useTracking();
  const { user } = useAuth();

  const legacyUrl = {
    organization: legacyOrganizationUrl(id, true),
    person: legacyPersonUrl(id, true),
    deal: legacyDealUrl(id, true)
  };

  const handleClick = () => {
    if (entityType === 'deal') {
      tracker.trackBackToLegacyModal({ user });
    }
  };

  return (
    <Tooltip content='Clique aqui para usar a versão anterior'>
      <ExternalLink
        className='fw-bold'
        onClick={handleClick}
        href={legacyUrl[entityType]}
      >
        <Icon name='close-beta' className='me-1' />
        Voltar para a versão anterior
      </ExternalLink>
    </Tooltip>
  );
}

export default LegacyButton;
