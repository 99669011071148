import React, { useMemo, useState } from 'react';
import { useAlert } from 'react-alert';
import { helpUrl } from '@/routes';
import { useCurrentAccount,
  useUpdateCurrentAccount, useUpdateVoipToggleAccount, voipKeys } from '@/api';
import cellphoneRinging from 'images/cellphone-ringing.png';
import UpdateCpfCnpjForm from '@/feature/voip/UpdateCpfCnpjForm';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Popover from '@/components/Popover';
import Tooltip from '@/components/Tooltip';
import ExternalLink from '@/components/ExternalLink';
import { VoipButton } from '@/components/EntityModal/Deal/DetailsVoip';
import APIErrorMessage from '@/components/APIErrorMessage';
import Modal from '@/components/Modal';
import LoadingButton from '@/components/LoadingButton';
import { useQueryClient } from 'react-query';

const ACTIVATION_DELAY = 3000;

function VoipEnabled({ voip, user, onCreate }) {
  const alert = useAlert();
  const queryClient = useQueryClient();
  const [showCpfCnpjModal, setShowCpfCnpjModal] = useState(false);
  const [isCreated, setIsCreated] = useState(voip?.created);
  const [isEnabled, setIsEnabled] = useState(voip?.enabled);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: currentAccountData } = useCurrentAccount();
  const accountData = useMemo(() => currentAccountData?.data, [JSON.stringify(currentAccountData)]);
  const voipToggleAccountMutation = useUpdateVoipToggleAccount();
  const currentAccountMutation = useUpdateCurrentAccount();
  const isAdmin = user?.admin;
  const isPerformanceOrHigher = user?.account?.performanceOrHigher;
  const submitting = voipToggleAccountMutation.isLoading ||
  currentAccountMutation.isLoading || isSubmitting;

  const onChangeActive = async () => {
    try {
      const data = await voipToggleAccountMutation.mutateAsync({});
      setIsCreated(data?.created);
      setIsEnabled(data?.enabled);
      if (data?.created) {
        await queryClient.invalidateQueries(voipKeys.verify);
        const alertLabel = isEnabled ? 'desativado' : 'ativado';
        alert.show(
          `Telefone virtual ${alertLabel} com sucesso!`,
          { variant: 'success', timeout: 5000 }
        );
        setShowCpfCnpjModal(false);
        onCreate?.();
      } else {
        alert.show(
          `Falha ao alterar estado do telefone
          virtual.${data.error ? ` Motivo: ${data.error}` : ''}`,
          { variant: 'danger', timeout: 5000 }
        );
      }
    } catch (err) {
      alert.show(
        <APIErrorMessage err={err} resource='modules/voip' action='update' />,
        { variant: 'danger', timeout: 5000 }
      );
    }
  };

  const onSubmitCpfCnpj = (values) => {
    setShowCpfCnpjModal(true);
    setIsSubmitting(true);
    setTimeout(async () => {
      try {
        await currentAccountMutation.mutateAsync(values);
        await onChangeActive();
        setIsSubmitting(false);
      } catch (err) {
        alert.show(
          <APIErrorMessage err={err} />,
          { variant: 'danger', timeout: 5000 }
        );
        setIsSubmitting(false);
      }
    }, ACTIVATION_DELAY);
  };

  const handleChangeActive = () => {
    if (!accountData?.cpfCnpj && !isEnabled && !showCpfCnpjModal) {
      setShowCpfCnpjModal(true);
    } else {
      onChangeActive();
    }
  };

  if (!isAdmin) {
    return (
      <div>
        <Tooltip
          className='text-small'
          placement='top'
          content='O módulo de telefone virtual está desativado.
          Peça para o administrador da conta ativá-lo.'
        >
          <div style={{ width: '100%' }}>
            <VoipButton />
          </div>
        </Tooltip>
      </div>
    );
  }

  return (
    <>
      <Modal
        show={showCpfCnpjModal && !isCreated}
        onHide={() => setShowCpfCnpjModal(false)}
        size='md'
        bodyClassName='p-8'
        backdrop='static'
      >
        <div className='d-flex flex-column'>
          { isSubmitting
            ? (
              <>
                <img src={cellphoneRinging} className='width-4 align-self-center mb-4' />
                <p className='h2 align-self-center mb-2'>O telefone virtual está sendo ativado</p>
                <p className='align-self-center'>
                Adicionando R$ 25 de créditos para você experimentar...
                </p>
              </>
            )
            : (
              <>
                <img src={cellphoneRinging} className='width-4 align-self-center mb-4' />
                <p className='h2 align-self-center mb-2'>Ative o telefone virtual na sua conta</p>
                <p className='align-self-center'>
                Preencha os dados abaixo para finalizar a ativação.</p>
              </>
            )
          }
          <UpdateCpfCnpjForm
            accountData={accountData}
            onSubmit={onSubmitCpfCnpj}
            onCancel={() => setShowCpfCnpjModal(false)}
            isSubmitting={submitting}
          />
        </div>
      </Modal>
      <Popover
        placement='bottom'
        showArrow
        className='my-1'
        content={({ onHide }) => (
          <div onClose={onHide}>
            <p className='fw-bold mb-4'>Você ganhou R$ 25 para fazer ligações!</p>
            {
              isPerformanceOrHigher
                ? (
                  <div className='justify content-end me-2 mb-3 d-flex text-purple fw-bold'>
                    <Icon name='ai' size='sm' className='me-1' />
                    Com ChatGPT
                  </div>
                )
                : null
            }
            <div className='d-flex mb-2 text-dark-gray'>
              <Icon name='check' size='sm' className='me-1 mt-1 text-dark-gray' />
                  Salva o áudio automaticamente;
            </div>
            <div className='d-flex mb-2 text-dark-gray'>
              <Icon name='check' size='sm' className='me-1 mt-1 text-dark-gray' />
                  Transcreve as chamadas;
            </div>
            <div className='d-flex mb-2 text-dark-gray'>
              <Icon name='check' size='sm' className='me-1 mt-1 text-dark-gray' />
                  Resume os pontos principais;
            </div>
            <div className='d-flex mb-4 text-dark-gray'>
              <Icon name='check' size='sm' className='me-1 mt-1 text-dark-gray' />
                  Teste sem compromisso, sem cartão.
            </div>
            <LoadingButton
              isLoading={submitting}
              loadingText='Ativando...'
              variant='primary'
              className='mt-2'
              onClick={handleChangeActive}
            >
              Experimentar grátis
            </LoadingButton>
            <Button
              as={ExternalLink}
              target='_blank'
              href={helpUrl('activate-voip')}
              className='text-darker-gray ms-2 mt-2 rounded'
              variant='light'
            >
              <Icon className='me-1' name='external-link' />
              <span>Saiba mais</span>
            </Button>
          </div>
        )}
      >
        <div style={{ width: '100%' }}>
          <VoipButton />
        </div>
      </Popover>
    </>
  );
}

export default VoipEnabled;
