import React, { useMemo, useEffect, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import isEmpty from 'lodash/isEmpty';
import Button from '@/components/Button';
import CalculableField from '@/components/CalculableField';
import EntityIcon from '@/components/EntityIcon';
import EntityLink from '@/components/EntityLink';
import Icon from '@/components/Icon';
import PaginationCounter from '@/components/List/PaginationCounter';
import EntityEmpty from '@/components/List/EntityEmpty';
import EntitySkeleton from '@/components/List/EntitySkeleton';
import LoadSpinner from '@/components/LoadSpinner';
import Table from '@/components/Table';
import TableCell from '@/components/TableCell';
import TableIndex from '@/components/TableIndex';
import DragScrollable from '@/components/DragScrollable';
import ListHeader from '@/components/List/ListHeader';
import { DealIcon } from '@/feature/automation';
import { useHorizontalScrollTracker, useStoredState, useQueryParams } from '@/hooks';
import { LIST_TYPE, ENTITIES } from '@/utils';
import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper
} from '@tanstack/react-table';
import {
  getDataColumnsBy,
  getEditPathBy,
  getAddPathBy
} from '@/components/List/utils';

const propTypes = {
  items: PropTypes.array.isRequired,
  customFields: PropTypes.array.isRequired,
  variant: PropTypes.oneOf(Object.values(LIST_TYPE)).isRequired,
  entity: PropTypes.oneOf(Object.values(ENTITIES)).isRequired,
  isLoading: PropTypes.bool,
  isFetching: PropTypes.bool,
  isFetchingNextPage: PropTypes.bool,
  fetchNextPage: PropTypes.func,
  hasNextPage: PropTypes.bool,
  totalItems: PropTypes.number,
  columns: PropTypes.object,
  columnsLoaded: PropTypes.bool,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  onClearSearch: PropTypes.func,
  onClearFilter: PropTypes.func,
  onChangeFilter: PropTypes.func,
  onOpenFilter: PropTypes.func,
  filterParams: PropTypes.object
};

const defaultProps = {
  items: [],
  customFields: [],
  variant: LIST_TYPE.LIST,
  entity: null,
  isLoading: false,
  isFetching: false,
  isFetchingNextPage: false,
  fetchNextPage: null,
  hasNextPage: false,
  totalItems: 0,
  columns: {},
  columnsLoaded: false,
  onDelete: null,
  onUpdate: null,
  onClearSearch: null,
  onClearFilter: null,
  onChangeFilter: null,
  onOpenFilter: null,
  filterParams: {}
};
const MIN_WIDTH = 140;
const WIDTH = 275;
const MAX_WIDTH = 500;

function ListEntity({
  items, isLoading, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage, totalItems,
  columns, columnsLoaded, onDelete, onUpdate, onClearSearch, onClearFilter, onOpenFilter,
  variant, entity, customFields
}) {
  const {
    store: storeColumnOrder,
    value: initialOrder
  } = useStoredState(`${entity}-column-order`, {});
  const filteredColumns = useMemo(
    () => {
      const dataColumns = getDataColumnsBy(entity, customFields)
        .filter((column) => columns[column.name] && !column.hidden);

      if (!isEmpty(initialOrder)) {
        const sortedDataColumns = dataColumns.sort((columnA, columnB) => {
          const indexA = initialOrder.indexOf(columnA.name);
          const indexB = initialOrder.indexOf(columnB.name);

          if (indexA === -1) {
            return 1;
          }

          if (indexB === -1) {
            return -1;
          }

          if (indexA === indexB) {
            return 0;
          }

          return indexA - indexB;
        });

        return sortedDataColumns;
      } else {
        return dataColumns;
      }
    },
    [entity, JSON.stringify(columns)]
  );

  const queryParams = useQueryParams();
  const [highlightId, setHighlightId] = useState();
  const [hoverFieldName, setHoverFieldName] = useState();
  const { scrollableElementRef } = useHorizontalScrollTracker(filteredColumns);
  const {
    store: storeColumnsSizes,
    value: columnsSizeValues
  } = useStoredState(`${entity}-columns-size`, {});

  const isLoadingOrFetching = isLoading || isFetching || isFetchingNextPage;
  const isListEmpty = isEmpty(items);
  const columnHelper = createColumnHelper();

  const columnHelpers = useMemo(() => (
    filteredColumns.map((header) => {
      const storedColumnSizeItem = columnsSizeValues[header.name];
      return columnHelper.accessor(header.name, {
        id: header.name,
        cell: header.humanizedName,
        fixed: header.fixed,
        filter: header.filter,
        minSize: MIN_WIDTH,
        size: storedColumnSizeItem?.size || WIDTH,
        maxSize: MAX_WIDTH,
        render: header.getValue
      });
    })
  ), [JSON.stringify(filteredColumns)]);

  const table = useReactTable({
    data: items,
    columns: columnHelpers,
    columnResizeMode: 'onEnd',
    enableColumnResizing: true,
    getCoreRowModel: getCoreRowModel()
  });

  const { rows } = table.getRowModel();

  useEffect(() => {
    if (queryParams?.dealId) {
      setHighlightId(Number(queryParams?.dealId));
    }
  }, [queryParams?.dealId]);

  function renderList() {
    return rows.map(({ original: rowItem, getVisibleCells }, index) => (
      <tr key={rowItem.id}>
        {
          getVisibleCells().map(({ column }) => {
            const columnWidth = column?.getSize();
            if (column.columnDef.fixed) {
              const entityIconData = {
                ...rowItem,
                value: rowItem.valueRaw,
                finishedAt: rowItem.finishedAtRaw
              };

              return (
                <td
                  key={`${column.id}_${rowItem.id}`}
                  className={classnames(
                    'position-sticky',
                    'start-0',
                    'bg-white',
                    'align-middle',
                    'height-1',
                    'px-0',
                    'z-index-1',
                    'fixed-border-right'
                  )}>
                  <div className='d-flex'>
                    <TableIndex
                      value={
                        <EntityLink
                          entity={{ type: entity, id: rowItem.id }}
                          className={classnames(
                            'd-flex align-items-center fw-bold text-break-all',
                            { 'text-primary': rowItem.id === highlightId },
                            { 'text-darker-gray': rowItem.id !== highlightId }
                          )}
                        >

                          {column.columnDef.render(rowItem, onUpdate)}
                        </EntityLink>
                      }
                      icon={(
                        <div className='d-flex align-items-center align-self-start'>
                          <EntityIcon
                            className={classnames('me-2', 'opacity-90')}
                            type={entity}
                            data={entityIconData}
                          />
                          <DealIcon
                            deal={rowItem}
                            className='text-primary me-2'
                          />
                        </div>
                      )}
                      index={index + 1}
                    />
                  </div>
                </td>
              );
            }
            return (
              <td
                onMouseEnter={() => setHoverFieldName(column.id)}
                key={`${column.id}_${rowItem.id}`}
                className='align-middle text-break-all non-fixed-border-right'
                style={{ width: columnWidth, minWidth: columnWidth, maxWidth: columnWidth }}
              >
                <TableCell value={column.columnDef.render(rowItem, onUpdate)} />
              </td>
            );
          })
        }
        <td className='align-middle text-end'>
          <Button
            className='py-0 px-2'
            variant='link'
            href={getEditPathBy(entity, rowItem.id)}
          >
            <Icon name='edit-field' className='me-1' />
            <span className='fw-bold pt-1'>
              Editar
            </span>
          </Button>
        </td>
        <td className='align-middle text-start'>
          <Button
            href='#'
            className='py-0 px-2'
            variant='link'
            onClick={() => onDelete(rowItem.id)}
          >
            <Icon name='delete' className='me-1 text-danger' />
            <span className='text-dark-gray fw-bold pt-1'>
              Excluir
            </span>
          </Button>
        </td>
      </tr>
    ));
  }

  function renderTBody() {
    if (isLoading || (isFetching && !isFetchingNextPage)) {
      return <EntitySkeleton maxCols={filteredColumns?.length} />;
    }
    if (isListEmpty) {
      return (
        <tr className='border-0'>
          <td colSpan={1} className='border-0 height-6'>
            <EntityEmpty
              className='position-absolute top-50 start-50 translate-middle'
              show={!isLoadingOrFetching && columnsLoaded}
              variant={variant}
              entity={entity}
              buttonAddPath={getAddPathBy(entity)}
              onClearSearch={onClearSearch}
              onClearFilter={onClearFilter}
              onOpenFilter={onOpenFilter}
            />
          </td>
        </tr>
      );
    }
    return renderList();
  }

  function renderTFoot() {
    return (
      <tr>
        {
          filteredColumns.map((column) => {
            const columnWidth = table?.getColumn(column.name)?.getSize();
            if (column.fixed) {
              return (
                <td key={column.name} className={classnames(
                  'sticky-bottom',
                  'start-0',
                  'bg-white',
                  'align-middle',
                  'height-3',
                  'p-0',
                  'border-0',
                  'z-index-1'
                )}>
                  <div className='d-flex align-items-center h-100 py-3 px-2 border-0'>
                    <PaginationCounter
                      model={entity}
                      show={!isListEmpty && !(isFetching && !isFetchingNextPage)}
                      count={items?.length}
                      max={totalItems}
                    />
                  </div>
                </td>
              );
            }
            return (
              <td
                key={column.name}
                className='ps-0 pe-1 border-0 z-index-1 align-middle'
                style={{
                  width: columnWidth,
                  minWidth: columnWidth,
                  maxWidth: columnWidth
                }}
              >
                {column.calculable && (
                  <CalculableField
                    hovered={hoverFieldName === column.name}
                    fieldName={column.name}
                    entity={entity}
                    customField={column.customField}
                    isDecimal={column.isDecimal}
                    isCurrency={column.isCurrency}
                  />
                )}
              </td>
            );
          })
        }

        <td className='min-width-4 border-0'></td>
        <td className='min-width-4 border-0'></td>
      </tr>
    );
  }

  return (
    <InfiniteScroll
      dataLength={items?.length}
      next={fetchNextPage}
      hasMore={hasNextPage}
      scrollableTarget='table'
      className='pt-5 px-2 overflow-hidden d-flex flex-column flex-grow-1 flexible-height'
      scrollThreshold={0.95}
    >
      <DragScrollable
        horizontalOnly
        ref={scrollableElementRef}
        id='table'
        className='table-responsive p-0 ms-2 mb-2 h-100'
      >
        <Table
          className='table-border-separate'
          style={{ width: table.getCenterTotalSize() }}
        >
          <ListHeader
            table={table}
            onResize={storeColumnsSizes}
            onReorder={storeColumnOrder}
          />
          <tbody>
            {renderTBody()}
          </tbody>
          <tfoot className='bg-white sticky-bottom z-index-3'>
            {renderTFoot()}
          </tfoot>
        </Table>
        {
          (hasNextPage && isFetchingNextPage) && (
            <LoadSpinner size='md' className={classnames(
              'position-absolute',
              'start-50',
              'bottom-0',
              'translate-middle',
              'mb-8',
              'z-index-3',
              'text-dark-gray',
              'opacity-100',
              'overflow-hidden'
            )}/>
          )
        }
      </DragScrollable>
    </InfiniteScroll>
  );
}

ListEntity.propTypes = propTypes;
ListEntity.defaultProps = defaultProps;

export default ListEntity;
