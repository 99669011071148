import React from 'react';
import PropTypes from 'prop-types';
import Base from 'react-bootstrap/Breadcrumb';
import Item from '@/components/Breadcrumb/Item';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

const defaultProps = {
  className: null
};

function Breadcrumb({ children, className }) {
  return (
    <Base className={className}>
      {children}
    </Base>
  );
}

Breadcrumb.propTypes = propTypes;
Breadcrumb.defaultProps = defaultProps;

Breadcrumb.Item = Item;
Breadcrumb.Item.displayName = 'Breadcrumb.Item';

export default Breadcrumb;
