import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Collapse from '@/components/Collapse';
import Icon from '@/components/Icon';
import Tooltip from '@/components/Tooltip';
import Button from '@/components/Button';

const propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
  onToggle: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string
};

const defaultProps = {
  open: true,
  onToggle: () => {},
  className: '',
  label: ''
};

function Drawer({ children, open, onToggle, className, label }) {
  const [openDrawer, setOpenDrawer] = useState(open);
  const [opened, setOpened] = useState(open);

  return (
    <div className='d-flex position-relative z-index-2'>
      <Collapse in={openDrawer} dimension='width'
        onExited={() => setOpened(false)}
        onEnter={() => setOpened(true)}
      >
        <div className={className}>
          {children}
        </div>
      </Collapse>

      <Tooltip
        placement='top'
        content={opened ? `Ocultar ${label}` : `Exibir ${label}`}
      >
        <Button
          as='label'
          className={classnames(
            'position-absolute',
            'p-1',
            'rounded-circle',
            'end-0',
            'mt-4',
            'translate-x-50',
            'text-white'
          )}
          variant='light-blue'
          onClick={() => {
            setOpenDrawer(!openDrawer);
            onToggle(!openDrawer);
          }}
        >
          <Icon
            name={opened ? 'double-arrow-left' : 'double-arrow-right'}
          />
        </Button>
      </Tooltip>
    </div>
  );
}

Drawer.propTypes = propTypes;
Drawer.defaultProps = defaultProps;

export default Drawer;
