import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '@/components/Button';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  alignment: PropTypes.oneOf(['left', 'right'])
};

const defaultProps = {
  className: '',
  alignment: 'right',
  onClick: () => {}
};

function InputAdornment({ children, className, onClick, alignment }) {
  return (
    <Button
      onClick={onClick}
      variant={null}
      className={classnames(
        'position-absolute',
        'top-50',
        'translate-middle-y',
        'px-2',
        'border-0',
        {
          'start-0': alignment === 'left',
          'end-0': alignment === 'right'
        },
        className
      )}
    >
      {children}
    </Button>
  );
}

InputAdornment.propTypes = propTypes;
InputAdornment.defaultProps = defaultProps;

export default InputAdornment;
