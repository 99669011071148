import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ConfirmationModal from '@/components/ConfirmationModal';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import { Checkbox } from '@/components/Inputs';

const propTypes = {
  entity: PropTypes.string.isRequired,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onConfirm: PropTypes.func
};

const defaultProps = {
  show: false,
  onHide: () => {},
  onConfirm: () => {}
};

function getConfirmationSteps(entity, importData) {
  const confirmationSteps = {
    organizations: [
      <>
        Todas as <b>empresas ({importData?.recordsCount})</b> dessa importação serão excluídas.
      </>,
      <>
        Todas as <b>pessoas</b> e <b>negócios</b> relacionados a essas empresas serão mantidos,
        porém ficarão sem empresa relacionada.
      </>,
      <>
        Todos os <b>comentários</b> e <b>tarefas</b> relacionados a essas empresas serão mantidos,
        porém ficarão sem empresa relacionada.
      </>,
      'Entendo que este é um processo irreversível.'
    ],
    people: [
      <>
        Todas as <b>pessoas ({importData?.recordsCount})</b> dessa importação serão excluídas.
      </>,
      <>
        Todos os <b>negócios</b> relacionados a essas pessoas serão mantidos,
        porém ficarão sem pessoa relacionada.
      </>,
      <>
        Todos os <b>comentários</b> e <b>tarefas</b> relacionados a essas pessoas serão mantidos,
        porém ficarão sem pessoa relacionada.
      </>,
      'Entendo que este é um processo irreversível.'
    ],
    activities: [
      <>
        Todos os <b>comentários</b> e <b>tarefas ({importData?.recordsCount})</b> dessa importação
        serão excluídos.
      </>,
      'Entendo que este é um processo irreversível.'
    ],
    deals: [
      <>
        Todos os <b>negócios ({importData?.recordsCount})</b> serão excluídos.
      </>,
      <>
        Todos os <b>comentários</b> e <b>tarefas</b> relacionados a esses negócios serão excluídos.
      </>,
      'Entendo que este é um processo irreversível.'
    ],
    products: [
      <>
        Todos os <b>produtos</b> e <b>categorias</b> nessa importação serão excluídos.
      </>,
      'As atualizações feitas em produtos existentes serão mantidas.',
      'Entendo que este é um processo irreversível.'
    ]
  };

  return confirmationSteps[entity];
}

function RevertingConfirmationModal({ show, onHide, onConfirm, entity, importData }) {
  const [canRevert, setCanRevert] = useState(false);

  const confirmationSteps = getConfirmationSteps(entity, importData);

  const handleFormSubmit = (formData) => {
    const values = Object.values(formData);

    const allFilled = values.length === confirmationSteps.length;
    const allTrue = values.every((value) => value);

    if (!canRevert && allFilled && allTrue) {
      setCanRevert(true);
    } else if (canRevert) {
      setCanRevert(false);
    }
  };

  const handleModalHide = () => {
    setCanRevert(false);
    onHide();
  };

  const handleModalConfirm = () => {
    setCanRevert(false);
    onConfirm();
  };

  return (
    <ConfirmationModal
      confirmationText={importData?.status === 'failed'
        ? 'Confirmar exclusão'
        : 'Confirmar perdas e desfazer importação'}
      show={show}
      onConfirm={handleModalConfirm}
      onHide={handleModalHide}
      disabled={!canRevert}
    >
      <ConfirmationModal.Title>
        {importData?.status === 'failed'
          ? <span>Deseja excluir esse registro?</span>
          : <span>Deseja desfazer esta importação?</span>}
      </ConfirmationModal.Title>

      <ConfirmationModal.Info>
        Marque as opções que realizaremos na sua conta:
      </ConfirmationModal.Info>

      <Form
        onSubmit={handleFormSubmit}
        className={classnames('d-flex')}
        name='confirm-import-revert-form'
      >
        {({ handleSubmit }) => (
          <div>
            {confirmationSteps.map((stepText, index) => (
              <FormField
                key={index}
                as={Checkbox}
                name={`step-${index}`}
                label={stepText}
                className='text-start'
                onChange={handleSubmit}
              />
            ))}
          </div>
        )}
      </Form>
    </ConfirmationModal>
  );
}

RevertingConfirmationModal.propTypes = propTypes;
RevertingConfirmationModal.defaultProps = defaultProps;

export default RevertingConfirmationModal;
