import { useState, useCallback } from 'react';
import { useFunnelContext } from '@/contexts';
import { useStoredState } from '@/hooks';
import {
  useFunnelStages,
  defaultFunnelStageParams,
  defaultFunnelStageFrozenParams
} from '@/api';

const SHOW_FROZEN_KEY = 'funnel.frozen.show';

export function useFunnelBoard({ funnelId, stages }) {
  const { columns, dispatch } = useFunnelContext();
  const { showFrozen, setShowFrozen, getColumn } = useFrozen({ columns });
  const {
    isFetching, isFetchingFrozen, params, onChangeFilters
  } = useQueries({ funnelId, stages, dispatch, showFrozen });

  const [totalAmount, totalCount] = stages.reduce((acc, stage) => {
    const [accAmount, accCount] = acc;
    const column = getColumn(`funnel-${stage.id}`);

    return [
      accAmount + (column.totalAmount || 0),
      accCount + (column.totalCount || 0)
    ];
  }, [0, 0]);

  const [totalFrozenAmount, totalFrozenCount] = stages.reduce((acc, stage) => {
    const [accAmount, accCount] = acc;
    const column = getColumn(`funnel-${stage.id}`);

    return [
      accAmount + (column.frozenAmount || 0),
      accCount + (column.frozenCount || 0)
    ];
  }, [0, 0]);

  return {
    columns,
    dispatch,
    getColumn,
    showFrozen,
    setShowFrozen,
    isFetching,
    isFetchingFrozen,
    params,
    onChangeFilters,
    totalAmount,
    totalCount,
    totalFrozenAmount,
    totalFrozenCount
  };
}

function useFrozen({ columns }) {
  const {
    store: setShowFrozen,
    value: showFrozen
  } = useStoredState(SHOW_FROZEN_KEY, false);

  const getColumn = useCallback((id) => {
    const column = columns[id] || {};

    const {
      totalAmount: itemsAmount = 0,
      totalCount: itemsCount = 0,
      frozen: {
        items: frozenItems,
        initialItems: initialFrozenItems,
        totalAmount: frozenAmount = 0,
        totalCount: frozenCount = 0
      } = {},
      ...rest
    } = column;

    let totalAmount = itemsAmount;
    let totalCount = itemsCount;

    if (showFrozen) {
      totalAmount = totalAmount + frozenAmount;
      totalCount = totalCount + frozenCount;
    }


    return {
      itemsCount,
      itemsAmount,
      initialFrozenItems,
      frozenItems,
      frozenAmount,
      frozenCount,
      totalAmount,
      totalCount,
      ...rest
    };
  }, [columns, showFrozen]);

  return { setShowFrozen, showFrozen, getColumn };
}

function useQueries({ funnelId, stages, dispatch, showFrozen }) {
  const [params, setParams] = useState(null);

  const { isFetching } = useFunnelStages({
    funnelId,
    params: { ...params, ...defaultFunnelStageParams },
    config: {
      enabled: Boolean(params),
      onSuccess: ({ data }) => {
        data.forEach((stage, _) => {
          const column = {
            initialItems: stage.items,
            items: stage.items,
            totalAmount: stage.totalAmount,
            totalCount: stage.totalCount
          };

          dispatch({
            type: 'update_column',
            payload: { id: `funnel-${stage.id}`, column }
          });
        });
      }
    }
  });

  const { isFetching: isFetchingFrozen } = useFunnelStages({
    funnelId,
    params: { ...params, ...defaultFunnelStageFrozenParams },
    config: {
      enabled: showFrozen && Boolean(params),
      onSuccess: ({ data }) => {
        data.forEach((stage, _) => {
          const column = {
            frozen: {
              initialItems: stage.items,
              items: stage.items,
              totalAmount: stage.totalAmount,
              totalCount: stage.totalCount
            }
          };

          dispatch({
            type: 'update_column',
            payload: { id: `funnel-${stage.id}`, column }
          });
        });
      }
    }
  });

  const onChangeFilters = useCallback((newParams) => {
    setParams(newParams);
  }, [
    stages,
    setParams
  ]);

  return {
    isFetching, isFetchingFrozen, params, onChangeFilters
  };
}
