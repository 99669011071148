import React from 'react';
import PropTypes from 'prop-types';

const MAX_DIGITS = 2;

const propTypes = {
  value: PropTypes.any,
  icon: PropTypes.any,
  index: PropTypes.number,
  maxDigits: PropTypes.number
};

const defaultProps = {
  value: null,
  icon: null,
  index: 1,
  maxDigits: MAX_DIGITS
};

function TableIndex({ value, icon, index, maxDigits }) {
  return (
    <>
      <span className='flex-shrink-0 d-inline-block me-2 width-2 text-end text-medium-gray'>
        {`${index}`.padStart(maxDigits, '0')}
      </span>
      {icon}
      {value}
    </>
  );
}

TableIndex.propTypes = propTypes;
TableIndex.defaultProps = defaultProps;

export default TableIndex;
