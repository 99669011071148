import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

function CardOverlay({ className, children }) {
  return (
    <div
      className={classnames(
        'hover-child-opacity-100',
        'opacity-0',
        'position-absolute',
        'top-0',
        'bottom-0',
        'start-0',
        'end-0',
        'bg-darker-gray',
        'd-flex',
        'flex-column',
        'align-items-center',
        'p-6',
        className
      )}
    >
      { children }
    </div>
  );
}

CardOverlay.propTypes = propTypes;

export default CardOverlay;
