import { useQuery, useMutation, useQueryClient } from 'react-query';
import { get, post, patch, _delete } from '@/lib/fetch';

export const requiredFieldsKeys = {
  all: ['required-fields'],
  lists: () => [...requiredFieldsKeys.all, 'list'],
  list: (params) => [...requiredFieldsKeys.lists(), params]
};

export async function getRequiredFields(params = {}) {
  const { data } = await get('/required_fields', params);

  return data;
}

export async function createRequiredField(params = {}) {
  const { data } = await post('/required_fields', params);

  return data;
}

export async function updateRequiredField({ id, params = {} } = {}) {
  const { data } = await patch(`/required_fields/${id}`, params);

  return data;
}

export async function deleteRequiredField({ id, params = {} } = {}) {
  const { data } = await _delete(`/required_fields/${id}`, params);

  return data;
}

export async function getDealRequiredFields({ dealId, params = {} }) {
  const { data } = await get(`/deals/${dealId}/required_fields`, params);

  return data;
}

export function useRequiredFields({ params = {}, config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: requiredFieldsKeys.list(params),
    queryFn: () => getRequiredFields(params),
    refetchOnMount: false
  });
}

export function useCreateRequiredField({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await queryClient.invalidateQueries(requiredFieldsKeys.all);
  };

  return useMutation(
    createRequiredField,
    {
      onSuccess,
      ...config
    }
  );
}

export function useUpdateRequiredField({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await queryClient.invalidateQueries(requiredFieldsKeys.all);
  };

  return useMutation(
    updateRequiredField,
    {
      onSuccess,
      ...config
    }
  );
}

export function useDeleteRequiredField({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries(requiredFieldsKeys.all);

  return useMutation(
    deleteRequiredField,
    {
      onSuccess,
      ...config
    }
  );
}
