export function canImport({ user, data }) {
  let effect, reason, privilege;

  switch (data.entity) {
    case 'organization':
      privilege = user.privileges.organizations.create_update;
      break;

    case 'person':
      privilege = user.privileges.people.create_update;
      break;

    case 'deal':
      privilege = user.privileges.deals.create_update;
      break;

    case 'product':
      privilege = user.admin;
      break;

    case 'activity':
      privilege =
        user.privileges.activities.create &&
        user.privileges.activities.update;
      break;

    default:
      privilege = false;
  }

  if (privilege) {
    effect = 'allow';
  } else {
    effect = 'disable';
    reason =
      'Você não possui privilégio para realizar importação. ' +
      'Contate o administrador de sua conta.';
  }

  return { effect, reason };
}
