import { get } from '@/lib/fetch';
import { useQuery } from 'react-query';

export const subscriptionPreviewKeys = {
  all: ['subscription-preview'],
  lists: () => [...subscriptionPreviewKeys.all, 'list'],
  list: (params) => [...subscriptionPreviewKeys.lists(), params]
};

export async function showSubscriptionPreview(params = {}) {
  const { data } = await get('/billing/subscription_preview', params);

  return data;
}

export function useSubscriptionPreview({ config = {}, params = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: subscriptionPreviewKeys.list(params),
    queryFn: () => showSubscriptionPreview(params)
  });
}
