import { useQuery, useMutation, useQueryClient } from 'react-query';
import { get, post, patch } from '@/lib/fetch';
import { accessoryCallCache } from '@/utils';

export const voipKeys = {
  verify: ['verify'],
  account: ['account']
};
export async function getVoipVerify() {
  const { data } = await get('/voip/verify');

  return data;
}

export async function createVoipStartCall(params = {}) {
  const { data } = await post('/voip/start_call', params);

  return data;
}

export async function createCustomNumber(params = {}) {
  const { data } = await post('/voip/custom_number', params);

  return data;
}

export async function updateVoipToggleAccount() {
  const { data } = await patch('/voip/toggle_account');

  return data;
}

export async function getVoipAccount() {
  const { data } = await get('/voip/account');

  return data;
}

export function useVoipVerify({ config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: voipKeys.verify,
    queryFn: () => getVoipVerify()
  });
}

export function useVoipAccount({ config = {} } = {}) {
  return useQuery({
    ...accessoryCallCache,
    ...config,
    queryFn: () => getVoipAccount()
  });
}

export function useCreateCustomNumber({ config = {} } = {}) {
  return useMutation(createCustomNumber, { ...config });
}
export function useCreateVoipStartCall({ config = {} } = {}) {
  return useMutation(createVoipStartCall, { ...config });
}

export function useUpdateVoipToggleAccount({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries(voipKeys.verify);
  return useMutation(updateVoipToggleAccount, { ...config, onSuccess });
}
