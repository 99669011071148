import React from 'react';
import { IntercomProvider as Base } from 'react-use-intercom';
import { useIntercomUserData } from '@/lib/intercom/hooks/use-intercom-user-data';
import { IntercomLocationTracker } from '@/lib/intercom/IntercomLocationTracker';
import { INTERCOM_APP_ID } from '@/config';

export function IntercomProvider({ children }) {
  const userData = useIntercomUserData();

  return (
    <Base appId={INTERCOM_APP_ID} autoBoot autoBootProps={userData}>
      <IntercomLocationTracker>
        {children}
      </IntercomLocationTracker>
    </Base>
  );
}
