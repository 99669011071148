import React from 'react';
import PropTypes from 'prop-types';

import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Modal from '@/components/Modal';

const propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onConfirm: PropTypes.func,
  isLoading: PropTypes.bool
};

const defaultProps = {
  show: false,
  onHide: () => {},
  onConfirm: () => {},
  isLoading: false
};

function PerformanceUpgradeAcceptedModal({ show, onHide, onConfirm, isLoading }) {
  return (
    <Modal
      size='md'
      show={show}
      onHide={onHide}
      bodyClassName='d-flex flex-column p-8 align-items-center text-center text-darker-gray'
      backdrop='static'
    >
      <h2 className='text-center'>
        Migrar para o plano Performance
      </h2>

      <span className='mt-2 mb-5'>
        Ao confirmar, você irá contratar o plano Performance e
        continuará utilizando as funcionalidades por apenas R$75 por mês por usuário.
      </span>

      <div className='d-flex w-100 justify-content-between text-start'>
        <div>
          <div className='mb-3'>
            <Icon name='listing' className='me-3'/>
            <span>Qualificação obrigatória</span>
          </div>

          <div>
            <Icon name='ai' className='me-3' />
            <span>Telefone Virtual inteligente</span>
          </div>
        </div>

        <div className='pe-4'>
          <div className='mb-3'>
            <Icon name='lightning' className='me-3' />
            <span>Automações</span>
          </div>
          <div>
            <Icon name='proposal' className='me-3' />
            <span>Modelo de propostas</span>
          </div>
        </div>
      </div>

      <div className='d-flex mt-6 w-100 justify-content-end'>
        <Button
          variant='light'
          className='me-2 text-darker-gray'
          onClick={onHide}
          disabled={isLoading}
        >
          Cancelar
        </Button>

        <Button
          onClick={onConfirm}
          disabled={isLoading}
        >
          Confirmar
        </Button>
      </div>
    </Modal>
  );
}

PerformanceUpgradeAcceptedModal.propTypes = propTypes;
PerformanceUpgradeAcceptedModal.defaultProps = defaultProps;

export default PerformanceUpgradeAcceptedModal;
