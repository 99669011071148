export function canEditOrganization({ user, _data }) {
  let effect, reason;
  const privilege = user.privileges.organizations.create_update;

  if (!privilege) {
    effect = 'disable';
    reason = `Você não possui privilégio para editar empresas.
      Contate o administrador de sua conta.`;
  } else {
    effect = 'allow';
  }

  return { effect, reason };
}
