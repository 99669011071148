import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  SkeletonComponent: PropTypes.elementType.isRequired
};

const defaultProps = {
};

function SkeletonWrapper({
  isLoading,
  children,
  SkeletonComponent
}) {
  if (isLoading) {
    return <SkeletonComponent />;
  }

  return children;
}

SkeletonWrapper.propTypes = propTypes;
SkeletonWrapper.defaultProps = defaultProps;

export default SkeletonWrapper;
