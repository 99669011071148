import React from 'react';
import PropTypes from 'prop-types';
import { TemplateCardBox, Connector } from '@/feature/automation';

const propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf([
    'standard',
    'spoiler'
  ])
};

const defaultProps = {
  variant: 'standard'
};

function TemplateTriggerBox(props) {
  const { variant, children } = props;

  return (
    <>
      <TemplateCardBox variant={variant}>
        {children}
      </TemplateCardBox>

      <Connector variant={variant} />
    </>
  );
}

TemplateTriggerBox.propTypes = propTypes;
TemplateTriggerBox.defaultProps = defaultProps;

export default TemplateTriggerBox;
