import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import FetchableSelect from '@/components/Inputs/FetchableSelect';
import { useAuth } from '@/lib/auth';
import { useEmailRecipients } from '@/api';
import Icon from '@/components/Icon';

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
  dealId: PropTypes.number
};

const defaultProps = {
  name: '',
  label: '',
  placeholder: '',
  multiple: false,
  isClearable: false,
  isSearchable: false,
  defaultValue: null,
  error: null,
  dealId: 0,
  onChange: () => {}
};

function EmailSelect(props) {
  const { error, dealId } = props;
  const { user } = useAuth();

  const transformer = useCallback((fetchedData) => (
    fetchedData.data.map((item) => ({
      label: `${item.name} <${item.email}>`,
      value: item.email,
      leftAdornment: <Icon name={item.type ?? 'person'} />
    }))
  ), [user]);

  return (
    <FetchableSelect
      {...props}
      transformer={transformer}
      query={useEmailRecipients}
      queryArgs={{ params: { deal_id: dealId } }}
      error={error}
      isCreatable={true}
    />
  );
}

EmailSelect.propTypes = propTypes;
EmailSelect.defaultProps = defaultProps;

export default EmailSelect;
