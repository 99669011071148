import React, { useMemo, useRef } from 'react';
import AccountDataForm from '@/components/Billing/AccountDataForm';
import PaymentWrapper from '@/components/LeadsGeneration/Payment/PaymentWrapper';
import { useUpdateCurrentAccount } from '@/api';
import { useLeadsGenerationContext } from '@/contexts';

function AccountData() {
  const formRef = useRef(null);
  const {
    nextStep,
    accountData,
    prevStep,
    isEnabledEditingAccountData,
    isValidAccountData,
    setIsEnabledEditingAccountData
  } = useLeadsGenerationContext();
  const updateCurrentAccountMutation = useUpdateCurrentAccount();

  const initialValues = useMemo(() => ({
    legal_name: accountData.legalName ?? '',
    cpf_cnpj: accountData.cpfCnpj ?? '',
    billing_emails: accountData.billingEmails?.join(',') ?? '',
    contact: {
      work_phone: accountData.contact.workPhone ?? ''
    },
    address: {
      city_id: accountData.address.city?.id ?? '',
      additional_info: accountData.address.additionalInfo ?? '',
      country: accountData.address.country ?? '',
      district: accountData.address.district ?? '',
      state: accountData.address.state ?? '',
      postal_code: accountData.address.postalCode ?? '',
      street_number: (accountData.address.streetNumber ?? '').toString(),
      street_name: accountData.address.streetName ?? ''
    }
  }), [accountData]);

  const onSubmit = (values) => {
    setIsEnabledEditingAccountData(true);
    updateCurrentAccountMutation.mutate(values, {
      onSuccess: () => {
        nextStep();
      }
    });
  };

  if (!isEnabledEditingAccountData && isValidAccountData) {
    nextStep();
  }

  return (
    <PaymentWrapper
      onClickNext={() => formRef.current?.submitForm()}
      buttonNextText='Avançar'
      onClickPrev={prevStep}
    >
      <AccountDataForm
        ref={formRef}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
    </PaymentWrapper>
  );
}

export default AccountData;
