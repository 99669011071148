import React from 'react';
import { Outlet } from 'react-router-dom';
import Tabs from '@/components/Tabs';
import NavLink from '@/components/NavLink';
import * as Settings from '@/feature/settings';
import { settingsAutomationsPath, settingsAutomationTemplatesPath } from '@/routes';
import { useDocumentTitle } from '@/hooks';
import Main from '@/components/Main';

const PAGE_TITLE = 'Automações';
const PAGE_SUBTITLE = `Crie ações automáticas para otimizar o seu
 fluxo de trabalho`;

function AutomationsBase() {
  useDocumentTitle(PAGE_TITLE);

  return (
    <Main>
      <Settings.PageTitle>
        {PAGE_TITLE}
      </Settings.PageTitle>

      <Settings.PageSubtitle>
        {PAGE_SUBTITLE}
      </Settings.PageSubtitle>

      <Tabs className='border-bottom border-1'>
        <Tabs.Item>
          <NavLink href={settingsAutomationTemplatesPath()} end>
            Nova automação
          </NavLink>
        </Tabs.Item>

        <Tabs.Item>
          <NavLink href={settingsAutomationsPath()} end>
            Automações criadas
          </NavLink>
        </Tabs.Item>
      </Tabs>
      <Outlet />
    </Main>
  );
}

export default AutomationsBase;
