import { useQuery, useMutation, useQueryClient } from 'react-query';
import { get, patch } from '@/lib/fetch';

export const audioTranscriptionKeys = {
  details: () => ['audio-transcription'],
  detail: (audioTranscriptionId) => [...audioTranscriptionKeys.details(), audioTranscriptionId]
};
export async function getAudioTranscription(audioTranscriptionId) {
  const { data } = await get(`/audio_transcriptions/${audioTranscriptionId}`);

  return data;
}

export async function updateAudioTranscription({ audioTranscriptionId, params = {} }) {
  const { data } = await patch(`/audio_transcriptions/${audioTranscriptionId}`, params);

  return data;
}

export function useAudioTranscription({ audioTranscriptionId, config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: audioTranscriptionKeys.detail(audioTranscriptionId),
    queryFn: () => getAudioTranscription(audioTranscriptionId)
  });
}

export function useUpdateAudioTranscription({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async ({ data }) => {
    await queryClient.invalidateQueries(audioTranscriptionKeys.detail(data?.id));
  };

  return useMutation(
    updateAudioTranscription,
    {
      onSuccess,
      ...config
    }
  );
}
