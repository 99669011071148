import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@/components/Tooltip';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Popover from '@/components/Popover';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import LoadingButton from '@/components/LoadingButton';
import { TextArea } from '@/components/Inputs';
import { useUpdateAudioSummary, useUpdateAudioTranscription } from '@/api';
import APIErrorMessage from '@/components/APIErrorMessage';
import { useAlert } from 'react-alert';

const propTypes = {
  data: PropTypes.object,
  entity: PropTypes.string
};

const FEEDBACK_TYPE = {
  audioSummary: {
    key: 'audioSummaryId',
    resource: 'audio_summaries',
    wrongMessage: 'Algo do resumo não faz sentido?'
  },
  audioTranscription: {
    key: 'audioTranscriptionId',
    resource: 'audio_transcriptions',
    wrongMessage: 'Algo da transcricão não faz sentido?'
  }
};

function useUpdateFeedbackFor(entity, options) {
  const feedbackMapping = {
    audioSummary: useUpdateAudioSummary,
    audioTranscription: useUpdateAudioTranscription
  };
  const feedbackMutation = feedbackMapping[entity];
  return feedbackMutation?.(options);
}

function VoipGptFeedback({ data, entity }) {
  const alert = useAlert();
  const dataId = data?.id;
  const liked = data?.liked;

  const updateMutation = useUpdateFeedbackFor(entity);
  const onUpdate = (params, done) => {
    updateMutation.mutate(params, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage
            err={err}
            resource={FEEDBACK_TYPE[entity].resource}
            action='update'
          />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.();
      },
      onSuccess: () => {
        alert.show(
          'Feedback enviado com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        done?.();
      }
    });
  };

  const handleLike = () => {
    if (data?.liked === null) {
      onUpdate({
        [FEEDBACK_TYPE[entity].key]: dataId,
        params: { liked: true, feedback: null }
      });
    } else {
      alert.show(
        'Você já enviou o feedback!',
        { variant: 'warning', timeout: 5000 }
      );
    }
  };

  const handleDislike = ({ feedback }, done) => {
    if (data?.liked === null) {
      onUpdate({
        [FEEDBACK_TYPE[entity].key]: dataId,
        params: { liked: false, feedback }
      }, done);
    }
  };

  return (
    <div className='d-flex justify-content-end me-2'>
      <div>
        <Tooltip content='Gostei'>
          <Button
            variant='white'
            className='px-1 me-1 text-darker-gray'
            onClick={handleLike}
          >
            <Icon
              name={liked === true ? 'like-fill' : 'like'}
              className={liked === false ? 'text-light-gray' : ''}
            />
          </Button>
        </Tooltip>
      </div>
      <Popover
        placement='top-start'
        showArrow
        closeButton
        onEnter={() => {
          if (liked !== null) {
            alert.show(
              'Você já enviou o feedback!',
              { variant: 'warning', timeout: 5000 }
            );
          }
        }}
        className={liked !== null ? 'd-none' : ''}
        content={({ onHide }) => (
          <>
            <h4 className='mb-4'>
              {FEEDBACK_TYPE[entity].wrongMessage}
              &nbsp;
              <span className='fw-light text-medium-gray'>(opcional)</span>
            </h4>
            <Form
              initialValues={{ feedback: undefined }}
              name='feedbackForm'
              onSubmit={(params) => handleDislike(params, onHide)}
            >
              {({ handleSubmit, isSubmitting }) => (
                <>
                  <FormField
                    as={TextArea}
                    name='feedback'
                    placeholder='Conta pra gente o que não fez sentido...'
                  />
                  <div className='d-flex justify-content-start'>
                    <LoadingButton
                      variant='primary'
                      loadingText='Enviando...'
                      onClick={handleSubmit}
                      isLoading={isSubmitting}
                    >
                    Enviar
                    </LoadingButton>
                  </div>
                </>
              )}
            </Form>
          </>
        )}
      >
        <div>
          <Tooltip content='Não gostei'>
            <Button
              variant='white'
              className='px-1 text-darker-gray'
            >
              <Icon
                name={liked === false ? 'dislike-fill' : 'dislike'}
                className={liked === true ? 'text-light-gray' : ''}
              />
            </Button>
          </Tooltip>
        </div>
      </Popover>
    </div>
  );
}

VoipGptFeedback.propTypes = propTypes;

export default VoipGptFeedback;
