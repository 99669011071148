import React from 'react';
import PropTypes from 'prop-types';
import Base from 'react-bootstrap/Breadcrumb';
import Icon from '@/components/Icon';
import Link from '@/components/Link';

const propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  active: PropTypes.bool,
  linkAs: PropTypes.elementType
};

const defaultProps = {
  href: '',
  linkAs: Link
};

function Item({ children, href, active, ...props }) {
  return (
    <Base.Item
      href={href}
      active={active}
      { ...props }
    >
      {children}

      <Icon
        name='arrow-right'
        className='ms-2 text-gray breadcrumb-divider'
        size='sm'
      />
    </Base.Item>
  );
}

Item.propTypes = propTypes;
Item.defaultProps = defaultProps;

export default Item;
