import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import { FileInput } from '@uppy/react';
import { useUppy, createUppy } from '@/lib/uppy';
import Button from '@/components/Button';
import Icon from '@/components/Icon';

const propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  iconName: PropTypes.oneOf(['agendor-logo-small', 'organization', 'person'])
};

const defaultProps = {
  className: null,
  value: null,
  onChange: () => {},
  iconName: 'agendor-logo-small',
  label: 'avatar'
};

function ImagePicker(props) {
  const { className, name, label, value, onChange, iconName } = props;

  const alert = useAlert();
  const uppy = useUppy(() => {
    const newUppy = createUppy({
      allowedFileTypes: ['.jpg', '.jpeg', '.png', '.gif'],
      maxFileSizeMega: 2,
      thumbnailGenerator: true
    });

    newUppy
      .on('file-removed', () => {
        onChange(null);
      })
      .on('restriction-failed', () => {
        alert.show(
          `Não foi possível adicionar o ${label}.
          Selecione um arquivo JPEG ou PNG de até 2 MB.`,
          { variant: 'danger' }
        );
      })
      .on('upload-success', (uploadedFile) => {
        const file = newUppy.getFile(uploadedFile.id);
        const body = structuredClone(file.response.body);
        body.preview = uploadedFile.preview;
        onChange(body);
      })
      .on('upload-error', (file) => {
        alert.show(
          `Ocorreu um erro ao adicionar o ${label}.`,
          { variant: 'danger' }
        );
        newUppy.removeFile(file.id);
      });

    return newUppy;
  });

  const [selectedFile] = uppy.getFiles();

  useEffect(() => {
    // Adiciona arquivo pré-selecionado via `value`.
    if (!selectedFile && value) {
      const fileId = uppy.addFile({
        name: value.id,
        data: {},
        isRemote: true,
        // Essa condicional garante transição entre telas que não enviam arquivo pro backend
        preview: value.url || value.preview
      });
      uppy.setFileState(fileId, {
        progress: { uploadComplete: true, uploadStarted: true }
      });
    }
  }, [value, selectedFile]);

  const onRemove = () => uppy.reset();
  const imageUrl = selectedFile?.preview || value?.preview;
  return (
    <div className={className}>
      <div className='width-4 height-4 mb-4 rounded overflow-hidden'>
        {
          imageUrl
            ? (
              <img
                className='w-100 h-100 object-fit-cover'
                src={imageUrl}
                alt={label}
              />
            )
            : (
              <div className='w-100 h-100 p-8 bg-light text-dark-gray'>
                <Icon name={iconName} size='full' />
              </div>
            )
        }
      </div>

      {
        selectedFile || value
          ? (
            <Button
              variant='link'
              className='text-danger p-0 fw-bold'
              onClick={onRemove}
            >
              Remover {label}
            </Button>
          )
          : (
            <Button
              as='label'
              variant='link'
              className='p-0 fw-bold'
            >
              <FileInput
                uppy={uppy}
                inputName={name}
                pretty={false}
              />

              Adicionar {label}
            </Button>
          )
      }
    </div>
  );
}

ImagePicker.propTypes = propTypes;
ImagePicker.defaultProps = defaultProps;
ImagePicker.displayName = 'ImagePicker';

export default ImagePicker;
