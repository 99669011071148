import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from '@/components/Inputs/MaskedInput';
import classnames from 'classnames';
import Dropdown from '@/components/Dropdown';
import TextInput from '@/components/Inputs/TextInput';
import { formatDurationMessage } from '@/date';
import RangeAdornment from '@/components/RangeAdornment';
import Select from '@/components/Inputs/Select';

const propTypes = {
  value: PropTypes.shape({
    number: PropTypes.string,
    unit: PropTypes.oneOf(['hours', 'days', 'weeks', 'months'])
  }),
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string
};

const defaultProps = {
  label: 'Para quando?',
  placeholder: 'Ex.: Para 2 dias depois'
};

const options = [
  {
    label: 'horas depois',
    value: 'hours'
  },
  {
    label: 'dias depois',
    value: 'days'
  },
  {
    label: 'semanas depois',
    value: 'weeks'
  },
  {
    label: 'meses depois',
    value: 'months'
  }
];

function DurationInput({ onChange, value, ...props }) {
  const [number, setNumber] = useState(value?.number);
  const [unit, setUnit] = useState(value?.unit);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    setNumber(value?.number);
    setUnit(value?.unit ? value.unit : options[1].value);
  }, [value]);

  const preview = useMemo(() => formatDurationMessage(value), [value]);

  const onApply = (newNumber, newUnit) => {
    setIsDropdownOpen(false);
    onChange({
      number: newNumber,
      unit: newNumber ? newUnit : options[1].value
    });
  };

  return (
    <Dropdown
      show={isDropdownOpen}
      onToggle={setIsDropdownOpen}
    >
      <Dropdown.Toggle
        as={TextInput}
        { ...props }
        readOnly
        innerClassName='cursor-default'
        value={preview}
        rightAdornment={() => <RangeAdornment isFilled={false} />}
      />
      <Dropdown.Menu
        flip={false}
      >
        <Dropdown.ItemText>
          <MaskedInput
            name='number'
            label='Para'
            placeholder='0'
            value={number}
            maskType='natural'
            onChange={setNumber}
            onBlur={() => {
              onApply(number, unit);
            }}
            className={
              classnames(
                'd-flex',
                'align-items-baseline'
              )
            }
            maxLength={2}
          />
        </Dropdown.ItemText>
        <Select
          name='unit'
          options={options}
          value={unit}
          defaultValue={options[1]}
          onChange={(newValue) => {
            setUnit(newValue);
            onApply(number, newValue);
          }}
          menuIsOpen={isDropdownOpen}
          className='mb-0'
          menuPortalTarget={null}
          menuPosition='static'
          menuListClassName='border-0'
          styles={{
            menu: (menuStyles) => (
              { ...menuStyles, position: 'static', marginBottom: 0 }
            ),
            control: (control) => ({ ...control, display: 'none' })
          }}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}

DurationInput.propTypes = propTypes;
DurationInput.defaultProps = defaultProps;

export default DurationInput;
