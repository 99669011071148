import { useQuery } from 'react-query';
import { get } from '@/lib/fetch';

export const backlogsKeys = {
  all: ['backlogs'],
  lists: () => [...backlogsKeys.all, 'list'],
  list: (funnelId, params) => [...backlogsKeys.lists(), funnelId, params]
};

export async function getBacklogs(funnelId, params = {}) {
  const { data } = await get(`/funnels/${funnelId}/backlogs`, params);

  return data;
}

export function useBacklogs({ funnelId, params = {}, config = {} }) {
  return useQuery({
    ...config,
    queryKey: backlogsKeys.list(funnelId, params),
    queryFn: () => getBacklogs(funnelId, params)
  });
}
