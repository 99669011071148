import React from 'react';

import { useOrganizationCustomFields } from '@/api';
import {
  useOrganization,
  useOnCreatePerson,
  useOnUpdate,
  useOnUpdatePerson
} from '@/contexts/organizationModal';
import { organizationEditPath } from '@/routes';
import { canEditOrganization } from '@/policies';
import DetailsContact from '@/components/EntityModal/DetailsContact';

function Sidebar() {
  const organization = useOrganization();
  const onUpdate = useOnUpdate();
  const onUpdatePerson = useOnUpdatePerson();
  const onCreatePerson = useOnCreatePerson();

  const {
    data: { data: organizationsCustomFields } = {},
    isLoading: isLoadingOrganizationsCustomFields
  } = useOrganizationCustomFields(
    { config: { staleTime: 1000 } }
  );

  const entityCustomFields = organizationsCustomFields || [];
  const entityEditPath = organizationEditPath(organization.id);
  const entityType = 'organization';

  const onRefreshModal = ({ data }) => {
    onCreatePerson(data);
  };

  return (
    <div className='me-3'>
      <DetailsContact
        onEditEntity={onUpdate}
        entity={organization}
        entityType={entityType}
        entityTypeModal={entityType}
        entityEditPath={entityEditPath}
        entityCustomFields={entityCustomFields}
        canEditPolicy={canEditOrganization}
        loading={isLoadingOrganizationsCustomFields}
        onCreatePerson={onRefreshModal}
        onEditPerson={onUpdatePerson}
        showTitle={false}
      />
    </div>
  );
}

export default Sidebar;
