
import { useAuth } from '@/lib/auth';
import { useStoredState } from '@/hooks';

export function useUpgradeStatus(key) {
  const { user } = useAuth();

  const {
    value: upgradeStatus,
    store: storeUpgradeStatus
  } = useStoredState(key, { declined: false, user: null });


  const handleUpgradeStatusChange = (status) => {
    storeUpgradeStatus({ ...upgradeStatus, declined: status, user: user.id });
  };

  return {
    handleUpgradeStatusChange,
    upgradeStatus
  };
}
