import React from 'react';
import PropTypes from 'prop-types';
import Select from '@/components/Inputs/Select';

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  allowNull: PropTypes.bool,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func
};

const defaultProps = {
  name: 'area_code_in',
  label: 'DDD',
  placeholder: 'Qualquer DDD',
  multiple: false,
  isClearable: false,
  isSearchable: false,
  allowNull: false,
  defaultValue: null,
  onChange: () => {}
};

const options = [
  {
    label: 'Indefinido',
    value: -1
  },
  {
    label: 'AC',
    options: [{ label: '68', value: 68 }]
  },
  {
    label: 'AL',
    options: [{ label: '82', value: 82 }]
  },
  {
    label: 'AM',
    options: [
      { label: '92', value: 92 },
      { label: '97', value: 97 }
    ]
  },
  {
    label: 'AP',
    options: [{ label: '96', value: 96 }]
  },
  {
    label: 'BA',
    options: [
      { label: '71', value: 71 },
      { label: '72', value: 72 },
      { label: '73', value: 73 },
      { label: '74', value: 74 },
      { label: '75', value: 75 },
      { label: '77', value: 77 }
    ]
  },
  {
    label: 'CE',
    options: [
      { label: '85', value: 85 },
      { label: '88', value: 88 }
    ]
  },
  {
    label: 'DF',
    options: [{ label: '61', value: 61 }]
  },
  {
    label: 'ES',
    options: [
      { label: '27', value: 27 },
      { label: '28', value: 28 }
    ]
  },
  {
    label: 'GO',
    options: [
      { label: '62', value: 62 },
      { label: '64', value: 64 }
    ]
  },
  {
    label: 'MA',
    options: [
      { label: '98', value: 98 },
      { label: '99', value: 99 }
    ]
  },
  {
    label: 'MG',
    options: [
      { label: '31', value: 31 },
      { label: '32', value: 32 },
      { label: '33', value: 33 },
      { label: '34', value: 34 },
      { label: '35', value: 35 },
      { label: '36', value: 36 },
      { label: '37', value: 37 },
      { label: '38', value: 38 }
    ]
  },
  {
    label: 'MS',
    options: [{ label: '67', value: 67 }]
  },
  {
    label: 'MT',
    options: [
      { label: '65', value: 65 },
      { label: '66', value: 66 }
    ]
  },
  {
    label: 'PA',
    options: [
      { label: '91', value: 91 },
      { label: '93', value: 93 },
      { label: '94', value: 94 }
    ]
  },
  {
    label: 'PB',
    options: [{ label: '83', value: 83 }]
  },
  {
    label: 'PE',
    options: [
      { label: '81', value: 81 },
      { label: '87', value: 87 }
    ]
  },
  {
    label: 'PI',
    options: [
      { label: '86', value: 86 },
      { label: '89', value: 89 }
    ]
  },
  {
    label: 'PR',
    options: [
      { label: '41', value: 41 },
      { label: '42', value: 42 },
      { label: '43', value: 43 },
      { label: '44', value: 44 },
      { label: '45', value: 45 },
      { label: '46', value: 46 }
    ]
  },
  {
    label: 'RJ',
    options: [
      { label: '21', value: 21 },
      { label: '22', value: 22 },
      { label: '24', value: 24 }
    ]
  },
  {
    label: 'RN',
    options: [{ label: '84', value: 84 }]
  },
  {
    label: 'RO',
    options: [{ label: '69', value: 69 }]
  },
  {
    label: 'RR',
    options: [{ label: '95', value: 95 }]
  },
  {
    label: 'RS',
    options: [
      { label: '51', value: 51 },
      { label: '52', value: 52 },
      { label: '53', value: 53 },
      { label: '54', value: 54 },
      { label: '55', value: 55 }
    ]
  },
  {
    label: 'SC',
    options: [
      { label: '47', value: 47 },
      { label: '48', value: 48 },
      { label: '49', value: 49 }
    ]
  },
  {
    label: 'SE',
    options: [{ label: '79', value: 79 }]
  },
  {
    label: 'SP',
    options: [
      { label: '11', value: 11 },
      { label: '12', value: 12 },
      { label: '13', value: 13 },
      { label: '14', value: 14 },
      { label: '15', value: 15 },
      { label: '16', value: 16 },
      { label: '17', value: 17 },
      { label: '18', value: 18 },
      { label: '19', value: 19 }
    ]
  },
  {
    label: 'TO',
    options: [{ label: '63', value: 63 }]
  }
];

function AreaCodeSelect(props) {
  return (
    <Select
      { ...props }
      options={props.allowNull ? options : options.slice(1)}
    />
  );
}

AreaCodeSelect.propTypes = propTypes;
AreaCodeSelect.defaultProps = defaultProps;

export default AreaCodeSelect;
