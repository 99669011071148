export function getInitials(name) {
  if (!name) {
    return '';
  }

  const length = 2;

  const words = name.split(' ').slice(0, length);
  const fillLength = length - words.length;

  const initials = words.map((word) => word.charAt(0));

  if (fillLength > 0) {
    const fill = words[0].substr(1, fillLength);

    initials.splice(1, 0, fill);
  }

  return initials.join('');
}
