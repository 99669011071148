import React, { useState } from 'react';

import Alert from '@/components/Alert';
import Icon from '@/components/Icon';
import Tooltip from '@/components/Tooltip';
import ExternalLink from '@/components/ExternalLink';
import { EmailVerifyModal } from '@/feature/email';
import { useEmailSenderList, useUsers } from '@/api';
import { helpUrl } from '@/routes';
import map from 'lodash/map';
import EmailVerifyPopover from '@/feature/email/EmailVerifyPopover';

function EmailVerifyListAlert() {
  const [showModal, setShowModal] = useState(false);
  const onHideModal = () => setShowModal(false);
  const showVerifyModal = () => setShowModal(true);
  const { data: users } = useUsers();

  const { data: emailSenderData, isLoading } = useEmailSenderList({ confirmed: true });

  const userIdsWithConfirmedEmail = map(emailSenderData?.data, 'user.id');

  const usersForVerify = (users?.data || []).reduce((acc, user) => (
    user.active && !userIdsWithConfirmedEmail.includes(user.id) ? [...acc, user] : acc
  ), []);

  if (!usersForVerify.length || isLoading) {
    return null;
  }

  return (
    <Alert className='w-100 mt-3 mt-0 mb-2' variant='danger'>
      <div className='d-flex align-items-center'>
        <span className='fw-bold pt-1'>
          Endereços de e-mails precisam ser verificados
        </span>

        <ExternalLink
          className='text-dark-gray mx-2'
          target='_blank'
          href={helpUrl('email-sender')}
        >
          <Tooltip content='
            Para que seus clientes vejam o seu e-mail ou dos usuários da sua conta como remetente,
            precisamos garantir que o endereço é seu. Clique para saber mais.'
          >
            <div>
              <Icon name='info' />
            </div>
          </Tooltip>
        </ExternalLink>

        <EmailVerifyModal show={showModal} onHide={onHideModal} isList={true} />
        <EmailVerifyPopover usersForVerify={usersForVerify} showVerifyModal={showVerifyModal} />
      </div>

    </Alert>
  );
}

export default EmailVerifyListAlert;
