import React, { forwardRef } from 'react';
import classnames from 'classnames';
import Avatar from '@/components/Avatar';
import DotList from '@/components/DotList';
import EntityIcon from '@/components/EntityIcon';
import Highlighter from '@/components/Highlighter';
import Icon from '@/components/Icon';
import EntityLink from '@/components/EntityLink';
import InputAdornment from '@/components/InputAdornment';
import LoadSpinner from '@/components/LoadSpinner';
import SearchBar from '@/components/SearchBar';
import Tooltip from '@/components/Tooltip';
import { DealIcon } from '@/feature/automation';
import { compact } from '@/array';
import { useScreenSize } from '@/hooks';

function Footer({ isFetching, canFetchMore, isEmpty, fetchMore }) {
  let text, icon, fetchable;

  if (isFetching) {
    text = 'Carregando';
    icon = <LoadSpinner className='me-1 text-dark-gray opacity-100' />;
    fetchable = false;
  } else if (canFetchMore) {
    text = 'Mostrar mais resultados';
    icon = <Icon className='me-1 text-primary' name='arrow-down' />;
    fetchable = true;
  } else if (isEmpty) {
    text = 'Nenhum resultado encontrado';
    icon = null;
    fetchable = false;
  } else {
    return null;
  }

  return (
    <div
      className={classnames(
        'd-flex',
        'align-items-center',
        'justify-content-center',
        'p-5',
        { 'cursor-pointer': fetchable }
      )}
      onClick={() => fetchable && fetchMore()}
    >
      {icon}

      <span className='text-dark-gray fw-bold'>
        {text}
      </span>
    </div>
  );
}

function Item({ item, term, onHide }) {
  const {
    type, allowed,
    data: { name, title, organization, person, category, ownerUser }
  } = item;

  const linkProps = allowed ? { entity: item } : { entity: {} };

  return (
    <EntityLink
      className={classnames(
        'search-result-item',
        'd-flex',
        'flex-row',
        'text-body',
        'hover-bg-light',
        'p-2',
        'rounded',
        'border-bottom',
        'border-light',
        'border-1'
      )}
      onClick={onHide}
      { ...linkProps }
    >
      <EntityIcon
        className={classnames('me-2', { 'opacity-50': !allowed })}
        type={type}
        data={item.data}
      />

      <div className={classnames(
        'd-flex',
        'flex-column',
        'justify-content-center',
        { 'opacity-50': !allowed }
      )}>
        <span className='d-flex flex-row align-items-center'>
          <DealIcon deal={item.data} />
          <Highlighter text={name || title} term={term} />
        </span>

        <DotList
          className='text-muted'
          items={compact([
            person &&
            <Highlighter text={person.name} term={term} />,
            organization &&
            <Highlighter text={organization.name} term={term} />,
            category && category.name
          ])}
        />
      </div>

      <div className='width-3 d-flex align-items-center ms-auto flex-shrink-0'>
        {!allowed &&
          <Tooltip
            placement='right'
            content={
              <span className='fw-normal'>
                <h6 className='mb-2'>
                  Esse registro possui privacidade fechada.
                </h6>

                <p className='mb-2'>
                  Isso significa que apenas os responsáveis por esse cliente
                  podem acessar seus dados.
                </p>

                <p className='mb-0'>
                  Para obter acesso, solicite ao seu gestor.
                </p>
              </span>
            }
          >
            <div className='ms-1'>
              <Icon name='lock' size='sm' />
            </div>
          </Tooltip>
        }

        {ownerUser &&
          <Avatar
            className='ms-auto'
            name={ownerUser.name}
            url={ownerUser.avatarUrl}
            tooltip={false}
            size='lg'
          />
        }
      </div>
    </EntityLink>
  );
}

const HeaderSearch = forwardRef(({ onClick, onMouseLeave, isSearchActive, onClear }, ref) => {
  const { isMD } = useScreenSize();

  const showSearchBar = !isMD || isSearchActive;

  const inputProps = {
    autoFocus: isMD,
    placeholder: 'Buscar',
    isClearable: true,
    onClear,
    autoComplete: 'off',
    maxLength: 100,
    rightAdornment: ({ focus }) => (
      <InputAdornment onClick={focus} alignment='right'>
        <Icon className='text-gray' name='search' />
      </InputAdornment>
    )
  };

  const popoverProps = {
    className: 'popover-padding-0 max-width-6 width-6',
    size: 'lg',
    placement: 'bottom-end'
  };

  const resultsFooter = (props) => <Footer { ...props } />;

  return (
    <>
      <div onClick={onClick} className={classnames(
        'd-lg-none',
        'align-self-center',
        'btn',
        'btn-transparent-dark',
        'btn-sm',
        'me-4',
        isSearchActive ? 'd-none' : 'd-block'
      )}
      >
        <Icon name='search' className='text-white nav-icon'/>
      </div>
      { showSearchBar && <SearchBar
        ref={ref}
        onMouseLeave={onMouseLeave}
        name='header-search'
        className={classnames(
          'd-lg-block',
          'align-self-center',
          'me-6',
          {
            'w-100': isSearchActive
          }
        )}
        resultsFooter={resultsFooter}
        ItemComponent={Item}
        inputProps={inputProps}
        popoverProps={popoverProps}
      />}
    </>
  );
});

HeaderSearch.displayName = 'HeaderSearch';

export default HeaderSearch;
