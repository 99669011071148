import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useAlert } from 'react-alert';
import { useAuth } from '@/lib/auth';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import ConfirmationModal from '@/components/ConfirmationModal';
import Authorization from '@/components/Authorization';
import { canDeleteCustomField } from '@/policies';
import { getString } from '@/utils';
import { useTracking } from '@/lib/tracking';

const propTypes = {
  data: PropTypes.object.isRequired,
  mutation: PropTypes.object.isRequired,
  entity: PropTypes.oneOf(['organization', 'person', 'deal']).isRequired
};

const defaultProps = {};

function DeleteCustomFields({ data, mutation, entity }) {
  const { user } = useAuth();
  const tracker = useTracking();
  const alert = useAlert();
  const [showModal, setShowModal] = useState(false);

  const isAdmin = user && user.admin;
  const entityName = getString(['models', entity]);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDelete = () => {
    mutation.mutate({ customFieldId: data.id }, {
      onSuccess: () => {
        alert.show(
          'Campo customizado excluído!',
          { variant: 'success', timeout: 5000 }
        );

        tracker.trackCustomFieldDeleted({ user, entity, type: data.type });

        handleCloseModal();
      },
      onError: () => {
        alert.show(
          'Erro ao excluir campo!',
          { variant: 'danger', timeout: 5000 }
        );
        handleCloseModal();
      }
    });
  };

  return (
    <>
      <Authorization
        policy={canDeleteCustomField}
        /*
         * Foi necessario incluir tooltipClassName='mx-4' para adicionar uma
         * espaço seguro no hover entre tooltip de edit e delete, caso contrario
         * o tooltip se esconde ao transitar de um botao para o outro.
         * No futuro, uma soluçao ideal seria usar prop offset do componente Tooltip
         */
        tooltipClassName='mx-4'
      >
        <Button
          id={`delete-field-${data.identifier}`}
          onClick={handleOpenModal}
          size='sm'
          variant='link'
          className='px-1 py-2 border-0 cursor-pointer'
          aria-label='Excluir campo'
        >
          <Icon
            name='delete'
            className={classnames(
              'cursor-pointer',
              { 'text-dark-gray': isAdmin }
            )}
          />
        </Button>
      </Authorization>
      <ConfirmationModal
        confirmationText='Sim, excluir'
        show={showModal}
        onConfirm={handleDelete}
        onHide={handleCloseModal}
      >
        <ConfirmationModal.Title>
          Deseja excluir?
        </ConfirmationModal.Title>

        <ConfirmationModal.Info>
          Você deseja realmente excluir este campo? Após confirmação,
          o campo <span className='fw-bold'>{data.name}</span> não estará
          mais disponível no formulário de cadastro de {entityName}.
        </ConfirmationModal.Info>
      </ConfirmationModal>
    </>
  );
}

DeleteCustomFields.propTypes = propTypes;
DeleteCustomFields.defaultProps = defaultProps;

export default DeleteCustomFields;
