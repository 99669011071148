import React, { useRef, useEffect } from 'react';
import Inputmask from 'inputmask';
import { TextInput } from '@/components/Inputs';

function IntegerInput(props) {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      const mask = new Inputmask({
        alias: 'integer',
        allowMinus: false,
        clearMaskOnLostFocus: false,
        nullable: false,
        rightAlign: false,
        placeholder: '0',
        showMaskOnFocus: false,
        showMaskOnHover: false
      });

      mask.mask(ref.current);
    }
  }, []);

  return (
    <TextInput
      ref={ref}
      { ...props }
    />
  );
}

export default IntegerInput;
