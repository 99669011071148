import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useCurrentAccount } from '@/api';
import { Col, Row } from 'react-bootstrap';
import DetailsEmail from '@/components/EntityModal/Deal/DetailsEmail';
import DetailsProposal from '@/components/EntityModal/Deal/DetailsProposal';
import DetailsVoip from '@/components/EntityModal/Deal/DetailsVoip';
import DetailsCard from '@/components/EntityModal/DetailsCard';
import DetailsWhatsApp from '@/components/EntityModal/Deal/DetailsWhatsApp';
import Skeleton from '@/components/Skeleton';
import { useMediaQuery } from '@/hooks';

DetailsIntegrations.defaultProps = {
  deal: {},
  voip: {},
  loading: true,
  entity: {},
  entityType: '',
  onCreateProposal: () => {},
  onRefreshModal: () => {},
  onUpdateEntity: () => {}
};

DetailsIntegrations.propTypes = {
  deal: PropTypes.object,
  voip: PropTypes.object,
  loading: PropTypes.bool,
  entity: PropTypes.object,
  entityType: PropTypes.string,
  onCreateProposal: PropTypes.func,
  onRefreshModal: PropTypes.func,
  onUpdateEntity: PropTypes.func
};

function DetailsIntegrations({
  deal,
  voip,
  loading,
  entity,
  entityType,
  onCreateProposal,
  onRefreshModal,
  onUpdateEntity
}) {
  const { data: accountData, isLoading: isLoadingAccount } = useCurrentAccount();
  const account = useMemo(() => accountData?.data, [accountData]);

  const isBelow1200 = useMediaQuery('(max-width: 1200px)');
  /* eslint-disable no-magic-numbers */
  const COL_SIZE = isBelow1200 ? 12 : 6;
  /* eslint-enable no-magic-numbers */

  if (loading || isLoadingAccount) {
    return <Skeleton width='100%' height={200} className='mb-3' />;
  }

  return (
    <DetailsCard title='Ações' className='mb-3' actionTitle>
      <Row className={`justify-content-center overflow-hidden ${isBelow1200 ? 'flex-column' : ''}`}>
        <Col sm={COL_SIZE} className={
          `d-flex flex-column align-items-center
          ${isBelow1200 ? 'mb-2' : 'me-n6'}`
        }>
          <DetailsEmail dealId={deal.id} showText />
          <DetailsProposal
            deal={deal}
            account={account}
            entity={entity}
            entityType={entityType}
            onCreateProposal={onCreateProposal}
          />
        </Col>
        <Col sm={COL_SIZE} className={
          `d-flex flex-column align-items-center
          ${isBelow1200 ? '' : 'me-n6'}`
        }>
          <DetailsVoip
            deal={deal}
            voip={voip}
            entity={entity}
            entityType={entityType}
            onRefreshModal={onRefreshModal}
            account={account}
            onUpdateEntity={onUpdateEntity}
          />
          <DetailsWhatsApp
            deal={deal}
            voip={voip}
            entity={entity}
            entityType={entityType}
            onRefreshModal={onRefreshModal}
            onUpdateEntity={onUpdateEntity}
          />
        </Col>
      </Row>
    </DetailsCard>
  );
}

export default DetailsIntegrations;
