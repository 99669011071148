import { useState } from 'react';

/**
 * Hook para controle de estado de Stepper, o índice do primeiro passo é 0
 *
 * @param {*} stepCount Quantidade de passos
 * @param {*} initialStep Índice do passo inicial
 */
export function useStepper(stepCount, initialStep = 0) {
  const [step, setStep] = useState(initialStep);

  const prevStep = () => setStep(Math.max(step - 1, 0));
  const nextStep = () => setStep(Math.min(step + 1, stepCount));

  return { step, prevStep, nextStep, goToStep: setStep };
}
