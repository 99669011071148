import React from 'react';
import PropTypes from 'prop-types';
import Base from 'react-bootstrap/Offcanvas';

const propTypes = {
  className: PropTypes.string,
  content: PropTypes.node,
  children: PropTypes.node,
  placement: PropTypes.oneOf([
    'start',
    'end',
    'top',
    'bottom'
  ])
};

const defaultProps = {
  className: null,
  children: null,
  placement: 'end'
};

function Offcanvas({ children, ...props }) {
  return (
    <>
      <Base
        { ...props }
      >
        {children}
      </Base>
    </>
  );
}

Offcanvas.propTypes = propTypes;
Offcanvas.defaultProps = defaultProps;

Offcanvas.Header = Base.Header;
Offcanvas.Title = Base.Title;
Offcanvas.Body = Base.Body;

export default Offcanvas;
