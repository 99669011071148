import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as Yup from 'yup';
import Form from '@/components/Form';
import AsyncFormField from '@/components/AsyncFormField';
import FormField from '@/components/FormField';
import {
  TextInput,
  MaskedInput,
  CategorySelect,
  CNPJField,
  LeadOriginSelect,
  SectorSelect,
  UserSelect,
  TextArea,
  WhatsAppInput,
  AddressFields,
  ProductSelect,
  PeopleFields,
  PrivacyInput,
  ImagePicker
} from '@/components/Inputs';
import { useOrganizationValidation } from '@/api';
import Button from '@/components/Button';
import LoadingButton from '@/components/LoadingButton';
import Icon from '@/components/Icon';
import Footer from '@/components/Footer';
import {
  schema as addressSchema, defaultValues as addressDefaultValues
} from '@/components/Inputs/AddressFields';
import {
  schema as privacySchema, defaultValues as privacyDefaultValues
} from '@/components/Inputs/PrivacyInput';
import { schema as peopleSchema } from '@/components/Inputs/PeopleFields';
import { CustomFieldsSection, customFieldsSchema } from '@/components/CustomFields';


/* eslint-disable no-magic-numbers */
const schema = Yup.object().shape({
  name: Yup
    .string()
    .max(150, 'O nome da empresa deve ter no máximo 150 caracteres.')
    .required('Por favor, informe o nome da empresa.')
    .when('name_async', ([value], fieldSchema) => (
      value
        ? fieldSchema.notOneOf([value], 'Já existe uma empresa com esse nome.')
        : fieldSchema
    )),
  cnpj: Yup
    .string()
    .length(14, 'CNPJ inválido.')
    .when('cnpj_async', ([value], fieldSchema) => (
      value
        ? fieldSchema.notOneOf([value], 'Já existe uma empresa com esse CNPJ.')
        : fieldSchema
    )),
  legal_name: Yup
    .string()
    .max(255, 'A razão social da empresa deve ter no máximo 255 caracteres.'),
  category_id: Yup
    .string()
    .nullable(),
  lead_origin_id: Yup
    .string()
    .nullable(),
  owner_user_id: Yup
    .string()
    .nullable(),
  sector_id: Yup
    .string()
    .nullable(),
  description: Yup
    .string()
    .max(700, 'A descrição da empresa deve ter no máximo 700 caracteres.'),
  products_id: Yup
    .array(Yup.string())
    .nullable(),
  website: Yup.string()
    .max(150, 'O website da empresa deve ter no máximo 150 caracteres.'),
  contact: Yup.object().shape({
    email: Yup.string()
      .email('E-mail inválido.'),
    whatsapp: Yup.string()
      .min(12, 'Número inválido.')
      .max(16, 'Número inválido.'),
    work_phone: Yup.string()
      .min(10, 'Número inválido.')
      .max(11, 'Número inválido.'),
    fax_phone: Yup.string()
      .min(10, 'Número inválido.')
      .max(11, 'Número inválido.'),
    phone_extension: Yup.string()
      .max(5, 'Ramal inválido'),
    mobile_phone: Yup.string()
      .min(10, 'Número inválido.')
      .max(11, 'Número inválido.'),
    linkedin: Yup.string()
      .max(255, 'LinkedIn deve ter no máximo 255 caracteres'),
    skype: Yup.string()
      .max(255, 'Skype deve ter no máximo 255 caracteres'),
    instagram: Yup.string()
      .max(255, 'Instagram deve ter no máximo 255 caracteres'),
    twitter: Yup.string()
      .max(255, 'Twitter deve ter no máximo 255 caracteres'),
    facebook: Yup.string()
      .max(255, 'Facebook deve ter no máximo 255 caracteres')
  }),
  address: addressSchema,
  privacy: privacySchema,
  people: peopleSchema,
  custom_fields: customFieldsSchema
});
/* eslint-enable no-magic-numbers */


const defaultValues = {
  name: '',
  cnpj: '',
  legal_name: '',
  website: '',
  description: '',
  owner_user_id: '',
  category_id: '',
  lead_origin_id: '',
  products_id: '',
  sector_id: '',
  privacy: {
    type: 'default'
  },
  contact: {
    email: '',
    whatsapp: '',
    work_phone: '',
    fax_phone: '',
    phone_extension: '',
    mobile_phone: '',
    linkedin: '',
    skype: '',
    instagram: '',
    twitter: '',
    facebook: ''
  },
  ...addressDefaultValues,
  ...privacyDefaultValues,
  people: [],
  logo: null,
  custom_fields: {}
};

const propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  customFields: PropTypes.array,
  saveButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  organizationId: PropTypes.number,
  onDelete: PropTypes.func
};

const defaultProps = {
  initialValues: {},
  saveButtonText: 'Salvar',
  cancelButtonText: 'Cancelar'
};

function FullOrganizationForm({
  onSubmit, onCancel, initialValues, saveButtonText,
  cancelButtonText, onDelete, customFields,
  organizationId
}) {
  return (
    <Form
      validationSchema={schema}
      validationContext={{ customFields }}
      initialValues={initialValues}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      className='flex-grow-1 d-flex flex-column'
    >
      {({ handleSubmit, handleBlur, isSubmitting }) => (
        <>
          <Container className='mt-8'>
            <Row>
              <Col
                md={{ span: 3, offset: 1 }}
                lg={{ span: 2, offset: 1 }}
              >
                <FormField
                  as={ImagePicker}
                  name='logo'
                  label='logotipo'
                  iconName='organization'
                />
              </Col>

              <Col md={7} lg={8}>
                <h2 className='my-4'>
                  Dados básicos
                </h2>

                <Row>
                  <Col sm={12}>
                    <AsyncFormField
                      as={TextInput}
                      query={useOrganizationValidation}
                      extraQueryArgs={{ excludedId: organizationId }}
                      name='name'
                      label='Nome'
                      autoComplete='off'
                      placeholder='Digite o nome'
                      hint='Obrigatório'
                      maxLength={150}
                      variant='light'
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>

                <Row className='gx-3'>
                  <Col sm={6}>
                    <CNPJField
                      organizationId={organizationId}
                    />
                  </Col>
                  <Col sm={6}>
                    <FormField
                      as={TextInput}
                      name='legal_name'
                      label='Razão social'
                      autoComplete='off'
                      placeholder='Digite a razão social'
                      variant='light'
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>

                <Row className='gx-3'>
                  <Col sm={6}>
                    <FormField
                      as={CategorySelect}
                      name='category_id'
                      label='Categoria'
                      placeholder='Selecione'
                    />
                  </Col>
                  <Col sm={6}>
                    <FormField
                      as={LeadOriginSelect}
                      name='lead_origin_id'
                      label='Origem'
                      placeholder='Selecione'
                    />
                  </Col>
                </Row>

                <Row className='gx-3'>
                  <Col sm={6}>
                    <FormField
                      as={UserSelect}
                      name='owner_user_id'
                      label='Responsável'
                      placeholder='Selecione'
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col sm={6}>
                    <FormField
                      as={SectorSelect}
                      name='sector_id'
                      label='Setor'
                      placeholder='Selecione'
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm={12}>
                    <FormField
                      as={TextArea}
                      name='description'
                      label='Descrição'
                      placeholder='Escreva detalhes importantes sobre esse cliente'
                      maxLength={700}
                      variant='light'
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>

                <CustomFieldsSection
                  section='basic_data'
                  fields={customFields}
                  className='gx-3'
                />

                <hr className='mb-6' />

                <h2 className='my-4'>
                  Informações para contato
                </h2>

                <p className='subtitle text-dark-gray'>
                  Adicione informações que facilitem o contato com o cliente.
                </p>

                <Row className='gx-3'>
                  <Col sm={6}>
                    <FormField
                      as={MaskedInput}
                      name='contact.email'
                      maskType='email'
                      label='E-mail'
                      placeholder='exemplo@email.com'
                      maxLength={700}
                      variant='light'
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col sm={6}>
                    <FormField
                      as={WhatsAppInput}
                      name='contact.whatsapp'
                      label='WhatsApp'
                      placeholder='+00 00 00000-0000'
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>

                <Row className='gx-3'>
                  <Col sm={6}>
                    <FormField
                      as={MaskedInput}
                      name='contact.work_phone'
                      label='Telefone'
                      maskType='phone'
                      placeholder='(00) 0000-0000'
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col sm={6}>
                    <FormField
                      as={MaskedInput}
                      name='contact.mobile_phone'
                      label='Celular'
                      maskType='phone'
                      placeholder='(00) 0000-0000'
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>

                <Row className='gx-3'>
                  <Col sm={6}>
                    <FormField
                      as={MaskedInput}
                      maskType='phone'
                      name='contact.fax_phone'
                      label='Fax'
                      placeholder='(00) 0000-0000'
                      onBlur={handleBlur}
                    />

                  </Col>
                  <Col sm={6}>
                    <FormField
                      as={TextInput}
                      name='contact.phone_extension'
                      label='Ramal'
                      placeholder='0000'
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>
                <Row className='gx-3'>
                  <Col sm={6}>
                    <FormField
                      as={TextInput}
                      name='website'
                      label='Website'
                      maxLength={150}
                      placeholder='www.exemplo.com.br'
                      onBlur={handleBlur}
                    />
                  </Col>

                  <CustomFieldsSection
                    section='contact'
                    fields={customFields}
                    useRowContainer={false}
                  />
                </Row>

                <hr className='mb-6' />

                <h2 className='my-4'>
                  Dados do endereço
                </h2>

                <p className='subtitle text-dark-gray'>
                  Adicione a localização do seu cliente.
                </p>

                <AddressFields />

                <CustomFieldsSection
                  section='address'
                  fields={customFields}
                  className='gx-3'
                />

                <hr className='mb-6' />

                <h2 className='my-4'>
                  Produtos e serviços
                </h2>

                <p className='subtitle text-dark-gray'>
                  Quais esta empresa tem potencial de compra?
                </p>

                <FormField
                  as={ProductSelect}
                  name='products_id'
                  label=''
                  placeholder='Buscar...'
                  onBlur={handleBlur}
                  multiple
                  isSearchable
                  queryArgs={{ params: { per_page: 600 } }}
                />

                <hr className='mb-6' />

                <h2 className='my-4'>
                  Pessoas da empresa
                </h2>

                <p className='subtitle text-dark-gray'>
                  Adicione seus contatos diretos de dentro da empresa.
                </p>

                <PeopleFields
                  InitialAddButton={({ onClick }) => (
                    <Button
                      onClick={onClick}
                      variant='link'
                      className='fw-bold ps-0 border-0'
                    >
                      <Icon name='plus' size='sm' className='me-1' />
                      <span>Adicionar pessoas</span>
                    </Button>
                  )}
                />

                <hr className='my-6' />

                <h2 className='my-4'>
                  Redes sociais
                </h2>

                <Row className='gx-3'>
                  <Col sm={6}>
                    <FormField
                      as={TextInput}
                      name='contact.facebook'
                      label='Facebook'
                      placeholder='facebook.com/agendor'
                      onBlur={handleBlur}
                      variant='light'
                    />
                  </Col>
                  <Col sm={6}>
                    <FormField
                      as={TextInput}
                      name='contact.twitter'
                      label='Twitter'
                      placeholder='twitter.com/agendor'
                      onBlur={handleBlur}
                      variant='light'
                    />
                  </Col>
                </Row>

                <Row className='gx-3'>
                  <Col sm={6}>
                    <FormField
                      as={TextInput}
                      name='contact.linkedin'
                      label='LinkedIn'
                      placeholder='linkedin.com/in/agendor'
                      onBlur={handleBlur}
                      variant='light'
                    />
                  </Col>
                  <Col sm={6}>
                    <FormField
                      as={TextInput}
                      name='contact.skype'
                      label='Skype'
                      placeholder='agendor'
                      onBlur={handleBlur}
                      variant='light'
                    />
                  </Col>
                </Row>

                <Row className='gx-3'>
                  <Col sm={6}>
                    <FormField
                      as={TextInput}
                      name='contact.instagram'
                      label='Instagram'
                      placeholder='instagram.com/agendor'
                      onBlur={handleBlur}
                      variant='light'
                    />
                  </Col>
                </Row>

                <hr className='mb-6' />

                <h2 className='my-4'>
                  Privacidade
                </h2>

                <p className='subtitle text-dark-gray'>
                  Quem pode ver o histórico e editar essa empresa?
                </p>

                <Row className='mb-2'>
                  <Col>
                    <PrivacyInput />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>

          <Footer className='mt-auto flex-shrink-0 py-4 shadow-up bg-white'>
            <Container>
              <Row>
                <Col
                  md={{ offset: 1, span: 10 }}
                  className='d-flex'
                >
                  {
                    onDelete &&
                      <Button
                        className='text-danger fw-bold px-0'
                        variant='link'
                        onClick={onDelete}
                      >
                        <Icon name='delete' className='me-2 my-n1' />
                        <span>Excluir empresa</span>
                      </Button>
                  }

                  <Button
                    className='ms-auto me-3'
                    variant='outline-darker-gray'
                    onClick={onCancel}
                    disabled={isSubmitting}
                  >
                    {cancelButtonText}
                  </Button>

                  <LoadingButton
                    onClick={handleSubmit}
                    isLoading={isSubmitting}
                    className='min-width-4 px-8'
                  >
                    {saveButtonText}
                  </LoadingButton>
                </Col>
              </Row>
            </Container>
          </Footer>
        </>
      )}
    </Form>
  );
}

FullOrganizationForm.defaultProps = defaultProps;
FullOrganizationForm.propTypes = propTypes;

export default FullOrganizationForm;
