import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CreateDealModal from '@/components/CreateDealModal';
import EntityStep from '@/components/CreateDealModal/EntityStep';

const propTypes = {
  /** Flag que determina se o modal deve ser exibido ou não. */
  show: PropTypes.bool,
  /**
   * Callback chamado quando o modal quer ser escondido.
   * Controla a prop `show`.
   */
  onHide: PropTypes.func,
  /** Funil em que o negócio será criado. */
  funnel: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  /** ID da etapa em que o negócio será criado. */
  stageId: PropTypes.number,
  /** Callback chamado quando a criação do negócio é concluída. */
  onFinish: PropTypes.func
};

const defaultProps = {};

function CreateDealEntityModal(props) {
  const [entity, setEntity] = useState(null);
  const { onHide } = props;

  const onExited = () => setEntity(null);

  return (
    <CreateDealModal
      { ...props }
      entity={entity}
      onExited={onExited}
    >
      <EntityStep onSelect={setEntity} onCancel={onHide} />
    </CreateDealModal>
  );
}

CreateDealEntityModal.propTypes = propTypes;
CreateDealEntityModal.defaultProps = defaultProps;

export default CreateDealEntityModal;
