import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '@/components/Icon';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';

const propTypes = {
  setPaymentData: PropTypes.func.isRequired,
  showRegistrationMessage: PropTypes.bool
};

const defaultProps = {
  showRegistrationMessage: true
};

function StripeCreditCardForm({ setPaymentData, showRegistrationMessage }) {
  return (
    <>
      <StripePaymentElement setPaymentData={setPaymentData} />
      <div className='text-dark-gray text-smaller mt-3'>
        <div className='mb-1 d-flex align-items-center'>
          <div className='me-2'>
            <Icon name='checkmark-security-lock' />
          </div>

          <span>Sua transação é 100% segura (Os dados do seu cartão estão protegidos).</span>
        </div>

        {showRegistrationMessage && (
          <div className='d-flex align-items-center'>
            <div className='me-2'>
              <Icon name='card-pay' />
            </div>

            <span>
              O cadastramento do cartão ficará salvo para transações futuras,
              recorrentes e automáticas.
            </span>
          </div>
        )}
      </div>
    </>
  );
}

const StripePaymentElement = ({ setPaymentData }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [prevEvent, setPrevEvent] = useState({});

  const handleFormComplete = (complete) => {
    if (complete) {
      const { error: submitError } = elements.submit();
      return submitError;
    }
  };

  const paymentElementOptions = {
    fields: {
      billingDetails: {
        name: 'auto',
        address: {
          country: 'never'
        }
      }
    }
  };

  return (
    <PaymentElement
      onChange={(event) => {
        if (prevEvent.complete !== event.complete) {
          const error = handleFormComplete(event.complete);

          setPaymentData((prev) => ({ ...prev, event, stripe, elements, error }));
          setPrevEvent(event);
        }
      }}
      options={paymentElementOptions}
    />
  );
};

StripeCreditCardForm.propTypes = propTypes;
StripeCreditCardForm.defaultProps = defaultProps;

export default StripeCreditCardForm;
