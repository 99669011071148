import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@/components/Icon';
import Tooltip from '@/components/Tooltip';
import strings from '@/strings';
import ExternalLink from '@/components/ExternalLink';
import { EMAIL_STATUSES } from '@/utils';
import { helpUrl } from '@/routes';

const propTypes = {
  status: PropTypes.string,
  statusMessage: PropTypes.string
};

const defaultProps = {
  status: null
};

const PENDING_MESSAGE = 'O e-mail está em nossa fila de processamento e será enviado em breve';

const FAILED_MESSAGES = {
  'bounce': 'Envio não realizado, clique aqui para saber mais.',
  'no-email': 'Não há contato vinculado a esse negócio, clique aqui para saber mais.',
  'unknown': 'Ocorreu um erro inesperado. Entre em contato com nosso suporte.'
};

function EmailStatusBadge({ status, statusMessage }) {
  if (status !== null) {
    const isEmailPending = status === EMAIL_STATUSES.PENDING;
    const isEmailFailed = status === EMAIL_STATUSES.FAILED;
    const isEmailReceived = status === EMAIL_STATUSES.RECEIVED;

    const badgeLabel = strings.models.email.status[status];
    const failedMessage = FAILED_MESSAGES[statusMessage];
    const href = statusMessage !== 'unknown' && helpUrl('email-failed');

    const badgeData = () => {
      if (isEmailReceived) {
        return {
          icon: 'double-check',
          label: strings.models.email.status.received,
          color: 'text-success'
        };
      }

      if (isEmailFailed) {
        return {
          icon: 'close',
          label: strings.models.email.status.failed,
          color: 'text-danger',
          message: failedMessage,
          href
        };
      }

      if (isEmailPending) {
        return {
          icon: 'check',
          label: strings.models.email.status.pending,
          color: 'text-warning',
          message: PENDING_MESSAGE
        };
      }

      return {
        icon: 'check',
        label: badgeLabel,
        color: 'text-success'
      };
    };

    const badge = badgeData();

    return (
      <Tooltip
        content={badge.message}
        hide={!badge.message}
      >
        <ExternalLink target='_blank' href={badge.href}>
          <span className={badge.color}>
            <Icon name={badge.icon} className='me-1' />
            {badge.label}
          </span>
        </ExternalLink>

      </Tooltip>
    );
  }
  return null;
}

EmailStatusBadge.propTypes = propTypes;
EmailStatusBadge.defaultProps = defaultProps;

export default EmailStatusBadge;
