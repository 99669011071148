import { getCookie, setCookie } from '@/browser';
import { betaFullUrl, DEALS_VIEW_PAGES } from '@/routes';

const REDIRECT_TO_COOKIE_NAME = 'redirectTo';
const DEALS_VIEW_COOKIE_NAME = 'viewNegocios';
const BETA_URL_COOKIE_NAME = 'BETA_URL';

export function useCookies() {
  return { setCookie, getCookie, setLoginRedirectCookie, setupLoginRedirect };
}

function setLoginRedirectCookie(path) {
  return setCookie(REDIRECT_TO_COOKIE_NAME, path, { domain: 'all' });
}

export function setupLoginRedirect(path) {
  const currentLocation = betaFullUrl(path);
  return setLoginRedirectCookie(currentLocation);
}

export function setDealsViewCookie(page) {
  const dealsViewPage = DEALS_VIEW_PAGES[page] || '';

  return setCookie(DEALS_VIEW_COOKIE_NAME, dealsViewPage, { domain: 'all' });
}

export function getDealsViewCookie() {
  return getCookie(DEALS_VIEW_COOKIE_NAME);
}

export function setupBetaUrlCookie() {
  return setCookie(BETA_URL_COOKIE_NAME, betaFullUrl(), { domain: 'all' });
}
