import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '@/components/Icon';

const propTypes = {
  variant: PropTypes.oneOf([
    'standard',
    'spoiler'
  ]),
  name: PropTypes.string.isRequired
};

const defaultProps = {
  variant: 'standard'
};

function TemplateIcon({ variant, name }) {
  return (
    <Icon
      name={name}
      className={classnames(
        'me-2 flex-shrink-0',
        {
          'text-flat-green': variant === 'standard',
          'text-dark-gray': variant === 'spoiler'
        }
      )}
    />
  );
}

TemplateIcon.propTypes = propTypes;
TemplateIcon.defaultProps = defaultProps;

export default TemplateIcon;
