import { useInfiniteQuery } from 'react-query';
import { get } from '@/lib/fetch';

export const searchesKeys = {
  infiniteAll: ['infinite-searches'],
  infiniteLists: () => [...searchesKeys.infiniteAll, 'list'],
  infiniteList: (params) => [...searchesKeys.infiniteLists(), params]
};

export async function getSearch(params = {}) {
  const { data } = await get('/search', params);

  return data;
}

export function useInfiniteSearch({ params = {}, config = {} }) {
  return useInfiniteQuery(
    searchesKeys.infiniteList(params),
    ({ pageParam: page = 1 }) => getSearch({ ...params, page }),
    {
      ...config,
      getNextPageParam: (lastPage) => lastPage.meta.next
    }
  );
}
