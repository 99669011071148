import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { validateCNPJ, validateCPF } from 'validations-br';
import Button from '@/components/Button';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import { MaskedInput, TextInput } from '@/components/Inputs';
import LoadingButton from '@/components/LoadingButton';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  accountData: PropTypes.object,
  isSubmitting: PropTypes.bool
};

const defaultProps = {
  accountData: null,
  isSubmitting: false
};

const CPF_LENGTH = 11;
const CNPJ_LENGTH = 14;

/* eslint-disable no-magic-numbers */
const schema = Yup.object().shape({
  cpf_cnpj: Yup.string()
    .test({
      test: (value, ctx) => {
        const hasValue = Boolean(value);
        if (!hasValue) {
          return ctx.createError({ message: 'CPF ou CNPJ é um campo obrigatório' });
        }
        const validSize = [CPF_LENGTH, CNPJ_LENGTH].includes(value.length);
        if (!validSize) {
          return ctx.createError({ message: 'CPF ou CNPJ inválido.' });
        }

        if (value.length === CPF_LENGTH && !validateCPF(value)) {
          return ctx.createError({ message: 'CPF inválido.' });
        }
        if (value.length === CNPJ_LENGTH && !validateCNPJ(value)) {
          return ctx.createError({ message: 'CNPJ inválido.' });
        }
        return true;
      }
    }),
  legal_name: Yup.string()
    .required('Nome ou razão social é um campo obrigatório')
    .max(255, 'O nome ou razão social da empresa deve ter no máximo 255 caracteres.'),
  contact: Yup.object().shape({
    work_phone: Yup.string()
      .required('Telefone é um campo obrigatório')
      .min(10, 'Número inválido.')
      .max(11, 'Número inválido.')
  })
});
  /* eslint-enable no-magic-numbers */
function UpdateCpfCnpjForm({ accountData, onSubmit, onCancel, isSubmitting }) {
  const initialValues = useMemo(() => ({
    name: accountData.name ?? '',
    legal_name: accountData.legalName ?? '',
    cpf_cnpj: accountData.cpfCnpj ?? '',
    billing_emails: accountData.billingEmails?.join(',') ?? '',
    voip_activation: true,
    contact: {
      work_phone: accountData.contact?.workPhone?.replace(/[\D]/gu, '') ?? ''
    }
  }), [accountData]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      {
        ({ handleSubmit }) => (
          <>
            { !initialValues.cpf_cnpj
              ? (
                <FormField
                  as={MaskedInput}
                  name='cpf_cnpj'
                  label='CPF ou CNPJ'
                  maskType='cpf_cnpj'
                  placeholder='Digite seu CPF ou CNPJ da sua empresa'
                />
              )
              : null}
            { !initialValues.legal_name
              ? (
                <FormField
                  as={TextInput}
                  name='legal_name'
                  label='Nome ou razão social'
                  placeholder='Digite o nome ou a razão social da sua empresa'
                />
              )
              : null}
            { !initialValues.contact.work_phone
              ? (
                <FormField
                  as={MaskedInput}
                  name='contact.work_phone'
                  label='Telefone'
                  maskType='phone'
                  placeholder='(00) 0000-0000'
                />
              )
              : null}

            <div className='d-flex justify-content-end mt-2'>
              <Button
                variant='light'
                className='me-2'
                onClick={onCancel}
                disabled={isSubmitting}
              >
                Fechar
              </Button>
              <LoadingButton
                isLoading={isSubmitting}
                loadingText='Ativando...'
                onClick={handleSubmit}>
                  Ativar
              </LoadingButton>
            </div>
          </>
        )
      }
    </Form>
  );
}

UpdateCpfCnpjForm.propTypes = propTypes;
UpdateCpfCnpjForm.defaultProps = defaultProps;

export default UpdateCpfCnpjForm;
