import React, { useState } from 'react';
import * as Yup from 'yup';
import { useAlert } from 'react-alert';
import { useParams, useNavigate } from 'react-router-dom';
import Main from '@/components/Main';
import Breadcrumb from '@/components/Breadcrumb';
import { StageSelect } from '@/components/Inputs';
import Button from '@/components/Button';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import APIErrorMessage from '@/components/APIErrorMessage';
import ConfirmationModal from '@/components/ConfirmationModal';
import Table from '@/components/Table';
import Icon from '@/components/Icon';
import Truncate from '@/components/Truncate';
import { funnelsPath, settingsFunnelStagesPath } from '@/routes';
import { useDocumentTitle } from '@/hooks';
import NotFound from '@/pages/not-found';
import { isNotFoundError } from '@/errors';
import { useCreateStageDealsRelocation, useStage } from '@/api';

const schema = Yup.object().shape({
  destination_stage_id: Yup.number().required()
});
const initialErrors = { destination_stage_id: '' };

const PAGE_TITLE = 'Excluir etapa';

function StagesDealsRelocationsIndex() {
  const { stageId, funnelId } = useParams();
  const navigate = useNavigate();
  const { data: { data: stage = {} } = {}, error, isSuccess } = useStage({ stageId });

  const [destination, setDestination] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const relocateMutation = useCreateStageDealsRelocation({
    stageId,
    funnelId
  });
  const alert = useAlert();

  useDocumentTitle(PAGE_TITLE);

  if (error && isNotFoundError(error)) {
    return <NotFound />;
  }

  if (!isSuccess) {
    return <div className='flex-grow-1 bg-light' />;
  }

  const { funnel } = stage;

  const handleModalHide = () => setShowModal(false);

  const onSubmit = (data) => {
    setShowModal(true);

    setFormData(data);
  };

  const handleDeleteConfirm = () => {
    setIsSubmitting(true);

    relocateMutation.mutate(formData, {
      onSuccess: (_response) => {
        alert.show(
          'Etapa excluída com sucesso.',
          { variant: 'success', timeout: 5000 }
        );

        navigate(settingsFunnelStagesPath(funnel.id));
      },
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='stage' action='delete' />,
          { variant: 'danger' }
        );

        setIsSubmitting(false);
      }
    });
  };

  return (
    <Main>
      <Breadcrumb>
        <Breadcrumb.Item href={funnelsPath()}>
          Personalizar funis
        </Breadcrumb.Item>

        <Breadcrumb.Item href={settingsFunnelStagesPath(funnel.id)}>
          Etapas do {funnel.name}
        </Breadcrumb.Item>

        <Breadcrumb.Item href='#' active>
          Excluir etapa {stage.name} do {funnel.name}
        </Breadcrumb.Item>
      </Breadcrumb>

      <h2 className='mb-6'>
        Excluir etapa {stage.name} do {funnel.name}
      </h2>

      <p className='mb-6'>
        Para executar esta ação, você precisa selecionar abaixo um destino
        para os negócios desta etapa que será excluída.
      </p>

      <ConfirmationModal
        show={showModal}
        disabled={isSubmitting}
        onHide={handleModalHide}
        onConfirm={handleDeleteConfirm}
        confirmationText='Mover negócios e excluir etapa'
      >
        <ConfirmationModal.Title>
          Confirmar exclusão da etapa {stage.name}
        </ConfirmationModal.Title>

        <ConfirmationModal.Info>
          Verifique abaixo se está tudo certo antes de confirmar a exclusão.
          Esta ação não poderá ser revertida.
        </ConfirmationModal.Info>

        <ConfirmationModal.Content>
          <div className='mb-2 text-start text-dark-gray text-uppercase fw-bold'>
            Destino dos negócios da etapa {stage.name}
          </div>

          <Table className='alig-self-start text-start'>
            <thead>
              <tr>
                <th className='max-width-4 px-0'>Etapa excluída</th>
                <th></th>
                <th className='max-width-4 px-0'>Etapa nova</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className='max-width-4 px-0'>
                  <Truncate>{stage.order}. {stage.name}</Truncate>
                </td>
                <td className='text-center'>
                  <Icon name='full-arrow-right' className='text-gray' />
                </td>
                <td className='max-width-4 px-0'>
                  {destination && <Truncate>{destination.name}</Truncate>}
                </td>
              </tr>
            </tbody>
          </Table>
        </ConfirmationModal.Content>
      </ConfirmationModal>
      <Form
        name='stage-deal-relocation'
        onSubmit={onSubmit}
        validationSchema={schema}
        initialErrors={initialErrors}
      >
        {
          ({ isValid }) => (
            <div>
              <FormField
                as={StageSelect}
                funnelId={funnel.id}
                className='mb-8 width-6'
                name='destination_stage_id'
                label={<h5>Para qual etapa os negócios devem ser movidos?</h5>}
                placeholder='Selecione uma etapa'
                defaultQueryParams={{ id_not_eq: stage.id }}
                onChange={(value, { option: { label } }) => {
                  setDestination({ name: label, id: value });
                }}
              />

              <Button
                className='py-3 px-5'
                type='submit'
                disabled={!isValid || isSubmitting}
              >
                Prosseguir
              </Button>

              <Button
                variant='link'
                type='button'
                href={settingsFunnelStagesPath(funnel.id)}
              >
                Cancelar
              </Button>
            </div>
          )
        }
      </Form>
    </Main>
  );
}

export default StagesDealsRelocationsIndex;
