import React, { useMemo, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useFormikContext } from 'formik';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import Popover from '@/components/Popover';
import Badge from '@/components/Badge';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import { PopoverProvider } from '@/contexts';

const propTypes = {
  children: PropTypes.node.isRequired,
  defaultValues: PropTypes.object.isRequired,
  className: PropTypes.string,
  columnsFilter: PropTypes.object,
  columnsList: PropTypes.array,
  label: PropTypes.string,
  openColumnsForm: PropTypes.func,
  filterButtonRef: PropTypes.any,
  clearFilterRef: PropTypes.any,
  onResetFilter: PropTypes.func,
  onSubmitFilter: PropTypes.func,
  disabled: PropTypes.bool
};

const defaultProps = {
  className: '',
  label: 'Mostrar filtros',
  columnsFilter: {},
  columnsList: [],
  openColumnsForm: () => {},
  filterButtonRef: null,
  clearFilterRef: null,
  onResetFilter: () => {},
  onSubmitFilter: () => {},
  disabled: false
};

function TableFormFieldsPopover(props) {
  const {
    children, defaultValues, label, className,
    columnsFilter, columnsList, openColumnsForm,
    clearFilterRef, filterButtonRef, onResetFilter,
    onSubmitFilter, disabled
  } = props;

  const [popoverShown, setPopoverShown] = useState(false);
  const container = useRef(null);
  const { values, submitForm, resetForm } = useFormikContext();

  const dirtyFiltersCount = useMemo(() => {
    const popoverValues = pick(values, Object.keys(defaultValues));

    return Object
      .entries(popoverValues)
      .filter(([key, value]) => !isEqual(defaultValues[key], value))
      .length;
  }, [defaultValues, values]);

  const isActive = popoverShown || Boolean(dirtyFiltersCount);

  // Stringify do columnsFilter garante consistencia useEffect
  const memoKey = JSON.stringify(columnsFilter);

  useEffect(() => {
    const filteredValues = {};

    columnsList.forEach((column) => {
      if (column.filter) {
        const filter = column.filter;

        if (!columnsFilter[column.name]) {
          filteredValues[filter.name] = defaultValues[filter.name];
        }
      }
    });

    resetForm({ values: { ...values, ...filteredValues } });
    submitForm();
  }, [memoKey]);

  const resetFilters = () => {
    resetForm({ values: { ...values, ...defaultValues } });
    submitForm();
    onResetFilter();
  };

  return (
    <>
      <Popover
        className={className}
        onEnter={() => setPopoverShown(true)}
        onExit={() => setPopoverShown(false)}
        content={
          ({ onHide }) => (
            <PopoverProvider value={{ container }}>
              <div ref={container} className='py-2' >
                <div className='d-flex align-items-center justify-content-between'>
                  <h4>Filtrar</h4>

                  {Boolean(dirtyFiltersCount) &&
                  <Button
                    variant='link'
                    className='fw-bold p-0'
                    onClick={() => {
                      resetFilters();
                      onHide();
                    }}
                  >
                    <Icon size='md' name='close' />

                    <span>Limpar filtros</span>
                  </Button>
                  }

                </div>

                <div className={classnames(
                  'table-form-fields-popover-content-max-height',
                  'min-height-4',
                  'overflow-y-scroll',
                  'mt-4',
                  'pe-1'
                )}>
                  {children}
                </div>

                <div className='d-grid gap-3 mt-4'>
                  <Button
                    onClick={() => {
                      submitForm();
                      onSubmitFilter();
                      onHide();
                    }}
                  >
                    Aplicar filtros
                  </Button>

                  <Button
                    variant='outline-dark-gray'
                    onClick={() => {
                      openColumnsForm();
                      onHide();
                    }}
                  >
                    <Icon className='me-1' size='sm' name='filter-settings' />

                    <span>Exibir mais filtros</span>
                  </Button>
                </div>
              </div>
            </PopoverProvider>
          )
        }
        trigger={['click']}
        placement='bottom-start'
        rootClose
        rootCloseEvent='mousedown'
        flip
      >
        <Button
          variant={isActive ? 'primary' : 'light'}
          aria-label={label}
          ref={filterButtonRef}
          disabled={disabled}
        >
          {dirtyFiltersCount
            ? (
              <Badge pill className='bg-white text-primary height-1 min-width-1 me-2'>
                {dirtyFiltersCount}
              </Badge>
            )
            : <Icon className='me-1' size='sm' name='filter' />
          }

          <span>Filtros</span>
        </Button>
      </Popover>
      <input ref={clearFilterRef} type='button' onClick={resetFilters} className='hidden-button' />
    </>
  );
}

TableFormFieldsPopover.propTypes = propTypes;
TableFormFieldsPopover.defaultProps = defaultProps;

export default TableFormFieldsPopover;
