export default {
  actions: {
    funnel: {
      create: 'adicionar esse funil',
      update: 'editar esse funil',
      delete: 'excluir esse funil'
    },
    stage: {
      create: 'adicionar essa etapa',
      update: 'editar essa etapa',
      delete: 'excluir essa etapa'
    },
    organization: {
      createDeal: 'adicionar esse negócio',
      create: 'adicionar essa empresa',
      delete: 'excluir essa empresa',
      update: 'editar essa empresa'
    },
    person: {
      createDeal: 'adicionar esse negócio',
      create: 'adicionar esse contato',
      update: 'editar esse contato',
      list: 'listar pessoas'
    },
    deal: {
      create: 'adicionar esse negócio',
      update: 'editar esse negócio',
      move: 'mover esse negócio para essa etapa',
      createDeal: 'duplicar esse negócio',
      delete: 'excluir esse negócio'
    },
    search: {
      show: 'realizar a busca'
    },
    product: {
      delete: 'excluir esse produto',
      create: 'criar esse produto',
      update: 'editar esse produto'
    },
    product_category: {
      create: 'criar essa categoria',
      manualImport: 'criar categorias'
    },
    import: {
      create: 'realizar essa importação',
      delete: 'desfazer essa importação'
    },
    export: {
      create: 'realizar essa exportação'
    },
    required_field: {
      create: 'criar essa regra de campo obrigatório',
      update: 'editar essa regra de campo obrigatório',
      delete: 'excluir essa regra de campo obrigatório'
    },
    activity_template: {
      delete: 'excluir esse modelo de anotação'
    },
    automation: {
      create: 'criar essa automação',
      update: 'editar essa automação',
      delete: 'excluir essa automação'
    },
    leads: {
      list: 'buscar leads'
    },
    email_sender: {
      create: 'verificar seu e-mail'
    },
    admin: {
      courtesy: {
        create: 'dar dias para esse cliente'
      },
      subscription: {
        cancel: 'cancelar essa assinatura'
      }
    },
    downgrade: {
      create: 'realizar downgrade'
    }
  },
  errors: {
    user: {
      no_privilege: 'Você não tem privilégio para realizar essa ação.'
    },
    account: {
      free: 'Sua conta não possui acesso a essa funcionalidade.',
      max_funnels: 'Sua conta atingiu o limite de funis.',
      max_organizations: 'Sua conta atingiu o limite de empresas.',
      max_people: 'Sua conta atingiu o limite de pessoas.',
      max_deals: 'Sua conta atingiu o limite de negócios.'
    },
    proposal_models: {
      name: {
        taken: 'Já existe um modelo com esse nome.',
        blank: 'O nome do modelo é obrigatório.'
      }
    },
    funnel: {
      max_stages: 'Esse funil atingiu o limite de etapas.',
      name: {
        taken: 'Já existe um funil com esse nome.'
      },
      initials: {
        taken: 'Já existe um funil com essa sigla.'
      },
      default: 'Não é possível excluir o funil padrão.',
      not_empty:
        'Por enquanto, só é possível excluir um funil sem negócios. ' +
        'Exclua os negócios deste funil ou mova-os para outro ' +
        'e depois tente novamente.'
    },
    stage: {
      name: {
        taken: 'Já existe uma etapa com esse nome.'
      },
      not_empty:
        'Por enquanto, só é possível excluir uma etapa sem negócios. ' +
        'Mova os negócios desta etapa para outra e depois tente novamente.'
    },
    deal: {
      'title': {
        taken: 'Já existe um negócio com esse título.'
      },
      'entity_products.product_active': {
        inclusion: 'Foi adicionado um produto que está inativo.'
      },
      'finished_at': {
        blank: 'Não é possível remover a data de conclusão de um negócio já finalizado.'
      }
    },
    person: {
      cpf: {
        taken: 'Este CPF já está cadastrado.'
      }
    },
    organization: {
      name: {
        taken: 'Já existe uma empresa com esse nome.'
      },
      cnpj: {
        taken: 'Já existe uma empresa com esse CNPJ.'
      }
    },
    custom_fields: {
      identifier: {
        taken: 'Já existe um campo com esse nome.'
      }
    },
    product: {
      name: {
        taken: 'Já existe um produto com esse nome.'
      },
      code: {
        taken: 'Já existe um produto com esse código.'
      }
    },
    import: {
      entity: {
        unsupported: 'Essa importação não pode ser desfeita.'
      }
    },
    product_category: {
      names: {
        invalid: 'Não foi informado nenhum nome válido.'
      }
    },
    required_field: {
      organization_field: {
        taken: 'Esse campo já foi configurado como obrigatório.'
      },
      person_field: {
        taken: 'Esse campo já foi configurado como obrigatório.'
      },
      deal_field: {
        taken: 'Esse campo já foi configurado como obrigatório.'
      },
      organization_custom_field_id: {
        taken: 'Esse campo já foi configurado como obrigatório.'
      },
      person_custom_field_id: {
        taken: 'Esse campo já foi configurado como obrigatório.'
      },
      deal_custom_field_id: {
        taken: 'Esse campo já foi configurado como obrigatório.'
      }
    },
    activity_templates: {
      name: {
        taken: 'Já existe um modelo de anotação com esse nome.'
      }
    },
    automation: {
      trigger_name: {
        taken: 'Já existe uma automação com essa configuração.'
      },
      trigger_params: {
        too_long: 'A configuração da automação é muito grande.'
      },
      action_params: {
        too_long: 'A configuração da automação é muito grande.'
      }
    },
    email_sender: {
      base: {
        'You can\'t use public domain emails.': 'A verificação não está disponível para e-mails ' +
          'de domínios públicos, como @gmail.com e @outlook.com.'
      }
    },
    admin: {
      courtesy: {
        account: {
          free: 'Não é possível adicionar dias para uma conta gratuita.',
          blocked: 'Não é possível adicionar dias para não bloquear para uma conta já bloqueada.'
        }
      },
      subscription: {
        account: {
          free: 'Não é possível cancelar uma assinatura gratuita.',
          trial: 'Não é possível cancelar uma assinatura em período trial.'
        }
      }
    },
    downgrade: {
      code: {
        invalid: 'Código inválido'
      }
    }
  },
  models: {
    organization: 'empresa',
    person: 'pessoa',
    deal: 'negócio',
    product: 'produto',
    call: 'chamada',
    product_category: 'categoria de produto',
    plural: {
      product_category: 'categorias de produtos'
    },
    deal_status: {
      won: 'Ganho',
      lost: 'Perdido',
      ongoing: 'Em andamento'
    },
    deal_status_id: {
      '1': 'Em andamento',
      '2': 'Ganho',
      '3': 'Perdido'
    },
    activity: {
      type: {
        task: 'Tarefa',
        note: 'Nota',
        email: 'E-mail',
        call: 'Ligação',
        proposal: 'Proposta',
        meeting: 'Reunião',
        visit: 'Visita'
      },
      type_started: {
        task: 'Tarefa',
        note: 'Nota',
        email: 'Enviar e-mail',
        call: 'Ligar',
        proposal: 'Enviar proposta',
        meeting: 'Participar de reunião',
        visit: 'Visitar'
      },
      type_finished: {
        task: 'Tarefa finalizada',
        note: 'Nota',
        email: 'E-mail enviado',
        call: 'Ligação feita',
        proposal: 'Proposta enviada',
        meeting: 'Reunião realizada',
        visit: 'Visita realizada'
      }
    },
    emailTemplate: 'Modelo',
    email: {
      status: {
        pending: 'Aguardando envio',
        sent: 'Enviado',
        read: 'Lido',
        received: 'Recebido',
        failed: 'Envio falhou'
      }
    }
  },
  attributes: {
    /**
     * Aqui em empresa, pessoa e negócio tem alguns campos que são na verdade
     * de associações, por exemplo contato, endereço e motivo de perda.
     * Futuramente podemos usar outra forma de estruturar isso.
     */
    organization: {
      legal_name: 'Razão social',
      cnpj: 'CNPJ',
      category: 'Categoria',
      lead_origin: 'Origem',
      sector: 'Setor',
      description: 'Descrição',
      email: 'E-mail',
      whatsapp: 'WhatsApp',
      work_phone: 'Telefone',
      mobile_phone: 'Celular',
      fax_phone: 'Fax',
      phone_extension: 'Ramal',
      postal_code: 'CEP',
      country: 'País',
      state: 'Estado',
      city: 'Cidade',
      district: 'Bairro',
      street_name: 'Rua',
      street_number: 'Número',
      additional_info: 'Complemento',
      products: 'Produtos e serviços',
      facebook: 'Facebook',
      twitter: 'Twitter',
      linkedin: 'LinkedIn',
      skype: 'Skype',
      instagram: 'Instagram',
      ranking: 'Ranking'
    },
    person: {
      cpf: 'CPF',
      job_title: 'Cargo',
      birthday: 'Aniversário',
      birth_year: 'Ano de nascimento',
      category: 'Categoria',
      lead_origin: 'Origem',
      description: 'Descrição',
      email: 'E-mail',
      whatsapp: 'WhatsApp',
      work_phone: 'Telefone',
      mobile_phone: 'Celular',
      fax_phone: 'Fax',
      phone_extension: 'Ramal',
      postal_code: 'CEP',
      country: 'País',
      state: 'Estado',
      city: 'Cidade',
      district: 'Bairro',
      street_name: 'Rua',
      street_number: 'Número',
      additional_info: 'Complemento',
      products: 'Produtos e serviços',
      facebook: 'Facebook',
      twitter: 'Twitter',
      linkedin: 'LinkedIn',
      skype: 'Skype',
      instagram: 'Instagram',
      ranking: 'Ranking'
    },
    deal: {
      finished_at: 'Data de conclusão',
      value: 'Valor total',
      description: 'Descrição',
      loss_reason: 'Motivo de perda',
      loss_reason_description: 'Descrição do motivo de perda',
      products: 'Produtos e serviços',
      ranking: 'Ranking'
    },
    imports: {
      entity: {
        organizations: 'empresa',
        people: 'pessoa',
        deals: 'negócio',
        products: 'produto',
        activities: 'comentário'
      },
      info: {
        organizationsCreated: 'Empresas criadas',
        organizationsUpdated: 'Empresas atualizadas',
        organizationsDeleted: 'Empresas excluídas',
        peopleCreated: 'Pessoas criadas',
        peopleUpdated: 'Pessoas atualizadas',
        peopleDeleted: 'Pessoas excluídas',
        productsCreated: 'Produtos criados',
        productsUpdated: 'Produtos atualizados',
        productsDeleted: 'Produtos excluídos',
        categoriesCreated: 'Categorias criadas',
        activitiesCreated: 'Comentários criados',
        activitiesUpdated: 'Comentários atualizados',
        activitiesDeleted: 'Comentários excluídos',
        dealsCreated: 'Negócios criados',
        dealsUpdated: 'Negócios atualizados',
        dealsDeleted: 'Negócios excluídos'
      }
    }
  },
  messages: {
    organization: {
      products: {
        empty_list: 'Esta empresa ainda não possui produtos cadastrados.'
      }
    },
    person: {
      products: {
        empty_list: 'Esta pessoa ainda não possui produtos cadastrados.'
      }
    },
    deal: {
      products: {
        empty_list: 'Este negócio ainda não possui produtos cadastrados.'
      }
    }
  },
  labels: {
    proposal_models: {
      layout_mode: {
        sections: `Essa seção não é editável no modelo.
          Aqui aparecerá automaticamente os dados do vendedor e do cliente.`,
        code: 'Este código será gerado automaticamente em cada proposta, não pode ser predefinido.'
      }
    },
    automation: {
      owner_user: 'Responsável pelo negócio',
      related_entity: 'Contato vinculado ao negócio'
    }
  }
};
