import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import classnames from 'classnames';
import Task from '@/task';
import TaskPopover from '@/components/TaskPopover';
import Tooltip from '@/components/Tooltip';

const VARIANTS = {
  nextTask: {
    empty: {
      btnVariant: 'light-red',
      textVariant: 'danger',
      btnText: 'Sem tarefa agendada',
      btnIcon: 'alert',
      tooltipText: ''
    },
    scheduled: {
      btnVariant: 'light-green',
      textVariant: 'success',
      tooltipText: 'Clique para mais detalhes'
    },
    overdue: {
      btnVariant: 'light-yellow',
      textVariant: 'warning',
      tooltipText: 'Clique para mais detalhes'
    },
    default: {
      btnVariant: 'light-red',
      textVariant: 'danger',
      btnText: 'Sem tarefa agendada',
      btnIcon: 'alert',
      tooltipText: ''
    }
  },
  latestTask: {
    empty: {
      btnVariant: 'light',
      textVariant: 'dark-gray',
      btnText: 'Histórico vazio',
      btnIcon: 'alert',
      tooltipText: ''
    },
    default: {
      btnVariant: 'light',
      textVariant: 'dark-gray',
      tooltipText: 'Clique para mais detalhes'
    }
  }
};

const propTypes = {
  variant: PropTypes.oneOf(['nextTask', 'latestTask']).isRequired,
  className: PropTypes.string,
  task: PropTypes.object
};

const defaultProps = {
  className: null,
  task: null
};

function TaskTag({ className, task, variant, entity }) {
  const [popoverShown, setPopoverShown] = useState(false);

  const taskObj = Task.build(task);

  const { status, type, description } = taskObj || {};

  const taskVariantWithStatus = VARIANTS[variant][status] || VARIANTS[variant].default;
  const taskVariantWithoutStatus = VARIANTS[variant].empty;
  const taskVariant = status ? taskVariantWithStatus : taskVariantWithoutStatus;

  const { btnVariant, textVariant, tooltipText, btnText, btnIcon } = taskVariant;

  let finalBtnText = description ?? btnText;
  let finalBtnIcon = type ?? btnIcon;

  /*
   * Solução rápida para exibirmos conteúdo de estado vazio em casos "inválidos"
   * ("próximas tarefas" sem status ou com done)
   */
  if (variant === 'nextTask' && (!status || status === 'done')) {
    finalBtnText = btnText;
    finalBtnIcon = btnIcon;
  }

  return (
    <Tooltip
      placement='top'
      content={tooltipText}
      hide={!tooltipText || popoverShown}
      className='pe-none'
    >
      <div className={classnames(className, 'd-inline-block', 'p-1')}>
        <TaskPopover
          variant={variant}
          placement='bottom-start'
          task={taskObj}
          onEnter={() => setPopoverShown(true)}
          onExit={() => setPopoverShown(false)}
          entity={entity}
        >
          <Button
            variant={btnVariant}
            className={classnames(
              'p-1',
              'border-0',
              `text-${textVariant}`,
              { 'cursor-default': !taskObj }
            )}
          >
            <Icon
              className='me-1'
              size='sm'
              name={finalBtnIcon}
            />

            <span className='fw-normal'>
              {finalBtnText}
            </span>
          </Button>
        </TaskPopover>
      </div>
    </Tooltip>
  );
}

TaskTag.propTypes = propTypes;
TaskTag.defaultProps = defaultProps;

export default TaskTag;
