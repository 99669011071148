import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import StoredForm from '@/components/StoredForm';
import Form from '@/components/Form';
import {
  organizationTableDefaultValues,
  personTableDefaultValues,
  dealTableDefaultValues,
  customFieldTableDefaultValues
} from '@/components/Columns';
import { getFilterFromQueryString } from '@/components/Columns/Utils';
import FormProvider from '@/components/FormProvider';

const propTypes = {
  type: PropTypes.oneOf(['organization', 'person', 'deal']).isRequired,
  columns: PropTypes.object,
  customFields: PropTypes.array,
  onSubmit: PropTypes.func,
  openColumnsForm: PropTypes.func,
  filterButtonRef: PropTypes.any,
  clearFilterRef: PropTypes.any,
  clearSearchRef: PropTypes.any,
  isLoading: PropTypes.bool,
  filterParams: PropTypes.object
};

const defaultProps = {
  columns: {},
  customFields: [],
  onSubmit: () => {},
  openColumnsForm: () => {},
  filterButtonRef: null,
  clearFilterRef: null,
  clearSearchRef: null,
  isLoading: false,
  filterParams: {}
};

function ListForm({ type, onSubmit, customFields, filterParams, children }) {
  const [searchParams] = useSearchParams();

  const queryParams = [...searchParams];

  const queryStringValues = useMemo(() => getFilterFromQueryString(type, queryParams), [type]);
  const fixedFiltersValues = useMemo(() => getTableFilters(type), [type]);

  /*
   * Foi necessário stringify para evitar bug do formik de render infinito
   */
  const memoKey = JSON.stringify({ type, queryStringValues, customFields, filterParams });

  const filterDefaultValues = useMemo(
    () => {
      const tableFiltersValues = {
        ...fixedFiltersValues,
        ...customFieldTableDefaultValues(customFields),
        ...filterParams
      };
      return {
        q: '',
        sorts: 'created_at desc',
        custom_fields: {},
        ...tableFiltersValues
      };
    },
    [memoKey]
  );

  /*
   *Por regra de produto, os filtros vindos de relatórios (queryParams) devem sobrescrever quaisquer
   *filtros já existentes (WEB-5744). Se ele sai da tela em que estava e volta depois, os filtros de
   *relatórios não devem mais ser mostrados (WEB-5731).
   */
  const FormBase = queryParams.length ? Form : StoredForm;

  // Esse className garante que o comportamento de scroll na listagem funcione
  const formProps = queryParams.length
    ? { className: 'flexible-height d-flex flex-column flex-grow-1 flexible-height' }
    : { as: FormProvider };

  return (
    <FormBase
      onSubmit={onSubmit}
      name={`${type}-table-form`}
      className='d-flex flex-column flex-fill'
      defaultValues={filterDefaultValues}
      initialValues={queryStringValues}
      {...formProps}
    >
      {children}
    </FormBase>
  );
}

function getTableFilters(type) {
  switch (type) {
    case 'deal':
      return dealTableDefaultValues;
    case 'organization':
      return organizationTableDefaultValues;
    case 'person':
      return personTableDefaultValues;
    default:
      throw new Error(`Invalid Column type ${type}`);
  }
}

ListForm.propTypes = propTypes;
ListForm.defaultProps = defaultProps;

export default ListForm;
