import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import FormField from '@/components/FormField';
import Form from '@/components/Form';
import { TextInput } from '@/components/Inputs';
import Icon from '@/components/Icon';
import InputAdornment from '@/components/InputAdornment';
import Authorization from '@/components/Authorization';
import { canEditStage } from '@/policies';

const propTypes = {
  stage: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  className: PropTypes.string
};

const defaultProps = {
  className: ''
};

/* eslint-disable no-magic-numbers */
const schema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(45, 'Nome da etapa deve ter no máximo 45 caracteres.')
    .required('Nome da etapa é obrigatório.')
});
/* eslint-enable no-magic-numbers */

function EditStageForm(props) {
  const { onSubmit, className, stage } = props;

  return (
    <Form
      className={className}
      defaultValues={stage}
      onSubmit={onSubmit}
      name={`edit-stage-form-${stage.id}`}
      validationSchema={schema}
      submitOnDefaultChange={false}
    >
      {({ submitForm }) => (
        <div className='width-6 py-2'>
          <Authorization policy={canEditStage}>
            <FormField
              as={TextInput}
              name='name'
              className='mb-0'
              placeholder='Nome da etapa'
              autoComplete='off'
              variant='transparent'
              onBlur={submitForm}
              rightAdornment={({ focus }) => (
                <InputAdornment
                  alignment='right'
                  className='input-edit invisible'
                  onClick={focus}
                >
                  <Icon className='text-primary' name='edit-field' />
                </InputAdornment>
              )}
            />
          </Authorization>
        </div>
      )}
    </Form>
  );
}

EditStageForm.propTypes = propTypes;
EditStageForm.defaultProps = defaultProps;

export default EditStageForm;
