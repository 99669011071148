import { useMutation, useQueryClient } from 'react-query';
import { post } from '@/lib/fetch';

export async function createRelocation(params = {}) {
  const { data } = await post('/relocations', params);

  return data;
}

export function useCreateRelocation({ keys = [], config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = () => {
    keys.forEach((key) => {
      queryClient.invalidateQueries(key);
    });
  };

  return useMutation(
    createRelocation,
    {
      onSuccess,
      ...config
    }
  );
}
