import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  TextInput,
  Select,
  TextArea
} from '@/components/Inputs';
import Main from '@/components/Main';
import { useAuth } from '@/lib/auth';
import { useDocumentTitle } from '@/hooks';

import NotFound from '@/pages/not-found';
import {
  InputPreview,
  AddressFieldsPreview,
  PrivacyFieldPreview,
  CustomFieldsSectionPreview
} from '@/components/CustomFields';
import CreateCustomFields from '@/components/CreateCustomFields';
import {
  useCreatePeopleCustomField,
  useUpdatePeopleCustomField,
  useDeletePeopleCustomField,
  usePeopleCustomFields
} from '@/api';

const PAGE_TITLE = 'Campos customizados';

function PeopleFormPreview() {
  const { user } = useAuth();
  const createPeopleCustomFieldMutation = useCreatePeopleCustomField();
  const updatePeopleCustomFieldMutation = useUpdatePeopleCustomField();
  const deletePeopleCustomFieldMutation = useDeletePeopleCustomField();
  const { data: customFields, error, isSuccess } = usePeopleCustomFields();

  useDocumentTitle(PAGE_TITLE);

  if (!user.account.paid || error) {
    return <NotFound />;
  }

  if (!isSuccess) {
    return <div className='flex-grow-1 bg-light' />;
  }

  return (
    <Main fluid={false} >
      <Container>
        <h1>Formulário de pessoa</h1>

        <p className='subtitle text-dark-gray'>
          Personalize os formulários do Agendor de acordo com as suas
          necessidades específicas.
        </p>

        <hr className='my-4' />

        <h2 className='my-4'>
          Dados básicos
        </h2>

        <Row className='mt-6 gx-3'>
          <Col sm={6}>
            <InputPreview
              as={TextInput}
              label='Nome'
            />
          </Col>
          <Col sm={6}>
            <InputPreview
              as={TextInput}
              label='CPF'
            />
          </Col>
        </Row>
        <Row className='gx-3'>
          <Col sm={6}>
            <InputPreview
              as={Select}
              label='Empresa'
            />
          </Col>
          <Col sm={6}>
            <InputPreview
              as={TextInput}
              label='Cargo'
            />
          </Col>
        </Row>
        <Row className='gx-3'>
          <Col xs={6} sm={2}>
            <InputPreview
              as={TextInput}
              label='Aniversário'
            />
          </Col>
          <Col xs={6} sm={4}>
            <InputPreview
              as={TextInput}
              label='Ano nasc.'
            />
          </Col>
          <Col sm={6}>
            <InputPreview
              as={Select}
              label='Responsável'
            />
          </Col>
        </Row>
        <Row className='gx-3'>
          <Col sm={6}>
            <InputPreview
              as={Select}
              label='Categoria'
            />
          </Col>
          <Col sm={6}>
            <InputPreview
              as={Select}
              label='Origem'
            />
          </Col>
        </Row>
        <Row className='gx-3'>
          <Col>
            <InputPreview
              as={TextArea}
              label='Descrição'
            />
          </Col>
        </Row>

        <CustomFieldsSectionPreview
          section='basic_data'
          entity='person'
          fields={customFields.data}
          updateMutation={updatePeopleCustomFieldMutation}
          deleteMutation={deletePeopleCustomFieldMutation}
        />

        <CreateCustomFields
          section='basic_data'
          mutation={createPeopleCustomFieldMutation}
          entity='person'
        />

        <hr className='mb-6' />

        <h2 className='my-4'>
          Informações para contato
        </h2>

        <p className='subtitle text-dark-gray'>
          Adicione informações que facilitem o contato com o cliente.
        </p>

        <Row className='gx-3'>
          <Col sm={6}>
            <InputPreview
              as={TextInput}
              label='Email'
              variant='light'
            />
          </Col>
          <Col sm={6}>
            <InputPreview
              as={TextInput}
              label='Whatsapp'
            />
          </Col>
        </Row>

        <Row className='gx-3'>
          <Col sm={6}>
            <InputPreview
              as={TextInput}
              label='Telefone'
            />
          </Col>
          <Col sm={6}>
            <InputPreview
              as={TextInput}
              label='Celular'
            />
          </Col>
        </Row>

        <Row className='gx-3'>
          <Col sm={6}>
            <InputPreview
              as={TextInput}
              label='Fax'
            />

          </Col>
          <Col sm={6}>
            <InputPreview
              as={TextInput}
              label='Ramal'
            />
          </Col>
        </Row>

        <CustomFieldsSectionPreview
          section='contact'
          entity='person'
          fields={customFields.data}
          updateMutation={updatePeopleCustomFieldMutation}
          deleteMutation={deletePeopleCustomFieldMutation}
        />

        <CreateCustomFields
          section='contact'
          mutation={createPeopleCustomFieldMutation}
          entity='person'
        />

        <hr className='mb-6' />

        <h2 className='my-4'>
          Dados do endereço
        </h2>

        <p className='subtitle text-dark-gray'>
          Adicione a localização do seu cliente.
        </p>

        <AddressFieldsPreview />

        <CustomFieldsSectionPreview
          section='address'
          entity='person'
          fields={customFields.data}
          updateMutation={updatePeopleCustomFieldMutation}
          deleteMutation={deletePeopleCustomFieldMutation}
        />

        <CreateCustomFields
          section='address'
          mutation={createPeopleCustomFieldMutation}
          entity='person'
        />

        <hr className='mb-6' />

        <div className='opacity-30'>
          <h2 className='my-4'>
            Produtos e serviços
          </h2>

          <p className='subtitle text-dark-gray'>
            Quais esta pessoa tem potencial de compra?
          </p>

          <InputPreview
            as={Select}
            label=''
            placeholder='Buscar...'
          />
        </div>

        <hr className='mb-6' />

        <div className='opacity-30'>
          <h2 className='my-4'>
            Redes sociais
          </h2>

          <Row className='gx-3'>
            <Col sm={6}>
              <InputPreview
                as={TextInput}
                label='Facebook'
                variant='light'
              />
            </Col>
            <Col sm={6}>
              <InputPreview
                as={TextInput}
                label='Twitter'
                variant='light'
              />
            </Col>
          </Row>

          <Row className='gx-3'>
            <Col sm={6}>
              <InputPreview
                as={TextInput}
                label='LinkedIn'
                variant='light'
              />
            </Col>
            <Col sm={6}>
              <InputPreview
                as={TextInput}
                label='Skype'
                variant='light'
              />
            </Col>
          </Row>

          <Row className='gx-3'>
            <Col sm={6}>
              <InputPreview
                as={TextInput}
                label='Instagram'
                variant='light'
              />
            </Col>
          </Row>
        </div>

        <hr className='mb-6' />

        <div className='opacity-30'>
          <h2 className='my-4'>
            Privacidade
          </h2>

          <p className='subtitle text-dark-gray'>
            Quem pode ver o histórico e editar essa pessoa?
          </p>

          <div className='mb-2'>
            <PrivacyFieldPreview />
          </div>
        </div>
      </Container>
    </Main>
  );
}

export default PeopleFormPreview;
