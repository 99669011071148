import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { TextInput } from '@/components/Inputs';
import Button from '@/components/Button';
import { toDigitsString } from '@/number';

const propTypes = {
  nextOrder: PropTypes.number.isRequired,
  onAdd: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

const defaultProps = {
  nextOrder: 0,
  onAdd: () => {},
  onCancel: () => {}
};

function CreateOptionForm({ nextOrder, onAdd, onCancel }) {
  const [optName, setOptName] = useState('');
  const btnOkHandler = () => {
    onAdd({
      id: 0,
      option: optName,
      order: nextOrder
    });
  };
  const txtNameHandleChange = (e) => {
    setOptName(e);
  };
  return (
    <div className={classnames(
      'col-sm-12',
      'align-items-start',
      'bg-light',
      'rounded'
    )}>
      <div className={classnames('row')}>
        <div className='col-sm-2 px-3 py-2 text-dark-gray'>
          <div className={'py-2'
          }>
            <div className='ms-auto'>
              {toDigitsString(nextOrder, 2)}
            </div>
          </div>
        </div>
        <div className='col-sm-10 px-3 py-2'>
          <TextInput
            onChange={txtNameHandleChange}
            name='name'
            className='mb-0'
            placeholder='Minha opção'
            autoComplete='off'
            variant='white'
            autoFocus
          />
        </div>
      </div>
      <div className={'row'}>
        <div className='px-3 py-2'>
          <Button
            variant='link'
            className='px-4 border-0'
            onClick={onCancel}
          >
            Cancelar
          </Button>
          <Button
            variant='primary'
            className='px-4 border-0'
            onClick={btnOkHandler}
          >
            OK
          </Button>
        </div>
      </div>
    </div>
  );
}

CreateOptionForm.propTypes = propTypes;
CreateOptionForm.defaultProps = defaultProps;

export default CreateOptionForm;
