import React from 'react';
import PropTypes from 'prop-types';
import { canImport } from '@/policies';
import Authorization from '@/components/Authorization';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Tooltip from '@/components/Tooltip';

const propTypes = {
  entity: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  entityNamePlural: PropTypes.string.isRequired,
  as: PropTypes.elementType,
  buttonText: PropTypes.string,
  buttonClassNames: PropTypes.string,
  iconClassName: PropTypes.string,
  spanClassName: PropTypes.string
};

const defaultProps = {
  as: Button,
  buttonText: 'Importar',
  buttonClassNames: 'text-nowrap me-2',
  iconClassName: 'me-1 text-dark-gray',
  spanClassName: ''
};

function ImportButton(props) {
  const {
    as: Component, entity, path, buttonText, buttonClassNames,
    iconClassName, spanClassName, entityNamePlural
  } = props;

  return (
    <Authorization
      policy={canImport}
      tooltipContainerClassName='align-self-center'
      data={{ entity }}
    >
      {({ disabled }) => (
        <>
          <Tooltip
            content={`Importar ${entityNamePlural} de uma planilha`}
            placement='left'
          >
            <Component
              href={path}
              variant='transparent-light'
              aria-label='Importar'
              className={buttonClassNames}
              disabled={disabled}
            >
              <Icon name='upload' className={iconClassName} />
              <span className={spanClassName}>{buttonText}</span>
            </Component>
          </Tooltip>
        </>
      )}
    </Authorization>
  );
}

ImportButton.defaultProps = defaultProps;
ImportButton.propTypes = propTypes;

export default ImportButton;
