import React from 'react';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import notFoundImage from 'images/404.png';
import { isForbiddenError } from '@/errors';


const ERRORS = {
  notFound: {
    image: <img width={188} src={notFoundImage} className='img-fluid mb-3' />,
    title: 'Oops!',
    message: 'Parece que o negócio que você está procurando não <br /> existe.'
  },
  noPrivacy: {
    image: <Icon name='lock' size='lg' className='mb-3 bg-light-gray rounded-2 p-2 m-8' />,
    title: 'Sem privacidade',
    message: `Você não possui privacidade para acessar este negócio.<br />
              Entre em contato com seus administradores.`
  }
};

function ModalError({ onHide, error }) {
  function getErrorContent() {
    if (isForbiddenError(error)) {
      return ERRORS.noPrivacy;
    }
    return ERRORS.notFound;
  }

  const errorContent = getErrorContent();

  return (
    <div className='d-flex justify-content-center py-8'>
      <div className='text-center py-8'>
        {errorContent.image}
        <h2 className='text-darker-gray mb-3'>{errorContent.title}</h2>

        <div
          dangerouslySetInnerHTML={{ __html: errorContent.message }}
          className='text-dark-gray mb-8'
        />

        <div className='mb-8'>
          <Button onClick={onHide}>
            <Icon
              className='me-2'
              name='full-arrow-left'
              size='sm'
            />
          Voltar
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ModalError;
