import { useQuery } from 'react-query';
import { get } from '@/lib/fetch';

export const dealCalculationsKeys = {
  all: ['deal-calculations'],
  allParams: (fieldName, params) => ([...dealCalculationsKeys.all, fieldName, params])
};

export async function getDealCalculations(params = {}) {
  const { data } = await get('/deal_calculations', params);

  return data;
}

export function useDealCalculations({ params = {}, config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: dealCalculationsKeys.allParams(params.field_name, params),
    queryFn: () => getDealCalculations(params)
  });
}
