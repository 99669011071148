import React from 'react';
import classnames from 'classnames';
import Nav from 'react-bootstrap/Nav';
import { NavLink as Base } from 'react-router-dom';
import PropTypes from 'prop-types';

const propTypes = {
  href: PropTypes.string,
  className: PropTypes.string
};

const defaultProps = {
  href: '',
  className: ''
};

const NavLink = React.forwardRef(
  (props, ref) => {
    const { className, href, ...otherProps } = props;

    const noComponentSpecified = !otherProps.as;
    const isLinkToAnotherPage = Boolean(href);

    /**
     * Quando for um link "normal" para outra página, renomeia o `href` para não
     * gerar conflito no estado de ativo entre o Bootstrap e o React Router.
     */
    const routeProps = noComponentSpecified && isLinkToAnotherPage
      ? { as: Base, to: href }
      : { href };

    return (
      <Nav.Link
        ref={ref}
        className={classnames('d-flex align-items-center', className)}
        {...routeProps}
        {...otherProps}
      />
    );
  }
);

NavLink.propTypes = propTypes;
NavLink.defaultProps = defaultProps;
NavLink.displayName = 'NavLink';

export default NavLink;
