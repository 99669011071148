import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';

import CardOverlay from '@/feature/automation//CardOverlay';
import TemplateCardBox from '@/feature/automation/TemplateCardBox';
import TemplateIcon from '@/feature/automation/TemplateIcon';
import TemplateTriggerBox from '@/feature/automation/TemplateTriggerBox';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Card from '@/components/Card';
import ExternalLink from '@/components/ExternalLink';
import { helpUrl } from '@/routes';
import { useTracking } from '@/lib/tracking';
import { useAuth } from '@/lib/auth';
import { getAutomationName } from '@/feature/automation/AutomationComponent';

const propTypes = {
  children: PropTypes.node,
  template: PropTypes.object
};

function TemplateCard(props) {
  const tracker = useTracking();
  const { user } = useAuth();
  const { template } = props;
  const { triggerIdentifier, triggerIcon, actionIdentifier, actionIcon, helpPath } = template ?? {};
  const navigate = useNavigate();
  const handleAutomationModalClick = () => {
    const automationName = getAutomationName(template);
    tracker.trackAutomationCardClicked({
      user,
      automationName
    });

    const automationType = `${template.triggerName}_${template.actionName}`;
    navigate('new', { state: { type: automationType } });
  };

  return (
    <div
      onClick={handleAutomationModalClick}
      className= {classnames(
        'hover-parent',
        'position-relative',
        'rounded',
        'overflow-hidden',
        'border-1',
        'border-dark-gray',
        'cursor-pointer'
      )}
    >
      <Card className='p-4 border-0 max-width-5 bg-dark text-light'>
        <TemplateTriggerBox>
          <TemplateIcon name={triggerIcon} />

          {triggerIdentifier}
        </TemplateTriggerBox>

        <TemplateCardBox>
          <TemplateIcon name={actionIcon} />

          <span>{actionIdentifier}</span>
        </TemplateCardBox>
      </Card>

      <CardOverlay
        className='bg-opacity-90 justify-content-around align-items-stretch'
      >
        <Button variant='transparent-green'>
          <Icon name='plus' className='me-2' size='sm' />
          Usar esse modelo
        </Button>

        <div className='text-white text-center'>
          ou
        </div>

        <Button
          variant='transparent-dark'
          as={ExternalLink}
          target='_blank'
          href={helpUrl(helpPath)}
        >
          <Icon name='info' className='me-2' size='sm' />
          Ver como funciona
        </Button>
      </CardOverlay>
    </div>
  );
}

TemplateCard.propTypes = propTypes;

export default TemplateCard;
