import React from 'react';
import PropTypes from 'prop-types';
import Select from '@/components/Inputs/Select';
import Icon from '@/components/Icon';
import {
  useDealsCustomFields,
  usePeopleCustomFields,
  useOrganizationCustomFields
} from '@/api';
import { getString } from '@/utils';

/* eslint key-spacing: ["error", { "align": "value" }] */
const groups = [
  {
    entity:        'deal',
    label:         'Campos de negócio',
    defaultFields: [
      'finished_at',
      'value',
      'description',
      'loss_reason',
      'loss_reason_description',
      'products'
    ].map((id) => (
      {
        id,
        name: getString(['attributes', 'deal', id]),
        default: true
      }
    ))
  },
  {
    entity:        'person',
    label:         'Campos de pessoa',
    defaultFields: [
      'cpf',
      'job_title',
      'birthday',
      'birth_year',
      'category',
      'lead_origin',
      'description',
      'email',
      'whatsapp',
      'work_phone',
      'mobile_phone',
      'fax_phone',
      'phone_extension',
      'postal_code',
      'country',
      'state',
      'city',
      'district',
      'street_name',
      'street_number',
      'additional_info',
      'products',
      'facebook',
      'twitter',
      'linkedin',
      'skype',
      'instagram'
    ].map((id) => (
      {
        id,
        name: getString(['attributes', 'person', id]),
        default: true
      }
    ))
  },
  {
    entity:        'organization',
    label:         'Campos de empresa',
    defaultFields: [
      'legal_name',
      'cnpj',
      'category',
      'lead_origin',
      'sector',
      'description',
      'email',
      'whatsapp',
      'work_phone',
      'mobile_phone',
      'fax_phone',
      'phone_extension',
      'postal_code',
      'country',
      'state',
      'city',
      'district',
      'street_name',
      'street_number',
      'additional_info',
      'products',
      'facebook',
      'twitter',
      'linkedin',
      'skype',
      'instagram'
    ].map((id) => (
      {
        id,
        name: getString(['attributes', 'organization', id]),
        default: true
      }
    ))
  }
];
/* eslint key-spacing: ["error", { "mode": "minimum" }] */

const propTypes = {
  isClearable: PropTypes.bool,
  value: PropTypes.string
};

const defaultProps = {
  isClearable: true
};

function FieldSelect(props) {
  const config = {
    refetchOnMount: false,
    staleTime: 1000
  };
  const queries = {
    deal: useDealsCustomFields({ config }),
    person: usePeopleCustomFields({ config }),
    organization: useOrganizationCustomFields({ config })
  };

  const options = groups.map((group, index) => {
    const {
      data: { data: customFields = [] } = {}
    } = queries[group.entity];

    const allFields = group.defaultFields.concat(customFields);

    return {
      entity: group.entity,
      label: group.label,
      options: allFields.map(({ name, id }) => ({
        label: name,
        value: `${group.entity}.${id}`,
        leftAdornment: <Icon name={group.entity} />
      })),
      index
    };
  });

  return (
    <Select
      { ...props }
      options={options}
      placeholder='Digite o nome do campo'
    />
  );
}

FieldSelect.propTypes = propTypes;
FieldSelect.defaultProps = defaultProps;

export default FieldSelect;
