import React from 'react';
import PropTypes from 'prop-types';
import { getErrorMessage } from '@/utils';

const propTypes = {
  errors: PropTypes.object,
  status: PropTypes.number,
  scope: PropTypes.string
};

const defaultProps = {
  status: 0
};

function APIErrorList({ errors, status, scope }) {
  const entries = errors ? Object.entries(errors) : null;

  if (!entries || entries.length === 0) {
    return (
      <div>Código do erro: {status}</div>
    );
  }

  return (
    <div>
      Ocorreram os seguintes erros:

      <ul className='mb-0'>
        {entries.flatMap(([attribute, keys]) => (
          <AttributeErrorList
            scope={scope}
            attribute={attribute}
            keys={keys}
            key={attribute}
          />
        ))}
      </ul>
    </div>
  );
}

function AttributeErrorList({ scope, attribute, keys }) {
  return keys.map((key) => {
    const errorMessage =
      getErrorMessage([scope, attribute, key]) ||
        getErrorMessage([attribute, key], 'Erro inesperado');

    return (
      <li key={`${attribute}-${key}`}>
        {errorMessage}
      </li>
    );
  });
}

APIErrorList.propTypes = propTypes;
APIErrorList.defaultProps = defaultProps;

export default APIErrorList;
