import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Select } from '@/components/Inputs';
import Button from '@/components/Button';
import Popover from '@/components/Popover';
import Tooltip from '@/components/Tooltip';
import ToggleButtonGroup from '@/components/ToggleButtonGroup';
import ToggleButton from '@/components/ToggleButton';
import Icon from '@/components/Icon';

const directionsTypes = {
  number: {
    asc: 'menor',
    desc: 'maior'
  },
  text: {
    asc: 'a',
    desc: 'z'
  },
  date: {
    asc: 'antigo',
    desc: 'recente'
  },
  futureDate: {
    asc: 'próximo',
    desc: 'distante'
  }
};

const directionShape = PropTypes.shape({
  type: PropTypes.oneOf(Object.keys(directionsTypes)).isRequired,
  defaultValue: PropTypes.oneOf(['asc', 'desc']).isRequired
});

const optionShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  direction: directionShape
});

const propTypes = {
  options: PropTypes.arrayOf(optionShape).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func,
  innerClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  disabled: PropTypes.bool
};

const defaultProps = {
  onChange: () => {},
  innerClassName: '',
  buttonClassName: '',
  disabled: false
};

function SortInput(props) {
  const {
    options, value: rawValue, onChange, innerClassName, buttonClassName,
    disabled
  } = props;

  const [value, directionValue] = rawValue.split(' ');

  const tooltipValue = getSelectedLabel(options, value);
  const direction = getSelectedDirection(options, value);

  const switchValue = directionValue || direction.defaultValue;
  const directionLabels = directionsTypes[direction.type];

  const handleSelectChange = (newValue) => {
    const newDirection = getSelectedDirection(options, newValue);
    onChange(`${newValue} ${newDirection.defaultValue}`);
  };

  const handleDirectionSwitch = (newDirectionValue) => {
    onChange(`${value} ${newDirectionValue}`);
  };

  return (
    <Popover
      className='width-5'
      content={
        () => (
          <div className='py-1'>
            <h4>Ordenar por</h4>

            <Select
              className='mt-4 mb-0'
              { ...props }
              value={value}
              onChange={handleSelectChange}
              isSearchable={false}
            />

            <ToggleButtonGroup
              className='switch-btn overflow-hidden mt-3 w-100'
              type='radio'
              name='sorts'
              value={switchValue}
              onChange={handleDirectionSwitch}
            >
              <ToggleButton
                id='sorts_asc'
                value='asc'
                className='w-50 justify-content-start z-index-2'
                variant='outline-dark'
                size='sm'
                aria-label='Ascendente'
              >
                <span>{directionLabels.asc}</span>

                <div className={classnames(
                  'switch-arrow',
                  'position-absolute top-50 start-100 pe-none',
                  'd-flex align-items-center justify-content-center',
                  'text-white rounded'
                )}>
                  <Icon name='full-arrow-right' size='sm' />
                </div>
              </ToggleButton>

              <ToggleButton
                id='sorts_desc'
                value='desc'
                className='w-50 justify-content-end'
                variant='outline-dark'
                size='sm'
                aria-label='Descendente'
              >
                <span>{directionLabels.desc}</span>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        )
      }
      trigger={['click']}
      placement='bottom-start'
      rootClose
      rootCloseEvent='mousedown'
      flip
    >
      <div className={innerClassName}>
        <Tooltip content={`Ordenado por ${tooltipValue}`} placement='top'>
          <Button
            className={classnames('px-2 text-nowrap', buttonClassName)}
            variant='transparent-light'
            disabled={disabled}
          >
            <Icon className='me-1' name='order' />

            <span className='d-none d-md-inline-block'>Ordenar</span>
          </Button>
        </Tooltip>
      </div>
    </Popover>
  );
}

function getSelected(options, selectedValue) {
  return options.find((option) => (
    selectedValue ? option.value === selectedValue : option.value
  ));
}

function getSelectedLabel(options, selectedValue) {
  const { label: selectedLabel } = getSelected(options, selectedValue);
  return selectedLabel;
}

function getSelectedDirection(options, selectedValue) {
  const { direction: selectedDirection } = getSelected(options, selectedValue);
  return selectedDirection;
}

SortInput.propTypes = propTypes;
SortInput.defaultProps = defaultProps;

export default SortInput;
