import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import FormField from '@/components/FormField';
import { isBlank } from '@/utils';

const propTypes = {
  source: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  autohide: PropTypes.bool
};

const defaultProps = {
  autohide: false
};

function DependentFormField({ source, target, autohide, ...fieldProps }) {
  const { name } = fieldProps;
  const { getFieldMeta, handleChange } = useFormikContext();

  const { value: sourceValue } = getFieldMeta(source);
  const dependentProps = { [target]: sourceValue, key: sourceValue };

  useEffect(() => {
    if (isBlank(sourceValue)) {
      handleChange({ target: { name, value: null } });
    }
  }, [sourceValue, handleChange, name]);

  if (isBlank(sourceValue) && autohide) {
    return null;
  }

  return <FormField { ...fieldProps } { ...dependentProps } />;
}

DependentFormField.propTypes = propTypes;
DependentFormField.defaultProps = defaultProps;

export default DependentFormField;
