import { LEGACY_HOST } from '@/config';

export function baseLegacyUrl() {
  return `${LEGACY_HOST}/sistema`;
}

export function legacyHomeUrl() {
  return `${baseLegacyUrl()}/inicio`;
}

export function legacyReportsUrl() {
  return `${baseLegacyUrl()}/relatorios/painel.php`;
}

export function legacyPlansUrl() {
  return `${baseLegacyUrl()}/minha-conta`;
}

export function legacyAccountUrl() {
  return `${baseLegacyUrl()}/minha-conta/alterar.php`;
}

export function legacyUsersUrl() {
  return `${baseLegacyUrl()}/usuarios`;
}

export function legacyRegisterUserUrl() {
  return `${baseLegacyUrl()}/usuarios/inserir.php`;
}

export function legacyUserUrl(id) {
  return `${legacyUsersUrl()}/alterar.php?id=${id}`;
}

export function legacyGroupsUrl() {
  return `${baseLegacyUrl()}/grupos`;
}

export function legacyTeamsUrl() {
  return `${baseLegacyUrl()}/equipes`;
}

export function legacyDepartmentsUrl() {
  return `${baseLegacyUrl()}/departamentos`;
}

export function legacySettingsUrl(config) {
  return `${baseLegacyUrl()}/configuracoes/#field${config}`;
}

export function legacyIntegrationsUrl() {
  return `${baseLegacyUrl()}/integracoes`;
}

export function legacyLoginUrl() {
  return `${LEGACY_HOST}/entrar`;
}

export function legacyLogoutUrl() {
  return `${LEGACY_HOST}/src/php/sistema/http/page/logout.php`;
}

/* eslint-disable max-len */
/**
 * Deixar as chaves em ordem alfabética.
 */
const HELP_PATHS = {
  'activate-voip': '/pt-BR/articles/8143966-como-ativar-o-telefone-virtual-do-agendor',
  'cnpj-not-found': '/pt-BR/articles/5989468-formulario-nao-preencheu-automaticamente-os-dados-de-uma-empresa-com-cnpj-existente-por-que',
  'concurrent-session': '/pt-BR/articles/8346285-minha-sessao-foi-encerrada-o-que-aconteceu',
  'create-deal': '/pt-BR/articles/2659952-como-acompanhar-os-negocios-no-agendor',
  'deal-email': '/como-gravar-emails-nos-historicos-do-agendor',
  'deal-automation': '/pt-BR/articles/8341978-como-as-automacoes-de-negocio-podem-me-ajudar',
  'email-automation': '/pt-BR/articles/8746305-como-as-automacoes-de-e-mail-podem-me-ajudar',
  'email-failed': '/pt-BR/articles/8898605-houve-uma-falha-no-envio-do-e-mail-o-que-aconteceu',
  'email-sender': '/pt-BR/articles/8887537-o-que-e-remetente-verificado-e-como-configurar',
  'import-export': '/pt-BR/collections/1748934-importacao-e-exportacao',
  'products': '/pt-BR/articles/2653300-como-eu-cadastro-um-produto-ou-servico-no-agendor',
  'replace-number': '/pt-BR/articles/8174075-como-funcionam-os-numeros-no-telefone-virtual-do-agendor',
  'task-automation': '/pt-BR/articles/8341975-como-as-automacoes-de-tarefa-podem-me-ajudar',
  'use-voip-gpt': '/pt-BR/articles/9357557-como-usar-o-telefone-virtual-inteligente',
  'whatsapp-extension': '/pt-BR/articles/6121363-como-funciona-a-extensao-whatsapp-web-do-agendor-no-google-chrome'
};
/* eslint-enable max-len */


export function helpUrl(key) {
  const path = (key && HELP_PATHS[key]) ?? '';

  return `https://ajuda.agendor.com.br${path}`;
}

export function changelogUrl() {
  return 'https://changelog.agendor.com.br';
}

export function betaFullUrl(path = '') {
  return `${window.location.origin}${path}`;
}

export function helpBlogUrl(key) {
  const path = (key && HELP_PATHS[key]) ?? '';

  return `https://www.agendor.com.br/blog${path}`;
}

export function homeUrl() {
  return '/';
}
