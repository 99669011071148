import { post } from '@/lib/fetch';
import { useMutation, useQuery } from 'react-query';

export const checkoutPreviewKeys = {
  all: ['checkout-preview'],
  couponAll: ['coupon'],
  lists: () => [...checkoutPreviewKeys.all, 'list'],
  list: (params) => [...checkoutPreviewKeys.lists(), params]
};

const mockData = {
  withDefaultAndCustomDiscount: {
    currentActiveUsersCount: 25,
    currentValuePerUser: 53.0,
    customDiscount: {
      finalDiscountAmount: 4770.00954,
      percentage: 0.166667,
      title: 'Preço especial do Plano Pro de R$ 30 ao invés de R$ 36 até 14/Abr de 2017'
    },
    defaultDiscount: {
      finalDiscountAmount: 5.3,
      percentage: 0.1,
      title: '10% de desconto para assinatura anual'
    },
    expirationDate: '2032-02-19T12:58:22.000Z',
    startDate: '2031-02-19T12:58:22.000Z',
    finalMonthlyValue: 1987.499205,
    finalValue: 23849.99046,
    maxUsersCount: 50,
    monthlyInfo: {
      valuePerAccount: 2385.0,
      valuePerUser: 47.7
    },
    name: 'Pro 50',
    paymentInfo: {
      lastFourDigits: '4567',
      method: 'credit_card',
      revenueDate: '2032-02-20T12:58:22.000Z'
    },
    periodicity: 'yearly',
    subscriptionType: 'paid',
    withoutCustomDiscountValue: 28620.0,
    withoutDiscountValue: 31800.0,
    finalDiscountAmount: 7950.00954
  },
  withCustomDiscount: {
    currentActiveUsersCount: 25,
    currentValuePerUser: 53,
    customDiscount: {
      finalDiscountAmount: 1150,
      percentage: 0.166667,
      title: 'Preço especial do Plano Pro de R$ 30 ao invés de R$ 36 até 14/Abr de 2017'
    },
    defaultDiscount: null,
    expirationDate: '2027-02-18T12:58:22.000Z',
    startDate: '2027-01-18T12:58:22.000Z',
    finalMonthlyValue: 2650,
    finalValue: 1500,
    maxUsersCount: 50,
    monthlyInfo: {
      valuePerAccount: 2650,
      valuePerUser: 53
    },
    name: 'Pro 50',
    paymentInfo: {
      lastFourDigits: null,
      method: 'bank_slip',
      revenueDate: '2027-02-19T12:58:22.000Z'
    },
    periodicity: 'monthly',
    subscriptionType: 'paid',
    withoutCustomDiscountValue: 2650.0,
    finalDiscountAmount: 1150.0
  },
  withDefaultDiscount: {
    currentActiveUsersCount: 25,
    currentValuePerUser: 53.0,
    customDiscount: null,
    defaultDiscount: {
      finalDiscountAmount: 5.3,
      percentage: 0.1,
      title: '10% de desconto para assinatura anual'
    },
    expirationDate: '2032-02-19T12:58:22.000Z',
    startDate: '2031-02-19T12:58:22.000Z',
    finalMonthlyValue: 2385.0,
    finalValue: 28620.0,
    maxUsersCount: 50,
    monthlyInfo: {
      valuePerAccount: 2385.0,
      valuePerUser: 47.7
    },
    name: 'Pro 50',
    paymentInfo: {
      lastFourDigits: '4567',
      method: 'credit_card',
      revenueDate: '2032-02-20T12:58:22.000Z'
    },
    periodicity: 'yearly',
    subscriptionType: 'paid',
    withoutCustomDiscountValue: 28620.0,
    withoutDiscountValue: 31800.0,
    finalDiscountAmount: 3180.0
  },
  voipRecharge: {
    currentActiveUsersCount: null,
    currentValuePerUser: null,
    customDiscount: null,
    defaultDiscount: {
      finalDiscountAmount: null,
      percentage: null,
      title: '10% de desconto para assinatura anual'
    },
    expirationDate: new Date().toISOString(),
    startDate: new Date().toISOString(),
    finalMonthlyValue: null,
    finalValue: 0,
    maxUsersCount: 0,
    monthlyInfo: {
      valuePerAccount: null,
      valuePerUser: null
    },
    name: 'Crédito Voip',
    paymentInfo: {
      lastFourDigits: '4567',
      method: 'credit_card',
      revenueDate: '2032-02-20T12:58:22.000Z'
    },
    periodicity: 'yearly',
    subscriptionType: 'paid',
    withoutCustomDiscountValue: null,
    withoutDiscountValue: null,
    finalDiscountAmount: null
  },
  default: {
    currentActiveUsersCount: 25,
    currentValuePerUser: 53,
    customDiscount: null,
    defaultDiscount: null,
    expirationDate: '2027-02-18T12:58:22.000Z',
    startDate: '2027-01-18T12:58:22.000Z',
    finalMonthlyValue: 2650,
    finalValue: 1500,
    maxUsersCount: 50,
    monthlyInfo: {
      valuePerAccount: 2650,
      valuePerUser: 53
    },
    name: 'Pro 50',
    paymentInfo: {
      lastFourDigits: null,
      method: 'bank_slip',
      revenueDate: '2027-02-19T12:58:22.000Z'
    },
    periodicity: 'monthly',
    subscriptionType: 'paid',
    withoutCustomDiscountValue: 2650.0,
    finalDiscountAmount: 1150.0
  }
};

export function showCheckoutPreview(params = {}) {
  let data = mockData.default;
  if (params.period === 'none') {
    data = mockData.voipRecharge;
  } else if (params.period !== 'monthly' && params.coupon) {
    data = mockData.withDefaultAndCustomDiscount;
  } else if (params.period !== 'monthly') {
    data = mockData.withDefaultDiscount;
  } else if (params.coupon) {
    data = mockData.withCustomDiscount;
  }

  let timeoutId;
  return new Promise((resolve) => {
    const delay = 2000;
    clearTimeout(timeoutId);

    setTimeout(() => {
      resolve({ data });
    }, delay);
  });
}

export async function checkCoupon(params) {
  const { data } = await post('/billing/check_coupon', params);

  return data;
}

export function useCheckoutPreview({ config = {}, params = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: checkoutPreviewKeys.list(params),
    queryFn: () => showCheckoutPreview(params)
  });
}

export function useCheckCoupon({ config = {} } = {}) {
  return useMutation(
    checkCoupon,
    { ...config }
  );
}
