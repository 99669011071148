import { useEffect } from 'react';

const SUFFIX = 'Agendor (Beta)';

export function useDocumentTitle(title) {
  useEffect(() => {
    const previousTitle = document.title;
    const newTitle = title ? `${title} - ${SUFFIX}` : SUFFIX;

    document.title = newTitle;

    return () => {
      document.title = previousTitle;
    };
  }, [title]);
}
