import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { settingsVoipPanelPath } from '@/routes';
import { useAuth } from '@/lib/auth';
import Icon from '@/components/Icon';
import { useVoipVerify } from '@/api';
import { toCurrencyString } from '@/number';

const MIN_BALANCE_WARNING = 20;

function VoipMessageBalance() {
  const { user } = useAuth();
  const { data: voip } = useVoipVerify();
  const accountBalance = Number(voip?.balance);
  const isAdmin = user?.admin;

  if (accountBalance < MIN_BALANCE_WARNING) {
    return (
      <Card className='bg-light-yellow text-warning border-0 p-2'>
        <Row>
          <Col sm={2} className='d-flex align-items-center'>
            <Icon name='alert' variant='warning' className='ms-2'></Icon>
          </Col>
          <Col sm={10} className='ms-n3 mt-1'>
            <span className='text-warning fw-bold ms-n2'>
              Seus créditos estão acabando!
            </span>
            <li className='text-small'>
              Você possui menos de <span className='fw-bold'>
                {toCurrencyString(MIN_BALANCE_WARNING)} de créditos</span>
              <span className='ms-4'> no telefone virtual.</span>
            </li>
            {isAdmin
              ? (
                <div>
                  <li className='text-small'>
                    Efetue uma nova recarga para não correr<br/>
                    <span className='ms-4'>riscos de ficar sem créditos.</span>
                  </li>
                  <Button
                    size='sm'
                    variant='link'
                    className='text-nowrap ms-n3 fw-bold'
                    href={settingsVoipPanelPath()}
                  >
                    <Icon name='external-link' className='text-dark me-1'></Icon>
                    <span className='text-dark mt-1'>Recarregar</span>
                  </Button>
                </div>
              )
              : (
                <li className='text-small'>
                  Solicite ao administrador da conta para<br/>
                  <span className='ms-4'>adicionar créditos.</span>
                </li>
              )}
          </Col>
        </Row>
      </Card>
    );
  }
  return null;
}

export default VoipMessageBalance;
