import { useInfiniteQuery } from 'react-query';
import { get } from '@/lib/fetch';

export const callsKeys = {
  infiniteAll: ['infinite-calls'],
  infiniteLists: () => [...callsKeys.infiniteAll, 'list'],
  infiniteList: (params) => ([...callsKeys.infiniteLists(), params])
};

export async function getCalls(params = {}) {
  const { data } = await get('/calls', params);

  return data;
}

export function useInfiniteCalls({ params = {}, config = {} } = {}) {
  return useInfiniteQuery(
    callsKeys.infiniteList(params),
    ({ pageParam: page = 1 }) => getCalls({ ...params, page }),
    {
      ...config,
      getNextPageParam: (lastPage) => lastPage.meta.next
    }
  );
}
