import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Popover from '@/components/Popover';
import { useFormikContext } from 'formik';
import Button from '@/components/Button';

const propTypes = {
  children: PropTypes.node.isRequired,
  defaultValues: PropTypes.object.isRequired,
  columnsFilters: PropTypes.node.isRequired
};

const defaultProps = {};

function ColumnsPopover({ children, defaultValues, columnsFilters }) {
  const { submitForm, resetForm } = useFormikContext();

  return (
    <Popover
      closeButton
      content={
        ({ onHide }) => (
          <div className='width-5 py-1'>
            <h4>Editar colunas e filtros</h4>

            <div
              className={classnames(
                'columns-popover-content-max-height',
                'min-height-4',
                'mt-4',
                'overflow-y-auto',
                'px-4',
                'mx-n4'
              )}
            >
              {columnsFilters}
            </div>

            <div className='mt-4 d-grid gap-2'>
              <Button
                onClick={() => {
                  submitForm();
                  onHide();
                }}
              >
                Salvar alterações
              </Button>

              <Button
                variant='outline-dark-gray'
                onClick={() => {
                  resetForm({ values: defaultValues });
                  submitForm();
                  onHide();
                }}
              >
                Restaurar padrão
              </Button>
            </div>
          </div>
        )
      }
      trigger={['click']}
      placement='bottom-start'
      rootClose
      rootCloseEvent='mousedown'
      flip
    >
      {children}
    </Popover>
  );
}

ColumnsPopover.propTypes = propTypes;
ColumnsPopover.defaultProps = defaultProps;

export default ColumnsPopover;
