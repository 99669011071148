import React from 'react';
import SearchSubSection from '@/components/LeadsGeneration/Segmentation/SearchSubSection';
import { RadioButton } from '@/components/Inputs';
import { useLeadsGenerationContext } from '@/contexts';
import Footer from '@/components/LeadsGeneration/Footer';
import MultiRangeSlider from '@/components/Inputs/MultiRangeSlider';

const OPTIONS = [
  { value: 'no_preference', label: 'Não tenho preferência' },
  { value: 'with_preference', label: 'Definir uma faixa de quantidade de funcionários' }
];

const ONE_THOUSAND = 1000;

function OrganizationSize() {
  const {
    leadSizeData,
    setLeadSizeData,
    leadSizePreference,
    setLeadSizePreference,
    nextStep,
    prevStep,
    callMixpanelEvent
  } = useLeadsGenerationContext();

  const rangePhrase = `
    ${leadSizeData?.minValue}
    a
    ${leadSizeData?.maxValue === ONE_THOUSAND ? '+1000' : leadSizeData?.maxValue}
  `;

  const onClickNext = () => {
    callMixpanelEvent('trackLeadsGenerationSegmented');

    nextStep();
  };

  return (
    <>
      <SearchSubSection
        title='Você tem preferência por tamanho de empresa?'
        icon='task'
      >
        {
          OPTIONS.map(({ value, label }) => (
            <RadioButton
              id={value}
              key={value}
              label={label}
              value={leadSizePreference}
              radioValue={value}
              name='lead_size'
              checkClassName='ms-0 mb-3 text-dark-gray fw-bold'
              noFlex
              onChange={setLeadSizePreference}
            />
          ))
        }

        {leadSizePreference === 'with_preference' && (
          <>
            <MultiRangeSlider
              onInputChange={setLeadSizeData}
              initialMinValue={leadSizeData.minValue}
              initialMaxValue={leadSizeData.maxValue}
            />
            <h4 className='mt-6 text-primary'>
              Selecionado: {rangePhrase} funcionários
            </h4>
          </>
        )}

      </SearchSubSection>

      <Footer
        buttonNextText='Avançar'
        onClickPrev={prevStep}
        onClickNext={onClickNext}
        isCounterVisible
      />
    </>
  );
}

export default OrganizationSize;
